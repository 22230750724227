import { createContext, useReducer, useContext } from 'react';
import NewFormCardReducer, { initialState } from './NewFormCardReducer';
// constants
import * as Constants from "@constants/index";
import logger from '@utilities/logService'

const { ADD_TO_CATEGORY,
	REMOVE_FROM_CATEGORY,
	ADD_UNIT_LIST,
	REMOVE_UNIT_LIST,
	ADD_TO_LIST_VIEW,
	ADD_NEW_FORM_DETAILS,
	SET_UNIT_OPTIONS_LIST_VIEW,
	UPDATE_UNIT_SELECTED_LIST_VIEW,
	RESET_FORM_LIST_VIEW,
	SET_PROJECT_FORM_STATE,
	REFETCH_FORMS_LIBRARY,
	OPERATON_TYPE } = Constants.CONTEXT_API_METHOD;

const NewFormCardContext = createContext();

export const NewFormCardProvider = ({ children }) => {
	const [state, dispatch] = useReducer(NewFormCardReducer, initialState);

	const addToCategory = (form, mode) => {
		state.projectFormAdded = [];
		const updateFormInCategorySection = state.projectFormAdded.concat(form);

		const { formName, isAdded } = updateFormInCategorySection[0];
		const isNewlyAdded = !isAdded;
		const currentExpandedFormCategoryPanel = `project-mgmt-panel-${updateFormInCategorySection?.at().formCategoryId}`;

		dispatch({
			type: ADD_TO_CATEGORY,
			payload: {
				projectFormAdded: updateFormInCategorySection,
				newFormName: formName,
				isNewlyAdded: isNewlyAdded,
				mode: mode,
				currentExpandedFormCategoryPanel: currentExpandedFormCategoryPanel
			}
		})
	}

	const removeFromCategory = () => {
		dispatch({
			type: REMOVE_FROM_CATEGORY,
			payload: {
				projectFormAdded: [],
				newFormName: '',
				isNewlyAdded: false,
				projectUnitTitle: [],
			}
		})
	}

	const addUnitList = (id) => {
		const updatedUnitList = state.projectUnitTitle.concat(id);
		const uniqueValues = [...new Set(updatedUnitList)]; // eliminate duplicate value

		dispatch({
			type: ADD_UNIT_LIST,
			payload: {
				projectUnitTitle: uniqueValues,
			}
		})
	}

	const removeUnitList = (id) => {
		const updatedUnitList = state.projectUnitTitle.filter(unitid => unitid !== id);

		dispatch({
			type: REMOVE_UNIT_LIST,
			payload: {
				projectUnitTitle: updatedUnitList,
			}
		})
	}

	const addToListView = (isSaveFromListView) => {
		dispatch({
			type: ADD_TO_LIST_VIEW,
			payload: {
				isSaveFromListView: isSaveFromListView,
			}
		})
	}

	const addNewFormDetails = (formName, newProjectFormName) => {
		dispatch({
			type: ADD_NEW_FORM_DETAILS,
			payload: {
				newFormName: formName,
				newProjectFormName: newProjectFormName
			}
		})
	}

	const setUnitOptionsListView = (unitOptios) => {
		dispatch({
			type: SET_UNIT_OPTIONS_LIST_VIEW,
			payload: {
				unitOptionsListView: unitOptios,
			}
		})
	}

	const updateUnitSelectedListView = (type, id) => {
		let finalResult;

		if (type === OPERATON_TYPE.ADD) {
			const updatedUnitList = state.unitsSelectedListView.concat(id);
			finalResult = [...new Set(updatedUnitList)]; // eliminate duplicate value
		} else if (type === OPERATON_TYPE.REMOVE) {
			finalResult = state.unitsSelectedListView.filter(unitid => unitid !== id);
		}

		dispatch({
			type: UPDATE_UNIT_SELECTED_LIST_VIEW,
			payload: {
				unitsSelectedListView: finalResult,
			}
		})
	}

	const executeRefetchOfFormsLibrary = (boolFlag) => {
		dispatch({
			type: REFETCH_FORMS_LIBRARY,
			payload: {
				hasTriggeredRefetchOfFormsLibrary: boolFlag,
			}
		})
	}

	const resetFormListView = () => {
		dispatch({
			type: RESET_FORM_LIST_VIEW,
			payload: {
				isSaveFromListView: false,
				unitOptionsListView: [],
				unitsSelectedListView: [],
				mode: '',
				projectFormAdded: [],
				projectUnitTitle: []
			}
		})
	}

	const setIsProjectFormOnEdit = (isOnEdit) => {
		dispatch({
			type: SET_PROJECT_FORM_STATE,
			payload: {
				isProjectFormOnEdit: isOnEdit,
			}
		})
	}

	const value = {
		projectFormAdded: state.projectFormAdded,
		newFormName: state.newFormName,
		newProjectFormName: state.newProjectFormName,
		newFormNameExtension: state.newFormNameExtension,
		newCheckedUnits: state.newCheckedUnits,
		isNewlyAdded: state.isNewlyAdded,
		projectUnitTitle: state.projectUnitTitle,
		currentExpandedFormCategoryPanel: state.currentExpandedFormCategoryPanel,
		isSaveFromListView: state.isSaveFromListView,
		unitOptionsListView: state.unitsSelectedListView,
		unitsSelectedListView: state.unitsSelectedListView,
		isProjectFormOnEdit: state.isProjectFormOnEdit,
		mode: state.mode,
		hasTriggeredRefetchOfFormsLibrary: state.hasTriggeredRefetchOfFormsLibrary,
		addToCategory,
		removeFromCategory,
		addUnitList,
		removeUnitList,
		addToListView,
		addNewFormDetails,
		setUnitOptionsListView,
		updateUnitSelectedListView,
		resetFormListView,
		setIsProjectFormOnEdit,
		executeRefetchOfFormsLibrary
	}

	return <NewFormCardContext.Provider value={value}>{children}</NewFormCardContext.Provider>
}

const useNewFormCard = () => {
	const context = useContext(NewFormCardContext);

	if (context === undefined) {
		logger.info(`useNewFormCard must be used within NewFormContext.`);
	}

	return context;
}

export default useNewFormCard;