import React from "react"
import {
    FormProvider
} from "react-hook-form"


export const Form = React.forwardRef((props, ref) => {
    const { form, handleSubmit, ...passThroughProps } = props

    return (
        <FormProvider {...form}>
            <form
                ref={ref}
                onSubmit={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    if(!!handleSubmit){
                        form.handleSubmit(handleSubmit)(event)
                    }
                }}
                {...passThroughProps}
            >
                {
                    React.Children.map(props.children, (child) => {
                        return typeof child !== "string" &&
                            typeof child !== "number" &&
                            typeof child !== "boolean" &&
                            child &&
                            "props" in child &&
                            child.props.name
                            ? React.createElement(child.type, {
                                ...{
                                    ...child.props,
                                    register: form.register,
                                    key: child.props.name
                                }
                            })
                            : child
                    })
                }
            </form>
        </FormProvider>
    )
})
