import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm, useWatch, useFieldArray } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { toast } from 'react-toastify';

import { useParams } from "react-router-dom";

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  InputLabel,
  Grid,
  Stack
} from '@mui/material';

import { COMMON } from '@constants/index'
import { INHERENT_RISK_FACTORS } from '@constants/customForm'

import CLATextField from '@components/Forms/CLATextField/CLATextField';
import { CLAComboBox, CLARadioButtonList, CLASelect } from '@ais/forms';
import { Form } from "@ais/palette"
import CLATruncatedTextWithTooltip from "@components/CLATruncatedTextWithTooltip/CLATruncatedTextWithTooltip";

import styles from '../FormView.module.css'
import {AddAnotherAuditArea} from './AddAnotherAuditArea'

// Services
import { useGetProjectSimple } from "@services/project";
import { useGetMethodologyVersionRiskFactor } from "@services/customForm/riskFactor";
import { useGetEventConditionsByMethodologyVersion } from "@services/customForm/eventConditions";
import { useAuditAreas } from '@services/auditArea';
import { useGetRiskAssessmentOptions } from '@services/riskassessment';
import { useUpdateRiskFactor, useCreateRiskFactor } from "@services/customForm/riskFactor.js";
import { useGetProjectRiskFactor } from "@services/customForm/riskFactor.js";
import { makeStyles } from '@mui/styles';

export const AddRiskFactor = ({
  visible,
  setVisible,
  formName,
  riskFactorData,
  workpaperReferenceEnabled,
  isEdit,
}) => {
  const {
    INHERENT_RISK_FACTORS_LABEL,
    RISK_FACTOR_ASSOCIATION_LABEL,
    RISK_FACTOR,
    RISK_FACTOR_WORKPAPER_REFERENCE_LABEL,
    RISK_FACTOR_NAME_LABEL,
    RISK_FACTOR_NAME_PLACEHOLDER,
    RISK_FACTOR_WORKPAPER_REFERENCE_PLACEHOLDER,
    RISK_OF_MATERIAL_MISSTATEMENTS_LABEL,
    INHERENT_RISK_AFFECT_ACCOUNTING_ESTIMATE_LABEL,
    INHERENT_RISK_AFFECT_SELECTION_AND_APPLICATION_METHOD_LABEL,
    INHERENT_RISK_AFFECT_POINT_ESTIMATE,
    ERROR_MESSAGE
  } = INHERENT_RISK_FACTORS.EN
  const {
    FORM,
    SELECT,
    HIGHER,
    LOWER
  } = COMMON.EN.OTHERS;

  const {
    YES,
    NO
  } = COMMON.EN.OPTIONS;

  const {
    RiskFactorName,
    WorkpaperReference,
    ProjectRiskFactorEventConditions,
    ProjectRiskFactorAuditAreas,
    ProjectScopeRiskFactorSummaries,
    ProjectFormId,
    ProjectRiskFactorId,
    ProjectRiskFactorRiskFactor,
    IsRelatedToAccountingEstimate,
    IsDegreeofManagementPointEstimateHigh,
    IsRiskFactorDegreeHigh
  } = riskFactorData;

  const { projectId, projectFormId } = useParams()
  const updateMutation = useUpdateRiskFactor(projectId);
  const createMutation = useCreateRiskFactor(projectId);
  const {
    data: projectRiskFactor,
    isLoading: projectRiskFactorLoading,
    isRefetching: projectRiskFactorRefetching
  } = useGetProjectRiskFactor(null, null, null, projectId);

  // FORMS DATA
  const [riskFactors, setRiskFactors] = React.useState([]);
  const [riskFactorNames, setRiskFactorNames] = useState([])
  const [eventsOrConditions, setEventsOrConditions] = React.useState([]);
  const [riskAssessmentOptions, setRiskAssessmentOptions] = React.useState([]);
  const [auditAreas, setAuditAreas] = React.useState([]);
  const [isRiskFactorNameUnique, setIsRiskFactorNameUnique] = React.useState(true)
  const stringRequiredSchema = Yup.string().trim().required()
  // API DATA
  const { data: projectSimpleDetails, isLoading: projectSimpleDetailsLoading } = useGetProjectSimple(projectId);
  const { data: methodologyRiskFactor } = useGetMethodologyVersionRiskFactor(projectSimpleDetails?.data?.MethodologyVersionId, !projectSimpleDetailsLoading, projectId);
  const { data: methodologyEventConditions } = useGetEventConditionsByMethodologyVersion(projectSimpleDetails?.data?.MethodologyVersionId, !projectSimpleDetailsLoading);
  
  const createRiskFactorOptions = (riskAssesmentData) => {
    return riskAssesmentData?.map((value) => ({
      value: value.RiskFactorId,
      label: value.RiskFactorName
    }))
  }

  const createEventConditionsOptions = (dataList) => {
    return dataList?.map((value) => (
      value.EventConditionName
    ))
  }

  const isRiskFactorDegreeHighBoolean = typeof IsRiskFactorDegreeHigh === 'boolean';
  const isDegreeofManagementPointEstimateHighBoolean = typeof IsDegreeofManagementPointEstimateHigh === 'boolean';

  const initialValues = {
    riskFactorAssociation: ProjectScopeRiskFactorSummaries?.map((riskAssessment) => riskAssessment.ProjectScopeId) ?? [],
    riskFactorWorkpaperReference: WorkpaperReference ?? '',
    riskFactorName: RiskFactorName ?? '',
    riskFactor: ProjectRiskFactorRiskFactor
      ? ProjectRiskFactorRiskFactor
        .filter(riskFactor => methodologyRiskFactor.some(
          methodologyRiskFactor => methodologyRiskFactor.RiskFactorId === riskFactor.RiskFactorId)
        )
        .map(({ RiskFactorId }) => RiskFactorId)
      : [],
    eventsOrConditions: ProjectRiskFactorEventConditions?.map(event => event.EventConditionName) ?? [],
    auditAreaScotabdsList: ProjectRiskFactorAuditAreas?.map(({ ProjectRiskFactorAuditAreaId, ProjectScopeAuditAreaId, ProjectRiskFactorAuditAreaSCOTABDs }) => {
      return ({
        id: ProjectRiskFactorAuditAreaId,
        auditArea: Array.isArray(ProjectScopeAuditAreaId) ? ProjectScopeAuditAreaId : [ProjectScopeAuditAreaId] ?? [],
        scotabds: ProjectRiskFactorAuditAreaSCOTABDs.map(item => item.ProjectScopeAuditAreaSCOTABDId) ?? []
      })
    }) ?? [],
    inherentRiskAffectAccountingEstimate: IsRelatedToAccountingEstimate ? YES : NO,
    inherentRiskAffectSelectionAndApplicationMethod: isRiskFactorDegreeHighBoolean ? (IsRiskFactorDegreeHigh ? HIGHER : LOWER) : '',
    inherentRiskAffectPointEstimate: isDegreeofManagementPointEstimateHighBoolean ? (IsDegreeofManagementPointEstimateHigh ? HIGHER : LOWER) : '',
  }

  const validationSchema = Yup.object().shape({
    riskFactorAssociation: Yup.array().min(1),
    riskFactorName: stringRequiredSchema,
    riskFactorWorkpaperReference: workpaperReferenceEnabled ? stringRequiredSchema : undefined,
    riskFactor: Yup.array().min(1),
    eventsOrConditions: Yup.array().min(1),
    inherentRiskAffectAccountingEstimate: stringRequiredSchema,
    inherentRiskAffectSelectionAndApplicationMethod: Yup.string().when("inherentRiskAffectAccountingEstimate", {
      is: YES,
      then: stringRequiredSchema
    }),
    inherentRiskAffectPointEstimate: Yup.string().when("inherentRiskAffectAccountingEstimate", {
      is: YES,
      then: stringRequiredSchema
    }),
    auditAreaScotabdsList: Yup.array().of(
      Yup.object().shape({
        auditArea: Yup.array().min(1),
        scotabds: Yup.array().min(1)
      })
    )
  });

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  });
  const { isValid, isSubmitting } = formMethods.formState;
  const { fields } = useFieldArray({ control: formMethods.control, name: "auditAreaScotabdsList" });

  const watchRiskFactorAssociation = useWatch({ control: formMethods.control, name: "riskFactorAssociation" });
  const watchInherentRiskAffectAccountingEstimate = useWatch({ control: formMethods.control, name: "inherentRiskAffectAccountingEstimate" });

  const generateAuditAreaPayload = auditAreaScotabdsList => {
    const result = []
    auditAreaScotabdsList.forEach(({ id, auditArea, scotabds }) => {
      if (!isEdit) {
        result.push({
          ProjectScopeAuditAreaId: auditArea[0],
          ProjectRiskFactorAuditAreaSCOTABDIds: scotabds
        })
      } else {
        const processScotabds = (scotabsList, ProjectRiskFactorAuditAreaSCOTABDs) =>
          scotabsList.map((scotabdId) => {
            const commonProps = { ProjectScopeAuditAreaSCOTABDId: scotabdId }
            if (ProjectRiskFactorAuditAreaSCOTABDs) {
              const scotabd = ProjectRiskFactorAuditAreaSCOTABDs.find(item => item.ProjectScopeAuditAreaSCOTABDId === scotabdId)

              return {
                ProjectRiskFactorAuditAreaSCOTABDId: (scotabd)
                  ? scotabd.ProjectRiskFactorAuditAreaSCOTABDId
                  : null,
                ...commonProps
              }
            }

            return {
              ProjectRiskFactorAuditAreaSCOTABDId: null,
              ...commonProps
            }
          })

        const existingAuditArea = ProjectRiskFactorAuditAreas.find(auditArea => auditArea.ProjectRiskFactorAuditAreaId === id)
        const commonProps = {
          ProjectRiskFactorAuditAreaSCOTABDs: processScotabds(scotabds, existingAuditArea?.ProjectRiskFactorAuditAreaSCOTABDs)
        }

        if (id) {
          const {
            ProjectRiskFactorAuditAreaId,
            ProjectScopeAuditAreaId,
          } = existingAuditArea
          result.push({
            ProjectRiskFactorAuditAreaId,
            ProjectScopeAuditAreaId,
            ...commonProps
          })
        } else {
          result.push({
            ProjectRiskFactorAuditAreaId: null,
            ProjectScopeAuditAreaId: auditArea[0],
            ...commonProps
          })
        }
      }
    })
    return result;
  }

  const generateEventsOrConditions = eventsOrConditions => {
    return eventsOrConditions.map(EventConditionName => {
      const record = ProjectRiskFactorEventConditions?.find(eventCondition => eventCondition.EventConditionName === EventConditionName)
      const recordFromMethodologyEventConditions = methodologyEventConditions.find(eventCondition => eventCondition.EventConditionName === EventConditionName)
      const baseResult = (isEdit) ? { ProjectRiskFactorEventConditionId: null } : {}
      if (record) return record
      return (recordFromMethodologyEventConditions)
        ? {
          ...baseResult,
          EventConditionName: recordFromMethodologyEventConditions?.EventConditionName,
          EventConditionId: recordFromMethodologyEventConditions?.EventConditionId,
        }
        : {
          ...baseResult,
          EventConditionName,
          EventConditionId: null,
        }
    })
  }

  const generateProjectRiskFactorRiskFactor = riskFactorIds => riskFactorIds.map(RiskFactorId => {
    if (!isEdit) {
      return {
        ProjectRiskFactorRiskFactorId: null,
        ProjectRiskFactorId: null,
        RiskFactorId
      }
    }
    const existingProjectRiskFactorRiskFactor =
      ProjectRiskFactorRiskFactor.find(item => item.RiskFactorId === RiskFactorId)

    return (existingProjectRiskFactorRiskFactor)
      ? existingProjectRiskFactorRiskFactor
      : {
        ProjectRiskFactorRiskFactorId: null,
        RiskFactorId,
        ProjectRiskFactorId
      }
  })

  const generateRiskFactorSummaries = riskFactorSummaries => riskFactorSummaries.map(projectScopeId => {
    const existingRiskFactorSummary = ProjectScopeRiskFactorSummaries.find(({ ProjectScopeId }) => ProjectScopeId === projectScopeId)
    if (existingRiskFactorSummary) {
      const {
        ProjectScopeRiskFactorSummaryId,
        ProjectScopeId
      } = existingRiskFactorSummary

      return {
        ProjectScopeRiskFactorSummaryId,
        ProjectScopeId
      }
    }

    return {
      ProjectScopeRiskFactorSummaryId: null,
      ProjectScopeId: projectScopeId
    }
  })

  const onSubmit = async (values) => {
    const newEventConditions = values && values.eventsOrConditions.filter(item => item); // removed falsy value produced by onBlur event
    const commonProperties = {
      IsRelatedToAccountingEstimate: (values.inherentRiskAffectAccountingEstimate === "Yes"),
      IsRiskFactorDegreeHigh: (values.inherentRiskAffectSelectionAndApplicationMethod !== "") && (values.inherentRiskAffectAccountingEstimate === "Yes")
        ? !!(values.inherentRiskAffectSelectionAndApplicationMethod === "Higher")
        : null,
      IsDegreeOfManagementPointEstimateHigh: (values.inherentRiskAffectPointEstimate !== "") && (values.inherentRiskAffectAccountingEstimate === "Yes")
        ? !!(values.inherentRiskAffectPointEstimate === "Higher")
        : null,
      Comment: null,
      WorkpaperReference: values.riskFactorWorkpaperReference || null,
      ProjectFormId: ProjectFormId || projectFormId || null,
      RiskFactorName: values.riskFactorName,
      ProjectRiskFactorAuditAreas: generateAuditAreaPayload(values.auditAreaScotabdsList),
      ProjectRiskFactorEventConditions: generateEventsOrConditions(newEventConditions),
      ProjectRiskFactorRiskFactor: generateProjectRiskFactorRiskFactor(values?.riskFactor)
    }
    if (riskFactorNames.includes(values.riskFactorName.trim().toLowerCase())) {
      setIsRiskFactorNameUnique(false)
      return
    }
    setIsRiskFactorNameUnique(true)

    try {
      if (isEdit) {
        const updatePayload = {
          ...commonProperties,
          ProjectRiskFactorId,
          ProjectScopeRiskFactorSummaries: generateRiskFactorSummaries(values.riskFactorAssociation)
        }

        await updateMutation.mutateAsync(updatePayload);
      }
      else {
        const insertPayload = {
          ...commonProperties,
          ProjectScopeIds: values.riskFactorAssociation
        }

        await createMutation.mutateAsync(insertPayload)
      }
    } catch (error) {
      toast.error(error.message.toString());
    }
    finally {
      setVisible(false);
      formMethods.reset(initialValues);
    }
  }

  const handleRiskFactorAssociationChange = (risks) => {
    const numberOfRows = fields.length === 0 ? 1 : fields.length // At least one row should be left with default value
    const auditAreaScotabdsRow = [...Array(numberOfRows).keys()].map(() => {
      return { auditArea: [], scotabds: [] }
    });
    formMethods.setValue("riskFactorAssociation", risks);
    formMethods.setValue('auditAreaScotabdsList', auditAreaScotabdsRow);
  }

  const currentProjectFormId = (ProjectFormId ?? projectFormId) ?? 0
  const isAddedRiskFromProjectForm = !!currentProjectFormId;
  const { data: auditAreaResult } = useAuditAreas(projectId, watchRiskFactorAssociation);
  const { data: riskAssessmentResult } = useGetRiskAssessmentOptions(projectId, currentProjectFormId, isAddedRiskFromProjectForm);
  
  const createAuditAreaOptions = (auditAreaData) => {
    return auditAreaData.map((value) => ({
      value: Array.isArray(value.ProjectScopeAuditAreaId) ? value.ProjectScopeAuditAreaId : [value.ProjectScopeAuditAreaId],
      label: value.AuditAreaName
    }));
  };

  const createRiskAssessmentOptions = (riskAssessmentData) => {
    return riskAssessmentData.map((value) => ({
      value: value.ProjectScopeId,
      label: value.ProjectFormUnits
    }));
  };

  const RadioGroupLabel = ({ children }) => (<div className={styles['radio-group-small-label']}>{children}</div>)

  const useStyles = makeStyles(() => ({
    noMinWidth: {
      minWidth: "0 !important"
    }
  }));

  const customClass = useStyles();

  // INITIALIZE DATA
  useEffect(() => {
    setRiskFactors(createRiskFactorOptions(methodologyRiskFactor))
  }, [methodologyRiskFactor])

  useEffect(() => {
    if (projectRiskFactorLoading || projectRiskFactorRefetching) return;
    setRiskFactorNames([])
    const names = projectRiskFactor.map(({ RiskFactorName }) => RiskFactorName.trim().toLowerCase())
    setRiskFactorNames(names)
  }, [projectRiskFactorLoading, projectRiskFactorRefetching]);

  useEffect(() => {
    if (!isRiskFactorNameUnique)
      setIsRiskFactorNameUnique(true)
  }, [visible])

  useEffect(() => {
    setEventsOrConditions(createEventConditionsOptions(methodologyEventConditions))
  }, [methodologyEventConditions])

  useEffect(() => {
    if (isEdit) {
      const filteredNames = riskFactorNames
        .filter(name => name !== RiskFactorName.trim().toLowerCase())
      setRiskFactorNames(filteredNames)
    }
    formMethods.reset(initialValues)
  }, [riskFactorData])

  useEffect(() => {
    if (!auditAreaResult || auditAreaResult.status !== 200) return;
    setAuditAreas(createAuditAreaOptions(auditAreaResult.data));
  }, [auditAreaResult]);

  useEffect(() => {
    if (!riskAssessmentResult || riskAssessmentResult.status !== 200) return;
    setRiskAssessmentOptions(createRiskAssessmentOptions(riskAssessmentResult.data));
  }, [riskAssessmentResult]);

  return (
    <Dialog open={visible} fullWidth scroll="body">
      <Form form={formMethods} handleSubmit={onSubmit}>
        <DialogTitle
          sx={{
            '&.MuiDialogTitle-root': {
              p: 4,
            },
          }}
        >
          <Typography variant="title">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={styles['dialog-title-adjustment']}>
              <div>{INHERENT_RISK_FACTORS_LABEL}</div>
              {!workpaperReferenceEnabled && (ProjectFormId || projectFormId) && (
                <div className={styles['dialog-title-info']}>
                  <div className={styles['form-info']}>
                    <div className={styles['form-info-label']}>
                      <InputLabel className={styles['name-input-label-title']}>{FORM}:</InputLabel>
                    </div>
                    <div className={styles['form-info-value']}>
                      <InputLabel className={styles['name-input-label']}>
                        {(formName.length <= 40) ? formName
                          : <CLATruncatedTextWithTooltip
                            text={formName}
                            length={40}
                          />
                        }
                      </InputLabel>
                    </div>
                  </div>
                </div>
              )}
            </Stack>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={styles['modal-content-outer-container']}>
            <Grid container rowSpacing={6} columnSpacing={2}>
              <Grid item xs={7}>
                <Controller
                  control={formMethods.control}
                  name="riskFactorAssociation"
                  render={({ field: { value } }) => {
                    return (
                      <CLASelect
                        name="riskFactorAssociation"
                        label={RISK_FACTOR_ASSOCIATION_LABEL}
                        onChange={handleRiskFactorAssociationChange}
                        defaultValues={value}
                        menuItems={riskAssessmentOptions}
                        truncateDisplay={true}
                      />
                    )
                  }} />
              </Grid>
              {(workpaperReferenceEnabled && (!ProjectFormId || !projectFormId)) && (
                <Grid item xs={12}>
                  <Controller
                    control={formMethods.control}
                    name="riskFactorWorkpaperReference"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CLATextField
                          name="riskFactorWorkpaperReference"
                          label={RISK_FACTOR_WORKPAPER_REFERENCE_LABEL}
                          placeholder={RISK_FACTOR_WORKPAPER_REFERENCE_PLACEHOLDER}
                          value={value}
                          onChange={onChange}
                          inputLableShrink
                        />
                      )
                    }} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={formMethods.control}
                  name="riskFactorName"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CLATextField
                        name="riskFactorName"
                        label={RISK_FACTOR_NAME_LABEL}
                        placeholder={RISK_FACTOR_NAME_PLACEHOLDER}
                        value={value}
                        onChange={onChange}
                        inputLableShrink
                        error={!isRiskFactorNameUnique}
                        helperText={!isRiskFactorNameUnique ? ERROR_MESSAGE : null}
                      />
                    )
                  }} />

              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={formMethods.control}
                  name="riskFactor"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CLASelect
                        name="riskFactor"
                        label={RISK_FACTOR}
                        onChange={onChange}
                        defaultValues={value}
                        menuItems={riskFactors}
                        placeHolder={SELECT}
                        truncateDisplay
                      />
                    )
                  }} />
              </Grid>
              <Grid item xs={9}>
                <Controller
                  control={formMethods.control}
                  name="eventsOrConditions"
                  render={({ field: { onChange, value, onBlur } }) => {
                    return (
                      <CLAComboBox
                        label={RISK_OF_MATERIAL_MISSTATEMENTS_LABEL}
                        onChange={onChange}
                        onBlur={onBlur}
                        options={eventsOrConditions}
                        defaultValue={value}
                        placeholder={SELECT}
                        allowMultiSelect
                      />
                    )
                  }} />
              </Grid>
              <Grid item xs={12} sx={{ pt: '16px !important' }}>
                <AddAnotherAuditArea
                  auditAreas={auditAreas}
                  ProjectScopeIds={watchRiskFactorAssociation}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  control={formMethods.control}
                  name="inherentRiskAffectAccountingEstimate"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CLARadioButtonList
                        name="inherentRiskAffectAccountingEstimate"
                        label={<RadioGroupLabel>{INHERENT_RISK_AFFECT_ACCOUNTING_ESTIMATE_LABEL}</RadioGroupLabel>}
                        onChange={onChange}
                        value={value}
                        options={[YES, NO]}
                        columns={2}
                        customClass={customClass}
                      />
                    )
                  }} />

              </Grid>
              <Grid item xs={2} />
              {watchInherentRiskAffectAccountingEstimate === YES && (
                <>
                  <Grid item xs={4}>
                    <Controller
                      control={formMethods.control}
                      name="inherentRiskAffectSelectionAndApplicationMethod"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CLARadioButtonList
                            name="inherentRiskAffectSelectionAndApplicationMethod"
                            label={<RadioGroupLabel>{INHERENT_RISK_AFFECT_SELECTION_AND_APPLICATION_METHOD_LABEL}</RadioGroupLabel>}
                            onChange={onChange}
                            value={value}
                            options={[HIGHER, LOWER]}
                            columns={3}
                            customClass={customClass}
                          />
                        )
                      }} />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3}>
                    <Controller
                      control={formMethods.control}
                      name="inherentRiskAffectPointEstimate"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CLARadioButtonList
                            name="inherentRiskAffectPointEstimate"
                            label={<RadioGroupLabel>{INHERENT_RISK_AFFECT_POINT_ESTIMATE}</RadioGroupLabel>}
                            onChange={onChange}
                            value={value}
                            options={[HIGHER, LOWER]}
                            columns={2}
                            customClass={customClass}
                          />
                        )
                      }} />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            '&.MuiDialogActions-root': {
              py: 4,
            },
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setVisible(false);
              formMethods.reset(initialValues)
            }}
            style={{ marginRight: '20px' }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            disabled={!isValid}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

AddRiskFactor.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  section: PropTypes.any,
  formName: PropTypes.string,
  riskFactorData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object]
  ),
  workpaperReferenceEnabled: PropTypes.bool,
  hideDialogTitleInfo: PropTypes.bool,
}

AddRiskFactor.defaultProps = {
  visible: false,
  section: '',
  formName: '',
  riskFactorData: [],
  workpaperReferenceEnabled: false,
  hideDialogTitleInfo: false,
}