import clsx from "clsx"
import styles from "./Label.module.css"

export const Label = ({ className, size, weight, ...other }) => {
    
    const componentClassName = clsx(
        styles["label"],
        size === "lg" && styles["label-lg"],
        weight === "medium" && styles["label-medium"],
        className
    )
    
    return (
        <label className={componentClassName} {...other} />
    )
}
