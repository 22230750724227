import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';

const CLACheckBoxField = (props) => {
  const {
    onChange,
    label,
    value,
    name,
    checked,
    defaultChecked,
    disabled,
    onBlur,
    onFocus,
  } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={onChange}
          name={name}
          value={value}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          />
        }
        label={label}
    />
  );
};

export default CLACheckBoxField;
