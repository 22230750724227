import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid, Button } from '@mui/material';

import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { VFCheckbox } from '@components/FormView';
import { useLoading, useUserList } from '@hooks/index';
import useUserProviderState from '@contexts/UserContext';
import { useUpdateEffect } from 'react-use';
import { formatUtcToLocalTimeZone } from '@utilities/dateHelpers';

import projectFormServices from '@services/forms/projectforms.js';

import styles from './FormView.module.css';

export const VFSignOff = ({
    isInstance,
    requiredSignOffLevel,
    projectFormId,
    signOffList,
    updateSignOffList,
    disabled,
}) => {
    const { projectId } = useParams();
    const [signOffUserList, setSignOffUserList] = useState([]);
    const [userInfoList, setUserInfoList] = useState([]);
    const { authUser, getUser } = useUserProviderState();
    const {getUsersById} = useUserList();
    const setLoading = useLoading();
    const signOffs = PROJECT_FORM_INSTANCE.EN.SIGN_OFFS;
    
    useUpdateEffect(async () => {
        const uniqueIds = [
            ...new Set(signOffList?.map((item) => item?.signOffUser)),
        ];
        if (uniqueIds) {
            setLoading(true);
            const users = await getUsersById(uniqueIds);
            setUserInfoList(users);
            setSignOffUserList(signOffList);
            setLoading(false);
        }
    }, [signOffList]);

    const addCurrentUserFullName = async () => {
        const { firstName, lastName, activeDirectoryId } = await getUser();
        const currentUser = { firstName, lastName, activeDirectoryId: activeDirectoryId?.toLowerCase() };
        setUserInfoList((prev) => {
            return prev ? [...prev, currentUser] : [currentUser];
        });
    };

    const handleSignOff = async (signOffLevel) => {
        if (!authUser || !isInstance) return;
        setLoading(true);
        try {
            const { status, data } = await projectFormServices.signOffProjectForm(
                projectId,
                projectFormId,
                signOffLevel,
                authUser?.localAccountId?.toUpperCase()
            );
            if (status === 201) {
                const user = userInfoList?.find(
                    (item) => item.activeDirectoryId.toLowerCase() === data.signOffUser.toLowerCase()
                );
                if (!user) await addCurrentUserFullName();
                const newValue = {
                    projectFormSignOffId: data.projectFormSignOffId,
                    projectFormId: data.projectFormId,
                    signOffLevel: data.signOffLevel,
                    signOffUser: data.signOffUser,
                    signOffDate: data.signOffDate,
                    signOffOrder: data.signOffOrder,
                };
                const newList = signOffUserList ? [...signOffUserList, newValue] : [newValue];
                updateSignOffList(newList);
            }
        } catch (error) {
            toast.error(error.toString());
        }
        setLoading(false);
    };

    const handleRemoveSignOff = async (projectFormSignOffId) => {
        if (!authUser || !isInstance) return;
        setLoading(true);
        try {
            const response = await projectFormServices.removeSignOffProjectFormById(projectId, projectFormSignOffId);
            if (response.status === 204) {
                const newList = signOffUserList.filter(item => item.projectFormSignOffId !== projectFormSignOffId);
                updateSignOffList(newList);
            }
            else toast.error(response.message);
        } catch (error) {
            toast.error(error.toString());
        }
        setLoading(false);
    };

    const displaySignOffDetails = (signOffUser) => {
        const user = userInfoList?.find(
            (item) => item.activeDirectoryId.toLowerCase() === signOffUser.signOffUser.toLowerCase()
        );
        const employeeId = user?.employeeId;
        return `${user?.firstName} ${user?.lastName[0]}. ${
            employeeId ? `(${employeeId})` : ''
        } (${formatUtcToLocalTimeZone(signOffUser.signOffDate)})`;
    };    

    const displaySignOff = (signOff, index) => {
        const users = isInstance ? signOffUserList.filter(
            (signOffUser) => signOffUser.signOffLevel === signOff.level
        ) : null;
        return (
            <Grid key={index} item>
                <Grid container gap={2} mt={2} direction="column">
                    {users?.length > 0 &&
                        users.map((signOffUser, index) => {
                            return (
                                <Grid key={index} item>
                                    <VFCheckbox
                                        name={signOff.name}
                                        label={signOff.label}
                                        value={true}
                                        disabled={disabled}
                                        hidden={
                                            authUser?.localAccountId?.toLowerCase() !==
                                            signOffUser.signOffUser.toLowerCase()
                                        }
                                        onChange={() => authUser?.localAccountId?.toLowerCase() ===
                                            signOffUser.signOffUser.toLowerCase() ? handleRemoveSignOff(signOffUser.projectFormSignOffId) : null}
                                    />
                                    <span className={styles['vf-signoff-info']}>
                                        {displaySignOffDetails(signOffUser)}
                                    </span>
                                </Grid>
                            );
                        })}
                    {(!users ||
                        users?.length === 0) && (
                            <Grid item>
                                <VFCheckbox
                                    name={signOff.name}
                                    label={signOff.label}
                                    disabled={disabled}
                                    onChange={() => handleSignOff(signOff.level)}
                                />
                                {signOff.level > requiredSignOffLevel && (
                                    <span className={styles['vf-signoff-optional']}>
                                        ({PROJECT_FORM_INSTANCE.EN.OPTIONAL})
                                    </span>
                                )}
                            </Grid>
                        )}
                    {isInstance && (
                        <Grid item>
                            <Button
                                variant="form-view-add-sign-off"
                                disabled={!users || users?.length === 0 || users?.length > 9 || disabled}
                                onClick={() => handleSignOff(signOff.level)}
                            >
                                <span>+</span>{PROJECT_FORM_INSTANCE.EN.BUTTONS.ADD_MY_SIGN_OFF}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    };
    return (
        <>
            <div className={styles['vf-conclusion']}>
                {PROJECT_FORM_INSTANCE.EN.CONCLUSIONS}
            </div>
            <Grid direction="row" container gap={12} mt={2}>
                {signOffs.map((signOff, i) => displaySignOff(signOff, i))}
            </Grid>
        </>
    );
};
