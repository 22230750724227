import { useState, useEffect, useCallback } from 'react'; 
import { useParams } from 'react-router-dom';

import { Select, Box, Chip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import formServices from '@services/forms/forms';
import { useCLAInitialScopingFramework } from './useCLAInitialScopingFramework';
import { CLAFrameworkChangeModal } from './CLAFrameworkChangeModal';
import { useProjectScope } from '../CLAProjectScopeAuditArea/hooks/useProjectScope';
import clsx from 'clsx';
import styles from '@views/ProjectScopingForm/CLAInitialScopingConsiderations/CLAInitialScopingConsiderations.module.css'
import { useRoomIdle } from "@components/Concurrency/provider/RoomProvider";

const menuItemStyle = {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
};

const FrameworksDropdown = (props) => {
    const { methodologyVersionId, projectFormId, disabled, onFocus, onClose, signOffList } = props;

    const [projectScopeId, setProjectScopeId] = useState(); 
    const [selectOpen, setSelectOpen] = useState(false);
    const displayEmpty = true;
    const placeholder = 'Select';

    const { 
        frameworks: fromConcurrencyFrameworks,
        frameworksHighlight,
    } = useProjectScope();

    const { projectId } = useParams();
    const {
        handleFrameworkChange,
        handleFrameworkClose,
        handleFrameworkOpen,
        onConfirmFrameworkWarningModal,
        frameworks,
        isFrameworksLoading,
        isFrameworkModalOpen,
        selectedFramework,
        auditAreaFrameworkData,
        hasLoadingAuditAreaFrameworkData,
        setIsFrameworkModalOpen,
        associatedFrameworkData, 
        handleFrameworks,
        isFrameworkHighlighted,
        handleExistingFrameworks
    } = useCLAInitialScopingFramework({
        projectFormId,
        projectScopeId,
        methodologyVersionId,
        signOffList
    }); 

    const [previousSelectedWork, setPreviousSelectedFramework] = useState(selectedFramework);

    const isIdle = useRoomIdle();

    const retrieveProjectScopeInformation = async () => {
        const projectScopeInfo = await formServices.getProjectScopeByProjectFormId(projectFormId, projectId);
        setProjectScopeId(projectScopeInfo?.data?.ProjectScopeId);
    }; 

    useEffect(() => {
        if (!projectScopeId) {
            retrieveProjectScopeInformation();
        }
    }, []);

    useEffect(() => {
        handleExistingFrameworks(fromConcurrencyFrameworks);

        const frameworkIds = fromConcurrencyFrameworks.map(f => f.FrameworkId)
        if (frameworkIds?.length !== 0) {  
            if(typeof frameworkIds[0] === 'number'){ 
                handleFrameworks(frameworkIds);
            } 
            // NOTE: Commented for now, we still need to determine what scenario can fall on this condition
            // else { 
            //     handleFrameworks(fromConcurrencyFrameworks.map(framework => framework.FrameworkId));
            // }
        }
    }, [fromConcurrencyFrameworks]);

    const getKey = useCallback((frameworkId) => {
        const result = selectedFramework.some(value => value === frameworkId);
        return result;
    }, [selectedFramework])

    useEffect(() => {
        if(isIdle && selectOpen){
            handleFrameworks(previousSelectedWork);
            setSelectOpen(false);
        }
    }, [isIdle]);

    return (
        <>
        <Box className={clsx(isFrameworkHighlighted && styles.DropdownSelectContainerHighlighted)}>
            <FormControl fullWidth>
                <InputLabel id="financialReportingFrameworks" shrink sx={{ backgroundColor: 'white' }}>What financial reporting frameworks apply to this project?</InputLabel>
                <Select
                    labelId="financialReportingFrameworks"
                    id="financialReportingFrameworksDropdown"
                    value={selectedFramework}
                    label="What financial reporting frameworks apply to this project?"
                    multiple 
                    open = {selectOpen}
                    onOpen={() => {
                        setPreviousSelectedFramework(selectedFramework)
                        setSelectOpen(true);
                        handleFrameworkOpen()
                        onFocus()
                    }}
                    onClose={async ()=> {
                        setSelectOpen(false);
                        onClose()
                        await handleFrameworkClose()
                    }} 
                    onChange={handleFrameworkChange}
                    displayEmpty={displayEmpty}
                    disabled={disabled}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                                const frameworkInfo = frameworks.find(x => x.FrameworkId === value);
                                return <Chip key={value} label={frameworkInfo.FrameworkName} />
                            })}
                        </Box>
                    )}
                >
                    {displayEmpty && (
                        <MenuItem disabled={true} value="" sx={menuItemStyle}>
                            {placeholder}
                        </MenuItem>
                    )}
                    {!isFrameworksLoading && frameworks.map((option, index) => {
                        let frameworkId = option?.hasOwnProperty('FrameworkId') ? option.FrameworkId : option;
                        return(
                            <MenuItem
                                key={`${index}-${getKey(frameworkId)}`}
                                value={frameworkId}
                                sx={menuItemStyle}
                            >
                                {option?.hasOwnProperty('FrameworkName') ? option.FrameworkName : option}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
            <CLAFrameworkChangeModal
                isFrameworkModalOpen={isFrameworkModalOpen}
                auditAreaFrameworkData={auditAreaFrameworkData}
                hasLoadingAuditAreaFrameworkData={hasLoadingAuditAreaFrameworkData}
                setIsFrameworkModalOpen={setIsFrameworkModalOpen}
                associatedFrameworkData={associatedFrameworkData}
                onConfirmFrameworkWarningModal={onConfirmFrameworkWarningModal}
            />
        </>
    );
}

export default FrameworksDropdown;
