import { useContext } from 'react'
import { InternalControlsContext } from '../../../contexts/InternalControls/InternalControlsContext';

const useICF = () => {
  const {
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    answerList,
    setAnswerList,
    comment,
    setComment,
    setBroadcastedEventData
  } = useContext(InternalControlsContext)

  const updateNonCustomFieldAnswer = (questionId, answer) => {
    setAnswerList((prev) => ({ ...prev, [questionId]: answer }));
  }

  const setDataOnBroadcastEvent = broadcastData => setBroadcastedEventData(broadcastData)

  return {
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    answerList,
    setAnswerList,
    updateNonCustomFieldAnswer,
    comment,
    setComment,
    setDataOnBroadcastEvent
  }
}

export default useICF