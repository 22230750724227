import {
  createField,
  dropNewItem,
  filterEmptyCriterias,
  updateFormItem,
} from '@components/Forms/helpers';
import { DIALOG, TRIGGERS } from '@constants/forms';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@ais/palette";
import { validateAST } from '@utilities/expressionEvaluator';
import { useContext, useEffect } from 'react';
import { SchemaContext } from '@ais/providers';
import { BaseDialog } from './BaseDialog';
import { RulesTab } from './dialog-components/rules';
import { FormulaSettings } from './dialog-components/settings';

export const FormulaDialog = ({
  additionalData,
  trigger,
  visible,
  setVisible,  
}) => {
  const { LABEL } = DIALOG.SETTINGS;
  const { schema, dispatchSchema } = useContext(SchemaContext);

  const initialValues = {
    label: additionalData?.label ?? '',
    required: additionalData?.required ?? true,
    tooltip: additionalData?.tooltip ?? '',
    width: additionalData?.width ?? 100,
    formula: additionalData?.formula ?? [],
    displayFormula: additionalData?.displayFormula ?? false,
    validation: additionalData?.validation ?? 'numerical',
    decimalPlaces: additionalData?.decimalPlaces ?? 0,
    rules:
      typeof additionalData?.rules === 'object'
        ? additionalData.rules
        : {
            behavior: 'show',
            match: 'any',
            criterias: [],
            excludeAnswerOnRollForward: false,
            canNotBeDeleted: true,
          },
    visibleToClient: additionalData.hasOwnProperty('visibleToClient')
      ? additionalData.visibleToClient
      : true,
    editableByClient: additionalData.hasOwnProperty('editableByClient')
      ? additionalData.editableByClient
      : true,
  };

  const schemaValidation = Yup.object().shape({
    label: Yup.string().trim().required(''),
    formula: Yup.array()
      .test('invalidFormula', ' ', function (value) {
        try {
          validateAST(value);
          return true;
        } catch (e) {
          return this.createError({ message: e });
        }
      })
  })

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation)
  });

  const handleSubmit = (values) => {
    const filteredRules = filterEmptyCriterias(values.rules);
    if (trigger !== TRIGGERS.EDIT_BTN) {
      const newField = createField({
        ...values,
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.refField.index,
        label: values.label,
        required: values.required,
        formula: values.formula,
        displayFormula: values.displayFormula,
        tooltip: values.tooltip,
        validation: values.validation,
        decimalPlaces: values.decimalPlaces,
        width: +values.width,
        rules: filteredRules,
      });
      dropNewItem(additionalData, newField, trigger, schema, dispatchSchema);
    } else {
      const updateField = createField({
        ...values,
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.index,
        label: values.label,
        required: values.required,
        formula: values.formula,
        displayFormula: values.displayFormula,
        tooltip: values.tooltip,
        validation: values.validation,
        decimalPlaces: values.decimalPlaces,
        width: +values.width,
        rules: filteredRules,
      });
      updateFormItem(additionalData, updateField, schema, dispatchSchema);
    }
    setVisible(false);
  }

  useEffect(() => {
    formMethods.reset(initialValues);
    formMethods.trigger();
  }, [visible]);

  const tabs = [
    {
      label: LABEL.SETTINGS,
      render: () => <FormulaSettings />,
    },
    {
      label: LABEL.RULES,
      render: () => (
        <RulesTab
          additionalData={additionalData}
          trigger={trigger}
        />
      ),
    },
  ];

  return (
    <Form form={formMethods}>
      <BaseDialog
        id={additionalData?.id}
        idPrefix={LABEL.QUESTION_ID}
        title={LABEL.FORMULA}
        visible={visible}
        setVisible={setVisible}
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        tabs={tabs}
      />
    </Form>
  );
};
