import { useContext, useEffect } from "react"
import { ApplicationContext } from "@contexts/ApplicationContext";
import { APPLICATION_ACTION } from "@constants/index";

export const useDisabledGlobalQueryLoading = (enable = true) => {
    const { dispatchApplication } = useContext(ApplicationContext);

    useEffect(() => {
        if(!!enable) {
            dispatchApplication({
                type: APPLICATION_ACTION.TURN_OFF_GLOBAL_QUERY_LOADING,
            });
        } 
        
        return () => {
            dispatchApplication({
                type: APPLICATION_ACTION.TURN_ON_GLOBAL_QUERY_LOADING,
            });
        }
    }, [enable])
}
