export const auditAreaTableStyles = {
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    fontWeight: '700',
    fontSize: '1.18em',
    fontFamily: 'museo-sans, sans-serif',
    lineHeight: '19.2px',
    backgroundColor: '#F6F6F6',
    outline: 'none'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
    border: 'none'
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    outline: 'none',
    border: 'none',
    paddingRight: 0
  },
  '&.MuiDataGrid-root .MuiDataGrid-detailPanelToggleCell ': {
    backgroundColor: '#f7f7f7'
  },
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'museo-sans, sans-serif',
    fontWeight: 700,
    fontSize: '1.35em',
    color: '#333333'
  },
  'button > svg': {
    fontSize: '10.5px'
  },
  mt: '30px',
  ml: '18px'
};

export const scotabdsTableStyles = {
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    fontWeight: 600,
    fontSize: '1.18em',
    fontFamily: 'museo-sans, sans-serif',
    lineHeight: '19.2px',
    backgroundColor: '#F6F6F6',
    outline: 'none'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
    border: 'none'
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    outline: 'none',
    paddingRight: 0
  },
  '&.MuiDataGrid-root .MuiDataGrid-detailPanelToggleCell ': {
    backgroundColor: '#f7f7f7'
  },
  ml: '60px',
  mr: '1px',
  '& .MuiDataGrid-cellContent': {
    fontFamily: 'museo-sans, sans-serif',
    fontWeight: 700,
    fontSize: '1.17em',
    color: '#333333'
  },
  'button > svg': {
    fontSize: '14.5px'
  }
};