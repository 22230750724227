import { useEffect, useContext, useRef } from 'react';
import { useMsal } from '@azure/msal-react'; 
import { useOtherUsersStore } from '@components/Concurrency/store/users';
import { useOthersListener, useUpdateMyPresence, useRoomIdle, useEventListener } from '@components/Concurrency/provider/RoomProvider';
import { TailorProcedureContext } from '@components/Forms/state';
import { TAILOR_PROCEDURE_REDUCER } from '@constants/forms';
import { isNil, omitBy } from 'lodash';
import { useProceduresContext } from '@contexts/Procedures';

export default function useConcurrencyProcedureListeners(sectionId) {
    const { dispatchTailorProcedures } = useContext(TailorProcedureContext);
    const { setProjectFormProcedureComponentData, projectFormProcedureComponentData } = useProceduresContext();
    
    // Use a ref to keep track of the latest projectFormProcedureComponentData
    // This is because useEventListener is actually a callback, and it will only get the value of projectFormProcedureComponentData
    // the moment it is rendered (a stale closure). This ref will keep track of the latest value of projectFormProcedureComponentData
    const projectFormProcedureComponentDataRef = useRef(null);

    useEffect(() => {
        projectFormProcedureComponentDataRef.current = projectFormProcedureComponentData;
    }, [projectFormProcedureComponentData])

    const append = useOtherUsersStore((state) => state.append)
    const remove = useOtherUsersStore((state) => state.remove)
    const updateUserPresence = useOtherUsersStore((state) => state.updateUserPresence)
    
    const isIdle = useRoomIdle();
    const updateMyPresence = useUpdateMyPresence();
    
    useOthersListener(({ type, sessionId, data }) => {
        switch (type) {
            case "enter":
                append({ sessionId, ...data })
                return;
            case "leave":
                remove(sessionId)
                return;
            case "presence":
                updateUserPresence(sessionId, data)
                return;
            default:
                console.warn(`Invalid event type: `, type)
        }
    })

    useEventListener(({ type, data }) => {
        if (type !== 'updates') return;
        
        if ('procedureStep' in data.answer) {
            updateSummaryProcedureStep(data.answer.procedureStep)
            return
        }

        if ('identifiedRisk' in data.answer) {
            updateIdentifiedRisk(data.answer.identifiedRisk, parseInt(data.id))
            return;
        }

        if ('customProcedure' in data.answer) {
            updateCustomProcedure(data.answer.customProcedure)
            return;
        }

        if ('customProcedureRiskList' in data.answer) {
            updateCustomProcedureIdentifiedRisk(data.answer.customProcedureRiskList, parseInt(data.id));
            return;
        }

        if ('clientSituationAnswer' in data.answer) {
            updateClientSituationAnswer(data.id, data.answer.clientSituationAnswer);
        }
        
        if ('procedures' in data.answer) {
            realignProcedureFromConcurrency(data.answer.procedures[0].SummaryProcedures, data.answer.procedures[0].CustomProcedures)
            return;
        }
    })

    const updateSummaryProcedureStep = (procedureStep) => {
        const {
            signOffDate,
            signOffUser,
            workpaperReference,
            comment,
            summaryProcedureStepId,
            summaryProcedureId
        } = procedureStep

        const summaryProcedureStep = omitBy({
            SummaryProcedureId: Number.parseInt(summaryProcedureId),
            SummaryProcedureStepId: Number.parseInt(summaryProcedureStepId),
            SignOffDate: signOffDate,
            SignOffUser: signOffUser,
            WorkpaperReference: workpaperReference,
            Comment: comment,
        }, isNil);

        if(signOffUser === null) {
            // If signoff user is null, it means it's been removed from the backend
            summaryProcedureStep.SignOffUser = null;
            summaryProcedureStep.SignOffDate = null;
        }

        const payload = {
            summaryProcedureStep,
            sectionId
        };

        dispatchTailorProcedures({
            type: TAILOR_PROCEDURE_REDUCER.UPDATE_STEP_PROCEDURE_CONTENT,
            payload
        });
    }

    const updateIdentifiedRisk = (identifiedRisk, summaryProcedureId) => {
        const payload = {
            identifiedRisk,
            summaryProcedureId,
            sectionId,
        };

        dispatchTailorProcedures({
            type: TAILOR_PROCEDURE_REDUCER.UPDATE_PROJECT_RISK_SUMMARY_PROCEDURE,
            payload
        });
    }

    const updateCustomProcedure = (customProcedureObject) => {
        const {
            projectFormCustomProcedureId,
            customProcedureName,
            description,
            signOffUser,
            signOffDate,
            workPaperReference,
            comment
        } = customProcedureObject;

        const customProcedure = omitBy({
            ProjectFormCustomProcedureId: Number.parseInt(projectFormCustomProcedureId),
            CustomProcedureName: customProcedureName,
            Description: description,
            SignOffUser: signOffUser,
            SignOffDate: signOffDate,
            WorkpaperReference: workPaperReference,
            Comment: comment,
        }, isNil)

        if(signOffUser === null) {
            // If signoff user is null, it means it's been removed from the backend
            customProcedure.SignOffUser = null;
            customProcedure.SignOffDate = null;
        }
        
        const payload = {
            customProcedure,
            sectionId
        };

        dispatchTailorProcedures({
            type: TAILOR_PROCEDURE_REDUCER.UPDATE_CUSTOM_PROCEDURE_CONTENT,
            payload
        });
    }

    const updateCustomProcedureIdentifiedRisk = (customProcedureRiskList, projectFormCustomProcedureId) => {
        const payload = {
            customProcedureRiskList,
            projectFormCustomProcedureId,
            sectionId
        };

        dispatchTailorProcedures({
            type: TAILOR_PROCEDURE_REDUCER.UPDATE_PROJECT_RISK_CUSTOM_PROCEDURE,
            payload
        });
    }

    const updateClientSituationAnswer = (id, clientSituationAnswer) => {
        // Check if ProcedureComponentId is the same because 
        // If two or more ProcedureComponent is in the page, it will update all of it without this
        if(id !== projectFormProcedureComponentDataRef?.current[0]?.ProcedureComponentId) return;

        setProjectFormProcedureComponentData([clientSituationAnswer])
        return;   
    }

    const realignProcedureFromConcurrency = (summaryProcedures, customProcedures) => {
        const payload = {
            summaryProcedures,
            customProcedures,
            sectionId
        };

        dispatchTailorProcedures({
            type: TAILOR_PROCEDURE_REDUCER.REALIGN_PROCEDURE_FROM_CONCURRENCY,
            payload
        });
    }

    useEffect(() => {
        if(!isIdle) return;

        dispatchTailorProcedures({
            type: TAILOR_PROCEDURE_REDUCER.RESET_STATE,
        });
        setProjectFormProcedureComponentData(structuredClone(projectFormProcedureComponentData));
        updateMyPresence({ focusedId: null })

        if(!(document.activeElement instanceof HTMLElement)) return 

        document.activeElement.blur();
    }, [isIdle])
}

