export const CustomHistoryReducer = (state, action) => {
  let newState = { ...state };
  switch (action.type) {
    case 'SET_LAST_PAGE': {
      newState.lastPage = action.payload;
      return newState;
    }
    case 'RESET_LAST_PAGE': {
      newState.lastPage = '';
      return newState;
    }
    default: {
      return newState;
    }
  }
};
