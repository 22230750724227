import * as Yup from 'yup';

export const MINIMUM_DUE_DATE = new Date('12/31/2000');

export const CREATE_PROJECT_NAV_LINK_TEXT = {
    CLIENT_AND_PROJECT: "Client & Project",
    REPORTS: "Reports",
    UNITS: "Units",
    FORMS: "Forms"
}

export const CREATE_PROJECT = {
    EN: {
        HEADER: {
            CLIENT_PROJECT: 'Client & Project',
            REPORTS: 'Reports',
            UNITS: 'Units',
            TRIAL_BALANCES: 'Trial Balances',
            FORMS: 'Assign Forms to Reports & Units',
            FORMS_HEADER: 'Forms',
            CREATE_PROJECT_TITLE: 'Create a New Project',
            PROJECT_SETUP_TITLE: 'Project Setup',
            WARNING: 'Warning',
            SUCCESS_TITLE: "Congratulations",
        },
        BUTTON: {
            SAVE_PROJECT: "Save Project",
            ADD_ANOTHER_REPORT: "ADD ANOTHER REPORT",
            ADD_ANOTHER_UNIT: "ADD ANOTHER UNIT",
            ADD_FORM_FROM_LIBRARY: 'Add a form from library',
            SAVE: 'SAVE',
            CANCEL: 'CANCEL',
            BACK_TO_AIS: "Back to AIS Dashboard",
            GO_TO_PROJECT_DASHBOARD: "Go to Project Dashboard",
        },
        NAV_LINK: [
            { text: CREATE_PROJECT_NAV_LINK_TEXT.CLIENT_AND_PROJECT, step: 1, resource: "client-and-project" },
            { text: CREATE_PROJECT_NAV_LINK_TEXT.REPORTS, step: 2, resource: "reports" },
            { text: CREATE_PROJECT_NAV_LINK_TEXT.UNITS, step: 3, resource: "units" },
            { text: CREATE_PROJECT_NAV_LINK_TEXT.FORMS, step: 4, resource: "forms" },
        ],
        FORM_CONTEXT_MENU: {
            RENAME: 'Rename',
            DUPLICATE: 'Duplicate',
            DELETE: 'Delete',
            SELECT_UNSELECT: 'Select/Unselect All Units',
        },
        SUCCESS_MESSAGE_TOP: "You have successfully setup your project.",
        SUCCESS_MESSAGE_BOT: "As a reminder, the Project Scoping Form should be completed first.",
        CLIENT: "Client",
        CLIENT_PLACE_HOLDER: "Search for Client",
        PROJECT: "Project",
        PROJECT_PLACE_HOLDER: "Select Project",
        METHODOLOGY_VERSION: "Methodology Version",
        METHODOLOGY_VERSION_PLACE_HOLDER: "Select Methodology Version",
        REPORT_TITLE: 'Report Title',
        REPORT_TITLE_PLACEHOLDER: 'Report Title here..',
        PRIMARY_INDUSTRY: 'Primary Industry',
        PRIMARY_INDUSTRY_PLACEHOLDER: 'Select Primary Industry',
        DUE_DATE:'Due Date',
        UNIT_TITLE: "Unit Title",
        UNIT_TITLE_PLACEHOLDER: "Unit Title here..",
        ASSIGN_TO_REPORTS: "Assign to Reports",
        ASSIGN_TO_REPORTS_PLACEHOLDER: "Select",
        SECONDARY_INDUSTRY: "Secondary Industry",
        SECONDARY_INDUSTRY_PLACEHOLDER: "Select (Optional)",
        BACK_WARNING: "Are you sure you want to go back? All changes will be lost.",
        DELETE_WARNING: "Unit will be permanently deleted. Are you sure?",
        BACK_SAVE_WARNING: 'Click save to save all changes and go back to the previous page. Click cancel to remain on this page and resume editing.',
        UNIT_TITLE_REQUIRED: "Please input a Unit Title",
        UNIT_REPORT_REQUIRED: "Please select at least one Report",
        UNIT_TITLE_EXISTS: "Unit Title already exists",
        FORM_DELETE_WARNING: "Are you sure you want to delete this form? It will permanently delete the data.",
        ADD_FORM_LIST: {
            TITLE: "All Forms Library",
            SEARCH_PLACEHOLDER: "Search/Filter Forms",
            MODAL: {
                INPUT_TOP_LABEL: "Form Name",
                INPUT_ERROR: 'Invalid Form Name',
                WARNING: "A form already exists with that name, please create a unique name.",
            }
        },
        DATA_HEADER_TITLE: {
            PROJECT_STATE: 'Project State',
            PROJECT_TYPE: 'Project Type',
            PERIOD_END_DATE: 'Period End Date',
            PROJECT_INDUSTRY: 'Project Industry',
            METHODOLOGY_VERSION: 'Methodology Version',
        }
    },
    DATA_HEADER: {
        CLIENT_NAME: 'ClientName',
        APP_DATA_INSTANCE_NAME: 'AppDataInstanceName',
        PROJECT_TYPE: 'ProjectType',
        PROJECT_STATE: 'ProjectState',
        PROJECT_PERIOD_END_DATE: 'ProjectPeriodEndDate',
        CLC_INDUSTRY_NAME: 'CLCIndustryName',
        METHODOLOGY_VERSION: 'MethodologyVersion',
    },
};

export const CREATE_PROJECT_FORM_CONTEXT_MENU_LIST_ITEMS = [
    CREATE_PROJECT.EN.FORM_CONTEXT_MENU.RENAME,
    CREATE_PROJECT.EN.FORM_CONTEXT_MENU.DUPLICATE,
    CREATE_PROJECT.EN.FORM_CONTEXT_MENU.DELETE,
    CREATE_PROJECT.EN.FORM_CONTEXT_MENU.SELECT_UNSELECT
]; 

export const CREATE_PROJECT_DATA_HEADER_ARRANGEMENT = {
    ClientName: 'ClientName',
    AppDataInstanceName: 'AppDataInstanceName',
    ProjectType: 'ProjectType',
    ProjectState: 'ProjectState',
    ProjectPeriodEndDate: 'ProjectPeriodEndDate',
    CLCIndustryName: 'CLCIndustryName',
    MethodologyVersion: 'MethodologyVersion',
}

export const MENU_ITEMS_TOOLTIP = {
    TITLE: "This Project has already been created."
}