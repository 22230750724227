import React, {
    useState,
    useEffect
} from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'
import { TextField } from '@mui/material';
import { useRoomIdle } from '@components/Concurrency/provider/RoomProvider';
import { DateIcon } from "@ais/assets";

const CommunicationOfDeficienciesDatepicker = (props) => {
    const { row, handleLockingField, handleDatePickerChange, handleCellChange, disabled } = props;
    const [deficiencyDate, setDeficiencyDate] = useState(row?.CommunicationDate)
    const [open, setOpen] = useState(false);
    const isIdle = useRoomIdle(); 
    const dateIcon = () => {
        return (
            <img src={DateIcon} style={{ "marginRight": "5px" }} alt='CLA Date icon' />
        )
    }

    useEffect(() => {
        setDeficiencyDate(row?.CommunicationDate);
    }, [row?.CommunicationDate]);

    useEffect(() => {
        if (deficiencyDate !== row?.CommunicationDate) {
            setDeficiencyDate(row?.CommunicationDate);
        }
    }, [row?.CommunicationDate]);

    useEffect(() => {
        setDeficiencyDate(row?.CommunicationDate);
        setOpen(false)
    }, [isIdle]);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={deficiencyDate}
                    PopperProps={{
                        placement: 'bottom-end'
                    }}
                    open={open}
                    onOpen={() => {
                        handleLockingField(true)
                        setOpen(true)
                    }}
                    onChange={(date) => {  
                        setDeficiencyDate(dayjs(date).format('MM/DD/YYYY'))
                    }}
                    onAccept={(date) => {
                        const parsedDate = dayjs(date).format('MM/DD/YYYY')
                        setDeficiencyDate(parsedDate)
                        handleDatePickerChange(parsedDate)
                        handleCellChange('CommunicationDate', parsedDate)
                        handleLockingField(false)
                    }}
                    onClose={() => {
                        handleLockingField(false)
                        setOpen(false)
                    }}
                    components={{
                        OpenPickerIcon: dateIcon
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            placeholder='Enter Date'
                            InputLabelProps={{
                                shrink: true,
                                variant: 'multiline-label'
                            }}
                            fullWidth
                            onBlur={() => {
                                handleDatePickerChange(deficiencyDate)
                                handleLockingField(false)
                            }}
                            onFocus={() => { handleLockingField(true) }}
                        />}
                    disabled={disabled}
                />
            </LocalizationProvider>
        </>
    );
}

export default CommunicationOfDeficienciesDatepicker;