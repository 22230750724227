import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const getLocalTimeZone = () => {
  return Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).resolvedOptions().timeZone;
};

export const formatUtcToLocalTimeZone = (date = '', dateFormat = 'P') => {
  // ? `T` means it is in UTC format.
  if (date.includes('T')) {
    // ? Data from SQL is `datetime` column so `Z` is omitted which is needed for UTC format.
    // ? Suggestion: Save UTC in a `string` column instead to retain the full UTC format.
    const timeZone = getLocalTimeZone();
    const lastLetter = date.slice(-1);
    let utcDate = '';
    if (lastLetter === 'Z') utcDate = date;
    else utcDate = date + 'Z';
    return formatInTimeZone(utcDate, timeZone, dateFormat);
  } else return format(new Date(date), dateFormat);
};

export const getFormattedSignOffDate = (date = new Date().toISOString()) => {
  return date.slice(0,21).replace('T', ' ');
}

export const getMaxDateBeforeNextDay = (dateTime) => new Date(dateTime).toLocaleString('sv').slice(0,10) + ' 23:59:59.000';


export const replaceDateHyphensToSlashesAndFormat = (date, dateFormat) => {
  //Encountered an issue where using "new Date()" with dates with hyphens eg: 2023-12-12 
  // will cause the date to be off by 1 day, replacing the hyphens with slashes will fix the issue
  // visit https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off for more info 
		if(typeof date === 'string'){
      const convertedDate = new Date(date.replace(/-/g, '\/').replace(/T.+/, ''))
			return {
        formattedDate: format(convertedDate, dateFormat),
        dateObject: convertedDate,
        isDateString: true
      }
		}
		return {
      formattedDate: format(date, dateFormat),
      dateObject: date,
      isDateString: false
    }
	}

export const sortSignOffListAsc = (list) =>
  list.sort((a, b) =>
    new Date(a.signOffDate).getTime() > new Date(b.signOffDate).getTime()
      ? 1
      : -1
  );

export const sortReviewerSignoffAsc = (signOffList) => {  //does not include preparer
  let reviewerSignOffs = []
  const sortSignOff = sortSignOffListAsc(signOffList || []);
  if (sortSignOff && sortSignOff?.length > 0) {
    reviewerSignOffs = sortSignOff.filter(
      (signOff) => signOff.signOffLevel !== 1
    );
  }
  return reviewerSignOffs
}

export const getLatestAddedData = (data) => {
  data.sort((a, b) =>
  new Date(a.ValidFrom).getTime() > new Date(b.ValidFrom).getTime()
    ? 1
    : -1
);
  return new Date(data[data?.length - 1]?.ValidFrom).getTime()
}

export const getLatestDeletedData = (data) => {
  data.sort((a, b) =>
  new Date(a.ValidTo).getTime() > new Date(b.ValidTo).getTime()
    ? 1
    : -1
  );
  return new Date(data[data?.length - 1]?.ValidTo).getTime()
}

export const getLocalDate = (dateTime) => {
  const date = new Date(dateTime);
  return date.toLocaleDateString('en-US');
}
