import { useEffect, useState } from "react"
import { RULES } from '@ais/constants';
const useRules = (rules, answers, methodologyIndustries, frameworks, performanceStandards) => {

    const [shouldDisplay, setShouldDisplay] = useState(true)

    useEffect(() => {
        const criterias = rules?.criterias;
        const results = criterias?.reduce(evaluateCriteria, []);
        let rulesMatching = false;
        if (results?.length) {
            const isBehaviorShow = rules?.behavior === RULES.BEHAVIOR.SHOW;
            if (rules?.match === RULES.MATCH.ANY) {
                rulesMatching = results.some((result) => result === true);
            } else if (rules?.match === RULES.MATCH.ALL) {
                rulesMatching = results.every((result) => result === true);
            }
            setShouldDisplay(isBehaviorShow ? rulesMatching : !rulesMatching);
        } else {
            setShouldDisplay(true);
        }

    }, [rules, answers, methodologyIndustries])

    const evaluateCriteria = (result, criteria) => {
        const { criteriaType, questionId, any, isEqual, value } = criteria;
        switch (criteriaType) {
            case RULES.CRITERIA_TYPES.QUESTION:
                const answer = answers[questionId.toLowerCase()] ?? '';
                const answerValue = Array.isArray(answer)
                    ? answer.filter((value) => value !== undefined && value !== '')
                    : [answer].filter((value) => value !== undefined && value !== '');
                    if (any) {
                    result.push(!!answer)
                } else {
                    switch (isEqual) {
                        case RULES.EQUALITY.IS:
                            result.push(answerValue?.length === value?.length && answerValue.every((_formValue) => value.includes(_formValue)));
                            break;
                        case RULES.EQUALITY.IS_NOT:
                            result.push(!answerValue.every((_formValue) => value.includes(_formValue)));
                            break;
                        case RULES.EQUALITY.ANY:
                            result.push(answerValue.some((_formValue) => value.includes(_formValue)));
                            break;
                    }
                }
                break;
            case RULES.CRITERIA_TYPES.INDUSTRY:
                switch (isEqual) {
                    case RULES.EQUALITY.IS:
                        result.push(value?.every(value => methodologyIndustries?.some(meth => meth.MethodologyIndustryId === value)));
                        break;
                    case RULES.EQUALITY.IS_NOT:
                        result.push(!methodologyIndustries?.some((mi) => value?.includes(mi.MethodologyIndustryId)));
                        break;
                    case RULES.EQUALITY.ANY:
                        result.push(methodologyIndustries?.some((mi) => value?.includes(mi.MethodologyIndustryId)));
                        break;
                }
                break;
            case RULES.CRITERIA_TYPES.REPORTING_FRAMEWORK:
                switch (isEqual) {
                    case RULES.EQUALITY.IS:
                        result.push(value?.every((f) => frameworks?.includes(f)));
                        break;
                    case RULES.EQUALITY.IS_NOT:
                        result.push(!frameworks?.some((f) => value?.includes(f)));
                        break;
                    case RULES.EQUALITY.ANY:
                        result.push(frameworks?.some((f) => value?.includes(f)));
                        break;
                }
                break;
            case RULES.CRITERIA_TYPES.PERFORMANCE_STANDARDS:
                switch (isEqual) {
                    case RULES.EQUALITY.IS:
                        result.push(value?.every((ps) => performanceStandards?.includes(ps)));
                        break;
                    case RULES.EQUALITY.IS_NOT:
                        result.push(!performanceStandards?.some((ps) => value?.includes(ps)));
                        break;
                    case RULES.EQUALITY.ANY:
                        result.push(performanceStandards?.some((ps) => value?.includes(ps)));
                        break;
                }
                break;                
        }
        return result;
    }

    return { shouldDisplay };
}

export default useRules