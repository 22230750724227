import { useState } from 'react';
import {
  OutlinedInput,
  IconButton,
  SvgIcon,
  FormControl,
  FormControlLabel,
  InputLabel,
  Grid,
  Radio
} from '@mui/material';

import { DIALOG } from '@constants/forms';
import {CLADropdown} from "@ais/forms";
import TextFieldTags from './TextFieldTags';
import { ReactComponent as Delete } from '@assets/delete_icon.svg';
import { ReactComponent as ArrowUp } from '@assets/form_arrow_up.svg';
import { ReactComponent as ArrowDown } from '@assets/form_arrow_down.svg';
const validationOptions = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Numerical',
    value: 'numerical',
  },
  {
    label: 'US Currency',
    value: 'usCurrency',
  },
  {
    label: 'Percentage',
    value: 'percentage',
  },
];

const fieldTypes = [
  {
    label: 'Free Text',
    value: 'freeText',
  },
  {
    label: 'Short Answer',
    value: 'shortAnswer',
  },
  {
    label: 'Radio Button',
    value: 'radioButton',
    properties: [{ name: 'radioOptions', value: ['', ''] }, { name: 'radioDefaultValue', value: false }]
  },
  {
    label: 'Dropdown',
    value: 'dropdown',
    properties: [{ name: 'dropdownOptions', value: [] }, { name: 'dropdownDefaultValue', value: '' }]
  },
  {
    label: 'DatePicker',
    value: 'datepicker',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
    properties: [{ name: 'checkboxCheckedByDefault', value: true }]
  },
  {
    label: 'Formula',
    value: 'formula',
  }
];

const disabledItems = [
  'freeText',
  'formula'
]


const ColumnBuilderRow = (props) => {
  const { id, column,index,columnsLength, onRowChange, onRowDelete, handleMoveUp, handleMoveDown } = props;

  const [properties, setProperties] = useState({
    ...column,
    validation: column?.hasOwnProperty('validation')
      ? column.validation
      : undefined,
    dropdownOptions: column?.hasOwnProperty('dropdownOptions')
      ? column.dropdownOptions
      : undefined,
    dropdownDefaultValue: column?.hasOwnProperty('dropdownDefaultValue')
      ? column.dropdownDefaultValue
      : undefined,
    radioOptions: column?.hasOwnProperty('radioOptions')
      ? column.radioOptions
      : undefined,
    radioDefaultValue: column?.hasOwnProperty('radioDefaultValue')
      ? column.radioDefaultValue
      : undefined,
    checkboxCheckedByDefault: column?.hasOwnProperty('checkboxCheckedByDefault')
      ? column.checkboxCheckedByDefault
      : true,
  });

  const handleChange = (propertyName, value) => {
    const updatedValues = propertyName === 'columnFieldType' && value === 'checkbox' ?
      { ...column, [propertyName]: value, checkboxCheckedByDefault: true } :
      {
        ...column,
        [propertyName]: value,
      };
    setProperties((prevState) => {

      const copy = propertyName === 'columnFieldType' && value === 'checkbox' ?
        { ...prevState, [propertyName]: value, checkboxCheckedByDefault: true } :
        { ...prevState, [propertyName]: value };
      return copy;
    });
    onRowChange(id, updatedValues);
  };

  const initializeProperties = (value) => {
    const fieldType = fieldTypes.find((fieldType) => fieldType.value === value);
    if (fieldType.properties) {
      fieldType.properties.map((property) => {
        setProperties((prevState) => {
          const copy = { ...prevState, [property.name]: property.value };
          return copy;
        });
      });
    }
  }

  const renderProperties = () => {
    switch (properties.columnFieldType) {
      case 'shortAnswer':
        return (
          <Grid item xs={5} p={1}>
            <CLADropdown
              label={DIALOG.COLUMN_BUILDER.VALIDATION}
              options={validationOptions}
              value={properties.validation}
              onChange={(e) => {
                handleChange('validation', e.target.value);
              }}
            />
          </Grid>
        );
      case 'dropdown':
        return (<>
          <Grid item xs={5} p={1}>
            <TextFieldTags
              label={"Dropdown options"}
              placeholder={"Options"}
              options={properties.dropdownOptions}
              onChange={(values) => {
                handleChange('dropdownOptions', values);
              }}
            />
          </Grid>
          <Grid item xs={5} p={1}>
            <CLADropdown
              label={"Default Value"}
              options={properties.dropdownOptions}
              value={properties.dropdownDefaultValue}
              onChange={(e) => {
                handleChange('dropdownDefaultValue', e.target.value);
              }}
            />
          </Grid>
        </>)
      case 'radioButton':
        return (<>
          <Grid item xs={4} sx={{'margin-left': '10px'}}>
            <FormControl fullWidth>
              <InputLabel shrink>{DIALOG.COLUMN_BUILDER.OPTION_1}</InputLabel>
              <OutlinedInput
                label={'Option 1'}
                onChange={(e) => {
                  const secondOptionValue = properties.radioOptions[1];
                  const updatedValues = [e.target.value, secondOptionValue];
                  handleChange('radioOptions', updatedValues);
                }}
                value={properties?.radioOptions[0]}
                notched
              />
            </FormControl>
          </Grid>
          <Grid sx={{ 'padding-left': '2px' }}>
            <FormControlLabel
              value="top"
              control={
                <Radio
                  checked={properties.radioDefaultValue === properties.radioOptions[0]}
                  value={properties.radioOptions[0] ?? 'First'}
                  disabled={properties.radioOptions[0] === ''}
                  onChange={(e) => { handleChange('radioDefaultValue', e.target.value); }}
                  size="small" />
              }
              label="Default"
              labelPlacement="top"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel shrink>{DIALOG.COLUMN_BUILDER.OPTION_2}</InputLabel>
              <OutlinedInput
                label={'Option 2'}
                onChange={(e) => {
                  const firstOptionValue = properties.radioOptions[0];
                  const updatedValues = [firstOptionValue, e.target.value]
                  handleChange('radioOptions', updatedValues);
                }}
                value={properties?.radioOptions[1]}
                notched
              />
            </FormControl>
          </Grid>
          <Grid sx={{ 'padding-left': '2px', 'margin-right': '15px' }}>
            <FormControlLabel
              sx={{
                fontSize: 13
              }}
              value="top"
              control={
                <Radio
                  checked={properties.radioDefaultValue === properties.radioOptions[1]}
                  value={properties.radioOptions[1] ?? 'Second'}
                  disabled={properties.radioOptions[1] === ''}
                  onChange={(e) => { handleChange('radioDefaultValue', e.target.value); }}
                  size="small" />
              }
              label="Default"
              labelPlacement="top"
            />
          </Grid>
        </>)
      case 'checkbox':
        return (
          <Grid item container columnSpacing={4} rowSpacing={2}>
            <Grid item xs={5}>
              {DIALOG.COLUMN_BUILDER.CHECKBOX_NOTE}
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Radio
                    checked={properties.checkboxCheckedByDefault}
                    onClick={() =>
                      handleChange(
                        'checkboxCheckedByDefault',
                        !properties.checkboxCheckedByDefault
                      )
                    }
                    size="small"
                  />
                }
                label="Checked by default?"
              />
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };
  
  let margin = properties.columnFieldType ? {marginTop: '30px'} : {}
  return (
    <>    
      <Grid item sx={{display: 'flex', flexDirection: 'column', ...margin }} xs={1}>
        <IconButton
          onClick={() => handleMoveUp(index)}
          disabled={index===0}
          sx={{
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <SvgIcon sx={{ fontSize: '30px' }}>
            <ArrowUp />
          </SvgIcon>
        </IconButton>
        <IconButton
         onClick={() => handleMoveDown(index)}
         disabled={index === columnsLength-1}
          sx={{
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <SvgIcon sx={{ fontSize: '30px' }}>
            <ArrowDown />
          </SvgIcon>
        </IconButton>
      </Grid>
    <Grid xs={11} sx={{display: 'flex', flexDirection: 'column'}}>
      <Grid sx={{display: 'flex', flexDirection: 'row', paddingBottom: '10px', paddingTop: '10px'}}>
      <Grid item p={1} xs={5}>
        <FormControl fullWidth>
          <InputLabel shrink>{DIALOG.COLUMN_BUILDER.COLUMN_LABEL}</InputLabel>
          <OutlinedInput
            label={DIALOG.COLUMN_BUILDER.COLUMN_LABEL}
            onChange={(e) => {
              handleChange('columnLabel', e.target.value);
            }}
            value={properties.columnLabel}
            notched
          />
        </FormControl>
      </Grid>
      <Grid item p={1} xs={5}>
        <CLADropdown
          label={DIALOG.COLUMN_BUILDER.FIELD_TYPE}
          options={fieldTypes}
          disabledValues={disabledItems}
          value={properties.columnFieldType}
          onChange={(e) => {
            initializeProperties(e.target.value);
            handleChange('columnFieldType', e.target.value);
          }}
        />
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => onRowDelete(id)}
          sx={{
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <SvgIcon sx={{ fontSize: '30px' }}>
            <Delete />
          </SvgIcon>
        </IconButton>
      </Grid>
      </Grid>
      <Grid sx={{display: 'flex', flexDirection: 'row',  paddingBottom: '10px', paddingTop: '10px'}}>
      {renderProperties()}
      </Grid>
      </Grid>
    </>
  );
};

export default ColumnBuilderRow;
