// constants
import { FUNCTIONAL_RIGHT_ACTIONS } from "@ais/constants";

export const initialState = {
    authUser: {},
    userGroups: [],
    functionalRights: [],
    userAccount: null,
    employeeId: "",
    email: null,
    isUserGroupFetch: false
}

const UserReducer = (state, action) => {
    const { type, payload } = action;
    let newState = { ...state };

    switch (type) {
        case FUNCTIONAL_RIGHT_ACTIONS.GET_USER_INFO:
            return {
                ...newState,
                authUser: payload.authUser,
                userGroups: payload.userGroups,
                functionalRights: payload.functionalRights,
                employeeId: payload.employeeId,
                email: payload.email,
                isUserGroupFetch: payload.isUserGroupFetch
            }
        case 'ADD_AISPERSON_DETAILS': 
            return {
                ...newState,
                userAccount: payload
            }
    }
}

export default UserReducer;