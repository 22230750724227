import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// hooks
import useUserProviderState from "@contexts/UserContext";
import {FUNCTIONAL_RIGHTS_GROUP} from '@ais/constants';
import { ROUTE_PATH } from '@constants/index';

const RequireFunctionalGroupCLAStandard = (props) => {
    const { isUserGroupFetch, userGroups } = useUserProviderState();
    const navigate = useNavigate();

    useEffect(() => {
        if (isUserGroupFetch && userGroups) {
            const withCLAStandard = userGroups?.find(group=> group.FunctionalRightsGroupId === FUNCTIONAL_RIGHTS_GROUP.CLA_Standard);
            if (!withCLAStandard) {
                navigate(`/${ROUTE_PATH.noaccess}`);
            }
        }
    },[isUserGroupFetch, userGroups, navigate]);

    return (!isUserGroupFetch) ? <></> : <>{props.children}</>;
}

export default RequireFunctionalGroupCLAStandard