import { useState, useEffect } from 'react';
import { useFormContext } from "react-hook-form"
import { Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DefaultValueRow from './DefaultValueRow';
import AddIcon from '@mui/icons-material/Add';

import {FIELD_TYPES} from '@ais/constants';
import clientServices from '@services/client';
import formServices from '@services/forms/forms';
import { colors } from '@ais/theme';
import { convertStringToArray } from '@components/Forms/helpers';
import { DIALOG } from '@constants/forms';

import styles from './DefaultValueDrawer.module.css';

import { useLoading } from '@hooks/index';

const DefaultValues = props => {
  const { onChange, fieldType } = props;
  const { ADD_NEW } = DIALOG.DEFAULT_ANSWERS.GENERAL;

  const [defaultValues, setDefaultValues] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [usedIndustries, setUsedIndustries] = useState([]);
  const [formMethodology, setFormMethodology] = useState();

  const setLoading = useLoading();

  const { getValues } = useFormContext();

  const generateNewDefaultValue = () => ({
    id: uuidv4(),
    defaultValue: '',
    industries: [],
  });

  const addDefaultValue = () => {
    const newDefaultValue = generateNewDefaultValue();
    setDefaultValues(prevState => {
      const copy = [...prevState];
      copy.push(newDefaultValue);
      return copy;
    });
  };

  const fetchIndustries = async () => {
    setLoading(true);
    const { data } = await clientServices.getIndustriesByMethodologyVersionId(formMethodology);
    const industries = data.map(industry => {
      const { industryName } = industry;
      return industryName;
    });

    setIndustryOptions(industries);
    setLoading(false);
  };

  const getDisabledIndustries = (defaultValueItem, usedIndustries) => {
    const disabledIndustries = [];
    usedIndustries.forEach(i => {
      if (defaultValueItem.industries.indexOf(i) < 0) {
        disabledIndustries.push(i);
      }
    });

    return disabledIndustries;
  };

  const getDisabledValues = (defaultValue, defaultValues) => {
    const value = defaultValue.defaultValue;
    const values = defaultValues.map(d => d.defaultValue);
    if (fieldType === FIELD_TYPES.RADIOGROUP) {
      const disabledValues = [];
      values.forEach(v => {
        if (v !== value) {
          disabledValues.push(v);
        }
      });

      return disabledValues;
    }

    return [];
  };

  const getAllUsedIndustries = selectedIndustries =>
    selectedIndustries.flatMap(item => {
      return item?.industries.map(industry => ({
        id: item.id,
        value: industry,
      }));
    });

  const deleteDefaultValue = id => {
    if (defaultValues.length > 1) {
      const filtered = defaultValues?.filter(
        defaultValues => defaultValues.id !== id
      );
      setDefaultValues([...filtered]);
      setUsedIndustries(getAllUsedIndustries([...filtered]));
    } else setDefaultValues([generateNewDefaultValue()]);
  };

  const populateMethodology = async () => {
    const formRef = window.location.pathname.split('/');
    const formData = await formServices.getFormById(formRef[formRef.length - 1]);

    const { methodologyVersionId } = formData;
    setFormMethodology(methodologyVersionId);
  };

  useEffect(() => {
    populateMethodology();
  }, []);

  useEffect(() => {
    if (formMethodology) { 
      fetchIndustries();
      if (getValues('defaultValues').length) {
        setDefaultValues(getValues('defaultValues'));
        setUsedIndustries(getAllUsedIndustries(getValues('defaultValues')));
      } else {
        setDefaultValues([generateNewDefaultValue()]);
      }
    }
  }, [formMethodology]);

  const handleRowChange = (id, updatedValue) => {
    const updatedValues = defaultValues.map(item => {
      return item.id === id ? updatedValue : item;
    });
    setDefaultValues(updatedValues);
    const usedindustries = updatedValues.flatMap(item => {
      return item?.industries.map(industry => ({
        id: item.id,
        value: industry,
      }));
    });
    setUsedIndustries(usedindustries);
  };

  useEffect(() => {
    onChange(defaultValues);
  }, [defaultValues]);

  const allIndustrySelected = industryOptions.length <= usedIndustries.length;

  const getInputComponentData = () => {
    switch (fieldType) {
      case FIELD_TYPES.RADIOGROUP:
      case FIELD_TYPES.CHECKBOXGROUP:
      case FIELD_TYPES.DROPDOWN: {
        return {
          options: convertStringToArray(getValues('options')),
        };
      }
      default:
        return {};
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {defaultValues.map(defaultValue => (
          <div key={defaultValue.id}>
            <DefaultValueRow
              id={defaultValue.id}
              fieldType={fieldType}
              defaultValueItem={defaultValue}
              industries={industryOptions}
              validation={getValues('validation')}
              onRowChange={handleRowChange}
              onRowDelete={deleteDefaultValue}
              inputComponentData={getInputComponentData()}
              disabledIndustries={
                getValues('allowMultiSelect')
                  ? []
                  : getDisabledIndustries(
                    defaultValue,
                    usedIndustries.map(i => i.value)
                  )
              }
              allowMultiSelectDefaultValue={getValues('allowMultiSelect')}
              disabledValues={getDisabledValues(defaultValue, defaultValues)}
            />
          </div>
        ))}
      </div>
      <div className={styles['def-row-add-button']}>
        <Button
          onClick={addDefaultValue}
          disabled={allIndustrySelected}
          startIcon={
            <AddIcon sx={{ fontSize: '12px', color: colors.riptide[500] }} />
          }
          sx={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: colors.charcoal,
          }}
        >
          {ADD_NEW}
        </Button>
      </div>
    </>
  );
};

export default DefaultValues;
