import { useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import { ButtonGroup, Button } from '@mui/material';

import { DRAG_TYPES, SCHEMA_ACTION_TYPES } from '@ais/constants';
import { SchemaContext } from '@ais/providers';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { ReactComponent as Pencil } from '@assets/form_edit.svg';
import { DeleteIconComponent } from '@ais/assets';
import { AddQuestion } from './actions';
import projectFormServices from '@services/forms/projectforms';

import styles from './FormView.module.css';

export const VFDraggableInstanceField = (props) => {
  const {
    field,
    handleBlur,
    renderField,
    projectFormId,
    section,
    sectionIndex,
    rowIndex,
    handleDeleteField,
    shouldHighlight,
    onLinkClick,
  } = props;
  const { projectId } = useParams();
  const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
  const { schema, dispatchSchema } = useContext(SchemaContext);

  const [visible, setVisible] = useState(false);

  const dragDropRef = useRef(null);

  const applyClass = () => {
    let classList = [styles['rendered-field'], styles['edit-mode']];
    if (onLinkClick) classList.push(styles['with-link']);
    if (shouldHighlight === HIGHLIGHT_BORDER.RELATED) classList.push(styles['highlight-related']);
    else if (shouldHighlight === HIGHLIGHT_BORDER.MODIFIED) classList.push(styles['highlight-modified']);
    return classList.join(' ');
  };

  const saveFields = async (newRowIndex, fieldToSave) => {
    try {
      await projectFormServices.updateQuestionToProjectFormSchema(
        projectId,
        projectFormId,
        section.id,
        newRowIndex,
        fieldToSave
      );
    } catch (error) {
      // * Show an error here
    }
  };

  const [, drop] = useDrop({
    accept: DRAG_TYPES.RENDERED_FIELD,
    hover: (item, _) => {
      if (!dragDropRef.current) return;
      const dragIndex = item.rowIndex;
      const hoverIndex = rowIndex;
      if (dragIndex === hoverIndex) return;
      const fields = [...schema?.properties[sectionIndex]?.fields];
      const dragged = fields[dragIndex];
      fields.splice(dragIndex, 1);
      fields.splice(hoverIndex, 0, dragged);
      saveFields(hoverIndex, dragged[0]);
      dispatchSchema({
        type: SCHEMA_ACTION_TYPES.UPDATE_SECTION_FIELDS,
        index: sectionIndex,
        payload: fields,
      });
      item.rowIndex = hoverIndex;
    }
  });

  const [, drag] = useDrag({
    type: DRAG_TYPES.RENDERED_FIELD,
    item: () => ({ rowIndex }),
  });

  drag(drop(dragDropRef));
  const sectionType = section?.sectionType ?? section?.type;
  return (
    <>
      <div
        onBlur={handleBlur}
        className={sectionType === DRAG_TYPES.SECTION_BLANK ? styles['vf-section-blank-wrapper'] : styles['vf-rendered-field-wrapper']}
        style={{ width: `${field.width}%` }}
      >
        <div ref={dragDropRef} className={applyClass()}>
          {renderField}
          <div className={styles['field-overlay']} style={{ cursor: 'move' }} />
        </div>
        <div className={styles['vf-rendered-field-buttons']}>
          <ButtonGroup sx={{ borderColor: 'gray' }} variant="outlined">
            <Button
              onClick={() => setVisible(true)}
              sx={{ borderColor: 'gray' }}
            >
              <Pencil />
            </Button>
            <Button sx={{ borderColor: 'gray' }} onClick={handleDeleteField}>
              <DeleteIconComponent />
            </Button>
          </ButtonGroup>
        </div>
        <AddQuestion
          section={section}
          projectFormId={projectFormId}
          visible={visible}
          setVisible={setVisible}
          additionalData={{ ...field, rowIndex }}
          editMode
        />
      </div>
    </>
  );
};
