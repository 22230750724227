import { useState, useCallback, useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions } from '@mui/material/';
import ReactQuill, { Quill } from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './CLAFinancialStatemetRichTextEditor.css';

const ExpandedTextFormatter = (props) => {
  const { open, onChange, onClose, initialState, modules, maxLength } = props;

  const quilRef = useRef(null);  
  const [richTextFormatterValue, setrichTextFormatterValue] = useState();

  const handleSave = useCallback(() => {
    onChange(richTextFormatterValue);
    onClose(false);
  }, [onChange, onClose, richTextFormatterValue]);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  useEffect(() => {
    setrichTextFormatterValue(initialState);
  }, [initialState]);
  const handleRichTextValueChange = (value) => {
      if(quilRef.current.getEditor().getText().length < maxLength)
        setrichTextFormatterValue(value)
      else quilRef.current.getEditor().deleteText(maxLength, quilRef.current.getEditor().getText().length - maxLength)
  }
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '560px',
          height: '546px',
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <DialogContent>
        <ReactQuill
          ref = {quilRef}
          className="rte-quill"
          modules={modules}
          value={richTextFormatterValue}
          theme="snow"
          onChange={handleRichTextValueChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disableElevation onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" disableElevation onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CLAFinancialStatemetRichTextEditor = (props) => {
  const { onChange, onBlur, value, placeholder, expandable, height, disabled, error, onFocus, maxLength, isIdle, unlockField} = props;

  const [quillvalue, setQuillValue] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const quilRef = useRef(null);  
  const defaultMaxLength = maxLength && typeof maxLength === 'number' ? maxLength : 4096;

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ]
  };

  const handleQuillChange = useCallback((value) => {
    setQuillValue(value);
    if (onChange) {
      onChange(value);
    }
  }, []);

  const handleBlur = (e) => {
    if (onBlur && isFocused) {
      onBlur(e, quillvalue);
      setIsFocused(false) 
    }
    unlockField()
  }

  const handleOnFocus = (event) => {
    setIsFocused(true) 
    onFocus && onFocus(event)
  }
  
  const handleTextAreaChange = useCallback(
    (value) => {
      if(quilRef.current.getEditor().getText().length < defaultMaxLength){
        setQuillValue(value);
        if (onChange) {
          onChange(value);
        }
      }
      else quilRef.current.getEditor().deleteText( defaultMaxLength, quilRef.current.getEditor().getText().length - defaultMaxLength )
    },
    [onChange]
  );

  useEffect(() => {
    setQuillValue(value);
  }, [value])
  useEffect(() => {
    if (isIdle) {
      handleBlur()
    }
  }, [isIdle])

  return (
    <>
      <div className="rte-container">
        <div className={error ? "rte-quill-container-error" : "rte-quill-container"} onBlur={handleBlur}>
          <ReactQuill
            ref = {quilRef}
            placeholder={placeholder}
            className="rte-quill"
            modules={modules}
            value={quillvalue}
            theme="snow"
            onChange={handleTextAreaChange}
            onFocus={handleOnFocus}
            readOnly={disabled}
            {...(height && { style: { height: height } })}
          />
        </div>
        <div>
          {expandable && !disabled && (
            <Button
              onClick={handleClickOpen}
              variant="text"
              sx={{
                fontFamily: 'museo-sans',
                textTransform: 'none',
                color: '#76D2D3',
                fontSize: '16px',
                p: 0,
                ':hover': {
                  backgroundColor: 'inherit',
                },
              }}
            >
              Expand
            </Button>
          )}
        </div>
      </div>
      <ExpandedTextFormatter
        open={open}
        onChange={handleQuillChange}
        onClose={handleClose}
        initialState={quillvalue}
        modules={modules}
        maxLength={defaultMaxLength}
      />
    </>
  );
};

CLAFinancialStatemetRichTextEditor.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  expandable: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool
};

CLAFinancialStatemetRichTextEditor.defaultProps = {
  value: '',
  placeholder: '',
  expandable: true,
  disabled: false,
  error: false,
};

export default CLAFinancialStatemetRichTextEditor;
