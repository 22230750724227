import React, { memo } from "react";
import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form"

import { NATGSelector } from "@components/NATGSelector/NATGSelector";
import styles from '@components/FormView/FormView.module.css';
import { useVFCanvasProvider, useProjectFormInstanceProvider } from '@providers';
import { FIELD_TYPES } from "@ais/constants";
import { TbLinkBaseField, NoValidationInput } from './TbLinkFields';

import CLAInitialScopingConsiderations from "../../CLAInitialScopingConsiderations/CLAInitialScopingConsiderations";
import PerformanceStandardsDropdown from "../../CLAInitialScopingConsiderations/PerformanceStandardDropdown";
import FrameworksDropdown from "../../CLAInitialScopingConsiderations/FrameworksDropdown";
import VFRenderedFieldWrapper from "@components/CustomForm/VFRenderedFieldWrapper";
import CLATrialBalanceSelect from '@views/ProjectScopingForm/CLATrialBalanceSelect/CLATrialBalanceSelect';
import CLAProjectScopeAuditArea from "../../CLAProjectScopeAuditArea/CLAProjectScopeAuditArea"
import { RiskCharacteristics } from "../../RiskCharacteristics/RiskCharacteristics";
import { useOthers } from "@components/Concurrency/store/users";
import { useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";

export const VFFields = memo((props) => {
    const {
        field,
        projectId,
        projectFormId,
        disabled: _disabled,
        isInstance,
        section,
        answerList,
        renderLink,
        getDefaultValue, 
        handleOnFocus,
        methodologyIndustries,
        methodologyVersionId,
        watchRadioList,
        procedureFilter,
        handleBlur,
        formTypeId,
        units,
        isIdle,
        project,
        answered, 
        signOffList
    } = props;
    const updateMyPresence = useUpdateMyPresence()
    const formCanvas = useFormContext();
    const {
        userId,
        isInitialScopingConsiderationExpanded, 
        isDeterminingSCOTABDSExpanded
    } = useProjectFormInstanceProvider();
    const { actions } = useVFCanvasProvider();
    const others = useOthers();
    const isLockedByUser = others.find((user) => user.presence.focusedId === field.id && user.info.userId.toLowerCase() !== userId.toLowerCase())
    const isTrialBalanceOrAuditScopingFieldLock = (ids) => { return !!others.find((user) => ids.includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase()) }
    const disabled = _disabled || field.linkType === 'Read Only' || isLockedByUser !== undefined;

    switch (field.type) {
        case FIELD_TYPES.TRIAL_BALANCE: {
            const defaultValue = getDefaultValue(true);
            const defaultArrayValue = Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : [];
            return (
                <Box
                    className={styles.field__wrapper} 
                >
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && projectId
                            ? (<CLATrialBalanceSelect
                                field={field}
                                formTypeId={formTypeId}
                                defaultValues={defaultArrayValue}
                                handCaptureInput={(userInput) => {
                                    formCanvas.setValue(field.id, userInput);
                                    actions.handleSubmit();
                                }}
                                onFocus={handleOnFocus}
                                isDisabled={_disabled}
                                project={project}
                                isPlanningAnalyticsForm={true}
                            />)
                            : (<div className={styles["vf-section-placeholder"]}> TRIAL BALANCE COMPONENT PLACEHOLDER  </div>)
                        }
                    </div>
                    {renderLink()}
                </Box>
            );
        }
        case FIELD_TYPES.PROJECT_SCOPE_TRIAL_BALANCE: {
            const defaultValue = getDefaultValue(true);
            const defaultArrayValue = Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : [];
            const { fields } = section;
            const projectScopingField = fields[0].find((field => field.type === FIELD_TYPES.PROJECT_SCOPE_AUDIT_AREA));
            const isLock = isTrialBalanceOrAuditScopingFieldLock([field.id, projectScopingField.id]);
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && isDeterminingSCOTABDSExpanded}
                >
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && projectId ? (
                            <CLATrialBalanceSelect
                                defaultValues={defaultArrayValue}
                                formTypeId={formTypeId}
                                handCaptureInput={(userInput) => {
                                    formCanvas.setValue(field.id, userInput);
                                    actions.handleSubmit();
                                }}
                                field={field}
                                isDisabled={_disabled || isLock}
                                project={project}
                                onFocus={handleOnFocus}
                                signOffList={signOffList}
                                highlightable={true}
                            />
                        ) : (
                            <div className={styles["vf-section-placeholder"]}>
                                Trial Balance
                            </div>
                        )}
                    </div>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.PROJECT_SCOPE_TEST_OPERATING_EFFECTIVENESS: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && isInitialScopingConsiderationExpanded}
                >
                    <div className={styles.field__wrapper}>
                        <div className={styles.field__wrapper_inner}>
                            {isInstance && projectId ? (
                                <CLAInitialScopingConsiderations
                                    fieldId={field.id}
                                    handCaptureInput={(userInput) => {
                                        formCanvas.setValue(field.id, userInput);
                                        if (isInstance) {
                                            actions.handleSubmit();
                                        }
                                    }}
                                    methodologyVersionId={methodologyVersionId}
                                    projectFormId={projectFormId}
                                    disabled={_disabled}
                                    onOpen={() => {
                                        handleOnFocus()
                                        updateMyPresence({ focusedId: field.id })
                                    }}
                                    onClose={() => {
                                        updateMyPresence({ focusedId: null })
                                    }}
                                    signOffList={signOffList}
                                    answerList={answerList}
                                />
                            ) : (
                                <div className={styles["vf-section-placeholder"]}>Audit Area</div>
                            )}
                        </div>
                        {renderLink()}
                    </div>
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.PERFORMANCE_STANDARDS: {

            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && isInitialScopingConsiderationExpanded}
                >
                    <div className={styles.field__wrapper}>
                        <div className={styles.field__wrapper_inner}>
                            {isInstance && projectId ? (
                                <PerformanceStandardsDropdown
                                    methodologyVersionId={methodologyVersionId}
                                    projectFormId={projectFormId}
                                    disabled={_disabled}
                                    onFocus={() => {
                                        handleOnFocus()
                                        updateMyPresence({ focusedId: field.id })
                                    }}
                                    onClose={() => {
                                        updateMyPresence({ focusedId: null })
                                    }}
                                    signOffList={signOffList}
                                />
                            ) : (
                                <div className={styles["vf-section-placeholder"]}>Audit Area</div>
                            )}
                        </div>
                        {renderLink()}
                    </div>
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.REPORTING_FRAMEWORKS: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && isInitialScopingConsiderationExpanded}
                >
                    <div className={styles.field__wrapper}>
                        <div className={styles.field__wrapper_inner}>
                            {isInstance && projectId ? (
                                <FrameworksDropdown
                                    methodologyVersionId={methodologyVersionId}
                                    projectFormId={projectFormId}
                                    disabled={_disabled}
                                    onFocus={() => {
                                        handleOnFocus()
                                        updateMyPresence({ focusedId: field.id })
                                    }}
                                    onClose={() => {
                                        updateMyPresence({ focusedId: null })
                                    }}
                                    signOffList={signOffList}
                                />
                            ) : null}
                        </div>
                        {renderLink()}
                    </div>
                </VFRenderedFieldWrapper>
            );
        } 
        case FIELD_TYPES.PROJECT_SCOPE_AUDIT_AREA: {
            if (section) {
                const { fields } = section;
                const trialBalanceField = fields[0].find((field => field.type === FIELD_TYPES.PROJECT_SCOPE_TRIAL_BALANCE));
                const isLock = isTrialBalanceOrAuditScopingFieldLock([field.id, trialBalanceField.id]);
                return (
                    <VFRenderedFieldWrapper
                        className={styles.field__wrapper}
                        isLockedByUser={isLockedByUser}
                        isLocked={isLockedByUser && isDeterminingSCOTABDSExpanded}
                    >
                        <div className={styles.field__wrapper}>
                            <div className={styles.field__wrapper_inner}>
                                {isInstance && projectId ? (
                                    <CLAProjectScopeAuditArea
                                        field={field} 
                                        methodologyIndustries={methodologyIndustries}
                                        trialBalanceField={trialBalanceField}
                                        disabled={_disabled || isLock} 
                                        signOffList={signOffList}
                                    />
                                ) : (
                                    <div className={styles["vf-section-placeholder"]}>Audit Area</div>
                                )}
                            </div>
                            {renderLink()}
                        </div>
                    </VFRenderedFieldWrapper>
                );
            } else {
                return null;
            }
        } 
        case FIELD_TYPES.CUSTOM_RISK_CHARACTERISTIC_SELECT_GROUP: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <RiskCharacteristics
                            projectFormId={projectFormId}
                            methodologyVersionId={methodologyVersionId}
                            methodologyIndustries={methodologyIndustries}
                            disabled={_disabled}
                            signOffList={signOffList}
                        />
                    </div>
                </div>
            )
        }
        default: {
            return (
                <NATGSelector
                    answered={answered}
                    answerList={answerList}
                    disabled={disabled}
                    field={field} 
                    formCanvas={formCanvas}
                    formTypeId={formTypeId}
                    isIdle={isIdle}
                    isInstance={isInstance}
                    isLockedByUser={isLockedByUser}
                    NoValidationInput={NoValidationInput}
                    procedureFilter={procedureFilter}
                    projectId={projectId}
                    section={section}
                    tbLinkBaseField={TbLinkBaseField}
                    units={units}
                    watchRadioList={watchRadioList}
                    getDefaultValue={getDefaultValue}
                    handleBlur={handleBlur}
                    handleOnFocus={handleOnFocus}
                    renderLink={renderLink}   
                />
            );
        }
    }
});
