import React, { useState, useEffect } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { FormulaConditionalRow } from './FormulaConditionalRow';
import update from 'immutability-helper';
import {DRAG_TYPES} from '@ais/constants';
import {FORMULA_CONDITIONS} from '@constants/forms';
import { v4 as uuid } from 'uuid';

export const FormulaConditionalCanvas = ({ 
    formula, 
    onChange, 
    invalid, 
    resetCanvas 
}) => {   
    const [formulaRows, setFormulaRows] = useState([]);
    const [activeRow, activateRow] = useState(-1);
    const { ELSE, ELSEIF, THEN, IF } = FORMULA_CONDITIONS;

    useEffect(() => {         
        const newFormulaRows = [[]]
        let currentConditionalIndex = 0;
        formula.forEach(item => {      
            if(item?.operator && [ELSE, ELSEIF, THEN, IF].includes(item.operator)){                       
                currentConditionalIndex++;
                newFormulaRows.push([])                    
                newFormulaRows[currentConditionalIndex].push(item);
            } else {
                newFormulaRows[currentConditionalIndex].push(item)                
            }
        });            
        
        newFormulaRows.shift();
        setFormulaRows(newFormulaRows)  
        activateRow(0)
    }, []);    

    useEffect(() => {   
        onChange([].concat(...formulaRows))
    }, [formulaRows])


    const onChangeFormulaRow = (formula, index) => {   
        setFormulaRows(prevItems => update(prevItems, {
            [index]: { $set: formula},
        }));        
    }

    const onAddFormulaConditions = (index, option) => {        
        if(index <=0) return;        
              
        if([ELSE, ELSEIF].includes(option)) {        
            setFormulaRows(prevItems => {                
                const newFormulaRow = [];
                prevItems.forEach((el, i) => {
                    if( index <= i ) return;                
                    return newFormulaRow.push(el);                
                })
                
                if(option === ELSE)
                    newFormulaRow.push([{
                        value: ":",
                        operator: ELSE,
                        itemType: DRAG_TYPES.FORMULA_OPERATOR,
                        id: uuid(),                            
                    }])

                if(option === ELSEIF) {
                    newFormulaRow.push([{
                        value: ":",
                        operator: ELSEIF,
                        itemType: DRAG_TYPES.FORMULA_OPERATOR,
                        id: uuid()
                    }],
                    [{
                        value: "?",
                        operator: THEN,
                        itemType: DRAG_TYPES.FORMULA_OPERATOR,
                        id: uuid()
                    }],
                    [{
                        value: ":",
                        operator: ELSE,
                        itemType: DRAG_TYPES.FORMULA_OPERATOR,
                        id: uuid(),                            
                    }])
                }                                
                return newFormulaRow;
            })

        }
    }
    
    const activateCondition = (index) => {           
        activateRow(index)
    }

    const showCloseButton = (formulaRow) => {
        return [ELSE, ELSEIF].includes(formulaRow[0]?.operator)
    }

    const removeCondition = (index) => {
        const updateData = (prevItems) => {
            let updatedData = update(prevItems, {
                $splice: [[index, prevItems.length - index]],
            });

            if (updatedData.length > 0) {
                updatedData = update( updatedData, {
                    $push: [[{
                        value: ":",
                        operator: ELSE,
                        itemType: DRAG_TYPES.FORMULA_OPERATOR,
                        id: uuid(),                            
                    }]]
                });
            }

            return updatedData;
        };
        setFormulaRows(prevItems => updateData(prevItems));
    }
 
    return (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'flex-start'}}>
            { formulaRows.length > 0 && formulaRows.map((formulaRow, indexRow) => (                
                <React.Fragment key={indexRow}>
                    <Box sx={{display: 'flex', flexDirection: 'row', padding: '0.5rem', gap: 1, border: '0.1rem dashed gray', borderRadius: 1, opacity: indexRow === activeRow ? 1 : 0.6 }}>
                        <Box sx={{minWidth: showCloseButton(formulaRow) ? '97%' : '100%', maxWidth: showCloseButton(formulaRow) ? '97%' : '100%'}} onClick={(e) => activateCondition(indexRow)}>
                            <FormulaConditionalRow
                                formula={formulaRow}
                                index={indexRow}
                                invalid={invalid}
                                active={indexRow === activeRow}
                                onChangeFormulaRow={onChangeFormulaRow}
                                onAddFormulaConditions={(options) => onAddFormulaConditions(indexRow, options)}
                                removeCondition={(index) => removeCondition(index)}   
                                resetCanvas={resetCanvas}                                                
                            />
                        </Box>
                        {
                            showCloseButton(formulaRow) && <Box  sx={{minWidth: '3%', maxWidth: '3%', textAlign: 'center', display: 'flex', alignItems: 'center' }} onClick={() => removeCondition(indexRow)}>
                                <IconButton sx={{
                                    border: '2px solid black',
                                    borderRadius: '50%',                                    
                                    color: 'black',
                                    padding: '1px',
                                    width: '20px',
                                    height: 'auto',
                                    fontSize: '10px',
                                    fontWeight: '700'                                    
                                }}>
                                    X
                                </IconButton>
                            </Box>
                        }
                    </Box>
                </React.Fragment>
            ))}            
        </Grid>   
    );
};