import React from "react"; 
import {Box} from '@mui/material';

import styles from "./CLAPerson.module.css";

export const CLAPerson = (props) => {
    const { account, styles: _styles } = props;

    if (!account) return <></>;

    const renderEmptyCell = (account) => {
        const { firstName = '', lastName = '' } = account;
        return (
            <Box className={styles['emptyCell__background']}>
                <span className={styles['emptyCell__text']}>
                    {`${firstName[0]?.toUpperCase() ?? ''}${lastName[0]?.toUpperCase() ?? ''}`}
                </span>
            </Box>
        )
    }

    return (
        <>
            <Box sx={{ ..._styles }}
                className={styles["avatar-circle"]}
            >
                {
                    account.thumbnailPhoto ?
                        <Box className={styles["img-wrapper"]}>
                            <img className={styles["image"]}
                                src={`data:image/png;base64,${account.thumbnailPhoto}`} />
                        </Box>
                        :
                        renderEmptyCell(account)
                }
            </Box>
        </>
    )
}

export default CLAPerson;