import {createContext} from 'react';

export const AppTypeContext = createContext({});

export const AppTypeProvider = ({isPortalApp, children}) => {
  return (
    <AppTypeContext.Provider value={{isPortalApp}}>
      {children}
    </AppTypeContext.Provider>
  );
};
