import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDrop } from 'react-dnd';
import {DRAG_TYPES} from '@ais/constants';
import { FORMULA_CONDITIONS } from '@constants/forms';
import update from 'immutability-helper';
import { v4 as uuid } from 'uuid';
import { DIALOG } from '@constants/forms/index';
import { FormulaConditionalCanvas } from './FormulaConditionalCanvas';
import { FormulaBar } from './FormulaBar';

export const FormulaCanvas = ({ value, onChange, invalid }) => {
    const LABEL = DIALOG.SETTINGS.LABEL;
    const [formula, setFormula] = useState(value);
    const [currentHoverIndex, setCurrentHoverIndex] = useState(-1);
    const [isConditional, setConditional] = useState(false);
    const { ELSE, IF, THEN } = FORMULA_CONDITIONS    

    useEffect(() => {
        setConditional(() => !!(formula.find(op => op?.operator === IF)));
    }, [formula])

    const [{ firstItemDragPreview }, ref] = useDrop({
        accept: [
            DRAG_TYPES.FORMULA_OPERATOR,
            DRAG_TYPES.FORMULA_VARIABLE,
            DRAG_TYPES.FORMULA_COMPONENT,
            DRAG_TYPES.FORMULA_PLACEHOLDER,
            DRAG_TYPES.FORMULA_QUESTION
        ],
        collect: (monitor) => {
            const itemType = monitor.getItemType();
            const item = monitor.getItem();

            if (!monitor.isOver() || !monitor.canDrop()) {
                setCurrentHoverIndex(-1);
            } else if (!!item && item.isPlaceholder && currentHoverIndex < 0) {
                setCurrentHoverIndex(formula.length);
            }

            return {
                firstItemDragPreview: !!itemType && formula.length === 0,
            };
        },
        drop: item => {
            const { isPlaceholder, shouldDelete, ...component } = item;
            const insertedComponent = { ...component, id: uuid() };

            if (item.itemType === 'FORMULA_QUESTION') {
                insertedComponent.questionId = item.questionId;
            };            

            if(component?.operator === IF) {
                setFormula(prevItems => update(prevItems, {
                    $push: [
                        insertedComponent,
                        {
                            value: '?',
                            operator: THEN,
                            itemType: DRAG_TYPES.FORMULA_OPERATOR,
                            id: uuid()                            
                        },
                        {
                            value: ':',
                            operator: ELSE,
                            itemType: DRAG_TYPES.FORMULA_OPERATOR,
                            id: uuid()                            
                        },
                    ],
                }));
            } else {                
                if (formula.length === 0) {
                    setFormula(prevItems => update(prevItems, {
                        $push: [insertedComponent],
                    }));
                } else {
                    const deleteIndex = formula.findIndex(f => f.id === item.id);
    
                    if (isNaN(deleteIndex) || deleteIndex === -1) {
                        setFormula(prevItems => update(prevItems, {
                            $splice: [
                                [currentHoverIndex, 0, insertedComponent],
                            ],
                        }));
                    } else {
                        setFormula(prevItems => update(prevItems, {
                            $splice: [
                                [deleteIndex, 1],
                                [currentHoverIndex > deleteIndex ? currentHoverIndex - 1 : currentHoverIndex, 0, insertedComponent],
                            ],
                        }));
                    }
                }
            }

            setCurrentHoverIndex(-1);
        },
    });    

    const onFormulaComponentHover = (index, side) => {
        setCurrentHoverIndex(side === 'left' ? index : index + 1);
    };

    const removeFormulaComponent = (index) => {
        setFormula(prevItems => update(prevItems, {
            $splice: [
                [index, 1],
            ],
        }));
    };

    const resetCanvas = () => {
        setConditional(false);
        setFormula([]);
    };
    
    useEffect(() => {
        if(!isConditional) {
            onChange(formula);
        }
    }, [formula]);        

    return (
        <Grid container rowSpacing={2} sx={{ mt: '60px' }}>
            <Grid item xs={12}>
                <Typography variant="title" sx={{ fontSize: '18px' }}>
                    {LABEL.PREVIEW}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box
                    ref={isConditional ? null : ref }
                    sx={{
                        height: '300px',
                        border: 1,
                        borderColor: invalid ? 'red' : 'gray.main',
                        backgroundColor: firstItemDragPreview && !isConditional ? 'rgba(118, 210, 211, 0.5)' : 'white',
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '20px',
                        padding: 3,
                        flexWrap: 'wrap',
                        overflowY: 'auto',
                    }}
                >
                    {
                        isConditional ? <FormulaConditionalCanvas 
                            formula={formula}
                            onChange={onChange}
                            invalid={invalid}
                            setFormula={setFormula}
                            resetCanvas={resetCanvas}             
                        /> :
                        <FormulaBar
                            formula={formula}                                            
                            onHover={(index, side) => onFormulaComponentHover(index, side)}
                            onDropOutside={(index) => removeFormulaComponent(index)}
                            currentHoverIndex={currentHoverIndex}/>
                    }
                </Box>
            </Grid>
        </Grid>
    );
};