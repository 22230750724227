export const DATABASE_LOOKUP_VALUES = {
    EN: {
        PROJECT_STATE: {
            ACTIVE: 'Active',
            FINALIZED: 'Finalized'
        },
        PROJECT_SENSITIVITY: {
            NORMAL: 'Normal',
            RESTRICTED: 'Restricted'
        },
        PROJECT_FORM_CATEGORY_GROUP: {
            GROUP1: 'Planning & Risk Assessment',
            GROUP2: 'Substantive Procedures',
            GROUP3: 'Project Conclusions'
        },
        ClientSensitivity: {
            NORMAL: 'Normal',
            RESTRICTED: 'Restricted'
        },
    }
}