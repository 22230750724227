import React, { memo } from "react";
import {
    FormControl,
    InputLabel,
    Typography,
} from "@mui/material";
import { sanitize } from "dompurify";
import { Controller, useFormContext } from "react-hook-form"

import { useVFCanvasProvider, useProjectFormInstanceProvider } from '@providers';
import { Flex } from "@ais/palette";
import { FIELD_TYPES } from "@ais/constants";
import {
    GENERAL_LEDGER_COMPLETENESS_1,
    CURRENT_PERIOD_TO_PRIOR,
    TRENDING_BY_MONTH,
} from '@constants/forms';
import {
    CLACheckBoxGroup,
    CLAComboBox,
    CLACurrencyField,
    CLADatePickerField,
    CLADropdown,
    CLAFormTable,
    CLANumberField,
    CLAPercentageField,
    CLARadioButtonList,
    CLASelect,
    ClientExternalIcon,
    copyObject,
    Formula,
    InstanceLongAnswer,
    parseQuestionLabel,
    renderLabel,
    VFQuestionGroup
} from '@ais/forms'; 
import { TbLinkBaseField, NoValidationInput } from './TbLinkFields';

import CLATrialBalanceSelect from '@views/ProjectScopingForm/CLATrialBalanceSelect/CLATrialBalanceSelect';
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import styles from '@components/FormView/FormView.module.css';
// Planning Analytics
import GLCAccounts from '../GLCAccounts/GLCAccounts'
import CPPPTable from "../CPPPTable/CPPPTable";
import GraphsDisplayManager from "../Graphs/GraphsDisplayManager";

import { useOthers } from "@components/Concurrency/store/users";
import { useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider"; 

export const VFFields = memo((props) => {
    const {
        field,
        projectId,
        projectFormId,
        disabled: _disabled,
        isInstance,
        section,
        answerList,
        renderLink,
        getDefaultValue,
        handleCurrencyValue,
        handleOnFocus,
        watchRadioList,
        handleBlur,
        formTypeId,
        units,
        isIdle,
        project,
        answered,
        onResetAnswerQuestionField,
    } = props;
     
    const updateMyPresence = useUpdateMyPresence();
    const others = useOthers();
    const formCanvas = useFormContext();
    const {
        userId,
        isGeneralLedgerExpanded,
        isCurrentPeriodExpanded,
        isTrendingByMonthExpanded,
        actions: { updateIsFormFocused } } = useProjectFormInstanceProvider();
    
    const isLockedByUser = others.find((user) => user.presence.focusedId === field.id && user.info.userId.toLowerCase() !== userId.toLowerCase())
    const { actions, state } = useVFCanvasProvider();
    const disabled = _disabled || field.linkType === 'Read Only' || isLockedByUser !== undefined;

    const handleNoValidationBlur = (answer) => {
        let parsedAnswer;
        try {
            parsedAnswer = JSON.parse(answer);
        } catch {
            parsedAnswer = answer;
        }

        if (typeof parsedAnswer !== "string" || !isInstance) return;

        const formValue = formCanvas.getValues(field.id);

        if (answer !== formValue && isInstance)
            formCanvas.setValue(field.id, answer);
    }
    const handleIsLocked = (section) => {
        if (section.title === GENERAL_LEDGER_COMPLETENESS_1.title) return isGeneralLedgerExpanded

        if (section.title === CURRENT_PERIOD_TO_PRIOR.title) return isCurrentPeriodExpanded

        if (section.title === TRENDING_BY_MONTH.title) return isTrendingByMonthExpanded
    }
    switch (field.type) {
        case FIELD_TYPES.SHORT_ANSWER:
        case FIELD_TYPES.LONG_ANSWER: {
            let defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();

            //only concurrency value from portal to portal has percentage symbol.
            if (field.validation === 'percentage' && isNaN(defaultValue) && defaultValue?.includes('%')) {
                defaultValue = Number(defaultValue.replace('%', '')) / 100;
            }
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && handleIsLocked(section)}
                >
                    <FormControl fullWidth>
                        {
                            !!field?.label && <InputLabel shrink variant="multiline-label">
                                {renderLabel(field.label, field.tooltip)}
                            </InputLabel>
                        }
                        {field.validation === 'none' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <NoValidationInput
                                            field={field}
                                            defaultValue={defaultValue}
                                            isFormInstance={isInstance}
                                            disabled={disabled}
                                            onFocus={() => {
                                                handleOnFocus()
                                                updateMyPresence({ focusedId: field.id })
                                            }}
                                            onChange={onChange}
                                            answered={answered}
                                            units={units}
                                            isIdle={isIdle}
                                            onBlur={(answer) => {
                                                handleNoValidationBlur(answer)
                                                updateMyPresence({ focusedId: null })
                                            }}
                                        />
                                    )
                                }} />

                        )}
                        {field.validation === 'numerical' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLANumberField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value)
                                            }}
                                            placeholder={field.placeholder}
                                            defaultValue={defaultValue}
                                            notched
                                            digitGroupSeparator=","
                                            disabled={disabled}
                                            isInstance={isInstance}
                                            tbLinkEnabled={isInstance}
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            field={field}
                                            tbLinkBaseField={TbLinkBaseField}
                                            units={units}
                                            isIdle={isIdle}
                                            onFocus={() => {
                                                handleOnFocus()
                                                updateMyPresence({ focusedId: field.id })
                                            }}
                                            onBlur={() => {
                                                updateMyPresence({ focusedId: null })
                                            }}
                                        />
                                    )
                                }} />
                        )}
                        {field.validation === 'usCurrency' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLACurrencyField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value)
                                            }}
                                            placeholder={field.placeholder}
                                            defaultValue={defaultValue}
                                            disabled={disabled}
                                            notched
                                            isInstance={isInstance}
                                            tbLinkEnabled={isInstance}
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            field={field}
                                            tbLinkBaseField={TbLinkBaseField}
                                            units={units}
                                            isIdle={isIdle}
                                            onFocus={() => {
                                                handleOnFocus()
                                                updateMyPresence({ focusedId: field.id })
                                            }}
                                            onBlur={() => {
                                                updateMyPresence({ focusedId: null })
                                            }}
                                        />
                                    )
                                }} />
                        )}
                        {field.validation === 'percentage' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLAPercentageField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value?.toString())
                                            }}
                                            placeholder={field.placeholder}
                                            value={defaultValue}
                                            disabled={disabled}
                                            notched
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            isIdle={isIdle}
                                            onFocus={() => {
                                                handleOnFocus()
                                                updateMyPresence({ focusedId: field.id })
                                            }}
                                            onBlur={() => {
                                                updateMyPresence({ focusedId: null })
                                            }}
                                        />
                                    )
                                }} />
                        )}
                    </FormControl>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.INSTANCE_LONG_ANSWER: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && handleIsLocked(section)}
                >
                    <InstanceLongAnswer
                        field={field}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        isIdle={isIdle}
                        onFocus={() => {
                            handleOnFocus()
                            updateMyPresence({ focusedId: field.id })
                        }}
                        onBlur={() => {
                            updateMyPresence({ focusedId: null })
                        }}
                    />
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.FREETEXT:
        case FIELD_TYPES.FREETEXT_NO_RULES: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && handleIsLocked(section)}
                >
                    <Flex direction="row" justify="between" align="baseline" className={styles['vf-field__freetext-container']}>
                        <Typography variant={field.type === FIELD_TYPES.FREETEXT && 'freeText'}>
                            <div
                                className="ql-editor"
                                dangerouslySetInnerHTML={{
                                    __html: sanitize(field.text, { ADD_ATTR: ['target'] }),
                                }}
                            />
                        </Typography>
                        <ClientExternalIcon
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                        />
                    </Flex>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.CHECKBOXGROUP: {
            const defaultValue = getDefaultValue(true);
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && handleIsLocked(section)}
                >
                    <CLACheckBoxGroup
                        id={field.id}
                        label={renderLabel(field.label, field.tooltip, renderLink)}
                        value={Array.isArray(defaultValue) ? defaultValue.filter(Boolean) : [defaultValue].filter(Boolean)}
                        onChange={(value, otherValue, otherToggled, e) => {
                            const copy = [...value];
                            if (otherValue) copy.push(otherValue)
                            formCanvas.setValue(field.id, copy);
                            if (!otherToggled && isInstance) {
                                actions.handleSubmit();
                            }
                            onResetAnswerQuestionField(field.id)
                            if (e) handleBlur(e, true)
                        }}
                        required={field.required}
                        columns={field.columns}
                        options={field.options}
                        allowOtherOption={field.allowOtherOption}
                        isDisabled={disabled}
                        onFocus={() => {
                            handleOnFocus()
                            updateMyPresence({ focusedId: field.id })
                        }}
                        isInstance={isInstance}
                        allowExternalAccess={field.visibleToClient ?? false}
                        answerable={field.editableByClient ?? false}
                        answered={answered}
                    />
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.RADIOGROUP: {
            const defaultValue = getDefaultValue();
            let _isLockedByUser = isLockedByUser;
            switch (field.id) {
                case '0142bfff-c7d2-4b79-832f-0091cbf08a56':
                    _isLockedByUser = others.find((user) => ['a0b25f15-db65-4dbd-883e-33ba42dca581', '2329c1bb-368c-40d6-beda-3573ad72ae40'].includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase())
                    break;
                case '4db6da62-c4cd-4d8c-9756-8a7b60f45df8':
                    _isLockedByUser = others.find((user) => ['2329c1bb-368c-40d6-beda-3573ad72ac39', '6f3201ec-eb69-429d-96cc-2b80081484cf'].includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase())
                    break;
                case '533137cd-c238-47b1-80e6-3fd71625116c':
                    _isLockedByUser = others.find((user) => ['36fdd3c3-3fca-40d3-8e1b-adc82bc29ab2', 'f7184d4b-0d30-4143-ab9c-0da7fabe21f6'].includes(user.presence.focusedId) && user.info.userId.toLowerCase() !== userId.toLowerCase())
                    break;
            }
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={_isLockedByUser}
                    isLocked={_isLockedByUser && handleIsLocked(section)}
                >
                    <Controller
                        control={formCanvas.control}
                        name={field.id}
                        render={({ field: { onChange } }) => {
                            return (
                                <CLARadioButtonList
                                    id={field.id}
                                    label={renderLabel(field.label, field.tooltip, renderLink)}
                                    defaultValue={watchRadioList}
                                    value={defaultValue}
                                    onChange={value => {
                                        onChange(value)
                                        if (isInstance) actions.handleSubmit();
                                    }}
                                    required={field.required}
                                    columns={field.columns}
                                    options={field.options}
                                    allowOtherOption={field.allowOtherOption}
                                    isDisabled={disabled || !!_isLockedByUser}
                                    onFocus={() => {
                                        handleOnFocus()
                                    }}
                                    formTypeId={formTypeId}
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                    answered={answered}
                                />
                            )
                        }} />
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.DROPDOWN: {
            const defaultValue = getDefaultValue(true);
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && handleIsLocked(section)}
                >
                    {field.allowOtherOption && (
                        <CLAComboBox
                            label={renderLabel(field.label, field.tooltip)}
                            defaultValue={defaultValue ?? ''}
                            onChange={(values, e, reason) => {
                                formCanvas.setValue(field.id, values)
                                if ((reason === 'removeOption' && e.target.tagName === 'path') && isInstance) {
                                    actions.handleSubmit();
                                }
                            }}
                            onBlur={(values) => {
                                formCanvas.setValue(field.id, values)
                                onResetAnswerQuestionField(field.id)
                                handleBlur({ target: { value: values } }, true)
                                if (isInstance) {
                                    actions.handleSubmit();
                                }
                                updateMyPresence({ focusedId: null })
                            }}
                            options={field.options}
                            allowMultiSelect={field.allowMultiSelect}
                            isDisabled={disabled}
                            onFocus={() => {
                                handleOnFocus()
                                updateMyPresence({ focusedId: field.id })
                            }}
                            allowMultiLineLabel
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                            enableOnChangeHandler={false}
                        />
                    )}
                    {!field.allowOtherOption && !field.allowMultiSelect && (
                        <CLADropdown
                            name={field.id}
                            label={renderLabel(field.label, field.tooltip)}
                            options={field.options}
                            value={defaultValue}
                            isDisabled={disabled}
                            onChange={(values) => {
                                formCanvas.setValue(field.id, values?.target?.value)
                            }}
                            onOpen={() => {
                                handleOnFocus()
                                updateMyPresence({ focusedId: field.id })
                            }}
                            allowMultiLineLabel
                            onClose={(e) => {
                                onResetAnswerQuestionField(field.id)
                                handleBlur(e, true)
                                updateMyPresence({ focusedId: null })
                            }}
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                        />
                    )}
                    {!field.allowOtherOption && field.allowMultiSelect && (
                        <CLASelect
                            id={field.id}
                            name={field.id}
                            label={renderLabel(field.label, field.tooltip)}
                            isDisabled={disabled}
                            defaultValues={
                                Array.isArray(defaultValue) ? defaultValue.filter(Boolean) : [defaultValue].filter(Boolean)
                            }
                            onChange={(values) => {
                                formCanvas.setValue(field.id, values?.filter((i) => i))
                            }}
                            menuItems={field.options}
                            onOpen={() => {
                                handleOnFocus()
                                updateMyPresence({ focusedId: field.id })
                            }}
                            allowMultiLineLabel
                            onClose={(e) => {
                                onResetAnswerQuestionField(field.id)
                                handleBlur(e, true)
                                updateMyPresence({ focusedId: null })
                            }}
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                            uncontrolled
                        />
                    )}
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.DATE_PICKER: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && handleIsLocked(section)}
                >
                    <Controller
                        control={formCanvas.control}
                        name={field.id}
                        render={({ field: { onChange } }) => {
                            return (
                                <CLADatePickerField
                                    label={renderLabel(field.label, field.tooltip)}
                                    onChange={(e) => {
                                        const value = e ?? null;
                                        onChange(value)
                                    }}
                                    onBlur={() => {
                                        handleBlur(null, true)
                                        updateMyPresence({ focusedId: null })
                                    }}
                                    placeholder={field.placeholder}
                                    disabled={disabled}
                                    defaultValue={defaultValue || formCanvas.getValues(field.id)}
                                    onFocus={() => {
                                        handleOnFocus()
                                        updateMyPresence({ focusedId: field.id })
                                    }}
                                    allowMultiLineLabel
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                    answered={answered}
                                    isIdle={isIdle}
                                />
                            )
                        }} />
                    {renderLink()}

                </VFRenderedFieldWrapper>

            );
        }
        case FIELD_TYPES.TABLE: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue(true);
            const parsed = parseQuestionLabel(field.label);
            const label = typeof parsed === 'object' ? parsed.questionLabel : parsed;
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && handleIsLocked(section)}
                >
                    <div className={styles['vf-section-table-container']}>
                        <div className={styles['vf-section-table-label-wrapper']}>
                            <Flex direction="row" justify="between" align="baseline">
                                <InputLabel className={styles['vf-section-table-label']}>
                                    {renderLabel(label, field.tooltip, renderLink)}
                                </InputLabel>
                                <ClientExternalIcon
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                />
                            </Flex>
                        </div>
                        <CLAFormTable
                            field={field}
                            values={defaultValue}
                            onChange={(e) => {
                                updateMyPresence({ focusedId: null })
                                formCanvas.setValue(field.id, e)
                                if (isInstance) {
                                    actions.handleSubmit();
                                }
                                handleBlur({ target: { value: e } }, true)
                                onResetAnswerQuestionField(field.id)
                            }}
                            isInstance={isInstance}
                            disabled={disabled}
                            onFocus={() => {
                                handleOnFocus();
                                updateMyPresence({ focusedId: field.id });
                            }}
                            isIdle={isIdle}
                        />
                    </div>
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.FORMULA: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && handleIsLocked(section)}
                >
                    <Formula
                        field={field}
                        shouldRenderFormula={isInstance}
                        label={renderLabel(field.label, field.tooltip, renderLink)}
                        answerList={answerList ? copyObject(answerList) : null}
                        onFocus={handleOnFocus}
                        disabled={disabled}
                    />
                </VFRenderedFieldWrapper>
            )
        }
        case FIELD_TYPES.TRIAL_BALANCE: {
            const defaultValue = getDefaultValue(true);
            const defaultArrayValue = Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : [];
            return (
                <>
                    {isInstance && projectId
                        ? (<CLATrialBalanceSelect
                            field={field}
                            formTypeId={formTypeId}
                            defaultValues={defaultArrayValue}
                            handCaptureInput={(userInput) => {
                                formCanvas.setValue(field.id, userInput);
                                updateMyPresence({ focusedId: null })
                                actions.handleSubmit();
                            }}
                            onFocus={() => {
                                handleOnFocus()
                            }}
                            onOpen={() => {
                                updateMyPresence({ focusedId: field.id })
                            }}
                            isDisabled={disabled}
                            project={project}
                            isPlanningAnalyticsForm={true}
                            isLockedByUser={isLockedByUser}
                            isLocked={isLockedByUser}
                        />)
                        : (<div className={styles["vf-section-placeholder"]}> TRIAL BALANCE COMPONENT PLACEHOLDER  </div>)
                    }
                    {renderLink()}
                </>
            );
        }
        case FIELD_TYPES.GENERAL_LEDGER: {
            return (
                <div className={styles.field__wrapper_noalign}>
                    <div className={styles.field__wrapper_inner}>
                        <GLCAccounts
                            isInstance={isInstance}
                            disabled={_disabled}
                            appDataInstanceId={project?.AppDataInstanceId}
                            setIsLoadCurrentPeriodSection={actions.handleSetIsLoadCurrentPeriodSection}
                        />
                    </div>
                    {renderLink()}
                </div>
            );
        }
        case FIELD_TYPES.CURRENT_PERIOD_TO_PRIOR: {
            const loadSection = state.isLoadCurrentPeriodSection
            return (
                <div className={styles.field__wrapper_noalign}>
                    <div className={styles.field__wrapper_inner}>
                        {isInstance ? (
                            <div style={{ marginTop: '50px' }}>
                                <CPPPTable
                                    projectId={projectId}
                                    sectionId={section?.id}
                                    isLoadCurrentPeriodSection={state.isLoadCurrentPeriodSection}
                                    section={section}
                                />
                            </div>
                        ) : (
                            <div className={styles["vf-section-placeholder"]}>
                                Current period to prior Component Placeholder
                            </div>
                        )}
                    </div>
                    {renderLink()}
                </div>
            );
        }
        case FIELD_TYPES.TRENDING_BY_MONTH: {
            const formValues = formCanvas.getValues();
            return (
                <div className={styles.field__wrapper_noalign}>
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && (
                            <GraphsDisplayManager
                                formValues={formValues}
                                projectId={projectId}
                                projectFormId={projectFormId}
                                sectionId={section.id}
                                project={project}
                                fieldId={field.id}
                                isLockedByUser={isLockedByUser}
                                isLocked={isLockedByUser && handleIsLocked(section)}
                                handleLock={() => {
                                    updateMyPresence({ focusedId: field.id })
                                }}
                                handleUnlock={() => {
                                    updateMyPresence({ focusedId: null })
                                }}
                            />
                        )}
                    </div>
                    {renderLink()}
                </div>
            );
        }
        case FIELD_TYPES.KPIS_RATIOS: {
            return (
                <></>
                // TODO: Enable once KPI's are implemented
                // Disabled based on US-3181 AC 8
                // <div className={styles.field__wrapper_noalign}>
                //     <div className={styles.field__wrapper_inner}>
                //         <div className={styles['vf-section-placeholder']}>
                //             KPIS RATIOS Component Placeholder
                //         </div>
                //     </div>
                //     {renderLink()}
                // </div>
            );
        }
        case FIELD_TYPES.QUESTION_GROUP: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper_noalign}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && handleIsLocked(section)}
                >
                    <VFQuestionGroup
                        field={field}
                        isInstance={isInstance}
                        onChange={(value) => {
                            formCanvas.setValue(field.id, value);
                            handleBlur({ target: { value } }, true);
                            onResetAnswerQuestionField(field.id);
                        }}
                        handleOnFocus={handleOnFocus}
                        answerValues={defaultValue}
                        updateIsFormFocused={updateIsFormFocused}
                        onSubmit={actions.handleSubmit}
                        disabled={disabled}
                    />
                </VFRenderedFieldWrapper>
            );
        }
        default: {
            return null;
        }
    }
});
