export const TAILOR_PROCEDURE_REDUCER = {
    LOAD_INIT_TAILOR_PROCEDURE: 'LOAD_INIT_TAILOR_PROCEDURE',
    SET_SUMMARY_PROCEDURE: 'SET_SUMMARY_PROCEDURE',
    UPDATE_SUMMARY_PROCEDURE: 'UPDATE_SUMMARY_PROCEDURE',
    SET_CUSTOM_PROCEDURE: 'SET_CUSTOM_PROCEDURES',
    DELETE_CUSTOM_PROCEDURE: 'DELETE_CUSTOM_PROCEDURE',
    UPSERT_CUSTOM_PROCEDURE: 'UPSERT_CUSTOM_PROCEDURE',
    SET_PROJECT_SCOPE_AUDIT_AREA_SCOTABDS: 'SET_PROJECT_SCOPE_AUDIT_AREA_SCOTABDS',
    REALIGN_PROCEDURE_FROM_CONCURRENCY: 'REALIGN_PROCEDURE_FROM_CONCURRENCY',
    UPDATE_STEP_PROCEDURE_CONTENT: 'UPDATE_STEP_PROCEDURE_CONTENT',
    UPDATE_PROJECT_RISK_SUMMARY_PROCEDURE: 'UPDATE_PROJECT_RISK_SUMMARY_PROCEDURE',
    RESET_STATE: 'RESET_STATE',
    UPDATE_CUSTOM_PROCEDURE_CONTENT: 'UPDATE_CUSTOM_PROCEDURE_CONTENT',
    UPDATE_PROJECT_RISK_CUSTOM_PROCEDURE: 'UPDATE_PROJECT_RISK_CUSTOM_PROCEDURE',
}
