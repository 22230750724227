import clientApi from '@utilities/claApiClient';
import { useQuery } from "@tanstack/react-query";

export const getPriorPeriodComparisonTransactionLevel = async (
  correlationDetailId,
  accountNumber,
  projectId
) => {
  try {
    const response = await clientApi.post(
      `/clc-audit/trial-balance/current-period-comparison/transaction-level/${projectId}`,
      { correlationDetailId, accountNumber }
    );
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 500, message: error };
  }
};

export const usePriorPeriodComparisonTransactionLevel = (
  correlationDetailId,
  accountNumber,
  projectId
) => {
  return useQuery(
    ["PriorPeriodComparisonTransactionLevel", correlationDetailId, accountNumber],
    () => getPriorPeriodComparisonTransactionLevel(
      correlationDetailId,
      accountNumber,
      projectId
    ),
    {
      enabled: !!correlationDetailId || !!accountNumber,
      retry: 3,
      retryDelay: 1000
    }
  );
};

const priorPeriodComparisonService = {
  getPriorPeriodComparisonTransactionLevel,
  usePriorPeriodComparisonTransactionLevel
}

export default priorPeriodComparisonService
