import React, { useContext, useEffect, useState } from 'react';
import ProcedureStepsCollapse from './ProcedureStepsCollapse';
import { Box } from '@mui/material';
import style from './CLATailorProcedureTable.module.css'
import TailoringProcedureContext from './state/TailoringProcedureContext';
import { useTailoringFilterProcedureContext } from './state/TailoringFilterProcedureContext';
import { PROJECT_FORM_INSTANCE } from '@constants/index';

const SummaryProcedureCollapse = (props) => {
    const { summaryProcedures = [], isExpanded, handleSummaryProcedureChange, setSummaryProcedures } = props;
    const { suggestedProcedures, applySuggestion, summaryProceduresUpdated, setSummaryProceduresUpdated, tailorProcedureData, isAppliedToRiskAssessment } = useContext(TailoringProcedureContext)
    const { selectedFilter } = useTailoringFilterProcedureContext()
    const { ALL, SUGGESTED_ADDITIONS, SUGGESTED_DELETIONS, CUSTOM_PROCEDURES } = PROJECT_FORM_INSTANCE.PROCEDURE.TAILORING_PROCEDURES_FILTER
    const [filteredProcs, setFilteredProcs] = useState(summaryProcedures)

    const findChildSteps = (procedureSteps, ps, checked) => {
        const { SummaryProcedureStepId } = ps;
        let result = [];

        const childProcedures = procedureSteps?.filter((data) => data.ParentSummaryProcedureStepId === SummaryProcedureStepId);
        for (const procedure of childProcedures) {
            result.push({
                ...procedure,
                IsChecked: checked
            })
            result = [...result, ...findChildSteps(procedureSteps, procedure, checked)];
        }
        return result;
    }

    const findParentSteps = (procedureSteps, ps, checked) => {
        const { ParentSummaryProcedureStepId } = ps;
        let result = [];
        const parentProcedures = procedureSteps?.filter((data) => data.SummaryProcedureStepId === ParentSummaryProcedureStepId);

        const hasSiblingCheckProcedure = () => {
            let isChecked = false;
            for (const procedure of procedureSteps) {
                if (((procedure.ParentSummaryProcedureStepId === ps.ParentSummaryProcedureStepId)
                    && (procedure.SummaryProcedureStepId !== ps.SummaryProcedureStepId))
                    && procedure.IsChecked === 1) {
                    isChecked = true;
                    break;
                }
            }
            return isChecked;
        }

        if (hasSiblingCheckProcedure()) return result;
        for (const procedure of parentProcedures) {
            result.push({
                ...procedure,
                IsChecked: checked
            })
            result = [...result, ...findParentSteps(procedureSteps, procedure, checked)];

        }
        return result;
    }

    const handleCheckboxChange = (ps) => {
        const newPs = filteredProcs.find((prevPs) => {
            const steps = prevPs?.SummaryProcedureSteps?.find(data => data.SummaryProcedureStepId === ps.SummaryProcedureStepId) ?? null;
            if (steps) {
                return prevPs;
            }
        }) ?? {};

        const { SummaryProcedureSteps } = newPs;

        if (SummaryProcedureSteps) {
            const { IsChecked } = ps;
            let steps = [ps, ...findChildSteps(SummaryProcedureSteps, ps, IsChecked), ...findParentSteps(SummaryProcedureSteps, ps, IsChecked)];
            const summaryProcedureStep = SummaryProcedureSteps.map((procedureStep) => {
                const step = steps.find((data) => data.SummaryProcedureStepId === procedureStep.SummaryProcedureStepId)
                if (step) return step;
                return procedureStep;
            })
            handleSummaryProcedureChange({
                ...newPs,
                SummaryProcedureSteps: summaryProcedureStep
            });
        } else {
            const { SummaryProcedureSteps, IsChecked } = ps;
            const checked = IsChecked ? 1 : 0;
            handleSummaryProcedureChange({
                ...ps,
                SummaryProcedureSteps: SummaryProcedureSteps?.map(data => {
                    return {
                        ...data,
                        IsChecked: checked
                    };
                }) ?? []
            });
        }
    }

    const checkIfChildIsRequired = (summaryProcedureSteps, ps, isParent = false) => {
        const { SummaryProcedureStepId, SummaryProcedureId } = ps || {};
        let isRequired = false;
        let childProcedures = [];
        if (SummaryProcedureId && isParent) {
            childProcedures = summaryProcedureSteps?.filter((data) => data.SummaryProcedureId === SummaryProcedureId);
        } else {
            childProcedures = summaryProcedureSteps?.filter((data) => data?.ParentSummaryProcedureStepId === SummaryProcedureStepId);
        }
        for (let summaryProcedureStep of childProcedures) {
            if (summaryProcedureStep?.IsRequiredStep) {
                return true;
            }
            isRequired = checkIfChildIsRequired(summaryProcedureSteps, summaryProcedureStep);
            if (isRequired) return true;
        }
        return isRequired;
    }

    const checkIfEditable = (isEditable, stepData) => {
        const matchingProc = tailorProcedureData.find((proc) => proc.SummaryProcedureId === stepData.SummaryProcedureId)
        const matchingStep = matchingProc?.SummaryProcedureSteps.find((step) => step.SummaryProcedureStepId === stepData.SummaryProcedureStepId)
        return matchingStep?.IsChecked ? isEditable : 1
    }

    const renderChildrenCollapse = (parentSteps, summaryProcedureSteps, _index, isChildren, isEditable) => {
        return parentSteps.map((data, index) => {
            const { SummaryProcedureStepId: id, IsChecked, IsRequiredStep } = data || {};
            const isChecked = IsChecked === 1 ? true : false
            const childrenSteps = summaryProcedureSteps?.filter(step => step?.ParentSummaryProcedureStepId === id);
            const currentIndex = `${_index}.${index + 1}`;
            let isLast = false;
            const childIsRequired = checkIfChildIsRequired(summaryProcedureSteps, data);
            const isCollapseAndChildRequired = IsRequiredStep || childIsRequired;

            if (!summaryProcedureSteps.find((sps => sps.ParentSummaryProcedureStepId === id)) || isChildren === 6) {
                isLast = true;
            }

            return (
                <ProcedureStepsCollapse
                    key={index}
                    index={currentIndex}
                    summaryProcedure={data}
                    isLast={isLast}
                    isRequired={isCollapseAndChildRequired}
                    checked={isChecked}
                    isEditable={checkIfEditable(isEditable, data)}
                    handleCheckboxChange={handleCheckboxChange}
                    isAppliedToRiskAssessment={isAppliedToRiskAssessment}
                >
                    {renderChildrenCollapse(childrenSteps,
                        summaryProcedureSteps,
                        currentIndex,
                        isChildren + 1,
                        isEditable)}
                </ProcedureStepsCollapse>
            );
        })
    }

    useEffect(() => {
        let updatedSummaryProcedure
        if (selectedFilter.value === SUGGESTED_ADDITIONS || selectedFilter.value === SUGGESTED_DELETIONS) {
            updatedSummaryProcedure = summaryProceduresUpdated?.map((proc) => {
                return {
                    ...proc,
                    SummaryProcedureSteps: proc?.SummaryProcedureSteps?.filter((step) => {
                        return selectedFilter.value === SUGGESTED_ADDITIONS
                            ? suggestedProcedures?.some((proc) => proc?.SummaryProcedureStepId === step?.SummaryProcedureStepId) && step.IsChecked === 0 && isAppliedToRiskAssessment
                            : !suggestedProcedures?.some((proc) => proc?.SummaryProcedureStepId === step?.SummaryProcedureStepId) && step.IsChecked === 1 && isAppliedToRiskAssessment;
                    })
                }

            })
            setFilteredProcs(updatedSummaryProcedure.filter((proc) => proc?.SummaryProcedureSteps?.length > 0))
            return
        }
        if (selectedFilter.value === ALL) {
            setFilteredProcs(summaryProceduresUpdated)
            return
        }
        if (selectedFilter.value === CUSTOM_PROCEDURES) {
            setFilteredProcs([])
        }
    }, [selectedFilter, summaryProceduresUpdated])

    useEffect(() => {
        const newProcs = summaryProceduresUpdated?.map((procedure) => {
            const matchingProc = summaryProcedures?.filter((sp) => sp.SummaryProcedureId === procedure.SummaryProcedureId)[0]
            if (matchingProc) {
                return matchingProc
            }
            return procedure
        })
        setSummaryProceduresUpdated(newProcs)
    }, [summaryProcedures])

    useEffect(() => {
        if (applySuggestion) {
            setSummaryProcedures(summaryProcedures?.map((proc) => {
                return {
                    ...proc,
                    SummaryProcedureSteps: proc?.SummaryProcedureSteps?.map((step) => {
                        if(Array.isArray(suggestedProcedures) && suggestedProcedures.length !== 0) {
                            if (suggestedProcedures?.some((proc) => proc?.SummaryProcedureStepId === step?.SummaryProcedureStepId)) {
                                return {
                                    ...step,
                                    IsChecked: proc.IsEditable === 1 ? 1 : step.IsChecked
                                }
                            }
                            return {
                                ...step,
                                IsChecked: checkIfEditable(proc.IsEditable, step) ? 0 : step.IsChecked
                            }
                        } else return {...step}
                    }) 
                }

            }))
        }
    }, [applySuggestion])

    return (
        <div data-test='summaryprocedure-container'>
            {
                filteredProcs?.map((data, index) => {
                    const currentIndex = index + 1;
                    const { IsRequired: isRequired, SummaryProcedureSteps, IsEditable } = data;
                    const summaryProcedureSteps = SummaryProcedureSteps ?? [];
                    const summaryProcedureStepsOrdered = summaryProcedureSteps.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
                    const parentSteps = summaryProcedureStepsOrdered.filter(data => !data?.ParentSummaryProcedureStepId)
                    const collapses = renderChildrenCollapse(parentSteps, summaryProcedureStepsOrdered, currentIndex, 1, IsEditable);
                    const checked = summaryProcedureStepsOrdered.some((data) => data.IsChecked === 1);
                    const childIsRequired = checkIfChildIsRequired(summaryProcedureSteps, data, true);

                    return (
                        <Box key={index} className={isExpanded ? style.post : ''}>
                            <Box sx={{ margin: '10px 0', paddingTop: '20px', paddingBottom: '10px' }}>
                                <ProcedureStepsCollapse
                                    key={index}
                                    index={currentIndex}
                                    summaryProcedure={data}
                                    isLast={summaryProcedureStepsOrdered.length === 0}
                                    isFirst={true}
                                    isRequired={isRequired || childIsRequired}
                                    checked={checked}
                                    isEditable={IsEditable === 1 ? true : false}
                                    handleCheckboxChange={handleCheckboxChange}
                                >
                                    {collapses}
                                </ProcedureStepsCollapse>
                            </Box>
                        </Box>
                    )
                })}
        </div>
    );
};

export default SummaryProcedureCollapse 
