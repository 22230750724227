import { useParams } from 'react-router-dom';
import { createContext, useReducer, useMemo, useState, useEffect } from 'react';
import { useSocket, ProjectFormInstanceConcurrencyReducer } from '@ais/providers';
import useUserProviderState from "@contexts/UserContext"; 
import { PROJECT_FORM_CUSTOM_LOCK, SOCKET_EVENT_NAMES } from '@ais/constants'; 

const initialState = {
    sessionsData: [],
    userId: null,
    projectFormId: null,
    projectId: null,
    subscribedQuestionIds: [],
    lastSessionUpdate: null
};

export const ProjectFormInstanceConcurrencyContext = createContext({});

export const ProjectFormInstanceConcurrencyProvider = ({ children }) => {
    const { socket } = useSocket();
    const { getUser } = useUserProviderState();
    const { projectId, projectFormId } = useParams(); 

    const [userId, setUserId] = useState('');
    const [lastEmmitedEvent, setLastEmmitedEvent] = useState(null);
    const [state, dispatchReducer] = useReducer(ProjectFormInstanceConcurrencyReducer, initialState);

    const emitLastEvent = () => {
        if(!lastEmmitedEvent) return;
        
        const { eventName, data } = lastEmmitedEvent;

        // When last event emitted, don't continue unless acknowledged from backend
        (async () => {
            await new Promise((resolve) => {
                socket.emit(eventName, data, () => {
                    resolve();
                });
            });
        })()
    } 

    useEffect(() => {    
        (async () => {
            const user = await getUser();
            const userLocalId = user?.activeDirectoryId ?? '';
        
            setUserId(userLocalId);
        })();
    }, []);

    useEffect(() => {
        dispatchReducer({
            type: PROJECT_FORM_CUSTOM_LOCK.INITIALIZE_BASE_STATE,
            data: {
                userId, projectId, projectFormId
            }
        })
    }, [userId, projectId, projectFormId])

    // Subscribe to BROADCAST_QUESTION_UPDATE, BROADCAST_REMOVE_USER and UPDATE_SOCKET_ID events
    useEffect(() => {
        if(!socket) return;

        socket.on(SOCKET_EVENT_NAMES.EMITS.BROADCAST_QUESTION_UPDATE, (data) => {
            dispatchReducer({
                type: PROJECT_FORM_CUSTOM_LOCK.UPSERT_SESSION,
                data
            })
        })

        socket.on(SOCKET_EVENT_NAMES.EMITS.BROADCAST_REMOVE_USER, (data) => {
            dispatchReducer({
              type: PROJECT_FORM_CUSTOM_LOCK.REMOVE_SESSION,
              data
            })
        })
    }, [socket])

    const onReconnect = () => {
        emitLastEvent()
    };

    useEffect(() => {
        socket.on(SOCKET_EVENT_NAMES.RECONNECT, onReconnect)
        // return () => {
        //     socket.off(SOCKET_EVENT_NAMES.RECONNECT, onReconnect)
        // }
    }, [lastEmmitedEvent, socket.id])

    const memoized = useMemo(
        () => ({ state, dispatchReducer, setLastEmmitedEvent }),
        [state, dispatchReducer, setLastEmmitedEvent]
    )

    return (
        <ProjectFormInstanceConcurrencyContext.Provider value={{
            ...memoized,
        }}>{children}</ProjectFormInstanceConcurrencyContext.Provider>
    )
}

