export const ADD_DEFICIENCY = {
    EN: {
        IDENTIFIED_DEFICIENCIES: 'Identified Deficiencies in Internal Control',
        FORM: 'Form',
        SECTION: 'Section',
        DEFICIENCY_NAME: 'Deficiency Name',
        WORKPAPER_REFERENCE: 'Workpaper Reference',
        ENTER_WORKPAPER_REFERENCE: 'Enter Workpaper Reference',
        ENTER_DEFICIENCY_NAME: 'Enter Deficiency Name',
        DESCRIBE_DEFICIENCY: 'Describe the identified deficiency',
        ENTER_DESCRIBE_DEFICIENCY: 'Enter description of deficiency in internal control and an explanation of the potential effects',
        AUDIT_AREA: 'Audit Area',
        ASSOCIATE_DEFICIENCY_EVALUATION: 'Associate with Deficiency Evaluation',
        SELECT: 'Select',
        YES: 'Yes',
        NO: 'No',
        CANCEL: 'Cancel',
        SAVE: 'Save',
        UNIQUE_NAME_REQUIRED: 'A unique name is required.'
    },
    API_REQUEST_ACTION_TYPE: {
        ADD: 'Add',
        UPDATE: 'Update'
    }
}