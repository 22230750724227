import { colors, fonts } from '@ais/theme';

export const cellBorderStyles = { borderTop: `1px solid ${colors.gray[35]}` };
export const wrapCellStyles = { maxWidth: '250px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', fontWeight: '600' };
export const cellStyles = { verticalAlign: 'top', borderBottom: 'none', fontSize: '14px', fontWeight: '600', paddingTop: '30px' }
export const riskGridCell = { border: '1px solid #E7E6E6', padding: '16px', fontSize: '12px', lineHeight: '14.4px', fontWeight: '600' };
export const boxColumnStyles = { display: 'flex', flexDirection: 'column' };
export const boxHeaderRowRiskStyles = { backgroundColor: colors.gray[30] };
export const boxHeaderRowAuditStyles = { display: 'flex', flexDirection: 'row', border: `1px ${colors.gray[75]} solid`, backgroundColor: colors.gray[30], p: '15px' };
export const boxHeaderColStyles = { fontWeight: '700', lineHeight: '16.8px', fontFamily: fonts.main, color: colors.navy[400], border: 'none' };
export const boxAuditAreaName = { flexGrow: 1, display: 'flex', alignItems: 'center' };
export const boxRowStyles = { display: 'flex', flexDirection: 'row' };
export const buttonStyles = { width: '100px', m: '26px 11.86px' };
export const typoAuditAreaName = { fontSize: '18px', color: colors.gray[500], fontWeight: '600' };
export const typoSCOTABDS = { fontSize: '16px', color: colors.navy[400], fontWeight: '600' };
export const boxRiskContainer = { ml: '30px', mb: '20px', fontSize: '14px' };
export const txtReferenceStyles = { width: '100%', '.MuiOutlinedInput-root': { fontSize: '14px', fontWeight: '600' } }
export const selectStyles = { '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': { fontSize: '14px' } }
export const financialSelectStyles = { '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': { fontSize: '12px', fontWeight: 600 } }
