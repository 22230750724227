import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { SchemaProvider } from '@ais/providers';
import { CustomHistoryProvider } from "@components/Forms/state";
import { NewFormCardProvider } from "@components/Forms/CLAProjectFormsLibrary/state/NewFormCardContext";
import { CLADatagridProvider } from "@components/CLADatagrid/state/CLADatagridContext";
import { NestedDatagridContextProvider } from "@components/DatagridMasterDetail/state/NestedDatagridContext";
import { MethodologyVersionProvider } from '@components/CLAHeader/state/MethodologyVersionContext';
import { theme } from '@ais/theme';
import { NotificationProvider } from './notifications/NotificationProvider';

/**
 * A function that takes a list of providers and returns
 * one provider that contains all of them
 * 
 * Functionality below will be the same as writing the following:
 * 
 * const AppProviders = ({ children }) => (
 *  <ThemeProvider>
 *      <NestedDatagridContextProvider>
 *          <CLADatagridProvider>
 *              <NewFormCardProvider>
 *                  <SchemaProvider>
 *                      <CustomHistoryProvider>
 *                          { children }
 *                      </CustomHistoryProvider>
 *                  </SchemaProvider>
 *              </NewFormCardProvider>
 *          </CLADatagridProvider>
 *      </NestedDatagridContextProvider>
 *  </ThemeProvider>
 * ) 
 */

function ThemeProvider({ children }) {
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}


const combineProviders = (providerList, children) =>
    providerList?.reduceRight((acc, Provider) => <Provider>{acc}</Provider>, <>{children}</>)

export const AppProviders = ({ children }) =>
    combineProviders(
        [
            NotificationProvider,
            ThemeProvider,
            NestedDatagridContextProvider,
            CLADatagridProvider,
            NewFormCardProvider,
            SchemaProvider,
            CustomHistoryProvider,
            MethodologyVersionProvider,
        ],
        children
    )

