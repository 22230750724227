import { createContext, useContext, useState } from "react"

const TailoringFilterProcedureContext = createContext()

export const TailoringFilterProcedureContextProvider = ({
    children
}) => {

    const [ selectedFilter, setSelectedFilter ] = useState({ value: "all", label: "View All Procedures and Steps" })
    
    return (
        <TailoringFilterProcedureContext.Provider
            value={{
                selectedFilter,
                setSelectedFilter
            }}>
            { children }
        </TailoringFilterProcedureContext.Provider>
    )
}

export const useTailoringFilterProcedureContext = () => {
    const ctx = useContext(TailoringFilterProcedureContext) ?? {}
    return ctx
}
