import React, {
    useState,
    useEffect
} from 'react'; 
import {
    Box,
    TextField
} from '@mui/material';
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import { useRoomIdle } from '@components/Concurrency/provider/RoomProvider';

const CommunicationOfDeficienciesComment = (props) => {
    const { ID } = DEFICIENCY_FORM_INSTANCE.EN;
    const { row, handleCommentSave, disabled, updateMyPresence } = props;
    const [commentState, setCommentState] = useState(row?.CommunicationComment);
    const isIdle = useRoomIdle();

    useEffect(() => {
        setCommentState(row?.CommunicationComment);
    }, [row?.CommunicationComment]);
    
    useEffect(() => {
        setCommentState(row?.CommunicationComment);
    }, [isIdle]);

    return (<>
        <Box sx={{ p: `20px 65px 10px 65px` }}>
            <TextField
                label={'Comment'}
                fullWidth
                multiline
                placeholder="Enter comment"
                rows={6}
                disabled={disabled}
                value={commentState}
                onFocus={() => { updateMyPresence({ focusedId: ID }) }}
                onChange={(e) => setCommentState(e.target.value)}
                onBlur={()=> {
                    updateMyPresence({ focusedId: null })
                    handleCommentSave(row, 'CommunicationComment', commentState);
                }}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    notched: true
                }}
                inputProps={{
                    maxLength: 4096
                }}
            />
        </Box>
    </>
    );
}

export default CommunicationOfDeficienciesComment;