import React, { useRef, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDrop, useDrag } from 'react-dnd';
import {DRAG_TYPES} from '@ais/constants';
import {FORMULA_CONDITIONS} from '@constants/forms';
import {CLADropdown} from "@ais/forms";

export const FormulaComponent = (props) => {
    const { 
        item, 
        index, 
        onHover, 
        onDropOutside, 
        active = false, 
        isConditional, 
        onAddFormulaConditions,
        resetCanvas
    } = props
    const ref = useRef(null);
    const [conditionDropDown, setConditionDropDown] = useState(null);
    const { ELSE, ELSEIF, THEN, IF } = FORMULA_CONDITIONS;        

    const options = [ELSE, ELSEIF]

    const handleOnChange = (value) => {        
        setConditionDropDown(value)
    }
    
    const [{ isDragging }, drag] = useDrag({
        item,
        type: DRAG_TYPES.FORMULA_COMPONENT,
        collect: monitor => ({ isDragging: monitor.isDragging() }),
        end: (_, monitor) => {        
            if(!isConditional || (isConditional && active)) {
                if (!monitor.didDrop()) {
                    onDropOutside(index);              

                    if (item?.operator === IF && isConditional)
                        resetCanvas && resetCanvas();
                }
            }    
        },
    });

    const [{ handlerId }, drop] = useDrop({
        accept: [
            DRAG_TYPES.FORMULA_OPERATOR,
            DRAG_TYPES.FORMULA_VARIABLE,
            DRAG_TYPES.FORMULA_COMPONENT,
            DRAG_TYPES.FORMULA_PLACEHOLDER,
        ],
        collect: monitor => ({ handlerId: monitor.getHandlerId() }),
        hover: (item, monitor) => {
            const rect = ref.current.getBoundingClientRect();

            const offset = monitor.getClientOffset();

            if(isConditional && active) {
                onHover(index, Math.abs(rect.left - offset.x) > Math.abs(rect.right - offset.x) ? 'right' : 'left');
            }

            if(!isConditional) {
                onHover(index, Math.abs(rect.left - offset.x) > Math.abs(rect.right - offset.x) ? 'right' : 'left');
            }
        },
    });

    if(!isConditional || (isConditional && active)) {        
        drag(drop(ref));
    }

    useEffect(() => {
        if(isConditional && active && conditionDropDown) {            
            onAddFormulaConditions(conditionDropDown)
        }
    }, [conditionDropDown])

    useEffect(() => {
        setConditionDropDown(null)
    }, [item?.operator])
    
    return (
        <Box
            ref={ isConditional && ([ELSE, ELSEIF, THEN].includes(item?.operator)) ? null : ref}
            sx={{
                backgroundColor: item.itemType === DRAG_TYPES.FORMULA_OPERATOR && ![IF, THEN].includes(item?.operator) ? 'navy.main' : 'rgba(118, 210, 211, 0.4)',
                borderRadius: 1,
                color: item.itemType === DRAG_TYPES.FORMULA_OPERATOR && ![IF, THEN].includes(item?.operator) ? 'white' : 'navy.main',
                minWidth: item?.operator === THEN ? '65px' : '45px',
                height: '45px',
                display: isDragging ? 'none' : 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: item.itemType === DRAG_TYPES.FORMULA_OPERATOR && !['AND', 'OR', IF, THEN].includes(item?.operator) ? '22px' : '16px',
                fontWeight: 'bold',
                cursor: item?.operator === THEN ? 'auto' : 'move',
                px: item.itemType === DRAG_TYPES.FORMULA_OPERATOR ? '0' : '10px',
            }}
        >
            {
                options.includes(item?.operator) ? <Box sx={{
                    maxWidth: '6rem',
                    minWidth: '6rem',
                    fontSize: '1rem'  
                }}>
                    <CLADropdown 
                        options={options} 
                        defaultValue={item?.operator}
                        value={conditionDropDown ? conditionDropDown : item.operator}
                        onChange={(e) => handleOnChange(e.target.value)}
                        isDisabled={!active}
                    />
               </Box> : item.itemType === DRAG_TYPES.FORMULA_OPERATOR ? item.operator : item.value
            }
        </Box>
    );
};