import styles from './ButtonComponent.module.css';
import {
    Button,
    Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const ButtonComponent = (props) => {
    const { label, buttonName, onClick, disabled, shouldHighlight } = props;
  
    return (
      <div className={styles.button__wrapper}>
        <label>{label}</label>
        <Box sx={shouldHighlight ? { borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red' }: {}}>
          <Button
            onClick={onClick}
            sx={{
              m: 0,
              p: 0,
              color: disabled ? '#969696' : 'black',
              fontWeight: 'bold',
            }}
            startIcon={
              <AddIcon
                sx={{
                  color: disabled ? '#969696 !important' : '',
                }}
                className={styles.pageButtonAddFormIcon}
              />
            }
            variant="page-button-add-form"
            disabled={!!disabled}
          >
            {buttonName}
          </Button>
        </Box>
      </div>
    );
  };