import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';


export const CLADialog = (props) => {
    const { 
        width, 
        height, 
        title, 
        visible, 
        setVisible, 
        children, 
        customActionButton, 
        closeButtonLabel, 
        dialogStyle
    } = props;

    return (
        <Dialog
            open={visible}
            scroll="paper"
            variant="cla-dialog-form"
            sx={ dialogStyle ? dialogStyle : {
                '& .MuiDialog-paper': {
                    width: width ? width + "px" : undefined,
                    height: height ? height + "px" : undefined
                },
            }}
        >   
            {title && 
            <DialogTitle variant="cla-dialog-form">
                {title}
            </DialogTitle>
            }
            <DialogContent variant="cla-dialog-form">
                {children}
            </DialogContent>
            <DialogActions variant="cla-dialog"> 
                <>
                    {
                        customActionButton 
                            ? customActionButton 
                            : 
                            <Button
                                variant="contained"
                                disableElevation
                                onClick={() => setVisible(false)}>
                                {closeButtonLabel ?? "Close"}
                            </Button>
                    }
                </>
            </DialogActions>
        </Dialog>
    );
};

CLADialog.propTypes = {
    saveButton: PropTypes.bool,
    title: PropTypes.string
}

CLADialog.defaultProps = {
    saveButton: false
}



export default CLADialog;