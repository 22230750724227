import { parse } from 'expression-eval';
import {DRAG_TYPES} from '@ais/constants';
import { FORMULA_CONDITIONS } from '@constants/forms'

const { ELSE, IF } = FORMULA_CONDITIONS

export const formatFormula = (formula) => {
    let formulaExpression = '';
    formula.forEach((el, index) => {
        if ((index === formula.length - 1 && el?.operator === ELSE) || el?.operator === IF) {
            return;
        }

        if (el.itemType === DRAG_TYPES.FORMULA_OPERATOR || el.itemType === DRAG_TYPES.FORMULA_VARIABLE) {
            formulaExpression = formulaExpression.concat(' ' + el?.value);
        }

        if (el.itemType === DRAG_TYPES.FORMULA_QUESTION) {
            formulaExpression = formulaExpression.concat(' _' + el.questionId.split('-').join('_'))
        }

        return
    }) 

    return formulaExpression;
}

/**
 * @description Formula will be an array of {operators, value} object, and will return a void if it is valid ast.
 * @param formula
 * @returns 
 */
export const validateAST = (formula) => {
    try {
        const newFormula = formatFormula(formula)
        parse(newFormula);
    } catch (err) {
        throw 'Invalid Formula Format'
    }
}
