import React from 'react';

import NoValidationField from '@ais/forms/src/V2/FieldComponents/NoValidationField';
import PercentageField from '@ais/forms/src/V2/FieldComponents/PercentageField';
import CurrencyField from '@ais/forms/src/V2/FieldComponents/CurrencyField';
import NumberField from '@ais/forms/src/V2/FieldComponents/NumberField';

import { VALIDATION_TYPES } from '@ais/constants';

const ShortAnswer = (props) => {
    switch (props.field.validation) {
        case VALIDATION_TYPES.NUMERICAL:
            return (<NumberField {...props} />);
        case VALIDATION_TYPES.PERCENTAGE:
            return (<PercentageField {...props} />);
        case VALIDATION_TYPES.USCURRENCY:
            return (<CurrencyField {...props} />);
        case VALIDATION_TYPES.NONE:
            return (<NoValidationField {...props} />);
        default:
            return (<NoValidationField {...props} />);
    }
}

export default React.memo(ShortAnswer);