import HelpIcon from '@mui/icons-material/Help';
import { CLATooltip } from "@ais/components"
import { sanitize } from 'dompurify';

export const VFSectionV2Tooltip = ({ tooltip }) => {
  if (!tooltip) {
    return (<></>)
  }
  else {
    return (
      <CLATooltip
        title={
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: sanitize(tooltip, {
                ADD_ATTR: ['target'],
              }),
            }}
          />
        }
        placement="bottom"
      >
        <HelpIcon style={{ fontSize: '18px' }} />
      </CLATooltip>
    )
  }
}