import { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

import { TailorProcedureReducer } from '.';

const initialData = {}

export const TailorProcedureContext = createContext(initialData);

export const TailorProcedureProvider = ({ children }) => {
    const [tailorProcedure, dispatchTailorProcedures] = useReducer(
        TailorProcedureReducer, initialData
    );

    const memoized = useMemo(
        () => ({ tailorProcedure, dispatchTailorProcedures }),
        [tailorProcedure, dispatchTailorProcedures]
    );

    return (
        <TailorProcedureContext.Provider value={memoized}>
            {children}
        </TailorProcedureContext.Provider>
    );
};

TailorProcedureProvider.propTypes = {
    children: PropTypes.node,
};
