import { useEffect } from 'react';
import PropTypes from "prop-types";

import CLAFinancialStatementLevelPersuasiveRisk from './CLAFinancialStatementLevelPersuasiveRisk';

import { useProjectFormInstanceProvider } from '@providers';
import styles from './CLAFinancialStatementLevelRisk.module.css';
import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import CustomToast from '@components/CustomToast/CustomToast';
import { useRiskAssessment } from '../../hooks/useRiskAssessment';

const CLAFinancialStatementLevelRisk = (props) => {
  const { EN } = RISK_ASSESSMENT_FORM_INSTANCE;
  const {
    financialStatementLevelRiskToggle,
    loadingProjectRiskAssessmentSummary,
    successProjectRiskAssessmentSummary,
    errorProjectRiskAssessmentSummary,
    actions: {
      riskAssessmentIdentifyModifiedAnswerAfterSignOff
    }
  } = useProjectFormInstanceProvider();

  const {
    financialStatementPersuasiveRisk,
    fetchFinancialStatementLevelData
  } = useRiskAssessment();

  const { disabled } = props;
  
  useEffect(async () => {
    await fetchFinancialStatementLevelData();
    await riskAssessmentIdentifyModifiedAnswerAfterSignOff();
  }, [financialStatementLevelRiskToggle]);

  return (
    <div className={styles['fin-state-level-container']}>
      <div className={styles['fin-state-level-header']}>
        <div className={styles['fin-state-level-header-text']}>
          {EN.FINANCIAL_STATEMENT_LEVEL_RISK.RISK_NAME_HEADER}
        </div>
        <div className={styles['fin-state-level-header-text']}>
          {EN.FINANCIAL_STATEMENT_LEVEL_RISK.PERVASSIVE_EFFECT}
        </div>
        <div className={styles['fin-state-level-header-text']}>
          {EN.FINANCIAL_STATEMENT_LEVEL_RISK.OVERALL_RESPONSE}
        </div>
      </div>
      <div className={styles['fin-state-level-main']}>
        <div className={styles['fin-state-level-main-content']}>
          {financialStatementPersuasiveRisk.map((row, index) => (
            <CLAFinancialStatementLevelPersuasiveRisk
              index={index}
              row={row}
              disabled={disabled}
            />
          ))}
        </div>
      </div>
      <CustomToast 
        error={errorProjectRiskAssessmentSummary}
        success={successProjectRiskAssessmentSummary}
        loading={loadingProjectRiskAssessmentSummary}
      />
    </div>
  )
}

CLAFinancialStatementLevelRisk.propTypes = {
  projectFormId: PropTypes.number,
  disabled: PropTypes.bool,
}

export default CLAFinancialStatementLevelRisk;