import React, {memo} from 'react'
import { parseQuestionLabel } from '@ais/forms'
import QuestionGroupInner from '@ais/forms/src/V2/QuestionGroup/QuestionGroupInner'
import {
  styled
} from '@mui/material'

const QuestionGroupWrapper = styled('fieldset')({
  border: '1px solid rgba(0, 0, 0, 0.26)',
  borderRadius: '4px',
  padding: 'initial',
  backgroundColor: '#fff',
  minWidth: '100%',
  margin: 0,
})
const QGLabel = styled('legend')({
  float: 'initial',
  width: 'initial',
  padding: 'initial',
  margin: 'initial',
  fontSize: 'initial',
  lineHeight: 'initial',
  marginLeft: '16px',
  padding: '0px 4px',
  fontSize: '12px',
  color: '#595959',
})
const QuestionGroup = (props) => {
  const {
    field: {
      label
    },
  } = props
  const parsed = parseQuestionLabel(label);
  const _label = typeof parsed === 'object' ? parsed.questionLabel : parsed;
  return (
    <QuestionGroupWrapper>
      <QGLabel>{_label}</QGLabel>
      <QuestionGroupInner {...props}/>
    </QuestionGroupWrapper>
  )
}

export default memo(QuestionGroup)