import formApi from '@utilities/claApiForm';
import { useQuery } from "@tanstack/react-query"
import logger from '@utilities/logService'

const getOtherCharacteristics = async (methodologyVersionId) => {
    try {
        const response = await formApi.get(`/othercharacteristics/${methodologyVersionId}`);
        return response.data;
    } catch (exception) {
        return { status: 400, message: exception };
    }
}

const upsertOtherCharacteristics = async (methodologyVersionId, body) => {
    try {
        const response = await formApi.put(`/methodology/${methodologyVersionId}/other-characteristics`, body);

        return { status: 201, data: response.data };
    } catch (error) {
        logger.error(error)
    }
}

export const useOtherCharacteristics = (methodologyVersionId) => {
    return useQuery(
        ['otherCharacteristics', methodologyVersionId],
        () => getOtherCharacteristics(methodologyVersionId),
        { 
            enabled: !!methodologyVersionId,
            staleTime: Infinity
        }
    )
}

const otherCharacteristics = {
    getOtherCharacteristics,
    upsertOtherCharacteristics,
    useOtherCharacteristics,
}

export default otherCharacteristics;