import { useState, useEffect } from 'react';
import { useWatch, useFormContext } from "react-hook-form"
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';

export const CheckboxField = (props) => {
  const { name, label, helperText, defaultChecked, onChange, disabled } = props;

  const [value, setValue] = useState(false);
  const formMethods = useFormContext();
  const watchValue = useWatch({ control: formMethods.control, name });

  useEffect(
    function splitNestedNamesAndAccessValues() {
      // * Split `name` using `.` denoting object access for `watchValue`
      const names = name?.split('.');
      if (names && names?.length > 1) {
        let actualValue;
        names.forEach((_name) => {
          // * This will run multiple times depending on the depth of the nested object of `watchValue`
          // * It will keep on assigning the value of the nested object until the last value of `names` is reached
          if (actualValue) {
            const nextValue = actualValue[_name];
            actualValue = nextValue;
          }
          // * This will only run once, it will assign the value of `watchValue` on the first iteration
          // else actualValue = formMethods.getValues(_name)
          else actualValue = formMethods.getValues(_name);
        });
        setValue(actualValue);
      }
      // * If the split `names` length is equal to 1 it means that the `name` is not nested
      // else setValue(watchValue);
      else setValue(watchValue);
    },
    [watchValue, name]
  );

  const handleOnChange = (e) => {
    formMethods.setValue(name, e.target.checked)
    if (onChange) {
      onChange();
    }
  };
  
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={value}
            onChange={(e) => handleOnChange(e)}
            defaultChecked={defaultChecked}
            disabled={disabled}
          />
        }
        componentsProps={{
          typography: {
            variant: 'label',
          },
        }}
        label={label}
      />
      {helperText && (
        <FormHelperText
          sx={{
            fontSize: '12px',
            color: 'black',
            pl: '12px',
            mt: 0,
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CheckboxField.defaultProps = {
  defaultChecked: false,
  disabled: false,
};
