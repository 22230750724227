import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const DeficiencySummaryContext = createContext({});

export const DeficiencySummaryProvider = ({ children }) => {
    const [concurrencyEventReceived, setConcurrencyEventReceived] = useState(false)
    const [deficiencies, setDeficiencies] = useState([]);
    const [deficiencyCombinations, setDeficiencyCombinations] = useState(null);

    const memoized = useMemo(
        () => ({
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            deficiencies,
            setDeficiencies,
            deficiencyCombinations,
            setDeficiencyCombinations
        }),
        [
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            deficiencies,
            setDeficiencies,
            deficiencyCombinations,
            setDeficiencyCombinations
        ]
    )

    return (
        <DeficiencySummaryContext.Provider value={memoized}>{children}</DeficiencySummaryContext.Provider>
    )
}

DeficiencySummaryProvider.propTypes = {
    children: PropTypes.node
}