export const FUNCTIONAL_RIGHTS_GROUP = {
    CLA_Standard: 1,
    NATG_Author: 2,
    Admin: 3
}

export const FUNCTIONAL_AREA = {
    Manage_Smart_Form: 1,
    Manage_Methodology_Version: 2,
    Manage_KPI_Library: 3,
    Manage_D20_List: 4,
    Manage_Retention: 5,
    Unlock_Finalized_Project: 6
}

export const FUNCTIONAL_RIGHTS_ROUTE_MAPPING = {
    'forms-library': {
        functionalArea: [
            FUNCTIONAL_AREA.Manage_Smart_Form
        ]
    },
    'methodology': {
        functionalArea: [
            FUNCTIONAL_AREA.Manage_Methodology_Version,
            FUNCTIONAL_AREA.Unlock_Finalized_Project
        ]
    },
    'project-administration': {
        functionalArea: [
            FUNCTIONAL_AREA.Manage_Retention,
            FUNCTIONAL_AREA.Unlock_Finalized_Project
        ]
    },
    'lists-library': {
        functionalArea: [
            FUNCTIONAL_AREA.Manage_Smart_Form
        ]
    },
    'kpi-library': {
        functionalArea: [
            FUNCTIONAL_AREA.Manage_KPI_Library
        ]
    }
}

export const FUNCTIONAL_RIGHT_ACTIONS = {
    GET_USER_INFO: 'GET_USER_INFO'
}

export const AUTHORIZATION = {
    EN: {
        NO_ACCESS_MESSAGE: 'You do not currently have access to this page or resource.',
        NO_PROJECT_FORM_LOCATED: ' The project or form cannot be located.',
        MAINTENANCE_MESSAGE: 'AIS is currently in a maintenance window, please check back later.',
        MAINTENANCE_MESSAGE_HEADER: 'Error 503'
    }
}