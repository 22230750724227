export const INHERENT_RISK_FACTOR_SUMMARY = {
    EN: {
        TITLE: {
            PAGE: 'Risk Factor Summary',
            RISK_FACTOR_TITLE: 'Inherent Risk Factor Summary',
            CONTAINER: 'Summary of Risk Factors',
        },
        LINK: {
            EXPAND_ALL: 'Expand All',
            COLLAPSE_ALL: 'Collapse All',
        },
        BUTTON: {
            ADD_ANOTHER_RISK_FACTOR: 'Add Another Risk Factor'
        },
        COLUMN: {
            FIELD: {
                ACTION: 'action',
                RISK_FACTOR_NAME: 'riskFactorName',
                RISK_FACTOR: 'riskFactor',
                EVENTS_OR_CONDITION: 'eventsOrConditions',
                RISK_ASSESSMENT_SUMMARY: 'riskAssessmentSummary',
                UNITS: 'units',
                WORKPAPER_REFERENCE_OR_FORM_NAME: 'workpaperReferenceOrFormName',
                COMMENT: 'comment',
                PROJECT_RISKFACTOR_RISKFACTOR: 'ProjectRiskFactorRiskFactor'
            },
            HEADER: {
                ACTION: '',
                RISK_FACTOR_NAME: 'Risk Factor Name',
                RISK_FACTOR: 'Risk Factor',
                EVENTS_OR_CONDITION: 'Events or conditions that indicate the existence of risks of material misstatement at the assertion level',
                RISK_ASSESSMENT_SUMMARY: 'Risk Assessment Summary',
                UNITS: 'Units',
                WORKPAPER_REFERENCE_OR_FORM_NAME: 'Workpaper Reference/Form Name',
                COMMENT: 'Comment',
            }
        },
        ACTION_ELLIPSIS_OPTIONS: {
            VIEW_OR_EDIT: 'View/Edit',
            DELETE: 'Delete'
        },
        MODAL: {
            DELETE_MESSAGE: "Are you sure you want to delete this? It will permanently delete the data."
        },
        CONTEXT_MENU_OPTIONS: {
            VIEW_EDIT: 'View/Edit',
            DELETE: 'Delete'
        },
        COMMENT: {
            COMMENT: 'Comments',
            INPUT_PLACEHOLDER: "Write a comment here.."
        },
        SUMMARY_TABLE: {
            AUDIT_AREA: "Audit Area",
            SCOTABDS: 'SCOTABDs',
            HIGHER: 'Higher',
            LOWER: 'Lower',
            YES: 'Yes',
            NO: 'No',
            ACCOUNT_BALANCE: "Account Balance",
            IS_RELATED_TO_ACCOUNTING_ESTIMATE: "Does the inherent risk factor effect an accounting estimate?",
            IS_RISK_FACTOR_DEGREE_HIGH: "Indicate the degree to which the inherent risk factor affects the selection and application of the method, assumptions and data used to make the accounting estimate",
            IS_DEGREE_OF_MANAGEMENT_POINT_ESTIMATE_HIGH: "Indicate the degree to which the inherent risk factor affects the selection of management's point estimate",
        }
    }
}