import { useEffect } from 'react'
import CLATailorProcedureTable from '@components/CLATailorProcedureTable/CLATailorProcedureTable'
import {CLASimpleDrawer} from '@ais/components';
import { useUpdateMyPresence, useRoomIdle } from "@components/Concurrency/provider/RoomProvider";

const TailorProceduresDrawer = ({
    isVisible,
    auditArea,
    sectionId,
    onClose,
    onCloseDrawer
}) => 
{   
    const isIdle = useRoomIdle();
    const updateMyPresence = useUpdateMyPresence();

    useEffect(() => {
        onClickClose();
    }, [isIdle])

    const onClickClose = () => {
        updateMyPresence({ focusedId: null, type: 'custom' })
        onClose();
    }

    return <CLASimpleDrawer
        anchor="right"
        isVisible={isVisible}
        allowBackDropCloseClick
        onClose={onClickClose}
        width="80%"
        zIndex="1300"
    >
        <CLATailorProcedureTable
            isVisible={isVisible}
            closeDrawer={onCloseDrawer}
            auditArea={auditArea}
            sectionId={sectionId}
        />
    </CLASimpleDrawer>
}

export default TailorProceduresDrawer