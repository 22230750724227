/**
 * @type {{activeContextMenuId: number | null}}
 */
export const initialState = {
    activeContextMenuId: null
};

/**
 * 
 * @typedef {{type: "CHANGE_ACTIVE_CONTEXT_MENU_ID", payload: number | null}} ProjectManagementActionType
 */

/**
 * 
 * @param {typeof initialState} state 
 * @param {ProjectManagementActionType} action
 */
const ProjectManagementReducer = (state, action) => {
    const { type, payload } = action;
    let newState = { ...state };

    switch (type) {
        case 'CHANGE_ACTIVE_CONTEXT_MENU_ID':
            return {
                ...newState,
                activeContextMenuId: payload
            }
    }
}

export default ProjectManagementReducer;