import { useContext } from 'react'
import { useParams } from "react-router-dom"

import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import Button from "@mui/material/Button"
import { useFinalizedProject } from "@hooks/useProject"
import VFRenderedFieldWrapper from '@components/FormView/VFRenderedFieldWrapper';

import * as Constants from "@constants/index"
import { useOthers } from "@components/Concurrency/store/users"; 
import { useUpdateMyPresence, useRoomIdle } from "@components/Concurrency/provider/RoomProvider";

const TailorProceduresBaseButton = ({onClick, disabled,  ...props}) => (
    <Button
        data-testid="tailor-procedures-btn"
        variant="contained"
        sx={{ marginRight: "30px" }}
        disabled={disabled}
        onClick={onClick}
        {...props}>
            {disabled ? <DoNotDisturbAltIcon style={{ marginRight: 8, fontSize: 15 }}/> : null}
            { Constants.PROJECT_FORM_INSTANCE.PROCEDURE.TAILOR_PROCEDURES }
    </Button>
)

const TailorProcedureButton = ({onClick, sectionId, userId, ...props}) => {

    const { projectId } = useParams();
    const updateMyPresence = useUpdateMyPresence();
    const others = useOthers();
    const focusId = `tailor-procedure-${sectionId}`;
    const isLocked = others.find((user) => user.presence.focusedId === focusId && user.info.userId != userId)
    const isIdentifiedRiskLocked = others.find((user) => user.presence.focusedId === focusId && user.info.userId != userId)
    const identifiedRiskLockingUser = isIdentifiedRiskLocked ? { userId: isIdentifiedRiskLocked.info.userId, alternativeName: isIdentifiedRiskLocked.info.name } : undefined

    const isProjectFinalized = useFinalizedProject(projectId);

    const onClickButton = () => {
        updateMyPresence({ focusedId: focusId, type: 'custom' })
        onClick();
    }
     
    return (<VFRenderedFieldWrapper 
                isLockedByUser={identifiedRiskLockingUser}
                isLocked={isIdentifiedRiskLocked}
                isInstance
            >
            <TailorProceduresBaseButton 
                onClick={onClickButton}
                disabled={isProjectFinalized || isLocked} 
                {...props}
            />
    </VFRenderedFieldWrapper>)
    
}

export default TailorProcedureButton
