import formApi from '@utilities/claApiForm';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"

export const getClientSituationAnswersByProjectFormId = async (projectFormId, projectId) => {
    const { data } = await formApi.get(`/projectforms/client-situations-answer/${projectFormId}/${projectId}`)
    return data
}

export const putClientSituationAnswersByProjectFormId = async (projectFormId, body) => {
    const { data } = await formApi.put(`/projectforms/client-situations-answer/${projectFormId}`, body)
    return data
}

export const usePutClientSituationAnswersByProjectFormId = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (payload) => putClientSituationAnswersByProjectFormId(payload.projectFormId, payload.reqBody),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ClientSituationAnswers');
            }
        }
    )
};

export const useGetClientSituationAnswersByProjectFormId = (projectFormId, projectId) => {
    return useQuery(
        ['ClientSituationAnswers', projectFormId], 
        () => getClientSituationAnswersByProjectFormId(projectFormId, projectId),
        { 
          enabled: !!projectFormId,
          staleTime: Infinity
        }
    )
}