export const stringHelper = {
    truncate: (str, maxLength, truncateChars = '..') => {
        const defaultMaxLength = 50;
        if (!maxLength) {
            return str?.length > defaultMaxLength ? str.substring(0, 49) + truncateChars : str;
        }
        return str?.length > maxLength ? str.substring(0, maxLength) + truncateChars : str;
    },
    truncateWithBool: (str, maxLength = 50, truncateChars = '..') => {
        return str?.length > maxLength
            ? { truncatedString: str.substring(0, 49) + truncateChars, isTruncated: true }
            : { truncatedString: str, isTruncated: false };
    },
    toLowerCaseTrim: (str) => {
        return str && str.toLowerCase().trim();
    },
    isEmptyOrSpaces: (str) => {
        return str === undefined || str === null || str.match(/^ *$/) !== null;
    },
    getAsString: (type, str) => {
        if (type === "object") {
            return str.join(', ');
        } else {
            return str;
        }
    },
    stripPath: (str) => {
        const url = new URL(str);
        const pathname = url.pathname
        if (pathname[pathname.length - 1] === '/') return pathname.slice(0, pathname.length - 1);
        return pathname;
    },
    convertToBase64: (str) => {
        return btoa(encodeURIComponent(JSON.stringify(str)))
    },
    stripHTMLTagsFromString: (str) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(str, "text/html");
        return doc.body.textContent;
    }
    
};

export const capitalizeFirstLetter = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
}