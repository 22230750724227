import React from "react"
import {Flex} from '@ais/palette';
import { Select } from "@palette"
import styles from "./CLATailorProcedureFilter.module.css"
import clsx from "clsx"
import { useTailoringFilterProcedureContext } from "./state/TailoringFilterProcedureContext"
import { PROJECT_FORM_INSTANCE } from '@constants/index';



const CLATailorProcedureFilter = ({ className }) => {

    const { selectedFilter, setSelectedFilter } = useTailoringFilterProcedureContext()
    const { TAILORING_PROCEDURES_FILTER } = PROJECT_FORM_INSTANCE.PROCEDURE
    const filterIconClassName = clsx(
        styles["filter__icon"]
    )

    const filterLabelClassName = clsx(
        styles["filter__label"]
    )

    return (
        <Flex align="center" direction="row" className={clsx(styles["filter"], className)}>
            <svg className={filterIconClassName} width="24" height="22" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.2963 18.4439V36.44L22.7037 30.0639V18.4439L37 0.13365H0L14.2963 18.4439ZM21.1431 28.5654L18.5002 31.2534V18.2868H21.1431V28.5654ZM16.1885 15.6936H20.8116L31.7143 2.72702H5.28573L16.1885 15.6936Z" />
            </svg>


            <label className={filterLabelClassName}>Filter</label>

            <Flex align="center" direction="row" className={styles["filter__content"]}>
                <Select defaultValue={TAILORING_PROCEDURES_FILTER.OPTIONS[0]} value={selectedFilter} onChange={setSelectedFilter}>
                    <Select.Button className={styles["filter__button"]}>{ selectedFilter?.label ?? "Select Filter" }</Select.Button>
                    <Select.Options>
                        {TAILORING_PROCEDURES_FILTER.OPTIONS.map((filter, idx) => (
                            <Select.Option key={idx} value={filter}>{ filter.label }</Select.Option>
                        ))}
                    </Select.Options>
                </Select>
            </Flex>
        </Flex>
    )

}

export default CLATailorProcedureFilter
