import { Popover as HeadlessPopover } from "@headlessui/react"
import clsx from "clsx"
import styles from "./Popover.module.css"

const PopoverButton = (props) => {
    
    const { className, children, disabled, ...other } = props

    const componentClassName = clsx(
        styles["popover__button"],
        disabled && styles["popover__button--disabled"],
        className
    )

    return (
        <HeadlessPopover.Button className={componentClassName} disabled={disabled} {...other}>
            { children }
        </HeadlessPopover.Button>
    )
}

const PopoverContent = (props) => {

    const { className, children, ...other } = props

    const componentClassName = clsx(
        styles["popover__content"],
        className
    )

    return (
        <HeadlessPopover.Panel className={componentClassName} {...other}>
            { children }
        </HeadlessPopover.Panel>
    )
}

export const Popover = (props) => {
    const {
        className,
        ...other
    } = props

    const componentClassName = clsx(
        styles["popover"],
        className
    )

    return (
        <HeadlessPopover className={componentClassName} {...other} />
    )
}

Popover.Button = PopoverButton
Popover.Content = PopoverContent

export default Popover
