import { FormControl, OutlinedInput, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

export const BasicTextField = (props) => {
  const { name, label, placeholder, maxLength, formMethods, required } = props;

  return (
    <FormControl fullWidth>
      <Typography
        variant="label"
        sx={{
          marginBottom: '8px',
        }}
      >
        {label}
      </Typography>
      <Controller
        control={formMethods.control}
        name={name}
        render={({ field, fieldState }) => (
          <OutlinedInput
            placeholder={placeholder}
            value={field.value}
            onChange={field.onChange}
            inputProps={{ maxLength }}
            error={!!fieldState.error}
            sx={{
              marginBottom: 0,
            }}
          />
        )}
        rules={{
          required,
        }}
      />
    </FormControl>
  );
};

BasicTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  formMethods: PropTypes.object,
  required: PropTypes.bool,
};

BasicTextField.defaultProps = {
  maxLength: 256,
  required: false,
};
