import { createContext, useContext, useMemo } from "react"
import { useParams } from "react-router-dom"
import { INTERNAL_CONTROLS } from '@constants/customForm';

import { useUpdateFirstLevelInternalControlData } from '@services/customForm/internalControls';

import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext'; 

const CommunicationOfSignificantsMattersContext = createContext()
const {
    COMMUNICATION_OF_SIGNIFICANT_MATTERS: {
        PEOPLE_WITH_ENTITY_COMMENT_FIELD_NAME,
        MANAGEMENT_COMMENT_FIELD_NAME,
        EXTERNAL_COMMENT_FIELD_NAME
    }
} = INTERNAL_CONTROLS.EN;

export const CommunicationOfSignificantsMattersContextProvider = ({ children }) => {
    const { projectFormId, projectId } = useParams();

    const {
        formattedInternalControls,
        changeFirstLevelField
    } = useInternalControlsContext();
    
    const { mutate: updateFirstLevelInternalControlData, isError, isSuccess, isLoading } = useUpdateFirstLevelInternalControlData();

    const internalControls = formattedInternalControls;

    const handleSave = (field, value) => {
        if (internalControls[field] === value) return;
        changeFirstLevelField(field, value);

        updateFirstLevelInternalControlData(
            { 
                projectFormId, 
                payload: {
                    ProjectFormId: projectFormId,
                    ProjectInternalControlId: internalControls.ProjectInternalControlId,
                    [field]: value
                }, 
                projectId 
            },
        );
    };

    return (
        <CommunicationOfSignificantsMattersContext.Provider value={
            {
                internalControls,
                isLoading,
                isError,
                isSuccess,
                actions: {
                    handleSave
                }
            }
        }>
            {children}
        </CommunicationOfSignificantsMattersContext.Provider>
    )
}

export const useCommunicationOfSignificantsMattersContext = () => {
    const ctx = useContext(CommunicationOfSignificantsMattersContext)
    if (!ctx) {
        throw new Error("`useCommunicationOfSignificantsMattersContext` must be used within CommunicationOfSignificantsMattersContextProvider")
    }
    return ctx
}
