import styles from './VFSectionV2.module.css';
import { sanitize } from 'dompurify';

export const VFSectionV2Description = ({ description }) => {
  if (!description) {
    return (<></>)
  }
  else {
    return (
      <div className={styles['section-description']}>
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{
            __html: sanitize(description, {
              ADD_ATTR: ['target'],
            }),
          }}
        />
      </div>
    )
  }
}