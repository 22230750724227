import { useEffect } from 'react';
import { useWatch, useFormContext } from "react-hook-form"
import { FormControl, Typography, Grid } from '@mui/material';
import { INTERNAL_CONTROLS_FORM_TYPE_ID } from "@ais/constants"
import { CheckboxField } from '.';

export const ExternalAccess = (props) => {
  const { allowExternalAccessName, makeAnswerableName, viewableOnly = false, formTypeId = null } = props;
  const { setValue, control } = useFormContext();
  const watchValue = useWatch({ control, name: allowExternalAccessName });
  useEffect(() => {
    if (!viewableOnly) {
      const value = watchValue;
      if (!value) setValue(makeAnswerableName, false);
    }
    else if (viewableOnly) {
      setValue(makeAnswerableName, false);
    }
  }, [viewableOnly, watchValue]);

  return (
    <FormControl fullWidth>
      <Typography
        variant="label"
        sx={{
          marginBottom: '8px',
        }}
      >
        External Access
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <CheckboxField
            name={allowExternalAccessName}
            label="Allow external access"
            disabled={formTypeId ? formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID : false}
          />
        </Grid>
        {!viewableOnly && (
          <Grid item xs={6}>
            <CheckboxField
              name={makeAnswerableName}
              label="Make answerable"
              disabled={!watchValue}
            />
          </Grid>
        )}
      </Grid>
    </FormControl>
  );
};
