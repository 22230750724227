import { CLADialogConfirm } from '@ais/components';
import apiClientFactory from '@utilities/apiClientFactory.js';
import { VERSION_REFRESH_MODAL_HEADING, VERSION_REFRESH_MODAL_DESCRIPTION, VERSION_REFRESH_MODAL_CONFIRM_TEXT } from '@constants/index';

export const VersionErrorDialog = () => {

    const refreshApplication = () => {
        // Refresh with a cacheless refresh (true param) to get latest version
        window.location.reload(true);
    }

    return (
        <CLADialogConfirm
            visible={apiClientFactory.isVersionExpired}
            title={VERSION_REFRESH_MODAL_HEADING}
            message={VERSION_REFRESH_MODAL_DESCRIPTION}
            onConfirm={() => refreshApplication()}
            singleButton={true}
            confirmText={VERSION_REFRESH_MODAL_CONFIRM_TEXT}
            variant="contained"
        />
    )
};
