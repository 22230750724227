/**
 * Open the url in new tab (can be relative path)
 * @param {String} url
 */
export const openUrlInNewTab = (url) => {
  window.open(url, "_blank");
};

export const openUrlInCurrentTab = (url) => {
  window.location = url;
};

