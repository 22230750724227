import { createContext } from 'react'

const TailoringProcedureContext = createContext()

export const TailoringProcedureProvider = ({children, value}) => {
  return(
    <TailoringProcedureContext.Provider value={value}>
      {children}
    </TailoringProcedureContext.Provider>
  )
}

export default TailoringProcedureContext