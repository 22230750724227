import { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApplicationReducer } from './ApplicationReducer';
const initialData = {
    isLoading: false,
    loadMap: new Map(),
    fetchingQueryCount: 0,
    globalQueryLoading: true,
}

export const ApplicationContext = createContext({});

export const ApplicationProvider = ({ children }) => {
    const [application, dispatchApplication] = useReducer(ApplicationReducer, initialData);

    const memoized = useMemo(
        () => ({ application, dispatchApplication }),
        [application, dispatchApplication]
    )

    return (
        <ApplicationContext.Provider value={memoized}>{children}</ApplicationContext.Provider>
    )
}

ApplicationProvider.propTypes = {
    children: PropTypes.node
}