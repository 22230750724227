import React, { createContext, useContext, useReducer, useEffect } from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined'; 

import { CollapseIcon, ExpandIcon as ExpandIconAsset } from "@ais/assets"

import style from "./CLAAccordion.module.css"
import { CLA_ACCORDION } from "@ais/constants"
import { CLATooltip } from "@ais/components"
import "./CLAAccordionCustomStyle.css"

const Actions = {
    TOGGLE: "toggle"
}

const CLAAccordionProContext = createContext()

const reducer = (state, action) => {
    switch (action.type) {
        case Actions.TOGGLE:
            return { expanded: action.payload }
        default:
            return state
    }
}

const CLAAccordionProProvider = ({ defaultExpanded, children }) => {
    const [state, dispatch] = useReducer(reducer, {
        expanded: defaultExpanded ? defaultExpanded : false
    })

    const expandPanel = (id) => {
        dispatch({
            type: Actions.TOGGLE,
            payload: id
        })
    }

    return (
        <CLAAccordionProContext.Provider value={{ expanded: state.expanded, expandPanel }}>
            {children}
        </CLAAccordionProContext.Provider>
    )
}

const useCLAAccordionProContext = () => {
    const ctx = useContext(CLAAccordionProContext)
    if (!ctx) {
        throw new Error("useCLAAccordionProContext must be used within CLAAccordionProProvider")
    }
    return ctx
}

const Panel = styled(MuiAccordionDetails, {
    shouldForwardProp: (prop) => prop !== 'panelStyles'
})(({ theme, panelStyles }) => ({
    padding: theme.spacing(0),
    ...(panelStyles && panelStyles)
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme, ...props}) => ({
    border: `1px solid rgba(151, 151, 151, 0.25)`,
    boxsizing: 'border-box',
    borderRadius: '4px',
    boxShadow: 'none',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&$expanded': {
        margin: "0 0 44px",
    },
    [`& .${props?.className}`] : {
        ...style[props?.className]
    }
}))

const ExpandIcon = () => {
    return (
        <Box
            sx={{
                ".Mui-expanded & > .collapsIconWrapper": {
                    display: "none"
                },
                ".expandIconWrapper": {
                    display: "none"
                },
                ".Mui-expanded & > .expandIconWrapper": {
                    display: "block"
                },
            }
            }
        >
            <div className="expandIconWrapper">
                <img alt={CLA_ACCORDION.EN.EXPAND} src={ExpandIconAsset} />
            </div>
            <div className="collapsIconWrapper">
                <img alt={CLA_ACCORDION.EN.COLLAPSE} src={CollapseIcon} />
            </div>
        </Box>
    )
}

const Content = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandIcon sx={{ fontSize: '0' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    minHeight: '60px',
    padding: '0px 28px',
    '& .MuiAccordionSummary-content': {
        fontFamily: 'Georgia',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '17px',
        paddingLeft: '16px',
        lineHeight: '19px',
        color: '#2e334e',
        cursor: 'default',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}))

const Trigger = (props) => {
    const { id, title, styles, children, customClassName = {title: '', subtitle: ''} } = props
    return (
        <Content id={id} data-test="accordion-title" sx={{...styles}}>
            <div className={customClassName.title}>
                <div>{ title }</div>

                {
                    props.tooltip &&
                    <CLATooltip title={props.tooltip}>
                        <HelpOutlinedIcon sx={{ paddingLeft: "6px", width: "21px", color: "#2e334e" }} />
                    </CLATooltip>
                }
            </div>

            <div data-test="accordion-subtitle" className={`${style.addProject} ${customClassName.subtitle}`} onClick={(e) => e.stopPropagation()}>
                { children }
            </div>
        </Content>
    )
}

const Root = (props) => {
    const { expandedPanel, children } = props

    return (
        <CLAAccordionProProvider defaultExpanded={expandedPanel}>
            {children}
        </CLAAccordionProProvider>
    )
}

export const CLAAccordionPro = (props) => {
    const {
        id,
        children,
        onValidateExpandedList = undefined,
        customClassName = { container: '', accordion: '' },
        disableTransitions = false,
        styles, 
        accordionStyles,
        expandedPanel = undefined,
    } = props;

    const { expanded, expandPanel } = useCLAAccordionProContext();

    useEffect(() => {
        if(!expandedPanel) return;
        expandPanel(expandedPanel)
    }, [expandedPanel])

    const validateExpandedList = () => {
        if (onValidateExpandedList) {
            onValidateExpandedList(expanded);
        }
        if (expanded instanceof Array) {
            return expanded.includes(id);
        } else {
            return expanded === id;
        }
    };

    return (
        <Box sx={styles} className={`${style.accordionContainer} ${customClassName.container}`}>
            <Accordion
                className={customClassName.accordion}
                expanded={validateExpandedList()}
                onChange={() => expandPanel(id === expanded ? false : id)}
                sx={accordionStyles}
                // Conditionally include this prop
                // so that other TransitionProps do not get overriden
                {...(disableTransitions
                    ? {
                          TransitionProps: {
                              timeout: 0,
                              unmountOnExit: true
                          }
                      }
                    : {})}
            >
                {children}
            </Accordion>
        </Box>
    );
};

CLAAccordionPro.Root = Root
CLAAccordionPro.Panel = Panel
CLAAccordionPro.Trigger = Trigger

export default CLAAccordionPro
