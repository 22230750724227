export const DEFICIENCY_EVALUATION_FORM_TYPE_ID = 6;

export const DEFICIENCY_FORM_INSTANCE = {
    EN: {
        COMMUNICATION_OF_DEFICIENCIES: {
            SECTION_HEADER: 'Document the form of communication method for each deficiency/combination of deficiency',
            EXPAND_ALL: 'Expand All',
            COLLAPSE_ALL: 'Collapse All',
            MATERIAL_WEAKNESS_HEADER: 'Material Weakness',
            MATERIAL_WEAKNESS_EMPTY: 'No Material Weakness has been identified',
            SIGNIFICANT_DEFICIENCY_HEADER: 'Significant Deficiency',
            SIGNIFICANT_DEFICIENCY_EMPTY: 'No Significant Deficiency has been identified',
            MANAGEMENT_COMMENT_DEFICIENCY_HEADER: 'Management Comment / Deficiency in Internal Control',
            MANAGEMENT_COMMENT_DEFICIENCY_EMPTY: 'No Management Comment / Deficiency in Internal Control have been identified',
            MANAGEMENT_COMMENT_DEFICIENCY: {                                                                                  
                MERITS_MANAGEMENT: 'Merits the attention of management',
                NAME_PERSON_COMMUNICATED_TO: 'Name of the person the deficiencies were communicated to',
                DATE: 'Date',                       
            },
            MATERIAL_WEAKNESS: {
                COMMUNICATED_TO: 'Who was the deficiency communicated to?',
                COMMUNICATED_BY: 'How was the deficiency communicated?',
                WRITTEN: 'Written',
                ORAL: 'Oral'
            },
            COLUMN_HEADERS: {
                DEFICIENCY_NAME: 'Deficiency Name',
                DEFICIENCY_DESCRIPTION: 'Deficiency Description',
                WORKPAPER_FORMNAME: 'Workpaper Reference/Form Name',
                DETERMINATION: 'Determination',
                FORM_OF_COMMUNICATION: 'Form of Communication',
                COMMENT: 'Comment'
            },
            GOVERNANCE: 'Governance',
            MANAGEMENT: 'Management'

        },
        EVALUATION_OF_DEFICIENCIES: {
            DRAWER: {
                DEFICIENCY_COMBINATION: 'Deficiency combinations',
                COMBINATION_NAME: 'Deficiency Combination Name',
                COMBINATION_NAME_PLACEHOLDER: 'Enter deficiency combination name',
                COMBINATION_DESCRIPTION: 'Deficiency Combination Description',
                COMBINATION_DESCRIPTION_PLACEHOLDER: 'Enter deficiency combination description',
                DEFICIENCIES: 'Deficiencies',
                DEFICIENCIES_PLACEHOLDER: 'Select'
            },

            SECTION_HEADER: 'Evaluate each deficiency to determine whether, individually or in combination, they constitute significant deficiencies or material weaknesses.  To combine deficiencies with similar characteristics, click "Add Deficiency Combination"',
            ADD_DEFICIENCY_COMBINATION: 'ADD DEFICIENCY COMBINATION',
            TOOLTIPS: {
                MAGNITUDE: `Factors that affect the magnitude of a misstatement that might result from a deficiency, or deficiencies, in internal control include, but are not limited to, the following:

                • The financial statement amounts or total of transactions exposed to the deficiency
                • The volume of activity (in the current period or expected in future periods) in the class of transactions or account balance exposed to the deficiency
                In evaluating the magnitude of the potential misstatement, the maximum amount by which an account balance or total of transactions can be overstated generally is the recorded amount, whereas understatements could be larger.`,
                OCCURENCE: `A reasonable possibility exists when the likelihood of an event occurring is either reasonably possible or probable as defined as follows:

                Reasonably possible. The chance of the future event or events occurring is more than remote but less than likely.
             
                Probable. The future event or events are likely to occur.
             
                Risk factors affect whether there is a reasonable possibility that a deficiency, or a combination of deficiencies, in internal control will result in a misstatement of an account balance or disclosure. The factors include, but are not limited to, the following:
                
                • The nature of the financial statement classes of transactions, account balances, disclosures, and assertions involved
                • The cause and frequency of the exceptions detected as a result of the deficiency, or deficiencies, in internal control
                • The susceptibility of the related asset or liability to loss or fraud
                • The subjectivity, complexity, or extent of judgment required to determine the amount involved
                • The interaction or relationship of the control(s) with other controls
                • The interaction with other deficiencies in internal control
                • The possible future consequences of the deficiency, or deficiencies, in internal control
                • The importance of the controls, such as the following, to the financial reporting process:
                • General monitoring controls (such as oversight of management)             
                • Controls over the prevention and detection of fraud             
                • Controls over the selection and application of significant accounting policies             
                • Controls over significant transactions with related parties             
                • Controls over significant unusual transactions             
                • Controls over the period-end financial reporting process (such as controls over nonrecurring journal entries)

                The evaluation of whether a deficiency in internal control presents a reasonable possibility of misstatement may be made without quantifying the probability of occurrence as a specific percentage or range. Also, in many cases, the probability of a small misstatement will be greater than the probability of a large misstatement.`
            },
            DETERMINATIONS: {
                MATERIAL_WEAKNESS: 'Material Weakness',
                SIGNIFICANT_DEFICIENCY: 'Significant Deficiency',
                OTHER_DEFICIENCY: 'Other Deficiency'
            }
        },
        ID: 'c1d76a64-afc1-4335-9578-3fe1a27b9f95' // Field id for deficiency summary form. Used for locking purposes.
    }
}