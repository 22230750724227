import { useContext } from 'react';
import { useFormContext, useWatch } from "react-hook-form";
import { Grid, Typography, Box } from '@mui/material';
import { DIALOG } from '@constants/forms';
import { BasicTextField } from '../BasicTextField';
import { FormulaVariableDropdown } from './FormulaVariableDropdown';
import { FormulaOperators } from './FormulaOperators';
import { FormulaCanvas } from './FormulaCanvas';
import { ToggleableTextarea } from '../ToggleableTextArea';
import { RadioSelect } from '../RadioSelect';
import { CheckboxField } from '../CheckboxField';
import { FORMULA_CONDITIONS } from '@constants/forms'
import { SchemaContext } from '@ais/providers';
import { ExternalAccess } from '../ExternalAccess';

const  { IF } = FORMULA_CONDITIONS

const decimalPlacesOptions = [
  {
    label: '0',
    value: '0',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
];

const validationOptions = [
  {
    label: 'Number',
    value: 'numerical',
  },
  {
    label: 'US Currency ($)',
    value: 'usCurrency',
  },
  {
    label: 'Percentage (%)',
    value: 'percentage',
  },
];

const widthOptions = [
  {
    label: '25%',
    value: 25,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '75%',
    value: 75,
  },
  {
    label: '100%',
    value: 100,
  },
];

export const FormulaSettings = () => {
  const { control, setValue, formState: { errors } } = useFormContext();
  const watchFormula = useWatch({ control, name: 'formula' }) ?? [];
  const { LABEL, PLACEHOLDER } = DIALOG.SETTINGS;
  const { schema } = useContext(SchemaContext);

  return (
    <Grid container rowSpacing={6} columnSpacing={22}>
      <Grid item xs={12}>
        <BasicTextField
          name="label"
          label={LABEL.QUESTION_LABEL}
          placeholder={PLACEHOLDER.QUESTION}
          maxLength={256}
          showRequired
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontSize: '16px', color: 'gray.main' }}
        >
          Instructions: Drag and drop Variables and Operators onto the Preview area below.
          Remove items by dragging them outside of the area.
        </Typography>
      </Grid>

      <Grid item container xs={12}>
        <Grid item xs={6}>
          <FormulaVariableDropdown schema = {schema}/>
        </Grid>

        <Grid item xs={6}>
          <FormulaOperators isConditional={!!(watchFormula.find(el => el.operator === IF))} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FormulaCanvas
          value={watchFormula}
          onChange={formula => setValue('formula', formula, { shouldValidate: true })}
          invalid={errors['formula']}
          isConditional={!!(watchFormula.find(el => el.operator === IF))}
        />
      </Grid>

      <Grid item xs={12}>
        <CheckboxField
          name="displayFormula"
          label="Display formula"
        />
      </Grid>

      <Grid item container xs={12}>
        <Grid item xs={8}>
          <RadioSelect
            name="validation"
            label="Display results as:"
            options={validationOptions}
          />
        </Grid>

        <Grid item xs={4}>
          <RadioSelect
            name="decimalPlaces"
            label="Decimal Places:"
            options={decimalPlacesOptions}
            sx={{ justifyContent: 'flex-end' }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{ height: '1px', border: '1px solid #717171', opacity: 0.25 }}
        />
      </Grid>

      <Grid
        item
        container
        xs={12}
        height="100%"
        sx={{ pt: '1.5em !important' }}
      >
        <Grid item xs={6}>
          <ToggleableTextarea
            name="tooltip"
            label={LABEL.ADD_TOOLTIP}
            placeholder={PLACEHOLDER.TEXT}
            toggleable
          />
        </Grid>
        <Grid item xs={6} display="flex" flexDirection="column">
          <RadioSelect
            name="width"
            label={LABEL.DISPLAY_WIDTH}
            options={widthOptions}
            sx={{ justifyContent: 'flex-end' }}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <ExternalAccess
          allowExternalAccessName="visibleToClient"
          makeAnswerableName="editableByClient"
          viewableOnly
        />
      </Grid>
    </Grid>
  );
};
