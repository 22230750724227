import React from "react";
import { Box, TablePagination } from "@mui/material/";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import DataGridPaginationAction from "./DataGridPaginationAction";

export const DataGridPagination = ({
  rows,
  count,
  rowsPerPageOptions,
  rowsPerPage,
  labelRowsPerPage,
  page,
  setRowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  bottomBorderStyle = "1px solid rgba(224, 224, 224, 1)"
}) => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={labelRowsPerPage}
          SelectProps={{
            inputProps: {
							'aria-label': 'rows per page',
							IconComponent: ArrowDropDownIcon,
							style: { fontSize: '11px' }
						},
						native: true,
						value: rowsPerPage
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={(subProps) => (
            <DataGridPaginationAction {...subProps} page={page} rows={rows} setRowsPerPage={setRowsPerPage} />
          )}
        />
      </Box>
    </div>
  );
};

export default DataGridPagination;
