import { useState, useEffect } from 'react';
import {
    Autocomplete,
    TextField
} from '@mui/material';

const TextFieldTags = (props) => {
    const { label, placeholder, options, onChange } = props;
    const [autoCompleteOptions, setAutoCompleteOptions] = useState(options);
    const [inputValue, setInputValue] = useState('');

    const handleChange = (values) => {
        if (onChange) {
            onChange(values);
        }
    }

    return (<Autocomplete
        multiple
        id="tags-filled"
        options={[]}
        freeSolo
        clearOnBlur={true}
        onChange={(e, values) => {
            setAutoCompleteOptions(values);
            handleChange(values);
        }}
        value={autoCompleteOptions}
        inputValue={inputValue}
        onInputChange={(e, values) => { setInputValue(values) }}
        onBlur={(e) => {
            const newItem = e.target.value;
            if (newItem !== '') {
                setAutoCompleteOptions([...autoCompleteOptions, newItem]);
                handleChange([...autoCompleteOptions, newItem]);
            }
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                variant="outlined"
                label={label}
                placeholder={placeholder}
            />
        )}
    />
    )
}

export default TextFieldTags;