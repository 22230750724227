export const transformAnswers = (answers)=> {
    const ans = answers.reduce((map, obj) => {
        if(obj?.questionId)
            map[obj.questionId.toLowerCase()] = obj.answer
        else
            map[obj.id] = obj.value
        return map;
    }, {});
    
    const newTransformedAnswer = {} 

    for (const key in ans) { 
        if(Array.isArray(ans[key]) && ans[key].some(v => typeof v === 'object' && v !== null)) {
            if(ans[key].some(v => typeof v === 'object' && 'rowLabel' in v)){
                newTransformedAnswer[key] = ans[key].map(item => {
                    const newObj = {
                        rowId: item.id,
                        rowLabel: item.rowLabel,
                    };

                    if(item.hasOwnProperty('values')) item.values.forEach(value => {
                        newObj[value.id] = value.value
                    })

                    return newObj
                })
            } 
            else {
                const qgrpAnswersArray = []
                ans[key].forEach((qgrpObj, qIndex) => {
                    qgrpAnswersArray[qIndex] = transformAnswers(qgrpObj)
                })
                newTransformedAnswer[key] = qgrpAnswersArray

            }
        } 
        else newTransformedAnswer[key] = ans[key]
    }
    return newTransformedAnswer
}

