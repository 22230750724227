// constants
import * as Constants from "@constants/index";

export const initialState = {
    apiRefInContext: null,
    duplicatedProjectFormID: 0,
    isSaveClickInList: false,
    addToList: false,
    hasOnlyOneUnit: false,
    units: [],
    unitsSelected: [],

    isEditMode: false,
    rowData: {}
}

const {
    ON_CLICK_AWAY_LIST_VIEW,
    ON_DUPLICATE_ACTION,
    UPDATE_UNIT_SELECTED,
    SAVE_FORM_IN_LIST,
    ADD_FORM_TO_LIST,
} = Constants.CONTEXT_API_METHOD.DATAGRID_CONTEXT;

const claDatagridReducer = (state, action) => {
    const { type, payload } = action;
    let newState = { ...state };

    switch (type) {
        case ON_CLICK_AWAY_LIST_VIEW:
            return {
                ...newState,
                apiRefInContext: payload.apiRefInContext,
            }
        case ON_DUPLICATE_ACTION:
            return {
                ...newState,
                duplicatedProjectFormID: payload.duplicatedProjectFormID,
            }
        case UPDATE_UNIT_SELECTED:
            return {
                ...newState,
                unitsSelected: payload.unitsSelected,
            }
        case SAVE_FORM_IN_LIST:
            return {
                ...newState,
                isSaveClickInList: payload.isSaveClickInList,
            }
        case ADD_FORM_TO_LIST:
            return {
                ...newState,
                addToList: payload.addToList,
                units: payload.units,
            }

        // demo only
        case 'ON_EDIT_MODE':
            return {
                ...newState,
                isEditMode: payload.isEditMode,
                rowData: payload.rowData,
            }
    }
};

export default claDatagridReducer;