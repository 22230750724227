import clientApi from "@utilities/claApiClient";
import { useQuery, useQueries } from "@tanstack/react-query";
import logger from '@utilities/logService'

const fetchGeneralLedgerCurrentYearSummary = async (appDataInstance, trialBalanceIds, projectId) => {
  try {
    const body = {
      appDataInstance,
      trialBalanceIds
    };
    const { data } = await clientApi.post(`/clc-audit/general-ledger/currentyearsummary/${projectId}`, body);
    return data;
  } catch (error) {
    logger.error(error);
  }
};

const fetchGeneralLedgerCurrentYear = async (GeneralLedgerId, AccountNumber, projectId) => {
  try {
    const { data } = await clientApi.get(`/clc-audit/general-ledger/${GeneralLedgerId}/${AccountNumber}/${projectId}`);

    return data;
  } catch (error) {
    logger.error(error);
  }
};

const fetchExportGeneralLedgerTransactionDetails = async (correlationDetailIds, projectId) => {
  try {
    const body = { correlationDetailIds };
    const { data } = await clientApi.post(`/clc-audit/general-ledger/export-details/${projectId}`, body);
    return data;
  } catch (error) {
    logger.error(error);
  }
};

const fetchTrialBalanceGeneralLedger = async (correlationDetailId, projectId) => {
  try {
    const { data } = await clientApi.get(`clc-audit/general-ledgers/${projectId}`, { 
      params: {
        correlationDetailId
      }
    })

    return data?.at();
  } catch (error) {
    logger.error(error);
  }
}

const generalLedgerServices = {
  fetchGeneralLedgerCurrentYearSummary,
  fetchTrialBalanceGeneralLedger
}

export default generalLedgerServices;

// EXPORTS ====================================================================
export const useGetGeneralLedgerCurrentYearSummary = (appDataInstanceId, trialBalanceIds, projectId) => {
  return useQuery(
    ["GeneralLedgerCurrentYearSummary", appDataInstanceId, trialBalanceIds],
    () => fetchGeneralLedgerCurrentYearSummary(appDataInstanceId, trialBalanceIds, projectId),
    {
      enabled: !!appDataInstanceId 
        && !!trialBalanceIds && Array.isArray(trialBalanceIds) && trialBalanceIds.length > 0,
      retry: 3,
      retryDelay: 1000
    }
  );
};

export const usePostGeneralLedgerCurrentYear = (GeneralLedgerId, AccountNumber, projectId) => {
  return useQuery(
    ["GeneralLedgerCurrentYear", GeneralLedgerId, AccountNumber],
    () => fetchGeneralLedgerCurrentYear(GeneralLedgerId, AccountNumber, projectId),
    {
      enabled: !!GeneralLedgerId && !!AccountNumber,
      retry: 3,
      retryDelay: 1000
    }
  );
};

export const useExportGeneralLedgerTransactionDetails = (correlationDetailIds, projectId) => {
  return useQuery(
    ["ExportGeneralLedgerTransactionDetails", correlationDetailIds],
    () => fetchExportGeneralLedgerTransactionDetails(correlationDetailIds, projectId),
    {
      enabled: !!correlationDetailIds && !!correlationDetailIds.length,
      retry: 3,
      retryDelay: 1000
    }
  );
};

const TRIAL_BALANCE_GENERAL_LEDGER_QRY_FACTORY = {
  all: [ "FetchTrialBalanceGeneralLedger" ],
  list: () => [ ...TRIAL_BALANCE_GENERAL_LEDGER_QRY_FACTORY.all, "list" ],
  details: (correlationDetailId) => [ ...TRIAL_BALANCE_GENERAL_LEDGER_QRY_FACTORY.all, "details", correlationDetailId ]
}

export const useFetchTrialBalanceGeneralLedger = (correlationDetailId, projectId) => {
  return useQuery(
    TRIAL_BALANCE_GENERAL_LEDGER_QRY_FACTORY.details(correlationDetailId),
    () => fetchTrialBalanceGeneralLedger(correlationDetailId, projectId),
    {
      enabled: !!correlationDetailId && !!correlationDetailId.length,
      retry: 3,
      retryDelay: 1000
    }
  );
};

export const useFetchTrialBalanceGeneralLedgers = (correlationDetailIds, projectId) => {
  const queries = useQueries({
    queries: (correlationDetailIds ?? []).map((correlationDetailId) => ({
      queryKey: TRIAL_BALANCE_GENERAL_LEDGER_QRY_FACTORY.details(correlationDetailId),
      queryFn: async () => await fetchTrialBalanceGeneralLedger(correlationDetailId, projectId),
      enabled: !!correlationDetailId && !!correlationDetailId.length > 0,
      retry: 3,
      retryDelay: 1000
    }))
  });

  return queries.filter(gl => !!gl.data);
};
