import React from 'react'
import styles from './styles.module.css';
import PropTypes from 'prop-types';

const ContainerHeader = (props) => {
    const { text, classNames } = props;

    /** Checks for empty spaces */
    if(!text.split(" ").join("")) {
        return <React.Fragment />
    }

    return (
        <div className={`${styles["container-header"]} ${classNames}`}>
            <div className={styles["container-header__text"]}>{text}</div>
        </div>
    )
}
ContainerHeader.propTypes = {
    text: PropTypes.string.isRequired,
    classNames: PropTypes.string,
};

export default ContainerHeader
