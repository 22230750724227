import { io } from "socket.io-client";
import { useRef, useEffect, useState, useMemo } from 'react';
import { stringHelper as string } from '@ais/utilities';
import PropTypes from 'prop-types';
import { SOCKET_DEFAULT_OPTIONS, SOCKET_EVENT_NAMES } from '@ais/constants';
import { SocketContext } from './SocketContext';
import { useMsal } from '@azure/msal-react';
import { useParams } from "react-router-dom";
import logger from '@utilities/logService'

const useDefaultSocketSettings = (...args) => {
    const { current: socket } = useRef(io(...args));
    useEffect(() => {
        return () => {
            logger.info('default socket settings cleanup')
            socket?.removeAllListeners();
            socket?.close();
        };
    }, [socket]);
    return [socket];
};

export const SocketProvider = ({ children, url }) => {
    const wsBaseUrl = url;
    const proxyPath = string.stripPath(wsBaseUrl);
    const { projectFormId } = useParams();
    const { accounts } = useMsal();
    const userId = accounts[0].localAccountId.toUpperCase();
    const token = accounts[0].idTokenClaims.accessToken;

    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const socket = useDefaultSocketSettings(wsBaseUrl.replace(proxyPath, ''), {
        ...SOCKET_DEFAULT_OPTIONS,
        path: proxyPath + SOCKET_DEFAULT_OPTIONS.path,
        auth: {
            token: token
        },
        query: {
            projectFormId,
            userId
        }
    })[0];

    const tryReconnect = () => {
        setTimeout(() => {
            logger.info('trying to reconnect')
            socket.io.open((err) => {
                logger.error(err)
                if (err) {
                    tryReconnect();
                }
            });
        }, 2000);
    }

    socket.io.on("close", tryReconnect);

    socket.on(SOCKET_EVENT_NAMES.CONNECT, () => {
        setIsSocketConnected(true)
    })

    socket.on(SOCKET_EVENT_NAMES.DISCONNECT, () => {
        setIsSocketConnected(false)
    })

    useEffect(() => {
        socket.connect();
        return () => {
            socket.disconnect();
        };
    }, [socket]);


    const memoized = useMemo(() => ({ socket, isSocketConnected }), [socket, isSocketConnected]);

    return (
        <SocketContext.Provider value={memoized}>{children}</SocketContext.Provider>
    )

}

SocketProvider.propTypes = {
    children: PropTypes.node
}