export default {
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
    fontWeight: "700",
    fontSize: "16px",
    fontFamily: "museo-sans, sans-serif",
    lineHeight: "19.2px",
    backgroundColor: "#F6F6F6",
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
    outline: "none",
    border: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell": {
    outline: "none",
    borderBottom: "none",
    borderTop: "1px solid rgba(224, 224, 224, 1)"
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell:first-of-type": {
    borderTop: "none"
  },
  "&.MuiDataGrid-root .MuiDataGrid-row:first-of-type .MuiDataGrid-cell": {
    borderTop: "none"
  },
  "&.MuiDataGrid-root .MuiDataGrid-detailPanelToggleCell ": {
    backgroundColor: "#f7f7f7"
  },
  "button > svg": {
    fontSize: "12px"
  }
}