import { DRAG_TYPES, FIELD_TYPES } from '@ais/constants';

export const DROPZONE_AREAS = {
  TOP: 'top',
  BOTTOM: 'bottom',
  RIGHT: 'right',
  LEFT: 'left',
};

export const PLANNING_ANALYTICS_FIELD_TYPES = {
  TRIAL_BALANCE: 'CUSTOM_TRIAL_BALANCE',
  GENERAL_LEDGER: 'CUSTOM_GENERAL_LEDGER',
  CURRENT_PERIOD_TO_PRIOR: 'CUSTOM_CURRENT_PERIOD_TO_PRIOR',
  TRENDING_BY_MONTH: 'CUSTOM_TRENDING_BY_MONTH',
  KPIS_RATIOS: 'CUSTOM_KPIS_RATIOS'
}

// export const FIELD_TYPES = {
//   SECTION: 'RENDERED_SECTION',
//   TEXTFIELD: 'TEXTFIELD',
//   NUMBER: 'NUMBER',
//   CHECKBOXGROUP: 'CHECKBOX_GROUP',
//   RADIOGROUP: 'RADIO_GROUP',
//   FREETEXT: 'FREE_TEXT',
//   FREETEXT_NO_RULES: 'FREE_TEXT_NO_RULES',
//   SHORT_ANSWER: 'SHORT_ANSWER',
//   LONG_ANSWER: 'LONG_ANSWER',
//   DROPDOWN: 'DROPDOWN',
//   DATE_PICKER: 'DATE_PICKER',
//   TABLE: 'TABLE',
//   EXISTING_QUESTION: 'EXISTING_QUESTION',
//   FORMULA: 'FORMULA',
//   QUESTION_GROUP: 'QUESTION_GROUP',
//   INSTANCE_LONG_ANSWER: 'INSTANCE_LONG_ANSWER',
//   TRIAL_BALANCE: 'TRIAL_BALANCE',
//   GENERAL_LEDGER: 'GENERAL_LEDGER',
//   CURRENT_PERIOD_TO_PRIOR: 'CURRENT_PERIOD_TO_PRIOR',
//   TRENDING_BY_MONTH: 'TRENDING_BY_MONTH',
//   KPIS_RATIOS: 'KPIS_RATIOS',
//   PROJECT_SCOPE_TRIAL_BALANCE: 'PROJECT_SCOPE_TRIAL_BALANCE',
//   PROJECT_SCOPE_AUDIT_AREA: 'PROJECT_SCOPE_AUDIT_AREA',
//   PROJECT_SCOPE_TEST_OPERATING_EFFECTIVENESS: 'TEST_OPERATING_EFFECTIVENESS',
//   FINANCIAL_STATEMENT_LEVEL_RISK: 'FINANCIAL_STATEMENT_LEVEL_RISK',
//   ACCOUNT_AND_ASSERTION_LEVEL_RISK: 'ACCOUNT_AND_ASSERTION_LEVEL_RISK',
//   PROCEDURE: 'PROCEDURE',
//   CUSTOM_COMPONENT: 'CUSTOM_COMPONENT',
//   CLIENT_SITUATIONS: 'CLIENT_SITUATIONS',
//   KPI_TRIAL_BALANCE: 'KPI_TRIAL_BALANCE',
//   EVALUATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL: 'EVALUATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL',
//   COMMUNICATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL: 'COMMUNICATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL',
//   CUSTOM_RISK_CHARACTERISTIC_SELECT_GROUP: 'CUSTOM_RISK_CHARACTERISTIC_SELECT_GROUP',
//   PURPOSE_OBJECTIVE_PROCEDURE: 'CUSTOM_PURPOSE_OBJECTIVE_PROCEDURE',
//   UNDERSTANDING_OF_ENTITY: 'CUSTOM_UNDERSTANDING_OF_ENTITY',
//   COMMUNICATION_OF_SIGNIFICANT_MATTERS: 'CUSTOM_COMMUNICATION_OF_SIGNIFICANT_MATTERS',
//   EVALUATING_KEY_CONTROL: 'CUSTOM_EVALUATING_KEY_CONTROL'
// };

export const VALIDATION_TYPES = {
  NUMERICAL: 'numerical',
  USCURRENCY: 'usCurrency',
  PERCENTAGE: 'percentage',
  NONE: 'none'
}

export const TRIGGERS = {
  CANVAS: 'canvas',
  DROPZONE: 'dropzone',
  EDIT_BTN: 'edit-btn',
  ELLIPSIS: 'ellipsis',
  INNER_SECTION: 'inner-section',
  QUESTION_GROUP: 'question-group',
  QUESTION_GROUP_DROPZONE: 'question-group-dropzone',
  QUESTION_GROUP_EDIT: 'question-group-edit',
};

export const ACTION_ELLIPSIS = {
  EDIT_SECTION: 'Edit Section',
  MOVE_UP: 'Move Up',
  MOVE_DOWN: 'Move Down',
  DUPLICATE: 'Duplicate',
  DELETE: 'Delete',
  ADD_QUESTION: 'Add a Question',
  ADD_RISK: 'Add a Risk',
  ADD_RISK_FACTOR: 'Add a Risk Factor',
  ADD_DEFICIENCY: 'Add a Deficiency',
  EDIT_MOVE_QUESTION: 'Edit/Move Question',
};

export const ACTION_ELLIPSIS_TYPES = {
  ADD_QUESTION: 'addQuestion',
  ADD_RISK: 'addRisk',
  ADD_RISK_FACTOR: 'addRiskFactor',
  ADD_DEFICIENCY: 'addDeficiency',
  EDIT_MOVE_QUESTION: 'editMoveQuestion',
};

export const DIALOG = {
  SETTINGS: {
    LABEL: {
      SETTINGS: 'Settings',
      CHECKBOXES: 'checkboxes',
      COLUMNS_OPTIONS: 'Number of {0} in each row',
      DISPLAY_WIDTH: 'Display Width:',
      DEFAULT_ANSWERS: 'Default Answers',
      ALLOW_OTHER_OPTION: `Allow 'Other' option`,
      REQUIRED: 'Required',
      ADD_TOOLTIP: 'Add Tooltip Instructions',
      ADD_MULTISELECT: 'Allow Multi-Select',
      FORMAT_TEXT: 'Format Text',
      SECTION: 'Add a Section',
      CHECKBOX: 'Checkbox',
      QUESTION_ID: 'Question ID: ',
      QUESTION_LABEL: 'Question Label',
      QUESTION_LINK_LABEL: 'paste in question ID from the source',
      RADIO_BUTTON: 'Radio Button',
      RADIOS_BUTTONS: 'radio buttons',
      ENTER_YOUR_TEXT_HERE: 'Enter your text here',
      FREE_TEXT: 'Free Text',
      DROPDOWNS: 'Dropdown',
      DATE_PICKER: 'Date Picker',
      PLACEHOLDER_TEXT: 'Placeholder Text',
      DEFAULT_VALUE: 'Default Value',
      DISPLAY_TEXTBOX_HEIGHT: 'Display Text Box Height (number of rows)',
      SHORT_ANSWER: 'Short Answer',
      LONG_ANSWER: 'Long Answer',
      TABLE: 'Table',
      FORMULA: 'Formula',
      EXISTING_QUESTION: 'Existing Question',
      VALIDATION: 'Validation',
      SETTINGS_COLUMN: 'Settings & Columns',
      ROWS: 'Rows',
      RULES: 'Rules',
      PROCEDURE: 'Procedures',
      AUDIT_AREA: 'Audit Area',
      PREVIEW: 'Preview',
      VARIABLE: 'Variable',
      SECTION_ID: 'Section ID: ',
      QUESTION_GROUP: 'Question Group',
      QUESTION_GROUP_LABEL: 'Question Group Label',
    },
    PLACEHOLDER: {
      OPTION_LIST: 'Type or paste options here, one per row',
      QUESTION: 'Type Question Here',
      TEXT: 'Text',
      DESCRIPTION: 'Type Description Here',
      VALUE: 'Value',
      BASIC_DROPDOWN: 'Select',
      QUESTION_LINK: 'Question ID',
      QUESTION_GROUP: 'Enter question group label',
    },
    ERROR: {
      QUESTION_REQUIRED: 'Question label required.',
      OPTION_LIST_REQUIRED: 'Options required.',
      OPTION_HAS_DUPLICATE: 'Options have duplicate values.',
      QUESTION_ID_REQUIRED: 'Question ID required',
      QUESTION_ID_INVALID: 'Question ID is invalid',
      QUESTION_INDUSTRY_REQUIRED_NON_RULES: 'Only questions within forms marked "required" may be used.',
      QUESTION_INDUSTRY_REQUIREMENT_INVALID: 'Question cannot be applied to rules. Only questions within forms marked "required" may be used.',
      LINK_TYPE_REQUIRED: 'Link Type must be selected',
    },
  },
  COLUMN_BUILDER: {
    ADD_COLUMN: 'ADD COLUMN',
    COLUMN_LABEL: 'Column Label',
    VALIDATION: 'Validation',
    FIELD_TYPE: 'Field Type',
    OPTION_1: 'Option 1',
    OPTION_2: 'Option 2',
    CHECKBOX_NOTE: 'Note: Only one checkbox is displayed'
  },
  ROW_BUILDER: {
    HEADER_MESSAGE: "Leave 'Row Label' blank if you'd like the user to complete the response.",
    DISABLE_ROW_LABEL: 'Disable Row Label',
    ADD_ROWS: 'Add Rows',
    ENTER_NUMBER_OF_ROWS: 'Enter # of Rows',
    OK_BUTTON: 'Ok',
    ROW_LABEL: 'Row Label'
  },
  RULES: {},
  LINKLISTS: {
    LABEL: {
      EDITOR_DISPLAY: 'Link Lists is enabled.\nTo preview, save this question\nand click \'View Form\'',
      DESIGNER_DISPLAY: 'Link Lists is enabled. To preview, click \'View Form\'',
    },
  },
  DEFAULT_ANSWERS: {
    LONG_ANSWER: {
      HEADER_TEXT: "Assign an industry or industries to any long answer default value.",
      COMPONENT_NAME: "Long Answer"
    },
    SHORT_ANSWER: {
      HEADER_TEXT: "Assign an industry or industries to any short answer default value.",
      COMPONENT_NAME: "Short Answer"
    },
    RADIOGROUP: {
      COMPONENT_NAME: "Radio Button Menu"
    },
    DROPDOWN: {
      COMPONENT_NAME: "Dropdown Menu"
    },
    GENERAL: {
      DEFAULT_VALUES: "Default Values",
      DEFAULT_VALUE: "Default Value",
      WARNING: "Warning:",
      WARNING_MESSAGE: "Changing validation on the previous screen will remove these default values.",
      INDUSTRY: "Industry",
      EDIT_ADVANCED: "Edit Advanced",
      ADVANCED: "Advanced",
      ADVANCED_MODE_DETECTED: "Advanced Mode Detected",
      SELECT_DEFAULT_ANSWER: "Select Default Answer",
      ADD_NEW: "ADD NEW"
    }
  }
};

export const FORM_DESIGNER = {
  MESSAGES: {
    SUCCESS: {
      FORM_UPDATED: 'Form has been updated.',
      FORM_SAVED: 'Form has been saved.',
    },
  },
  LABELS: {
    FORM_NAME_PLACEHOLDER: 'Form Name Here',
    CONCLUSIONS: 'Conclusions',
  },
  BUTTONS: {
    SAVE: 'Save',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
  },
};

export const LINK_TYPE = {
  Clone: { value: 'Clone', label: 'Clone' },
};

export const DELETE_QUESTION_ERROR = {
  ERROR_TITLE: 'Cannot be deleted.',
  USED_IN_RULE: 'This question is associated with other questions.',
  USED_AS_ANSWER: 'The answer to this question is set as a default answer for another question.'
}

export const TABLE = {
  LABELS: {
    PREVIEW: "Table is enabled. To preview, click 'View Form'"
  }
}

export const FORM_NAME = {
  USER_FORM: "User Form",
  AUDIT_SCOPING: "Audit Scoping",
  PLANNING_ANALYTICS: "Planning Analytics"
}

export const PROJECT_SCOPING = {
  LABELS: {
    CUSTOM_COMPONENT: 'Custom component enabled',
    TRIAL_BALANCE: 'Trial Balance',
    AUDIT_AREA: 'Audit Area',
    TEST_OPERATING_EFFECTIVENESS: 'Will the audit team test the operating effectiveness of internal controls in the current year or rely on evidence obtained from prior year tests of controls?',
    DROPDOWNPLACEHOLDER: 'Select',
    YES: 'Yes',
    NO: 'No'
  }
}

export const PROCEDURE_FIELD = {
  LABEL: "Client Situations to Consider",
  PROCEDURE_ENABLED: "Procedures Enabled.",
  PROCEDURE_NAME: "Procedure Name",
  IDENTIFIED_RISKS: "Identified Risks",
  ASSERTIONS: "Assertions",
  COMPLETED: "Completed",
  WORKPAPER_REFERENCE: "Workpaper Reference",
  COMMENTS: "Comments",
  EXTERNAL_REFERENCES: "External References",
  TEXT: "TEXT",
  COMMENT: "Comment",
  WORKPAPER_REF: "Workpaper Ref."
}

export const TAILORING_PROCEDURE_FIELD = {
  TITLE: 'Procedure Details',
  PROCEDURE_NAME: 'Procedure Name',
  AUDIT_AREA: 'Audit Area',
  SCOTABDS: 'SCOTABDs',
  STEP_DESCRIPTION: 'Step Description',
  PRIMARY_ASSERTIONS: 'Primary Assertions',
  SECONDARY_ASSERTIONS: 'Secondary Assertions',
  CASH: 'Cash',
  SELECT: 'Select',
  ENTER_STEP_DESCRIPTION: 'Enter step description',
  ENTER_PROCEDURE_NAME: 'Enter procedure name',
}

export const ACCEPTED_QUESTION_GROUP_FIELDS = [
  FIELD_TYPES.TEXTFIELD,
  FIELD_TYPES.NUMBER,
  FIELD_TYPES.CHECKBOXGROUP,
  FIELD_TYPES.RADIOGROUP,
  FIELD_TYPES.FREETEXT,
  FIELD_TYPES.SHORT_ANSWER,
  FIELD_TYPES.LONG_ANSWER,
  FIELD_TYPES.DROPDOWN,
  FIELD_TYPES.DATE_PICKER,
  FIELD_TYPES.TABLE,
];

export const FORM_DRAGGABLES = [
  {
    dragType: DRAG_TYPES.SECTION,
    fieldType: FIELD_TYPES.SECTION,
    title: 'Section',
  },
  {
    dragType: DRAG_TYPES.FIELD,
    fieldType: FIELD_TYPES.FREETEXT,
    title: 'Free Text',
  },
  {
    dragType: DRAG_TYPES.FIELD,
    fieldType: FIELD_TYPES.SHORT_ANSWER,
    title: 'Short Answer',
  },
  {
    dragType: DRAG_TYPES.FIELD,
    fieldType: FIELD_TYPES.LONG_ANSWER,
    title: 'Long Answer',
  },
  {
    dragType: DRAG_TYPES.FIELD,
    fieldType: FIELD_TYPES.RADIOGROUP,
    title: 'Radio Button',
  },
  {
    dragType: DRAG_TYPES.FIELD,
    fieldType: FIELD_TYPES.DROPDOWN,
    title: 'Dropdown',
  },
  {
    dragType: DRAG_TYPES.FIELD,
    fieldType: FIELD_TYPES.CHECKBOXGROUP,
    title: 'Checkbox',
  },
  {
    dragType: DRAG_TYPES.FIELD,
    fieldType: FIELD_TYPES.DATE_PICKER,
    title: 'Date Picker',
  },
  {
    dragType: DRAG_TYPES.FIELD,
    fieldType: FIELD_TYPES.TABLE,
    title: 'Table',
  },
  {
    dragType: DRAG_TYPES.FIELD,
    fieldType: FIELD_TYPES.FORMULA,
    title: 'Formula',
  },
  {
    dragType: DRAG_TYPES.PROCEDURE,
    fieldType: FIELD_TYPES.PROCEDURE,
    title: 'Procedures'
  },
  {
    dragType: DRAG_TYPES.FIELD,
    fieldType: FIELD_TYPES.EXISTING_QUESTION,
    title: 'Existing Question',
  },
  {
    dragType: DRAG_TYPES.QUESTION_GROUP,
    fieldType: FIELD_TYPES.QUESTION_GROUP,
    title: 'Question Group',
  },
];

export const COMMUNICATION_OF_SIGNIFICANT_MATTERS = {
  SECTION_TITLE: 'Communication of Significant Matters'
};