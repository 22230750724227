import update from 'immutability-helper';
import { PROJECT_FORM_USAGE_ACTIONS } from '@constants/index';

export const projectFormUsageModalReducer = (projectFormUsageModal = [], action) => {
    switch(action.type) {
        case PROJECT_FORM_USAGE_ACTIONS.ADD: {
            return action.projectFormUsages
        }
        case PROJECT_FORM_USAGE_ACTIONS.UPDATE: {
            if(projectFormUsageModal.length <= 0) return [action.projectFormUsage];
    
            const { projectFormUsage } = action;
            
            const index = projectFormUsageModal.findIndex(form => projectFormUsage?.userId === form?.userId);

            if(index < 0) return [...projectFormUsageModal, projectFormUsage];

            return update(projectFormUsageModal, {
                        [index]: {
                           questionId: {
                                $set: projectFormUsage?.questionId || null
                           },
                           updateDateTime: {
                                $set: projectFormUsage?.updateDateTime || null
                           }
                        }
                    });
        }
        case PROJECT_FORM_USAGE_ACTIONS.DELETE: {
            if(projectFormUsageModal.length <= 0) return projectFormUsageModal;
            const index = projectFormUsageModal.findIndex(form => form?.userId == action.userId);

            if(index < 0) return projectFormUsageModal;

            return update(projectFormUsageModal, { $splice: [[index, 1]] })
        }
        case PROJECT_FORM_USAGE_ACTIONS.SESSION_DROPPED: {
            const index = projectFormUsageModal.findIndex(form => form.sessionId === action?.data?.sessionId);
            if(index < 0) return projectFormUsageModal;
            return update(projectFormUsageModal, { $splice: [[index, 1]] })
        }
        default: {
            return projectFormUsageModal
        }
    }
}