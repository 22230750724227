import { createContext, useContext, useReducer, useEffect, useState, useMemo } from "react"
import { useParams } from "react-router-dom"

import { formatProjectInternalControl } from '@ais/components';

import { useFetchProjectInternalControls } from '@services/customForm/internalControls';
import { InternalControlsReducer, initialState } from './InternalControlsReducer';
import { SchemaContext } from '@ais/providers';
import sortBy from 'lodash/sortBy';

import { INTERNAL_CONTROLS_FORM_TYPE_ID } from '@ais/constants';
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';

export const InternalControlsContext = createContext();

export const InternalControlsContextProvider = ({ children }) => {
    const { 
        AUDIT_AREAS: {
            DISPLAY_ORDER: AUDIT_AREA_DISPLAY_ORDER
        },
        SCOTABDS: {
            DISPLAY_ORDER: SCOTABD_DISPLAY_ORDER
        }
    } = UNDERSTANDING_OF_ENTITY;
    const { projectFormId, projectId } = useParams();
    const { schema } = useContext(SchemaContext);
    const [state, dispatch] = useReducer(InternalControlsReducer, initialState);
    const [sortedAuditAreas, setSortedAuditAreas] = useState([]);
    const [sortedSCOTABDs, setSortedSCOTABDs] = useState([]);
    
    const {
        data: internalControls,
        isLoading: isLoadingInternalControls,
        isRefetching: isRefetchingInternalControls
    } = useFetchProjectInternalControls(projectFormId, schema?.formTypeId, projectId);

    const [concurrencyEventReceived, setConcurrencyEventReceived] = useState(false)
    const [answerList, setAnswerList] = useState([]);
    const [comment, setComment] = useState({})
    const [ broadcastedEventData, setBroadcastedEventData ] = useState({})

    const memoized = useMemo(
        () => ({
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            answerList,
            setAnswerList,
            comment,
            setComment,
            setBroadcastedEventData
        }),
        [
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            answerList,
            setAnswerList,
            comment,
            setComment,
            setBroadcastedEventData
        ]
    )

    // Initialize to formatted internal controls data
    useEffect(() => {
        if (!!projectFormId && !!internalControls && schema?.formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID) {
            const formattedProjectInternalControl = formatProjectInternalControl(internalControls)
            changeInternalControlsObj(formattedProjectInternalControl)
            subscribeAuditAreasAndScotabds(formattedProjectInternalControl);
        }
    }, [projectFormId, internalControls, schema?.formTypeId]);

    const updateIsServiceOrganization = eventData => {
        const { answer, ProjectScopeAuditAreaId } = eventData
        const { isServiceOrganization } = answer
        const currentProjectScopeAuditArea = state.ProjectScopeAuditArea
        currentProjectScopeAuditArea.forEach((auditArea, index) => {
            if (auditArea.ProjectScopeAuditAreaId === parseInt(ProjectScopeAuditAreaId)) {
                auditArea.IsServiceOrganization = !!isServiceOrganization
                currentProjectScopeAuditArea[index] = auditArea
            }
        })
        changeFirstLevelField('ProjectScopeAuditArea', currentProjectScopeAuditArea)
    }

    const updateSingleValues = (eventData, type) => {
        const { ProjectKeyControlId, answer } = eventData
        let isFoundInFirstLevel = false
        const projectInternalControlProjectKeyControl = state.ProjectInternalControlProjectKeyControl
        projectInternalControlProjectKeyControl.forEach((item, index) => {
            const projectKeyControls = item.ProjectKeyControl
            const firstLevelProjectKeyControlIndex = projectKeyControls.findIndex(keyControl => keyControl.ProjectKeyControlId === parseInt(ProjectKeyControlId))
            if (firstLevelProjectKeyControlIndex > -1) {
                isFoundInFirstLevel = true
                projectKeyControls[firstLevelProjectKeyControlIndex][type] = answer[type]
                projectInternalControlProjectKeyControl[index].ProjectKeyControl = projectKeyControls
            }
        })
        if (isFoundInFirstLevel) {
            changeFirstLevelField('ProjectInternalControlProjectKeyControl', projectInternalControlProjectKeyControl)
            return
        }
        state.ProjectScopeAuditArea.forEach((projectScopeAuditArea) => {
            projectScopeAuditArea?.ProjectScopeAuditAreaSCOTABDS?.forEach((psaas) => {
                psaas.ProjectScopeAuditAreaSCOTABDProjectKeyControl.forEach((psaaspk) => {
                    psaaspk.ProjectKeyControl.forEach((projectKeyControl, projectKeyControlIndex) => {
                        if (projectKeyControl.ProjectKeyControlId === parseInt(ProjectKeyControlId)) {
                            psaaspk.ProjectKeyControl[projectKeyControlIndex][type] = answer[type]
                        }
                    })
                })
            })
        })
        changeInternalControlsObj({ ...state })
    }

    const updateICInfoProcessingAndControlActivityWithComment = eventData => {
        const { ICComment, ICInfoProcessingAndControlActivity, ProjectScopeAuditAreaSCOTABDId } = eventData.answer
        const projectScopeAuditArea = state.ProjectScopeAuditArea
        projectScopeAuditArea.forEach((projectScopeAuditArea) => {
            if (projectScopeAuditArea.ProjectScopeAuditAreaSCOTABDS) {
                projectScopeAuditArea.ProjectScopeAuditAreaSCOTABDS.forEach((psaas) => {
                    if (psaas.ProjectScopeAuditAreaSCOTABDId === parseInt(ProjectScopeAuditAreaSCOTABDId)) {
                        psaas.ICComment = ICComment
                        psaas.ICInfoProcessingAndControlActivity = ICInfoProcessingAndControlActivity
                    }
                })
            }
        })

        changeFirstLevelField('ProjectScopeAuditArea', projectScopeAuditArea)
    }

    const updateProjectScopeAuditAreaSCOTABProjectKeyControl = broadcastEventData => {
        const {
            ProjectScopeAuditArea,
            projectScopeAuditAreaSCOTABDId
        } = broadcastEventData.answer
        let isKeyControlsUpdated = false
        const parsedProjectScopeAuditAreaData = JSON.parse(ProjectScopeAuditArea)
        const previousStateProjectScopeAuditAreas = state.ProjectScopeAuditArea
        let projectScopeAuditAreaSCOTABDProjectKeyControl;

        parsedProjectScopeAuditAreaData?.forEach(psaa => {
            if (!psaa.ProjectScopeAuditAreaSCOTABDS) return;
            const projectScopeAuditAreaScotabdIndex = psaa.ProjectScopeAuditAreaSCOTABDS.findIndex(scotabds => scotabds.ProjectScopeAuditAreaSCOTABDId === projectScopeAuditAreaSCOTABDId)
            if (projectScopeAuditAreaScotabdIndex === -1) return 
            const foundSCOTABDS = psaa.ProjectScopeAuditAreaSCOTABDS[projectScopeAuditAreaScotabdIndex];
            projectScopeAuditAreaSCOTABDProjectKeyControl = foundSCOTABDS?.ProjectScopeAuditAreaSCOTABDProjectKeyControl
        })
        const latestProjectScopeAuditAreaSCOTABDProjectKeyControlIds = projectScopeAuditAreaSCOTABDProjectKeyControl?.map(item => item.ProjectScopeAuditAreaSCOTABDProjectKeyControlId)
        previousStateProjectScopeAuditAreas.forEach((psaa, psaaIndex) => {
            if (isKeyControlsUpdated) return;
            if (!psaa.ProjectScopeAuditAreaSCOTABDS) return;
            const projectScopeAuditAreaScotabdIndex = psaa.ProjectScopeAuditAreaSCOTABDS.findIndex(scotabds => scotabds.ProjectScopeAuditAreaSCOTABDId === projectScopeAuditAreaSCOTABDId)
            if (projectScopeAuditAreaScotabdIndex === -1) return
            const projectScopeAuditAreaScotabd = psaa.ProjectScopeAuditAreaSCOTABDS[projectScopeAuditAreaScotabdIndex]
            if (projectScopeAuditAreaScotabd?.ProjectScopeAuditAreaSCOTABDProjectKeyControl.length < 1) {
                setKeyControlValuesToScotabdLevelField(psaaIndex, projectScopeAuditAreaScotabdIndex, projectScopeAuditAreaSCOTABDProjectKeyControl)
                isKeyControlsUpdated = true
                return;
            }
            const prevProjectScopeAuditAreaScotabdProjectKeyControlIds = 
                projectScopeAuditAreaScotabd
                    .ProjectScopeAuditAreaSCOTABDProjectKeyControl
                        ?.map(item => item.ProjectScopeAuditAreaSCOTABDProjectKeyControlId);
            latestProjectScopeAuditAreaSCOTABDProjectKeyControlIds.forEach((id, index) => {
                if (!prevProjectScopeAuditAreaScotabdProjectKeyControlIds.includes(id)) {
                    pushKeyControlValuesToScotabdLevelField(psaaIndex, projectScopeAuditAreaScotabdIndex, projectScopeAuditAreaSCOTABDProjectKeyControl[index])
                } else {
                    const latestKeyControlData = projectScopeAuditAreaSCOTABDProjectKeyControl[index]
                    changeScotabdKeyControlField(psaaIndex, projectScopeAuditAreaScotabdIndex, index, 'ProjectKeyControlName', latestKeyControlData.ProjectKeyControl[0].ProjectKeyControlName)
                    changeScotabdKeyControlField(psaaIndex, projectScopeAuditAreaScotabdIndex, index, 'ProjectKeyControlDescription', latestKeyControlData.ProjectKeyControl[0].ProjectKeyControlDescription)
                    changeScotabdKeyControlField(psaaIndex, projectScopeAuditAreaScotabdIndex, index, 'ProjectKeyControlProjectRisk', latestKeyControlData.ProjectKeyControl[0].ProjectKeyControlProjectRisk)
                    changeScotabdKeyControlField(psaaIndex, projectScopeAuditAreaScotabdIndex, index, 'IsITApplicationAssociated', latestKeyControlData.ProjectKeyControl[0].IsITApplicationAssociated)
                    changeScotabdKeyControlField(psaaIndex, projectScopeAuditAreaScotabdIndex, index, 'ITApplicationAssociatedComment', latestKeyControlData.ProjectKeyControl[0].ITApplicationAssociatedComment)
                    changeScotabdKeyControlLevel(psaaIndex, projectScopeAuditAreaScotabdIndex, index, 'IsCoversOtherRelativeAssertion', latestKeyControlData.IsCoversOtherRelativeAssertion)
                }
            })
        })
    }

    const updateProjectInternalControlProjectKeyControl = broadcastedEventData => {
        const {
            ProjectInternalControlProjectKeyControl
        } = broadcastedEventData.answer

        const parsedInternalControlData = JSON.parse(ProjectInternalControlProjectKeyControl)
        const latestProjectInternalControlProjectKeyControlIds = 
            parsedInternalControlData.map(item => item.ProjectInternalControlProjectKeyControlId)
        
        const previousProjectInternalControlProjectKeyControlIds = 
            state.ProjectInternalControlProjectKeyControl.map(item => item.ProjectInternalControlProjectKeyControlId)
        
        if (previousProjectInternalControlProjectKeyControlIds.length < 1) {
            setProjectInternalControlProjectKeyControl(parsedInternalControlData)
            return;
        }

        latestProjectInternalControlProjectKeyControlIds.forEach((id, index) => {
            if (!previousProjectInternalControlProjectKeyControlIds.includes(id)) {
                pushItemsToProjectInternalControlProjectKeyControl(parsedInternalControlData[index])
            } else {
                const keyControlData = parsedInternalControlData[index].ProjectKeyControl[0]
                changeJournalFinancialKeyControlField(index, 'ProjectKeyControlName', keyControlData.ProjectKeyControlName)
                changeJournalFinancialKeyControlField(index, 'ProjectKeyControlDescription', keyControlData.ProjectKeyControlDescription)
                changeJournalFinancialKeyControlField(index, 'ProjectKeyControlProjectRisk', keyControlData.ProjectKeyControlProjectRisk)
                changeJournalFinancialKeyControlField(index, 'IsITApplicationAssociated', keyControlData.IsITApplicationAssociated)
                changeJournalFinancialKeyControlField(index, 'ITApplicationAssociatedComment', keyControlData.ITApplicationAssociatedComment)
            }
        })
    }

    const deleteProjectKeyControl = broadcastedEventData => {
        const { projectKeyControlId: broadcastedProjectKeyControlId } = broadcastedEventData.answer
        if (!broadcastedProjectKeyControlId) return;
        let keyControlIndex;
        if (state.ProjectInternalControlProjectKeyControl.length < 1) return
        state.ProjectInternalControlProjectKeyControl.forEach((item, index) => {
            const projectKeyControlId = item.ProjectKeyControl[0].ProjectKeyControlId
            if (projectKeyControlId === parseInt(broadcastedProjectKeyControlId)) {
                keyControlIndex = index
                return;
            }
        })
        if (keyControlIndex > -1) {
            deleteProjectInternalControlProjectKeyControl(keyControlIndex)
            return
        }
        const projectScopeAuditAreas = state.ProjectScopeAuditArea
        let isAlreadyDeleted = false
        
        projectScopeAuditAreas.forEach((psaa, auditAreaIndex) => {
            if (isAlreadyDeleted) return;

            const ProjectScopeAuditAreaSCOTABDs = psaa.ProjectScopeAuditAreaSCOTABDS

            ProjectScopeAuditAreaSCOTABDs.forEach((scotabds, scotabdsIndex) => {

                const ScotabdsProjectKeyControl = scotabds.ProjectScopeAuditAreaSCOTABDProjectKeyControl
                if (ScotabdsProjectKeyControl.length < 1) return;
                ScotabdsProjectKeyControl.forEach((scotabdsKeyControl, scotabdsKeyControlIndex) => {
                    if (isAlreadyDeleted) return;
                    const projectKeyControl = scotabdsKeyControl.ProjectKeyControl
                    
                    if (projectKeyControl[0].ProjectKeyControlId === parseInt(broadcastedProjectKeyControlId)) {
                        deleteProjectScopeAuditAreaSCOTABDProjectKeyControls(auditAreaIndex, scotabdsIndex, scotabdsKeyControlIndex)
                        isAlreadyDeleted = true
                        return;
                    }
                })
            })
        })
    }

    useEffect(() => {
        if (!concurrencyEventReceived || Object.keys(broadcastedEventData).length < 1) return
        const { answer } = broadcastedEventData
        const { code } = answer
        switch (code) {
            case 'IsServiceOrganization': {
                updateIsServiceOrganization(broadcastedEventData)
                return;
            }
            case 'IsDesignedEffectively': {
                updateSingleValues(broadcastedEventData, 'IsDesignedEffectively')
                return;
            }
            case 'IsImplementedEffectively': {
                updateSingleValues(broadcastedEventData, 'IsImplementedEffectively')
                return;
            }
            case 'WalkthroughComment': {
                updateSingleValues(broadcastedEventData, 'WalkthroughComment')
                return;
            }
            case 'ICInfoProcessingAndControlActivity': {
                updateICInfoProcessingAndControlActivityWithComment(broadcastedEventData)
                return;
            }
            case 'ProjectScopeAuditArea': {
                updateProjectScopeAuditAreaSCOTABProjectKeyControl(broadcastedEventData)
                return;
            }
            case 'ProjectInternalControlProjectKeyControl': {
                updateProjectInternalControlProjectKeyControl(broadcastedEventData)
                return;
            }
            case 'DeleteProjectKeyControl': {
                deleteProjectKeyControl(broadcastedEventData)
                return;
            }
            case 'whole': {
                const { code, ...rest } = answer
                changeInternalControlsObj(rest)
                return;
            }
            case 'updated-properties': {
                const { code, ...rest } = answer
                Object.keys(rest).forEach(key => {
                    changeFirstLevelField(key, rest[key])
                })
                return;
            }
            default: {
                return false
            }
        }
    }, [ concurrencyEventReceived, broadcastedEventData ])

    const subscribeAuditAreasAndScotabds = (newState) => {
        setSortedAuditAreas(() => ([]))
        setSortedSCOTABDs(() => ([]))

        const sortedAuditAreas = sortBy(newState?.ProjectScopeAuditArea, AUDIT_AREA_DISPLAY_ORDER);
        
        sortedAuditAreas?.forEach((auditArea, auditAreaIndex) => {
            const auditAreaScotabds = sortBy(auditArea?.ProjectScopeAuditAreaSCOTABDS, SCOTABD_DISPLAY_ORDER);

            setSortedSCOTABDs((prev) => {
                const newSorted = [...prev]
                newSorted.push(auditAreaScotabds)
                return newSorted
            })
        })

        setSortedAuditAreas(sortedAuditAreas)
    }

    const changeFirstLevelField = (key, value) => {
        dispatch({
            type: 'CHANGE_FIRST_LEVEL_FIELD',
            payload: {
                key,
                value
            }
        })

        return {
            ...state,
            [key]: value
        };
    }

    const changeFirstLevelFieldWithObject = (keyValues) => {
        dispatch({
            type: 'CHANGE_FIRST_LEVEL_FIELD_WITH_OBJECT',
            payload: {...keyValues}
        });

        return {
            ...state,
            ...keyValues
        }
    }

    const changeInternalControlsObj = (newInternalControlsObj) => {
        dispatch({
            type: 'CHANGE_WHOLE_OBJECT',
            payload: newInternalControlsObj
        });
    }

    const changeScotabdLevelField = (auditAreaIndex, scotabdIndex, key, value) => {
        dispatch({
            type: 'CHANGE_SCOTABD_LEVEL_FIELD',
            payload: { auditAreaIndex, scotabdIndex, key, value }
        });
    }

    const setKeyControlValuesToScotabdLevelField = (auditAreaIndex, scotabdIndex, value) => {
        dispatch({
            type: 'SET_SCOTABD_KEY_CONTROL_FIELD',
            payload: { auditAreaIndex, scotabdIndex, value }
        });
    }

    const pushKeyControlValuesToScotabdLevelField = (auditAreaIndex, scotabdIndex, value) => {
        dispatch({
            type: 'PUSH_SCOTABD_KEY_CONTROL_FIELD',
            payload: { auditAreaIndex, scotabdIndex, value }
        });
    }

    const pushItemsToProjectInternalControlProjectKeyControl = value => {
        dispatch({
            type: 'PUSH_PROJECT_INTERNAL_CONTROL_PROJECT_KEY_CONTROL',
            payload: { value }
        })
    }

    const deleteProjectInternalControlProjectKeyControl = index => {
        dispatch({
            type: 'DELETE_PROJECT_INTERNAL_CONTROL_PROJECT_KEY_CONTROL',
            payload: { index }
        })
    }

    const deleteProjectScopeAuditAreaSCOTABDProjectKeyControls = (
        auditAreaIndex,
        scotabdIndex,
        projectScopeAuditAreaSCOTABDProjectKeyControlIndex
    ) => {
        dispatch({
            type: 'DELETE_SCOTABD_PROJECT_KEY_CONTROL',
            payload: {
                auditAreaIndex,
                scotabdIndex,
                projectScopeAuditAreaSCOTABDProjectKeyControlIndex
            }
        })
    }

    const setProjectInternalControlProjectKeyControl = value => {
        dispatch({
            type: 'SET_PROJECT_INTERNAL_CONTROL_PROJECT_KEY_CONTROL',
            payload: { value }
        })
    }

    /**
     * updates Key Control fields in Journal Entries and Financial Statement sections under **Summary of Key Controls**,
     * under **Evaluating the Design** section.\
     * Used in **IncorporateKeyControls** component.
     * @param {number} keyControlIndex 
     * @param {string} key 
     * @param {string | boolean} value
     * @todo narrow **key** param
     */
    const changeJournalFinancialKeyControlField = (keyControlIndex, key, value) => {
        dispatch({
            type: 'CHANGE_JOURNAL_FINANCIAL_KEY_CONTROL_FIELD',
            payload: { keyControlIndex, key, value }
        });
    }

    /**
     * updates Key Control fields in Scotabds sections under **Summary of Key Controls**,
     * under **Evaluating the Design** section.\
     * Used in **CustomKeyControl** component.
     * @param {number} auditAreaIndex 
     * @param {number} scotabdIndex 
     * @param {number} keyControlIndex 
     * @param {string} key 
     * @param {string | boolean} value
     * @todo narrow **key** param
     */
    const changeScotabdKeyControlField = (auditAreaIndex, scotabdIndex, keyControlIndex, key, value) => {
        dispatch({
            type: 'CHANGE_SCOTABD_KEY_CONTROL_FIELD',
            payload: { auditAreaIndex, scotabdIndex, keyControlIndex, key, value }
        });
    }

    const changeScotabdKeyControlLevel = (auditAreaIndex, scotabdIndex, keyControlIndex, key, value) => {
        dispatch({
            type: 'CHANGE_SCOTABD_KEY_CONTROL_LEVEL_FIELD',
            payload: { auditAreaIndex, scotabdIndex, keyControlIndex, key, value }
        });
    }

    return (
        <InternalControlsContext.Provider value={{
            ...memoized,
            formattedInternalControls: state,
            isLoadingInternalControls,
            isRefetchingInternalControls,
            changeFirstLevelField,
            changeInternalControlsObj,
            changeFirstLevelFieldWithObject,
            changeScotabdLevelField,
            changeJournalFinancialKeyControlField,
            changeScotabdKeyControlField
        }}>
            {children}
        </InternalControlsContext.Provider>
    )
}

export const useInternalControlsContext = () => {
    const ctx = useContext(InternalControlsContext)
    if (!ctx) {
        throw new Error("`useInternalControlsContext` must be used within InternalControlsContextProvider")
    }
    return ctx
}
