import { useFormContext, Controller } from "react-hook-form"
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

export const RadioSelect = ({ name, label, options, sx }) => {
  const { control } = useFormContext();

  return (
    <FormControl
      fullWidth
      sx={{ flexDirection: 'row', alignItems: 'center', gap: '12px', ...sx }}
    >
      <Typography variant="label" component="label">{label}</Typography>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) =>
          <RadioGroup
            name={name}
            value={value}
            onChange={onChange}
            row
            sx={{
              '&.MuiFormGroup-row': {
                gap: '4px',
              },
            }}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option?.value ? option.value : option}
                label={option?.label ? option.label : option}
                control={<Radio />}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '12px',
                    color: 'black',
                  },
                }}
              />
            ))}
          </RadioGroup>
        }
      />
    </FormControl>
  );
};

RadioSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  options: PropTypes.array,
  sx: PropTypes.object,
};

RadioSelect.defaultProps = {
  options: [],
};
