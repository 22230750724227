import deleteIcon from "@assets/delete_icon.svg";
import { useParams } from 'react-router-dom';
import form_delete_disabled from "@assets/form_delete_disabled.svg";
import { Grid, IconButton } from '@mui/material';
import styles from '@components/FormView/FormView.module.css';
import * as Constants from '@constants/customForm/index'
import { CLADropdown, CLASelect } from '@ais/forms';
import { useState, useEffect } from 'react';
import { useScotabds } from '@services/scotabd';
import { useLoading } from '@hooks/index';
import { useFormContext, useWatch } from "react-hook-form"

export const AddAuditArea = ({
  auditArea,
  auditAreaIndex,
  auditAreaOptions,
  assertionsOptions,
  handleDeleteAuditArea,
  onAuditAreaChange,
  onScotabdChange,
  onAssertionChange,
  isDisabled,
  setRiskFormValue,
  hasDefaultRisk
}) => {
  const formMethods = useFormContext();
  const watchRiskAssessment = useWatch({ control: formMethods.control, name: "riskAssessment" });

  const { EN } = Constants.ADD_RISK
  const { projectId } = useParams();
  const [scotabdOptions, setScotabdOptions] = useState([]);
  const [cloneProjectRiskAssertions, setCloneProjectRiskAssertions] = useState([]);
  const setLoading = useLoading()
  const riskAssessmentProjectScopeIds = () => watchRiskAssessment.filter((element) => typeof element === "number");
  const auditAreaIds = () => typeof auditArea[auditAreaIndex].auditAreaId === 'string' ? [] : [auditArea[auditAreaIndex].auditAreaId].flat();
  const {
    data: scotabdResult,
    isFetching: isLoadingScotabd
  } = useScotabds(
    projectId,
    riskAssessmentProjectScopeIds(),
    auditAreaIds()
  );

  const createScotabdOptions = (scotabdData) => {
    return scotabdData?.map((value) => ({
      value: value.ProjectScopeAuditAreaSCOTABDId,
      label: value.ClassOfTransactionName
    }));
  }

  useEffect(() => {
    setLoading(isLoadingScotabd);

    if (!scotabdResult || scotabdResult.status !== 200) {
      setScotabdOptions([]);
      return;
    };

    setScotabdOptions(createScotabdOptions(scotabdResult?.data));
  }, [isLoadingScotabd, scotabdResult]);

  useEffect(() => {
    if (!Array.isArray(auditArea[auditAreaIndex].auditAreaId) && auditAreaOptions?.length) {
      auditArea && auditArea.forEach((auditAreaId, index) => {
        const getAuditArea = auditAreaOptions.find(x => x.label === auditAreaId.auditAreaName);
        if (getAuditArea) {
          formMethods.setValue(`auditAreaList[${index}].auditAreaId`, getAuditArea?.value);
          setRiskFormValue(index,{...auditAreaId,auditAreaId:getAuditArea?.value})
        }
      });
    }
  }, [auditAreaOptions])

  useEffect(() => {
    setCloneProjectRiskAssertions(auditArea[auditAreaIndex].assertions.map(assertion => ({
      ...assertion, 
      auditAreaId: auditArea[auditAreaIndex].auditAreaId,
      projectRiskAuditAreaId: auditArea[auditAreaIndex].projectRiskAuditAreaId
    })))
  }, [])
  return (
    <>
      <Grid item xs={12} sx={{ display: 'flex' }}>
        <div className={styles['add-risk-dropdown']}>
          <CLADropdown
            id={`auditAreaList[${auditAreaIndex}].auditAreaId`}
            name={`auditAreaList[${auditAreaIndex}].auditAreaId`}
            label={EN.AUDIT_AREA}
            value={auditArea[auditAreaIndex].auditAreaId}
            options={auditAreaOptions}
            onChange={(e) => onAuditAreaChange(e, auditAreaIndex)}
            placeholder={EN.SELECT}
            isDisabled={hasDefaultRisk}
          />
        </div>
        <div className={styles['add-risk-dropdown']}>
          <CLADropdown
            id={`auditAreaList[${auditAreaIndex}].scotabd`}
            name={`auditAreaList[${auditAreaIndex}].scotabd`}
            label={EN.SCOTABD}
            value={auditArea[auditAreaIndex].scotabd}
            options={scotabdOptions}
            onChange={(e) => onScotabdChange(e, auditAreaIndex)}
            placeholder={EN.SELECT}
            isDisabled={hasDefaultRisk}
          />
        </div>
        <div className={styles['add-risk-dropdown-assertions']}>
          <CLASelect
            id={`auditAreaList[${auditAreaIndex}].assertions`}
            name={`auditAreaList[${auditAreaIndex}].assertions`}
            label={EN.ASSERTIONS}
            placeholder={EN.SELECT}
            isDisabled={hasDefaultRisk}
            truncateDisplay={true}
            menuItems={assertionsOptions}
            onChange={(assertions) => onAssertionChange(assertions, cloneProjectRiskAssertions, auditAreaIndex)}
            defaultValues={auditArea[auditAreaIndex]?.assertions.map(value => value.AssertionId)}
            error={false}
            helperText={''}
          />
        </div>
        <div className={styles['delete-icon-container']}>
          <IconButton onClick={() => { handleDeleteAuditArea(auditAreaIndex) }} disabled={isDisabled || hasDefaultRisk}>
            <img className={styles['delete-icon']} src={!isDisabled ? deleteIcon : form_delete_disabled} alt='deleteIcon' />
          </IconButton>
        </div>
      </Grid>
    </>
  )
}