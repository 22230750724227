import { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

const initialData = {
  clientName: '',
  projectName: '',
  methodologyVersion: '',
};

const MethodologyVersionReducer = (state, action) => {
  let newState = { ...state, ...action };
  return newState;
};

export const MethodologyVersionContext = createContext(initialData);

export const MethodologyVersionProvider = ({ children }) => {
  const [projectData, dispatchProjectData] = useReducer(
    MethodologyVersionReducer,
    initialData
  );

  const memoized = useMemo(
    () => ({ projectData, dispatchProjectData }),
    [projectData, dispatchProjectData]
  );

  return (
    <MethodologyVersionContext.Provider value={memoized}>
      {children}
    </MethodologyVersionContext.Provider>
  );
};

MethodologyVersionProvider.propTypes = {
  children: PropTypes.node,
};
