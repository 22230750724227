import { PROJECT_FORM_CUSTOM_LOCK } from '@ais/constants';
import uniq from 'lodash/uniq'

export const ProjectFormInstanceConcurrencyReducer = (state, { type, data }) => {

    if(!type) return state;
    switch (type) {
        case PROJECT_FORM_CUSTOM_LOCK.INITIALIZE_SESSIONS:
            const sessionsData = [...data]; 
            return {
                ...state,
                sessionsData
            }
        case PROJECT_FORM_CUSTOM_LOCK.UPDATE_IDLE:
            const idleData = data.idleData;
            return {
                ...state,
                idleData
            }
        case PROJECT_FORM_CUSTOM_LOCK.INITIALIZE_BASE_STATE:
            const { userId, projectId, projectFormId } = data;
            return { 
                ...state, 
                userId, 
                projectId, 
                projectFormId
            }
        case PROJECT_FORM_CUSTOM_LOCK.SUBSCRIBE_QUESTION_ID:
            if(!data) return state;
            const newSubscribedQuestionIds = [...state.subscribedQuestionIds]
            newSubscribedQuestionIds.push(data)
            return { 
                ...state, 
                subscribedQuestionIds: uniq(newSubscribedQuestionIds)
            }
        case PROJECT_FORM_CUSTOM_LOCK.UPSERT_SESSION:
            const sessionDataIndex = state.sessionsData.findIndex(session => session?.sessionId === data?.sessionId);
            if (sessionDataIndex !== -1) {
                // If session with same sessionId exists, update it
                const updatedSessionsData = [...state.sessionsData];
                updatedSessionsData[sessionDataIndex] = data;
                return { 
                    ...state, 
                    sessionsData: updatedSessionsData, 
                    lastSessionUpdate: data 
                };
            } else {
                // If session with same sessionId doesn't exist, add it
                const updatedSessionsData = [...state.sessionsData, data];
                return { ...state, sessionsData: updatedSessionsData, lastSessionUpdate: data };
            }
        case PROJECT_FORM_CUSTOM_LOCK.REMOVE_SESSION:
            const updatedSessionsData = state.sessionsData.filter(session => session?.sessionId !== data?.sessionId);
            return { 
                ...state, 
                sessionsData: updatedSessionsData, 
                lastSessionUpdate: null 
            };
        case PROJECT_FORM_CUSTOM_LOCK.MANUAL_UNLOCK_PROCEDURE_COMPONENT:
            const _updatedSessionsData = [...state.sessionsData];
            const procedureComponentIndex = state.sessionsData.findIndex(session => session?.procedureComponentId === data?.procedureComponentId);
            _updatedSessionsData[procedureComponentIndex] = {
                ..._updatedSessionsData[procedureComponentIndex],
                procedureComponentId: null,
            };
            return { 
                ...state, 
                sessionsData: _updatedSessionsData, 
                lastSessionUpdate: _updatedSessionsData[procedureComponentIndex]
            };
        default:
            break;
    }
}