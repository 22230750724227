import { createContext, useReducer, useContext } from 'react';
import CopyAnswerModalReducer, { initialState } from './copyAnswerModalReducer';
import logger from '@utilities/logService'

const CopyAnswerModalContext = createContext();

export const CopyAnswerModalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(CopyAnswerModalReducer, initialState);

    const changeModalState = (isVisible, projectFormId = 0) => {
        dispatch({
			type: "CHANGE_MODAL_STATE",
			payload: {	
				isVisible,
				projectFormId
			}
		})
    }

    const value = {
        isVisible: state.isVisible,
		projectFormId: state.projectFormId,
        changeModalState
    }

    return <CopyAnswerModalContext.Provider value={value}>{children}</CopyAnswerModalContext.Provider>
}

const useCopyAnswerModalContext = () => {
	const context = useContext(CopyAnswerModalContext);

	if (context === undefined) {
		logger.info(`useCopyAnswerModal must be used within CopyAnswerModalContext.`);
	}

	return context;
}

export default useCopyAnswerModalContext;