import { useEffect, useContext } from 'react'
import { CustomToastContext } from '@contexts/CustomToast/CustomToastContext'

export const useCustomToast = ({
    isLoading,
    isSuccess,
    isError,
}) => {
    const { setIsLoading, setIsSuccess, setIsError } = useContext(CustomToastContext)

    useEffect(() => {
        setIsLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
        setIsSuccess(isSuccess)
    }, [isSuccess])

    useEffect(() => {
        setIsError(isError)
    }, [isError])
}