export const CUSTOM_TOAST = {
    CLASSES: {
        ERROR: 'custom-toast-error',
        SUCCESS: 'custom-toast-success',
        LOADING: 'custom-toast-loading',
        WRAPPER: 'custom-toast__wrapper',
        WRAPPER_ERROR: 'custom-toast__wrapper-error',
        WRAPPER_SUCCESS: 'custom-toast__wrapper-success',
        WRAPPER_LOADING: 'custom-toast__wrapper-loading',
        TEXT: 'custom-toast__text',
        CONTAINER: 'Toastify__toast-modified-container'
    },
    TYPES: {
        ERROR: 'error',
        SUCCESS: 'success',
        LOADING: 'loading'
    },
    STYLES: {
        ERROR: {
            backgroundColor: "#ffd7d4",
            width: 278,
            borderRadius: 50
        },
        SUCCESS: {
            borderRadius: "50%",
            position: "relative",
            left: "250px"
        },
        LOADING: {
            borderRadius: "50%",
            position: "relative",
            left: "250px"
        }
    }
}