import { AnsweredIconComponent, EditIconComponent, ViewIconComponent } from '@ais/assets';
import { CLATooltip } from "@ais/components"
import { HQ_CLIENT } from '@ais/constants';
import { useAppTypeProvider } from '@ais/providers';
import styles from './ClientExternalIcon.module.css';

export const ClientExternalIcon = ({
    allowExternalAccess = false,
    answerable = false,
    answered = false,
}) => {    
    const {isPortalApp} = useAppTypeProvider();

    const view = allowExternalAccess && !answerable;
    const edit = allowExternalAccess && answerable && !answered;
    const submitted = allowExternalAccess && answerable && answered;
    const tooltipText = view
        ? HQ_CLIENT.CLIENT_CAN_VIEW
        : edit
        ? HQ_CLIENT.CLIENT_CAN_EDIT
        : submitted
        ? HQ_CLIENT.ANSWERED_AND_SUBMITTED
        : '';

    if (!isPortalApp) return <></>;

    return (
        <CLATooltip title={tooltipText} placement="bottom">
            <div>
                {view && <ViewIconComponent className={styles['icon']} />}
                {edit && <EditIconComponent className={styles['icon']} />}
                {submitted && <AnsweredIconComponent className={styles['icon']} />}
            </div>
        </CLATooltip>
    );
};

export default ClientExternalIcon;
