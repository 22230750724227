export const RISK_ASSESSMENT_FORM_TYPE_ID = 4;

export const RISK_ASSESSMENT_FORM_INSTANCE = {
    EN: {
        FINANCIAL_STATEMENT_LEVEL_RISK: {
            RISK_NAME_HEADER: 'Identified Risks of Material Misstatement at the Financial Statement Level',
            PERVASSIVE_EFFECT: 'Describe the Nature and Extent of the Pervasive Effect on the Financial Statements',
            OVERALL_RESPONSE: 'Planned Overall Response'
        }, ACCOUNT_ASSERTION_LEVEL_RISK: {
            AUDIT_AREAS: 'Audit Areas',
            COMMENTS: 'Comments',
            SCOTABDS: 'SCOTABDs',
            COMMENT_PLACEHOLDER: 'Write a comment here..',
            SAVE: 'Save',
            CANCEL: 'Cancel',
            OTHER_RELEVANT_ASSERTIONS: 'Other Relevant Assertions',
            INHERENT_RISK_FACTOR: 'Inherent Risk Factor',
            REFERENCE_FOR_WALKTHROUGH: 'Reference for Walkthrough',
            INHERENT_RISK_ASSESSMENT: 'Inherent Risk Assessment',
            REFERENCE_FOR_TEST_OF_CONTROLS_IF_PERFORMED: 'Reference for test of controls if performed',
            CONTROL_RISK_ASSESSMENT: 'Control Risk Assessment',
            RISK_OF_MATERIAL_MISSTATEMENT: 'Risk of Material Misstatement',
            IDENTIFIED_RISK: 'Identified Risk',
            RELEVANT_ASSERTIONS: 'Relevant Assertions',
            FINANCIAL_STATEMENT_LEVEL_RISK: 'Financial Statement Level Risk That affects this risk',
            WORKPAPER_REFERENCE_FORM_NAME: 'Workpaper Reference/Form Name',
            UNITS: 'Units',
            ENTER_REFERENCE_WALKTHROUGH: 'Enter Reference for Walkthrough',
            ENTER_REFERENCE_TEST: 'Enter Reference for test',
            PROCEDURES_ADDRESS_RISK: 'Procedures that address risk:'
        }, 
        RISK_LEVEL_INHERIT_RISK: ['Significant', 'High', 'Moderate', 'Low'],
        RISK_LEVEL_CONTROL_RISK: ['High', 'Moderate', 'Low'],
        RISK_MATERIAL_MISSTATEMENT_MATRIX: [
            ['Significant', 'High', 'Moderate'],
            ['High', 'Moderate', 'Low'],
            ['Moderate', 'Moderate', 'Low'],
            ['Low', 'Low', 'Low']
        ],
        RISKS_KEYVALUE_INHERENT: [
            { key: 'Significant', value: 1 },
            { key: 'High', value: 2 },
            { key: 'Moderate', value: 3 },
            { key: 'Low', value: 4 }
        ],
        RISKS_KEYVALUE_CONTROL: [
            { key: 'High', value: 1 },
            { key: 'Moderate', value: 2 },
            { key: 'Low', value: 3 }
        ],
        RISKS_KEYVALUE_RMM: [
            { key: 'High', value: 1 },
            { key: 'Moderate', value: 2 },
            { key: 'Low', value: 3 },
            { key: 'Significant', value: 4 }
        ],
        COMMON_IDENTIFIED_RISK_TOOLTIP: 'Common Identified Risks'
    }
}