import formApi from '@utilities/claApiForm';

// Set is used on the front end in the Form Instance when selecting a Framework from a single select drop down
// This feature, since only one Framework can be selected, cleans non-selected entries. Technically should always be a max of 1 only.
const setProjectScopeFramework = async (projectId, data) => {
    const { ProjectScopeId } = data;
    try {
        const currentFrameworks = await formApi.get(`/projectscopeframework/${ProjectScopeId}/${projectId}`);
        currentFrameworks?.data?.forEach(async (item) => {
            await formApi.delete(`/projectscopeframework/${projectId}/${item.ProjectScopeFrameworkId}`);
        });
        const response = await formApi.post(`/projectscopeframework/${projectId}`, data);
        return { status: 201, response };
    } catch (error) {
        return { status: 400, message: error };
    }
};

const getProjectScopeFramework = async (projectScopeId, projectId) => {
    try {
        const response = await formApi.get(`/projectscopeframework/${projectScopeId}/${projectId}`);
        return { status: 201, response };
    } catch (error) {
        return { status: 400, message: error };
    }
};

const updateProjectScopeFramework = async (projectId, payload) => {
    try {
        const response = await formApi.put(`/projectscopeframework/${projectId}`, payload);
        return { status: 201, response };
    }
    catch (error) {
        return { status: 400, message: error };
    }
}

const projectScopeFramework = {
    setProjectScopeFramework,
    getProjectScopeFramework,
    updateProjectScopeFramework
}

export default projectScopeFramework;