import { useMemo } from 'react';
import styles from '@components/CLANotification/CLANotification.module.css';
import CLANotificationItem from '@components/CLANotification/CLANotificationItem';
import { useNotificationContext } from '../../providers/notifications/NotificationProvider';
import { findIndex } from 'lodash';

const CLANotification = () => {
  const { notifications } = useNotificationContext();
  const filteredNotifications = useMemo(() => notifications?.filter(notification => !notification.isDelete) ?? [], [notifications]);

  return (
      <div style={{ backgroundColor: '#f6f6f6', height:'40vh' }}>
        <div className={styles.claNotificationHeader}>
          My Notifications
        </div>
        <div className={styles.claNotificationContainer}>
         {
            !filteredNotifications.length
              ?
                <p className={styles.claNoNotificationsText}>No notifications yet</p>
              : 
                filteredNotifications.map((notification) => {
                  const notificationIndex = findIndex(notifications, ['JobInstanceId', notification.JobInstanceId]);
                  return (
                    <CLANotificationItem 
                      key={notification.JobInstanceId}
                      notification={notification}
                      index={notificationIndex}
                    />
                  );
                })
          }
        </div>
      </div>
  );
};

export default CLANotification;