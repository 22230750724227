import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const KPIContext = createContext({});

export const KPIProvider = ({ children }) => {

    const [answerList, setAnswerList] = useState([]);
    const [concurrencyEventReceived, setConcurrencyEventReceived] = useState(false);
    
    const memoized = useMemo(
        () => ({
            answerList,
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            setAnswerList,
         }),
        [
            answerList,
            concurrencyEventReceived,
            setConcurrencyEventReceived,   
            setAnswerList
        ]
    )

    return (
        <KPIContext.Provider value={memoized}>{children}</KPIContext.Provider>
    )
}

KPIProvider.propTypes = {
    children: PropTypes.node
}