import formApi from "@utilities/claApiForm"
import { useQuery } from "@tanstack/react-query"

const fetchProcedureSuggestions = async (projectFormId, auditAreaId, projectId) => {
    const { data } = await formApi.get(`/projectform/${projectFormId}/procedures/${auditAreaId}/suggestions/${projectId}`)
    return data
}

export const fetchProceduresByAuditAreaIds = async (body) => {
    const { data } = await formApi.post(`/projectform/allprocedures/`, body);

    return data;
}

export const useFetchProcedureSuggestions = (projectFormId, auditAreaId, projectId) => {
    return useQuery(
        ["SuggestedProcedures", projectFormId],
        () => fetchProcedureSuggestions(projectFormId, auditAreaId, projectId),
        {
            enabled: !!(projectFormId && auditAreaId && projectId)
        }
    )
}