import { useContext } from 'react';
import { ProjectManagementContext } from './ProjectManagementProvider';

export const useProjectManagementProvider = () => {
  const context = useContext(ProjectManagementContext);

  if (context === undefined) {
    throw new Error(
      'useProjectManagementProvider() hook must be used within <ProjectManagementContext/>'
    );
  }

  return context;
}

export default useProjectManagementProvider;