import React, {
    useState,
    useEffect
} from 'react';
import CLATextField from '@components/Forms/CLATextField/CLATextField';
import { useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import { useRoomIdle } from '@components/Concurrency/provider/RoomProvider';

const CommunicationOfDeficienciesTextField = (props) => {
    const { ID } = DEFICIENCY_FORM_INSTANCE.EN;
    const { row, handlePersonCommunicatedToSave, disabled } = props;
    const [personCommunicatedTo, setPersonCommunicatedTo] = useState(row?.PersonCommunicatedTo);
    const updateMyPresence = useUpdateMyPresence()
    const isIdle = useRoomIdle();

    useEffect(() => {
        setPersonCommunicatedTo(row?.PersonCommunicatedTo);
    }, [row?.PersonCommunicatedTo]); 
    
    useEffect(() => {
        setPersonCommunicatedTo(row?.PersonCommunicatedTo);
    }, [isIdle]); 

    return (<>
        <CLATextField
            onChange={(e) => {
                setPersonCommunicatedTo(e.target.value);
            }}
            onFocus={() => { updateMyPresence({ focusedId: ID }) }}
            onBlur={() => {
                handlePersonCommunicatedToSave(row, 'PersonCommunicatedTo', personCommunicatedTo);
                updateMyPresence({ focusedId: null })
            }}
            disabled={disabled}
            value={personCommunicatedTo}
            inputLableShrink={true}
            placeholder="Enter Person's Name"
            maxLength={257}
        />
    </>
    );
}

export default CommunicationOfDeficienciesTextField;