import formApi from '@utilities/claApiForm';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"

const fetchDeficiencyEvaluationOptions = async (projectId, projectFormId) => {
    try {
        const project = `ProjectId=${projectId}`
        const projectForm = projectFormId ? `&ProjectFormId=${projectFormId}`: ''
        const { data } = await formApi.get(`/project-deficiencies/options/${projectId}?${project}${projectForm}`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

const postProjectDeficiency = async (body, projectId) => {
    const response = await formApi.post(`project-deficiencies/${projectId}`, body)
    if (response) {
        return { status: 200, data: response.data }
    }
    return { status: 400, message: 'POST: Project Deficiency response: ' + response }
}

export const putProjectDeficiency = async (projectDeficiencyId, body, projectId) => {
    const response = await formApi.put(`project-deficiencies/${projectDeficiencyId}/${projectId}`, body)
    if (response) {
        return { status: 200, data: response.data }
    }
    return { status: 400, message: 'PUT: Project Deficiency response: ' + response }
}

const getProjectDeficiencies = async (projectId) => {
    try {        
        const { data } = await formApi.get(`/project-deficiencies/${projectId}?ProjectId=${projectId}`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

// EXPORTS ====================================================================
export const useGetDeficiencyEvaluationOptions = (projectId, projectFormId) => {
    return useQuery(
        ["DeficiencyEvaluationOptions", projectId, projectFormId],
        () => fetchDeficiencyEvaluationOptions(projectId, projectFormId),
        {
            enabled: !!projectId || !!projectFormId
        }
    );
};

export const usePostProjectDeficiency = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (payload) => postProjectDeficiency(payload.reqBody, payload.projectId),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectDeficiency');
            }
        }
    )
};

export const usePutProjectDeficiency = (projectDeficiencyId) => {
    const queryClient = useQueryClient();

    return useMutation(
        (payload) => putProjectDeficiency(projectDeficiencyId, payload.reqBody, payload.projectId),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectDeficiency');
            }
        }
    )
};

export const usePutProjectDeficiencies = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (payload) => putProjectDeficiency(payload.projectDeficiencyId, payload.reqBody, payload.projectId),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectDeficiency');
            }
        }
    )
};

export const useGetProjectDeficiencies = (projectId) => {
    return useQuery(
        ["Deficiencies", projectId],
        () => getProjectDeficiencies(projectId),
        {
            enabled: !!projectId
        }
    );
};