import formApi from '@utilities/claApiForm';

const getFinancialStatementsByMethodologyVersionId = async (methodologyVersionId, isEnabledOnly = false) => {
    try {
        const response = await formApi.get(`/financialstatementcategories/${methodologyVersionId}`);
        return { status: 200, data: (!isEnabledOnly) ? response.data : response.data.filter(({ IsEnabled }) => IsEnabled) };
    } catch (error) {
        return { status: 400, message: error };
    }
};

const putFinancialStatementsByMethodologyVersionId = async (methodologyVersionId, body) => {
    try {
        const response = await formApi.put(`/financialstatementcategories/${methodologyVersionId}`, body);  
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

const financialStatementService = {
    getFinancialStatementsByMethodologyVersionId,
    putFinancialStatementsByMethodologyVersionId
};

export default financialStatementService;