import React from 'react';
import { Flex } from "@ais/palette";
import {
  Checkbox,
  InputLabel,
  OutlinedInput,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { renderLabel, parseQuestionLabel } from '@ais/forms'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import TableActionButton from '@ais/forms/src/V2/TableComponents/TableActionButton';
import NumericalField from '@ais/forms/src/V2/TableComponents/NumericalField';
import PercentageField from '@ais/forms/src/V2/TableComponents/PercentageField';
import RadioButtonList from '@ais/forms/src/V2/TableComponents/RadioButtonList';
import CurrencyField from '@ais/forms/src/V2/TableComponents/CurrencyField';
import Dropdown from '@ais/forms/src/V2/TableComponents/Dropdown';
import TableDatePicker from '@ais/forms/src/V2/TableComponents/TableDatePicker';


const TableHeadWrapper = styled(TableHead)({
  border: '1px solid rgba(151, 151, 151, 0.25)'
})
const FieldWrapper = styled('div')({
  minWidth: '250px', 
  minHeight: '58px',
  display: 'flex',
  placeItems: 'center'
})
const TableCellWrapper = styled(TableCell)(({isHeader})=>({
  fontFamily: 'museo-sans',
  fontStyle: 'normal',
  fontWeight: '1000',
  fontSize: '11px',
  lineHeight: '13px',
  color: '#2E334E',
  backgroundColor: isHeader ? '#F7F7F7' : 'white'
}))

const CLATable = (props) => {
  const {
    field,
    clientExternalIcon = null,
    disabled,
    onSubmit
  } = props
  const { columns, id, disableRowLabel } = field
  const { control } = useFormContext();
  
  const handleBlurSubmit = () => {
    onSubmit(id)
  } 
  const parsed = parseQuestionLabel(field.label);
  const label = typeof parsed === 'object' ? parsed.questionLabel : parsed;
  const fieldArrayMethods = useFieldArray({
    control,
    name: id
  });
  const {fields} = fieldArrayMethods


  const renderTableCell = (column, index) => {
    switch(column.columnFieldType){
      case 'shortAnswer':
        return (
          <>
            {column.validation === 'none' && (
              <Controller
                key={`${id}.${index}.${column.id}`}
                name={`${id}.${index}.${column.id}`}
                control={control}
                render={({field: { onChange, value }}) => {
                  return(
                    <OutlinedInput
                      notched={true}
                      value={value}
                      onChange={onChange}
                      onBlur={handleBlurSubmit}
                      inputProps={{
                        "test-id": `ShortNoValidation-${index}-${id}`,
                        "test-value": value,
                      }}
                    />
                )}}
              />
            )}
            {column.validation === 'numerical' && (
              <Controller
                key={`${id}.${index}.${column.id}`}
                name={`${id}.${index}.${column.id}`}
                control={control}
                render={({field: { onChange, value }}) => {
                  return(
                    <NumericalField
                      defaultValue={value}
                      onChange={onChange}
                      onBlur={handleBlurSubmit}
                      inputProps={{
                        "test-id": `ShortNumerical-${index}-${id}`,
                        "test-value": value,
                      }}
                    />
                )}}
              />
            )}
            {column.validation === 'usCurrency' && (
              <Controller
                key={`${id}.${index}.${column.id}`}
                name={`${id}.${index}.${column.id}`}
                control={control}
                render={({field: { onChange, value }}) => {
                  return(
                    <CurrencyField
                      defaultValue={value}
                      onChange={onChange}
                      onBlur={handleBlurSubmit}
                      inputProps={{
                        "test-id": `ShortCurrency-${index}-${id}`,
                        "test-value": value,
                      }}
                    />
                )}}
              />
            )}
            {column.validation === 'percentage' && (
              <Controller
                key={`${id}.${index}.${column.id}`}
                name={`${id}.${index}.${column.id}`}
                control={control}
                render={({field: { onChange, value }}) => { 
                  if (value === '') value = undefined;
                  else if (!isNaN(value)) value = Number(value) * 100;

                  return(
                    <PercentageField
                      value={value}
                      onBlur={handleBlurSubmit}
                      onChange={onChange}
                      inputProps={{
                        "test-id": `ShortPercentage-${index}-${id}`,
                        "test-value": value,
                      }}
                    />
                )}}
              />
            )}
          </>
        )
      case 'dropdown':
        return (
          <Controller
            key={`${id}.${index}.${column.id}`}
            name={`${id}.${index}.${column.id}`}
            control={control}
            render={({field: { onChange, value }}) => {
              return(
                <Dropdown
                  options={column.dropdownOptions}
                  value={value ?? column.dropdownDefaultValue}
                  onChange={(e)=>{
                    onChange(e.target.value)
                    handleBlurSubmit()
                  }}
                  inputProps={{
                    "test-id": `Dropdown-${index}-${id}`,
                    "test-value": value,
                  }}
                />
            )}}
          />
        )
      case 'datepicker':
        return (
          <Controller
            key={`${id}.${index}.${column.id}`}
            name={`${id}.${index}.${column.id}`}
            control={control}
            render={({field: { onChange, value }}) => {
              return(
                <TableDatePicker
                  value={value}
                  defaultValue={value}
                  onChange={onChange}
                  onBlur={handleBlurSubmit}
                  inputProps={{
                    "test-id": `Datepicker-${index}-${id}`,
                    "test-value": value,
                  }}
                />
            )}}
          />
        )
      case 'radioButton':
        return (
          <Controller
            key={`${id}.${index}.${column.id}`}
            name={`${id}.${index}.${column.id}`}
            control={control}
            render={({field: { onChange, value }}) => {
              return(
                <RadioButtonList
                  value={value ?? column.radioDefaultValue}
                  options={column.radioOptions}
                  onChange={(e)=> {
                    onChange(e)
                    handleBlurSubmit()
                  }}
                  inputProps={{
                    "test-id": `RadioButton-${index}-${id}`,
                    "test-value": value,
                  }}
                />
            )}}
          />
        )
      case 'checkbox':
        return (
          <Controller
            key={`${id}.${index}.${column.id}`}
            name={`${id}.${index}.${column.id}`}
            control={control}
            render={({field: { onChange, value }}) => {
              return (
                <Checkbox
                  checked={value}
                  onChange={(e)=>{
                    onChange(e)
                    handleBlurSubmit()
                  }}
                  inputProps={{
                    "test-id": `Checkbox-${index}-${id}`,
                    "test-value": value,
                  }}
                />
            )}}
          />
        )
      default:
        return null;
    }
  }

  return (
    <div style={{ overflow: 'auto', width: '100%' }}>
      <div style={{ padding: '10px' }}>
        <Flex direction="row" justify="between" align="baseline">
          <InputLabel sx={{ color: '#2E334E', whiteSpace: 'normal', width: 'max-content' }}>
            {renderLabel(label, field.tooltip)}
          </InputLabel>
          {clientExternalIcon && clientExternalIcon}
        </Flex>
      </div>
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: 700 }}>
          <TableHeadWrapper data-test="tableHeader">
            <TableRow key='header'>
              {/* action button ellipsis */}
              <TableCellWrapper isHeader key={'action'} align="left" />
              {!disableRowLabel && <TableCellWrapper isHeader key="rowLabel" align="left" />}
              
              {/* declared column from schema */}
              {columns.map(column => (
                <TableCellWrapper isHeader
                  key={`columnHeader${column.id}`}
                  align="left"
                >
                  {column.columnLabel}
                </TableCellWrapper>
              ))}
            </TableRow>
          </TableHeadWrapper>
          <TableBody data-test="tableBody">
              {fields.map((field, index)  => {
                return(
                  <TableRow key={`rowBody${field.id}${index}`}>
                    <TableActionButton
                      tableData={props.field}
                      rowData={field}
                      index={index}
                      disabled={disabled}
                      fieldArrayMethods={fieldArrayMethods}
                      onSubmit={onSubmit}
                    />
                    {!disableRowLabel && (
                      <TableCellWrapper title={`rowLabel${index}`} key={`row${index}`}>
                        <FieldWrapper>
                          <Controller
                            name={`${id}.${index}.rowLabel`}
                            control={control}
                            render={({field: { onChange, value }}) => {
                              return(
                              <OutlinedInput
                                notched
                                defaultValue={value}
                                onChange={onChange}
                                onBlur={handleBlurSubmit}
                                inputProps={{
                                  "test-id": `TableRowLabel-${index}-${id}`,
                                  "test-value": value,
                                }}
                              />
                            )}}
                          />
                        </FieldWrapper>
                      </TableCellWrapper>
                    )}
                    {columns.map((column, j) => {
                      return(
                        <TableCellWrapper title={`row${index} column${j}`} key={`rowAnswer${column.id}${index}`}>
                          <FieldWrapper>{renderTableCell(column, index)}</FieldWrapper>
                        </TableCellWrapper>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};


export default CLATable;

