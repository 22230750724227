import { useState, useEffect, useRef } from 'react';
import { OutlinedInput } from '@mui/material';
import AutoNumeric from 'autonumeric';
import PropTypes from 'prop-types';
import { useAppTypeProvider } from '@ais/providers';
import {
  ClientExternalIcon,
  getTrialBalanceAmountEnding,
  isValueTrialBalanceValue
} from '@ais/forms';
import InputAdornment from '@mui/material/InputAdornment';

const getTrialBalanceAmount = (_value) => {
  try {
    const jsonValue = JSON.parse(_value)
    return jsonValue?.amount;
  } catch {
    return _value;
  }
};

export const CLANumberField = (props) => {
  const {
    name,
    label,
    placeholder,
    defaultValue,
    onChange,
    onBlur,
    notched,
    disabled,
    decimalPlaces,
    digitGroupSeparator,
    value,
    sx,
    onFocus,
    isInstance,
    tbLinkEnabled,
    allowExternalAccess,
    answerable,
    answered,
    field,
    tbLinkBaseField: TbLinkBaseField,
    units,
    isIdle,
    onUnlockConcurrentField
  } = props;

  const ref = useRef(null);
  const containerRef = useRef(null);
  const { isPortalApp } = useAppTypeProvider();
  const [instance, setInstance] = useState(null);

  const initAutoNumeric = (_value) => {
    const isAutoNumeric = AutoNumeric.test(instance?.domElement);
    if (!isAutoNumeric && ref.current) {
      const newInstance = new AutoNumeric(ref.current, {
        allowDecimalPadding: 'floats',
        decimalPlaces: decimalPlaces,
        selectOnFocus: false,
        showWarnings: false,
        digitGroupSeparator,
        modifyValueOnWheel: false,
        watchExternalChanges: true,
      });
      AutoNumeric.set(newInstance.domElement, _value)
      setInstance(newInstance);
      return;
    } else {
      instance?.set(_value);
    }
  }

  const initTBAmount = (tbAmount) => {
    if (tbAmount === 0) {
      instance?.remove();
    } else {
      initAutoNumeric(tbAmount);
    }
  };

  useEffect(() => {
    if (isIdle) { 
      containerRef.current?.classList.remove('Mui-focused');
      ref.current?.blur();
    }
  }, [isIdle])

  useEffect(() => {
    if (!isNaN(defaultValue) || defaultValue === '' || defaultValue === '$') {
      initAutoNumeric(defaultValue);
      return;
    }

    const isTrialBalanceValue = isValueTrialBalanceValue(defaultValue);

    if (isTrialBalanceValue && isPortalApp) {
      const tbAmount = getTrialBalanceAmount(defaultValue);
      initTBAmount(tbAmount);
    } else if (isTrialBalanceValue && !isPortalApp) {
      const tbAmountEnding = getTrialBalanceAmountEnding(defaultValue);
      initTBAmount(tbAmountEnding);
    } else {
      initAutoNumeric(defaultValue);
    }
  }, [defaultValue, ref.current]);

  if (tbLinkEnabled && TbLinkBaseField && isPortalApp) {
    return (
      <TbLinkBaseField
        sx={sx}
        inputRef={ref}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => onChange && onChange(null, e)}
        onBlur={(e) => onBlur && onBlur(null, e)}
        onFocus={onFocus}
        notched={notched}
        disabled={disabled}
        isFormInstance={isInstance}
        tbLinkEnabled={isInstance}
        numerical={true}
        answered={answered}
        field={field}
        units={units}
        isIdle={isIdle}
        onDrawerOpen={onFocus}
        onUnlockConcurrentField={onUnlockConcurrentField}
      />
    )
  }

  return (
    <OutlinedInput
      inputRef={ref}
      ref={containerRef}
      sx={sx}
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange && onChange(e, instance?.getNumericString())}
      onBlur={(e) => onBlur && onBlur(e, instance?.getNumericString())}
      onFocus={onFocus}
      notched={notched}
      disabled={disabled}
      endAdornment={
        <InputAdornment position="end">
          <ClientExternalIcon
            allowExternalAccess={allowExternalAccess}
            answerable={answerable}
            answered={answered} />
        </InputAdornment>}
    />
  );
};

CLANumberField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  notched: PropTypes.bool,
  disabled: PropTypes.bool,
  digitGroupSeparator: PropTypes.string,
  decimalPlaces: PropTypes.number,
  allowExternalAccess: PropTypes.bool,
  answerable: PropTypes.bool
};

CLANumberField.defaultProps = {
  notched: false,
  disabled: false,
  digitGroupSeparator: '',
  decimalPlaces: 2,
  allowExternalAccess: false,
  answerable: false
};

export default CLANumberField;
