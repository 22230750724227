import { useContext } from "react"
import { ApplicationContext } from "@contexts/ApplicationContext";
import { APPLICATION_ACTION } from "@constants/index";

export const useLoadingByQueryCount = () => {
    const { dispatchApplication } = useContext(ApplicationContext);

    const setLoadingByQueryCount = (value) => {
        dispatchApplication({
            type: APPLICATION_ACTION.SET_LOADING_BY_QUERY_COUNT,
            payload: value,
        });
    }

    return {
        setLoadingByQueryCount
    }
}
