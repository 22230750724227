export default {
  "&.MuiDataGrid-root": {
    border: "none !important"
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
    display: "none"
  },
  "&.MuiDataGrid-root .MuiDataGrid-virtualScroller": {
    marginTop: "0 !important"
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell": {
    outline: "none",
    border: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
    display: "none"
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell": {
    outline: "none !important"
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(1)": {
    marginLeft: "50px !important"
  },
}