export const PROJECT_DASHBOARD = {
    EN: {
        PROJECT_DASHBOARD: "Project Dashboard",
        PROJECT_ACTIVE_DASHBOARD_OPTIONS: ["Roll Forward"],
        PROJECT_FINALIZED_DASHBOARD_OPTIONS: ["Roll Forward"],
        EDIT: 'Edit',
        SEE_ALL_LINK: 'See all',
        RANK: 'Rank',
        STATUS: 'Status',
        PROJECT_CARD_VIEW: {
            COMPLETION_DATE: 'Completion Date: ',
            UPDATED_DATE: 'Updated: ',
        },
        RELEASE_NOTES: 'Release Notes',
        DESCRIPTION: 'Description',
        STARTING_VERSION: 'Starting Version',
        CURRENT_VERSION: 'Current Version',
        VERSION: 'Version',
        KEY_DELIVERABLE_SIGN_OFF_KEYS: {
            PROJECT_DELIVERABLE_ID: 'ProjectDeliverableId',
            DELIVERABLE_NAME: 'DeliverableName',
            DUE_DATE: 'DueDate',
            REPORT_PROCESSING: 'ReportProcessingSignOffUser',
            SIGNER_REVIEW: 'SignerReviewSignOffUser',
            SECOND_REVIEW: 'SecondReviewSignOffUser',
            SIGNER_APPROVE_FOR_RELEASE: 'SignerApproveForReleaseSignOffUser',
            ASSEMBLED: 'AssembledSignOffUser'
        },
        KEY_DELIVERABLE: {
            DELIVERABLE: 'Deliverables',
            DUE_DATE: 'Due Date',
            DELIVERABLE_PLACEHOLDER: 'Add Text',
            DELIVERABLES: 'Deliverables',
            REPORT_PROCESSING: 'Report Processing',
            ENGAGEMENT_LEADER_REVIEW: 'Engagement Leader Review',
            SECOND_REVIEW: 'Second Review',
            ENGAGEMENT_LEADER_APPROVE_FOR_RELEASE: 'Engagement Leader Approve for Release',
            ASSEMBLED: 'Assembled',
            ADD_DELIVERABLE: 'ADD DELIVERABLE',
            MODAL: {
                TITLE: 'Key Deliverables',
                MESSAGE: 'Are you sure you want to remove the signoff?',
            }
        },
        MODAL: {
            TITLE: 'Warning',
            CANCEL: 'Cancel',
            CONFIRM: 'OK',
            MESSAGE: 'Are you sure you want to roll forward the project?',
        },
        ADD_TO_DASHBOARD_MODAL: {
            TITLE: 'Success!',
            CONFIRM: 'Close',
            MESSAGE: 'Project was successfully added to the AIS Dashboard.',
        },
        CONTEXT_MENU_OPTIONS: {
            FINALIZE_PROJECT: 'Finalize Project',
            ROLL_FORWARD: 'Roll Forward',
            ADD_PROJECT_TO_AIS_DASHBOARD: 'Add project to AIS Dashboard'
        }
    },
    KEY_DELIVERABLE: {
        ITEM_STATE: {
            NEW: 'New'
        },
        REQUEST_TYPE: {
            ADD: 'Add',
            UPDATE: 'Update',
            DELETE: 'Delete'
        }
    },
    PROJECT_NAME_MAX_CHAR: 67
}

export const PROJECT_TEAM = {
    EN: {
        TITLE_PROJECT_TEAM: 'CLA Team',
        TITLE_CLIENT_TEAM: 'Client Team',
        TITLE_SEARCH_MEMBER: 'Search for Member',
        ADD_ANOTHER_CLIENT_USER: 'ADD ANOTHER CLIENT USER',
        VALIDATION_USER_EXISTS: 'User already exists',
        LINK_EDIT: 'Edit',
        LINK_CANCEL: 'Cancel',
        ROLES: {
            CONTRIBUTOR: 'Contributor',
            RECENT_CONTRIBUTOR: 'Recent Contributor',
            ENGAGEMENT_LEADER: 'Engagement Leader',
            CLIENT_RELATIONSHIP_LEADER: 'Client Relationship Leader',
        }
    }
}

export const FINALIZE_PROJECT = {
    EN: {
        HEADER_TOOLTIP: 'Clicking on Yes will cause the project to be finalized, which will cause the project to become read-only. A list of all project diagnostics that have not been resolved are displayed below. Before proceeding with the finalization process for the project, you should ensure that any relevant diagnostics are resolved and all required documentation has been captured in the project forms.'
    }
}

export const FORM_CARD_PROGRESS = 'Progress'
