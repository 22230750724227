export const DIAGNOSTICS = {
    EN: {
        PAGE_TITLE: 'Diagnostics',
        AUDIT_PROGRAM_TITLE: {
            TITLE: 'Select Audit Program',
            SUBHEADER: 'Select an audit program to associate procedures with risks'  
        },
        SECTIONS: {
            WARNING: "Warning",
            CAUTION: "Caution",
        },
        COLOR: {
            WARNING: "#BC2142",
            CAUTION: "#FBC55A",
            DEFAULT: "#7DD2D3"
        },
        REFRESH_DIAGNOSTICS: 'Refresh Diagnostics',
        FILTER: {
            LABEL: 'Filter',
            PLACEHOLDER: 'Select Reports',
            SAMPLE_OPTION: [
                { id: 'false', name: 'Select reports' },
                { id: '1', name: 'Report 1' },
                { id: '2', name: 'Report 2' },
                { id: '3', name: 'Report 3' },
                { id: '4', name: 'Report 4' }
            ]
        },
        TOOLTIP: {
            WARNING: "Issue must be addressed prior to releasing the report.",
            CAUTION: "Issue should be reviewed prior to releasing the report but may not require a change of input. Team may choose to 'Hide'.",
        },
        MODAL: {
            TITLE_HIDE: "Hide/Unhide Diagnostics",
            CONFIRM: "Save",
            CANCEL: "Cancel",
            CLOSE: "Close",
        },
        DIAGNOSTIC_TYPE: {
            SIGNIFICANT_RISK_UNADDRESSED: 2,
            ASSERTION_LEVEL_RISK_UNADDRESSED: 3,
            OTHER_RELEVANT_ASSERTIONS_MISSING: 4,
            POTENTIAL_UNNECESSARY_STEPS: 10,
            SUGGESTED_STEPS_NOT_INCLUDED: 11,
            RISK_KEY_CONTROL_MISSING: 18
        },
        DIAGNOSTIC_TYPE_NAME: {
            POTENTIAL_UNNECESSARY_STEPS: 'Suggestion: Potential Unnecessary Steps',
            SUGGESTED_STEPS_NOT_INCLUDED: 'Suggestion: Recommended Additional Steps'
        }
    }
}