import { Typography } from "@mui/material";
import { styled } from '@mui/system';
import { sanitize } from "dompurify";
import React from 'react';

import { Flex } from "@ais/palette";
import { ClientExternalIcon } from '@ais/forms';
import { FIELD_TYPES } from "@ais/constants";

const StyledFlex = styled(Flex)({
    width: '100%'
  });

const FreeText = (props) => {
    const { field } = props;

    return (
        <StyledFlex direction="row" justify="between" align="baseline">
            <Typography variant={field.type === FIELD_TYPES.FREETEXT && 'freeText'}>
                <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={{
                        __html: sanitize(field.text, { ADD_ATTR: ['target'] }),
                    }}
                />
            </Typography>
            <ClientExternalIcon
                allowExternalAccess={field.visibleToClient ?? false}
                answerable={field.editableByClient ?? false}
            />
        </StyledFlex>
    )
}

export default React.memo(FreeText);