export const DG_DASHBOARD_COLUMNS = {
    ACTION: {
        FIELD: 'action',
        HEADER_NAME: ''
    },
    CLIENT_NAME: {
        FIELD: 'ClientName',
        HEADER_NAME: 'Client Name'
    },
    CLIENT_NUMBER: {
        FIELD: 'ClientID',
        HEADER_NAME: 'Client Number'
    },
    PROJECT_ID: {
        FIELD: 'ProjectID',
        DRAWER_FIELD: 'ProjectId',
        HEADER_NAME: 'Project ID'
    },
    PROJECT_NAME: {
        FIELD: 'ProjectName',
        HEADER_NAME: 'Project Name'
    },
    PROJECT_YEAR_END: {
        FIELD: 'ProjectYearEnd',
        HEADER_NAME: 'Year End'
    },
    PROJECT_YEAR: {
        FIELD: 'ProjectYear',
        HEADER_NAME: 'Project Year'
    },
    PROJECT_STATE: {
        FIELD: 'ProjectState',
        HEADER_NAME: 'Project State'
    },
    PROJECT_TYPE: {
        FIELD: 'ProjectType',
        HEADER_NAME: 'Project Type'
    },
    ENGAGEMENT_LEADER: {
        FIELD: 'EngagementLeader',
        HEADER_NAME: 'Engagement Leader'
    },
    PROJECT_LOCATION: {
        FIELD: 'ProjectLocation',
        HEADER_NAME: 'Office'
    },
    CLC_INDUSTRY_NAME: {
        FIELD: 'CLCIndustryName',
        HEADER_NAME: 'Industry'
    },
    PROJECT_PROGRESS: {
        FIELD: 'ProjectProgress',
        HEADER_NAME: 'Progress'
    },
    PROJECT_UPDATED: {
        FIELD: 'ProjectUpdated',
        HEADER_NAME: 'Updated'
    },
    PROJECT_NUMBER: {
        FIELD: 'CLCProjectNumber',
        HEADER_NAME: 'Project Number'
    },
    PROJECT_COMPLETION_DATE: {
        FIELD: 'ProjectCompletionDate',
        HEADER_NAME: 'Completion Date'
    }
}