import { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

import { SchemaReducer } from './SchemaReducer';

const initialData = {
  formSettings: {},
  properties: [],
  disabledDeleteQuestionIds: [],
  newlyAddedIds: [],
  answerLinkDefaultIds: [],
  formTypeId: null,
  formRules: [],
  formProcedures: [],
  isLoading: false
};

export const SchemaContext = createContext({});

export const SchemaProvider = ({ children }) => {
  const [schema, dispatchSchema] = useReducer(SchemaReducer, initialData);

  const memoized = useMemo(
    () => ({ schema, dispatchSchema }),
    [schema, dispatchSchema]
  );

  return (
    <SchemaContext.Provider value={memoized}>{children}</SchemaContext.Provider>
  );
};

SchemaProvider.propTypes = {
  children: PropTypes.node,
};
