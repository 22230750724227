import { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useMsal } from '@azure/msal-react';
import { stringHelper as string } from '@ais/utilities';
import { useNotificationsStore } from './store/notifications';
import { markNotificationAsNotified } from '@services/projectPdf';
import { keyBy, orderBy, values } from 'lodash';

const baseUrl = process.env.REACT_APP_API_FORM_WEBSOCKET;
const proxy = string.stripPath(baseUrl);
const namespace = new URL('/notifications', baseUrl)
export const socket = io(namespace.href, {
  path: proxy + '/ais',
  transports: ['websocket'],
  autoConnect: false,
  upgrade: false,
});

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const { accounts } = useMsal();
  const userId = accounts[0].localAccountId.toUpperCase();

  const [notificationsRefreshed, setNotificationsRefreshed] = useState(false);

  const notifications = useNotificationsStore((state) => state.notifications);
  const updateNotifications = useNotificationsStore((state) => state.updateNotifications);

  const updateNotificationBell = (notifs) => {
    setNotificationsRefreshed(!!notifs?.filter((notification) => !notification.isRead && !notification.isDelete)?.length);
  };

  const updateNotification = (index, key, value) => {
    const newNotifications = [...notifications];
    newNotifications[index][key] = value;

    // On notification read or delete, mark the notification as notified
    if ((key === 'isRead' || key === 'isDelete') && value === true) {
      markNotificationAsNotified(newNotifications[index].JobInstanceId);
    }
    // Remove any deleted notifications from the array
    updateNotifications(newNotifications);
  
     // Update Status for Red dot on notification Bell
    updateNotificationBell(newNotifications);
  };
 
  useEffect(() => {
    const handleNewNotification = (newNotification) => {
      // Convert array to object indexed by JobInstanceId
      let jobsById = keyBy(notifications, 'JobInstanceId');

      // Add or overwrite the object with the same JobInstanceId
      jobsById[newNotification.JobInstanceId] = { ...newNotification, isRead: false, isDelete: false, updatedDate: new Date().toISOString() };

      // Convert back to array
      let jobs = values(jobsById);

      // Sort by updatedDate in descending order
      jobs = orderBy(jobs, ['updatedDate'], ['desc']);

      // Update state
      updateNotifications(jobs);

      // Update Status for Red dot on notification Bell
      updateNotificationBell(jobs);
    };

    socket.connect();

    socket.on('connect', () => {
      socket.emit('subscribe_to_notification', { userId });
    });

    socket.on('notify_subscribers', handleNewNotification);

    return () => {
      socket.off('connect');
      socket.off('notify_subscribers', handleNewNotification);
    };
  }, [notifications, updateNotifications, userId]);

  // Start: Sync local storage with store
  const updateStore = () => {
    useNotificationsStore.persist.rehydrate();
    updateNotificationBell(notifications);
  };

  useEffect(() => {
      document.addEventListener("visibilitychange", updateStore);
      window.addEventListener("focus", updateStore);

      return () => {
          document.removeEventListener("visibilitychange", updateStore);
          window.removeEventListener("focus", updateStore);
      };
  }, [notifications]);
  // End: Sync local storage with store

  return (
    <NotificationContext.Provider
      value={{ socket, notifications, notificationsRefreshed, updateNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext must be used within NotificationProvider');
  }
  return context;
};