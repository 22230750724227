import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
  Grid,
} from '@mui/material';
import { PLANNING_ANALYTICS_FORM_TYPE_ID } from '@ais/constants';
import {ClientExternalIcon} from '@ais/forms';
import { Flex } from '@ais/palette';
import styles from './CLARadioButtonList.module.css';

export const CLARadioButtonList = (props) => {
  const {
    id,
    label,
    value,
    columns,
    options,
    allowOtherOption,
    onChange,
    isDisabled,
    onFocus,
    defaultValue,
    formTypeId,
    allowExternalAccess,
    answerable,
    answered,
    customClass,
    onBlur,
  } = props;

  const fieldId = 'CLARadioButtonList_' + id;
  const otherRadioValue = 'CLAOtherRadio_' + id;

  const isValueExistsInOptions = (val) => {
    const otherValueChecker = options.includes(val);
    return otherValueChecker;  //returns false if other value is selected
  }

  const textFieldRef = useRef(null);

  const _value = isValueExistsInOptions(value) ? value : null;
  const _otherValue = !isValueExistsInOptions(value) && (value || value === '') ? value : null;

  const [actualValue, setActualValue] = useState(_otherValue === null ? _value : otherRadioValue);
  const [otherValue, setOtherValue] = useState(_otherValue);

  const handleRadioGroupChange = (e) => {
    const value = e.target.value;
    if (value === otherRadioValue)
      textFieldRef.current?.focus();
    else 
      onChange && onChange(value);
    setActualValue(value);
    setOtherValue('');
  };

  const handleTextFieldChange = (e) => {
    const value = e.target.value;
    setOtherValue(value);
    setActualValue(otherRadioValue)
  };

  const handleOtherTextBlur = (e) => {
    onChange && onChange(otherValue);
    onBlur && onBlur();
  }

  const initializeOtherValue = () => {
    setActualValue(otherRadioValue);
    if (otherValue === null)
      setOtherValue('');
  }

  const formIsPlanningAnalytics = formTypeId && formTypeId === PLANNING_ANALYTICS_FORM_TYPE_ID;
  const boldAndBlue = { fontWeight: 'bold', color: '#2E334E' };

  const setupOptions = () => {
    const optionFields = options.map(
      (option, index) => {
        return option && (
          <FormControlLabel
            key={index}
            control={<Radio disabled={isDisabled} />}
            value={option}
            label={option}
            sx={
              formIsPlanningAnalytics
                ? {
                  '& .MuiFormControlLabel-label': boldAndBlue,
                }
                : {}
            }
          />
        )
      }
    );
    if (allowOtherOption) {
      const lastIndex = optionFields.length;
      optionFields.push(
        <div className={styles['other-wrapper']} key={lastIndex}>
          <FormControlLabel
            key={`other_label_${lastIndex}`}
            control={<Radio disabled={isDisabled} />}
            value={otherRadioValue}
            label="Other"
          />
          <TextField
            inputRef={textFieldRef}
            key={`other_textfield_${lastIndex}`}
            value={otherValue}
            onChange={handleTextFieldChange}
            fullWidth
            disabled={isDisabled}
            onBlur={handleOtherTextBlur}
            onFocus={() => initializeOtherValue()}
          />
        </div>
      );
    }
    return optionFields;
  };

  useEffect(() => {
    const _otherValue = !isValueExistsInOptions(value) && (value || value === '') ? value : null;

    setActualValue(_otherValue === null ? _value : otherRadioValue);
    if (value === otherRadioValue || isValueExistsInOptions(value)) setOtherValue('');
    else if (!isValueExistsInOptions(value)) setOtherValue(value);
  }, [value])
  return (
    <FormControl className={styles['radio-control']}>
      <Flex direction="row" justify="between">
        <FormLabel id={fieldId} sx={formIsPlanningAnalytics ? boldAndBlue : {}}>
          {label}
        </FormLabel>
        <ClientExternalIcon
          allowExternalAccess={allowExternalAccess}
          answerable={answerable}
          answered={answered} />
      </Flex>
      <RadioGroup
        row
        name={fieldId}
        value={actualValue}
        onChange={handleRadioGroupChange}
        className={styles['container-list']}
        onFocus={onFocus}
        defaultValue={defaultValue}
      >
        <Grid container spacing={2} columns={columns}>
          {setupOptions().map((list, rowIndex) => (
            <Grid key={rowIndex} className={`${styles['list-wrapper']} ${customClass?.noMinWidth}`} item xs={1}>
              {list}
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

CLARadioButtonList.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string,
  columns: PropTypes.number,
  options: PropTypes.array,
  allowOtherOption: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  allowExternalAccess: PropTypes.bool,
  answerable: PropTypes.bool
};

CLARadioButtonList.defaultProps = {
  value: undefined,
  columns: 1,
  options: [],
  allowOtherOption: false,
  isDisabled: false,
  allowExternalAccess: false,
  answerable: false
};

export default CLARadioButtonList;
