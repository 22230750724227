import { memo, useEffect, useRef } from "react";
import AutoNumeric from 'autonumeric';
import { StyledOutlinedInput } from "./NumericalField";

export const SHORT_ANSWER_MAX_CHARACTERS = 256;
    

const CurrencyField = (props) => {

  const inputRef = useRef(null);
  const autoNumericRef = useRef(null);

  useEffect(() => {
      const inputElement = inputRef.current;
      const autoNumeric = new AutoNumeric(inputElement, {
        allowDecimalPadding: 'floats',
        currencySymbol: '$',
        decimalPlaces: 2,
        negativeBracketsTypeOnBlur: '(,)',
        selectOnFocus: false,
        showWarnings: false,
        modifyValueOnWheel: false,
        watchExternalChanges: true
      });

      autoNumericRef.current = autoNumeric;

      return () => {
          autoNumeric.remove();
      };
  }, []);
  return (
    <StyledOutlinedInput
      {...props}
      notched
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
      }}
      inputProps={{
        ...props.inputProps,
        maxLength: SHORT_ANSWER_MAX_CHARACTERS
      }}
      inputRef={inputRef}
    />)
}

export default memo(CurrencyField)