export const GENERAL_LEDGER_COMPLETENESS_TRANSACTION_DESCRIPTION = "TransactionDescription"

export const GENERAL_LEDGER_COMPLETENESS_TRANSACTION_COLUMN = [
  {
    field: "TransactionDate",
    headerName: "Transaction \nDate",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 300,
    flex: 1,
  },
  {
    field: "TransactionReference",
    headerName: "Transaction \nReference",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 300,
    flex: 1,
  },
  {
    field: "TransactionDescription",
    headerName: "Transaction \nDescription",
    editable: false,
    sortable: false,
    filterable: false,
    flex: 1,

  },
  {
    field: "TransactionDebitAmount",
    headerName: "Debit \nAmount",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 120,
    flex: 1,
  },
  {
    field: "TransactionCreditAmount",
    headerName: "Credit \n Amount",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 120,
    flex: 1,
  },
];
