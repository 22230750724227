import { useState } from "react";
import { Button } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";

import { BasicDropdown } from "./BasicDropdown";
import { convertStringToArray, validateAdvancedDefaultAnswers } from "@components/Forms/helpers";
import DefaultValueDrawer from "./DefaultValueDrawer";
import DefaultValues from "./DefaultValues";

import {FIELD_TYPES} from '@ais/constants';
import { DIALOG } from "@constants/forms";

import styles from './CheckboxDefaultAnswersTab.module.css';

const CheckboxDefaultAnswersTab = () => {
    const { control, setValue } = useFormContext();
    const watchDefaulValues = useWatch({ control, name: 'defaultValues' }) ?? [];
    const watchLabel = useWatch({ control, name: 'label' }) ?? '';
    const watchOptions = useWatch({ control, name: 'options' }) ?? '';
    const { PLACEHOLDER, LABEL } = DIALOG.SETTINGS;
    const { EDIT_ADVANCED, ADVANCED, ADVANCED_MODE_DETECTED, SELECT_DEFAULT_ANSWER } = DIALOG.DEFAULT_ANSWERS.GENERAL;

    const [openDrawer, setOpenDrawer] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);
    const [defaultValues, setDefaultValues] = useState([]);

    const handleChange = (values) => {
        const validationResult = validateAdvancedDefaultAnswers(values, watchDefaulValues, true);
        setIsValidForm(validationResult.isValid);
        setDefaultValues(validationResult.newValues);
    };

    const handleSave = () => {
        setValue("defaultValues", defaultValues);
        setValue("defaultValue", '');
        setOpenDrawer(false);
    };

    return (
        <div className={styles['chkbox-defaultanswers-tab']}>
            <div className={styles['chkbox-defaultanswers-header']}>
                <div className={styles['chkbox-defaultanswers-header-label']}>{LABEL.QUESTION_LABEL}</div>
                <div className={styles['chkbox-defaultanswers-header-value']}>{watchLabel}</div>
            </div>
            <div className={styles['chkbox-defaultanswers-wrapper']}>
                <div className={styles['chkbox-defaultanswers-dropdown']}>
                    <BasicDropdown
                        name="defaultValue"
                        label={SELECT_DEFAULT_ANSWER}
                        placeholder={
                            watchDefaulValues.length
                                ? ADVANCED_MODE_DETECTED
                                : PLACEHOLDER.BASIC_DROPDOWN
                        }
                        disabled={watchDefaulValues.length ? true : false}
                        options={convertStringToArray(watchOptions)}
                    />
                </div>
                <div className={styles['chkbox-defaultanswers-button']}>
                    <Button
                        onClick={() => {
                            setOpenDrawer(true);
                        }}
                        variant="text"
                        disableRipple
                        sx={{
                            fontFamily: "museo-sans",
                            textTransform: "none",
                            color: "#76D2D3",
                            fontSize: "16px",
                            p: 0,
                            ":hover": {
                                backgroundColor: "inherit",
                            },
                        }}
                    >
                        {watchDefaulValues.length ? EDIT_ADVANCED : ADVANCED}
                    </Button>
                </div>
            </div>
            <div>
                <DefaultValueDrawer
                    name="defaultValues"
                    formComponentName="Checkbox Menu"
                    drawerVisible={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    handleSave={handleSave}
                    isValidForm={isValidForm}
                >
                    <DefaultValues
                        onChange={handleChange}
                        fieldType={FIELD_TYPES.CHECKBOXGROUP}
                    />
                </DefaultValueDrawer>
            </div>
        </div>
    );
};

export default CheckboxDefaultAnswersTab;
