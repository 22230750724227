export const CLA_INITIAL_SCOPING_CONSIDERATIONS_MODAL = {
    EN: {
        WARNING_TITLE: 'Warning - Associated Items Need to be Removed',
        ASSOCIATED_RISK_FACTORS: 'Associated Risk Factors',
        BUTTON_LABEL: 'OK',
        ASSOCIATED_RISK: 'Associated Identified Risks',
        ASSOCIATED_DEFICIENCIES: 'Associated Deficiencies',
        KEY_CONTROLS: 'Key Controls for Associated Identified Risks',
        FRAMEWORK: {
            WARNING_TEXT: 'Changing the selected frameworks will result in risk factor(s), identified risk(s), key control(s), or deficiencies no longer associated with a valid audit area. Please remove all of the following items associated with the audit area(s) that will be deleted before changing the selected framework(s).',
        },
        PERFORMANCE_STANDARDS: {
            WARNING_TEXT: 'Changing the selected performance standards will result in risk factor(s), identified risk(s), key control(s), or deficiencies no longer being associated with a valid audit area. Please remove all of the following items associated with the audit area(s) that will be deleted before changing the selected performance standard(s).',
        },
    }
}