import formApi from '@utilities/claApiForm';
import { useQuery, useQueries } from "@tanstack/react-query"
import logger from '@utilities/logService'

const getFrameworks = async (methodologyVersionId, isEnabledOnly = false) => {
    try {
        const response = await formApi.get(`/frameworks/${methodologyVersionId}`);

        return (!isEnabledOnly) ? response.data : response.data.filter(({ IsEnabled }) => IsEnabled);
    } catch (error) {
        logger.error(error)
    }
}

const getFrameworksByMethodology = async (methodologyVersionId) => {
    try {
        const response = await formApi.get(`/frameworks/${methodologyVersionId}`);

        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

const getFrameworksListLibraryByMethodology = async (methodologyVersionId) => {
    try {
        const response = await formApi.get(`/methodology/${methodologyVersionId}/lists/frameworks/`);

        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

const putMethodologyFrameworks = async (methodologyVersionId, body) => {
    try {        
        const response = await formApi.put(`/methodology/${methodologyVersionId}/lists/frameworks/`, body);
        return { status: 200, data: response.data };
    } catch (error) {
        const { response } = error;
        return { status: 400, message: response?.data?.message ?? error };
    }
};

export const useFrameworks = (methodologyVersionId, isEnabledOnly = false) => {
    return useQuery(
        ['frameworks', methodologyVersionId],
        async () => await getFrameworks(methodologyVersionId, isEnabledOnly),
        {
            enabled: !!methodologyVersionId
        } 
    )
}

export const useFrameworkAuditAreaData = (projectScopeId, projectScopeFrameworkIds) => {
    return useQuery(
        ['frameworkAuditAreaData', ...projectScopeFrameworkIds],
        async () => {
            const { data } = await formApi.post(`/frameworks/auditareadata/`, {
                projectScopeId,
                projectScopeFrameworkIds
            })
            return data
        },
        {
            enabled: Array.isArray(projectScopeFrameworkIds) 
                && projectScopeFrameworkIds.length > 0
                && !!projectScopeId
        }
    )
}
  
const frameworks = {
    getFrameworks,
    getFrameworksByMethodology,
    getFrameworksListLibraryByMethodology,
    putMethodologyFrameworks,
}

export default frameworks;