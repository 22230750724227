import { Grid, Typography, Box } from '@mui/material';
import { useDrag } from 'react-dnd';
import { DRAG_TYPES } from '@ais/constants';

export const FormulaOperators = ({ isConditional }) => {    
    return (
        <Grid container sx={{ paddingRight: '50px' }} rowSpacing={2}>
            <Grid item xs={12}>
                <Typography variant="title" sx={{ fontSize: '18px' }}>
                    Operators
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: '20px' }}>
                    <Operator operator="+" value="+" />
                    <Operator operator="-" value="-" />
                    <Operator operator="/" value="/" />
                    <Operator operator="x" value="*" />
                    <Operator operator="(" value="(" />
                    <Operator operator=")" value=")" />
                    <Operator operator="if" value="" disabled={isConditional}/>                    
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: '15px' }}>
                    <Operator operator="=" value="===" disabled={!isConditional}/>
                    <Operator operator="<" value="<" disabled={!isConditional}/>
                    <Operator operator=">" value=">" disabled={!isConditional}/>
                    <Operator operator="<=" value="<=" disabled={!isConditional}/>
                    <Operator operator=">=" value=">=" disabled={!isConditional}/>
                    <Operator operator="<>" value="!" disabled={!isConditional}/>
                    <Operator operator="AND" value="&&" disabled={!isConditional}/>
                    <Operator operator="OR" value="||" disabled={!isConditional}/>          
                </Box>
            </Grid>
        </Grid>
    );
};

const Operator = ({ operator, value, disabled = false }) => {
    const [{ isDragging }, ref] = useDrag({
        type: DRAG_TYPES.FORMULA_OPERATOR,
        item: {
            value,
            operator,
            itemType: DRAG_TYPES.FORMULA_OPERATOR,
            isPlaceholder: true,
        },
        collect: monitor => ({ isDragging: monitor.isDragging() }),
    });
    
    return (                 
            <Box
                ref={disabled ? null : ref}
                title={operator}
                sx={{
                    borderRadius: 1,
                    backgroundColor: 'rgba(118, 210, 211, 0.5)',
                    color: disabled ? 'grey' : 'navy.main',
                    width: 45,
                    height: 45,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: !['AND', 'OR'].includes(operator) ? '22px' : '16px',
                    fontWeight: 'bold',
                    cursor: disabled ? null : 'move',
                }}                
            >
                {operator}
            </Box>
    );
};