import clientApi from '@utilities/claApiClient';
import { useQuery, useQueries } from '@tanstack/react-query';
import logger from '@utilities/logService'

export const getTrialBalanceById = async (trialBalanceId, projectId) => {
  const { data } = await clientApi.get(
    `/clc-audit/trial-balance/${trialBalanceId}/${projectId}`
  );
  return data;
}

export const getProjectTrialBalanceData = async (appDataInstanceId, period, projectId) => {
  const { data } = await clientApi.get(
    `/clc-audit/trial-balance/${appDataInstanceId}/${period}/${projectId}`
  );
  return data;
};

export const useGetTrialBalance = (trialBalanceId, projectId) => {
  return useQuery(
    ['TrialBalanceById', trialBalanceId],
    () => getTrialBalanceById(trialBalanceId, projectId),
    {
      enabled: !!trialBalanceId,
      onError: error => logger.error(error),
      retry: 3,
      retryDelay: 1000
    }
  );
}

export const getCurrentPeriodComparison = async (projectFormId, trialBalanceIds, projectId) => {
  const __projectFormId = projectFormId ? projectFormId.toString() : ""
  const data = await clientApi.post(
    `/clc-audit/trial-balance/current-period-comparison/${projectId}`, { projectFormId: __projectFormId, trialBalanceIds }
  );
  return data;
};

export const useGetProjectTrialBalanceData = (
  appDataInstanceId,
  period,
  projectId
) => {
  return useQuery(
    ['ProjectTrialBalanceData', appDataInstanceId, period],
    () => getProjectTrialBalanceData(appDataInstanceId, period, projectId),
    {
      enabled: !!appDataInstanceId && !!period,
      onError: error => logger.error(error),
      retry: 3,
      retryDelay: 1000
    }
  );
};

export const useCurrentPeriodComparison = (projectFormId, trialBalanceIds, projectId) => {
  return useQuery(
    ['ProjectTrialBalanceCurrentPeriodComparison', projectFormId, trialBalanceIds],
    () => getCurrentPeriodComparison(projectFormId, trialBalanceIds, projectId),
    {
      enabled: !!projectFormId && Array.isArray(trialBalanceIds),
      onError: error => logger.error(error),
      retry: 3,
      retryDelay: 1000
    }
  );
};

const PROJECT_TRIAL_BALANCES_QRY_FACTORY = {
  all: ["project_trial_balances"],
  list: () => [...PROJECT_TRIAL_BALANCES_QRY_FACTORY.all, "list"],
  details: (trialBalanceId) => [...PROJECT_TRIAL_BALANCES_QRY_FACTORY.all, "details", trialBalanceId]
}

export const useProjectTrialBalances = (appDataInstanceId, projectId) => {
  return useQuery(
    PROJECT_TRIAL_BALANCES_QRY_FACTORY.list(),
    async () => {
      const { data } = await clientApi.get(`/clc-audit/trial-balance/${projectId}?appDataInstanceId=${appDataInstanceId}`);
      //Data coming back may be double nested (data inside data object), this isn't on the clientApi end itself as that returns this nested properly
      //The return logic below will, should anything change, return the actual data whether it's nested or made available at first level
      return data;
    },
    {
      enabled: !!appDataInstanceId,
      staleTime: Infinity,
      select: (items) => {
        return items.map((item) => ({ ...item, label: item.correlationName, value: item.id }))
      },
      retry: 3,
      retryDelay: 1000
    }
  )
}

export const useTrialBalances = (trialBalanceIds, projectId) => {
  return useQueries({
    queries: (trialBalanceIds ?? []).map((trialBalanceId) => ({
      queryKey: PROJECT_TRIAL_BALANCES_QRY_FACTORY.details(trialBalanceId),
      queryFn: async () => {
        const { data } = await clientApi.get(`/clc-audit/trial-balance/${trialBalanceId}/${projectId}`);
        return data;
      },
      enabled: trialBalanceId !== '00000000-0000-0000-0000-000000000000' && !!trialBalanceIds.length > 0 && !!trialBalanceIds && !!Array.isArray(trialBalanceIds),
      retry: 3,
      retryDelay: 1000
    }))
  })
}
