import formApi from '@utilities/claApiForm';
import { useQuery } from "@tanstack/react-query"
import logger from '@utilities/logService'

const addMethodologyVersionReports = async (methodologyVersionReport) => {
    try {
        const response = await formApi.post('/methodologyversionreports', methodologyVersionReport);
        return { status: 201, data: response };
    } catch (error) {
        return { status: 400, message: error };
    }
}

const getMethodologyVersionReports = async (methologyVersionReportId) => {
    try {
        const response = await formApi.get(`/methodologyversionreports/${methologyVersionReportId}`);
        return { status: 201, data: response };
    } catch (error) {
        return { status: 400, message: error };
    }
}

const updateMethodologyVersionReports = async (methologyVersionReportId, methodologyVersionReport) => {
    try {
        const response = await formApi.put(`/methodologyversionreports/${methologyVersionReportId}`, methodologyVersionReport);
        return { status: 200, data: response };
    } catch (error) {
        return { status: 400, message: error };
    }
}

const getMethodologyChangeReport = async (methodologyVersionId, previousVersionId) => {
    try {
        return await formApi.get(`/methodology/${methodologyVersionId}/change-report/${previousVersionId}`);
    } catch (error) {
        return { status: 400, message: error };
    }
}

const getMethodologyChangeReportByMethodologyVersionId = async (methodologyVersionId) => {
    try {
        const { data } = await formApi.get(`/methodology/${methodologyVersionId}/change-report/`);
        return data ? data[0] : null;
    } catch (error) {
        logger.error(error);
    }
}

export const useGetMethodologyChangeReportByMethodologyVersionId = (methodologyVersionId) => {
        return useQuery(
        ["MethodologyVersionChangeReport", methodologyVersionId],
        () => getMethodologyChangeReportByMethodologyVersionId(methodologyVersionId),
        {
            enabled: !!methodologyVersionId
        }
    )
}

const getMethodologyReports = async (methodologyVersionId) => {
    try {
        return await formApi.get(`/methodology/${methodologyVersionId}/change-report`);
    } catch (error) {
        return { status: 400, message: error };
    }
}

const methodologyVersionReportService = {

    addMethodologyVersionReports,
    getMethodologyVersionReports,
    updateMethodologyVersionReports,
    getMethodologyChangeReport,
    getMethodologyReports
};

export default methodologyVersionReportService;