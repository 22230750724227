export default {
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
    fontWeight: "700",
    fontSize: "1.3em",
    fontFamily: "museo-sans, sans-serif",
    lineHeight: "19.2px",
    backgroundColor: "#F6F6F6",
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell": {
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-detailPanelToggleCell ": {
    backgroundColor: "#f7f7f7"
  }
}