import axios from 'axios'
import { AIS_RELEASEVERSION } from '@constants/index';

class ApiClientFactory {
    static isVersionExpired = false;
    static isMaintenanceMode = false;

    createClient(config) {
        let newClient = axios.create(config);

        newClient.interceptors.response.use(async (response) => {
            // Check response header from API for server release version
            let apiVersion = response.headers['ais-releaseversion'];

            // If mismatch between versions, redirect to display error modal
            if (!!apiVersion && apiVersion !== AIS_RELEASEVERSION) {
                this.isVersionExpired = true;
            }
            return response;
        }, (error) => {
            if (error.response && error.response.status === 503) {
                this.isMaintenanceMode = true;
            }
            return Promise.reject(error);
        });

        return newClient;
    }
}

const apiClientFactory = new ApiClientFactory();
export default apiClientFactory;