export const clientScopes = [
    `${process.env.REACT_APP_API_CLIENT_ID_URI}access_as_user`,
]

export const extFormScopes = [
    `${process.env.REACT_APP_API_EXT_FORM_ID_URI}access_as_user`,
]

export const formScopes = [
    `${process.env.REACT_APP_API_FORM_ID_URI}access_as_user`,
]

export const reportScopes = [
    `${process.env.REACT_APP_API_REPORT_ID_URI}access_as_user`,
]


export const graphScopes = [
    'https://graph.microsoft.com/Contacts.Read',
    'https://graph.microsoft.com/People.Read',
    'https://graph.microsoft.com/Presence.Read.All',
    'https://graph.microsoft.com/User.ReadBasic.All',
    'https://graph.microsoft.com/User.Read.All'
]
