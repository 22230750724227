import { IconButton, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import styles from './CLASimpleDrawer.module.css';

export const CLASimpleDrawer = (props) => {
  const { anchor, onClose, isVisible, children, headerTitle, allowBackDropCloseClick, width, zIndex } = props;

  const handleDrawerClose = (ev, reason) => {
    if (reason === 'backdropClick' && allowBackDropCloseClick) {
      onClose();
    }
  };

  return (
    <Drawer
      anchor={anchor}
      open={isVisible}
      onClose={handleDrawerClose}
      style={{ zIndex: zIndex ? zIndex : 1101 }}
      PaperProps={{
        sx: { width: width, backgroundColor: 'gray !important' },
      }}
    >
      <div className={styles['close-btn-wrapper']}>
        <IconButton
          variant="close-drawer-button"
          sx={{ marginTop: '20px', position: 'inherit', marginRight: '15px', padding: '10px', float: 'right' }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        {
          headerTitle &&
          <div className={styles['header-title']}>
            {headerTitle}
          </div>
        }
      </div>
      <div className={styles['drawer-container']}>{children}</div>
    </Drawer>
  );
};
CLASimpleDrawer.propTypes = {
  allowBackDropCloseClick: PropTypes.bool,
  width: PropTypes.string
}
CLASimpleDrawer.defaultProps = {
  allowBackDropCloseClick: false,
  width: '55%'
}


export default CLASimpleDrawer;
