import { createContext, useState } from 'react';

const initialState = {};

export const TailorProceduresDrawerContext = createContext({});

export const TailorProceduresDrawerProvider = ({ children }) => {
    
    const [showProcedureDrawer, setShowProcedureDrawer] = useState({ auditArea: null, show: false, sectionId: null });

    return (
        <TailorProceduresDrawerContext.Provider value={{
            showProcedureDrawer, setShowProcedureDrawer
        }}>
            {children}
        </TailorProceduresDrawerContext.Provider>
    )
}

