import { createTheme } from '@mui/material/styles';
import { tablePaginationClasses } from '@mui/material/TablePagination';
import { inputBaseClasses } from '@mui/material/InputBase';
import { tableFooterClasses } from '@mui/material/TableFooter';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {CheckboxIconComponent} from '@ais/assets';

export const fonts = {
  main: 'museo-sans',
  header: 'Georgia',
};

export const colors = {
  riptide: {
    40: '#E4F6F6',
    50: '#D1E7DD',
    100: '#C2EAEA',
    200: '#A4DFE0',
    300: '#95D9DB',
    400: '#7DD2D3',
    450: '#76D2D3',
    460: '#7dd2d380',
    475: '#6BAEB4',
    500: '#49BFC1',
    600: '#39A5A7',
    605: '#34A0A2',
    700: '#2A787A',
  },
  navy: {
    100: '#8B92BB',
    200: '#535C8D',
    300: '#3C4367',
    400: '#2E334E',
    450: '#2D3C59',
    500: '#262A40',
    600: '#1E2133',
    700: '#171927',
  },
  celedon: {
    100: '#F5F7D1',
    200: '#EEF2B2',
    300: '#E7EC93',
    400: '#E2E868',
    500: '#B0B650',
    600: '#84893C',
    700: '#42441E',
  },
  saffron: {
    100: '#FEEECE',
    200: '#FDDC9C',
    300: '#FCD17B',
    400: '#FBC55A',
    500: '#C99E48',
    600: '#7E632D',
    700: '#4B3B1B',
  },
  scarlett: {
    100: '#FBCDC4',
    200: '#F69B89',
    300: '#F37962',
    400: '#EE5340',
    500: '#C0462F',
    600: '#782C1E',
    700: '#481A12',
  },
  green: '#198754',
  charcoal: '#25282A',
  smoke: '#ABAEAB',
  white: '#FFFFFF',
  black: '#000000',
  gray: {
    25: '#F7F7F7',
    30: '#F6F6F6',
    35: '#C6C6C6',
    40: '#AEAEAE',
    50: '#97979780',
    75: '#97979740',
    100: '#979797',
    200: '#6D6D6D',
    300: '#595959',
    350: '#565656',
    400: '#4B4B4B',
    500: '#333333'
  },
  red: {
    100: '#FD4747',
    200: '#BC2142'
  }
};

export const theme = createTheme({
  palette: {
    riptide: {
      main: colors.riptide[500],
    },
    navy: {
      main: colors.navy[600],
    },
    error: {
      main: colors.red[100],
    },
    gray: {
      main: colors.gray[200],
    }
  },
  typography: {
    fontFamily: fonts.main,
    title: {
      fontFamily: fonts.header,
      fontWeight: 700,
      color: colors.navy[400],
    },
    freeText: {
      fontFamily: fonts.main,
      fontWeight: 600,
      fontSize: '18px',
      color: colors.gray[200],
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
      textAlign: 'justify',
      width: '100%',
    },
    label: {
      fontFamily: fonts.main,
      fontWeight: 400,
      fontSize: '16px',
      color: colors.black,
    },
  },
  components: {
    defaultProps: {
      disableElevation: true,
    },
    MuiTextField: {
      defaultProps: {
        color: 'riptide',
      },
    },
    MuiFormControl: {
      defaultProps: {
        color: 'riptide',
      },
      styleOverrides: {
        root: {
          marginTop: '0px',
          marginBottom: '0px',
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // overrides tooltip
          zIndex: 1101 + '!important',
        },
        popper: {
         // overrides the popper
          zIndex: 1101 + '!important',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.black,
          '&.Mui-focused': {
            color: colors.black,
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          caretColor: colors.riptide[605],
          fontWeight: 600,
          color: colors.gray[300],
          '&.Mui-focused': {
            color: colors.gray[300]
          }
        }
      },
      variants: [
        {
          props: { variant: 'multiline-label' },
          style: {
            fontSize: '12px',
            margin: '0px 14px',
            top: '9px',
            position: 'inherit',
            transformOrigin: 'unset',
            left: 'unset',
            whiteSpace: 'normal',
            transform: 'none',
            color: colors.gray[300],
            zIndex: 1
          }
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: colors.gray[100],
          backgroundColor: colors.white,
          '&:not(.Mui-error):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.riptide[605],
            borderWidth: '1px'
          },
          '&:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.riptide[605],
          },
          '&.Mui-error': {
            borderColor: colors.red[100],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px'
          }
        },
        input: {
          color: colors.gray[400],
          fontWeight: 600,
          padding: '12px',
          caretColor: colors.riptide[400],
          '&::placeholder': {
            color: colors.gray[100],
          },
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon
      },
      styleOverrides: {
        icon: {
          color: colors.charcoal,
          width: '1.25em',
          height: '1.25em',
          top: 'calc(50% - 0.625em)',
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'navy',
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: '6px',
          paddingTop: 0,
          paddingBottom: 0,
          '&:not(.Mui-disabled):hover': {
            color: colors.navy[600],
            backgroundColor: 'transparent'
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'navy',
        disableRipple: true,
        checkedIcon: <CheckboxIconComponent />
      },
      styleOverrides: {
        root: {
          '& input': {
            width: '38px',
            height: '24px',
          },
          color: colors.gray[100],
          padding: 0,
          paddingLeft: '8px',
          paddingRight: '6px',
          '&:not(.Mui-disabled):hover': {
            color: colors.navy[600],
          },
          '&:not(.Mui-disabled):hover.Mui-checked': {
            filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.5))'
          },
          '&.Mui-checked': {
            color: colors.navy[600],
          },
          '&.Mui-disabled.Mui-checked': {
            "& svg": {
              "& rect": {
                fill: "#9D9A9A"
              }
            }
          }
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: colors.riptide[450],
            color: "rgba(0, 0, 0, 0.87)"
          },
          "&:hover": {
            backgroundColor: colors.riptide[460]
          },
          "&:focus": {
            backgroundColor: colors.riptide[450]
          },
          "&:focus.Mui-selected": {
            backgroundColor: colors.riptide[450]
          },
          "&.Mui-selected:hover": {
            backgroundColor: colors.riptide[460]
          },
          "$.MuiCheckbox-root.Mui-checked": {
            color: colors.navy[600],
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        textTransform: 'none',
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '13px',
          lineHeight: '16px',
          borderRadius: '3px',
        },
        outlined: {
          padding: '8px 24px 8px 24px',
          backgroundColor: 'transparent',
          border: `1px solid ${colors.navy[400]}`,
          color: colors.navy[400],
          ':hover': {
            borderColor: 'inherit',
            backgroundColor: 'inherit',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.5)'
          },
        },
        contained: {
          padding: '8px 36px 8px 36px',
          backgroundColor: colors.navy[400],
          border: `1px solid ${colors.navy[400]}`,
          color: colors.white,
          '&:active': {
            borderColor: colors.navy[600],
            backgroundColor: colors.navy[600],
            boxShadow: 'none'
          },

          '&:hover': {
            backgroundColor: colors.navy[400],
            boxShadow: '0px 2px 4px rgba(0,0,0,0.5)'
          },
          '&.Mui-disabled': {
            backgroundColor: colors.gray[25],
            color: colors.gray[40],
            borderColor: colors.gray[40],
          },
        },
      },
      variants: [
        {
          props: { variant: "button-white" },
          style: {
            padding: '8px 24px 8px 24px',
            backgroundColor: colors.white,
            height: '48px',
            fontSize: '12px',
            lineHeight: "14.4px",
            color: colors.navy[400],
            ':hover': {
              borderColor: 'inherit',
              backgroundColor: colors.white,
              boxShadow: '0px 2px 4px rgba(0,0,0,0.5)'
            },
          }
        },
        {
          props: { variant: "page-button-add-form" },
          style: {
            backgroundColor: colors.white,
            color: colors.gray[350],
            minWidth: "1%",
            height: "22px",
            fontSize: "14px",
            lineHeight: "17px",
            textTransform: "uppercase",
            margin: "39px 0px 0px 39px",
            ":hover": {
              backgroundColor: colors.white,
              color: colors.gray[350],
            }
          }
        },
        {
          props: { variant: "page-button-add-key-control" },
          style: {
            backgroundColor: colors.white,
            color: colors.gray[350],
            minWidth: "1%",
            height: "22px",
            fontSize: "14px",
            lineHeight: "17px",
            textTransform: "uppercase",
            fontWeight: "bold",
            margin: "0px 0px 0px 0px",
            ":hover": {
              backgroundColor: colors.white,
              color: colors.gray[350],
            }
          }
        },
        {
          props: { variant: "form-view-add-sign-off" },
          style: {
            color: colors.gray[350],
            minWidth: "1%",
            height: "22px",
            fontWeight: "700",
            fontSize: "11px",
            lineHeight: "17px",
            textTransform: "uppercase",
            padding: "2px",
            "& span": {
              color: colors.riptide[400],
              fontSize: "14px",
              paddingRight: "4px"
            }
          }
        },
        {
          props: { variant: "close-view-form" },
          style: {
            color: '#4B4B4B',
            borderRadius: '2px',
            backgroundColor: '#F7F7F7',
            ":hover": {
              backgroundColor: '#F7F7F7',
              boxShadow: '0px 2px 4px rgba(0,0,0,0.5)'
            },
          }
        }
      ]
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '& .MuiSnackbarContent-message': {
            margin: 'auto'
          }
        }
      },
      variants: [
        {
          props: { variant: "success" },
          style: {
            "& .MuiSnackbarContent-root": {
              background: colors.riptide[50],
              border: '1px solid rgba(0,0,0,0.05)',
              color: colors.green
            }
          }
        },
        {
          props: { variant: "error" },
          style: {
            "& .MuiSnackbar-root": {
              marginTop: 0
            }
          }
        },
        {
          props: { variant: "informational-message" },
          style: {
            "& .MuiSnackbarContent-root": {
              padding: 0
            },
            "& .MuiSnackbarContent-root": {
              backgroundColor: '#ffd7d4',
              color: '#bc2142',
              border: '0.06rem solid #bc2142',
              borderRadius: '0.25rem',
              boxShadow: 'none',
              width: '40rem',
              marginTop: '14.2rem',
              padding: 0,
              fontSize: '.9.5rem',
              fontWeight: '400',
              lineHeight: '1.4375rem',
              align: 'center',
            },
            "& .MuiSnackbarContent-message": {
              padding: 0
            }
          }
        }
      ]
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: colors.riptide[450]
          },
          "&:focus.Mui-selected": {
            backgroundColor: colors.riptide[450]
          },
          "&:focus": {
            backgroundColor: colors.riptide[450]
          },
          "&:hover": {
            backgroundColor: colors.riptide[460]
          },
          "&.Mui-selected:hover": {
            backgroundColor: colors.riptide[460]
          }
        }
      },
      variants: [
        {
          props: { variant: "ellipsis-menu" },
          style: {
            fontWeight: "bold",
            fontSize: "11px",
            lineHeight: "13px",
            color: colors.navy[450],
            height: "21px",
            display: "grid",
            justifyContent: "start",
            padding: "5px 10px",
            textDecoration: "none",
            ":hover": {
              backgroundColor: colors.riptide[450]
            },
          }
        }
      ]
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          "& .MuiAutocomplete-option:hover": {
            backgroundColor: colors.riptide[460]
          },
          "& .MuiAutocomplete-option[aria-selected='true']": {
            backgroundColor: colors.riptide[450]
          },
          "& .MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
            backgroundColor: colors.riptide[450]
          },
          "& .MuiAutocomplete-option.Mui-focused:hover": {
            backgroundColor: colors.riptide[460]
          },
          "& .MuiAutocomplete-option[aria-selected='true']:hover": {
            backgroundColor: colors.riptide[460]
          },
        },
        root: {
          "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
            padding: "4px"
          }
        }
      }
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: colors.riptide[450]
          },
        }
      },
      variants: [
        {
          props: { variant: "form-designer-list-item" },
          style: {
            border: '1px solid rgba(151, 151, 151, 0.5)',
            borderRadius: '4px',
            marginBottom: '16px',
            backgroundColor: 'white',
            ':hover': {
              backgroundColor: 'white',
            },
            '&.Mui-disabled': {
              backgroundColor: '#E8E8E8',
              opacity: 1,
              border: 0,
            },
          }
        }
      ]

    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      variants: [
        {
          props: { variant: "close-drawer-button" },
          style: {
            position: 'absolute',
            backgroundColor: colors.gray[25],
            ':hover': {
              borderColor: 'inherit',
              backgroundColor: colors.gray[25],
              boxShadow: '0px 2px 4px rgba(0,0,0,0.5)'
            },
          }
        }
      ]
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.gray[25],
          },
          '& 	.MuiDataGrid-cell': {
            whiteSpace: 'normal',
          },
          '& .MuiDataGrid-columnHeader': {
            fontFamily: fonts.main,
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '11px',
            lineHeight: '13px',
            color: colors.navy[400],
            backgroundColor: colors.gray[25]
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 1000
          },
          '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden',
          },
          '& div.MuiDataGrid-row:hover, & div.MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: colors.riptide[40],
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'initial',
          },
          '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell': {
            whiteSpace: 'normal'
          },
          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
            display: "none"
          },
          '& .Mui-checked': {
            color: colors.navy[600]
          },
          '& .MuiDataGrid-overlay .MuiCircularProgress-root': {
            color: colors.riptide[400]
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          [`& .${tablePaginationClasses.spacer}`]: {
            display: "none"
          },
          [`& .${tablePaginationClasses.toolbar}`]: {
            justifyContent: "center"
          },
          [`& .${tablePaginationClasses.displayedRows}`]: {
            display: "none"
          },
          [`& .${tablePaginationClasses.selectIcon}`]: {
            color: colors.riptide[475]
          },
          [`& .${tablePaginationClasses.select}`]: {
            fontFamily: fonts.main,
            fontStyle: 'normal',
            fontWeight: 1000,
            fontSize: '12px',
            lineHeight: '17px',
            textAlign: 'center',
            color: colors.black,
            marginRight: '0px'
          },
          [`& .${inputBaseClasses.root}`]: {
            marginRight: '0px'
          }
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          [`& .${tableFooterClasses.root}`]: {
            borderBottom: 'none'
          },
          [`& .${tableFooterClasses.root}`]: {
            marginTop: '30px'
          }
        }
      }

    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          color: colors.riptide[450],
          zIndex: 10001
        }
      }
    },
    MuiDialog: {
      defaultProps: {
        disableEscapeKeyDown: true,
        maxWidth: "lg"
      },
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            backgroundColor: colors.gray[30],
            borderRadius: 0,
          },
        }
      },
      variants: [
        {
          props: { variant: "cla-dialog" },
          style: {
            '& .MuiDialog-paper': {
              borderRadius: "5px",
            },
          }
        },
        {
          props: { variant: "cla-comment-box" },
          style: {
            '& .MuiDialog-paper': {
              borderRadius: 0,
              width: '550px',
              height: '700px'
            },
          }
        },
        {
          props: { variant: "cla-confirm" },
          style: {
            '& .MuiDialog-paper': {
              borderRadius: 1,
              maxWidth: '550px',
              height: '300px'
            },
          }
        },
        {
          props: { variant: "cla-dialog-form" },
          style: {
            '& .MuiDialog-paper': {
              borderRadius: 1,
              width: '569px'
            },
          }
        },
        {
          props: { variant: "view-form" },
          style: {
            '& .MuiDialog-paper': {
              padding: "8px",
              paddingBottom: "24px",
              borderRadius: "0px",
              backgroundColor: colors.gray[25],
              overflow: "visible",
              marginTop: "128px",
              marginBottom: "128px"
            },
          }
        },
        {
          props: { variant: "cla-dialog-tailoring-procedure" },
          style: {
            '& .MuiDialog-paper': {
              padding: "8px",
              paddingBottom: "24px",
              borderRadius: "0px",
              backgroundColor: colors.gray[25],
              overflow: "visible",
              marginTop: "128px",
              marginBottom: "128px",
            },
          }
        },
        {
          props: { variant: "diagnostics-modal" },
          style: {
            '& .MuiDialog-paper': {
              width: '600px',
              height: '545px',
              padding: '30px 35px 0px 35px',
              borderRadius: "3px",
              backgroundColor: colors.gray[25],
            },
          }
        },
        {
          props: { variant: "copyanswer-modal" },
          style: {
            '& .MuiDialog-paper': {
              width: '920px',
              height: '420px',
              padding: '30px 35px 0px 35px',
              borderRadius: "3px",
              backgroundColor: colors.gray[25],
            },
          }
        }
      ]
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&.MuiDialogTitle-root': {
            fontFamily: fonts.header,
            fontSize: '22px',
            color: colors.navy[400],
            padding: "0px",
          },
        }
      },
      variants: [
        {
          props: { variant: "cla-dialog" },
          style: {
            '&.MuiDialogTitle-root': {
              padding: '32px 32px 0px 32px',
              fontWeight: 700,
            },
          }
        },
        {
          props: { variant: "cla-comment-box" },
          style: {
            '&.MuiDialogTitle-root': {
              fontFamily: fonts.main,
              fontSize: '14px',
              padding: '40px 0px 0px 50px',
              fontWeight: 900
            },
          }
        },
        {
          props: { variant: "cla-confirm" },
          style: {
            '&.MuiDialogTitle-root': {
              padding: '55px 0px 0px 30px',
              fontWeight: 900
            },
          }
        },
        {
          props: { variant: "cla-dialog-form" },
          style: {
            '&.MuiDialogTitle-root': {
              padding: '40px 0px 0px 30px',
              fontWeight: 900
            },
          }
        },
        {
          props: { variant: "cla-dialog-tailoring-procedure" },
          style: {
            '&.MuiDialogTitle-root': {
              padding: '15px 0px 0px 30px',
              fontWeight: 900,
              margin: '2rem 2rem 1rem 2rem',
              padding: 0
            },
          }
        },
        {
          props: { variant: "diagnostics-modal" },
          style: {
            '&.MuiDialogTitle-root': {
              fontSize: '21px',
              padding: '15px 0',
              fontWeight: 700,
              wordBreak: 'break-word',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              display: 'inline-block',
            },
          }
        },
        {
          props: { variant: "copyanswer-modal" },
          style: {
            '&.MuiDialogTitle-root': {
              fontSize: '21px',
              padding: '15px 0',
              fontWeight: 700,
              wordBreak: 'break-word',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              display: 'inline-block',
            },
          }
        }
      ]
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&.MuiDialogContent-root': {
            padding: "32px",
          },
        }
      },
      variants: [
        {
          props: { variant: "cla-dialog" },
          style: {
            '&.MuiDialogContent-root': {
              padding: "32px",
              fontWeight: 500,
            },
          }
        },
        {
          props: { variant: "cla-comment-box" },
          style: {
            '&.MuiDialogContent-root': {
              padding: '0px 30px 0px 30px',
            },
          }
        },
        {
          props: { variant: "cla-confirm" },
          style: {
            '&.MuiDialogContent-root': {
              padding: '10px 30px 0px 30px',
            },
          }
        },
        {
          props: { variant: "cla-dialog-form" },
          style: {
            '&.MuiDialogContent-root': {
              width: '100%',
              padding: '30px 30px 0px 30px',
              fontSize: '14px'
            },
          }
        },
        {
          props: { variant: "cla-dialog-tailoring-procedure" },
          style: {
            '&.MuiDialogContent-root': {
              padding: '-1px',
              fontWeight: 900,
              display: 'flex',
              fontSize: '0.5rem',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: '2rem'
            },
          }
        },
        {
          props: { variant: "diagnostics-modal" },
          style: {
            '&.MuiDialogContent-root': {
              width: '100%',
              height: '320px',
              padding: '0',
              paddingRight: '9px',
              marginTop: '15px',
              marginBottom: '50px',
              overflowX: 'auto',
              display: 'flex',
              flexDirection: 'column'
            },
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: '2px',
              backgroundColor: 'white',
              boxShadow: 'unset'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#D2D2D2',
            }
          }
        },
        {
          props: { variant: "copyanswer-modal" },
          style: {
            '&.MuiDialogContent-root': {
              width: '100%',
              height: '320px',
              padding: '0',
              paddingRight: '9px',
              marginTop: '15px',
              marginBottom: '50px',
              overflowX: 'auto',
              display: 'flex',
              flexDirection: 'column'
            },
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: '2px',
              backgroundColor: 'white',
              boxShadow: 'unset'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#D2D2D2',
            }
          }
        }
      ]
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          padding: '32px',
          paddingTop: "0px",
          gap: "16px",
        }
      }
    },
    MuiBadge: {
      variants: [
        {
          props: { variant: "comment-count" },
          style: {
            "& .MuiBadge-badge": {
              backgroundColor: colors.red[200],
              color: colors.white
            }
          }
        },
      ]
    },
    MuiTableRow: {
      variants: [
        {
          props: { variant: "fs-line-table-cell" },
          style: {
            "& .MuiTableCell-root": {
              backgroundColor: colors.gray[30],
              fontWeight: 'bold'
            }
          }
        },
      ]

    }
  },
});
