import formApi from '@utilities/claApiForm';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

const getRiskCharacteristics = async (MethodologyVersionId) => {
    try {
        const { data } = await formApi.get(`/riskcharacteristics/${MethodologyVersionId}`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

const upsertRiskCharacteristics = async (methodologyVersionId, body) => {
    try {
        const response = await formApi.put(`/methodology/${methodologyVersionId}/lists/risk-characteristics`, body);
        return { status: 200, data: response.data };
    } catch (error) {
        const { response } = error;
        return { status: 400, message: response?.data?.message ?? error };
    }
}

export const useRiskCharacteristics = (methodologyVersionId) => {
    return useQuery(
        ["riskCharacteristics", methodologyVersionId],
        async () => {
            const { data } = await getRiskCharacteristics(methodologyVersionId)
            return data
        },
        {
            enabled: !!methodologyVersionId
        }
    )
}

export const PROJECT_FORM_RISK_CHARACTERISTICS_QUERY_FACTORY = {
    all: ["all"],
    projectForm: (projectFormId) => [...PROJECT_FORM_RISK_CHARACTERISTICS_QUERY_FACTORY.all, "projectForm", projectFormId],
    projectFormRisks: (projectFormId) => [...PROJECT_FORM_RISK_CHARACTERISTICS_QUERY_FACTORY.all, "projectFormRisks", projectFormId],
    projectScopeRisks: (projectScopeId) => [...PROJECT_FORM_RISK_CHARACTERISTICS_QUERY_FACTORY.all, "projectScopeRisks", projectScopeId],
}

const getProjectScopeRiskCharacteristics = async (projectScopeId, projectId) => {
    try {
        const url = `/projectscoperiskcharacteristics/projectscope/${projectScopeId}/${projectId}`;
        const response = await formApi.get(url);
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
};

export const useProjectScopeRiskCharacteristics = (projectScopeId, projectId) => {
    return useQuery(
        PROJECT_FORM_RISK_CHARACTERISTICS_QUERY_FACTORY.projectScopeRisks(projectScopeId),
        async () => {
            const { data } = await formApi.get(`/projectscoperiskcharacteristics/projectscope/${projectScopeId}/${projectId}`)
            return data
        },
        {
            enabled: !!projectScopeId
        }
    )
}

export const useProjectFormRiskCharacteristics = (projectFormId) => {
    return useQuery(
        PROJECT_FORM_RISK_CHARACTERISTICS_QUERY_FACTORY.projectForm(projectFormId),
        async () => {
            const { data } = await formApi.get(`/projectscoperiskcharacteristics/projectform/${projectFormId}`)
            return data
        },
        {
            enabled: !!projectFormId
        }
    )
}

export const useProjectScopeRiskCharacteristicMutation = (projectId) => {
    const queryClient = useQueryClient()
    return useMutation(
        (formData) => {
            return formApi.post(`/projectscoperiskcharacteristics/${projectId}`, formData)
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(PROJECT_FORM_RISK_CHARACTERISTICS_QUERY_FACTORY.all)
            }
        }
    )
}

export const useDeleteProjectScopeRiskCharacteristicMutation = (projectId) => {
    const queryClient = useQueryClient()
    return useMutation(
        (formData) => {
            return formApi.delete(`/projectscoperiskcharacteristics/${projectId}`, { data: formData })
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(PROJECT_FORM_RISK_CHARACTERISTICS_QUERY_FACTORY.all)
            }
        }
    )
}

const riskCharacteristicServices = {
    useRiskCharacteristics,
    getRiskCharacteristics,
    getProjectScopeRiskCharacteristics,
    upsertRiskCharacteristics
}

export default riskCharacteristicServices;