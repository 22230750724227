import { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
  Box
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import {
  fetchProjectRisksAuditArea,
  getEnabledInherentRiskAssessments,
  getPreliminaryAuditScopingAnswerByProjectFormId,
  getEnabledControlAssessments
} from "@services/riskassessment";
import formServices from '@services/forms/forms';
import { getProjectRiskByAuditAreaAndProjectFormId } from "@services/projectRisk.js";
import CLAAuditAreaCollapsible from './CLAAuditAreaCollapsible';
import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import styles from './CLAAccountAssertionLevelRisk.module.css';
import { boxColumnStyles, boxHeaderColStyles, boxHeaderRowAuditStyles } from './muiStyles';
import { useParams } from 'react-router-dom';
import { useProjectFormInstanceProvider } from '@providers';
import { useRiskAssessment } from '../../hooks/useRiskAssessment';

const CLAAccountAssertionLevelRisk = (props) => {
  const { projectFormId, disabled, isIdle } = props;
  const { ACCOUNT_ASSERTION_LEVEL_RISK: CONSTANT_TEXT } = RISK_ASSESSMENT_FORM_INSTANCE.EN;
  const [scotabds, setSCOTABDs] = useState([]);
  const [inherentRiskOptions, setInherentRiskOptions] = useState([]);
  const [controlRiskOptions, setControlRiskOptions] = useState([]);
  const [financialRisks, setFinancialRisks] = useState([]);
  const [isInternalControlEffectiveness, setIsInternalControlEffectiveness] = useState(false);
  const [focusedId, setFocusedId] = useState(null);
  const { projectId } = useParams();

  const {
    auditAreaScotabdRisk,
    actions: {
      riskAssessmentIdentifyModifiedAnswerAfterSignOff
    },
    setStates: {
      setAuditAreaScotabdRisk
    }
  } = useProjectFormInstanceProvider();

  const { auditAreaWithComment, setAuditAreaWithComment } = useRiskAssessment();

  const { data: projectRisksAuditArea } = useQuery(['ProjectRisksAuditArea', projectFormId], () => {
    if (!projectFormId) return Promise.reject(false);
    return fetchProjectRisksAuditArea(projectFormId, projectId).then(response => {
      return response.sort(
        (a, b) =>
          a.projectScopeAuditAreaId > b.projectScopeAuditAreaId
      )
    });
  }, { refetchOnWindowFocus: false });

  const { data: enabledInherentRiskAssessments } = useQuery(['InherentRiskOptions', projectFormId], () => {
    return getEnabledInherentRiskAssessments();
  }, { refetchOnWindowFocus: false });

  const { data: enabledControlRiskAssessments } = useQuery(['ControlRiskOptions', projectFormId], () => {
    return getEnabledControlAssessments();
  }, { refetchOnWindowFocus: false });

  const { data: preliminaryAuditScopingAnswer } = useQuery(['PreliminaryAuditScopingAnswer', projectFormId], () => {
    if (!projectFormId) return Promise.reject(false);
    return getPreliminaryAuditScopingAnswerByProjectFormId(projectFormId, projectId);
  }, { refetchOnWindowFocus: false });

  const { data: scotabdList } = useQuery(['ProjectRisksAuditAreaSCOTABDList', auditAreaWithComment?.map(auditArea => auditArea.projectScopeAuditAreaId)], () => {
    if (!auditAreaWithComment?.length) return Promise.reject(false);
    const ids = auditAreaWithComment.map(auditArea => auditArea.projectScopeAuditAreaId);
    return formServices.getProjectScopeAuditAreaSCOTABDListbyAuditAreaIds(JSON.stringify(ids), projectId);
  }, { refetchOnWindowFocus: false });

  const { data: projectRisks } = useQuery(['ProjectRisksAuditAreaList', projectFormId], () => {
    if (!projectFormId) return Promise.reject(false);
    return getProjectRiskByAuditAreaAndProjectFormId(projectFormId, projectId);
  }, { refetchOnWindowFocus: false });

  useEffect(() => {
    if (projectRisksAuditArea) {
      setAuditAreaWithComment(projectRisksAuditArea);
      riskAssessmentIdentifyModifiedAnswerAfterSignOff()
    }
  }, [projectRisksAuditArea]);

  useEffect(() => {
    if (scotabdList) {
      setSCOTABDs(scotabdList);
      setAuditAreaScotabdRisk(scotabdList.map(scotabd => scotabd.projectScopeAuditAreaSCOTABDId));
    }
  }, [scotabdList]);

  useEffect(() => {
    if (auditAreaScotabdRisk.length) {
      riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
    }
  }, [auditAreaScotabdRisk])

  useEffect(() => {
    if (enabledInherentRiskAssessments)
      setInherentRiskOptions(enabledInherentRiskAssessments);
  }, [enabledInherentRiskAssessments]);

  useEffect(() => {
    if (preliminaryAuditScopingAnswer)
      setIsInternalControlEffectiveness(!preliminaryAuditScopingAnswer?.answer ? true : preliminaryAuditScopingAnswer?.answer?.toLowerCase() === 'yes');
  }, [preliminaryAuditScopingAnswer]);
  useEffect(() => {
    if (enabledControlRiskAssessments)
      setControlRiskOptions(enabledControlRiskAssessments);
  }, [enabledControlRiskAssessments]);

  useEffect(() => {
    if (!projectRisks) return;
    const result = projectRisks?.data ?? [];
    const persuasiveRisks = result.filter(projectRisk => { return projectRisk.IsPervasive })
    setFinancialRisks(persuasiveRisks);
  }, [projectRisks]);

  return (
    <div className={styles['auditarea-wrapper']}>
      <Box sx={{ ...boxColumnStyles, ml: '20px' }}>
        <Box sx={boxHeaderRowAuditStyles}>
          <Box sx={{ ...boxHeaderColStyles, width: '40%' }} data-test="audit-area-header">{CONSTANT_TEXT.AUDIT_AREAS}</Box>
          <Box sx={{ ...boxHeaderColStyles, width: '60%' }} data-test="comment-header">{CONSTANT_TEXT.COMMENTS}</Box>
        </Box>
        <Box sx={boxColumnStyles} data-test="auditarea-container">
          {
            auditAreaWithComment?.map((auditArea, index) => {
              const scotabdList = scotabds?.filter(scotabd => scotabd.projectScopeAuditAreaId === auditArea.projectScopeAuditAreaId);
              return <CLAAuditAreaCollapsible
                key={index}
                index={index}
                scotabdList={scotabdList}
                inherentRiskOptions={inherentRiskOptions}
                controlRiskOptions={controlRiskOptions}
                isInternalControlEffectiveness={isInternalControlEffectiveness}
                auditArea={auditArea}
                financialRisks={financialRisks}
                disabled={disabled}
                focusedId={focusedId}
                setFocusedId={setFocusedId}
              />
            })
          }
        </Box>
      </Box>
    </div>
  )
}
CLAAccountAssertionLevelRisk.propTypes = {
  projectFormId: PropTypes.number,
  disabled: PropTypes.bool,
}
export default CLAAccountAssertionLevelRisk;