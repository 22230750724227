import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useNotificationsStore = create(
     persist(
        (set) => ({
            notifications: [],
            updateNotifications: (notifications) => set({ notifications }),
            clearNotifications: () => set({ notifications: [] }),
        }),
        {
            name: 'claNotifications',
        }
    ),
);
