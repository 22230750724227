export const INHERENT_RISK_FACTORS = {
    EN: {
        ERROR_MESSAGE: "A unique name is required.",
        ADD_ANOTHER_AUDIT_AREA: "Add Another Audit Area",
        INHERENT_RISK_FACTORS_LABEL: "Inherent Risk Factors",
        RISK_FACTOR_ASSOCIATION_LABEL: "Associate with Risk Assessment Summary",
        RISK_FACTOR: "Risk Factor",
        RISK_FACTOR_WORKPAPER_REFERENCE_LABEL: "Workpaper Reference",
        RISK_FACTOR_WORKPAPER_REFERENCE_PLACEHOLDER: "Workpaper Reference here",
        RISK_FACTOR_NAME_LABEL: "Risk Factor Name",
        RISK_FACTOR_NAME_PLACEHOLDER: "Risk factor name here..",
        RISK_OF_MATERIAL_MISSTATEMENTS_LABEL: "Events or conditions that indicated the existence of risks of material misstatement at the assertion level",
        INHERENT_RISK_AFFECT_ACCOUNTING_ESTIMATE_LABEL: "Does the inherent risk factor affect an accounting estimate?",
        INHERENT_RISK_AFFECT_SELECTION_AND_APPLICATION_METHOD_LABEL: "Indicate the degree to which the inherent risk factor affects the selection and application of the method, assumptions and data used to make the accounting estimate",
        INHERENT_RISK_AFFECT_POINT_ESTIMATE: "Indicate the degree to which the inherent risk factor affects the selection of management's point estimate",
        SECTION: {
            TITLE: 'Risk Factors Identified',
            HEADER: 'Inherent Risk Factors',
        },
        SCOTABDS_TRANSACTION: "Classes of Transactions",
        SCOTABDS_ACCOUNT_BALAMCE: "Associated Account Balances",
    }
}