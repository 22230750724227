import { parse, eval as evaluateExpression } from 'expression-eval';

/**
 * 
 * @param formula '(x+y) > 0 ? 1 : false
 * @param variables  {x: 1, y: 2}
 * @returns 1
 */

export const evaluateFormula = (formula, variables = {}) => {
    try {
        let formulaExpression = formula; 
        

        const ast = parse(formulaExpression, variables);
        return evaluateExpression(ast, variables);
    } catch (err) {
        throw 'Invalid Formula Format'
    }
}