import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import React, { forwardRef } from "react";

const CLATextField = React.memo(forwardRef(({
        id,
        name,
        label,
        value,
        placeholder,
        error,
        helperText,
        inputLableShrink,
        onBlur,
        onChange,
        hoveredBounds,
        disabled,
        onFocus,
        maxLength,
        formHelperTextProps,
        multiline,
    }, 
     ref) => {
    return (
        <div>
            {inputLableShrink ?
                <TextField
                    ref={ref}
                    autoComplete="off"
                    id={id}
                    name={name}
                    fullWidth
                    margin="normal"
                    label={label}
                    error={error}
                    helperText={helperText}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                    inputProps={{ maxLength: maxLength ?? 256 }}
                    InputLabelProps={{ shrink: inputLableShrink }}
                    disabled={disabled}
                    FormHelperTextProps={formHelperTextProps}
                    multiline={multiline}
                />
                :
                <TextField
                    ref={ref}
                    fullWidth
                    margin="normal"
                    className={[
                        'adjustable-field',
                        !!hoveredBounds ? `drop-${hoveredBounds}` : ''
                    ].join(' ')}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={error}
                    FormHelperTextProps={formHelperTextProps}
                    helperText={helperText}
                    label={label}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                    inputProps={{ maxLength: 1000 }}
                    multiline={multiline}
                />
            }
        </div>
    )
}));

CLATextField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    inputLableShrink: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    hoveredBounds: PropTypes.func,
    formHelperTextProps: PropTypes.object
}

CLATextField.defaultProps = {
    disabled: false,
    multiline: false,
}

export default CLATextField;