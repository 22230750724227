import { useState, useEffect } from 'react';
import { useDebounce } from 'react-use';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Checkbox, Grid } from '@mui/material';
import { ButtonComponent } from '@components/AuditAreas/ButtonComponent';
import * as Constants from '@constants/index';
import { CLAComboBox, CLACurrencyField, CLANumberField } from '@ais/forms';
import projectServices from '@services/project';
import financialStatementService from '@services/forms/financialstatements';
import generalLedgerService from '@services/generalledger';

export const FinancialStatementLineTable = (props) => {
    const { EN, BUTTON } = Constants.TRIAL_BALANCE;
    const {
        trialBalances,
        handleSelectedFinancialStatements,
        defaultFinancialStatement,
        financialStatementsInOtherAuditArea,
        financialStatements: _financialStatements
    } = props;
    const { projectId } = useParams();

    const initialCustomFS = _financialStatements?.FinancialStatementLineItems?.filter((data) => data.isUserEnteredCategory);

    const [financialStatements, setFinancialStatements] = useState([]);
    const [otherAuditAreaFinancialStatements, setOtherAuditAreaFinancialStatements] = useState([]);
    const [selectedFinancialStatement, setSelectedFinancialStatement] = useState([]);
    const [customFSInputChange, setCustomFSInputChange] = useState(null);
    const [selectedCustomFSLines, setSelectedCustomFSLines] = useState(initialCustomFS ?? []);
    const [customFSLines, setCustomFSLines] = useState(initialCustomFS ?? []);
    
    const getLedgerTrialbalanceCategories = async () => {
        const response = await generalLedgerService.getLedgerTrialbalanceCategories(projectId, {
            TrialBalanceIds: trialBalances?.map((data) => data.value),
        });
        const data = response?.data ?? [];
        setFinancialStatements(
            data.map((data, index) => ({
                financialStatementCategoryId: index,
                projectScopeAuditAreaCategoryId: null,
                financialStatementCategoryName: data.CategoryName,
                balance: data?.TransactionBalance ?? null,
                transactionCount: data?.TransactionCount ?? null,
                isDisabled:
                    financialStatementsInOtherAuditArea?.findIndex(
                        (category) => category?.financialStatementCategoryName === data.CategoryName && !category.isUserEnteredCategory
                    ) >= 0,
            }))
        );
    };

    const getFinancialStatementsByMethodologyVersionId = async (methodologyVersionId) => {
        const _financialStatements = await financialStatementService.getFinancialStatementsByMethodologyVersionId(
            methodologyVersionId, true
        );
        const sortedFinancialStatement = _financialStatements?.data?.sort((a, b) => {
            return a.DisplayOrder - b.DisplayOrder;
        });

        setFinancialStatements(
            sortedFinancialStatement.map((data) => ({
                financialStatementCategoryId: data.FinancialStatementCategoryId,
                projectScopeAuditAreaCategoryId: null,
                financialStatementCategoryName: data.FinancialStatementCategoryName,
                isDisabled:
                    financialStatementsInOtherAuditArea?.findIndex(
                        (category) => category?.financialStatementCategoryName === data.FinancialStatementCategoryName && !category.isUserEnteredCategory
                    ) >= 0,
            }))
        );
    };

    useEffect(async () => {
        const projectData = await projectServices.getProjectSimple(projectId);
        const data = projectData?.data ?? null;
        if (data) {
            const { MethodologyVersionId: methodologyVersionId, CLCProjectId: clcProjectId } = data;
            if (!!trialBalances?.length) await getLedgerTrialbalanceCategories();
            else getFinancialStatementsByMethodologyVersionId(methodologyVersionId);
        }
    }, []);

    useEffect(async () => {
        if (!!financialStatements?.length) {
            let selectedFS = financialStatements.map((data) => {
                const selected = defaultFinancialStatement?.find((fs) => data.financialStatementCategoryName === fs.financialStatementCategoryName && !fs.isUserEnteredCategory)
                if (selected) {
                    return {
                        ...data,
                        projectScopeAuditAreaCategoryId: selected.projectScopeAuditAreaCategoryId
                    };
                }
            });
            selectedFS = selectedFS.filter(data => data)
            setSelectedFinancialStatement(selectedFS);

            const selectedCustom = defaultFinancialStatement?.filter((data) => data.isUserEnteredCategory);
            setCustomFSLines(selectedCustom);
            setSelectedCustomFSLines(selectedCustom);
        }
    }, [defaultFinancialStatement, financialStatements]);

    useEffect(() => {
        if(selectedFinancialStatement && selectedCustomFSLines)
            handleSelectedFinancialStatements([...selectedFinancialStatement, ...selectedCustomFSLines]);
    }, [selectedFinancialStatement, selectedCustomFSLines]);

    useEffect(() => {
        if (JSON.stringify(financialStatementsInOtherAuditArea) != JSON.stringify(otherAuditAreaFinancialStatements)) {
            setOtherAuditAreaFinancialStatements(financialStatementsInOtherAuditArea);
            setFinancialStatements((prev) => (
                prev.map((data) => (
                    {
                        ...data,
                        isDisabled:
                            financialStatementsInOtherAuditArea?.findIndex(
                                (category) => category.financialStatementCategoryName === data.financialStatementCategoryName && !data.isUserEnteredCategory
                            ) >= 0,
                    }
                ))
            ));
        }
    }, [financialStatementsInOtherAuditArea]);

    const handleCheckboxChange = (checked, data, isCustom = false) => {
        const { FinancialStatementLineItems: financialStatementLineItems } = _financialStatements;
        const selectedFs = financialStatementLineItems.find(fsItem => (fsItem.financialStatementCategoryName === data.financialStatementCategoryName) && !fsItem.isUserEnteredCategory);
        if (!checked) {
            if (isCustom) {
                setSelectedCustomFSLines((prevState) =>
                    prevState.filter((p) => p.projectScopeAuditAreaCategoryId !== data.projectScopeAuditAreaCategoryId)
                );
            }
            else
                setSelectedFinancialStatement((prevState) =>
                    prevState.filter((p) => p.financialStatementCategoryName !== data.financialStatementCategoryName)
                );
        } else {
            if (isCustom)
                setSelectedCustomFSLines((prevState) => ([...prevState, data]));
            else {
                if (selectedFs) data = {
                    ...data,
                    ...selectedFs
                };
                setSelectedFinancialStatement((prevState) => ([...prevState, data]));
            }
        }
    };

    const handleAddCustomFS = () => {
        const newCustom = {
            projectScopeAuditAreaCategoryId: uuidv4(),
            financialStatementCategoryName: null,
            balance: null,
            transactionCount: 0,
            isUserEnteredCategory: true
        }
        setCustomFSLines(prev => (
            [...prev, newCustom]
        ));
        setSelectedCustomFSLines(prev => (
            [...prev, newCustom]
        ));
    }

    const handleUpdateCustomFS = (index, propName, value, shouldHandleSelectedCustomFSChange, currentFS) => {
        setCustomFSLines(prev => {
            const selected = prev[index];
            selected[propName] = value;
            return prev;
        });

        if (shouldHandleSelectedCustomFSChange) {
            setCustomFSInputChange({ value, currentFS });
        }
    };

    // Handle financialStatementCategoryName change for selectedCustomFSLines state with debounce
    useDebounce(
        () => {
            if (!!customFSInputChange) {
                const { value, currentFS } = customFSInputChange;

                setSelectedCustomFSLines((prev) => {
                    const foundIndex = prev.findIndex(
                        (fs) => fs.projectScopeAuditAreaCategoryId === currentFS.projectScopeAuditAreaCategoryId
                    );
                    if (foundIndex > -1) {
                        const selected = prev[foundIndex];
                        selected.financialStatementCategoryName = value;
                        return [...prev];
                    }

                    return [...prev];
                });
            }
        },
        500,
        [customFSInputChange]
    );
    
    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow variant="fs-line-table-cell">
                            <TableCell width="1%"></TableCell>
                            <TableCell width="33%">
                                {EN.CATEGORY}
                            </TableCell>
                            <TableCell width="33%">
                                {EN.BALANCE}
                            </TableCell>
                            <TableCell width="33%">
                                {EN.NUMBER_OF_TRANSACTION}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {financialStatements?.map((data, index) => {
                            return (
                                <TableRow key={index} >
                                    <TableCell width="1%">
                                        <Checkbox
                                            disabled={data.isDisabled}
                                            checked={
                                                !!selectedFinancialStatement.find(
                                                    (fs) =>
                                                        fs.financialStatementCategoryName === data.financialStatementCategoryName && !fs.isUserEnteredCategory && !data.isDisabled
                                                )
                                            }
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                handleCheckboxChange(checked, data);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell width="33%">{data.financialStatementCategoryName}</TableCell>
                                    <TableCell width="33%">
                                        {
                                            <CLACurrencyField
                                                useAsDisplay
                                                placeholder="---"
                                                allowDecimalPadding
                                                emptyInputBehavior="null"
                                                defaultValue={data?.balance}
                                            />
                                        }
                                    </TableCell>
                                    <TableCell width="33%">{data?.transactionCount ?? '0'}</TableCell>
                                </TableRow>
                            );
                        })}
                        {customFSLines?.map((data, index) => {
                            return (
                                <TableRow key={index} disabled={data.isDisabled}>
                                    <TableCell width="1%">
                                        <Checkbox
                                            disabled={data.isDisabled}
                                            checked={
                                                !!selectedCustomFSLines.find(
                                                    (fs) =>
                                                        fs.projectScopeAuditAreaCategoryId === data.projectScopeAuditAreaCategoryId && data.isUserEnteredCategory && !data.isDisabled
                                                )
                                            }
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                handleCheckboxChange(checked, data, true);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell width="33%">
                                        <CLAComboBox
                                            placeholder="Select"
                                            defaultValue={data.financialStatementCategoryName}
                                            options={financialStatements.map(f => f.financialStatementCategoryName)}
                                            allowMultiSelect={false}
                                            maxLength={256}
                                            isDisabled={false}
                                            onChange={(value) => {
                                                handleUpdateCustomFS(index, 'financialStatementCategoryName', value, true, data);
                                            }}
                                            onInputChange={(value) => {
                                                handleUpdateCustomFS(index, 'financialStatementCategoryName', value, true, data);
                                            }}
                                        /></TableCell>
                                    <TableCell width="33%">
                                        <CLACurrencyField
                                            sx={{ padding: 0 }}
                                            placeholder="---"
                                            defaultValue={data?.balance}
                                            allowDecimalPadding
                                            emptyInputBehavior="null"
                                            notched
                                            onChange={(e, value) => handleUpdateCustomFS(index, 'balance', value)}
                                        />
                                    </TableCell>
                                    <TableCell width="33%">
                                        <CLANumberField
                                            sx={{ padding: 0 }}
                                            placeholder="Enter a number (Optional)"
                                            defaultValue={data?.transactionCount ?? 0}
                                            notched
                                            digitGroupSeparator=","
                                            decimalPlaces={0}
                                            disabled={false}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const answer = +value?.replace('$', '').replaceAll(',', '');
                                                handleUpdateCustomFS(index, 'transactionCount', answer);
                                            }}
                                        /></TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container p={3} pb={6}>
                <Grid item xs>
                    <ButtonComponent label={null} buttonName={BUTTON.CUSTOM_FINANCIAL_STATEMENT} onClick={handleAddCustomFS} />
                </Grid>
            </Grid>
        </>
    );
};
