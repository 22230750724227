export const DEFICIENCY_SUMMARY = {
    EN: {
        TITLE: 'Deficiency Summary',
        TABLE_HEADER: 'Summary Of Identified Deficiencies in Internal Control',
        ADD_ANOTHER_DEFICIENCY: 'ADD ANOTHER DEFICIENCY',
        SAVE: "Save",
        CANCEL: "Cancel",
        MODAL: {
            DELETE_MESSAGE: "Are you sure you want to delete this? It will permanently delete the data.",
            DELETE_ERROR_MESSAGE: "This deficiency is included in a combined deficiency and the deficiency cannot be deleted until it is removed from the combined deficiency."
        },
    }
}

export const DEFICIENCY_SUMMARY_COL_TYPES = {
    ACTION: "ACTION",
    NOWRAP_TEXT: "NOWRAP_TEXT",
    LINK: "LINK",
    LIST: "LIST",
    COMMENT: "COMMENT",
    TEXT: "TEXT",
    CHANGEABLE_TEXT: "CHANGEABLE_TEXT"
};

export const DEFICIENCY_SUMMARY_FIELD_TYPES = {
    AUDIT_AREAS: "AuditAreas",
    UNITS: "Units",
};
