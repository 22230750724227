import {
    Typography
} from '@mui/material';

import { CLATooltip } from "@ais/components"

export const activateTooltip = (child, value) => {
    return <CLATooltip title={value} placement="bottom">
        {child}
    </CLATooltip>
}

export const DeficiencyLabelTypography = ({ label }) => {
    return (
        <Typography variant="p" sx={{ fontSize: '0.8rem', fontFamily: 'museo-sans', color: '#2e334e', fontWeight: 600 }}>
            {label}
        </Typography>
    )
}