import { useState, useEffect } from 'react';
import { Grid, IconButton, SvgIcon } from '@mui/material';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';

import {RULES} from '@ais/constants';
import { TRIGGERS } from '@constants/forms';
import { ReactComponent as DeleteIcon } from '@assets/delete_icon.svg';
import {CLADropdown, CLASelect} from '@ais/forms';
import questionsService from '@services/forms/questions';
import { useLoading } from '@hooks/index';
import { SelectQuestion } from '.';

export const CriteriaRow = (props) => {
  const {
    id,
    formName,
    methodologyVersionId,
    formSchemaID,
    formIndustries,
    formCategoryOptions,
    formCategory,
    trigger,
    criteria,
    criterias,
    sections,
    fields,
    industries,
    selectedIndustries,
    auditAreas,
    selectedAuditAreas,
    scotabds,
    selectedScotabds,
    frameworks,
    performanceStandards,
    setCriterias,
    deleteCriteria,
    setSelectedIndustries,
    setSelectedAuditAreas,
    setSelectedScotabds,
    ruleCriteriaTypes
  } = props;

  const setBackDropLoading = useLoading();

  const handleLoader = (value) => {
    setBackDropLoading(value);
  };

  const [properties, setProperties] = useState(
    trigger === TRIGGERS.EDIT_BTN || trigger === TRIGGERS.ELLIPSIS
      ? {
        ...criteria,
        criteriaType: criteria?.hasOwnProperty('criteriaType')
          ? criteria.criteriaType
          : criteria.questionId
            ? RULES.CRITERIA_TYPES.QUESTION
            : '',
        isExternalQuestion: criteria?.hasOwnProperty('isExternalQuestion')
          ? criteria.isExternalQuestion
          : false,
      }
      : {
        id,
        criteriaType: '',
        questionId: '',
        isExternalQuestion: false,
        any: false,
        isEqual: true,
        value: [],
      }
  );
  const [answerFields, setAnswerFields] = useState([]);
  const [criteriaTypes, setCriteriaTypes] = useState([]);
  const [disabledCriteriaTypes, setDisabledCriteriaTypes] = useState([
    RULES.CRITERIA_TYPES.AUDIT_AREA,
    RULES.CRITERIA_TYPES.SCOTABDS,
  ]);
  const [tempSelectedIndustries, setTempSelectedIndustries] = useState({
    [id]: [],
  });
  const [tempSelectedAuditArea, setTempSelectedAuditArea] = useState({
    [id]: [],
  });
  const [tempSelectedScotabds, setTempSelectedScotabds] = useState({
    [id]: [],
  });
  const [selectedQuestion, setSelectedQuestion] = useState({
    label: '',
    value: '',
  });
  const [selected, setSelected] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handlePropertyChange = (propertyName, value) => {
    const updated = criterias.map((criteria) =>
      criteria.id === id
        ? {
          ...criteria,
          criteriaTypeId: ruleCriteriaTypes.find(rule=> rule.value === criteria.criteriaType)?.id,
          value:
            propertyName === 'questionId' || propertyName === 'criteriaType'
              ? []
              : criteria.value,
          questionId:
            propertyName === 'criteriaType' ? '' : criteria.questionId,
          any:
            propertyName === 'value'
              ? value?.includes(RULES.ANY_RESPONSE)
                ? true
                : false
              : false,
          [propertyName]: value,
        }
        : criteria
    );
    setProperties((prevState) => {
      const copy = { ...prevState, [propertyName]: value };
      if (propertyName === 'questionId' || propertyName === 'criteriaType')
        copy.value = [];
      if (propertyName === 'criteriaType') copy.questionId = '';
      return copy;
    });
    setCriterias([...updated]);
  };

  const fetchExternalQuestion = async () => {
    try {
      handleLoader(true);
      setEditMode(true);
      const { data } = await questionsService.getQuestionById(
        properties.questionId, methodologyVersionId
      );
      if (data) {
        setSelectedQuestion({ label: data.label, value: properties.questionId });
        const copy = [...data.options];
        copy.push(RULES.ANY_RESPONSE);
        setAnswerFields(copy);
      }
      setSelected(true);
    } catch (error) {
      toast.error(error.toString());
    } finally {
      handleLoader(false);
    }
  };

  const updateExternalQuestionsAnswerFields = async () => {
    handleLoader(true);
    const questionId = properties.questionId ?? selectedQuestion?.value;
    const { data } = await questionsService.getQuestionById(questionId, methodologyVersionId);
    if (data) {
      const copy = [...data.options];
      copy.push(RULES.ANY_RESPONSE);
      setAnswerFields(copy);
      handleLoader(false);
    }
  };

  useEffect(()=>{
    setCriteriaTypes(prev => {
      const newRuleCriteriaTypes = ruleCriteriaTypes?.map(rule=> ({label: rule.label, value: rule.value}))?? [];

      if (prev.length === 0)
        return newRuleCriteriaTypes;

      return [...newRuleCriteriaTypes, ...prev];
    })
  },[ruleCriteriaTypes]);

  useUpdateEffect(
    function watchCriteriaTypeForChanges() {
      if (properties.criteriaType !== RULES.CRITERIA_TYPES.QUESTION) {
        setVisible(false);
      }
      if (properties.criteriaType !== RULES.CRITERIA_TYPES.INDUSTRY) {
        setSelectedIndustries((prevState) => {
          const copy = { ...prevState };
          delete copy[id];
          return copy;
        });
      }
      if (properties.criteriaType !== RULES.CRITERIA_TYPES.AUDIT_AREA) {
        setSelectedAuditAreas((prevState) => {
          const copy = { ...prevState };
          delete copy[id];
          return copy;
        });
      }
    },
    [id, properties.criteriaType]
  );

  useEffect(
    function setAnswerFieldsBasedOnCriteriaType() {
      if (properties.criteriaType === RULES.CRITERIA_TYPES.INDUSTRY) {
        setAnswerFields(industries);
      } else if (properties.criteriaType === RULES.CRITERIA_TYPES.AUDIT_AREA) {
        setAnswerFields(auditAreas);
      } else if (properties.criteriaType === RULES.CRITERIA_TYPES.SCOTABDS) {
        setAnswerFields(scotabds);
      } else if (
        properties.criteriaType ===
        RULES.CRITERIA_TYPES.REPORTING_FRAMEWORK
      ) {
        setAnswerFields(frameworks);
      } else if (
        properties.criteriaType === RULES.CRITERIA_TYPES.PERFORMANCE_STANDARDS
      ) {
        setAnswerFields(performanceStandards);
      }
    },
    [
      properties.criteriaType,
      industries,
      auditAreas,
      scotabds,
      frameworks,
      performanceStandards,
    ]
  );

  useEffect(
    function watchIndustryAndAuditAreaAndScotabdsForChanges() {
      if (properties.criteriaType === RULES.CRITERIA_TYPES.INDUSTRY) {
        setTempSelectedIndustries((prevState) => {
          const copy = { ...prevState };
          copy[id] = properties.value;
          return copy;
        });
      } else if (properties.criteriaType === RULES.CRITERIA_TYPES.AUDIT_AREA) {
        setTempSelectedAuditArea((prevState) => {
          const copy = { ...prevState };
          copy[id] = properties.value;
          return copy;
        });
      } else if (properties.criteriaType === RULES.CRITERIA_TYPES.SCOTABDS) {
        setTempSelectedScotabds((prevState) => {
          const copy = { ...prevState };
          copy[id] = properties.value;
          return copy;
        });
      }
    },
    [id, properties.criteriaType, properties.value]
  );

  useUpdateEffect(
    function watchTempSelectedIndustries() {
      setSelectedIndustries((prevState) => {
        const copy = { ...prevState, ...tempSelectedIndustries };
        return copy;
      });
    },
    [tempSelectedIndustries]
  );

  useUpdateEffect(
    function watchTempSelectedAuditAreas() {
      setSelectedAuditAreas((prevState) => {
        const copy = { ...prevState, ...tempSelectedAuditArea };
        return copy;
      });
    },
    [tempSelectedAuditArea]
  );

  useUpdateEffect(
    function watchTempSelectedScotabds() {
      setSelectedScotabds((prevState) => {
        const copy = { ...prevState, ...tempSelectedScotabds };
        return copy;
      });
    },
    [tempSelectedScotabds]
  );

  useEffect(
    function enableAuditAreaAndScotabds() {
      setDisabledCriteriaTypes((prevState) => {
        let copy = prevState;
        if (criterias.length > 1 && selectedIndustries.length) {
          const filtered = copy.filter(
            (value) => value !== RULES.CRITERIA_TYPES.AUDIT_AREA
          );
          copy = [...filtered];
        } else copy.push(RULES.CRITERIA_TYPES.AUDIT_AREA);
        if (criterias.length > 2 && Object.keys(selectedAuditAreas).length) {
          const filtered = copy.filter(
            (value) => value !== RULES.CRITERIA_TYPES.SCOTABDS
          );
          copy = [...filtered];
        } else copy.push(RULES.CRITERIA_TYPES.SCOTABDS);
        return copy;
      });
    },
    [selectedIndustries, selectedAuditAreas, criterias]
  );

  useEffect(
    function filterAnswerFieldsForQuestionCriteria() {
      if (properties.criteriaType === RULES.CRITERIA_TYPES.QUESTION) {
        const found = fields.find(
          (field) => field.id === properties.questionId
        )?.options;
        if (found) {
          const copy = [...found];
          copy.push(RULES.ANY_RESPONSE);
          setAnswerFields(copy);
        } else if (properties.isExternalQuestion) {
          updateExternalQuestionsAnswerFields();
        } else setAnswerFields([]);
      }
    },
    [fields, properties.questionId, properties.criteriaType]
  );

  useEffect(
    function showSelectQuestion() {
      if (!selected) {
        setSelectedQuestion();
        setCriteriaTypes(ruleCriteriaTypes?.map(rule=> ({label: rule.label, value: rule.value}))?? []);
        if (properties.criteriaType === RULES.CRITERIA_TYPES.QUESTION)
          setVisible(true);
      }
    },
    [properties.criteriaType, selected]
  );

  useEffect(
    function addSelectedQuestionToCriteriaDropdown() {
      if (properties.criteriaType === RULES.CRITERIA_TYPES.QUESTION)
        if (selectedQuestion) {
          const found = fields.find(
            (field) => field.id === selectedQuestion?.value
          );
          if (!found) {
            if (selectedQuestion?.label) {
              setCriteriaTypes((previousState) => {
                const copy = [...previousState];
                copy.push({
                  label: selectedQuestion?.label,
                  value: selectedQuestion?.value,
                });
                return copy;
              });
            }
          } else {
            found?.label &&
              setCriteriaTypes((previousState) => {
                const copy = [...previousState];
                copy.push({
                  label: found.label,
                  value: selectedQuestion?.value,
                });
                return copy;
              });
          }
          setSelected(true);
        }
    },
    [selectedQuestion, properties.criteriaType]
  );

  useEffect(function initializeQuestionIdIfNotEmpty() {
    if (properties.criteriaType === RULES.CRITERIA_TYPES.QUESTION) {
      if (properties.questionId) {
        fetchExternalQuestion();
      }
    }
  }, []);
  return (
    <>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={6}>
          <CLADropdown
            value={selected ? selectedQuestion?.value : properties.criteriaType}
            disabledValues={disabledCriteriaTypes}
            options={criteriaTypes}
            onChange={(e) => {
              handlePropertyChange('criteriaType', e.target.value);
              setSelected(false);
              setEditMode(false);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <CLADropdown
            displayEmpty={false}
            value={properties.isEqual}
            options={[
              { label: 'Is', value: RULES.EQUALITY.IS },
              { label: 'Is Not', value: RULES.EQUALITY.IS_NOT },
              { label: 'Is Any', value: RULES.EQUALITY.ANY },
            ]}
            onChange={(e) => handlePropertyChange('isEqual', e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          {properties.criteriaType === RULES.CRITERIA_TYPES.AUDIT_AREA ? (
            <CLADropdown
              value={properties.value[0]}
              options={answerFields}
              onChange={(e) => handlePropertyChange('value', [e.target.value])}
            />
          ) : (
            <CLASelect
              defaultValues={properties.value}
              menuItems={answerFields}
              onChange={(value) => handlePropertyChange('value', value)}
              truncateDisplay
            />
          )}
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => deleteCriteria(id)}
            sx={{
              ':hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <SvgIcon sx={{ fontSize: '30px' }}>
              <DeleteIcon />
            </SvgIcon>
          </IconButton>
        </Grid>
      </Grid>
      {visible && !editMode && (
        <SelectQuestion
          methodologyVersionId={methodologyVersionId}
          formSchemaID={formSchemaID}
          formName={formName}
          formIndustries={formIndustries}
          formCategoryOptions={formCategoryOptions}
          formCategory={formCategory}
          sections={sections}
          fields={fields}
          handlePropertyChange={handlePropertyChange}
          visible={visible}
          setVisible={setVisible}
          setSelectedQuestion={setSelectedQuestion}
          setLoading={handleLoader}
        />
      )}
    </>
  );
};
