import { useState, useEffect, useContext } from 'react';
import { useIdle } from 'react-use'
import { useFormContext, useWatch } from "react-hook-form";
import PropTypes from 'prop-types';

import { FIELD_TYPES, RULES, WAITING_TIME } from '@ais/constants';
import { SchemaContext } from '@ais/providers';
import { LinkIconComponent } from '@ais/assets';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { useQuery } from '@hooks/index';
import { useFinalizedProject } from '@hooks/useProject';
import { useVFCanvasProvider, useProjectFormInstanceProvider } from '@providers';

import { useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';

import { VFDraggableInstanceField, VFDroppableExistingField } from '@components/FormView';
import styles from '@components/FormView/FormView.module.css';
import { VFFields } from './components';

export const VFRenderedField = (props) => {
  const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
  const {
    field,
    disabled,
    isEditMode,
    projectFormId,
    section,
    sectionIndex,
    rowIndex,
    onDeleteQuestion,
    onLinkClick,
    answerList,
    modifiedAfterSignOff,
    isInstance,
    projectId = null,
    methodologyIndustries,
    lockDetails,
    methodologyVersionId,
    formTypeId,
    sectionForViewFormInternalControl,
    procedureFilter,
    setUserModifiedAnswerLinkDefault,
    units,
    project,
    answered,
    signOffList,
    frameworks = [],
    performanceStandards = []
  } = props;
  const query = useQuery();
  const questionIds = query.get('questionIds');
  const updateMyPresence = useUpdateMyPresence()
  const { actions } = useVFCanvasProvider();
  const isProjectFinalized = useFinalizedProject(projectId);
  const isProcedureField = [FIELD_TYPES.PROCEDURE, FIELD_TYPES.CLIENT_SITUATIONS].includes(field.type);
  const isIdle = useIdle(WAITING_TIME.MINS_15);
  const [rules, setRules] = useState(field?.rules || undefined);
  const [results, setResults] = useState([]);
  const [shouldHighlight, setShouldHighlight] = useState('');
  const { schema } = useContext(SchemaContext);
  const handleDefaultLink = () => {
    if (isInstance && schema?.answerLinkDefaultIds?.length > 0) {
      setUserModifiedAnswerLinkDefault && setUserModifiedAnswerLinkDefault(field.id, true)
    }
  }
  const formCanvas = useFormContext();
  const watchRadioList = useWatch({ control: formCanvas.control, name: field.id });
  const watchDependentFields = useWatch({ control: formCanvas.control, name: field.rules?.criterias?.map(c => c.questionId) ?? [] });
  const { actions: { updateIsFormFocused } } = useProjectFormInstanceProvider();

  const getDefaultValue = (multipleValues = false, _) => {
    const answerLinkDefault = schema.answerLinkDefaultIds?.find((item) => item.id === field.id);
    const id =
      answerLinkDefault && !answerLinkDefault?.isUserModified && answerLinkDefault?.existingQuestionId
        ? field.existingQuestionId
        : field.id;

    if (!isInstance && !formCanvas.getValues(id)) {
      if (multipleValues) {
        return field.defaultValues?.length > 0 &&
          field.defaultValues.every((item) => item instanceof String)
          ? field.defaultValues
          : [''];
      }
      return field.defaultValue ?? '';
    }

    return formCanvas.getValues(id);
  };

  const handleBlur = (e, _) => {
    if (!isInstance) return;

    const currentTarget = e?.currentTarget;
    const isToggleInputType = e && (e.target.type === 'radio' || e.target.type === 'checkbox')

    requestAnimationFrame(() => {
      const isQuestionGroup = field.type === FIELD_TYPES.QUESTION_GROUP;

      // Check if the new focused element is a child of the original container
      if (!currentTarget?.contains(document.activeElement) || isQuestionGroup) {
        handleDefaultLink();

        if (actions) {
          actions.handleSubmit();
        }
        if (isToggleInputType && field.type !== FIELD_TYPES.PROJECT_SCOPE_AUDIT_AREA) {
          updateMyPresence({ focusedId: null })
        }
      }
    });
  };

  const handlePercentageValue = (value) => {
    if (value || value === 0) {
      return isNaN(+value) ? value.replace('%', '') : value;
    } else {
      return undefined;
    }
  };

  const handleDeleteField = () => {
    onDeleteQuestion(field.id, sectionIndex);
  };

  const handleLinkClick = () => {
    if (onLinkClick) onLinkClick(field.id);
  };


  const renderLink = () => {
    return onLinkClick &&
      <div
        className={styles['link-icon']}
        style={{ top: isEditMode ? '28%' : '37%' }}
      >
        <LinkIconComponent onClick={handleLinkClick} />
      </div>
  }

  const handleOnFocusInstance = () => {
    if (!isInstance || typeof lockDetails?.userId === 'string') return;
    updateIsFormFocused(true);
  }
  const renderActualField = () => {
    if (field?.isFormInstanceQuestion && isEditMode)
      return (
        (
          <VFDraggableInstanceField
            field={field}
            handleBlur={handleBlur}
            renderField={
              <VFFields
                field={field}
                projectId={projectId}
                projectFormId={projectFormId}
                disabled={disabled || isProjectFinalized}
                isInstance={isInstance}
                section={section}
                answerList={answerList}
                renderLink={renderLink}
                getDefaultValue={getDefaultValue}
                handlePercentageValue={handlePercentageValue}
                handleOnFocus={handleOnFocusInstance}
                methodologyIndustries={methodologyIndustries}
                methodologyVersionId={methodologyVersionId}
                formTypeId={formTypeId}
                procedureFilter={procedureFilter}
                setUserModifiedAnswerLinkDefault={setUserModifiedAnswerLinkDefault}
                units={units}
                answered={answered}
              />
            }
            projectFormId={projectFormId}
            section={section}
            sectionIndex={sectionIndex}
            rowIndex={rowIndex}
            handleDeleteField={handleDeleteField}
            shouldHighlight={shouldHighlight}
            onLinkClick={onLinkClick ? handleLinkClick : null}
          />
        )
      )
    else
      return (
        <VFDroppableExistingField
          isEditMode={isEditMode}
          field={field}
          rules={rules}
          isInstance={isInstance}
          formTypeId={formTypeId}
          sectionForViewFormInternalControl={sectionForViewFormInternalControl}
          handleBlur={handleBlur}
          renderField={
            <VFFields
              field={field}
              projectId={projectId}
              projectFormId={projectFormId}
              disabled={disabled || isProjectFinalized}
              isInstance={isInstance}
              section={section}
              answerList={answerList}
              watchRadioList={watchRadioList}
              renderLink={renderLink}
              getDefaultValue={getDefaultValue}
              handlePercentageValue={handlePercentageValue}
              handleOnFocus={handleOnFocusInstance}
              methodologyIndustries={methodologyIndustries}
              methodologyVersionId={methodologyVersionId}
              formTypeId={formTypeId}
              procedureFilter={procedureFilter}
              handleBlur={handleBlur}
              setUserModifiedAnswerLinkDefault={setUserModifiedAnswerLinkDefault}
              units={units}
              isIdle={isIdle}
              project={project}
              answered={answered}
              signOffList={signOffList}
            />
          }
          projectFormId={projectFormId}
          section={section}
          sectionIndex={sectionIndex}
          rowIndex={rowIndex}
          handleDeleteField={handleDeleteField}
          shouldHighlight={shouldHighlight}
          onLinkClick={onLinkClick ? handleLinkClick : null}
        />
      );
  };

  useEffect(() => {
    setRules(field?.rules);
  }, [field]);

  useEffect(() => {
    if (modifiedAfterSignOff) {
      const shouldHighlight = !!modifiedAfterSignOff?.find(signOff => signOff?.toLowerCase() === field.id?.toLowerCase()) && !isProcedureField
        ? HIGHLIGHT_BORDER.MODIFIED :
        questionIds?.split(',').indexOf(field.id) >= 0 ? HIGHLIGHT_BORDER.RELATED
          : null;

      setShouldHighlight(shouldHighlight);
    }
  }, [modifiedAfterSignOff]);

  useEffect(() => {
    setRules(current => ({ ...current }));
  }, [watchDependentFields]);

  useEffect(
    function filterCriterias() {
      if (rules?.criterias?.length) {
        const { criterias } = rules;
        const results = [];
        criterias.forEach((criteria) => {
          const { criteriaType, questionId, any, isEqual, value } = criteria;
          if (criteriaType === RULES.CRITERIA_TYPES.QUESTION) {
            const _value = formCanvas.getValues(questionId);
            const formValue = Array.isArray(_value)
              ? _value.filter(
                (value) => value !== undefined && value !== ''
              )
              : [_value].filter(
                (value) => value !== undefined && value !== ''
              );
            if (any) {
              if (formValue.length) results.push(true);
              else results.push(false);
            } else {
              if (isEqual === RULES.EQUALITY.IS)
                results.push(
                  formValue.length === value.length &&
                  formValue.every((_formValue) => value.includes(_formValue))
                );
              else if (isEqual === RULES.EQUALITY.IS_NOT)
                results.push(
                  !formValue.every((_formValue) => value.includes(_formValue))
                );
              else if (isEqual === RULES.EQUALITY.ANY)
                results.push(
                  formValue.some((_formValue) => value.includes(_formValue))
                );
            }
          } else if (
            isInstance &&
            criteriaType === RULES.CRITERIA_TYPES.INDUSTRY
          ) {
            if (isEqual === RULES.EQUALITY.IS)
              results.push(
                value.every((mi) => methodologyIndustries.includes(mi))
              );
            else if (isEqual === RULES.EQUALITY.IS_NOT)
              results.push(
                !methodologyIndustries.some((mi) => value.includes(mi))
              );
            else if (isEqual === RULES.EQUALITY.ANY) {
              results.push(
                methodologyIndustries.some((mi) => value.includes(mi))
              );
            }
          } else if (
            isInstance &&
            criteriaType === RULES.CRITERIA_TYPES.REPORTING_FRAMEWORK
          ) {
            if (isEqual === RULES.EQUALITY.IS) {
              results.push(
                value.every((f) => frameworks.includes(f))
              );
            }
            else if (isEqual === RULES.EQUALITY.IS_NOT) {
              results.push(
                !frameworks.some((f) => value.includes(f))
              );
            }
            else if (isEqual === RULES.EQUALITY.ANY) {
              results.push(
                frameworks.some((f) => value.includes(f))
              );
            }
          } else if (
            isInstance &&
            criteriaType === RULES.CRITERIA_TYPES.PERFORMANCE_STANDARDS
          ) {
            if (isEqual === RULES.EQUALITY.IS) {
              results.push(
                value.every((ps) => performanceStandards.includes(ps))
              );
            }
            else if (isEqual === RULES.EQUALITY.IS_NOT) {
              results.push(
                !performanceStandards.some((ps) => value.includes(ps))
              );
            }
            else if (isEqual === RULES.EQUALITY.ANY) {
              results.push(
                performanceStandards.some((ps) => value.includes(ps))
              );
            }
          }
        });
        setResults(results);
      }
    }, [formCanvas, rules, watchRadioList]);

  if (results.length) {
    if (rules?.match === RULES.MATCH.ANY) {
      if (rules?.behavior === RULES.BEHAVIOR.SHOW) {
        if (results.some((result) => result === true)) return renderActualField();
        else return null;
      } else if (rules?.behavior === RULES.BEHAVIOR.HIDE) {
        if (results.some((result) => result === true)) return null;
        else return renderActualField();
      }
    } else if (rules?.match === RULES.MATCH.ALL) {
      if (rules?.behavior === RULES.BEHAVIOR.SHOW) {
        if (results.every((result) => result === true)) return renderActualField();
        else return null;
      } else if (rules?.behavior === RULES.BEHAVIOR.HIDE) {
        if (results.every((result) => result === true)) return null;
        else return renderActualField();
      }
    }
  } else return renderActualField();

};

VFRenderedField.propTypes = {
  field: PropTypes.object,
  disabled: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isInstance: PropTypes.bool,
  onDeleteQuestion: PropTypes.func,
  methodologyIndustries: PropTypes.array,
  onFocus: PropTypes.func,
  lockDetails: PropTypes.object,
  currentUserlockDetails: PropTypes.object,
  answered: PropTypes.bool,
};

VFRenderedField.defaultProps = {
  disabled: false,
  isEditMode: false,
  isInstance: false,
  methodologyIndustries: [],
  lockDetails: {},
  currentUserlockDetails: {},
  answered: false
};
