import {    
    MenuItem, 
    Box,
    Typography
} from '@mui/material';
import { assertionAcronymFormatter } from '@utilities/assertionAcronymFormatter.js';
import {CLAActionEllipsis} from '@ais/forms';
import { sanitize } from 'dompurify';
import { v4 as uuidv4 } from 'uuid';
import style from './CLATailorProcedureTable.module.css';

const CustomTailorProcedure = (props) => {
    const {
        openDialog,
        warningDialog,
        customProcedure,
        setCustomProcedure,
        assertions,
        index
    } = props;

    const {
        PrimaryAssertions: primaryAssertions,
        SecondaryAssertions: secondaryAssertions,
        Description: description,
        CustomProcedureName: customProcedureName
    } = customProcedure;

    const separator = secondaryAssertions && primaryAssertions && secondaryAssertions.length > 0 && primaryAssertions.length > 0 ? ', ' : '';

    return (
        <Box className={index > 0 && style.post} sx={{ display: 'flex', flexDirection: 'column', marginTop: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', margin: 0, marginTop: index > 0 && '1rem', paddingLeft: '45px', paddingTop: '8px'}}>
                <CLAActionEllipsis sx={{padidng: 0}}>
                    {() => [
                        <MenuItem
                            key={`Edit_${uuidv4()}`}
                            onClick={() => {
                                setCustomProcedure(customProcedure)
                                openDialog()
                            }}>Edit</MenuItem>,
                        <MenuItem
                            key={`Delete_${uuidv4()}`}
                            onClick={() => warningDialog(customProcedure)}>
                            Delete</MenuItem>,
                    ]}
                </CLAActionEllipsis>
                <Typography
                    sx={{ fontWeight: 700 }}
                    component="p"                    
                    fontSize='1rem'
                    color='#2E334E'>
                    {customProcedureName?.toUpperCase()}
                </Typography>
            </Box>
                
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>  
                <div style={{ width: '3%' }} />   
                <div style={{width: '97%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center'
                    }}>                        
                        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>                          
                            <Typography sx={{
                                fontSize: '16px',
                                color: '#4B4B4B',                                
                            }} component="p">
                                <span
                                    className="ql-editor"
                                    style={{ display: 'grid', paddingRight: '10px', fontSize: '16px', fontWeight: 600, paddingLeft: "70px", paddingBottom: '21px', paddingTop: "0px" }}
                                    dangerouslySetInnerHTML={{
                                        __html: sanitize(`${description}`, { ADD_ATTR: ['target'] }),
                                    }}
                                />
                            </Typography>
                        </Box>
                        <Box sx={{
                            flexShrink: 0,
                            width: '100px'
                        }}>                            
                            <Typography sx={{
                                overflowWrap: 'break-word',
                                fontSize: '1rem',
                                color: '#4B4B4B',                                
                            }}>
                                {assertionAcronymFormatter(primaryAssertions, assertions, true) + separator + assertionAcronymFormatter(secondaryAssertions, assertions)}
                            </Typography>                            
                        </Box>
                    </Box>
                </div>                                 
            </Box >
        </Box>
    )
}

export default CustomTailorProcedure