import {useContext} from 'react';
import {AppTypeContext} from './AppTypeProvider';

export const useAppTypeProvider = () => {
  const context = useContext(AppTypeContext);

  if (context === undefined) {
    throw new Error(
      'useAppTypeProvider() hook must be used within <AppTypeContext/>'
    );
  }

  return context;
}