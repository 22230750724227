import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const ProjectScopeContext = createContext({});

export const ProjectScopeProvider = ({ children }) => {

    const [answerList, setAnswerList] = useState([]);
    const [projectScopeId, setProjectScopeId] = useState(undefined);
    const [auditAreas, setAuditAreas] = useState([]);
    const [scotabds, setScotabds] = useState([]);
    const [financialStatements, setFinancialStatements] = useState([]);
    const [concurrencyEventReceived, setConcurrencyEventReceived] = useState(false)
    const [frameworksHighlight, setFrameworksHighlight] = useState([]) 
    const [commonRisks, setCommonRisks] = useState([]) 
    const [performanceStandardsHighlight, setPerformanceStandardsHighlight] = useState([])  
    const [deletedAuditAreas, setDeletedAuditAreas] = useState([])
    const [deletedFinancialStatements, setDeletedFinancialStatements] = useState([])
    const [performanceStandards, setPerformanceStandards] = useState([])
    const [frameworks, setFrameworks] = useState([])
    
    const memoized = useMemo(
        () => ({
            answerList,
            projectScopeId,
            auditAreas,
            scotabds,
            financialStatements,
            commonRisks, 
            frameworks,
            performanceStandards,
            frameworksHighlight,
            performanceStandardsHighlight,
            deletedAuditAreas,
            deletedFinancialStatements, 
            setProjectScopeId,
            setAuditAreas,
            setScotabds,
            setFinancialStatements,
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            setFrameworks,
            setPerformanceStandards,
            setAnswerList,
            setCommonRisks,
            setDeletedAuditAreas, 
            setFrameworksHighlight,
            setPerformanceStandardsHighlight, 
            setDeletedFinancialStatements, 
         }),
        [
            answerList,
            projectScopeId,
            auditAreas,
            scotabds, 
            commonRisks,  
            frameworksHighlight,
            performanceStandardsHighlight,  
            financialStatements,
            deletedAuditAreas,
            deletedFinancialStatements,
            performanceStandards,
            frameworks,
            setProjectScopeId,
            setAuditAreas,
            setScotabds,
            setFinancialStatements,
            concurrencyEventReceived,
            setConcurrencyEventReceived,   
            setAnswerList,
            setCommonRisks,  
            setFrameworksHighlight,
            setPerformanceStandardsHighlight,
            setDeletedAuditAreas,
            setDeletedFinancialStatements,
            setPerformanceStandards,
            setFrameworks
        ]
    )

    return (
        <ProjectScopeContext.Provider value={memoized}>{children}</ProjectScopeContext.Provider>
    )
}

ProjectScopeProvider.propTypes = {
    children: PropTypes.node
}