import { memo, useState, useEffect } from 'react'
import { Flex } from "@ais/palette";
import {
  Radio,
  TextField,
  Grid,
  FormControlLabel,
} from '@mui/material'; 
import { Controller, useFormContext } from 'react-hook-form'; 
import { useRoomIdle } from '@ais/hooks';

const RadioOtherOption = ({ allowOtherOption, options, id, disabled, lockField, unlockField, onFocus, onSubmit }) => { 
  const { control, setValue, watch } = useFormContext();
  const value = watch(id);
  const [textValue, setTextValue] = useState('');
  const [otherRadio, setOtherRadio] = useState(false);
  const isIdle = useRoomIdle()

  const handleTextBlur = (e) => {
    if (isIdle) return;
    setValue(id, e.target.value);
    if (options.includes(e.target.value)) {
      setOtherRadio(false);
      setTextValue('');
    }
    onSubmit(id)
    unlockField && unlockField()
  };

  useEffect(() => { 
    if (!options.includes(value)) { 
      setTextValue(value);
    } else {
      setOtherRadio(false)
      setTextValue('');
    }
  }, [options, value])

  useEffect(()=>{
    if(isIdle && !options.includes(value)) setTextValue(value)
  }, [isIdle])

  if (!allowOtherOption) return <></>;
  return (
    <Grid style={{ minWidth: '150px' }} item xs={1}>
      <Flex direction="row" align="baseline">
        <FormControlLabel
          label="Other"
          control={
            <Radio
              checked={!!textValue || otherRadio}
              onChange={(e) => {
                if (!e.target.value) {
                  setTextValue('');
                  setOtherRadio(true)
                }
              }}
              inputProps={{
                "test-id": `RadioButton-${id}`,
                "test-value": textValue
              }}
              disabled={disabled}
            />
          }
        />
        <Controller
          key={id}
          control={control}
          name={`RadioButton-other-${id}`}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              value={textValue}
              onChange={e => {
                setTextValue(e.target.value);
              }}
              onBlur={(e) => handleTextBlur(e)}
              inputProps={{
                "test-id": `RadioButtonOther-${id}`,
                "test-value": textValue
              }}
              onFocus={(e) => {
                onFocus(e, id)
                if (!textValue && textValue === '') {
                  setValue(id, '');
                }
                setOtherRadio(true);
                lockField && lockField()
              }}
              disabled={disabled}
            />
          )}
        />
      </Flex>
    </Grid>
  );
};


export default memo(RadioOtherOption);

