import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import Typography from '@mui/material/Typography';

import styles from './CLADialogConfirm.module.css';

const SingleButton = ({ customButton,  onConfirm, variant, confirmText }) => {
    return customButton ? customButton :
                    <Button
                        className={styles.confirmButton}
                        variant={variant}
                        disableElevation
                        onClick={onConfirm}
                    >
                        {confirmText}
                    </Button>;
}

export const CLADialogConfirm = ({
    visible,
    title,
    message,
    customMessage,
    warningMessage,
    singleButton,
    cancelText,
    confirmText,
    customButton,
    onConfirm,
    onCancel,
    customCancelButton,
    onClose,
    variant,
    children,
}) => {
    return (
        <Dialog
            open={visible}
            scroll="paper"
            variant="cla-confirm"
            PaperProps={{
                className: styles.dialogPaperContainer
            }}
            onClose={(onClose) && onClose}
        >
            <DialogTitle variant="cla-confirm">{title}</DialogTitle>
            <DialogContent variant="cla-confirm">
                <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontSize: '14px' }}>
                    {
                        (customMessage)
                            ? customMessage
                            : message
                    }
                </Typography>
                {warningMessage && <Typography className={styles.warningMessage} >{warningMessage}</Typography>}
                {children}
            </DialogContent>
            <DialogActions>
                {
                    singleButton ? <SingleButton {...{customButton, onConfirm, variant, confirmText}}  /> :
                        <>
                            {
                                customCancelButton
                                    ? customCancelButton
                                    : <Button
                                        className={styles.cancelButton}
                                        variant="outlined"
                                        disableElevation
                                        onClick={onCancel}
                                    >
                                        {cancelText}
                                    </Button>
                            }
                            {customButton ?
                                customButton
                                : <Button
                                    className={styles.confirmButton}
                                    variant="contained"
                                    disableElevation
                                    onClick={onConfirm} >
                                    {confirmText}
                                </Button>
                            }
                        </>
                }
            </DialogActions>
        </Dialog>
    );
};

CLADialogConfirm.propTypes = {
    visible: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    warningMessage: PropTypes.string,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    customButton: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    singleButton: PropTypes.bool,
    variant: PropTypes.string,
}

CLADialogConfirm.defaultProps = {
    visible: false,
    singleButton: false,
    confirmText: 'OK',
    variant: 'outlined'
}

export default CLADialogConfirm;