import * as Constants from '@constants/index'
import { useEffect, useState } from 'react';
import style from "@components/MethodologyVersions/MethodologyVersions.module.css";
import {Typography, Container} from '@mui/material'
import MethodologyVersionsContrast from '@views/Methodology/MethodologyVersionsContrast';
import { richTextEditorTextContent } from '@utilities/richTextEditorTextContent';
import { useGetMethodologyChangeReportByMethodologyVersionId } from "@services/methodologyversionreport";
import { useLoading } from '@hooks/index';

const MethodologyVersions = ({ methodologyVersionId }) => {
  const [changeReport, setChangeReport] = useState()
  const setLoading = useLoading();
  const {
    data: methodologyChangeReport,
    isLoading: isChangeReportLoading
  } = useGetMethodologyChangeReportByMethodologyVersionId(methodologyVersionId)

  useEffect(() => {
    setLoading(isChangeReportLoading)
  }, [isChangeReportLoading])

  useEffect(() => {
    if(methodologyChangeReport?.Comment){
      const report = JSON.parse(methodologyChangeReport?.Comment)
      const transformedReport = report?.map((report) => {
        return {
          ...report,
          formName: report.name
        }})
      setChangeReport(transformedReport)
    }
  },[methodologyChangeReport])

    return ( 
      <div className={style.versionContainer}>
        <div className={style.versionHeader}>
          <span className={style.versionTitle}>{Constants.PROJECT_DASHBOARD.EN.RELEASE_NOTES}</span>
          <div className={style.versions}>
            {
              <>
              <div className={style.startingVersion}>
                <span>{Constants.PROJECT_DASHBOARD.EN.STARTING_VERSION}</span>
                <Typography
                sx={{ 
                  fontFamily: "museo-sans", 
                  fontWeight: 600, 
                  lineHeight: "21.6px", 
                  color: "#000000", 
                  fontSize: '18px',
                  paddingTop: '5px'
                }}
                >
                  {methodologyChangeReport?.StartMethodologyVersion}
                </Typography>
              </div>
              <div className={style.currentVersion}>
                <span>{Constants.PROJECT_DASHBOARD.EN.CURRENT_VERSION}</span>
                <Typography
                sx={{ 
                  fontFamily: "museo-sans", 
                  fontWeight: 600, 
                  lineHeight: "21.6px", 
                  color: "#000000", 
                  fontSize: '18px',
                  paddingTop: '5px'
                }}
                >
                  {methodologyChangeReport?.EndMethodologyVersion}
                </Typography>
              </div>
              </>
            }
          </div>
        </div>
        <div className={style.versionContent}>
          {
            <Container disableGutters sx={{backgroundColor: 'white', marginTop: "10px"}}>
              <div className={style.descriptionContainer}>
                <Typography sx={{ 
                  fontFamily: "museo-sans", 
                  fontWeight: 700, 
                  lineHeight: "19.2px", 
                  color: "#2E334E", 
                  fontSize: '16px'}}>
                    {Constants.PROJECT_DASHBOARD.EN.DESCRIPTION}
                  </Typography>
                <Typography sx={{ 
                  fontFamily: "museo-sans", 
                  lineHeight: "21.6px", 
                  color: "#979797", 
                  fontSize: '18px',
                  paddingTop: '10px'
                  }}>
                    {richTextEditorTextContent(methodologyChangeReport?.Description)}
                  </Typography>
              </div>
              <div className={style.contrastContainer}>
                <MethodologyVersionsContrast 
                  methodologyChangeReports={changeReport}
                />
              </div>
              
            </Container>
          }

        </div>
      </div>
    )
}

export default MethodologyVersions;