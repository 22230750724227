import { useContext, useEffect } from "react"
import { ApplicationContext } from "@contexts/ApplicationContext";
import { APPLICATION_ACTION } from "@constants/index";

export const useLoadMap = () => {
    const { dispatchApplication } = useContext(ApplicationContext);

    const setLoadMapEntry = (loadKey, isLoading) => {
        dispatchApplication({
            type: APPLICATION_ACTION.SET_LOAD_MAP,
            payload: {loadKey, isLoading},
        });
    }

    useEffect(() => {
        return () => {
            dispatchApplication({
                type: APPLICATION_ACTION.CLEAR_LOAD_MAP,
            });
        }
    }, [])

    return {
        setLoadMapEntry
    }
}
