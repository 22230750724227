import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Button, Grid } from "@mui/material";

import { BasicDropdown } from "./BasicDropdown";
import { convertStringToArray, validateAdvancedDefaultAnswers } from "@components/Forms/helpers";
import DefaultValueDrawer from "./DefaultValueDrawer";
import DefaultValues from "./DefaultValues";

import {FIELD_TYPES} from '@ais/constants';
import { DIALOG } from "@constants/forms";

import styles from "./RadioButtonDefaultAnswersTab.module.css";

export const RadioButtonDefaultAnswersTab = () => {
  const { setValue, control } = useFormContext();
  const watchDefaulValues = useWatch({ control, name: 'defaultValues' }) ?? [];
  const watchLabel = useWatch({ control, name: 'label' }) ?? '';
  const watchOptions = useWatch({ control, name: 'options' }) ?? '';

  const { PLACEHOLDER, LABEL } = DIALOG.SETTINGS;
  const { RADIOGROUP } = DIALOG.DEFAULT_ANSWERS;
  const { EDIT_ADVANCED, ADVANCED, ADVANCED_MODE_DETECTED, SELECT_DEFAULT_ANSWER } = DIALOG.DEFAULT_ANSWERS.GENERAL;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);

  const [defaultValues, setDefaultValues] = useState();

  const handleChange = (values) => {
    const validationResult = validateAdvancedDefaultAnswers(values, watchDefaulValues);
    setIsValidForm(validationResult.isValid);
    setDefaultValues(validationResult.newValues);
  };

  const handleSave = () => {
    setValue("defaultValues", defaultValues);
    setValue("defaultValue", "");
    setOpenDrawer(false);
  };

  return (
    <Grid container spacing={4} rowSpacing={1}>
      <Grid sx={{ marginBottom: "15px" }} item xs={12} spacing={1}>
        <span className={styles["question-label"]}>{LABEL.QUESTION_LABEL}</span>
        <span className={styles["question-label-text"]}>
          {watchLabel}
        </span>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={5}>
          <BasicDropdown
            name="defaultValue"
            label={SELECT_DEFAULT_ANSWER}
            placeholder={
              watchDefaulValues.length
                ? ADVANCED_MODE_DETECTED
                : PLACEHOLDER.BASIC_DROPDOWN
            }
            disabled={watchDefaulValues.length ? true : false}
            options={convertStringToArray(watchOptions)}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={5} justifyContent="flex-end">
          <Button
            onClick={() => {
              setOpenDrawer(true);
            }}
            variant="text"
            disableRipple
            sx={{
              fontFamily: "museo-sans",
              textTransform: "none",
              color: "#76D2D3",
              fontSize: "16px",
              p: 0,
              ":hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            {watchDefaulValues.length
              ? EDIT_ADVANCED
              : ADVANCED}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DefaultValueDrawer
          name="defaultValues"
          formComponentName={RADIOGROUP.COMPONENT_NAME}
          drawerVisible={openDrawer}
          onClose={() => setOpenDrawer(false)}
          handleSave={handleSave}
          isValidForm={isValidForm}
        >
          <DefaultValues
            onChange={handleChange}
            fieldType={FIELD_TYPES.RADIOGROUP}
          />
        </DefaultValueDrawer>
      </Grid>
    </Grid>
  );
};
