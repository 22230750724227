export const FORM_NAVIGATION = {
    EN: {
        menuList: [
            { title: 'Form Designer', to: '/forms-designer' },
            { title: 'Form Settings', to: '/forms-settings' },
            { title: 'Help', to: '/forms-help' }
        ],
    }
}
export const BACK_ARROW_LINK = {
    backLink: ['Forms Library', '/forms-library']
}