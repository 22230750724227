import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Stack } from '@mui/material';

import formServices from '@services/forms/forms';
import projectFormServices from '@services/forms/projectforms';
import { CLASimpleDrawer } from '@ais/components';
import { useLoading } from '@hooks/index';
import { SCOTABDsTable } from '.';
import { useQueryClient } from "@tanstack/react-query";

export const SCOTABDsDrawer = (props) => {
  const {
    methodologyIndustries,
    methodologyVersionId,
    visible,
    setVisible,
    allScotabds,
    defaultScotabds,
    selectedProjectScopeAuditAreaId,
    selectedAuditAreaId,
    fetchData,
    setConcurrencyEventReceived
  } = props;

  const setLoading = useLoading();
  const { projectId, projectFormId } = useParams();

  const [_defaultScotabds, _setDefaultScotabds] = useState([]);
  const [filteredScotabds, setFilteredScotabds] = useState([]);
  const [selectedScotabds, setSelectedScotabds] = useState([]);
  const [customScotabds, setCustomScotabds] = useState([]);
  const [accountBalances, setAccountBalances] = useState([]);
  const [assertions, setAssertions] = useState([]);
  const queryClient = useQueryClient();

  const handleSave = async () => {
    try {
      setLoading(true);
      const payload = selectedScotabds.map((ss) => {
        const {
          SCOTABDId,
          ClassOfTransactionName,
          AccountBalances,
          Assertions,
          IsAccountingEstimateAndDisclosure,
        } = ss;
        return {
          SCOTABDId,
          ClassOfTransactionName,
          AccountBalanceTypeList: JSON.stringify(AccountBalances),
          AssertionList: JSON.stringify(Assertions),
          IsAccountingEstimateAndDisclosure,
          ProjectScopeAuditAreaSCOTABDId: _defaultScotabds.find(ds => ds.SCOTABDId === SCOTABDId)?.ProjectScopeAuditAreaSCOTABDId ?? null
        };
      });
      
      const payloadCustom = customScotabds
        .filter((cs) => cs.enabled === true)
        .map((cs) => {
          const _accountBalances = accountBalances
            .filter((ab) => cs.accountBalances.some((_ab) => _ab === ab.AccountBalanceTypeId))
            .map((ab) => {
              const { AccountBalanceTypeId, AccountBalanceName } = ab;
              return {
                AccountBalanceTypeId,
                AccountBalanceName,
              };
            });
          const _assertions = assertions
            .filter((a) => cs.assertions.some((_a) => _a === a.AssertionId))
            .map((a) => {
              const { AssertionId, AssertionName } = a;
              return {
                AssertionId,
                AssertionName,
              };
            });
          return {
            SCOTABDId: null,
            ClassOfTransactionName: cs.name,
            AccountBalanceTypeList: JSON.stringify(_accountBalances),
            AssertionList: JSON.stringify(_assertions),
            IsAccountingEstimateAndDisclosure: cs.isAed,
            ProjectScopeAuditAreaSCOTABDId: cs.ProjectScopeAuditAreaSCOTABDId
          };
        });
      const uniqueScotabds = new Map(payload.map(item => [item['SCOTABDId'], item])).values();
      await formServices.addProjectScopeAuditAreaSCOTABD(projectId,
        selectedProjectScopeAuditAreaId,
        projectFormId,
        [
          ...uniqueScotabds,
          ...payloadCustom,
        ]
      );

      queryClient.invalidateQueries(["projectScopeForm"]);
      queryClient.invalidateQueries(["all", "projectScopeRisks"]);
    } catch (error) {
      // Catch error but don't show to user
    } finally {
      await fetchData();
      setLoading(false);
      setVisible(false);
      setConcurrencyEventReceived(false)
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (methodologyVersionId) {
      const init = async () => {
        const accountBalances = await formServices.getAccountBalancesByMethodologyVersion(
          methodologyVersionId, true
        );
        if (accountBalances.status === 200)
          setAccountBalances(accountBalances.data.sort((a, b) => a.DisplayOrder - b.DisplayOrder));
        const assertions = await projectFormServices.getAssertionsByMethodologyVersion(
          methodologyVersionId, true
        );
        if (assertions.status === 200)
          setAssertions(assertions.data.sort((a, b) => a.DisplayOrder - b.DisplayOrder));
      };
      init();
    }
  }, [methodologyVersionId]);

  useEffect(() => {
    if (allScotabds.length && selectedAuditAreaId) {
      setFilteredScotabds(
        allScotabds.filter((s) => {
          const mapped = s.AuditAreas.map((aa) => aa.AuditAreaId);
          return mapped.some((m) => m === selectedAuditAreaId);
        }).filter((s) => 
        {
          const filteredIndustries = methodologyIndustries.map(mi => {
            const industryId = s.MethodologyIndustries.map((industry) => industry.MethodologyIndustryId);
            return industryId.some((id) => id === mi);
          });
          return filteredIndustries.some(mi => mi);
        })
      );
    }
  }, [allScotabds, selectedAuditAreaId]);

  useEffect(() => {
    if (selectedProjectScopeAuditAreaId) {
      _setDefaultScotabds(
        defaultScotabds.filter(
          (ds) => ds.ProjectScopeAuditAreaId === selectedProjectScopeAuditAreaId
        )
      );
    }
  }, [defaultScotabds, selectedProjectScopeAuditAreaId]);

  return (
    <CLASimpleDrawer
      anchor="right"
      isVisible={visible}
      onClose={() => setVisible(false)}
      headerTitle="Significant Classes of Transactions, Account Balances and Disclosure"
    >
      <Grid container justifyContent="flex-end" p={3}>
        <Grid item>
          <Stack direction="row" spacing={3}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={
                customScotabds.some(
                  (cs) =>
                    cs.enabled && (!cs.name || !cs.accountBalances.length || !cs.assertions.length)
                )
              }              
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <SCOTABDsTable
        accountBalances={accountBalances}
        assertions={assertions}
        defaultScotabds={_defaultScotabds}
        filteredScotabds={filteredScotabds}
        onChange={(selectedScotabds, customScotabds) => {
          setSelectedScotabds(selectedScotabds);
          setCustomScotabds(customScotabds);
        }}
      />
    </CLASimpleDrawer>
  );
};
