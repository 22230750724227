import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import {
    Button,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel
} from '@mui/material';

import { DIALOG } from '@constants/forms';
import {CLANumberField} from '@ais/forms';
import RowBuilderRow from './RowBuilderRow';
import styles from './RowBuilder.module.css';



const RowBuilder = () => {
    const { control, setValue } = useFormContext();
    const watchDisableRowLabel = useWatch({ control, name: 'disableRowLabel' }) ?? '';
    const watchRows = useWatch({ control, name: 'rows' }) ?? [];
    const [rows, setRows] = useState([]);
    const [addrows, setAddRows] = useState();
    const [disableRowLabel, setDisableRowLabel] = useState(false);

    const generateNewRow = () => ({
        id: uuidv4(),
        rowLabel: '',
        rowIndex: '',
    });

    const handleOK = () => {
        setRows((prevState) => {
            const copy = [...prevState];
            for (let i = 1; i <= addrows; i++) {
                const newColumn = generateNewRow();
                copy.push(newColumn);
            }
            return copy;
        });
        setAddRows(0);
    };

    const handleDisableLabelCheckbox = (e) => {
        if (e.target.checked === true) {
            setRows([generateNewRow()]);
        }
        setDisableRowLabel(e.target.checked)
        setValue('disableRowLabel', e.target.checked);
    };

    const deleteRow = (id) => {
        if (rows.length > 1) {
            const filtered = rows.filter((row) => row.id !== id);
            setRows([...filtered]);
        } else setRows([generateNewRow()]);
    };

    const handleRowChange = (id, updatedValue) => {
        const updatedValues = rows.map((item) => {
            return item.id === id ? updatedValue : item;
        });
        setRows(updatedValues);
    };

    useEffect(() => {
        if (watchDisableRowLabel) {
            setDisableRowLabel(true);
        }
        if (watchRows.length > 0) {
            setRows(watchRows);
        } else {
            setRows([generateNewRow()]);
        }
    }, []);

    useEffect(() => {
        setValue('rows', rows);
    }, [rows]);

    return <>
        <div className={styles['rb-main-wrapper']}>
            <div className={styles['rb-main-header']}>
                <div>
                    <FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={disableRowLabel}
                                    onChange={(e) => handleDisableLabelCheckbox(e)}
                                />
                            }
                            label={DIALOG.ROW_BUILDER.DISABLE_ROW_LABEL}
                            sx={{
                                marginBottom: '8px',
                            }}
                        />
                    </FormControl>
                </div>
                <div className={styles['rb-header-message']}>
                    {DIALOG.ROW_BUILDER.HEADER_MESSAGE}
                </div>
            </div>
            <div className={styles['rb-main']}>
                <div className={styles['rb-main-content']}>
                    <div className={styles['rb-rows']}>
                        {rows.map((row) => (
                            <RowBuilderRow
                                key={row.id}
                                id={row.id}
                                row={row}
                                onRowChange={handleRowChange}
                                onRowDelete={deleteRow}
                                disableRowLabel={disableRowLabel}
                            />
                        ))}
                    </div>
                </div>
                <div className={styles['rb-addrow-wrapper']}>
                    <div className={styles['rb-addrow-textfield']}>
                        <FormControl>
                            <InputLabel shrink>{DIALOG.ROW_BUILDER.ADD_ROWS}</InputLabel>
                            <CLANumberField
                                label={DIALOG.ROW_BUILDER.ADD_ROWS}
                                placeholder={DIALOG.ROW_BUILDER.ENTER_NUMBER_OF_ROWS}
                                defaultValue={addrows}
                                onChange={(e) => {
                                    setAddRows(e.target.value);
                                }}
                                notched
                                disabled={disableRowLabel}
                                style={{ height: '10px' }}
                            />
                        </FormControl>
                    </div>
                    <div className={styles['rb-addrow-okbutton']}>
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={handleOK}
                            sx={{ width: '10%', height: '100%' }}
                            disabled={disableRowLabel}
                        >
                            {DIALOG.ROW_BUILDER.OK_BUTTON}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </>
}


export default RowBuilder;