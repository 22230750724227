import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import {CLASimpleDrawer} from "@ais/components";
import styles from "../CLAEvaluationOfDeficiencies.module.css";
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import {
    Button,
    TextField
} from '@mui/material';

import { usePostProjectDeficiencyCombination } from '@services/deficiencyCombination';

import { useRoomIdle } from "@components/Concurrency/provider/RoomProvider";
import CLATextField from '@components/Forms/CLATextField/CLATextField';
import {CLASelect} from '@ais/forms';

import { getDeficiencyNames, validateDrawerFields } from '../helpers';

export const AddCombinationDrawer = (props) => {
    const { EVALUATION_OF_DEFICIENCIES } = DEFICIENCY_FORM_INSTANCE.EN;
    const { rows, showDrawer, setShowDrawer, projectId } = props;
    const [combinationName, setCombinationName] = useState('');
    const [combinationDescription, setCombinationDescription] = useState('');
    const [selectedDeficiencies, setSelectedDeficiencies] = useState([]);
    const [deficiencyNameExist, setDeficiencyNameExist] = useState({
        isExist: false,
        message: ''
    });

    const { projectFormId } = useParams();
    const isIdle = useRoomIdle()
    const {
        mutate: postDeficiencyCombinationMutation
    } = usePostProjectDeficiencyCombination();
    useEffect(() => {
        clearFields()
    }, [isIdle])
    const apiRequest = {
        Add: (reqBody, options) => postDeficiencyCombinationMutation({ reqBody: reqBody, projectId: projectId }, options)
    };

    const clearFields = () => {
        setCombinationName('');
        setCombinationDescription('');
        setSelectedDeficiencies([]);
    };

    const saveDeficiencyCombination = async (fieldValues) => {
        const requestBody = {
            ProjectId: projectId,
            CombinationName: fieldValues.combinationName,
            CombinationDescription: fieldValues.combinationDescription,
            IsMagnitudeMaterial: false,
            IsOccurencePossible: false,
            IsPrudentOfficial: false,
            IsMeritsGovernance: false,
            IsCommunicatedGovernance: false,
            IsCommunicatedManagement: false,
            IsCommunicationOral: false,
            IsCommunicationWritten: false,
            IsMeritsAttentionOfManagement: false,
            Deficiencies: fieldValues.selectedDeficiencies.map((id) => { return { ProjectDeficiencyId: id } }),
            RequestProjectFormId: projectFormId
        };

        const options = {
            onError: (error) => {
                const errResponse = error?.response;
                if (errResponse?.status === 409) {
                    setDeficiencyNameExist({ isExist: true, message: 'Deficiency Combination Name must be unique.' });
                } else {
                    setDeficiencyNameExist({ isExist: false, message: '' });
                    toast.error(error.toString());
                }
            },
            onSuccess: () => {
                setDeficiencyNameExist({ isExist: false, message: '' });
                setShowDrawer(false);
                clearFields();
            }
        }

        await apiRequest['Add'](requestBody, options)
    }

    return (<>
        <CLASimpleDrawer
            anchor="right"
            isVisible={showDrawer}
            headerTitle={EVALUATION_OF_DEFICIENCIES.DRAWER.DEFICIENCY_COMBINATION}
            onClose={() => { setShowDrawer(false); clearFields(); setDeficiencyNameExist({ isExist: false, message: '' }); }}
            width={'40%'}
        >
            <div className={styles["add-combination-drawer-wrapper"]}>
                <div className={styles["add-combination-container"]}>
                    <div className={styles["add-combination-buttons"]}>
                        <Button variant="outlined" disableElevation onClick={() => { setShowDrawer(false); clearFields();  setDeficiencyNameExist({ isExist: false, message: '' });}}>
                            Cancel
                        </Button>
                    </div>
                    <div className={styles["add-combination-buttons"]}>
                        <Button
                            disabled={validateDrawerFields({ combinationName, combinationDescription, selectedDeficiencies })}
                            variant="contained"
                            disableElevation
                            onClick={() => { saveDeficiencyCombination({ combinationName, combinationDescription, selectedDeficiencies }); }}
                        >
                            Save
                        </Button>
                    </div>
                </div>

            </div>
            <div className={styles["add-combination-drawer-content-wrapper"]}>
                <div className={styles["add-combination-drawer-content-field"]}>
                    <CLATextField
                        label={EVALUATION_OF_DEFICIENCIES.DRAWER.COMBINATION_NAME}
                        onChange={(e) => { setCombinationName(e.target.value) }}
                        value={combinationName}
                        inputLableShrink={true}
                        error={deficiencyNameExist.isExist}
                        helperText={deficiencyNameExist.message}
                        placeholder={EVALUATION_OF_DEFICIENCIES.DRAWER.COMBINATION_NAME_PLACEHOLDER} />
                </div>
                <div className={styles["add-combination-drawer-content-field"]}>
                    <TextField
                        label={EVALUATION_OF_DEFICIENCIES.DRAWER.COMBINATION_DESCRIPTION}
                        fullWidth
                        multiline
                        placeholder={EVALUATION_OF_DEFICIENCIES.DRAWER.COMBINATION_DESCRIPTION_PLACEHOLDER}
                        rows={4}
                        value={combinationDescription}
                        onChange={(e) => { setCombinationDescription(e.target.value) }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            notched: true
                        }}
                        inputProps={{
                            maxLength: 4096
                        }}
                    />
                </div>
                <div className={styles["add-combination-drawer-content-field"]}>
                    <CLASelect
                        id={'projectScopeDeficiencySummary'}
                        name={'projectScopeDeficiencySummary'}
                        label={EVALUATION_OF_DEFICIENCIES.DRAWER.DEFICIENCIES}
                        placeholder={EVALUATION_OF_DEFICIENCIES.DRAWER.DEFICIENCIES_PLACEHOLDER}
                        isDisabled={false}
                        truncateDisplay={true}
                        menuItems={getDeficiencyNames(rows)}
                        onChange={(values) => { setSelectedDeficiencies(values) }}
                        error={false}
                        helperText={''}
                    />
                </div>
            </div>
        </CLASimpleDrawer></>)
}