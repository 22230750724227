import formApi from "@utilities/claApiForm";

const getprojectScopesKeyControls = async (projectId, riskCharacteristicId, projectScopeId) => {
    try {
        const response = await formApi.get(`/projectscopes/${projectId}/${riskCharacteristicId}/keycontrols?projectScopeId=${projectScopeId}`);
        return { status: 200, response };
    }
    catch (error) {
        return { status: 400, message: error };
    }
}

const projectScopesKeyControls = {
    getprojectScopesKeyControls
};

export default projectScopesKeyControls;