import { useContext } from 'react'
import { KPIContext } from "@contexts/KPI/KPIContext";
export const useKPI = () => {
  const {
    answerList,
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    setAnswerList,
  } = useContext(KPIContext);

  const updateFieldAnswer = (questionId, answer) => {
    setAnswerList((prev) => ({ ...prev, [questionId]: answer }));
  }

  return {
    answerList,
    updateFieldAnswer,
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    setAnswerList,
  }
}