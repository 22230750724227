import { useState, useEffect } from 'react';
import { Grid, InputLabel, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { Form } from "@ais/palette"
import { Controller, useForm, useWatch, useFieldArray } from "react-hook-form"
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CLARadioButtonList, CLASelect, CLADropdown } from '@ais/forms';
import fraudRiskTypeServices from '@services/fraudRiskTypes';
import { useGetRiskAssessmentOptions } from '@services/riskassessment';
import { useAuditAreas } from '@services/auditArea';
import { useAssertions } from '@services/assertions';
import { useGetProjectById } from '@services/project';
import { usePostProjectRisk, usePutProjectRisk } from '@services/projectRisk';

import styles from '../FormView.module.css';
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup"
import * as Constants from '@constants/customForm/index'
import CLATextField from "@components/Forms/CLATextField/CLATextField"
import { useParams } from "react-router-dom";
import { useLoading } from '@hooks/index';
import { AddAuditArea } from '@components/FormView/actions/components/AddAuditArea/AddAuditArea';
import CLATruncatedTextWithTooltip from "@components/CLATruncatedTextWithTooltip/CLATruncatedTextWithTooltip";
import { makeStyles } from '@mui/styles';

export const AddRisk = ({
	section,
	visible,
	setVisible,
	formSectionId = null,
	formName,
	riskData,
	isEdit = false,
	showWorkPaperReference = true,
	onSuccessfulSave
}) => {
	const setLoading = useLoading()
	const [buttonLoading, setButtonLoading] = useState(false);
	const [methodologyVersionId, setMethodologyVersionId] = useState(null);
	const [fraudRiskTypeOptions, setFraudRiskTypeOptions] = useState([]);
	const [auditAreaOptions, setAuditAreaOptions] = useState([]);
	const [assertionsOptions, setAssertionsOptions] = useState([])
	const [riskAssessmentOptions, setRiskAssessmentOptions] = useState([])
	const [riskNameExist, setRiskNameExist] = useState({
		isExist: false,
		message: ''
	});

	const { projectId, projectFormId } = useParams()

	const { EN, API_REQUEST_ACTION_TYPE } = Constants.ADD_RISK

	const stringRequiredSchema = Yup.string().trim().required()

	const addRiskInitialValues = {
		riskAssessment: [],
		riskName: '',
		riskDescription: '',
		riskLikelihood: '',
		riskMagnitude: '',
		riskRelation: '',
		fraudRiskType: '',
		conditionsDescription: '',
		isRiskPervasive: '',
		assertionLevelDescription: '',
		assertions: assertionsOptions || [],
		isRiskRelatedToEstimate: '',
		workpaperReference: '',
		auditAreaList: [{
			projectRiskAuditAreaId: null,
			auditAreaId: '',
			auditAreaName: '',
			scotabd: '',
			assertions: [{
				AssertionId: '',
				ProjectRiskAuditAreaAssertionId: null,
			}]
		}]
	}

	const editRiskInitialValues = {
		riskAssessment: riskData?.ProjectScopeRiskSummaries?.map((riskAssessment) => riskAssessment.ProjectScopeId) ?? [],
		riskName: riskData && riskData.riskName ? riskData.riskName : '',
		riskDescription: riskData && riskData.riskDescription ? riskData.riskDescription : '',
		riskLikelihood: riskData && riskData.IsRiskMoreThanRemote === null ? '' : (riskData && riskData.IsRiskMoreThanRemote ? 'Yes' : 'No'),
		riskMagnitude: riskData && riskData.IsMagnitudeMoreThanMaterial === null ? '' : (riskData && riskData.IsMagnitudeMoreThanMaterial ? 'Yes' : 'No'),
		riskRelation: riskData && riskData.IsRelatedToErrorOrFraud === null ? '' : (riskData && riskData.IsRelatedToErrorOrFraud ? 'Error' : 'Fraud'),
		fraudRiskType: riskData?.FraudRiskTypes?.map(({ FraudRiskTypeId }) => FraudRiskTypeId).shift() ?? '',
		conditionsDescription: riskData && riskData.ErrorConditionDescription ? riskData.ErrorConditionDescription : '',
		isRiskPervasive: riskData && riskData.IsPervasive === null ? '' : (riskData && riskData.IsPervasive ? 'Yes' : 'No'),
		assertionLevelDescription: riskData && riskData.AssertionLevelDescription ? riskData.AssertionLevelDescription : '',
		assertions: assertionsOptions || [],
		isRiskRelatedToEstimate: riskData && riskData.IsRelatedToAccountingEstimate === null ? '' : (riskData && riskData.IsRelatedToAccountingEstimate ? 'Yes' : 'No'),
		workpaperReference: riskData && riskData.WorkpaperReference ? riskData.WorkpaperReference : '',
		auditAreaList: riskData?.AuditAreas?.map((item) => ({
			projectRiskAuditAreaId: item?.ProjectRiskAuditAreaId ?? null,
			auditAreaId: item?.ProjectScopeAuditAreaId ?? "",
			auditAreaName: item?.AuditAreaName ?? "",
			scotabd: item?.SCOTABD.map((scotabd) => scotabd.ProjectScopeAuditAreaSCOTABDId)[0] ?? '',
			assertions: riskData?.Assertions?.filter(assertion => assertion.ProjectScopeAuditAreaId === item.ProjectScopeAuditAreaId
				&& assertion.ProjectRiskAuditAreaId === item.ProjectRiskAuditAreaId)?.map((value) => {
					return {
						AssertionId: value.AssertionId,
						ProjectRiskAuditAreaAssertionId: value.ProjectRiskAuditAreaAssertionId
					}
				}) ?? [],
		})) ?? [{
			projectRiskAuditAreaId: null,
			auditAreaId: '',
			auditAreaName: '',
			scotabd: '',
			assertions: [{
				AssertionId: '',
				ProjectRiskAuditAreaAssertionId: null,
			}]
		}],
	}

	const hasDefaultRisk = !!riskData?.DefaultRiskId

	const {
		data: projectData,
		isLoading: isLoadingProjectData
	} = useGetProjectById(projectId);

	const {
		mutate: postProjectRiskMutation
	} = usePostProjectRisk(projectId);

	const {
		mutate: putProjectRiskMutation
	} = usePutProjectRisk(projectId, riskData?.ProjectRiskId);

	const schemaValidation = Yup.object().shape({
		riskAssessment: Yup.array().min(1),
		riskName: stringRequiredSchema,
		workpaperReference: showWorkPaperReference && stringRequiredSchema,
		riskDescription: stringRequiredSchema,
		riskLikelihood: stringRequiredSchema,
		isRiskPervasive: Yup.string()
			.when(['fraudRiskType', 'conditionsDescription'], {
				is: (fraudRiskType, conditionsDescription) => fraudRiskType?.length || conditionsDescription,
				then: stringRequiredSchema
			}),
		isRiskRelatedToEstimate: Yup.string()
			.when(['auditAreaList', 'assertionLevelDescription'], {
				is: (auditAreaList, assertionLevelDescription) => auditAreaList?.length > 0 && assertionLevelDescription,
				then: stringRequiredSchema
			}),
		riskMagnitude: Yup.string()
			.when('riskLikelihood', {
				is: EN.YES,
				then: stringRequiredSchema
			}),
		riskRelation: Yup.string()
			.when('riskMagnitude', {
				is: EN.YES,
				then: stringRequiredSchema
			}),
		assertionLevelDescription: Yup.string()
			.when('isRiskPervasive', {
				is: EN.NO,
				then: stringRequiredSchema
			}),
		conditionsDescription: Yup.string()
			.when('riskRelation', {
				is: EN.ERROR,
				then: stringRequiredSchema
			}),
		fraudRiskType: Yup.string()
			.when('riskRelation', {
				is: EN.FRAUD,
				then: stringRequiredSchema
			}),
		auditAreaList: Yup.array()
			.when('assertionLevelDescription', {
				is: (assertionLevelDescription) => assertionLevelDescription?.length >= 1,
				then: Yup.array().of(
					Yup.object({
						scotabd: stringRequiredSchema,
						assertions: Yup.array().min(1)
					})
				)
			})
	})

	const hasDefaultRiskSchemaValidation = Yup.object().shape({
		riskDescription: stringRequiredSchema,
		assertionLevelDescription: Yup.string()
			.when('isRiskPervasive', {
				is: EN.NO,
				then: stringRequiredSchema
			}),
		conditionsDescription: Yup.string()
			.when('riskRelation', {
				is: EN.ERROR,
				then: stringRequiredSchema
			}),
	})

	const defaultValues = isEdit ? editRiskInitialValues : addRiskInitialValues;
	const formMethods = useForm({
		values: defaultValues,
		resolver: yupResolver(hasDefaultRisk ? hasDefaultRiskSchemaValidation : schemaValidation),
	});
	const { isValid, isDirty, errors, isSubmitting } = formMethods.formState;
	const { fields, update, append, remove } = useFieldArray({
		control: formMethods.control,
		name: "auditAreaList",
	});

	const watchRiskAssessment = useWatch({ control: formMethods.control, name: "riskAssessment" });
	const watchRiskLikelihood = useWatch({ control: formMethods.control, name: "riskLikelihood" });
	const watchRiskMagnitude = useWatch({ control: formMethods.control, name: "riskMagnitude" });
	const watchRiskRelation = useWatch({ control: formMethods.control, name: "riskRelation" });
	const watchFraudRiskType = useWatch({ control: formMethods.control, name: "fraudRiskType" });
	const watchConditionsDescription = useWatch({ control: formMethods.control, name: "conditionsDescription" });
	const watchIsRiskPervasive = useWatch({ control: formMethods.control, name: "isRiskPervasive" });
	const watchAssertionLevelDescription = useWatch({ control: formMethods.control, name: "assertionLevelDescription" });
	const watchIsRiskRelatedToEstimate = useWatch({ control: formMethods.control, name: "isRiskRelatedToEstimate" });
	const watchAccountingEstimate = useWatch({ control: formMethods.control, name: "auditAreaList" });

	/** Condition for displaying isRiskRelatedToEstimate field */
	const conditionalWatchAccountingEstimate = watchAccountingEstimate.filter((data) => data.assertions.length && data.scotabd)

	const apiRequest = {
		Add: (reqBody, options) => postProjectRiskMutation(reqBody, options),
		Update: (reqBody, options) => putProjectRiskMutation(reqBody, options)
	}

	const executeRequest = async (actionType, reqBody) => {
		setLoading(true);
		setButtonLoading(true);

		const options = {
			onError: (error) => {
				const errResponse = error?.response;
				if (errResponse?.status === 409) {
					setRiskNameExist({
						isExist: true,
						message: errResponse?.data.message
					})
				} else {
					setRiskNameExist({
						isExist: false,
						message: ''
					})

					toast.error(error.message.toString());
					setButtonLoading(false);
					setVisible(false);
				}
			},
			onSettled: (_, error) => {
				const errResponse = error?.response;
				if (errResponse?.status === 409) {
					setButtonLoading(false)
				} else {
					formMethods.reset(defaultValues);
				}

				setLoading(false);
			},
			onSuccess: () => {
				setLoading(isSubmitting);
				setRiskNameExist({
					isExist: false,
					message: ''
				})

				setButtonLoading(false)
				setVisible(false);
				onSuccessfulSave && onSuccessfulSave();
			}
		}

		if (reqBody.length === 0) return
		await apiRequest[actionType](reqBody, options)
	}

	const setResetRMMValues = (newValues) => {
		if (!isEdit || newValues.AuditAreas.length === 0 || editRiskInitialValues.auditAreaList.length === 0) return;

		for (let auditArea of newValues.AuditAreas) {
			const existingValue = editRiskInitialValues.auditAreaList.find(value => value.auditAreaId === auditArea.ProjectScopeAuditAreaId);

			if (!existingValue || +auditArea.ProjectScopeAuditAreaSCOTABDId !== existingValue.scotabd) {
				newValues.ResetRMMValues = true;
				return;
			}
		}
	}

	const onSubmit = async (values) => {
		const {
			riskAssessment,
			workpaperReference,
			riskName,
			riskDescription,
			riskLikelihood,
			riskMagnitude,
			riskRelation,
			fraudRiskType,
			conditionsDescription,
			assertionLevelDescription,
			auditAreaList,
			isRiskPervasive,
			isRiskRelatedToEstimate,
		} = values;

		const reqBody = {
			ProjectId: projectId,
			FormSectionId: formSectionId,
			ProjectFormId: (projectFormId ?? riskData?.ProjectFormId) || null,
			ProjectRiskId: riskData?.ProjectRiskId || null,
			RiskName: riskName,
			RiskDescription: riskDescription,
			IsRiskMoreThanRemote: !!riskLikelihood.length ? (riskLikelihood === EN.YES) : null,
			IsMagnitudeMoreThanMaterial: !!riskMagnitude.length ? (riskMagnitude === EN.YES) : null,
			IsRelatedToErrorOrFraud: !!riskRelation.length ? (riskRelation === EN.ERROR) : null,
			FraudRiskTypes: fraudRiskType ? [{
					FraudRiskTypeId: fraudRiskType,
					ProjectRiskFraudRiskTypeId: riskData?.FraudRiskTypes?.map(({ ProjectRiskFraudRiskTypeId }) => ProjectRiskFraudRiskTypeId).shift() ?? null
				}] : [],
			ErrorConditionDescription: conditionsDescription || '',
			IsPervasive: !!isRiskPervasive.length ? (isRiskPervasive === EN.YES) : null,
			AssertionLevelDescription: assertionLevelDescription,
			IsRelatedToAccountingEstimate: !!isRiskRelatedToEstimate.length ? (isRiskRelatedToEstimate === EN.YES) : null,
			WorkpaperReference: workpaperReference || '',
			Comment: riskData?.Comment ?? '',
			NatureAndExtentOfPervasiveEffect: null,
			PlannedOverallResponse: null,
			ProjectScopeRiskSummaries: riskAssessment.map((riskSummaryId) => { return { ProjectScopeId: riskSummaryId } }),
			AuditAreas: !!assertionLevelDescription ? auditAreaList.map((item) => {
				const auditAreaPUTRequestBody = {
					ProjectRiskAuditAreaId: item?.projectRiskAuditAreaId ?? null,
					ProjectScopeAuditAreaId: Array.isArray(item.auditAreaId) ? item.auditAreaId[0]?.toString() : item.auditAreaId,
					ProjectScopeAuditAreaSCOTABDId: item.scotabd,
					ProjectRiskAuditAreaAssertions: item.assertions.map((assertion) => {
						return {
							ProjectRiskAuditAreaAssertionId: assertion.ProjectRiskAuditAreaAssertionId,
							AssertionId: assertion.AssertionId
						}
					})
				}
				const auditAreaPOSTRequestBody = {
					ProjectScopeAuditAreaId: Array.isArray(item.auditAreaId) ? item.auditAreaId[0]?.toString() : item.auditAreaId,
					ProjectScopeAuditAreaSCOTABDId: item.scotabd,
					ProjectRiskAuditAreaAssertionIds: item.assertions.map(item => item.AssertionId)
				}
				return isEdit ? auditAreaPUTRequestBody : auditAreaPOSTRequestBody;
			}) : [],
			HasDefaultRisk: hasDefaultRisk
		}

		setResetRMMValues(reqBody);
		const actionType = isEdit ? API_REQUEST_ACTION_TYPE.UPDATE : API_REQUEST_ACTION_TYPE.ADD;
		await executeRequest(actionType, reqBody);
	}

	const generateAuditArea = () => {
		const newAuditArea = {
			auditAreaId: '',
			auditAreaName: '',
			scotabd: '',
			projectRiskAuditAreaId: null,
			assertions: [{
				AssertionId: '',
				ProjectRiskAuditAreaAssertionId: null,
			}],
		}
		return newAuditArea;
	}

	const resetAuditAreaList = () => {
		remove()
		append(generateAuditArea());
	}

	const handleRiskLikelihoodChange = (value) => {
		formMethods.setValue("riskLikelihood", value);
		formMethods.setValue("riskMagnitude", addRiskInitialValues.riskMagnitude);
		formMethods.setValue("riskRelation", addRiskInitialValues.riskRelation);
		formMethods.setValue("fraudRiskType", addRiskInitialValues.fraudRiskType);
		formMethods.setValue("conditionsDescription", addRiskInitialValues.conditionsDescription);
		formMethods.setValue("isRiskPervasive", addRiskInitialValues.isRiskPervasive);
		formMethods.setValue("isRiskRelatedToEstimate", addRiskInitialValues.isRiskRelatedToEstimate);
		formMethods.setValue("assertionLevelDescription", addRiskInitialValues.assertionLevelDescription);
		resetAuditAreaList();
	}
	const handleRiskMagnitudeChange = (value) => {
		formMethods.setValue("riskMagnitude", value);
		formMethods.setValue("riskRelation", addRiskInitialValues.riskRelation);
		formMethods.setValue("fraudRiskType", addRiskInitialValues.fraudRiskType);
		formMethods.setValue("conditionsDescription", addRiskInitialValues.conditionsDescription);
		formMethods.setValue("isRiskPervasive", addRiskInitialValues.isRiskPervasive);
		formMethods.setValue("isRiskRelatedToEstimate", addRiskInitialValues.isRiskRelatedToEstimate);
		formMethods.setValue("assertionLevelDescription", addRiskInitialValues.assertionLevelDescription);
		resetAuditAreaList();
	}
	const handleRiskRelationChange = (value) => {
		formMethods.setValue("riskRelation", value);
		formMethods.setValue("fraudRiskType", addRiskInitialValues.fraudRiskType);
		formMethods.setValue("conditionsDescription", addRiskInitialValues.conditionsDescription);
		formMethods.setValue("isRiskPervasive", addRiskInitialValues.isRiskPervasive);
		formMethods.setValue("isRiskRelatedToEstimate", addRiskInitialValues.isRiskRelatedToEstimate);
		formMethods.setValue("assertionLevelDescription", addRiskInitialValues.assertionLevelDescription);
		resetAuditAreaList();
	}

	const handleFraudRiskTypeChange = (event) => {
		formMethods.setValue("fraudRiskType", event.target.value);
		formMethods.setValue("conditionsDescription", addRiskInitialValues.conditionsDescription);
		formMethods.setValue("isRiskPervasive", addRiskInitialValues.isRiskPervasive);
		formMethods.setValue("isRiskRelatedToEstimate", addRiskInitialValues.isRiskRelatedToEstimate);
		formMethods.setValue("assertionLevelDescription", addRiskInitialValues.assertionLevelDescription);
		resetAuditAreaList();
	}

	const handleIsRiskPervasiveChange = (value) => {
		formMethods.setValue("isRiskPervasive", value);
		formMethods.setValue("isRiskRelatedToEstimate", addRiskInitialValues.isRiskRelatedToEstimate);
		formMethods.setValue("assertionLevelDescription", addRiskInitialValues.assertionLevelDescription);
		resetAuditAreaList();
	}

	const handleRiskAssessmentChange = (riskAssessments) => {
		formMethods.setValue("riskAssessment", riskAssessments);
		formMethods.setValue("isRiskRelatedToEstimate", addRiskInitialValues.isRiskRelatedToEstimate);
		resetAuditAreaList();
	}

	const onAuditAreaChange = (event, index) => {
		update(index, {
			auditAreaId: event.target.value,
			scotabd: "",
			assertions: [],
			projectRiskAuditAreaId: null
		});
	}

	const onScotabdChange = (event, index) => {
		update(index, {
			auditAreaId: fields[index].auditAreaId,
			scotabd: event.target.value,
			assertions: []
		});
	}

	const onAssertionsChange = (assertions, clonedAssertionIds, index) => {
		const projectRiskAuditAreaId = clonedAssertionIds[0]?.projectRiskAuditAreaId ?? null
		const clonedAssertions = assertions.filter(assertion => assertion !== 0).map((value) => {
			return {
				AssertionId: value,
				ProjectRiskAuditAreaAssertionId: clonedAssertionIds.filter(item => item.AssertionId === value).map(riskAssertion => riskAssertion.ProjectRiskAuditAreaAssertionId)[0] ?? null,
			}
		});


		update(index, {
			auditAreaId: fields[index].auditAreaId,
			scotabd: fields[index].scotabd,
			assertions: clonedAssertions,
			projectRiskAuditAreaId
		});
	}

	const handleOnCancel = () => {
		setVisible(false)
		formMethods.reset(defaultValues);
		setRiskNameExist({
			isExist: false,
			errMsg: ''
		})
	}

	const handleConditionsChange = (event) => {
		formMethods.setValue("conditionsDescription", event.target.value);
		if (!event.target.value && !hasDefaultRisk) {
			formMethods.setValue("isRiskPervasive", addRiskInitialValues.isRiskPervasive);
			formMethods.setValue("isRiskRelatedToEstimate", addRiskInitialValues.isRiskRelatedToEstimate);
			formMethods.setValue("assertionLevelDescription", addRiskInitialValues.assertionLevelDescription);
			resetAuditAreaList()
		}
	}

	const handleAssertionLevelChange = (event) => {
		formMethods.setValue("assertionLevelDescription", event.target.value);
		if (!event.target.value && !hasDefaultRisk) {
			resetAuditAreaList();
		}
	}

	const getFraudRiskTypes = async () => {
		try {
			const { data } = await fraudRiskTypeServices.getFraudRiskTypes();
			const fraudRiskTypeOptions = data.map(fraudRisk => {
				return { value: fraudRisk.FraudRiskTypeId, label: fraudRisk.FraudRiskTypeName }
			});
			return fraudRiskTypeOptions;
		} catch (error) {
			toast.error(error.toString());
			return [];
		}
	};

	const loadFraudRiskTypes = async () => {
		setLoading(true);
		const result = await getFraudRiskTypes();
		setFraudRiskTypeOptions(result);
		setLoading(false);
	};
	const currentProjectFormId = (riskData?.ProjectFormId ?? projectFormId) ?? 0
	const isAddedRiskFromProjectForm = !!currentProjectFormId;
	const { data: riskAssessmentResult, isFetching: isRiskAssessmentFetching } = useGetRiskAssessmentOptions(projectId, currentProjectFormId, isAddedRiskFromProjectForm);
	const { data: auditAreaResult, isLoading: isLoadingAuditArea, isFetching: isAuditAreaFetching } = useAuditAreas(projectId, watchRiskAssessment);
	const { data: assertionResult, isLoading: isLoadingAssertion } = useAssertions(methodologyVersionId, true);

	const handleAddAuditArea = () => {
		append(generateAuditArea());
	};

	const handleDeleteAuditArea = (indexToRemove) => {
		remove(indexToRemove);

    	const filteredFields = fields.filter((data, index) => indexToRemove !== index && data.assertions.length && data.scotabd)

		if(filteredFields.length <= 0) {
			formMethods.setValue("isRiskRelatedToEstimate", null);
		}
	}

	const createRiskAssessmentOptions = (riskAssessmentData) => {
		const result = riskAssessmentData.map((value) => ({
			value: value.ProjectScopeId,
			label: value.ProjectFormUnits
		}))
		result.sort((a, b) => (a.label > b.label) ? 1 : -1)
		return result
	}

	const createAuditAreaOptions = (auditAreaData) => {
		return auditAreaData?.map((value) => ({
			value: value.ProjectScopeAuditAreaId,
			label: value.AuditAreaName,
		}));
	}

	const createAssertionOptions = (assertionData) => {
		return assertionData.map((value) => ({
			value: value.AssertionId,
			label: value.AssertionName
		}));
	}

	const useStyles = makeStyles(() => ({
		noMinWidth: {
			minWidth: "0 !important"
		}
	}));

	const customClass = useStyles();

	useEffect(() => {
		loadFraudRiskTypes()
	}, []);

	useEffect(() => {
		if (!projectData || projectData.status !== 200) return;
		setMethodologyVersionId(projectData.data.MethodologyVersionId)
	}, [isLoadingProjectData])

	useEffect(() => {
		formMethods.reset(defaultValues)
	}, [riskData])

	useEffect(() => {
		if (!riskAssessmentResult || riskAssessmentResult.status !== 200) return;
		setRiskAssessmentOptions(createRiskAssessmentOptions(riskAssessmentResult.data));
	}, [riskAssessmentResult]);

	useEffect(() => {
		if (isAuditAreaFetching) {
			setLoading(true)
		} else {
			setLoading(false)
		}
	}, [isAuditAreaFetching])

	useEffect(() => {
		if (isRiskAssessmentFetching) {
			setLoading(true)
		} else {
			setLoading(false)
		}
	}, [isRiskAssessmentFetching])

	useEffect(() => {
		if (!auditAreaResult || auditAreaResult.status !== 200) {
			setAuditAreaOptions([])
		};
		setAuditAreaOptions(createAuditAreaOptions(auditAreaResult?.data));
	}, [isLoadingAuditArea, watchRiskAssessment]);

	useEffect(() => {
		if (!assertionResult || assertionResult.status !== 200) return;
		setAssertionsOptions(createAssertionOptions(assertionResult.data));
	}, [isLoadingAssertion])

	useEffect(() => {
		formMethods.resetField('isRiskRelatedToEstimate', { defaultValue: addRiskInitialValues.isRiskRelatedToEstimate })
	}, [errors?.auditAreaList?.length])
	const setRiskFormValue = (key,value) => {
		update(key, value);
	}	

	const modalTitle = hasDefaultRisk ? EN.COMMON_IDENTIFIED_RISK : EN.RISK_IDENTIFICATION
	const showAuditArea = hasDefaultRisk || watchAssertionLevelDescription && !hasDefaultRisk
	const showRiskPervasive = hasDefaultRisk || (watchConditionsDescription || watchFraudRiskType)

	return (
		<Dialog open={visible} fullWidth scroll="paper">
			<Form form={formMethods} handleSubmit={onSubmit}>
				<div className={styles['add-risk-header-container']}>
					<DialogTitle>
						<Typography variant="title">{modalTitle}</Typography>
					</DialogTitle>
					{
						(!showWorkPaperReference) ?
							<div className={styles['add-risk-form-container']}>
								<div className={styles['form-info']}>
									<div className={styles['form-info-label']}>
										<InputLabel className={styles['name-input-label-title']}>{EN.FORM}:</InputLabel>
									</div>
									<div className={styles['form-info-value']}>
										<InputLabel className={styles['name-input-label']}>
											{(formName && formName.length <= 40) ? formName
												: <CLATruncatedTextWithTooltip
													text={formName}
													length={40}
												/>
											}
										</InputLabel>
									</div>
								</div>
								<div className={styles['form-info']}>
									<div className={styles['form-info-label']}>
										<InputLabel className={styles['section-input-label-title']}>{EN.SECTION}:</InputLabel>
									</div>
									<div className={styles['form-info-value']}>
										<InputLabel className={styles['section-input-label']}>
											{((section && section?.title && section?.title?.length <= 40
												|| riskData && riskData?.SectionName && riskData?.SectionName?.length <= 40))
												? (section?.title || riskData?.SectionName)
												: <CLATruncatedTextWithTooltip
													text={(section?.title || riskData?.SectionName)}
													length={40}
												/>
											}
										</InputLabel>
									</div>
								</div>
							</div> : <></>
					}
				</div>
				<DialogContent>
					<Grid container rowSpacing={4}>
						<Grid item xs={6}>
							<Controller
								control={formMethods.control}
								name="riskAssessment"
								render={() => {
									return (
										<CLASelect
											id={'riskAssessment'}
											name={'riskAssessment'}
											label={EN.RISK_ASSESSMENT}
											placeholder={EN.SELECT}
											isDisabled={hasDefaultRisk}
											truncateDisplay={true}
											menuItems={riskAssessmentOptions}
											onChange={handleRiskAssessmentChange}
											defaultValues={watchRiskAssessment}
											error={false}
											helperText={''}
										/>
									)
								}} />
						</Grid>
						{
							(showWorkPaperReference) &&
							<Grid item xs={12}>
								<Controller
									control={formMethods.control}
									name="workpaperReference"
									render={({ field: { onChange, value } }) => {
										return (
											<CLATextField
												id={'workpaperReference'}
												name={'workpaperReference'}
												label={EN.WORKPAPER_REFERENCE}
												placeholder={EN.WORKPAPER_REFERENCE_PLACEHOLDER}
												value={value}
												onChange={onChange}
												inputLableShrink={true}
											/>
										)
									}}
								/>
							</Grid>
						}
						<Grid item xs={12}>
							<Controller
								control={formMethods.control}
								name="riskName"
								render={({ field: { onChange, value } }) => {
									return (
										<CLATextField
											id={'riskName'}
											name={'riskName'}
											label={EN.RISK_NAME}
											onChange={onChange}
											value={value}
											placeholder={EN.RISK_NAME_HERE}
											error={riskNameExist.isExist}
											helperText={riskNameExist.message}
											inputLableShrink
											disabled={hasDefaultRisk}
										/>
									)
								}} />
						</Grid>

						<Grid item xs={12}>
							<Controller
								control={formMethods.control}
								name="riskDescription"
								render={({ field: { onChange, value } }) => {
									return (
										<TextField
											id={'riskDescription'}
											name={'riskDescription'}
											label={hasDefaultRisk ? EN.DESCRIBE_COMMON_IDENTIFIED_RISK : EN.DESCRIBE_RISK}
											onChange={onChange}
											value={value}
											multiline
											rows={3}
											placeholder={EN.RISK_DESCRIPTION}
											inputProps={{
												maxLength: 4096,
											}}
											fullWidth
											InputLabelProps={{
												shrink: true
											}}
										/>
									)
								}} />
						</Grid>
						<Grid item xs={12} sx={{
							display: 'flex',
							justifyContent: 'space-between'
						}}>
							<div className={styles['radio-container']}>
								<div className={styles['radio-label']}>
									<InputLabel
										sx={{
											'&.MuiInputLabel-root': {
												whiteSpace: 'normal'
											},
										}}
									>{EN.RISK_LIKELIHOOD}
									</InputLabel>
								</div>
								<div>
									<Controller
										control={formMethods.control}
										name="riskLikelihood"
										shouldUnregister={true}
										render={({ field: { value } }) => {
											return (
												<CLARadioButtonList
													id={'riskLikelihood'}
													value={value}
													defaultValue={watchRiskLikelihood}
													columns={2}
													customClass={customClass}
													options={EN.YES_OR_NO}
													isDisabled={hasDefaultRisk}
													onChange={handleRiskLikelihoodChange} />
											)
										}} />
								</div>
							</div>
							{(watchRiskLikelihood === EN.YES) ? (
								<div className={styles['radio-container']}>
									<div className={styles['radio-label']}>
										<InputLabel
											sx={{
												'&.MuiInputLabel-root': {
													whiteSpace: 'normal'
												},
											}}
										>{EN.RISK_MAGNITUDE}
										</InputLabel>
									</div>
									<div>
										<Controller
											control={formMethods.control}
											name="riskMagnitude"
											render={({ field: { value } }) => {
												return (
													<CLARadioButtonList
														id={'riskMagnitude'}
														value={value}
														defaultValue={watchRiskMagnitude}
														columns={2}
														customClass={customClass}
														options={EN.YES_OR_NO}
														isDisabled={hasDefaultRisk}
														onChange={handleRiskMagnitudeChange} />
												)
											}} />
									</div>
								</div>
							)
								: (
									<div className={styles['radio-container']}></div>
								)
							}
							{watchRiskMagnitude === EN.YES ? (
								<div className={styles['radio-container']}>
									<div className={styles['radio-label']}>
										<InputLabel
											sx={{
												'&.MuiInputLabel-root': {
													whiteSpace: 'normal'
												},
											}}
										>{EN.RISK_RELATION}
										</InputLabel>
									</div>
									<div className={styles['radio-options-full']}>
										<Controller
											control={formMethods.control}
											name="riskRelation"
											render={({ field: { value } }) => {
												return (
													<CLARadioButtonList
														id={'riskRelation'}
														value={value}
														defaultValue={watchRiskRelation}
														columns={2}
														customClass={customClass}
														options={EN.ERROR_OR_FRAUD}
														isDisabled={hasDefaultRisk}
														onChange={handleRiskRelationChange} />
												)
											}} />
									</div>
								</div>
							)
								: (
									<div className={styles['radio-container']}></div>
								)
							}
						</Grid>
						{watchRiskRelation === EN.FRAUD && (
							<Grid item xs={4}>
								<Controller
									control={formMethods.control}
									name="fraudRiskType"
									render={({ field: { value } }) => {
										return (
											<CLADropdown
												id="fraudRiskType"
												name="fraudRiskType"
												label={EN.FRAUD_RISK_TYPE}
												value={value}
												options={fraudRiskTypeOptions}
												isDisabled={hasDefaultRisk}
												onChange={handleFraudRiskTypeChange}
											/>
										)
									}} />
							</Grid>
						)
						}
						{watchRiskRelation === EN.ERROR && (
							<Grid item xs={12}>
								<Controller
									control={formMethods.control}
									name="conditionsDescription"
									render={({ field: { value, onChange } }) => {
										return (
											<TextField
												id={'conditionsDescription'}
												name={'conditionsDescription'}
												label={EN.CONDITIONS_DESCRIPTION}
												onChange={(e) => {
													onChange(e)
													handleConditionsChange(e)
												}}
												value={value}
												multiline
												fullWidth
												rows={3}
												inputProps={{
													maxLength: 4096,
												}}
												InputLabelProps={{
													shrink: true
												}}
											/>
										)
									}} />
							</Grid>
						)}
						{!!showRiskPervasive && (
							<Grid item xs={12}>
								<InputLabel
									sx={{
										'&.MuiInputLabel-root': {
											whiteSpace: 'normal'
										},
									}}
								>{EN.RISK_PERVASIVE}
								</InputLabel>
								<div>
									<Controller
										control={formMethods.control}
										name="isRiskPervasive"
										render={({ field: { value } }) => {
											return (
												<CLARadioButtonList
													id={'isRiskPervasive'}
													value={value}
													defaultValue={watchIsRiskPervasive}
													columns={8}
													customClass={customClass}
													options={EN.YES_OR_NO}
													isDisabled={hasDefaultRisk}
													onChange={handleIsRiskPervasiveChange} />
											)
										}} />
								</div>
							</Grid>
						)
						}

						{watchIsRiskPervasive === EN.NO && (
							<Grid item xs={12}>
								<Controller
									control={formMethods.control}
									name="assertionLevelDescription"
									render={({ field: { value, onChange } }) => {
										return (
											<TextField
												id={'assertionLevelDescription'}
												name={'assertionLevelDescription'}
												label={EN.ASSERTION_DESCRIPTION}
												onChange={(e) => {
													onChange(e)
													handleAssertionLevelChange(e)
												}}
												value={value}
												multiline
												fullWidth
												rows={3}
												inputProps={{
													maxLength: 4096,
												}}
												InputLabelProps={{
													shrink: true
												}
												}
											/>
										)
									}} />
							</Grid>
						)}
						{!!showAuditArea && (
							<>
								{fields && fields.map((_, index) => {
									const isDisabled = fields.length <= 1;
									return (
										<AddAuditArea
											key={index}
											auditArea={fields}
											auditAreaIndex={index}
											auditAreaOptions={auditAreaOptions}
											assertionsOptions={assertionsOptions}
											handleDeleteAuditArea={handleDeleteAuditArea}
											onAuditAreaChange={onAuditAreaChange}
											onScotabdChange={onScotabdChange}
											onAssertionChange={onAssertionsChange}
											isDisabled={isDisabled}
											setRiskFormValue={setRiskFormValue}
											hasDefaultRisk={hasDefaultRisk}
										/>
									)
								})}
								<Grid item xs={12} sx={{
									'&.MuiGrid-item': {
										paddingTop: '10px'
									}
								}}>
									<div className={styles.addReportButton}>
										<IconButton onClick={handleAddAuditArea} disabled={hasDefaultRisk}>
											<span className={styles.iconStyle}>
												+&nbsp;
											</span>
											<span className={styles.textStyle}>
												{EN.ADD_AUDIT_AREA}
											</span>
										</IconButton>
									</div>
								</Grid>
							</>
						)}
						{!!conditionalWatchAccountingEstimate.length && <Grid item xs={4}>
							<InputLabel
								sx={{
									'&.MuiInputLabel-root': {
										whiteSpace: 'normal'
									},
								}}
							>{EN.ACCOUNTING_ESTIMATE}
							</InputLabel>
							<div>
								<Controller
									control={formMethods.control}
									name="isRiskRelatedToEstimate"
									render={({ field: { onChange, value } }) => {
										return (
											<CLARadioButtonList
												value={value}
												defaultValue={watchIsRiskRelatedToEstimate}
												columns={3}
												customClass={customClass}
												options={EN.YES_OR_NO}
												isDisabled={hasDefaultRisk}
												onChange={onChange} />
										)
									}} />
							</div>
						</Grid>}

					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						'&.MuiDialogActions-root': {
							py: 4,
						},
					}}
				>
					{/* Controller react hook form */}
					<Button variant="outlined" onClick={handleOnCancel}>
						{EN.CANCEL}
					</Button>
					<LoadingButton
						variant="contained"
						type="submit"
						loading={buttonLoading}
						disabled={!isValid || (!isEdit && !isDirty)}
					>
						{EN.SAVE}
					</LoadingButton>
				</DialogActions>
			</Form>
		</Dialog>
	);
};


