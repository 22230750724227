import {useContext} from 'react';
import {ProjectFormInstanceContext} from './ProjectFormInstanceProvider';

export const useProjectFormInstanceProvider = () => {
  const context = useContext(ProjectFormInstanceContext);

  if (context === undefined) {
    throw new Error(
      'useProjectFormInstanceProvider() hook must be used within <ProjectFormInstanceContext/>'
    );
  }

  return context;
}