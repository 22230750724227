import { createContext, useReducer, useContext } from 'react';
import NestedDatagridReducer, { initialState } from './NestedDatagridReducer';

const NestedDatagridContext = createContext();

export const NestedDatagridContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(NestedDatagridReducer, initialState);

    const onEditMode = (isEditMode, row) => {
        dispatch({
            type: 'ON_EDIT_MODE',
            payload: {
                isEditMode: isEditMode,
                rowData: row,
            }
        })
    }

    const value = {
        isEditMode: state.isEditMode,
        rowData: state.rowData,
        onEditMode
    }

    return <NestedDatagridContext.Provider value={value}>{children}</NestedDatagridContext.Provider>;
}

const useNestedDatagridProviderState = () => {
    const context = useContext(NestedDatagridContext);

    if (!context) {
        throw `useNestedDatagridProviderState must be used within NestedDatagridContext.`;
    }

    return context;
}

export default useNestedDatagridProviderState;