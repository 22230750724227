import { Fragment, forwardRef } from "react"
import { Listbox } from "@headlessui/react"
import clsx from "clsx"
import styles from "./Select.module.css"

export const Select = (props) => {

    const {
        className,
        children,
        'aria-label': ariaLabel,
        ...other
    } = props

    const componentClassName = clsx(
        styles["select"],
        className
    )

    return (
        <Listbox as="div" className={componentClassName} {...other} aria-label={ariaLabel}>
            { children }
        </Listbox>
    )
}

const SelectButton = forwardRef((props, ref) => {
    
    const {
        className,
        children,
        isOpen,
        disabled,
        ...other
    } = props

    const componentClassName = clsx(
        styles["select-button"],
        disabled && styles["select-button--disabled"],
        className
    )

    const iconClassName = clsx(
        styles["select-button__icon"],
        isOpen && styles["select-button__icon--reversed"],
        disabled && styles["select-button__icon--disabled"]
    )
    
    return (
        <button className={componentClassName} {...other} ref={ref}>
            <span>{ children }</span>
            <svg className={iconClassName} width="1.25em" height="1.25em" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.656982 1.3074L3.82898 4.35156L6.99998 1.3074L3.82898 4.35156L0.656982 1.3074Z" fill="#2E334E" stroke="#1E2133" strokeWidth="1.2"/>
            </svg>
        </button>
    )
})

Select.Button = (props) => {

    const {
        className,
        children,
        ...other
    } = props

    const componentClassName = clsx(
        styles["select__button"],
        className
    )
    
    return (
        <Listbox.Button as={Fragment} {...other}>
            {
                typeof children === "function"
                ? children
                : ({ open, disabled }) => (
                    <SelectButton
                        className={className}
                        disabled={disabled}
                        isOpen={open}
                    >
                        { children }
                    </SelectButton>
                )
            }
        </Listbox.Button>
    )
}

Select.Options = (props) => {
    const { className, ...other } = props;
  
    const componentClassName = clsx(
        styles["select__options"],
        className,
    );
  
    return (
        <Listbox.Options
            className={componentClassName}
            {...other}
        />
    )
}

const SelectOption = (props) => {

    const {
        className,
        selected,
        active,
        disabled,
        children,
        ...other
    } = props

    const componentClassName = clsx(
        styles["select__option"],
        selected && styles["select__option--selected"],
        active && styles["select__option--active"],
        disabled && styles["select__option--disabled"],
        className
    )

    return (
        <li className={componentClassName} {...other}>
            { children }
        </li>
    )
}

Select.Option = (props) => {

    const {
        className,
        children,
        disabled = false,
        ...other
    } = props

    const componentClassName = clsx(
        styles["select__option"],
        disabled && styles["select__option--disabled"],
        className
    )

    return (
        <Listbox.Option as={Fragment} className={componentClassName} {...other}>
            {
                typeof children === "function"
                ? children
                : ({ disabled, selected, active }) => (
                    <SelectOption active={active} selected={selected} disabled={disabled}>
                        { children }
                    </SelectOption>
                )
            }
        </Listbox.Option>
    )
}

Select.Label = (props) => {

    const {
        className,
        children
    } = props

    const componentClassName = clsx(
        styles["select__label"],
        className
    )

    return (
        <Listbox.Label className={componentClassName}>{ children }</Listbox.Label>
    )
}

export default Select
