import React, { useEffect, useState } from 'react';
import {
  TextField,
  Autocomplete,
  FormControl,
  InputAdornment
} from '@mui/material';
import classes from './CLASelect.module.css';
import { Controller, useFormContext } from 'react-hook-form'; 
import { useRoomIdle } from '@ais/hooks';

const CLAComboBox = (props) => {
  const {
    options,
    allowExternalAccess,
    label,
    id,
    error,
    isDisabled,
    clientExternalIcon,
    lockField,
    unlockField,
    onFocus,
    onSubmit
  } = props; 
  const isIdle = useRoomIdle();

  const { control } = useFormContext();
  const [ open, setOpen ] = useState(false); 

  const handleBlur = () => {
    unlockField && unlockField()
    onSubmit(id);
  }

  const handleFocus = (e) => {
    onFocus(e, id)
    lockField && lockField() 
  }

  const fieldId = `autocomplete-${id}`;

  useEffect(() => {
    if(isIdle) setOpen(false)
  }, [isIdle])

  return (
    <FormControl className={classes['cla-select']} error={error} fullWidth>
      <Controller
        key={id}
        render={({ field: { value, onChange } }) => (
          <Autocomplete
            freeSolo
            id={fieldId}
            open={open}
            options={options}
            value={value ?? ''}
            disabled={isDisabled}
            test-id={fieldId}
            test-value={value ?? ''}
            onClose={() => { setOpen(false) }}
            onOpen={() => { setOpen(true) }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={label}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  variant: 'multiline-label',
                  shrink: true
                }}
                sx={{
                  '& .MuiAutocomplete-endAdornment': {
                    right: allowExternalAccess ? '0 !important' : '9px',
                    position: allowExternalAccess ? 'relative' : 'absolute'
                  },
                  "& .MuiAutocomplete-clearIndicator": {
                    top: '14px'
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      <InputAdornment position="end">
                        {clientExternalIcon}
                      </InputAdornment>
                    </>
                  )
                }}
              />
            )}
            onInputChange={(_, newInputValue) => {
              if (newInputValue && !options.includes(newInputValue)) {
                onChange(newInputValue);
              }
            }}
            onChange={(_, newValue) => {
              onChange(newValue);
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}
        onChange={([, data]) => data}
        name={id}
        control={control}
      />
    </FormControl>
  );
};

export default React.memo(CLAComboBox);
