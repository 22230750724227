import clsx from "clsx"
import styles from "./Flex.module.css"

export const Flex = ({
    className,
    children,
    direction = "column",
    align,
    justify,
    ...other
}) => {

    const componentClassName = clsx(
        styles["flex"],
        direction && styles[`flex--${direction}`],
        align && styles[`flex--${align}`],
        justify && styles[`flex--justify-${justify}`],
        className
    )

    return (
        <div className={componentClassName} {...other}>
            { children }
        </div>
    )
}

export default Flex
