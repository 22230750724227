import { FormControl, FormControlLabel, Checkbox } from '@mui/material';

import PropTypes from 'prop-types';
import { colors } from '@ais/theme';

export const VFCheckbox = (props) => {
  const { name, label, onChange, value, hidden, disabled } = props;

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            sx={{
              color: colors.riptide[500],
              visibility: hidden ? 'hidden' : 'visible'
            }}
            onChange={onChange}
            checked={value}
            disabled={disabled}
          />
        }
        label={label}
        sx={{
          marginRight: '4px',
          '& .MuiTypography-root': {
            color: colors.riptide[500],
          },
        }}
      />
    </FormControl>
  );
};

VFCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool
};

VFCheckbox.defaultProps = {
  name: '',
  label: '',
  value: false,
  hidden: false,
  disabled: false
};
