
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import { FUNCTIONAL_RIGHTS_ROUTE_MAPPING } from '@ais/constants';
import useUserProviderState from "@contexts/UserContext";

const RequireFunctionalRight = (props) => {
    const { rightsKey, showUnauthorize } = props;
    const { userFunctionalRights } = useUserProviderState();
    const [isVisible, setIsVisible] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (userFunctionalRights.length > 0 && FUNCTIONAL_RIGHTS_ROUTE_MAPPING[rightsKey]) {
            const allowedRights = FUNCTIONAL_RIGHTS_ROUTE_MAPPING[rightsKey];
            const matchedRights = userFunctionalRights.filter(userRight => allowedRights.functionalArea.some(allowedRight => userRight.FunctionalAreaId === allowedRight));
           
            if (!matchedRights.find(right => right.CanCreate) 
                && !matchedRights.find(right => right.CanRead) 
                && !matchedRights.find(right => right.CanUpdate) 
                && !matchedRights.find(right => right.CanDelete)) {
                if (showUnauthorize) {
                    navigate('/noaccess');
                } else {
                    setIsVisible(false);
                }
            }
        }    
    },[userFunctionalRights]);

    return (userFunctionalRights.length === 0 || !isVisible) ? <></> : <>{props.children}</>;
}

export default RequireFunctionalRight;