import { useContext } from 'react';

import { CustomHistoryContext } from '@components/Forms/state';

export const useCustomHistory = () => {
  const { customHistory, dispatchCustomHistory } =
    useContext(CustomHistoryContext);

  const setLastPage = (page) => {
    dispatchCustomHistory({
      type: 'SET_LAST_PAGE',
      payload: page,
    });
  };

  const resetLastPage = () => {
    dispatchCustomHistory({
      type: 'RESET_LAST_PAGE',
    });
  };

  return { lastPage: customHistory?.lastPage, setLastPage, resetLastPage };
};
