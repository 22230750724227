import { formatMoneyDecimalAndComma, formatDecimalToPercent } from "@utilities/numberHelper";

const columns = [
  { 
    field: 'CategoryName', 
    headerName: 'CategoryName', 
    editable: false,
    sortable: false,
    flex: 1,
    maxWidth: 230,
    minWidth: 230,
    filterable: false,
    disableReorder: true,
    renderHeader: () => null
  },
  { 
    field: 'CurrentPeriodBalance', 
    headerName: 'Current Period',
    editable: false,
    flex: 1,
    sortable: false,
    maxWidth: 200,
    minWidth: 200,
    filterable: false,
    disableReorder: true,
    disableColumnMenu: true,
    renderHeader: () => null,
    renderCell: params => {
      if(params?.row?.CurrentPeriodBalance === null) return ''
      return formatMoneyDecimalAndComma(params?.row?.CurrentPeriodBalance)
    }
  },
  {
      field: 'PriorPeriodBalance',
      flex: 1,
      headerName: 'Prior Period',
      disableColumnMenu: true, 
      hideSortIcons: true,
      maxWidth: 200,
      minWidth: 200,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: () => null,
      renderCell: params => {
        if(params?.row?.PriorPeriodBalance === null) return ''
        return formatMoneyDecimalAndComma(params?.row?.PriorPeriodBalance)
      }
  },
  {
    field: 'DifferenceAmount',
    headerName: 'Difference Amount',
    flex: 1,
    maxWidth: 200,
    minWidth: 200,
    editable: false,
    sortable: false,
    filterable: false,
    disableReorder: true,
    renderHeader: () => null,
    renderCell: params => {
      if(params?.row?.DifferenceAmount === null) return ''
      return formatMoneyDecimalAndComma(params?.row?.DifferenceAmount)
    }
  },
  {
    field: 'DifferencePercent',
    headerName: 'Difference Percentage', 
    editable: false,
    flex: 1,
    sortable: false,
    filterable: false,
    disableReorder: true,
    renderHeader: () => null,
    renderCell: params => {
      if(params?.row?.DifferencePercent === null) return ''
      return formatDecimalToPercent(params?.row?.DifferencePercent)
    }
  },
  {
    field: 'Comment',
    headerName: 'Comment',
    flex: 1,
    minWidth: 150,
    maxWidth: 150,
    editable: false,
    sortable: false,
    filterable: false,
    disableReorder: true,
    hide: true,
  }
];

export default columns
