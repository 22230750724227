import { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm, useWatch } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@ais/palette";

import {
  BasicTextField,
  CheckboxField,
  RadioSelect,
  CLAColumnsOptions,
  ToggleableTextarea,
  ExternalAccess,
} from './dialog-components/settings';
import {
  createField,
  dropNewItem,
  widthOptions,
  convertStringToArray,
  hasDuplicateItems,
  updateFormItem,
  convertArrayToStringOptions,
  filterEmptyCriterias,
  isTriggeredFromQuestionGroup,
  getShareableComponentDefaultValue
} from '@components/Forms/helpers';
import { SchemaContext } from '@ais/providers';
import { BaseDialog } from '.';
import { DIALOG, TRIGGERS } from '@constants/forms';
import { RulesTab } from './dialog-components/rules';
import { RadioButtonDefaultAnswersTab } from './dialog-components/settings';

const { LABEL, PLACEHOLDER, ERROR } = DIALOG.SETTINGS;

export const RadioButtonDialog = ({ visible, setVisible, additionalData, trigger }) => {
  const { schema, dispatchSchema } = useContext(SchemaContext);

  const initialValues = {
    label: additionalData?.label ?? '',
    required: additionalData?.required ?? true,
    columns: additionalData?.columns ?? 1,
    options: additionalData?.options ? convertArrayToStringOptions(additionalData.options) : '',
    allowOtherOption: additionalData?.allowOtherOption ?? false,
    tooltip: additionalData?.tooltip ?? '',
    width: additionalData?.width ?? 100,
    rules:
      typeof additionalData?.rules === 'object'
        ? additionalData.rules
        : {
            behavior: 'show',
            match: 'any',
            criterias: [],
            excludeAnswerOnRollForward: false,
            canNotBeDeleted: true,
          },
    defaultValue: additionalData?.defaultValue ?? '',
    defaultValues: additionalData?.defaultValues ?? [],
    visibleToClient: getShareableComponentDefaultValue(additionalData, "visibleToClient", trigger),
    editableByClient: getShareableComponentDefaultValue(additionalData, "editableByClient", trigger),
  };

  const schemaValidation = Yup.object().shape({
    label: Yup.string().trim().required(''),
    options: Yup.string().ensure().trim().required('')
      .test('options_validation', '', function (value) {
        const optionList = convertStringToArray(value);
        return hasDuplicateItems(optionList) ? this.createError({ message: ERROR.OPTION_HAS_DUPLICATE }) : true;
      })
  })

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation)
  });

  const { errors, touchedFields } = formMethods.formState;

  const watchColumns = useWatch({ control: formMethods.control, name: 'columns' }) ?? [];

  const handleSubmit = values => {
    const filteredRules = filterEmptyCriterias(values.rules);
    if (![TRIGGERS.EDIT_BTN, TRIGGERS.QUESTION_GROUP_EDIT].some((t) => t === trigger)) {
      const newField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.refField.index,
        ...values,
        options: convertStringToArray(values.options),
        width: +values.width,
        rules: filteredRules,
      });
      dropNewItem(additionalData, newField, trigger, schema, dispatchSchema);
    } else {
      const updateField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.index,
        ...values,
        options: convertStringToArray(values.options),
        width: +values.width,
        rules: filteredRules,
      });
      updateFormItem(additionalData, updateField, schema, dispatchSchema);
    }
    setVisible(false);
  }

  useEffect(() => {
    formMethods.reset(initialValues);
    formMethods.trigger();
  }, [visible]);

  const tabs = [
    {
      label: LABEL.SETTINGS,
      render: () => (
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <BasicTextField
              name="label"
              label={LABEL.QUESTION_LABEL}
              placeholder={PLACEHOLDER.QUESTION}
              maxLength={256}
              showRequired
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleableTextarea
              name="options"
              maxRows={10}
              minRows={10}
              placeholder={PLACEHOLDER.OPTION_LIST}
              showFormatText={false}
            />
          </Grid>
          <Grid item container xs={12}>
            <Grid item container xs={9}>
              <Grid item xs={4}>
                <CheckboxField
                  name="allowOtherOption"
                  label={LABEL.ALLOW_OTHER_OPTION}
                />
              </Grid>
              <Grid item xs="auto">
                <ToggleableTextarea
                  name="tooltip"
                  maxLength={4096}
                  maxRows={2}
                  minRows={2}
                  label={LABEL.ADD_TOOLTIP}
                  placeholder={PLACEHOLDER.TEXT}
                  toggleable
                />
              </Grid>
            </Grid>
            <Grid item container xs={3} justifyContent="flex-end">
              <Grid item xs="auto">
                <CLAColumnsOptions
                  labelType={LABEL.RADIOS_BUTTONS}
                  value={watchColumns}
                  onChange={(e) => formMethods.setValue('columns', e.target.value)}
                  touched={touchedFields['columns']}
                  errors={errors['columns']}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              {!isTriggeredFromQuestionGroup(trigger) && <ExternalAccess
                allowExternalAccessName="visibleToClient"
                makeAnswerableName="editableByClient"
              />}
            </Grid>
            <Grid item>
              <RadioSelect
                name="width"
                label={LABEL.DISPLAY_WIDTH}
                options={widthOptions}
              />
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      label: LABEL.DEFAULT_ANSWERS,
      render: () => <RadioButtonDefaultAnswersTab />,
    },
    {
      label: LABEL.RULES,
      render: () => <RulesTab additionalData={additionalData} trigger={trigger} />,
    },
  ];

  return (
    <Form form={formMethods}>
      <BaseDialog
        id={isTriggeredFromQuestionGroup(trigger) ? '' : additionalData?.id}
        idPrefix={isTriggeredFromQuestionGroup(trigger) ? '' : LABEL.QUESTION_ID}
        title={LABEL.RADIO_BUTTON}
        visible={visible}
        setVisible={setVisible}
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        tabs={
          isTriggeredFromQuestionGroup(trigger)
            ? tabs.filter((tab) => tab.label !== LABEL.RULES)
            : tabs
        }
      />
    </Form>
  );
};

RadioButtonDialog.propTypes = {
  visible: PropTypes.bool,
  additionalData: PropTypes.object,
  trigger: PropTypes.string,
  setVisible: PropTypes.func,  
};

RadioButtonDialog.defaultProps = {
  value: false,
  additionalData: null,
  trigger: '',  
};
