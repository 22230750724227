import formApi from "@utilities/claApiForm"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"

const postProjectDeficiencyCombination = async (body, projectId) => {
    const response = await formApi.post(`project-deficiency-combinations/${projectId}`, body)
    if (response) {
        return { status: 200, data: response.data }
    }
    return { status: 400, message: 'POST: Project Deficiency Combination response: ' + response }
};

export const putProjectDeficiencyCombination = async (projectDeficiencyCombinationId, body, projectId) => {
    const response = await formApi.put(`/project-deficiency-combinations/${projectDeficiencyCombinationId}/${projectId}`, body)
    if (response) {
        return { status: 200, data: response.data }
    }
    return { status: 400, message: 'PUT: Project Deficiency Combination response: ' + response }
}

const getProjectDeficiencyCombinations = async (projectId, unitIds, requestProjectFormId) => {
    try {
        const { data } = await formApi.get(`/project-deficiency-combinations/${projectId}`, {
            params: {
                ProjectId: projectId,
                ProjectUnitIds: unitIds?.join() ?? [],
                RequestProjectFormId: requestProjectFormId
            }
        });
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

const deleteProjectDeficiencyCombination = async (projectDeficiencyCombinationId, projectId, projectFormId) => {
    try {
        const response =
            await formApi.delete(`/project-deficiency-combinations/${projectId}/${projectFormId}/${projectDeficiencyCombinationId}`);
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
};

//#region exports functions
export const usePostProjectDeficiencyCombination = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (payload) => postProjectDeficiencyCombination(payload.reqBody, payload.projectId),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectDeficiencyCombination');
            }
        }
    )
};

export const useDeleteProjectDeficiencyCombination = (projectFormId) => {
    const queryClient = useQueryClient();

    return useMutation(
        (payload) => deleteProjectDeficiencyCombination(payload.projectDeficiencyCombinationId, payload.projectId, projectFormId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['ProjectDeficiencyCombination']);
            }
        }
    );
};

export const usePutProjectDeficiencyCombination = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (payload) => putProjectDeficiencyCombination(payload.projectDeficiencyCombinationId, payload.reqBody, payload.projectId),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectDeficiencyCombination');
            }
        }
    )
};

export const useGetProjectDeficiencyCombinations = (projectId, unitIds, requestProjectFormId) => {
    return useQuery(
        ["ProjectDeficiencyCombination", projectId, unitIds, requestProjectFormId],
        () => getProjectDeficiencyCombinations(projectId, unitIds, requestProjectFormId),
        {
            enabled: !!projectId
        }
    );
};
//#endregion

const deficiencyCombinationService = {
    getProjectDeficiencyCombinations
}

export default deficiencyCombinationService;