export const PROJECT_FORM_INSTANCE = {
    EN: {
        ASSIGNED_UNITS: 'Assigned Units:',
        CONCLUSIONS: 'Conclusions',
        MODAL: {
            DELETE: {
                HEADER: 'Warning',
                DELETE_MESSAGE: 'Are you sure you want to delete this form? It will permanently delete the data.'
            },
            CONFLICT_RESOLUTION: {
                HEADER: 'Conflicts Resolution',
                MESSAGE: 'Select the unit you would like to use'
            }
        },
        MESSAGE: {
            DELETE_FAILED: 'Project Form deletion failed.'
        },
        OPTIONAL: 'optional',
        CONFIRMATION_TYPE: {
            DELETE_QUESTION: 'DELETE_QUESTION',
        },
        BUTTONS: {
            CANCEL: 'Cancel',
            DELETE: 'Delete',
            OK: 'OK',
            ADD_MY_SIGN_OFF: 'ADD MY SIGN-OFF',
            BACK_TO_DASHBOARD: 'Back to Dashboard',
            SAVE: 'Save'
        },
        SIGN_OFFS: [
            { level: 1, name: 'preparer', label: 'Preparer' },
            { level: 2, name: 'reviewer1', label: 'Reviewer 1' },
            { level: 3, name: 'reviewer2', label: 'Reviewer 2' },
            { level: 4, name: 'reviewer3', label: 'Reviewer 3' },
        ],
        HIGHLIGHT_BORDER: {
            MODIFIED: 'modified',
            RELATED: 'related'
        },
        ERROR_IN_SAVING_ANSWER: 'An error was encountered upon saving your answer. Please try again.',
        TOAST: {
            ERROR: "Internal Error! Form not saved"
        }
    },
    PROCEDURE: {
        TAILOR_PROCEDURES: "Tailor Procedures",
        TAILOR_PROCEDURES_OPENED: "This audit area is currently being tailored.",
        SAVE: "Save",
        EXPAND_ALL: 'Expand All Comments',
        COLLAPSE_ALL: 'Collapse All Comments',
        CANCEL: "Cancel",
        ADD_CUSTOM_PROCEDURE: "ADD A CUSTOM PROCEDURE",
        PROCEDURE_NAME: "Procedure Name",
        ASSERTIONS: "Assertions",
        FILTER_OPTIONS: {
            OPEN_STEPS: "Open steps",
            COMPLETED_STEPS: "Completed steps",
            CLIENT_INQUIRY: "Client inquiry",
            INQUIRY: "Inquiry",
            PBC_NEEDED: "PBC Needed",
            PBC: "PBC",
            CONFIRMATION: "Confirmation",
            CONFIRM: "Confirm",
            PLANNING: "Planning",
            INTERIM: "Interim",
            YEAR_END: "Year End"
        },
        APPLY_ALL_SUGGESTIONS: "Apply All Suggestions",
        TAILORING_PROCEDURES_FILTER: {
            OPTIONS: [
                { value: "all", label: "View All Procedures and Steps" },
                { value: "suggestedadditions", label: "Suggested Additions" },
                { value: "suggesteddeletions", label: "Suggested Deletions" },
                { value: "customprocedures", label: "Custom Procedures" }
            ],
            ALL: "all",
            SUGGESTED_ADDITIONS: "suggestedadditions",
            SUGGESTED_DELETIONS: "suggesteddeletions",
            CUSTOM_PROCEDURES: "customprocedures"
        }
    }
}