import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

import string from "@utilities/stringHelper.js";

import { activateTooltip } from '../helpers';

export const LinkCell = ({ row, value, projectId }) => {
  const ProjectFormId = row?.ProjectFormId;
  const ProjectFormName = row?.ProjectFormName;
  const FormSectionId = row?.FormSectionId;
  const actualValue = value ?? ProjectFormName;
  const truncate = string.truncateWithBool(actualValue, 30);
  const WorkpaperReference = FormSectionId && ProjectFormId ? (
    <Link
      style={{
        margin: '20px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: '#76D2D3',
        fontWeight: 600
      }}
      target={"_blank"}
      to={{
        pathname: `/project-management/${projectId}/project-form-instance/${ProjectFormId}/`
      }}
    >
      {ProjectFormName}
    </Link>
  ) : (
    <Typography sx={{
      fontWeight: 600,
      fontSize: '14px',
      m: '20px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }}>
      {value}
    </Typography>
  );

  return (truncate.isTruncated ? activateTooltip(WorkpaperReference, actualValue) : WorkpaperReference)
};
