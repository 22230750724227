import { useContext } from 'react'

import { DeficiencySummaryContext } from "@contexts/DeficiencySummary/DeficiencySummaryContext";

export const useDeficiency = () => {
    const {
        concurrencyEventReceived,
        setConcurrencyEventReceived,
        deficiencies,
        setDeficiencies,
        deficiencyCombinations,
        setDeficiencyCombinations
    } = useContext(DeficiencySummaryContext);

    return {
        concurrencyEventReceived,
        setConcurrencyEventReceived,
        deficiencies,
        setDeficiencies,
        deficiencyCombinations,
        setDeficiencyCombinations
    }
}