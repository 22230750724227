import { useContext } from 'react';
import { VFCanvasContext } from './VFCanvasProvider';

export const useVFCanvasProvider = () => {
  const context = useContext(VFCanvasContext);

  if (context === undefined) {
    throw new Error(
      'useVFCanvasProvider() hook must be used within <VFCanvasContext/>'
    );
  }

  return context;
}

export default useVFCanvasProvider;