import React from 'react';
import './PageSubTitle.css'

const PageSubTitle = (props) => {
    return (
        <span className="sub-title" data-test="subtitle" title={props.title}>
            {props.subTitle}
        </span>
    );
}

export default PageSubTitle;