import clientApi from '@utilities/claApiClient';
import {
    PublicClientApplication
} from "@azure/msal-browser";
import { msalConfig } from '@utilities/authentication.js';
import { useQuery } from "@tanstack/react-query"
import logger from '@utilities/logService'

const getAll = async () => {
    const response = await clientApi.get('users');
    const users = [];

    response.data.forEach((user) => {
        users.push({
            id: user.id,
            name: user.name,
            preferred_username: user.preferredUsername,
            email_address: user.email,
            nickname: user.nickname,
            family_name: user.familyName,
            given_name: user.givenName,
            upn: user.userPrincipalName,
            home_office: user.homeOffice,
            family_member_level: user.familyMemberLevel,

            roles: [],
            groups: [],
            industries: []
        })
    })

    return users
}

const getbyId = async (id) => {
    const response = await clientApi.get(`users/${id}`);
    return {
        activeDirectoryId: response.data.activeDirectoryId,
        thumbnailPhoto: response.data.thumbnailPhoto,
        employeeId: response.data.employeeId,
        firstName: response.data?.firstName ?? '',
        lastName: response.data?.lastName ?? '',
        jobTitle: response.data.jobTitle,
        emailAddress: response.data.emailAddress,
        phoneNumber: response.data.phoneNumber,
        officeLocation: response.data.officeLocation,
        preferredLanguage: response.data.preferredLanguage
    }
}

const addUserbyId = async (id) => {
    try {
        const response = await clientApi.post(`users/${id}`);

        return {
            activeDirectoryId: response.data.activeDirectoryId,
            thumbnailPhoto: response.data.thumbnailPhoto,
            employeeId: response.data.employeeId,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            jobTitle: response.data.jobTitle,
            emailAddress: response.data.emailAddress,
            phoneNumber: response.data.phoneNumber,
            officeLocation: response.data.officeLocation,
            preferredLanguage: response.data.preferredLanguage
        }
    } catch (error) {
        logger.error({ error })
    }
}

const getByIdList = async (ids) => {
    if (!ids || ids.length === 0) return;
    let promises = [];
    const users = [];
    ids.forEach(id => {
        const promise = clientApi.get(`users/${id}`).then(response => {
            users.push({
                activeDirectoryId: response.data.activeDirectoryId,
                thumbnailPhoto: response.data.thumbnailPhoto,
                employeeId: response.data.employeeId,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                jobTitle: response.data.jobTitle,
                emailAddress: response.data.emailAddress,
                phoneNumber: response.data.phoneNumber,
                officeLocation: response.data.officeLocation,
                preferredLanguage: response.data.preferredLanguage
            })
        });
        promises.push(promise);
    });
    await Promise.all(promises);

    return users;
}

const getPhotobyId = async (id) => {
    const response = await clientApi.get(`users/${id}/photo`);
    return response.data;
}

const getMe = async () => {
    const response = await clientApi.get(`users/me`);
    return response.data;
}

const getEmployeeDetails = async (employeeId) => {
    const response = await clientApi.get(`users/employee-details/${employeeId}`);
    return response.data;
}

const getFunctionalRightsGroupMembers = async (id, projectId, search) => {
    let response;

    if (search)
        response = await clientApi.get(`users/groupmembers/${projectId}/${id}?search=${search}`);
    else
        response = await clientApi.get(`users/groupmembers/${projectId}/${id}`);
    return response.data;
}

const getUserFunctionalGroupsByUserId = async (id) => {
    const { data } = await clientApi.get(`users/functionalgroups/${id}`);
    return data;
}

const getUserFunctionaRightsByUserId = async (id) => {
    const { data } = await clientApi.get(`users/functionalrights/${id}`);
    return data;
}

const logIn = () => {
    try {
        const msalInstance = new PublicClientApplication(msalConfig)
        const loginRequest = {
            scopes: [],
            prompt: "select_account"
        }
        const loginRedirectRequest = {
            ...this.loginRequest,
            redirectStartPage: window.location.href
        }

        this.msalInstance.loginRedirect(this.loginRedirectRequest)
    } catch (ex) {
    }

}

const usersServices = {
    getAll,
    getbyId,
    addUserbyId,
    getPhotobyId,
    getMe,
    getEmployeeDetails,
    getFunctionalRightsGroupMembers,
    logIn,
    getByIdList,
    getUserFunctionalGroupsByUserId,
    getUserFunctionaRightsByUserId
};

export default usersServices;

export const fetchUserSummary = async () => {
    const { data } = await clientApi.get(`users/summary`)
    return data
}

export const userSummaryQuery = () => ({
    queryKey: ["userSummary"],
    queryFn: () => fetchUserSummary()
})

export const useUserSummary = () => {
    return useQuery(userSummaryQuery())
}
