import {
    TableCell,
    Typography
} from '@mui/material';

import { colors } from '@ais/theme';

const CommunicationOfDeficienciesHeader = (props) => {
    const { columns } = props;
    
    return columns.map((column, index) => {
        const { headerName, maxWidth, minWidth, width } = column;

        return (
            <TableCell
                className={'MuiDataGrid-columnHeader MuiDataGrid-columnHeaderTitleContainer'}
                sx={{
                    pr: 0,
                    pl: 0,
                    display: 'flex',
                    maxWidth: maxWidth,
                    minWidth: minWidth,
                    width: width,
                    flexGrow: 1,
                    border: 0
                }}
                key={index}
            >
                <Typography
                    data-test={`${headerName}_component`}
                    className={'MuiDataGrid-columnHeaderTitleContainerContent'}
                    sx={{
                        fontWeight: 700,
                        fontSize: '16px',
                        m: '0 40px',
                        color: colors.navy[400]
                    }}>
                    {headerName}
                </Typography>
            </TableCell>
        );
    })
}

export default CommunicationOfDeficienciesHeader;