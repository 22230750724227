import { useEffect, useState } from 'react'
import { useDeepCompareEffect } from 'react-use';
import { 
    useDiagnosticsCategories, 
    useDiagnosticsTypes,
    useDiagnosticsReportByDiagnosticTypeIds,
    useRefreshDiagnosticsMutation,
} from "@services/diagnostics";
import * as Constants from "@constants/index";

export const useProjectDiagnostics = (
    projectId,
    engagementLeaderId,
    enabled = true,
) => {

    const [diagnostics, setDiagnostics] = useState([])
    const { data: diagnosticsCategories, isLoading: isDiagnosticsCategoriesLoading } = useDiagnosticsCategories();
    const [diagnosticTypeIds, setDiagnosticTypeIds] = useState([]);
    const [isAnyDiagnosticsLoading, setIsAnyDiagnosticsLoading] = useState(false);
    const { data: diagnosticTypes, isLoading: isDiagnosticTypesLoading } = useDiagnosticsTypes();
    const { mutate } = useRefreshDiagnosticsMutation();
    const diagnosticReports = useDiagnosticsReportByDiagnosticTypeIds(projectId, engagementLeaderId, diagnosticTypeIds, enabled)

    const { DIAGNOSTIC_TYPE } = Constants.DIAGNOSTICS.EN;

    useEffect(() => {
        if(!isDiagnosticTypesLoading) {
            const diagnosticTypeIds = diagnosticTypes
                // special filter for potential unnecesarry steps and steps suggested because 
                // there's only one query for both of the diagnostic types
                // calling one of them gets both
                .filter(({DiagnosticTypeId}) => DiagnosticTypeId !== DIAGNOSTIC_TYPE.POTENTIAL_UNNECESSARY_STEPS) 
                .map(({DiagnosticTypeId}) => DiagnosticTypeId)
            setDiagnosticTypeIds(diagnosticTypeIds)
        }
    }, [isDiagnosticTypesLoading])

    useDeepCompareEffect(() => {
        setIsAnyDiagnosticsLoading(diagnosticReports.some(({isLoading, isRefetching}) => isLoading || isRefetching))
        const diagnostics = diagnosticReports
            .filter(({data}) => data !== undefined && data?.status !== 400)
            .flatMap(({data}) => data)
        setDiagnostics(diagnostics)
    }, [diagnosticReports])

    const refreshDiagnostics = () => {
        setDiagnostics([]);
        mutate();
    }

    return {
        diagnostics,
        isAnyDiagnosticsLoading,
        diagnosticsCategories,
        isDiagnosticsCategoriesLoading,
        refreshDiagnostics,
    }
}