
import { useEffect } from 'react';

export const useTitle = (initialTitle) => {
    useEffect(() => {
      document.title = initialTitle || 'AIS Dashboard';
  
      return () => {
        document.title = 'AIS Dashboard';
      };
    }, [initialTitle]);
  };