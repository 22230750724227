import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

import { MethodologyVersionContext } from '@components/CLAHeader/state/MethodologyVersionContext';
import methodologyVersionService from '@services/methodologyversion';

export const useMethodologyVersion = (input) => {
  const { projectData, dispatchProjectData } = useContext(MethodologyVersionContext);

  const [isLatestVersion, setIsLatestVersion] = useState(true);

  const checkForLatestVersion = async (methodologyVersion) => {
    const response = await methodologyVersionService.getLatestMethodologyVersion();
    if (response.status === 200)
      setIsLatestVersion(response.data.MethodologyVersion === methodologyVersion);
    else if (response.status === 400) toast.error(response.message);
  };

  useEffect(() => {
    if (input) checkForLatestVersion(input);
  }, [input]);

  return {
    currentClientName: projectData.clientName,
    currentProjectName: projectData.projectName,
    currentMethodologyVersion: projectData.methodologyVersion,
    isLatestVersion,
    dispatchProjectData,
  };
};
