export const PROJECT_TEAM_MODAL = {
    EN: {
        BUTTON: {
            CANCEL: 'Cancel',
            DELETE: 'Delete',
            DENY: 'Deny',
            RESTORE: 'Restore',
            OK: 'Ok',
        },
        MESSAGE: {
            DELETE: 'Are you sure you want to delete this user?',
            DENY: 'Are you sure you want to deny project team member access?',
            RESTORE: 'Are you sure you want to restore project team member access?',
            SUCCESS: 'User has been deleted.',
            DUPLICATE: 'User already exists. Enter new user information and try again.',
        },
        TITLE: {
            SUCCESS: 'Success!',
            WARNING: 'Warning',
        },
        VARIANT: {
            CONTAINED: 'contained',
        }
    }
}

export const PROJECT_TEAM_TOOLTIP = {
    EN: {
        MESSAGE: {
            PROVISION: {
                IN_PROGRESS: 'User provisioning in progress'
            },
            DENY: 'Deny User Access',
            RESTORE: 'Restore User Access',
        }
    }
}
