export const GENERAL_LEDGER_COMPLETENESS_PLACEHOLDER = "Please document any differences noted below";

export const TRANSACTION_DEBIT = 'D'

export const TRANSACTION_CREDIT = 'C'

export const GENERAL_LEDGER_COMPLETENESS_COLUMN = [
  {
    field: "AccountNumber",
    headerName: "Account\nNumber",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 200,
    flex: 1,
  },
  {
    field: "Name",
    headerName: "Trial Balance\nName",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 200,
    flex: 1,
  },
  {
    field: "AccountDescription",
    headerName: "Account\nDescription",
    editable: false,
    sortable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: "PriorYearFinalBalance",
    headerName: "Prior Period\nFinal Balance",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 200,
    flex: 1,
  },
  {
    field: "CurrentYearTransactionSummary",
    headerName: "Current Period\nTransaction Summary",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 200,
    minWidth: 195,
    flex: 1,
  },
  {
    field: "CurrentYearUnadjustedBalance",
    headerName: "Current Period\nUnadjusted",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 200,
    flex: 1,
  },
  {
    field: "DifferenceAmount",
    headerName: "Difference\n($)",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 120,
    flex: 1,
  },
  {
    field: "DifferencePercent",
    headerName: "Difference\n(%)",
    editable: false,
    sortable: false,
    filterable: false,
    maxWidth: 120,
    flex: 1,
  },
];

export const GL_ROLLFORWARD_MESSAGES = {
  SUCCESS: 'General Ledger has Rolled Forward Successfully',
  NOT_ENOUGH_DATA: 'Not Enough Data to Complete Roll Forward'
}