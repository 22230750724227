import React, { memo } from 'react';
import { styled } from '@mui/system';

import styles from './VFSectionV2.module.css';
import { NATGFieldSelector } from '@components/FormView/v2/NATGFieldSelector';

import { ClientExternalIcon } from '@ais/forms';
import { useProjectFormInstanceProviderV2 } from '@providers';

const StyledRow = styled('div')({
  display: 'flex',
  position: 'relative',
  flexFlow: 'row',
  flexWrap: 'wrap',
  width: '100%'
});
const VFInnerSection = ({ fields }) => {
  const { projectForm: { clientProjectFormStatusId } } = useProjectFormInstanceProviderV2(); 
  return (
    <div className={styles['vf-section-inner']}>
      {fields.map((row, rowIndex) =>
        <StyledRow key={`row-${rowIndex}`}>
          {row.map((field, fieldIndex) => { 
            return (
                <NATGFieldSelector
                  field={field}
                  rowIndex={rowIndex}
                  fieldIndex={fieldIndex}
                  clientExternalIcon={
                    <ClientExternalIcon
                      allowExternalAccess={field.visibleToClient ?? false}
                      answerable={field.editableByClient ?? false}
                      answered={clientProjectFormStatusId === 3}
                    />
                  }
                /> 
            )
          })}
        </StyledRow>
      )}
    </div>
  );
}

export default memo(VFInnerSection);
