import { useState } from 'react';
import {CLASelect} from '@ais/forms';
import { OutlinedInput, IconButton, SvgIcon } from '@mui/material';
import { ReactComponent as DeleteIcon } from '@assets/delete_icon.svg';

import {FIELD_TYPES} from '@ais/constants';
import { VALIDATION_TYPES } from '@constants/forms';
import {CLACurrencyField, CLADropdown, CLANumberField, CLAPercentageField} from '@ais/forms';

import styles from './DefaultValueDrawer.module.css';

const DefaultValueRow = props => {
  const {
    id,
    defaultValueItem,
    disabledValues,
    industries,
    validation,
    disabledIndustries,
    onRowChange,
    onRowDelete,
    fieldType,
    inputComponentData,
    allowMultiSelectDefaultValue,
  } = props;

  const [defaultValue, setDefaultValue] = useState(
    defaultValueItem.defaultValue
  );

  const [defaultValues, setDefaultValues] = useState(
    defaultValueItem.defaultValues ?? []
  );

  const handleChange = (propertyName, value) => {
    const updatedValues = {
      ...defaultValueItem,
      [propertyName]: value,
    };
    onRowChange(id, updatedValues);
  };

  const getDefaulValueInputComponent = () => {
    switch (fieldType) {
      case FIELD_TYPES.SHORT_ANSWER: {
        // Identify validation type and return appropriate component
        switch (validation) {
          case VALIDATION_TYPES.NUMERICAL:
            {
              return (
                <CLANumberField
                  sx={{
                    width: '100%',
                  }}
                  name="defaultValue"
                  placeholder={'Default Value'}
                  value={defaultValue}
                  onChange={e => {
                    handleChange('defaultValue', e.target.value);
                    setDefaultValue(e.target.value);
                  }}
                />
              );
            }

          case VALIDATION_TYPES.USCURRENCY:
            {
              return (
                <CLACurrencyField
                  sx={{
                    width: '100%',
                  }}
                  name="defaultValue"
                  placeholder={'Default Value'}
                  value={defaultValue}
                  onChange={e => {
                    handleChange('defaultValue', e.target.value);
                    setDefaultValue(e.target.value);
                  }}
                />
              )
            }

          case VALIDATION_TYPES.PERCENTAGE:
            {
              return (
                <CLAPercentageField
                  sx={{
                    width: '100%',
                  }}
                  name="defaultValue"
                  placeholder={'Default Value'}
                  value={defaultValue}
                  onChange={(e, value) => {
                    handleChange('defaultValue', value?.toString());
                    setDefaultValue(value?.toString());
                  }}
                />
              )
            }

          default:
            {
              return (
                <OutlinedInput
                  sx={{
                    width: '100%',
                  }}
                  name="defaultValue"
                  placeholder={'Default Value'}
                  value={defaultValue}
                  onChange={e => {
                    handleChange('defaultValue', e.target.value);
                    setDefaultValue(e.target.value);
                  }}
                />
              );
            }
        }
      }
      case FIELD_TYPES.LONG_ANSWER: {
        return (
          <OutlinedInput
            sx={{
              width: '100%',
            }}
            name="defaultValue"
            placeholder={'Default Value'}
            value={defaultValue}
            onChange={e => {
              handleChange('defaultValue', e.target.value);
              setDefaultValue(e.target.value);
            }}
          />
        );
      }
      case FIELD_TYPES.CHECKBOXGROUP: {
        return (
          <CLASelect
            defaultValues={defaultValues}
            menuItems={inputComponentData.options}
            onChange={(value) => {
              handleChange("defaultValues", value);
              setDefaultValues(value);
            }}
            disabledValues={disabledValues}
          />
        );
      }
      case FIELD_TYPES.RADIOGROUP: {
        return (
          <CLADropdown
            value={defaultValue}
            options={inputComponentData.options}
            onChange={e => {
              handleChange('defaultValue', e.target.value);
              setDefaultValue(e.target.value);
            }}
            disabledValues={disabledValues}
          />
        );
      }
      case FIELD_TYPES.DROPDOWN: {
        return (
          <>
            {!allowMultiSelectDefaultValue && (
              <CLADropdown
                value={defaultValue}
                options={inputComponentData.options}
                onChange={e => {
                  handleChange('defaultValue', e.target.value);
                  setDefaultValue(e.target.value);
                }}
                disabledValues={disabledValues}
              />
            )}
            {allowMultiSelectDefaultValue && (
              <CLASelect
                defaultValues={defaultValues}
                menuItems={inputComponentData.options}
                onChange={value => {
                  handleChange('defaultValues', value);
                  setDefaultValues(value);
                }}
                disabledValues={disabledValues}
              />
            )}
          </>
        );
      }
    }
  };

  return (
    <div className={styles['def-row-wrapper']}>
      <div className={styles['def-row-text']}>
        {getDefaulValueInputComponent()}
      </div>
      <div className={styles['def-row-dropdown']}>
        <CLASelect
          defaultValues={defaultValueItem.industries}
          menuItems={industries}
          onChange={value => {
            handleChange('industries', value);
          }}
          disabledValues={disabledIndustries}
        />
      </div>

      <div className={styles['def-row-delete-icon']}>
        <IconButton
          onClick={() => onRowDelete(id)}
          sx={{
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <SvgIcon sx={{ fontSize: '30px' }}>
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      </div>
    </div>
  );
};

export default DefaultValueRow;
