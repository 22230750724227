import { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Paper,
  FormControl,
  Typography,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';

import {FIELD_TYPES, FORM_LABEL_TYPES} from '@ais/constants';
import { DIALOG } from '@constants/forms';

import questionsService from '@services/forms/questions.js';
import {CLADropdown} from "@ais/forms";
import formCategoriesService from '@services/forms/formCategories.js';
import formServices from '@services/forms/forms.js';

export const SelectQuestion = (props) => {
    const {
      schema,
      visible,
      setVisible,
      handleClickSelectQuestion,
      setLoading
    } = props;
  
    const { ERROR } = DIALOG.SETTINGS;

    const methodologyVersionId = schema?.formSettings?.methodologyVersionId;
  
    const [categoryId, setCategoryId] = useState(schema?.formSettings?.formCategoryId);
    const [formCategoryOptions, setFormCategoryOptions] = useState([]);
    const [formId, setFormId] = useState();
    const [sectionId, setSectionId] = useState();
    const [sections, setSections] = useState([]);
    const [questionId, setQuestionId] = useState();
    const [formOptions, setFormOptions] = useState([]);
    const [formSchema, setFormSchema] = useState(null);
    const [formSectionsOptions, setFormSectionOptions] = useState([]);
    const [questionOptions, setQuestionOptions] = useState([]);
    const [helperText, setHelperText] = useState('');
    const [searchQuestionId, setSearchQuestionId] = useState();

    const findSections = (properties) => {
      const sections = [];
      properties.forEach((section) => {
        sections.push({
          label: section.title,
          value: section.id,
        });
      });
      return sections;
    };

    const validateQuestionID = (data) => {
      const pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      let result = data.match(pattern) ? true : false;
      return result;
    }
  
    const validateQuestion = (data) => {
      if (data.type === FIELD_TYPES.SHORT_ANSWER && !data?.validation?.includes('none')) {
        return true;
      } else {
        return false;
      }
    }
  
    const handleConfirm = async () => {
      // Prioritize manual input over drop down selection
      if (searchQuestionId) {
        if (validateQuestionID(searchQuestionId)) {

          const { data } = await questionsService.getRequiredQuestionById(searchQuestionId, true, schema.formSettings.methodologyVersionId);

          const selectedQuestion = {
            label: data.label,
            type: data.type,
            validation: data.validation,
            value: data.id
          }; 
  
          if (selectedQuestion === undefined || !validateQuestion(selectedQuestion)) {
            //Valid GUID but invalid question selected
            setHelperText(<div style={{ color: 'red', fontWeight: 'bold' }}>{ERROR.QUESTION_ID_INVALID}</div>);
            return;
          } else {
            handleClickSelectQuestion(selectedQuestion);
            setHelperText('');
            setVisible(false);
            setSearchQuestionId();
          }
  
        } else {
          setHelperText(<div style={{ color: 'red', fontWeight: 'bold' }}>{ERROR.QUESTION_ID_INVALID}</div>);
        }
  
      } else if (questionId && questionOptions) {
        const selectedQuestion = questionOptions.find(
          (question) => question.value == questionId
        );
        if (validateQuestion(selectedQuestion)) {
          handleClickSelectQuestion(selectedQuestion);
          setVisible(false);
          setQuestionId();
        } else {
          setHelperText(<div style={{ color: 'red', fontWeight: 'bold' }}>{ERROR.QUESTION_ID_INVALID}</div>);
        }
      }
    };
  
    async function fetchFormOptions() {
      try {
        setLoading(true);
        const { data } = await formCategoriesService.getFormsByCategoryId(
          categoryId
        );
        return data.filter((e) => e.MethodologyVersionId === methodologyVersionId).map((e) => {
          return {
            label: e.FormName,
            value: e.FormId,
          };
        });
      } catch (error) {
        toast.error(error.toString());
      } finally {
        setLoading(false);
      }
    }
  
    async function fetchFormSchema() { 
      const applicableTypes = [
        FIELD_TYPES.SHORT_ANSWER
      ];
      try {
        setLoading(true); 
        const data = await formServices.getFormWithSchemaByFormId(
          formId
        );
        setFormSchema(data?.formSchema);
        setFormSectionOptions(
          data?.formSchema.flatMap((e) => {
            return {
              label: e.title,
              value: e.id,
            };
          })
        );
        const options = data?.formSchema.flatMap((section) =>
          section.fields.flatMap((row) =>
            row.flatMap((e) => {
              return {
                label: e.label,
                value: e.id,
                type: e.type,
                validation: e.validation
              };
            })
          )
        );
        const filteredOptions = options?.filter((option) => {
          if (!option?.validation?.includes('none') && applicableTypes?.includes(option.type)) {
            return option;
          }
        });
        setLoading(false);
        setQuestionOptions(filteredOptions);
      } catch (error) {
        toast.error(error.toString());
      } finally {
        setLoading(false);
      }
    }
  
    function updateQuestionOptions() {
      const applicableTypes = [
        FIELD_TYPES.SHORT_ANSWER
      ];
      if (sectionId) {
        const section = formSchema?.find((section) => section.id === sectionId);
        if (section) {
          let options = section.fields
            .flatMap((row) => row)
            .flatMap((field) => ({
              label: field.label,
              value: field.id,
              type: field.type,
              validation: field.validation
            }));  

          options = options?.filter((option) => {
            if (!option?.validation?.includes('none') && applicableTypes?.includes(option.type)) {
              return option;
            }
          }); 
          setQuestionOptions(options);
        }
      } else {
        if (formId) fetchFormSchema();
      }
    }

    useEffect(() => {
      const fetchCategoryData = async () => {
        try {
          const { data } = await formCategoriesService.getFormCategories();
          setFormCategoryOptions(
            data.map((e) => ({
              label: e.FormCategoryName,
              value: e.FormCategoryId,
            }))
          );
        } catch (error) {
          toast.error(error.toString());
        }
      };
      fetchCategoryData();
    }, []);

    useEffect(() => {
      if (Array.isArray(schema?.properties)) {
        const sections = findSections(schema.properties);
        setSections(sections);
      }
    }, [schema?.properties]);
  
    useEffect(() => {
      if (!visible) {
        setFormOptions([]);
        setFormSectionOptions([]);
        setQuestionOptions([]);
      }
    }, [visible]);
  
    useEffect(async () => {
      if (categoryId) {
        const formOpts = await fetchFormOptions();
        setFormOptions(formOpts);
      }
    }, [categoryId]);
  
    useEffect(() => {
      if (formId) fetchFormSchema();
    }, [formId]);
  
    useEffect(() => {
      updateQuestionOptions();
    }, [sectionId]);
  
    useEffect(async () => {
      const init = async () => {
        const formOpts = await fetchFormOptions();
        setFormOptions(formOpts);
        const found = formOpts.find((form) => form.label === schema?.formSettings?.formName);
        if (found?.label) {
          setFormId(found.value);
        }
      };
      init();
    }, []);
    return (
      <Grid container spacing={4} pt={4} sx={{ paddingTop: '0' }}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <CLADropdown
                  label="Select Form Category"
                  labelType={FORM_LABEL_TYPES.OUTER}
                  value={categoryId}
                  options={formCategoryOptions}
                  onChange={(e) => {
                    setLoading(true);
                    setFormId('');
                    setSectionId('');
                    setQuestionId('');
                    setCategoryId(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CLADropdown
                  label="Select Form"
                  labelType={FORM_LABEL_TYPES.OUTER}
                  placeholder="Select"
                  value={formId}
                  options={formOptions}
                  onChange={(e) => {
                    setSectionId('');
                    setQuestionId('');
                    const found = formOptions.find(
                      (form) => form.value === e.target.value
                    );
                    setFormId(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CLADropdown
                  emptyCanBeSelected
                  label="Select Section"
                  labelType={FORM_LABEL_TYPES.OUTER}
                  placeholder="Select"
                  value={sectionId}
                  options={formSectionsOptions ?? sections}
                  isDisabled={!formId}
                  onChange={(e) => {
                    setQuestionId('');
                    setSectionId(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CLADropdown
                  label="Select Question"
                  labelType={FORM_LABEL_TYPES.OUTER}
                  placeholder="Select"
                  value={questionId}
                  isDisabled={!formId}
                  options={questionOptions}
                  onChange={(e) => {
                    setQuestionId(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography
                    variant="label"
                    sx={{
                      marginBottom: '8px',
                    }}
                  >
                    <strong>OR</strong> paste in question ID from the source
                  </Typography>
                  <TextField
                    name="searchByQuestionID"
                    placeholder="Question ID"
                    maxLength={256}
                    onChange={(e) => {
                      setSearchQuestionId(e.target.value);
                    }}
                    helperText={helperText}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={handleConfirm}>
                  OK
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  };