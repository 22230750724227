import { Box } from '@mui/material';
import { useState, useEffect, useRef, useCallback } from 'react';
import { UNDERSTANDING_OF_ENTITY, WAITING_TIME } from '@ais/constants';
import styles from './UOEAuditAreaCollapsibles.module.css';
import { ConcurrentInternalControlTextbox } from '../../../ConcurrentInternalControlTextbox';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext';
import { useRoomIdle, useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { useOthers } from '@components/Concurrency/store/users';
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useProjectFormInstanceProvider } from '@providers';

const UOEConstants = UNDERSTANDING_OF_ENTITY;
const UOEFinancialStatementConstants = UOEConstants.AUDIT_AREAS.FINANCIAL_STEMENT_PROCESS;
const UOEInfoProcessingConstants = UNDERSTANDING_OF_ENTITY.INFORMATION_PROCESSING;
const UOEComments = UOEConstants.COMMENTS;

export const FinanceStatementProcessContent = ({
    financeStatementData,
    isCommentOpen,
    saveInternalControlData,
    isProjectFinalized,
    signOffList
}) => {
    const {
        formattedInternalControls,
        changeFirstLevelField
    } = useInternalControlsContext();
    
    const { userId } = useProjectFormInstanceProvider();
    const [fieldHighlight, setFieldHighlight] = useState(false)
    const activeElementRef = useRef(null)

    const updateMyPresence = useUpdateMyPresence();
    const others = useOthers();
    const [currentFocusedField, setCurrentFocusedField] = useState(null)
    const [data, setData] = useState(financeStatementData)
    const [comment, setComment] = useState(formattedInternalControls?.FinancialStatementICComment)
    const [financialStatement1, setFinancialStatement1] = useState(data?.FinancialStatementICInfoProcessingAndControlActivity1)
    const [financialStatement2, setFinancialStatement2] = useState(data?.FinancialStatementICInfoProcessingAndControlActivity2)
    const [financialStatement3, setFinancialStatement3] = useState(data?.FinancialStatementICInfoProcessingAndControlActivity3)
    const [financialStatement4, setFinancialStatement4] = useState(data?.FinancialStatementICInfoProcessingAndControlActivity4)
    const [financialStatement5, setFinancialStatement5] = useState(data?.FinancialStatementICInfoProcessingAndControlActivity5)
    const [financialStatement6, setFinancialStatement6] = useState(data?.FinancialStatementICInfoProcessingAndControlActivity6)
    const [previousValue, setPreviousValue] = useState(null)
    const isIdle = useRoomIdle()
    const commentRef = useRef(null)

    const getFinancialStatementState = useCallback((key) => {
        switch (key) {
          case 'FinancialStatementICInfoProcessingAndControlActivity1':
            return [financialStatement1 || '', setFinancialStatement1];
          case 'FinancialStatementICInfoProcessingAndControlActivity2':
            return [financialStatement2 || '', setFinancialStatement2];
          case 'FinancialStatementICInfoProcessingAndControlActivity3':
            return [financialStatement3 || '', setFinancialStatement3];
          case 'FinancialStatementICInfoProcessingAndControlActivity4':
            return [financialStatement4 || '', setFinancialStatement4];
          case 'FinancialStatementICInfoProcessingAndControlActivity5':
            return [financialStatement5 || '', setFinancialStatement5];
          case 'FinancialStatementICInfoProcessingAndControlActivity6':
            return [financialStatement6 || '', setFinancialStatement6];
        }
    }, [
        financialStatement1, financialStatement2, financialStatement3,
        financialStatement4, financialStatement5, financialStatement6
    ]);

    useEffect(() => {
        setFinancialStatement1(data?.FinancialStatementICInfoProcessingAndControlActivity1)
    }, [data?.FinancialStatementICInfoProcessingAndControlActivity1])

    useEffect(() => {
        setFinancialStatement2(data?.FinancialStatementICInfoProcessingAndControlActivity2)
    }, [data?.FinancialStatementICInfoProcessingAndControlActivity2])

    useEffect(() => {
        setFinancialStatement3(data?.FinancialStatementICInfoProcessingAndControlActivity3)
    }, [data?.FinancialStatementICInfoProcessingAndControlActivity3])

    useEffect(() => {
        setFinancialStatement4(data?.FinancialStatementICInfoProcessingAndControlActivity4)
    }, [data?.FinancialStatementICInfoProcessingAndControlActivity4])

    useEffect(() => {
        setFinancialStatement5(data?.FinancialStatementICInfoProcessingAndControlActivity5)
    }, [data?.FinancialStatementICInfoProcessingAndControlActivity5])

    useEffect(() => {
        setFinancialStatement6(data?.FinancialStatementICInfoProcessingAndControlActivity6)
    }, [data?.FinancialStatementICInfoProcessingAndControlActivity6])

    useEffect(() => {
        if (isIdle) {
            if (currentFocusedField === UOEFinancialStatementConstants.COMMENT_FIELD_KEY) {
                setComment(formattedInternalControls?.FinancialStatementICComment)
                commentRef.current?.blur()
                updateMyPresence({ focusedId: null })
            } else {
                const key = Object.keys(UOEFinancialStatementConstants.LABELS)[currentFocusedField]
                const previousValue = financeStatementData[key]
                setData(prev => {
                    const _copy = { ...prev }
                    return { ..._copy, [key]: previousValue }
                })
                activeElementRef.current?.blur()
                updateMyPresence({ focusedId: null, type: 'custom' })
            }
        }
    }, [isIdle])

    useEffect(() => {
        setComment(formattedInternalControls?.FinancialStatementICComment)
    }, [formattedInternalControls?.FinancialStatementICComment])

    useEffect(() => {
        if (!financeStatementData) return
        setData(financeStatementData)
    }, [financeStatementData])

    const handleBlur = (event, key) => {
        setCurrentFocusedField(null)
        if (isIdle) {
            setPreviousValue(null)
            activeElementRef.current = null
            return
        }
        updateMyPresence({ focusedId: null, type: 'custom' })
        if (previousValue === event.target.value) return;
        changeFirstLevelField(key, event.target.value)
        saveInternalControlData({
            [key]: event.target.value
        })
        activeElementRef.current = null
        setPreviousValue(null)
    };

    const handleCommentBlur = (e) => {
        if (isIdle) {
            commentRef.current = null
            return
        }

        updateMyPresence({ focusedId: null, type: 'custom' })
        changeFirstLevelField(
            UOEFinancialStatementConstants.COMMENT_FIELD,
            e.target.value
        );

        saveInternalControlData({
            [UOEFinancialStatementConstants.COMMENT_FIELD]: e.target.value
        });
        commentRef.current = null
    }

    useEffect(() => {
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        const dateModified = new Date(formattedInternalControls.ValidFrom).getTime();
        const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
        const shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
        const isHighLight = isSignoffEnabled && shouldHighlight;

        setFieldHighlight(isHighLight)

    }, [formattedInternalControls, signOffList])

    const updateActiveRef = element => activeElementRef.current = element

    return (
        <Box className={styles.contentContainer}>
            <Box sx={fieldHighlight ? { borderStyle: "dashed", borderWidth: "4px", borderColor: "red", p: "10px", mb: "10px" } : {}} >
                {Object.keys(UOEFinancialStatementConstants.LABELS).map((item, index) => {
                    const isFinancialStatement6 = item === 'FinancialStatementICInfoProcessingAndControlActivity6';
                    const [value, setValue] = getFinancialStatementState(item)
                    return (

                        <div
                            className={styles.textfieldContainer}
                            style={{ marginBottom: isFinancialStatement6 ? 0 : null }}
                            key={`financialStatementInfo-${index}`}>
                            <span className={styles.numberedText}>
                                {UOEFinancialStatementConstants.LABELS[item]}
                            </span>
                            <VFRenderedFieldWrapper
                                className={`${styles.textfieldContainer} ${styles["field__wrapper"]}`}
                                style={{ marginBottom: 0 }}
                                isLockedByUser={others.find((user) => user.presence.focusedId === `financial-statement-${index}` && user.info.userId.toLowerCase() !== userId.toLowerCase())}
                            >
                                <ConcurrentInternalControlTextbox
                                    label={UOEInfoProcessingConstants.LABEL}
                                    placeholder={UOEInfoProcessingConstants.PLACEHOLDER}
                                    value={value}
                                    defaultValue={value}
                                    onKeyUp={(event) => setValue(event.target.value)}
                                    maxLength={null}
                                    onBlur={(e) => {
                                        handleBlur(e, item)
                                    }}
                                    onFocus={(event) => {
                                        const fieldId = `financial-statement-${index}`
                                        updateMyPresence({ focusedId: fieldId, type: 'custom' })
                                        setCurrentFocusedField(index)
                                        setPreviousValue(data[item])
                                        updateActiveRef(event.target)
                                    }}
                                    customFormObjectId={`financial-statement-${index}`}
                                    disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === `financial-statement-${index}` && user.info.userId.toLowerCase() !== userId.toLowerCase())}
                                />
                            </VFRenderedFieldWrapper>
                        </div>
                    )
                })}
            </Box>
            {
                !!isCommentOpen &&
                <VFRenderedFieldWrapper
                    className={`${styles.textfieldContainer} ${styles["field__wrapper"]}`}
                    isLockedByUser={others.find((user) => user.presence.focusedId === UOEFinancialStatementConstants.COMMENT_FIELD_KEY && user.info.userId.toLowerCase() !== userId.toLowerCase())}
                >
                    <div style={{ marginTop: "20px" }}>
                        <ConcurrentInternalControlTextbox
                            label={UOEComments.LABEL}
                            placeholder={UOEComments.PLACEHOLDER}
                            value={comment || ''}
                            defaultValue={comment || ''}
                            nonMultilineLabelFontSize={UOEComments.NON_MULTILINE_LABEL_FONT_SIZE}
                            nonMultilineLabelMarginLeft={UOEComments.NON_MULTILINE_LABEL_MARGIN_LEFT}
                            maxLength={4096}
                            onBlur={handleCommentBlur}
                            onFocus={(event) => {
                                updateMyPresence({ focusedId: UOEFinancialStatementConstants.COMMENT_FIELD_KEY, type: 'custom' })
                                setCurrentFocusedField(UOEFinancialStatementConstants.COMMENT_FIELD_KEY)
                                commentRef.current = event.target
                            }}
                            customFormObjectId={UOEFinancialStatementConstants.COMMENT_FIELD_KEY}
                            disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === UOEFinancialStatementConstants.COMMENT_FIELD_KEY && user.info.userId.toLowerCase() !== userId.toLowerCase())}
                            onKeyUp={event => setComment(event.target.value)}
                        />
                    </div>
                </VFRenderedFieldWrapper>
            }
        </Box>
    );
}

export default FinanceStatementProcessContent;
