import React, { useEffect, useState } from 'react'
import { IconButton, Collapse } from '@mui/material';

import { useQuery, useScroll } from "@hooks/index";
import string from "@utilities/stringHelper";

import {CollapseIconComponent, ExpandIconComponent} from "@ais/assets";

const InherentRiskFactorsAccordion = ({ title, children, style }) => {

    const query = useQuery();
    const sectionId = query.get('sectionId');
    const [executeScroll, elRef] = useScroll();
    const [isExpanded, setIsExpanded] = useState(true);
    const shouldScrollToSection = "riskfactor" === string.toLowerCaseTrim(sectionId);
    useEffect(() => {
        if (shouldScrollToSection) {
            executeScroll();
        }
    }, []);
    return (
        <div ref={elRef} className={style.irfSectionWrapper}>
            <div className={style.irfSection}>
                <div className={style.sectionHeaderWrapper}>
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                    </IconButton>
                    <div className={style.sectionHeader}>{title}</div>
                </div>
                <Collapse in={isExpanded} collapsedSize={0}>
                    {children}
                </Collapse>
            </div>
        </div>
    )
}

export default InherentRiskFactorsAccordion