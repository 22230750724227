export const AIS_DASHBOARD = {
    EN: {
        ADMIN: 'Administrator',
        PAGE_TITLE: 'AIS Dashboard',
        FIND_PROJECT_TO_ADD: 'Find a Project to Add',
        ACTIVE_PROJECTS: 'Active Projects',
        FINALIZED_PROJECTS: 'Finalized Projects',
        FIND_PROJECT_TO_REMOVE: "Remove All Projects",
        PROJECT_TABLE: {
            TITLES: {
                CLICK_HEADING_SORT_FILTER: 'Click Heading to sort and filter', 
                AVAILABLE_PROJECTS: 'Available Projects'
            },
            COLUMNS: {
                CLIENT_NAME: 'Client Name',
                PROJECT_ID: 'Project ID',
                PROJECT_NAME: 'Project Name',
                YEAR_END: 'Year End',
                PROJECT_YEAR: 'Project Year',
                PROJECT_STATE: 'Project State',
                PROJECT_TYPE: 'Project Type',
                ENGAGEMENT_LEADER: 'Engagement Leader',
                OFFICE: 'Office',
                INDUSTRY: 'Industry',
                PROGRESS: 'Progress',
                UPDATED: 'Updated'
            },
            MESSAGES: {
                NO_RESULTS_AFTER_FILTER: 'No projects match filter criteria.'
            }
        },
        PROJECT_CARD_CONTEXT_MENU: {
            OPEN: 'Open',
            EDIT_PROJECT_SETUP: 'Edit Project Setup',
            REMOVE_FROM_DASHBOARD: 'Remove from Dashboard', 
        },
        PROJECT_CARD_FINALIZED_CONTEXT_MENU: {
            OPEN: 'Open',
            EDIT_PROJECT_SETUP: 'Edit Project Setup',
            REMOVE_FROM_DASHBOARD: 'Remove from Dashboard',
            UN_FINALIZE_PROJECT: 'Un-Finalize Project'
        },
        PROJECT_FORM_CONTEXT_MENU: {
            VIEW_AND_EDIT: 'View/Edit',
            RENAME: 'Rename',
            DUPLICATE: 'Duplicate',
            DELETE: 'Delete',
            MANAGE_UNITS: 'View/Manage Units',
            IMPORT_FROM_ANOTHER_PROJECT: 'Import from Another Project'
        },
        ACCORDION_IDS: {
            ACTIVE_PROJECT_PANEL: 'active-project-panel',
            FINALIZED_PROJECT_PANEL: 'finalized-project-panel'
        },
        MODAL: {
            DEFAULT: {
                TITLE: 'Warning',
                CANCEL: 'Cancel',
                CONFIRM: 'OK'
            },
            UN_FINALIZE: {
                MESSAGE: {
                    LINE1: 'Un-finalizing a finalized project will change the project state back to "Active".',
                    LINE2: 'Do you want to proceed?'
                }
            }
        }
    }
}

export const ACTIVE_STATE = 'Active'

export const FINALIZED_STATE = 'Finalized'