import { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@ais/palette";

import {parseQuestionLabel} from '@ais/forms';
import { DIALOG, TRIGGERS } from '@constants/forms';
import { SchemaContext } from '@ais/providers';
import {
  createField,
  dropNewItem,
  updateFormItem,
  filterEmptyCriterias,
  isTriggeredFromQuestionGroup,
  getShareableComponentDefaultValue
} from '@components/Forms/helpers';
import { BaseDialog } from '.';
import { BasicTextField, ToggleableTextarea, ExternalAccess } from './dialog-components/settings';
import { RulesTab } from './dialog-components/rules';
import ColumnBuilder from './dialog-components/settings/ColumnBuilder';
import RowBuilder from './dialog-components/settings/RowBuilder';

const processInitialValues = (additionalData, trigger) => {
  const parsed = parseQuestionLabel(additionalData?.label);
  const initialValues = {
    label: additionalData?.label ?? '',
    columns: additionalData?.columns ?? [],
    rows: additionalData?.rows ?? [],
    disableRowLabel: additionalData?.disableRowLabel ?? false,
    required: additionalData?.required ?? true,
    validation: additionalData?.validation ?? 'none',
    tooltip: additionalData?.tooltip ?? '',
    width: 100,
    rules:
      typeof additionalData?.rules === 'object'
        ? additionalData.rules
        : {
            behavior: 'show',
            match: 'any',
            criterias: [],
            excludeAnswerOnRollForward: false,
            canNotBeDeleted: true,
          },
    visibleToClient: getShareableComponentDefaultValue(additionalData, "visibleToClient", trigger),
    editableByClient: getShareableComponentDefaultValue(additionalData, "editableByClient", trigger),
  };
  if (typeof parsed === 'string') {
    initialValues.label = additionalData?.label;
  } else if (typeof parsed === 'object') {
    initialValues.label = parsed.questionLabel;
    initialValues.columns = parsed.columns;
    initialValues.rows = parsed.rows;
  }
  return initialValues;
};

export const TableDialog = ({ visible, setVisible, additionalData, trigger }) => {
  const { LABEL, PLACEHOLDER } = DIALOG.SETTINGS;
  const { schema, dispatchSchema } = useContext(SchemaContext);

  const initialValues = processInitialValues(additionalData, trigger);

  const schemaValidation = Yup.object().shape({
    label: Yup.string().trim().required(''),
    columns: Yup.array().ensure().test('column_validation', ' ', function (value) {
      if (value.some((item) => !item.columnLabel)) return this.createError({ message: 'Column Name Required' });
      if (value.some((item) => !item.columnFieldType)) return this.createError({ message: 'Column Field Type Required' });
      if (value.some((item) => item.columnFieldType === 'shortAnswer' && !item?.validation))
        return this.createError({ message: 'Validation is Required' });
      if (value.some((item) => item.columnFieldType === 'dropdown' && !item?.dropdownOptions?.length > 0))
        return this.createError({ message: 'Dropdown Options is Required' });
      if (value.some((item) => item.columnFieldType === 'radioButton' && item?.radioOptions?.some((radioOption) => radioOption === '')))
        return this.createError({ message: 'Radio Options is Required' });
      if (value.some((item) => item.columnFieldType === 'radioButton' && !item?.radioDefaultValue))
        return this.createError({ message: 'Radio Options is Required' });

      return true;
    })
  })

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation)
  });

  const handleSubmit = (values) => {
    const filteredRules = filterEmptyCriterias(values.rules);
    const stringified = JSON.stringify({
      questionLabel: values.label,
      columns: values.columns,
      rows: values.rows,
    });
    if (![TRIGGERS.EDIT_BTN, TRIGGERS.QUESTION_GROUP_EDIT].some((t) => t === trigger)) {
      const newField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.refField.index,
        ...values,
        label: stringified,
        width: +values.width,
        rules: filteredRules,
      });
      dropNewItem(additionalData, newField, trigger, schema, dispatchSchema);
    } else {
      const updateField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.index,
        ...values,
        label: stringified,
        width: +values.width,
        rules: filteredRules,
      });
      updateFormItem(additionalData, updateField, schema, dispatchSchema);
    }
    setVisible(false);
  }

  useEffect(() => {
    formMethods.reset(initialValues);
    formMethods.trigger();
  }, [visible]);

  const tabs = [
    {
      label: DIALOG.SETTINGS.LABEL.SETTINGS_COLUMN,
      render: () => (
        <Grid container rowSpacing={6} columnSpacing={22}>
          <Grid item xs={12}>
            <BasicTextField
              name="label"
              label={LABEL.QUESTION_LABEL}
              placeholder={PLACEHOLDER.QUESTION}
              maxLength={256}
              showRequired
            />
          </Grid>
          <Grid item xs={12}>
            <ColumnBuilder additionalData={additionalData} />
          </Grid>
          <Grid item xs={12}>
            <Grid container height="100%" rowSpacing={5}>
              <Grid item xs={12}>
                <ToggleableTextarea
                  name="tooltip"
                  label={LABEL.ADD_TOOLTIP}
                  placeholder={PLACEHOLDER.TEXT}
                  toggleable
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {!isTriggeredFromQuestionGroup(trigger) && (
              <ExternalAccess
                allowExternalAccessName="visibleToClient"
                makeAnswerableName="editableByClient"
              />
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      label: DIALOG.SETTINGS.LABEL.ROWS,
      render: () => <RowBuilder additionalData={additionalData} />,
    },
    {
      label: DIALOG.SETTINGS.LABEL.RULES,
      render: () => <RulesTab additionalData={additionalData} trigger={trigger} />,
    },
  ];

  return (
    <Form form={formMethods}>
      <BaseDialog
        id={isTriggeredFromQuestionGroup(trigger) ? '' : additionalData?.id}
        idPrefix={isTriggeredFromQuestionGroup(trigger) ? '' : LABEL.QUESTION_ID}
        title={LABEL.TABLE}
        visible={visible}
        setVisible={setVisible}
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        tabs={
          isTriggeredFromQuestionGroup(trigger)
            ? tabs.filter((tab) => tab.label !== LABEL.RULES)
            : tabs
        }
      />
    </Form>
  );
};
