import React, { useState } from 'react';
import PropTypes from "prop-types";
import {
    Box,
    Collapse,
    IconButton,
    Typography
} from '@mui/material';

import {CollapseIconComponent, ExpandIconComponent} from "@ais/assets";
import { boxRowStyles, boxColumnStyles, header1Style } from './muiStyles';
import { CLA_METHODOLOGY } from '@constants/index';
import MethodologyVersionsContrastCollapsible from './MethodologyVersionsContrastCollapsible';

import styles from './MethodologyVersionsContrast.module.css';

const MethodologyVersionsContrast = (props) => {
    const { methodologyChangeReports } = props;
    const { REPORTS } = CLA_METHODOLOGY.EN;

    const [expandAll, setExpandAll] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <>
            <Box sx={boxRowStyles}>
                <div className={`${styles['collapse-icon-wrapper']} ${styles['questions-row']}`}>
                    <IconButton
                        sx={{ p: 0 }}
                        data-test="collapse-icon"
                        onClick={() => { setIsExpanded(prevIsExpanded => !prevIsExpanded) }}>
                        {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                    </IconButton>
                </div>
                <div className={`${styles.column} ${styles['questions-row']} ${styles['first-questions-column']}`}>
                    <Box sx={{
                        ...boxRowStyles,
                        width: '100%'
                    }}>
                        <Box sx={{ flexGrow: 1, alignItems: 'center' }}>
                            <Typography sx={header1Style}>
                                {REPORTS.QUESTIONS}
                            </Typography>
                        </Box>
                        <Box>
                            {expandAll
                                ? <a href="#expand" className={styles.expand_all_link} onClick={() => { setExpandAll(false); setIsExpanded(false); }}>{REPORTS.COLLAPSE_ALL}</a>
                                : <a href="#collapse" className={styles.expand_all_link} onClick={() => { setExpandAll(true); setIsExpanded(true); }}>{REPORTS.EXPAND_ALL}</a>}
                        </Box>
                    </Box>
                </div>
            </Box>
            <Collapse in={isExpanded} collapsedSize={0} data-test="collapse-wrapper">
                <Box sx={{ ...boxColumnStyles, ml: '30px', mb: '20px', fontSize: '14px' }}>
                    {methodologyChangeReports && methodologyChangeReports.map((report, index) =>
                        <MethodologyVersionsContrastCollapsible
                            key={index}
                            index={index}
                            form={report}
                            isExpandAll={expandAll}
                            data-test="collapse-component"
                        />)}
                </Box>
            </Collapse>
        </>
    );
};

MethodologyVersionsContrast.propTypes = {
    methodologyChangeReports: PropTypes.array
}

export default MethodologyVersionsContrast;