import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import PropTypes from 'prop-types';

export const CLAActionEllipsis = (props) => {
  // Declare constants
  const { children, autofocus, disabled, anchorOrigin, onFocus, onCancelMenu } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  // Declare Events - Needed to mount child elements to parent
  const handleClick = (e) => {
    if (onFocus) onFocus();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (fromMenu = false) => {
    if (fromMenu && onCancelMenu) onCancelMenu();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="action-ellipsis"
        className="action-ellipsis"
        data-testid="action-ellipsis"
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        autoFocus={autofocus}
        className="action-menu"
        anchorEl={anchorEl}
        keepMounted
        aria-label="action-menu"
        data-testid="action-ellipsis-menu"
        open={Boolean(anchorEl)}
        onClose={() => handleClose(true)}
        anchorOrigin={anchorOrigin}
      >
        {children(handleClose)}
      </Menu>
    </div>
  );
};

CLAActionEllipsis.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  anchorOrigin: PropTypes.object
}

CLAActionEllipsis.defaultProps = {
  autofocus: false,
  disabled: false,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  }
};

export default CLAActionEllipsis;
