import { FormControl, FormHelperText, Select, FormLabel, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

import { DIALOG } from '@constants/forms/index';

const LABEL = DIALOG.SETTINGS.LABEL;

export const CLAColumnsOptions = ({ labelType, value, onChange, touched, errors }) => {
  const formLabel = LABEL.COLUMNS_OPTIONS.replace('{0}', labelType);

  return (
    <FormControl
      fullWidth
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <FormLabel sx={{ whiteSpace: 'nowrap' }}>{formLabel}</FormLabel>
      <Select
        name="columns"
        labelId="presentation"
        id="presentation"
        value={value}
        onChange={onChange}
        error={touched && Boolean(errors)}
      >
        <FormHelperText>{touched && errors}</FormHelperText>
        {[...Array(10)].map((_el, index) => (
          <MenuItem key={index} value={index + 1}>
            {index + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CLAColumnsOptions.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  touched: PropTypes.bool,
  errors: PropTypes.string,
};

CLAColumnsOptions.defaultProps = {
  value: 1,
  touched: false,
  errors: '',
};
