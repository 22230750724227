import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, MenuItem } from '@mui/material';

import {CLAActionEllipsis} from '@ais/forms';

const TooltipLink = ({ children, disabled, onClick }) => (
  <Link style={{textDecoration: 'none'}}>
    <MenuItem variant="ellipsis-menu" disabled={disabled} onClick={onClick}>
      {children}
    </MenuItem>
  </Link>
);

export const ActionCell = ({ onDeleteClick, onEditClick, disabled }) => {
  const handleOnDeleteClick = (event, handleClose) => {
    event.preventDefault();
    handleClose();
    onDeleteClick();
  };
  const handleEditClick = (event, handleClose) => {
    event.preventDefault();
    handleClose();
    onEditClick();
  };

  return (
    <Typography component="span" sx={{ m: '-9px 0 0 0' }}>
      <CLAActionEllipsis disabled={disabled}>
        {(handleClose) => [
          <TooltipLink key="openedit" onClick={event => handleEditClick(event, handleClose)}>
            Open/Edit
          </TooltipLink>,
          <TooltipLink
            key="delete"
            onClick={event => handleOnDeleteClick(event, handleClose)}
          >
            Delete
          </TooltipLink>,
        ]}
      </CLAActionEllipsis >
    </Typography>
  );
};
