import formApi from "@utilities/claApiForm"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

const fetchDeficiencies = async (projectFormId, projectId, projectScopeDeficiencySummaryId, unitIds, requestProjectFormId) => {
    try {
        const response = await formApi.get(`/project-deficiencies/${projectId}`, {
            params: {
                ProjectId: projectId,
                ProjectFormId: projectFormId,
                ProjectScopeDeficiencySummaryId: projectScopeDeficiencySummaryId,
                ProjectUnitIds: unitIds?.join() ?? [],
                RequestProjectFormId: requestProjectFormId
            }
        });
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
};

const updateDeficiency = async (projectDeficiencyId, body, projectId) => {
    try {
        const response = await formApi.put(`/project-deficiencies/${projectDeficiencyId}/${projectId}`, body);
        return { status: 201, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
};

const updateDeficiencyComment = async (projectDeficiencyId, body, projectId) => {
    try {
        const response = await formApi.put(`/project-deficiencies/${projectDeficiencyId}/comment/${projectId}`, body);
        return { status: 201, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
};

const deleteDeficiency = async (projectDeficiencyId, projectId) => {
    try {
        const { data } = await formApi.delete(`/project-deficiencies/${projectDeficiencyId}/${projectId}`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
}

const deficienciesServices = {
    fetchDeficiencies,
    updateDeficiency,
    updateDeficiencyComment,
    deleteDeficiency,
}

// EXPORTS ======

export const DEFICIENCY_QUERY_KEYS = {
    ALL: ["deficiencies"],
    PER_PROJECT: (projectId) => [ ...DEFICIENCY_QUERY_KEYS.ALL, "per_project", projectId]
}

export const useGetProjectDeficiencies = (projectFormId, projectId, projectScopeDeficiencySummaryId, unitIds, requestProjectFormId) => {
    return useQuery(
        ["deficiencyEvaluationForm", projectFormId, projectId, projectScopeDeficiencySummaryId, unitIds, requestProjectFormId],
        () => fetchDeficiencies(projectFormId, projectId, projectScopeDeficiencySummaryId, unitIds, requestProjectFormId),
        {
            enabled: !!projectId
        }
    );
};

export const useProjectDeficiencies = (projectId) => {
    return useQuery(
        DEFICIENCY_QUERY_KEYS.PER_PROJECT(projectId),
        async () => {
            const { data } = await fetchDeficiencies(null, projectId, null, null)
            return data
        },
        {
            enabled: !!projectId
        }
    );
};

export const useProjectDeficiencyEditCommentMutation = (projectDeficiencyId) => {
    const queryClient = useQueryClient()
    return useMutation(
        async (payload) => {
            return formApi.put(`/project-deficiencies/${projectDeficiencyId}/comment/${payload.projectId}`, payload.formData)
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(DEFICIENCY_QUERY_KEYS.ALL);
            }
        }
    )
}

export const useProjectDeficiencyDeleteMutation = (projectDeficiencyId) => {
    const queryClient = useQueryClient()
    return useMutation(
        async (projectId) => {
            return formApi.delete(`/project-deficiencies/${projectDeficiencyId}/${projectId}`)
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(DEFICIENCY_QUERY_KEYS.ALL);
            }
        }
    )
}

export const useProjectFormDeficiencies = (projectFormId, projectId) => {
    return useQuery(
        [ "projectFormDeficiencies", projectFormId ],
        async () => {
            const { data } = await formApi.get(`/project-deficiencies/${projectId}`, {
                params: {
                    ProjectFormId: projectFormId
                }
            })
            return data
        },
        {
            enabled: !!projectFormId,
            select: (deficiencies) => {
                return deficiencies.map((deficiency) => deficiency.FormSectionId)
            }
        }
    )
}

// ENDEXPORTS ======

export default deficienciesServices;