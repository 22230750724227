import formApi from "@utilities/claApiForm";

const getprojectScopeRiskCharacteristicsDefaultRisks = async (projectId, projectScopeRiskCharacteristicId) => {
    try {
        const response = await formApi.get(`/projectscoperiskcharacteristics/${projectScopeRiskCharacteristicId}/${projectId}`);
        return { status: 200, response };
    }
    catch (error) {
        return { status: 400, message: error };
    }
}

const projectScopeRiskCharacteristics = {
    getprojectScopeRiskCharacteristicsDefaultRisks
};

export default projectScopeRiskCharacteristics;