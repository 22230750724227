import formApi from '@utilities/claApiForm';

const saveProjectScopeAuditAreaCategory = async (projectId, projectScopeAuditAreaId, projectFormId, data) => {
  try {
    const response = await formApi.post(`/projectscopeauditareacategory/${projectId}/${projectScopeAuditAreaId}/${projectFormId}`, data);
    return { status: 201, data: response };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const getProjectScopeAuditAreaCategory = async (projectScopeAuditAreaId, projectId) => {
  try {
    const response = await formApi.get(`/projectscopeauditareacategory/${projectScopeAuditAreaId}/${projectId}`);
    return { status: 200, data: response.data };
  } catch (error) {
    return { status: 400, message: error };
  }
};

const projectScopeAuditAreaCategory = {
  saveProjectScopeAuditAreaCategory,
  getProjectScopeAuditAreaCategory
}

export default projectScopeAuditAreaCategory;