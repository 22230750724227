import { useState } from 'react';
import {
    OutlinedInput,
    IconButton,
    SvgIcon,
    FormControl,
    InputLabel
} from '@mui/material';
import { ReactComponent as Delete } from '@assets/delete_icon.svg';

import { DIALOG } from '@constants/forms';
import styles from './RowBuilder.module.css';


const RowBuilderRow = (props) => {
    const { id, row, onRowChange, onRowDelete, disableRowLabel } = props;
    const [rowLabel, setRowLabel] = useState(row.rowLabel);

    const handleChange = (value) => {
        const updatedValues = {
            ...row,
            rowLabel: value
        }
        onRowChange(id, updatedValues);
    };

    return <div className={styles['rb-row-wrapper']}>
        <div className={styles['rb-row-rowlabel']}>
            <FormControl fullWidth>
                <InputLabel shrink>{DIALOG.ROW_BUILDER.ROW_LABEL}</InputLabel>
                <OutlinedInput
                    label={DIALOG.ROW_BUILDER.ROW_LABEL}
                    onChange={(e) => {
                        handleChange(e.target.value);
                        setRowLabel(e.target.value)
                    }}
                    value={rowLabel}
                    disabled={disableRowLabel}
                    notched
                />
            </FormControl>
        </div>
        <div className={styles['rb-row-delete-icon']}>
            <IconButton
                onClick={() => onRowDelete(id)}
                sx={{
                    ':hover': {
                        backgroundColor: 'transparent',
                    },
                }}
                disabled={disableRowLabel}
            >
                <SvgIcon sx={{ fontSize: '30px' }}>
                    <Delete />
                </SvgIcon>
            </IconButton>
        </div>
    </div>;
}


export default RowBuilderRow;