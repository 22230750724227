import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import {useAppTypeProvider} from '@ais/providers';

import styles from './CLATooltip.module.css';

export const CLATooltip = styled(({ className, ...props }) => {
  const {isPortalApp} = useAppTypeProvider();

  return (
    <Tooltip 
      {...props} 
      arrow 
      classes={{ popper: `${styles['tooltip']} ${className} ${isPortalApp ? '' : styles['ext-tooltip']}` }}
    />
  )
})(({ theme }) => ({
  '& p': {
    margin: 0,
    span: {
      backgroundColor: 'inherit !important',
      color: 'inherit !important'
    }
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#373D3F',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#373D3F',
    padding: theme.padding,
    display: theme.display,
    top: theme.top
  },
}));

export default CLATooltip;
