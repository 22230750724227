import { createContext, useReducer, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CustomHistoryReducer } from '.';

const initialData = {
  lastPage: '',
};

const localState = JSON.parse(window.localStorage.getItem('customHistory'));

export const CustomHistoryContext = createContext({});

export const CustomHistoryProvider = ({ children }) => {
  const [customHistory, dispatchCustomHistory] = useReducer(
    CustomHistoryReducer,
    localState || initialData
  );

  useEffect(() => {
    window.localStorage.setItem('customHistory', JSON.stringify(customHistory));
  }, [customHistory]);

  const memoized = useMemo(
    () => ({ customHistory, dispatchCustomHistory }),
    [customHistory, dispatchCustomHistory]
  );

  return (
    <CustomHistoryContext.Provider value={memoized}>
      {children}
    </CustomHistoryContext.Provider>
  );
};

CustomHistoryProvider.propTypes = {
  children: PropTypes.node,
};
