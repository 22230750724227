import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ProjectFormInstanceV2 from "@views/ProjectFormInstance/v2/ProjectFormInstanceV2";

import { ProjectFormInstanceProviderV2 } from "@providers"
import loadable from "@loadable/component"
import { useGetProjectFormWithSchemaById, useGetAnswersByProjectFormId } from "@services/forms/projectforms";
import { FormProvider, useForm } from 'react-hook-form' 
import { RoomProvider } from "@components/Concurrency/provider/RoomProvider";
import useUserProviderState from "@contexts/UserContext"; 
const UnauthorizedErrorPage = loadable(() => import("@views/UnauthorizedErrorPage/UnauthorizedErrorPage.jsx"))
const ProjectFormRenderer = () => {
    const { projectId, projectFormId } = useParams();
    const { data: projectForm, isError, isLoading, isFetching, isFetched } = useGetProjectFormWithSchemaById(projectFormId, projectId);
    const { data: answers } = useGetAnswersByProjectFormId(projectFormId, projectId)
    const [userId, setUserId] = useState('')
    const formHook = useForm();
    const { getUser } = useUserProviderState();

    useEffect(() => {
        async function initData() {
            const user = await getUser();
            const userLocalId = user?.activeDirectoryId ?? '';

            setUserId(userLocalId);
        }

        initData()
    }, [])

    if (isError) return <UnauthorizedErrorPage />
    if (isLoading && isFetching && !isFetched) return <></>

    return (
        <FormProvider {...formHook}>
            <ProjectFormInstanceProviderV2 projectFormData={projectForm} answers={answers} userId={userId}>
                <RoomProvider roomId={projectForm.projectFormID} userId={userId}>
                    <ProjectFormInstanceV2 />
                </RoomProvider>
            </ProjectFormInstanceProviderV2>
        </FormProvider>
    )
}

export default ProjectFormRenderer