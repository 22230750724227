import { memo } from "react";
import { RadioGroup, Radio, Grid, FormControlLabel } from "@mui/material"

const RadioButtonList = (props) => {
  return (
    <RadioGroup
      row
      name={`tableRadio-${props.index}-${props.questionId}`}
      sx={{
        width: '100%'
      }}
    >
      <Grid container columns={2}>
        {props.options.map((val) => (
          <Grid key={val} xs={1}>
            <FormControlLabel
              control={
                <Radio 
                  disabled={props.disabled} 
                  checked={props.value===val}
                  onChange={props.onChange}
                />
              }
              value={val}
              label={val}
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  )
}

export default memo(RadioButtonList)