import formApi from "@utilities/claApiForm"
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"

export const fetchProjectRisks = async (projectRiskId, projectFormId, projectScopeId, projectId) => {
    try {
        const { data } = await formApi.get(`/projectrisk/${projectId}`, {
            params: {
                ProjectRiskId: projectRiskId,
                ProjectFormId: projectFormId,
                ProjectScopeId: projectScopeId,
                ProjectId: projectId
            }
        })
        return data;
    }
    catch (error) {
        return { status: 400, message: error };
    }
};

export const fetchLinkedProjectRisk = async (projectId, projectRiskId) => {    
    if (!projectId || !projectRiskId) {
        return;
    }

    try {
        const { data } = await formApi.get(`/projectrisk/${projectId}/${projectRiskId}/keycontrols`, {
            params: {
                projectRiskId,
                projectId
            }
        })
        return data;
    }
    catch (error) {
        return { status: 400, message: error };
    }
};

export const fetchLinkedFinancialStatementProjectRisk = async (projectId, projectRiskId) => {
    if (!projectId || !projectRiskId) {
        return;
    }

    try {
        const { data } = await formApi.get(`/projectrisk/${projectId}/${projectRiskId}/assertionlevelrisk`, {
            params: {
                projectRiskId,
                projectId,
            }
        });

        return data;
    } catch (error) {
        return { status: 400, message: error };
    }
}

export const useProjectRisks = (projectRiskId, projectFormId, projectScopeId, projectId) => {
    return useQuery(
        ["projectRisks", projectRiskId, projectFormId, projectScopeId, projectId],
        () => fetchProjectRisks(projectRiskId, projectFormId, projectScopeId, projectId),
        {
            enabled: !!projectRiskId || !!projectFormId || !!projectScopeId || !!projectId,
            refetchOnWindowFocus: false,
        }
    )
}

export const useLinkedProjectRisk = (projectId, projectRiskId) => {    
    const res = useQuery(
        ["LinkedProjectRisk", projectId, projectRiskId],
        () => fetchLinkedProjectRisk(projectId, projectRiskId),
        {
            enabled: !!projectId && !!projectRiskId,
            refetchOnWindowFocus: false,
        }
    );

    return res;
}

export const useLinkedFinancialStatementProjectRisk = (projectId, projectRiskId) => {
    const res = useQuery(
        ["LinkedFSProjectRisk", projectId, projectRiskId],
        () => fetchLinkedFinancialStatementProjectRisk(projectId, projectRiskId),
        {
            enabled: !!projectId && !!projectRiskId,
            refetchOnWindowFocus: false,
        }
    );

    return res;
}

export const useDeleteProjectRisks = (projectId) => {
    const queryClient = useQueryClient()

    return useMutation(
        (projectRiskId) => formApi.delete(`/projectrisk/${projectId}/${projectRiskId}`),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('projectRisks');
            }
        }
    )
}

export const useUpdateProjectRiskComment = (projectId) => {
    const queryClient = useQueryClient()

    return useMutation(
        ({ ProjectRiskId, Comment }) => formApi.put(`/projectrisk/${projectId}/comment`, { ProjectRiskId, Comment }),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('projectRisks');
            }
        }
    )
}

export const updateProjectRiskAssessmentSummary = async (projectId, projectRiskId, payload) => {
    try {
        const { data } = await formApi.put(`/projectrisk/${projectId}/risksummaryassessment/${projectRiskId}`, payload);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
}


export const getProjectRiskByAuditAreaAndProjectFormId = async (projectFormId, projectId) => {
    try {        
        const { data } = await formApi.get(`/projectrisk/${projectFormId}/${projectId}`)   
        
        const mappedData = data.map((v) => ({
            ...v,
            validFrom: v.ValidFrom
        }))

        return { status: 200, data: mappedData };
    } catch (error) {
        return { status: 400, message: error };
    }
}

export const useProjectRiskByAuditAreaAndProjectFormId = (projectFormId, projectId) => {
    return useQuery(
        ['projectRisksAuditAreaAndProjectFormId', projectFormId],
        () => getProjectRiskByAuditAreaAndProjectFormId(projectFormId, projectId),
        {
            staleTime: Infinity,
            enabled: !!projectFormId
        }
    )
}

export const updateProjectRiskByAuditAreaAndProjectFormId = async (projectId, summaryProcedureId, projectFormId, ids) => {
    try {
        const { data } = await formApi.put(`/projectrisk/${projectId}/projectForm/${projectFormId}/summaryProcedure/${summaryProcedureId}`, { 'ProjectRiskIds': ids })
        return { status: 200, data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

export const updateProjectRiskByProjectFormIdProjectFormCustomProcedureId = async (projectId, projectFormCustomProcedureId, projectFormId, ids) => {
    try {
        const { data } = await formApi.put(`/projectrisk/${projectId}/projectForm/${projectFormId}/customProcedure/${projectFormCustomProcedureId}`, { 'ProjectRiskIds': ids })
        return { status: 201, data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

export const createProjectRiskAuditAreaFinancialStatementProjectRisk = async (projectId, requestBody) => {
    try {
        const { data } = await formApi.post(`/projectriskauditareafinancialstatementprojectrisk/${projectId}`, requestBody)
        return { status: 200, data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

export const deleteProjectRiskAuditAreaFinancialStatementProjectRiskById = async (projectRiskAuditAreaId, financialStatementProjectRiskId, projectId, projectFormId) => {
    try {
        const { data } = await formApi.delete(`/projectriskauditareafinancialstatementprojectriskbyids/${projectId}/${projectFormId}/${projectRiskAuditAreaId}/${financialStatementProjectRiskId}`)
        return { status: 200, data };
    } catch (error) {
        return { status: 400, message: error };
    }
}

export const multiOpProjectRiskAuditAreaFinancialStatementProjectRisk = async (projectFormId, projectRiskAuditAreaId, projectScopeAuditAreaSCOTABDId, reqBody) => {
    try {
        const { data } = await formApi.post(`/projectriskauditareafinancialstatementprojectrisk/${projectFormId}/${projectRiskAuditAreaId}/${projectScopeAuditAreaSCOTABDId}`, reqBody)
        return { status: 204, data };
    } catch (error) {
        return { status: 400, message: error };
    }
}


const postProjectRisk = async (projectId, body) => {
    const response = await formApi.post(`/projectrisk/${projectId}`, body)
    if (response) {
        return { status: 200, data: response.data }
    }
    return { status: 400, message: 'POST: Project Risk response: ' + response }
}

export const usePostProjectRisk = (projectId) => {
    const queryClient = useQueryClient()

    return useMutation(
        (reqBody) => postProjectRisk(projectId, reqBody),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectRisk');
            }
        }
    )
}

const putProjectRisk = async (projectId, riskId, body) => {
    const response = await formApi.put(`/projectrisk/${projectId}/${riskId}`, body)
    if (response) {
        return { status: 200, data: response.data }
    }
    return { status: 400, message: 'PUT: Project Risk response: ' + response }
}

export const usePutProjectRisk = (projectId, riskId) => {
    const queryClient = useQueryClient()

    return useMutation(
        (reqBody) => putProjectRisk(projectId, riskId, reqBody),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectRisk');
            }
        }
    )
}

export const getKeyControlProjectRisk = async (projectId, projectFormId, projectScopeAuditAreaSCOTABDId) => {
    const response = await formApi.get(`/projectrisk/${projectId}/${projectFormId}/keycontrol?projectScopeAuditAreaSCOTABDId=${projectScopeAuditAreaSCOTABDId}`)
    const { data } = response;
    return data;
}

export const useGetKeyControlProjectRisk = (projectId, projectFormId, projectScopeAuditAreaSCOTABDId) => {
    return useQuery(
        ["keyControlProjectRisk", projectId, projectFormId, projectScopeAuditAreaSCOTABDId],
        () => getKeyControlProjectRisk(projectId, projectFormId, projectScopeAuditAreaSCOTABDId),
        {
            enabled: !!projectId && !!projectFormId && !!projectScopeAuditAreaSCOTABDId,
            refetchOnWindowFocus: false,
        }
    )
}
