import formApi from '@utilities/claApiForm';
import { useQuery } from "@tanstack/react-query"

const getFraudRiskTypes = async () => {
    try {
        const { data } = await formApi.get('/fraudrisktypes/enabled');
        return { status: 200, data };
    } catch (error) {
        return { status: 400, message: error };
    }
};

export const useFraudRiskTypes = () => {
    return useQuery(
        [ "fraudRiskTypes" ],
        () => getFraudRiskTypes().then(({data}) => data),
        {
            refetchOnWindowFocus: false,
        }
    )
}

const fraudRiskTypeServices = {
    getFraudRiskTypes,
    useFraudRiskTypes,
};

export default fraudRiskTypeServices;