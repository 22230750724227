import { createContext, useState } from "react"
import CustomToast from '@components/CustomToast/CustomToast';

export const CustomToastContext = createContext({
    setIsLoading: () => {},
    setIsSuccess: () => {},
    setIsError: () => {},
})

export const CustomToastProvider = ({ children }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(false)

    return (
        <CustomToastContext.Provider value={{
            setIsLoading,
            setIsSuccess,
            setIsError,
        }}>
            { children }
            <CustomToast 
                success={isSuccess}
                error={isError}
                loading={isLoading}
            />
        </CustomToastContext.Provider>
    )
}