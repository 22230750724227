import { useState, useEffect } from "react"

export const useSelectionState = (key, defaultValue = [], enableTrackingOfDeleted = false) => {
    const [ selections, setSelections ] = useState(defaultValue)
    const [ deletedSelections, setDeletedSelections ] = useState([])

    useEffect(() => {
        if(defaultValue && defaultValue.length > 0) {
            setSelections(defaultValue)
        }
    }, [ defaultValue ])

    const foundInDefaultValues = (selection) => {
        let items = defaultValue
        if(!items) {
            items = []
        }
        return items.findIndex((item) => item[key] === selection[key]) > -1
    }

    const selectOne = (selection) => {
        const index = selections.findIndex((currentSelection) => currentSelection[key] === selection[key])
		if (index > -1) {
            if(enableTrackingOfDeleted) {
                const willBeDeleted = selections[index]
                const deletedIdx = deletedSelections.findIndex((deletedSelection) => deletedSelection[key] === willBeDeleted[key])
                if(deletedIdx > -1) {
                    setDeletedSelections((prevDeleted) => [
                        ...prevDeleted.slice(0, deletedIdx),
                        ...prevDeleted.slice(deletedIdx + 1)
                    ])
                }
                else if(foundInDefaultValues(willBeDeleted)) {
                    setDeletedSelections((prevDeleted) => [ ...prevDeleted, willBeDeleted ])
                }
            }
			setSelections((prevSelected) => [
				...prevSelected.slice(0, index),
				...prevSelected.slice(index + 1),
			])
            return
		}
        if(enableTrackingOfDeleted) {
            const deletedIdx = deletedSelections.findIndex((deletedSelection) => deletedSelection[key] === selection[key])
            if(deletedIdx > -1) {
                setDeletedSelections((prevDeleted) => [
                    ...prevDeleted.slice(0, deletedIdx),
                    ...prevDeleted.slice(deletedIdx + 1)
                ])
            }
        }
        setSelections((prevSelected) => [...prevSelected, selection])
    }

    const selectMultiple = (multipleSelections) => {
        let selectionsCopy = Array.from(selections)
        let deletedSelectionsCopy = Array.from(deletedSelections)
        multipleSelections.forEach((selectedSelection) => {
            const index = selectionsCopy.findIndex((currentSelection) => currentSelection[key] === selectedSelection[key])
            if(index > -1) {
                if(enableTrackingOfDeleted) {
                    const willBeDeleted = selectionsCopy[index]
                    const deletedIdx = deletedSelectionsCopy.findIndex((deletedSelection) => deletedSelection[key] === willBeDeleted[key])
                    if(deletedIdx > -1) {
                        deletedSelectionsCopy = [
                            ...deletedSelectionsCopy.slice(0, deletedIdx),
                            ...deletedSelectionsCopy.slice(deletedIdx + 1)
                        ]
                    }
                    else if(foundInDefaultValues(willBeDeleted)) {
                        deletedSelectionsCopy = [ ...deletedSelectionsCopy, willBeDeleted ]
                    }
                }
                selectionsCopy = [
                    ...selectionsCopy.slice(0, index),
                    ...selectionsCopy.slice(index + 1),
                ]
                return
            }

            if(enableTrackingOfDeleted) {
                const deletedIdx = deletedSelectionsCopy.findIndex((deletedSelection) => deletedSelection[key] === selectedSelection[key])
                if(deletedIdx > -1) {
                    deletedSelectionsCopy = [
                        ...deletedSelectionsCopy.slice(0, deletedIdx),
                        ...deletedSelectionsCopy.slice(deletedIdx + 1)
                    ]
                }
            }
            selectionsCopy = [...selectionsCopy, selectedSelection]
        })
        setSelections(selectionsCopy)
        setDeletedSelections(deletedSelectionsCopy)
    }

    const selectOnly = (nextSelection) => setSelections((selections.indexOf(nextSelection) > -1) ? [] : [ nextSelection ])

    const selectAll = (nextSelections) => setSelections(nextSelections)

    const isSelected = (selected) => {
        const index = selections.findIndex((currentSelection) => currentSelection[key] === selected[key])
        return index > -1
    }

    const replaceSelection = (selectionKey, replacedSelection) => {
        const index = selections.findIndex((currentSelection) => currentSelection[key] === selectionKey)
        if(index < 0) return
        setSelections((prevSelected) => [
            ...prevSelected.slice(0, index),
            ...prevSelected.slice(index + 1),
            replacedSelection
        ])
    }

    return [ selections, deletedSelections, { selectOne, selectOnly, selectAll, isSelected, replaceSelection, selectMultiple } ]
}
