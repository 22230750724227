import React from 'react';

import { IconButton } from '@mui/material'; 
import { Comment, CommentResolved as EditComment} from '@ais/assets'; 
export const CommentCell = (props) => {
  const { commentAreaVisible, onClick, row } = props;

  const handleCommentClick = () => {
    if (onClick) {
      onClick(!commentAreaVisible);
    }
  }

  return (
    <IconButton
      onClick={handleCommentClick}
    >
      {row?.CommunicationComment && row?.CommunicationComment !== "" ? <EditComment /> : <Comment />}
    </IconButton>
  )
};
