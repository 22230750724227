import React from 'react';
import { Typography } from '@mui/material';

import string from "@utilities/stringHelper.js";

import { activateTooltip } from '../helpers';

export const TextCell = ({ value }) => {
  const component = (
    <Typography sx={{
      fontWeight: 600,
      fontSize: '14px',
      maxWidth: 200,
      m: '20px 20px 20px 10px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }}>
      {value}
    </Typography>
  );

  const result = string.truncateWithBool(value, 25);

  return (result.isTruncated ? activateTooltip(component, value) : component);
};
