import styles from '@components/CLANotification/CLANotification.module.css';
import closeIcon from '@assets/icon_circle_cross.svg';
import moment from 'moment';
import sanitize from 'sanitize-filename';

import { JOB_INSTANCE_STATUS } from '@constants';
import { NOTIFICATION_MESSAGES } from '@constants';
import { useNotificationContext } from '@providers/notifications/NotificationProvider';
import {
  downloadProjectPdfBlob,
  markNotificationAsNotified
} from '@services/projectPdf';

const CLANotificationItem = ({ notification, index }) => {
  const { updateNotification } = useNotificationContext();
  const { JobInstanceId, JobStatusId, JobId, ReqBody, CreatedDate, isRead } = notification;
  const requestBody = ReqBody ? JSON.parse(ReqBody): '';
  //  Making Notification filename based oon its JobId
  let notificationFileName = null;
  switch(JobId){
    case 1:
      notificationFileName = requestBody?.ClientName ?
        sanitize(`${requestBody.ClientName}-${requestBody.ProjectName}.pdf`) :
        'PDF';
  };

  const downloadPdf = async () => {
    const { ProjectId } = await JSON.parse(ReqBody);
    if (ProjectId) {
      await downloadProjectPdfBlob(ProjectId, notificationFileName);
    }
  }

   // Making text for notification based oon its JobStatusId
  let text = null;
  switch (JobStatusId) {
    case JOB_INSTANCE_STATUS.NOT_STARTED:
      text = NOTIFICATION_MESSAGES.NOT_STARTED;
      break;
    case JOB_INSTANCE_STATUS.STARTED:
      text = `The ${notificationFileName} ${NOTIFICATION_MESSAGES.IN_PROGRESS}`;
      break;
    case JOB_INSTANCE_STATUS.COMPLETED:
      text = `The ${notificationFileName} ${NOTIFICATION_MESSAGES.COMPLETED}`;
      break;
    case JOB_INSTANCE_STATUS.FAILED:
      text = `The ${notificationFileName} ${NOTIFICATION_MESSAGES.CREATION_FAILED}`;
      break;
    case JOB_INSTANCE_STATUS.NOTIFIED:
      text = `The ${notificationFileName} ${NOTIFICATION_MESSAGES.NOTIFIED}`;
      break;
    default:
      break;
  }
 
  const link = JobStatusId === JOB_INSTANCE_STATUS.COMPLETED ? (
    <a className={styles.claNotificationItemLink} onClick={() => { markNotificationAsNotified(JobInstanceId); downloadPdf();  }} href="#">
      {NOTIFICATION_MESSAGES.DOWNLOAD_PDF}
    </a>
  ) : null;

  const failedText = text;
  // Handling the local state of notification i.e isRead, is notification content clicked by user isDelete, is notification deleted by user 
  const handleUpdateNotification = (index, type, value) => {
    updateNotification(index, type, value);
  }
  
  return (
    <div style={{ display: 'flex' }}>
      <div className={styles.claNotificationItemHeader} onClick={() => handleUpdateNotification(index, 'isRead', true)}>
        <p className={styles.claNotificationItemDate}>{moment.utc(CreatedDate).format('MM/DD/YYYY')}</p>
        <p className={styles.claNotificationItemTextContent} style={{ fontWeight: isRead ? '400': '600'}}>
          {failedText}
        </p>
        {link}
      </div>
      <div style={{ padding: '5px' }}>
        <img
          className={styles.closeIcon}
          src={closeIcon}
          alt="closeIcon"
          onClick={() => handleUpdateNotification(index, 'isDelete', true)}
        />
      </div>
    </div>
  );
};

export default CLANotificationItem;