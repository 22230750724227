import {
    Box,
    Divider
} from '@mui/material';
import CustomProcedure from './CustomProcedure';
import { colors } from '@ais/theme';

const CustomProcedureSection = ({ customProcedures = [], ...props }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {
                customProcedures.length > 0 ? customProcedures.map((cp, index) => {
                    return (
                        <span key={cp.ProjectFormCustomProcedureId}> 
                            {index > 0 && <Divider sx={{ backgroundColor: colors.gray[30], borderWidth: '1px' }} />}
                            <CustomProcedure
                                customProcedure={cp}
                                {...props}
                            />
                        </span>)
                }) : <></>
            }
        </Box>
    )
}

export default CustomProcedureSection;