import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export const CLAProcedureContext = createContext();

const useCLAProcedureContext = () => useContext(CLAProcedureContext);

export const useCLAProcedureItem = (id) => {
  const { commentBoxStates, setCommentBoxStates } = useCLAProcedureContext();
  
  const toggleCommentBox = useCallback(() => {
    setCommentBoxStates(current => ({
      ...current,
      [id]: !current[id],
    }))
  }, []);

  useEffect(() => {
    // register the comment box
    setCommentBoxStates(current => ({
      ...current,
      [id]: false,
    }));

    return () => {
      // unregister the comment box
      setCommentBoxStates(current => {
        const newState = { ...current, [id]: undefined };
        delete newState[id];
  
        return newState;
      });
    };
  }, [id]);

  return [
    commentBoxStates[id],
    toggleCommentBox,
  ];
}

export const useCLAProcedureGlobalCommentBox = () => {
  const { commentBoxStates, setCommentBoxStates } = useCLAProcedureContext();
  const isAllClosed = useMemo(() => Object.values(commentBoxStates).every(x => !x), [commentBoxStates]);

  const toggleAllCommentBoxes = useCallback(() => {
    setCommentBoxStates(current => {
      const newStates = { ...current };

      Object.keys(newStates).forEach(id => {
        newStates[id] = isAllClosed;
      });

      return newStates;
    });
  }, [isAllClosed]);

  return [
    isAllClosed,
    toggleAllCommentBoxes,
  ];
}

export const CLAProcedureContextProvider = ({ children }) => {
  const [commentBoxStates, setCommentBoxStates] = useState({});

  const value = useMemo(() => ({
    commentBoxStates,
    setCommentBoxStates,
  }), [commentBoxStates]);

  return (
    <CLAProcedureContext.Provider value={value}>
      {children}
    </CLAProcedureContext.Provider>
  );
}