import React, { memo, useState } from 'react'
import {
  Grid,
  Button,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CLADialogConfirm } from '@ais/components';
import { DeleteIconComponent } from '@ais/assets';
import { v4 as uuidv4 } from 'uuid';

const QuestionGroupActions = ({index, baseFields, disabled, id, onSubmit, fieldArrayMethods: {insert, remove}}) => {
  const [visible, setVisible] = useState(false);
  const handleAdd = () => {
    const newFields = {}
    for(const key in baseFields){
      let defaultAnswer = baseFields[key]
      if(Array.isArray(defaultAnswer) && defaultAnswer.length > 0 && Object.keys(defaultAnswer[0]).includes('id')){
        defaultAnswer = defaultAnswer.map((obj) => {
          return {
            rowId: uuidv4(),
            rowLabel: obj.rowLabel,
            ...obj.values
          }
        })
      }
      newFields[uuidv4()] = defaultAnswer 
    }
    insert(index + 1, newFields)
    onSubmit(`${id}.${index}`)
  }
  const handleDelete = () => {
    remove(index)
    onSubmit(`${id}.${index}`)
    setVisible(false)
  }
  return (
    <Grid container px="40px" py="20px">
      <Grid container item xs={6} alignItems="center">
        <Button
          onClick={handleAdd}
          startIcon={<AddIcon />}
          variant="page-button-add-form"
          sx={{ m: 0, px: 0 }}
        >
          ADD ANOTHER
        </Button>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <IconButton onClick={()=>setVisible(true)} disabled={disabled}>
          <DeleteIconComponent
            style={{ width: '20px', height: '30px', opacity: disabled ? 0.3 : 1 }}
          />
        </IconButton>
      </Grid>
      <CLADialogConfirm
        visible={visible}
        title="Warning"
        cancelText="Cancel"
        confirmText="Delete"
        message="Are you sure you want to delete this?"
        onConfirm={handleDelete}
        onCancel={()=>setVisible(false)}
      />
    </Grid>
  )
}

export default memo(QuestionGroupActions)