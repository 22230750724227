import { Button } from '@mui/material';

import { CLADialog } from '@ais/components';
import { Flex } from '@ais/palette';

import { CLA_INITIAL_SCOPING_CONSIDERATIONS_MODAL } from '@constants/forms/claInitialScopingConsiderations'


const CLAPerformanceStandardsChangeModal = ({
    isPerformanceStandardModalOpen,
    onConfirmPerformanceStandardModal,
    hasLoadingAuditAreaperformanceStandardsData,
    setIsPerformanceStandardModalOpen,
    associatedStandardPerformancData
}) => {

    const {
        associatedRiskFactors,
        associatedRisks,
        associatedDeficiency,
        associatedKeyControls,
    } = associatedStandardPerformancData;

    const { 
        PERFORMANCE_STANDARDS,
        ASSOCIATED_RISK_FACTORS,
        ASSOCIATED_RISK,
        ASSOCIATED_DEFICIENCIES,
        KEY_CONTROLS,
        BUTTON_LABEL,
        WARNING_TITLE
    } = CLA_INITIAL_SCOPING_CONSIDERATIONS_MODAL.EN;

    return (
        <>
            <CLADialog
                visible={!hasLoadingAuditAreaperformanceStandardsData && isPerformanceStandardModalOpen}
                title={WARNING_TITLE}
                closeButtonLabel={BUTTON_LABEL}
                singleButton={true}
                setVisible={setIsPerformanceStandardModalOpen}
                customActionButton={<ConfirmButton label={BUTTON_LABEL} onClick={onConfirmPerformanceStandardModal} />}
                dialogStyle={{
                    '& .MuiDialog-paper': {
                        width: '635px',
                        height: '700px'
                    },
                    '& .MuiDialogContent-root': {
                        overflow: 'hidden',
                        marginBottom: '10px'
                    }
                }}
            >
                <Flex>
                    <WarningText>
                        {PERFORMANCE_STANDARDS.WARNING_TEXT}
                    </WarningText>
                    <Flex style={{  maxHeight: '440px', overflowY: 'overlay', paddingRight: '25px', gap: '20px', paddingBottom: '10px' }}>
                        {
                            associatedRiskFactors.length > 0 && (
                                <Flex>
                                    <ItemHeading>{ASSOCIATED_RISK_FACTORS}</ItemHeading>
                                    {
                                        associatedRiskFactors.map(({RiskFactorName}) => {
                                            return <Item>{RiskFactorName}</Item>
                                        })
                                    }
                                </Flex>
                            )
                        }
                        {
                            associatedRisks.length > 0 && (
                                <Flex>
                                    <ItemHeading size="3">{ASSOCIATED_RISK}</ItemHeading>
                                    {
                                        associatedRisks.map(({RiskName, RiskDescription}) => {
                                            return <Flex direction="row" justify='start'>
                                                <Item style={{ width: '50%'}}>{RiskName}</Item>
                                                <Item>{RiskDescription}</Item>
                                            </Flex>
                                        })
                                    }
                                </Flex>
                            )
                        }
                        {   
                            associatedDeficiency.length > 0 && (
                                <Flex>
                                    <ItemHeading size="3">{ASSOCIATED_DEFICIENCIES}</ItemHeading>
                                    {
                                        associatedDeficiency.map(({DeficiencyName, DeficiencyDescription}) => {
                                            return <Flex direction="row" justify='start'>
                                                <Item style={{ width: '50%'}}>{DeficiencyName}</Item>
                                                <Item>{DeficiencyDescription}</Item>
                                            </Flex>
                                        })
                                    }
                                </Flex>
                            )
                        }
                        {
                            associatedKeyControls.length > 0 && (
                                <Flex>
                                    <ItemHeading size="3">{KEY_CONTROLS}</ItemHeading>
                                    {
                                        associatedKeyControls.map(({ProjectKeyControlName, ProjectKeyControlDescription}) => {
                                            return <Flex direction="row" justify='start'>
                                                <Item style={{ width: '50%'}}>{ProjectKeyControlName}</Item>
                                                <Item>{ProjectKeyControlDescription}</Item>
                                            </Flex>
                                        })
                                    }
                                </Flex>
                            )
                        }
                    </Flex>
                </Flex>
            </CLADialog>
        </>
    )
}



const ConfirmButton = ({onClick, label}) => (
    <Button
        variant="contained"
        disableElevation
        onClick={onClick}>
        {label}
    </Button>
)

const ItemHeading = ({children}) => (
    <span style={{
        color: '#2D3C59',
        fontFamily: 'museo-sans',
        fontWeight: 'bold',
        lineHeight: '18.8px',
        fontSize: '14px',
        paddingBottom: '5px',
        marginBottom: '10px',
        display: 'block',
        width: '100%',
        borderBottom: '1px solid #979797'
    }}>{children}</span>
)

const Item = ({children}) => (
    <span style={{
        color: '#4B4B4B',
        fontFamily: 'museo-sans',
        fontWeight: '600',
        lineHeight: '16.8px',
        fontSize: '14px',
        marginBottom: '5px',
        width: '100%'
    }}>
        {children}
    </span>
)

const WarningText = ({children}) => (
    <div style={{
        fontFamily: 'museo-sans',
        fontWeight: 400,
        fontSize: '12px',
        marginTop: '-15px',
        marginBottom: '20px'
    }}>
        {children}
    </div>
)

export default CLAPerformanceStandardsChangeModal;