import { INTERNAL_CONTROLS } from '@constants/customForm';
import styles from './PurposeObjectiveProcedure.module.css';

const {
  PURPOSE_OBJECTIVES: { PURPOSE, OBJECTIVES, PROCEDURES }
} = INTERNAL_CONTROLS.EN;

const PurposeObjectiveProcedure = () => {
  return (
    <section className={styles.POPSection}>
      <p className={styles.POPDescription}>
        <span className={styles.POPKeyword}>{PURPOSE.KEYWORD}:</span> {PURPOSE.DESCRIPTION}
      </p>
      <p className={styles.POPDescription}>
        <span className={styles.POPKeyword}>{OBJECTIVES.KEYWORD}:</span> {OBJECTIVES.DESCRIPTION}
      </p>
      <p className={styles.POPDescription}>
        <span className={styles.POPKeyword}>{PROCEDURES.KEYWORD}:</span> {PROCEDURES.DESCRIPTION}
      </p>
    </section>
  );
};

export default PurposeObjectiveProcedure;