import React, {useMemo} from 'react';
import {Button} from '@mui/material';

import { FORM_SETTINGS } from '@ais/constants';
import { ReactComponent as RemoveClientAccess } from "@assets/remove_client_access.svg";

export const ShareFormButton = ({
  isInternalControlForm,
  isProjectFinalized,
  isSharedToClient,
  projectForm,
  onShareClientClick,
  onRemoveSharedClientClick
}) => {
  const {SHARE_FORM_BUTTON_TEXT, REMOVE_CLIENT_ACCESS} = FORM_SETTINGS.EN;

  const hasSharedQuestion = useMemo(() => {
    if (!projectForm) return false;
    if (isInternalControlForm) return true;

    const { schema } = projectForm;

    const sharedQuestionFound = schema.some(section => {
      const { fields } = section;
      if (!fields) return false;

      return fields.some(row => row.some(field => field.visibleToClient));
    });

    return sharedQuestionFound;
  }, [projectForm]);

  return (
    <>
      {!isSharedToClient && hasSharedQuestion && (
        <Button variant="contained" sx={{ px: '8px' }} onClick={onShareClientClick} disabled={isProjectFinalized}>
          {SHARE_FORM_BUTTON_TEXT}
        </Button>
      )}
      {isSharedToClient && (
        <Button variant="contained" sx={{ px: '8px' }} startIcon={<RemoveClientAccess />} onClick={onRemoveSharedClientClick}>
          {REMOVE_CLIENT_ACCESS}
        </Button>
      )}
    </>
  )
};
