import React from "react"
import clsx from "clsx"
import styles from "./Heading.module.css"


export const Heading = React.forwardRef((
    {
        as,
        size = "1",
        weight = "normal",
        fontFamily = "museo",
        className,
        children,
        ...other
    },
    ref
) => {

    const componentClassName = clsx(
        styles["heading"],
        size && styles[`heading--size-${size}`],
        weight && styles[`heading--weight-${weight}`],
        fontFamily && styles[`heading--fontFamily-${fontFamily}`],
        className
    )

    const Tag = as || "h1"

    return (
        <Tag className={componentClassName} ref={ref} {...other}>
            {children }
        </Tag>
    )
})

export default Heading
