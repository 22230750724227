import update from 'immutability-helper';

export const initialState = {
    ProjectInternalControlId: null,
    ProjectFormId: null,
    JournalEntryICInfoProcessingAndControlActivity: '',
    JournalEntryICComment: '',
    FinancialStatementICInfoProcessingAndControlActivity1: '',
    FinancialStatementICInfoProcessingAndControlActivity2: '',
    FinancialStatementICInfoProcessingAndControlActivity3: '',
    FinancialStatementICInfoProcessingAndControlActivity4: '',
    FinancialStatementICInfoProcessingAndControlActivity5: '',
    FinancialStatementICInfoProcessingAndControlActivity6: '',
    FinancialStatementICComment: '',
    PeopleWithinEntityComment: '',
    ManagementComment: '',
    ExternalComment: '',
    AccordingToFrameworkComment: '',
    IsAccordingToFramework: false,
    ProjectInternalControlProjectKeyControl: [],
    ProjectScopeAuditArea: [],
    History: [],
};

export const InternalControlsReducer = (state, action) => {
    const { type, payload } = action;
    let newState = { ...state };

    switch (type) {
        case 'CHANGE_WHOLE_OBJECT': {
            return { ...payload };
        }
        case 'CHANGE_FIRST_LEVEL_FIELD': {
            const { key, value } = payload;

            return {
                ...newState,
                [key]: value
            };
        }
        case 'CHANGE_FIRST_LEVEL_FIELD_WITH_OBJECT': {
            return {
                ...newState,
                ...payload
            };
        }
        case 'CHANGE_SCOTABD_LEVEL_FIELD': {
            const { auditAreaIndex, scotabdIndex, key, value } = payload;

            const withUpdatedProjectScopeAuditArea = update(newState, {
                ProjectScopeAuditArea: {
                    [auditAreaIndex]: {
                        ProjectScopeAuditAreaSCOTABDS: {
                            [scotabdIndex]: {
                                $merge: {
                                    [key]: value
                                }
                            }
                        }
                    }
                }
            });

            return { ...newState, ProjectScopeAuditArea: withUpdatedProjectScopeAuditArea.ProjectScopeAuditArea };
        }
        case 'CHANGE_JOURNAL_FINANCIAL_KEY_CONTROL_FIELD': {
            const { keyControlIndex, key, value } = payload;

            const withUpdatedProjectInternalControlKeyControls = update(newState, {
                ProjectInternalControlProjectKeyControl: {
                    [keyControlIndex]: {
                        ProjectKeyControl: {
                            0: {
                                $merge: {
                                    [key]: value
                                }
                            }
                        }
                    }
                }
            });

            return {
                ...newState,
                ProjectInternalControlProjectKeyControl:
                    withUpdatedProjectInternalControlKeyControls.ProjectInternalControlProjectKeyControl
            };
        }
        case 'CHANGE_SCOTABD_KEY_CONTROL_LEVEL_FIELD': {
            const { auditAreaIndex, scotabdIndex, keyControlIndex, key, value } = payload;

            const withUpdatedProjectScopeAuditAreaScotabdKeyControl = update(newState, {
                ProjectScopeAuditArea: {
                    [auditAreaIndex]: {
                        ProjectScopeAuditAreaSCOTABDS: {
                            [scotabdIndex]: {
                                ProjectScopeAuditAreaSCOTABDProjectKeyControl: {
                                    [keyControlIndex]: {
                                        $merge: {
                                            [key]: value
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            });

            return { ...newState, ProjectScopeAuditArea: withUpdatedProjectScopeAuditAreaScotabdKeyControl.ProjectScopeAuditArea };
        }

        case 'CHANGE_SCOTABD_KEY_CONTROL_FIELD': {
            const { auditAreaIndex, scotabdIndex, keyControlIndex, key, value } = payload;

            const withUpdatedProjectScopeAuditAreaScotabdKeyControl = update(newState, {
                ProjectScopeAuditArea: {
                    [auditAreaIndex]: {
                        ProjectScopeAuditAreaSCOTABDS: {
                            [scotabdIndex]: {
                                ProjectScopeAuditAreaSCOTABDProjectKeyControl: {
                                    [keyControlIndex]: {
                                        ProjectKeyControl: {
                                            0: {
                                                $merge: {
                                                    [key]: value
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            });

            return { ...newState, ProjectScopeAuditArea: withUpdatedProjectScopeAuditAreaScotabdKeyControl.ProjectScopeAuditArea };
        }

        case 'SET_SCOTABD_KEY_CONTROL_FIELD': {
            const { auditAreaIndex, scotabdIndex, value } = payload;

            const withUpdatedProjectScopeAuditAreaScotabdKeyControl = update(newState, {
                ProjectScopeAuditArea: {
                    [auditAreaIndex]: {
                        ProjectScopeAuditAreaSCOTABDS: {
                            [scotabdIndex]: {
                                ProjectScopeAuditAreaSCOTABDProjectKeyControl: {
                                    $set: value
                                }
                            }
                        }
                    }
                }
            });

            return { ...newState, ProjectScopeAuditArea: withUpdatedProjectScopeAuditAreaScotabdKeyControl.ProjectScopeAuditArea };
        }

        case 'PUSH_SCOTABD_KEY_CONTROL_FIELD': {
            const { auditAreaIndex, scotabdIndex, value } = payload;

            const withUpdatedProjectScopeAuditAreaScotabdKeyControl = update(newState, {
                ProjectScopeAuditArea: {
                    [auditAreaIndex]: {
                        ProjectScopeAuditAreaSCOTABDS: {
                            [scotabdIndex]: {
                                ProjectScopeAuditAreaSCOTABDProjectKeyControl: {
                                    $push: [value]
                                }
                            }
                        }
                    }
                }
            });

            return { ...newState, ProjectScopeAuditArea: withUpdatedProjectScopeAuditAreaScotabdKeyControl.ProjectScopeAuditArea };
        }

        case 'PUSH_PROJECT_INTERNAL_CONTROL_PROJECT_KEY_CONTROL': {
            const { value } = payload;
            
            const internalControlData = update(newState, {
                ProjectInternalControlProjectKeyControl: {
                    $push: [value]
                }
            });

            return { ...newState, ProjectInternalControlProjectKeyControl: internalControlData.ProjectInternalControlProjectKeyControl };
        }

        case 'SET_PROJECT_INTERNAL_CONTROL_PROJECT_KEY_CONTROL': {
            const { value } = payload;

            const ProjectInternalData = update(newState, {
                ProjectInternalControlProjectKeyControl: {
                    $set: value
                }
            });

            return { ...newState, ProjectInternalControlProjectKeyControl: ProjectInternalData.ProjectInternalControlProjectKeyControl };
        }

        case 'DELETE_PROJECT_INTERNAL_CONTROL_PROJECT_KEY_CONTROL': {
            const { index } = payload;

            const ProjectInternalControlData = update(newState, {
                ProjectInternalControlProjectKeyControl: {
                    $splice: [[ index, 1 ]]
                }
            });

            return { ...newState, ProjectInternalControlProjectKeyControl: ProjectInternalControlData.ProjectInternalControlProjectKeyControl };
        }

        case 'DELETE_SCOTABD_PROJECT_KEY_CONTROL': {
            const { auditAreaIndex, scotabdIndex, projectScopeAuditAreaSCOTABDProjectKeyControlIndex } = payload;
            
            const withUpdatedProjectScopeAuditAreaScotabdKeyControl = update(newState, {
                ProjectScopeAuditArea: {
                    [auditAreaIndex]: {
                        ProjectScopeAuditAreaSCOTABDS: {
                            [scotabdIndex]: {
                                ProjectScopeAuditAreaSCOTABDProjectKeyControl: {
                                    $splice: [[ projectScopeAuditAreaSCOTABDProjectKeyControlIndex, 1 ]]
                                }
                            }
                        }
                    }
                }
            });

            return { ...newState, ProjectScopeAuditArea: withUpdatedProjectScopeAuditAreaScotabdKeyControl.ProjectScopeAuditArea };
        }

        default: {
            return state;
        }
    }
};