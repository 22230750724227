
import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const CLABackDrop = (props) => {
    const { isOpen } = props;
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    return (
        <>
            <Backdrop
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default CLABackDrop