import { useState, useEffect } from 'react'
import { formatMoneyDecimalAndComma, formatDecimalToPercent } from "@utilities/numberHelper";
import { CLANestedDataGrid } from '@ais/components';
import styles from './styles'
import classes from './CPPPAccountLevel.module.css'
import CLATruncatedTextWithTooltip from "@components/CLATruncatedTextWithTooltip/CLATruncatedTextWithTooltip";
import CPPPTransactionLevel from "../CPPPTransactionLevel/CPPPTransactionLevel";

const CPPPAccountLevel = ({ rowData }) => {
  const [rows, setRows] = useState([])

  const updateRowDataFormat = (data) => {
    return data.map((value, index) => ({
      ...value,
      id: index,
      AccountDescription: value.AccountName ? value.AccountName : "",
      TrialBalanceName: value.CorrelationName ? value.CorrelationName : "",
      PriorPeriodBalance: value.PriorPeriodBalance ? formatMoneyDecimalAndComma(value.PriorPeriodBalance) : "",
      CurrentPeriodBalance: value.CurrentPeriodBalance ? formatMoneyDecimalAndComma(value.CurrentPeriodBalance) : "",
      DifferenceAmount: value.DifferenceAmount ? formatMoneyDecimalAndComma(value.DifferenceAmount) : "",
      DifferencePercent: value.DifferencePercent ? `${value.DifferencePercent}%` : "",
      ExpandedPanel: <CPPPTransactionLevel
        correlationDetailId={value.CorrelationDetailId}
        accountNumber={value.AccountNumber}
      />
    }));
  };

  const columns = [
    {
      field: 'AccountNumber',
      flex: 1,
      maxWidth: 200,
      minWidth: 200,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: _ => (
        <>
          <div className={classes['custom-header']}>
            <div>
              <strong>Account</strong>
            </div>
            <strong>Number</strong>
          </div>
        </>
      ),
      disableColumnMenu: true,
    },
    {
      field: 'TrialBalanceName',
      headerName: 'Trial Balance',
      flex: 1,
      maxWidth: 245,
      minWidth: 245,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: _ => (
        <>
          <div className={classes['custom-header']}>
            <div>
              <strong>Trial</strong>
            </div>
            <strong>Balance</strong>
          </div>
        </>
      ),
      renderCell: params => (
        (params?.row?.TrialBalanceName.length <= 40)
          ? params?.row?.TrialBalanceName
          : <CLATruncatedTextWithTooltip
            text={params?.row?.TrialBalanceName}
            length={40}
          />
      )
    },
    {
      field: 'AccountDescription',
      flex: 1,
      maxWidth: 230,
      minWidth: 230,
      disableColumnMenu: true,
      hideSortIcons: true,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: _ => (
        <>
          <div className={classes['custom-header']}>
            <div>
              <strong>Account</strong>
            </div>
            <strong>Description</strong>
          </div>
        </>
      ),
      renderCell: params => (
        (params?.row?.AccountDescription.length <= 70)
          ? params?.row?.AccountDescription
          : <CLATruncatedTextWithTooltip
            text={`${params?.row?.AccountDescription}`}
            length={70}
          />
      )
    },
    {
      field: 'CurrentPeriodBalance',
      flex: 1,
      maxWidth: 220,
      minWidth: 220,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: _ => (
        <>
          <div className={classes['custom-header']}>
            <div>
              <strong>Current Period</strong>
            </div>
            <strong>Unadjusted</strong>
          </div>
        </>
      ),
      renderCell: params => (params?.row?.CurrentPeriodBalance ? `${params?.row?.CurrentPeriodBalance?.toLocaleString('en-US', { maximumFractionDigits: 2 })}` : "")
    },
    {
      field: 'PriorPeriodBalance',
      flex: 1,
      maxWidth: 220,
      minWidth: 220,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: _ => (
        <>
          <div className={classes['custom-header']}>
            <div>
              <strong>Prior Period</strong>
            </div>
            <strong>Balance</strong>
          </div>
        </>
      ),
      renderCell: params => (params?.row?.PriorPeriodBalance ? `${params?.row?.PriorPeriodBalance?.toLocaleString('en-US', { maximumFractionDigits: 2 })}` : "")
    },
    {
      field: 'DifferenceAmount',
      flex: 1,
      editable: false,
      maxWidth: 195,
      minWidth: 195,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: _ => (
        <>
          <div className={classes['custom-header']}>
            <div>
              <strong>Difference</strong>
            </div>
            <strong>($)</strong>
          </div>
        </>
      ),
      renderCell: params => (params?.row?.DifferenceAmount ? params?.row?.DifferenceAmount?.toLocaleString('en-US', { maximumFractionDigits: 2 }) : "")
    },
    {
      field: 'DifferencePercent',
      flex: 1,
      maxWidth: 150,
      minWidth: 150,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: _ => (
        <>
          <div className={classes['custom-header']}>
            <div>
              <strong>Difference</strong>
            </div>
            <strong>(%)</strong>
          </div>
        </>
      ),
      renderCell: params => (params?.row?.DifferencePercent ? formatDecimalToPercent(params?.row?.DifferencePercent) : "")
    }
  ];

  useEffect(() => {
    return () => {
      setRows([])
    }
  }, []);

  useEffect(() => {
    if (rowData.length) {
      setRows(updateRowDataFormat(rowData));
    }
  }, [rowData]);

  return (
    <>
      <div style={{ padding: "10px 0px 0px 3.5em" }}>
        <CLANestedDataGrid
          rows={rows}
          columns={columns}
          rowHeight={70}
          hideFooterRowCount={true}
          showFooterContent={false}
          hideFooterPagination={true}
          headerHeight={70}
          hideFooter={true}
          style={styles}
        />
      </div>
    </>
  )
}

export default CPPPAccountLevel
