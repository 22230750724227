import {
    createContext,
    useContext
} from "react"
import { useParams } from "react-router-dom"
import { useGETProjectClientHeaderInformationByProjectId } from "@services/project"

const ProjectContext = createContext({});

export const ProjectContextProvider = ({ children }) => {

    const { projectId } = useParams()
    const { data: projectData, isLoading} = useGETProjectClientHeaderInformationByProjectId(projectId)

    let projectState = projectData?.data?.projectState;
    const project = { ...projectData?.data, projectState}

    return (
        <ProjectContext.Provider value={{ project, isLoading}}>
            { children }
        </ProjectContext.Provider>
    )
}

export const useProjectContext = () => {
    const ctx = useContext(ProjectContext)
    return ctx
}

export default useProjectContext
