import { createContext, useState } from 'react';
import sessionWrapper from '@utilities/sessionWrapper.js';

export const ProjectManagementContext = createContext({});

export const ProjectManagementProvider = ({ children }) => {

  const [isListView, setIsListView] = useState(sessionWrapper.isListView('projectManagementIsListView'));
  const [isFilterWithDefaulValue, setIsFilterWithDefaultValue] = useState(true);
  const [filterModel, setFilterModel] = useState({ field: 'UnitTitles', value: '' });
  const [filterUnitsOption, setFilterUnitsOption] = useState(null);
  const [filterOptionId, setFilterOptionId] = useState(null);

  const handleFilterOptionChanged = (id) => {
    if (id === "0") {
      setFilterOptionId(null);
      setFilterModel({ field: 'UnitTitles', value: '' });
    } else {
      setFilterOptionId(id);
      const selectedFilter = filterUnitsOption.find(x => x.id == id);
      setFilterModel({ field: 'UnitTitles', value: selectedFilter.name });
    }
    setIsFilterWithDefaultValue(false);
  };

  return (
    <ProjectManagementContext.Provider
      value={{
        actions: {
          setFilterModel,
          setFilterUnitsOption,
          setFilterOptionId,
          setIsListView,
          handleFilterOptionChanged
        },
        state: {
          filterUnitsOption,
          filterModel,
          isFilterWithDefaulValue,
          filterOptionId,
          isListView
        }
      }}
    >
      {children}
    </ProjectManagementContext.Provider>
  )
};