import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import update from 'immutability-helper';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

import { useFinalizedProject } from '@hooks/useProject';
import {
  CLANestedDataGrid,
  CLATooltip,
  auditAreaTableStyles,
  getRowClassName,
} from '@ais/components';
import FinanceStatementProcess from './UOAuditAreaCollapsibles/FinanceStatementProcess';
import JournalEntries from './UOAuditAreaCollapsibles/JournalEntries';
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';
import {
  useUpdateFirstLevelInternalControlData,
  useUpdateIsServiceOrganization,
  useUpdateProjectScopeAuditAreaSCOTABDFields
} from '@services/customForm/internalControls';
import UOEScotabdsTable from './UOEScotabdsTable';
import { ConcurrentCheckbox } from '../../ConcurrentCheckbox'
import './styles.css';
import CustomToast from '@components/CustomToast/CustomToast';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext';  

const UOEConstants = UNDERSTANDING_OF_ENTITY;
const UOEAuditAreasConstants = UOEConstants.AUDIT_AREAS;
const UOEAuditAreasColsConstants = UOEAuditAreasConstants.COLUMNS;

const UOEAuditAreaTable = ({
  signOffList=[]
}) => {

  const { formattedInternalControls, changeInternalControlsObj } = useInternalControlsContext();

  const { projectFormId, projectId } = useParams();
  const [expandedRows, setExpandedRows] = useState([]);
  const [UOELoading, setUOELoading] = useState(false)
  const [UOESucess, setUOESuccess] = useState(false)
  const [UOEError, setUOEError] = useState(false)
  const isProjectFinalized = useFinalizedProject(projectId);

  const handleAdditionalCollapseTrigger = (newIds) => {
    setExpandedRows(newIds);
  };


  const { 
    mutate: updateFirstLevelInternalControlData,
    isError: isErrorUpdateFirstLevelICData,
    isLoading: isLoadingUpdateFirstLevelICData,
    isSuccess: isSuccessUpdateFirstLevelICData
  } = useUpdateFirstLevelInternalControlData();

  const { mutate: updateIsServiceOrganization } = useUpdateIsServiceOrganization();

  const { mutate: updateProjectScopeAuditAreaSCOTABDFields } = useUpdateProjectScopeAuditAreaSCOTABDFields();

  const saveInternalControlData = (newInternalControlData) => {
    const payload = {
      ProjectFormId: formattedInternalControls.ProjectFormId,
      ProjectInternalControlId: formattedInternalControls.ProjectInternalControlId,
      ...newInternalControlData
    }
    updateFirstLevelInternalControlData({ projectFormId, payload, projectId });
  };

  const handleCheckboxChange = (event, projectScopeAuditAreaId) => {
    setUOELoading(true)
    const foundIndex = formattedInternalControls?.ProjectScopeAuditArea.findIndex(
      (item) => item.ProjectScopeAuditAreaId === projectScopeAuditAreaId
    );

    // Check for nullish values (null or undefined) first
    if (foundIndex == null || foundIndex === -1) {
      return;
    }

    const newInternalControlsData = update(formattedInternalControls, {
      ProjectScopeAuditArea: {
        [foundIndex]: {
          $merge: {
            IsServiceOrganization: event.target.checked
          }
        }
      }
    });

    updateIsServiceOrganization(
      { 
        IsServiceOrganization: event.target.checked ? 1 : 0, 
        ProjectScopeAuditAreaId: projectScopeAuditAreaId,
        projectId,
        RequestProjectFormId: projectFormId
      },
      {
        onSuccess: () => {
          setUOELoading(false)
          setUOESuccess(true)
          changeInternalControlsObj(newInternalControlsData)
        },
        onError: () => {
          setUOELoading(false)
          setUOEError(true)
        }
      }
    )
  };

  const auditAreasColumns = [
    {
      field: UOEAuditAreasColsConstants.AUDIT_AREAS.FIELD,
      headerName: UOEAuditAreasColsConstants.AUDIT_AREAS.HEADER_NAME,
      editable: false,
      sortable: false,
      filterable: false,
      maxWidth: 450,
      flex: 1,
      renderHeader: (params) => <span style={{ fontWeight: 1000 }}>{params.colDef?.headerName}</span>
    },
    {
      field: UOEAuditAreasColsConstants.IS_SERVICE_ORGANIZATION.FIELD,
      headerName: UOEAuditAreasColsConstants.IS_SERVICE_ORGANIZATION.HEADER_NAME,
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1,
      maxWidth: 350,
      align: 'center',
      renderHeader: (params) => (
        <>
          <span style={{ fontWeight: 600 }}>{params.colDef?.headerName}</span>
          <CLATooltip
            title={UOEAuditAreasColsConstants.IS_SERVICE_ORGANIZATION.TOOLTIP}
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  p: '15px',
                  pb: '25px',
                  fontSize: '12px',
                  lineHeight: 'normal'
                }
              }
            }}
          >
            <HelpOutlinedIcon sx={{ paddingLeft: '4px', width: '23px', color: '#2e334e', fontSize: 16 }} />
          </CLATooltip>
        </>
      ),
      renderCell: (params) => (
        <ConcurrentCheckbox
          value={params.value}
          disabled={isProjectFinalized}
          sx={{
            px: 'auto'
          }}
          onChange={(e) => handleCheckboxChange(e, params.row.ProjectScopeAuditAreaId)}
          customFormObjectId={`audit-area-table-${params.row.ProjectScopeAuditAreaId}-checkbox`}
        />
      )
    },
    {
      field: '',
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      flex: 1
    }
  ];

  const onSaveUpdateProjectScopeAuditAreaSCOTABDFields = (payload, prevInternalControlsData) => {
    setUOELoading(true);
    updateProjectScopeAuditAreaSCOTABDFields(
      { payload, projectFormId, projectId },
      {
        onSuccess: () => {
          setUOELoading(false);
          setUOESuccess(true);
        },
        onError: () => {
          changeInternalControlsObj(prevInternalControlsData);
          setUOEError(false);
          setUOELoading(false);
        }
      }
    );
  };

  const auditAreas = formattedInternalControls?.ProjectScopeAuditArea?.map((auditArea) => auditArea) || [];
  const validFrom = formattedInternalControls.ValidFrom;

  const modifiedAuditAreas = useMemo(() => {
    if (!auditAreas || auditAreas.length === 0) return [];
    const sortedAuditAreas = sortBy(auditAreas, UOEAuditAreasConstants.DISPLAY_ORDER);
    return (
      sortedAuditAreas.map((item, index) => {
        return {        
          ...item,
          id: `AuditArea-${index}`,
          index,
          ExpandedPanel: (
            <UOEScotabdsTable
              auditAreaIndex={index}
              auditAreaId={item.AuditAreaId}
              scotabds={item.ProjectScopeAuditAreaSCOTABDS}
              projectScopeAuditAreaId={item.ProjectScopeAuditAreaId}
              saveProjectScopeAuditAreaFields={onSaveUpdateProjectScopeAuditAreaSCOTABDFields}
              isProjectFinalized={isProjectFinalized}
            />
          )}
      }) || []
    );
  }, [auditAreas, validFrom, signOffList]);

  const ToastStates = {
    success: UOESucess || isSuccessUpdateFirstLevelICData,
    error: UOEError || isErrorUpdateFirstLevelICData,
    loading: UOELoading || isLoadingUpdateFirstLevelICData
  }

  return (
    <>
      <CLANestedDataGrid
        sx={{
          ...auditAreaTableStyles,
          // When there are no auditAreas yet, do not show default NoRowsOverlay
          ...(!!formattedInternalControls &&
            modifiedAuditAreas?.length === 0 && {
            '& .MuiDataGrid-virtualScrollerContent': {
              display: 'none'
            },
            '& .MuiDataGrid-overlay': {
              display: 'none'
            }
          })
        }}
        columns={auditAreasColumns}
        rows={modifiedAuditAreas}
        expandedRow={expandedRows}
        rowHeight={76}
        getRowClassName={(params) =>
          getRowClassName(params, formattedInternalControls?.ProjectScopeAuditArea, expandedRows, 1)
        }
        handleAdditionalCollapseTrigger={handleAdditionalCollapseTrigger}
        hideFooter={true}
        initialRowsPerPage={Number.MAX_VALUE}
      />
      {!!formattedInternalControls && (
        <>
          <JournalEntries
            journalEntryInfoProcessing={formattedInternalControls?.JournalEntryICInfoProcessingAndControlActivity}
            saveInternalControlData={saveInternalControlData}
            isProjectFinalized={isProjectFinalized}
            signOffList={signOffList}
            formattedInternalControls={formattedInternalControls}
          />
          <FinanceStatementProcess
            financeStatementData={{
              FinancialStatementICInfoProcessingAndControlActivity1:
                formattedInternalControls?.FinancialStatementICInfoProcessingAndControlActivity1,
              FinancialStatementICInfoProcessingAndControlActivity2:
                formattedInternalControls?.FinancialStatementICInfoProcessingAndControlActivity2,
              FinancialStatementICInfoProcessingAndControlActivity3:
                formattedInternalControls?.FinancialStatementICInfoProcessingAndControlActivity3,
              FinancialStatementICInfoProcessingAndControlActivity4:
                formattedInternalControls?.FinancialStatementICInfoProcessingAndControlActivity4,
              FinancialStatementICInfoProcessingAndControlActivity5:
                formattedInternalControls?.FinancialStatementICInfoProcessingAndControlActivity5,
              FinancialStatementICInfoProcessingAndControlActivity6:
                formattedInternalControls?.FinancialStatementICInfoProcessingAndControlActivity6
            }}
            saveInternalControlData={saveInternalControlData}
            isProjectFinalized={isProjectFinalized}
            signOffList={signOffList}
          />
        </>
      )}
      <CustomToast 
        success={ToastStates.success} 
        error={ToastStates.error} 
        loading={ToastStates.loading} 
      />
    </>
  );
};

export default UOEAuditAreaTable;