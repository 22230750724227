
export const initialState = {
    isVisible: false,
    projectFormId: 0
};

const CopyAnswerModalReducer = (state, action) => {
    const { type, payload } = action;
    let newState = { ...state };

    switch (type) {
        case "CHANGE_MODAL_STATE":
            return {
                ...newState,
                isVisible: payload.isVisible,
                projectFormId: payload.projectFormId
            }
    }
}

export default CopyAnswerModalReducer;