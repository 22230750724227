import { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm, useWatch } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@ais/palette";
import PropTypes from 'prop-types';

import {
  BasicTextField,
  CheckboxField,
  RadioSelect,
  ToggleableTextarea,
  LinkList,
  ExternalAccess,
} from './dialog-components/settings';
import { DIALOG, TRIGGERS } from '@constants/forms';
import classes from './DropDownDialog.module.css';
import {
  createField,
  dropNewItem,
  widthOptions,
  convertStringToArray,
  hasDuplicateItems,
  updateFormItem,
  convertArrayToStringOptions,
  filterEmptyCriterias,
  isTriggeredFromQuestionGroup,
  getShareableComponentDefaultValue
} from '@components/Forms/helpers';
import { SchemaContext } from '@ais/providers';
import { BaseDialog } from '.';
import { RulesTab } from './dialog-components/rules';
import questionsService from '@services/forms/questions.js';
import DropdownDefaultAnswersTab from './dialog-components/settings/DropdownDefaultAnswersTab';

export const DropDownDialog = ({ visible, setVisible, additionalData, trigger }) => {
  const { LABEL, PLACEHOLDER, ERROR } = DIALOG.SETTINGS;
  const { EDITOR_DISPLAY } = DIALOG.LINKLISTS.LABEL;
  const { schema, dispatchSchema } = useContext(SchemaContext);

  const validateQuestionID = (data) => {
    const pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return data.match(pattern);
  };

  const questionIdValidation = async (value, formMethods) => {
    if (!validateQuestionID(value)) {
      return false
    }

    async function fetchQuestionByQuestionId(targetQuestionID) {
      try {
        const { data } = await questionsService.getQuestionById(
          targetQuestionID,
          schema.formSettings.methodologyVersionId
        );

        // Align data
        formMethods.setValue('linkList.linkedQuestion', data);
        formMethods.setValue('label', data.label);
        formMethods.setValue('options', convertArrayToStringOptions(data.options ?? []));
        formMethods.setValue('type', data.type);
        return true;
      } catch (error) {
        formMethods.setError('linkList.searchByQuestionID', { types: { message: '' } });
        return false;
      }
    }

    const fetchSuccess = await fetchQuestionByQuestionId(value);
    if (!fetchSuccess) {
      return false
    }
    return true;
  }

  const initialValues = {
    label: additionalData?.label ?? '',
    required: additionalData?.required ?? true,
    columns: additionalData?.columns ?? 1,
    options: additionalData?.options ? convertArrayToStringOptions(additionalData.options) : '',
    allowOtherOption: additionalData?.allowOtherOption ?? false,
    allowMultiSelect: additionalData?.allowMultiSelect ?? false,
    tooltip: additionalData?.tooltip ?? '',
    width: additionalData?.width ?? 100,
    rules:
      typeof additionalData?.rules === 'object'
        ? additionalData.rules
        : {
          behavior: 'show',
          match: 'any',
          criterias: [],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true,
        },
    defaultValue: additionalData?.defaultValue ?? '',
    defaultValues: additionalData?.defaultValues ?? [],
    linkList: typeof additionalData?.linkList === 'object' ? additionalData.linkList : {},
    visibleToClient: getShareableComponentDefaultValue(additionalData, "visibleToClient", trigger),
    editableByClient: getShareableComponentDefaultValue(additionalData, "editableByClient", trigger),
  };

  const formMethods = useForm({
    values: initialValues,
    resolver: yupResolver(
      Yup.object().shape({
        linkList: Yup.object().shape({
          searchByQuestionID: Yup.string().ensure().trim()
            .when('linkToInternalList', {
              is: (value) => !!value,
              then: (schema) => schema.required('')
                .test({
                  name: 'searchByQuestionId_validation',
                  message: 'Question ID is invalid',
                  test: async (value) => await questionIdValidation(value, formMethods),
                })
            }),
          questionId: Yup.string().ensure().trim()
            .when(['linkToInternalList', 'formCategoryId', 'formId', 'sectionId'], {
              is: (linkToInternalList, formCategoryId, formId, sectionId) => {
                if (!linkToInternalList && !!formCategoryId && !!formId)
                  return !!sectionId
              },
              then: (schema) => schema.required('')
                .test({
                  name: 'questionId_validation',
                  message: 'Question ID is invalid',
                  test: async (value) => await questionIdValidation(value, formMethods),
                })
            })
        }),
        label: Yup.string().trim()
          .when(['linkList.linkToInternalList', 'linkList.questionId'], {
            is: (linkToInternalList, questionId) => questionId ? !questionId : !linkToInternalList,
            then: (schema) => schema.required(''),
            otherwise: (schema) => {
              formMethods.clearErrors('label');
              return schema.notRequired()
            }
          }),
        options: Yup.string().ensure().trim()
          .when(['linkList.linkToInternalList', 'linkList.questionId'], {
            is: (linkToInternalList, questionId) => questionId ? !questionId : !linkToInternalList,
            then: (schema) => schema.required('')
              .test({
                name: 'options_duplicate_validation',
                message: ERROR.OPTION_HAS_DUPLICATE,
                test: (value) => {
                  const optionList = convertStringToArray(value);
                  return !hasDuplicateItems(optionList);
                },
              }),
            otherwise: (schema) => {
              formMethods.clearErrors('options');
              return schema.notRequired()
            }
          })
      })
    )
  });

  const watchLinkToInternalList = useWatch({ control: formMethods.control, name: 'linkList.linkToInternalList' }) ?? false;
  const watchQuestionId = useWatch({ control: formMethods.control, name: 'linkList.questionId' }) ?? '';

  const handleAllowMultiSelectChange = () => {
    if (!formMethods.control) return;
    formMethods.setValue('defaultValue', '');
    formMethods.setValue('defaultValues', []);
  };

  const handleSubmit = (values) => {
    const filteredRules = filterEmptyCriterias(values.rules);

    if (![TRIGGERS.EDIT_BTN, TRIGGERS.QUESTION_GROUP_EDIT].some((t) => t === trigger)) {
      const newField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.refField.index,
        ...values,
        options: convertStringToArray(values.options),
        width: +values.width,
        rules: filteredRules,
        linkList: values.linkList,
      });
      dropNewItem(additionalData, newField, trigger, schema, dispatchSchema);
    } else {
      const updateField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.index,
        ...values,
        options: convertStringToArray(values.options),
        width: +values.width,
        rules: filteredRules,
        linkList: values.linkList,
      });
      updateFormItem(additionalData, updateField, schema, dispatchSchema);
    }
    setVisible(false);
  }

  useEffect(() => {
    formMethods.reset(initialValues);
    formMethods.trigger(); // initial validation
  }, [visible]);

  const tabs = [
    {
      label: LABEL.SETTINGS,
      render: () => {
        if (watchLinkToInternalList || watchQuestionId.length > 0) {
          return (
            <div className={classes.container}>
              <div className={classes['container-instructions']}>{EDITOR_DISPLAY}</div>
            </div>
          );
        } else {
          return (
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <BasicTextField
                  name="label"
                  label={LABEL.QUESTION_LABEL}
                  placeholder={PLACEHOLDER.QUESTION}
                  maxLength={256}
                  showRequired
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleableTextarea
                  name="options"
                  maxRows={10}
                  minRows={10}
                  label=""
                  showFormatText={false}
                  placeholder={PLACEHOLDER.OPTION_LIST}
                />
              </Grid>
              <Grid item container xs={12}>
                <Grid item container xs={9}>
                  <Grid item xs={4}>
                    <CheckboxField
                      name="allowOtherOption"
                      label={LABEL.ALLOW_OTHER_OPTION}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <CheckboxField
                      name="allowMultiSelect"
                      label={LABEL.ADD_MULTISELECT}
                      onChange={handleAllowMultiSelectChange}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={3} justifyContent="flex-end">
                  <Grid item xs="auto">
                    <ToggleableTextarea
                      name="tooltip"
                      maxLength={4096}
                      maxRows={2}
                      minRows={2}
                      label={LABEL.ADD_TOOLTIP}
                      placeholder={PLACEHOLDER.TEXT}
                      toggleable
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                <Grid item xs={6}>
                  {!isTriggeredFromQuestionGroup(trigger) && <ExternalAccess
                    allowExternalAccessName="visibleToClient"
                    makeAnswerableName="editableByClient"
                  />}
                </Grid>
                <Grid item>
                  <RadioSelect
                    name="width"
                    label={LABEL.DISPLAY_WIDTH}
                    options={widthOptions}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        }
      },
    },
    {
      label: LABEL.DEFAULT_ANSWERS,
      render: () => <DropdownDefaultAnswersTab />,
    },
    {
      label: LABEL.RULES,
      render: () => <RulesTab additionalData={additionalData} trigger={trigger} />,
    },
    {
      label: 'Link List',
      render: () => (
        <LinkList
          name="label"
          placeholder={PLACEHOLDER.QUESTION_LINK}
          maxLength={256}
          additionalData={additionalData}
        />
      ),
    },
  ];

  return (
    <Form form={formMethods}>
      <BaseDialog
        id={isTriggeredFromQuestionGroup(trigger) ? '' : additionalData?.id}
        idPrefix={isTriggeredFromQuestionGroup(trigger) ? '' : LABEL.QUESTION_ID}
        title={LABEL.DROPDOWNS}
        visible={visible}
        setVisible={setVisible}
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        tabs={
          isTriggeredFromQuestionGroup(trigger)
            ? tabs.filter((tab) => tab.label !== LABEL.RULES)
            : tabs
        }
      />
    </Form>
  );
};

DropDownDialog.propTypes = {
  visible: PropTypes.bool,
  additionalData: PropTypes.object,
  trigger: PropTypes.string,
  setVisible: PropTypes.func,
};

DropDownDialog.defaultProps = {
  value: false,
  additionalData: null,
  trigger: '',
};
