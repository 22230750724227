import formApi from '@utilities/claApiForm';

// Set is used on the front end in the Form Instance when selecting a Performance Standard from a single select drop down
// This feature, since only one Performance Standard can be selected, cleans non-selected entries. Technically should always be a max of 1 only.
const setProjectScopePerformanceStandard = async (data) => {
    const { ProjectScopeId } = data;
    try {
        const currentStandards = await formApi.get(`/projectscopesperformancestandard/${ProjectScopeId}`);
        currentStandards?.data?.forEach(async (item) => {
            await formApi.delete(`/projectscopesperformancestandard/${item.ProjectScopePerformanceStandardId}`);
        });
        const response = await formApi.post(`/projectscopesperformancestandard/`, data);
        return { status: 201, response };
    } catch (error) {
        return { status: 400, message: error };
    }
};

const getProjectScopePerformanceStandard = async (projectScopeId, projectId) => {
    try {
        const response = await formApi.get(`/projectscopesperformancestandard/${projectScopeId}/${projectId}`);
        return { status: 201, response };
    } catch (error) {
        return { status: 400, message: error };
    }
};

const updateProjectscopesperformancestandard = async (params, projectId) => {
    try {
        const response = await formApi.put(`/projectscopesperformancestandard/${projectId}`, params);
        return { status: 201, response };
    }
    catch (error) {
        return { status: 400, message: error };
    }
}

const projectScopePerformanceStandards = {
    setProjectScopePerformanceStandard,
    getProjectScopePerformanceStandard,
    updateProjectscopesperformancestandard
}

export default projectScopePerformanceStandards;