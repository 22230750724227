import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// hooks
import {FUNCTIONAL_RIGHTS_GROUP} from '@ais/constants';
import useUserProviderState from "@contexts/UserContext";
import { ROUTE_PATH } from '@constants/index';

const RequireFunctionalGroup = ({ children, requiredGroups, route }) => {
    const { isUserGroupFetch, userGroups } = useUserProviderState();
    const [hasAccess, setHasAccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (isUserGroupFetch && userGroups) {
            let access = false;
            if (requiredGroups) {
                for (const requiredGroup of requiredGroups) {
                    const userGroup = userGroups?.find(group => group.FunctionalRightsGroupId === FUNCTIONAL_RIGHTS_GROUP[requiredGroup]);
                    if (userGroup) {
                        access = true;
                        setHasAccess(true);
                        break;
                    }
                }

                if (route && !access) {
                    navigate(`/${ROUTE_PATH.noaccess}`);
                }
                
            } else {
                setHasAccess(true);
            }
        }
    }, [isUserGroupFetch, userGroups, navigate]);

    return (!hasAccess) ? <></> : <>{children}</>;
}

export default RequireFunctionalGroup