export const UNDERSTANDING_OF_ENTITY = {
  AUDIT_AREAS: {
    DISPLAY_ORDER: 'DisplayOrder',
    COLUMNS: {
      AUDIT_AREAS: {
        FIELD: 'AuditAreaName',
        HEADER_NAME: 'Audit Areas'
      },
      IS_SERVICE_ORGANIZATION: {
        FIELD: 'IsServiceOrganization',
        HEADER_NAME: 'Process involves a service organization',
        TOOLTIP: 'If the service org box is checked, complete an Understanding of Service Organization form.'
      }
    },
    FINANCIAL_STEMENT_PROCESS: {
      LABELS: {
        FinancialStatementICInfoProcessingAndControlActivity1: '1. Describe the budgeting/forecasting process',
        FinancialStatementICInfoProcessingAndControlActivity2: '2. Describe the reconciliation of general/subsidiary ledgers process',
        FinancialStatementICInfoProcessingAndControlActivity3: '3. Describe the reconciliation of significant accounts process',
        FinancialStatementICInfoProcessingAndControlActivity4: '4. Describe the financial statement reports process',
        FinancialStatementICInfoProcessingAndControlActivity5: '5. Describe the consolidations and adjustments in financial statement preparation process',
        FinancialStatementICInfoProcessingAndControlActivity6: '6. Describe the opening/closing periods process'
      },
      COMMENT_KEY: 'financial-statement-process',
      COMMENT_FIELD_KEY: 'financial-statement-comment',
      COMMENT_FIELD: 'FinancialStatementICComment'
    }
  },
  SCOTABDS: {
    KEY_CONTROLS: "Key Controls",
    COMMENTS_FIELD: 'ICComment',
    KEY_CONTROLS_FIELD: 'RiskAssessmentRiskOfMaterialMisstatementId',
    DISPLAY_ORDER: 'DisplayOrder',
    RISK_OF_MATERIAL: {
      HIGH: 1,
      SIGNIFICANT: 4
    },
    INHERENT_RISK: {
      SIGNIFICANT: 1,
      HIGH: 2,
      MODERATE: 3,
      LOW: 4
    },
    CONTROL_RISK: {
      HIGH: 1,
      MODERATE: 2,
      LOW: 3
    }
  },
  INFORMATION_PROCESSING: {
    LABEL: 'Information Processing and Control Activities',
    PLACEHOLDER: 'Enter information processing and control activities'
  },
  JOURNAL_ENTRIES: {
    ACCORDION_ID: 'UOEJournalEntries',
    COMMENT_KEY: 'journal-entries-comment',
    FIELD: 'JournalEntryICInfoProcessingAndControlActivity',
    COMMENT_FIELD: 'JournalEntryICComment'
  },
  COMMENTS: {
    LABEL: 'Comment',
    PLACEHOLDER: 'Enter comment here',
    NON_MULTILINE_LABEL_FONT_SIZE: '11px',
    NON_MULTILINE_LABEL_MARGIN_LEFT: '0',
  },
  KEY_CONTROL_DRAWER: {
    TITLE: 'Key Controls',
    BODY_TEXT: 'Examples of controls include authorizations and approvals, reconciliations, verifications (such as edit and validation checks or automated calculations), segregation of duties, and physical or logical controls, including those addressing safeguarding of assets.',
    DIALOG_TITLE: 'Warning',
    DIALOG_BODY: 'The following risks of material misstatement have not been associated with any identified key controls:',
    DIALOG_FOOTER_TEXT: 'Please identify key controls for all risks of material misstatement prior to completing this form',
    DELETE_BODY: 'Are you sure you want to delete this key control?',
    BUTTONS: {
      SAVE: 'Save',
      CANCEL: 'Cancel',
      ADD_KEY_CONTROL: 'ADD ANOTHER KEY CONTROL',
    },
    LABELS: {
      KEY_CONTROL_NAME: 'Key Control Name',
      KEY_CONTROL_DESCRIPTION: 'Key Control Description',
      PROJECT_RISKS: 'Key control addresses the following risks of material misstatement',
      IS_IT_APPLICATION: 'IT application associated with the control',
    },
    PLACEHOLDERS: {
      KEY_CONTROL_NAME: 'Enter key control name',
      KEY_CONTROL_DESCRIPTION: 'Enter key control description',
      PROJECT_RISKS: 'Select',
      IT_APPLICATION_COMMENT: 'Identify application here'
    },
    OTHER_RELEVANT_ASSERTIONS: {
      LABEL: "Other Relevant Assertions",
      OPTION_VALUE: 'other-relevant-assertions'
    }
  },
  KEYS: {
    ICCOMMENT: 'ICComment',
    ICINFOCOMMENT: 'ICInfoProcessingAndControlActivity'
  }
};