// constants
import * as Constants from "@constants/index";

export const initialState = {
    projectFormAdded: [],
    projectUnitTitle: [],
    newCheckedUnits: [],
    newFormName: '',
    newProjectFormName: '',
    isNewlyAdded: false,
    mode: '',
    currentExpandedFormCategoryPanel: '',
    isSaveFromListView: false,
    unitOptionsListView: [],
    unitsSelectedListView: [],
    isProjectFormOnEdit: false,
	hasTriggeredRefetchOfFormsLibrary: true
}

const { ADD_TO_CATEGORY,
    REMOVE_FROM_CATEGORY,
    ADD_UNIT_LIST,
    REMOVE_UNIT_LIST,
    ADD_TO_LIST_VIEW,
    ADD_NEW_FORM_DETAILS,
    SET_UNIT_OPTIONS_LIST_VIEW,
    UPDATE_UNIT_SELECTED_LIST_VIEW,
    RESET_FORM_LIST_VIEW,
    SET_PROJECT_FORM_STATE,
	REFETCH_FORMS_LIBRARY } = Constants.CONTEXT_API_METHOD;

const NewFormCardReducer = (state, action) => {
    const { type, payload } = action;
    let newState = { ...state };

    switch (type) {
        case ADD_NEW_FORM_DETAILS:
            return {
                ...newState,
                newFormName: payload.newFormName,
                newProjectFormName: payload.newProjectFormName
            }
        case ADD_TO_CATEGORY:
            return {
                ...newState,
                projectFormAdded: payload.projectFormAdded,
                newFormName: payload.newFormName,
                isNewlyAdded: payload.isNewlyAdded,
                mode: payload.mode,
                currentExpandedFormCategoryPanel: payload.currentExpandedFormCategoryPanel
            }
        case REMOVE_FROM_CATEGORY:
            return {
                ...newState,
                projectFormAdded: payload.projectFormAdded,
                newFormName: payload.newFormName,
                isNewlyAdded: payload.isNewlyAdded,
                projectUnitTitle: payload.projectUnitTitle,
                mode: payload.mode,
                currentExpandedFormCategoryPanel: payload.currentExpandedFormCategoryPanel
            }
        case ADD_UNIT_LIST:
            return {
                ...newState,
                projectUnitTitle: payload.projectUnitTitle,
            }
        case REMOVE_UNIT_LIST:
            return {
                ...newState,
                projectUnitTitle: payload.projectUnitTitle,
            }
        case ADD_TO_LIST_VIEW:
            return {
                ...newState,
                isSaveFromListView: payload.isSaveFromListView,
            }
        case SET_UNIT_OPTIONS_LIST_VIEW:
            return {
                ...newState,
                unitOptionsListView: payload.unitOptionsListView,
            }
        case UPDATE_UNIT_SELECTED_LIST_VIEW:
            return {
                ...newState,
                unitsSelectedListView: payload.unitsSelectedListView,
            }
        case RESET_FORM_LIST_VIEW:
            return {
                ...newState,
                isSaveFromListView: payload.isSaveFromListView,
                unitOptionsListView: payload.unitOptionsListView,
                unitsSelectedListView: payload.unitsSelectedListView,
                mode: payload.mode,
                projectFormAdded: payload.projectFormAdded,
                projectUnitTitle: payload.projectUnitTitle
            }
        case SET_PROJECT_FORM_STATE:
            return {
                ...newState,
                isProjectFormOnEdit: payload.isProjectFormOnEdit
            }
		case REFETCH_FORMS_LIBRARY:
			return {
				...newState,
				hasTriggeredRefetchOfFormsLibrary: payload.hasTriggeredRefetchOfFormsLibrary,
			}
    }
};

export default NewFormCardReducer; 