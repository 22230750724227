export const ADD_RISK = {
    EN: {
        RISK_IDENTIFICATION: 'Risk Identification',
        COMMON_IDENTIFIED_RISK: 'Common Identified Risk',
        FORM: 'Form',
        SECTION: 'Section',
        RISK_ASSESSMENT: 'Associate with Risk Assessment Summary',
        RISK_NAME: 'Risk Name',
        RISK_NAME_HERE: 'Risk name here..',
        DESCRIBE_RISK: 'Describe the identified risk',
        DESCRIBE_COMMON_IDENTIFIED_RISK: 'Describe the common identified risk',
        RISK_DESCRIPTION: 'Risk description here..',
        RISK_LIKELIHOOD: 'Is the likelihood of the risk occurring more than remote?',
        RISK_MAGNITUDE: 'Is the magnitude of the risk more than material?',
        RISK_RELATION: 'Is the risk related to error or fraud?',
        FRAUD_RISK_TYPE: 'Select the type of fraud risk',
        CONDITIONS_DESCRIPTION: 'Describe the conditions indicating the error occurred',
        RISK_PERVASIVE: 'Is the risk pervasive?',
        ASSERTION_DESCRIPTION: 'Describe what could go wrong at the assertion level',
        AUDIT_AREA: 'Audit Area',
        SELECT: 'Select',
        SCOTABD: 'SCOTABD',
        ASSERTIONS: 'Assertions',
        ADD_AUDIT_AREA: 'ADD ANOTHER AUDIT AREA',
        ACCOUNTING_ESTIMATE: 'Does the risk relate to an accounting estimate?',
        ERROR_OR_FRAUD: ['Error', 'Fraud'],
        YES_OR_NO: ['Yes', 'No'],
        ERROR: 'Error',
        FRAUD: 'Fraud',
        YES: 'Yes',
        NO: 'No',
        CANCEL: 'Cancel',
        SAVE: 'Save',
        WORKPAPER_REFERENCE: 'Workpaper Reference',
        WORKPAPER_REFERENCE_PLACEHOLDER: "Workpaper Reference here",
    },
    API_REQUEST_ACTION_TYPE: {
        ADD: 'Add',
        UPDATE: 'Update'
    }
}