import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';
import { renderCommonDatagridHeader } from '@ais/components';

const UOEConstants = UNDERSTANDING_OF_ENTITY;

export const scotabdsColumns = [
  {
    field: 'ClassOfTransactionName',
    headerName: 'SCOTABDs',
    editable: false,
    sortable: false,
    filterable: false,
    flex: 5,
    renderHeader: (params) => renderCommonDatagridHeader(params)
  },
  {
    field: 'stringAccountBalanceTypeList',
    headerName: 'Accounts',
    editable: false,
    sortable: false,
    filterable: false,
    flex: 5,
    renderHeader: (params) => renderCommonDatagridHeader(params)
  },
  {
    field: UOEConstants.SCOTABDS.COMMENTS_FIELD,
    headerName: 'Comments',
    editable: false,
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    width: 120,
    maxWidth: 120,
    renderHeader: (params) => renderCommonDatagridHeader(params)
  },
  {
    field: UOEConstants.SCOTABDS.KEY_CONTROLS_FIELD,
    headerName: '',
    editable: false,
    sortable: false,
    filterable: false,
    flex: 3,
    align: 'right'
  }
];