import { createContext, useContext, useState, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { usePutClientSituationAnswersByProjectFormId } from '@services/clientSituationsAnswer';

const ProceduresContext = createContext()

export const ProcedureContextProvider = ({ children }) => {
    const { projectFormId } = useParams();
    const [ projectFormProcedureComponentData, setProjectFormProcedureComponentData ] = useState([])
    const [ currentReqBody, setCurrentReqBody ] = useState([])
    const { mutateAsync: updateClientSituationAnswers } = usePutClientSituationAnswersByProjectFormId()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleProcedureBlur = (matchingProcedure, values) => {
        setLoading(true) 
        matchingProcedure.ClientSituationAnswer = `[${values}]`
        const foundProcedureIndex = projectFormProcedureComponentData.findIndex((procedure) => procedure.ProcedureComponentId === matchingProcedure.ProcedureComponentId)
        if(foundProcedureIndex === -1){
            setCurrentReqBody((prev) => [...prev, matchingProcedure])
        }else{
            setCurrentReqBody((prev) => {
                const newState = [...prev];
                newState[foundProcedureIndex] = matchingProcedure;
                return newState
            })
        }
    }

    useEffect( () => {
        const update = async () => {
            try {
                if((projectFormId && currentReqBody.length !== 0) && (currentReqBody !== projectFormProcedureComponentData)){
                    await updateClientSituationAnswers({projectFormId: projectFormId , reqBody: currentReqBody})
                    setSuccess(true)
                    setError(false)
                    setLoading(false)
                }
            } catch (error) {
                setSuccess(false)
                setError(true)
                setLoading(false)
            }
        }

        update();
    }, [currentReqBody])

    const memoized = useMemo(() => ({projectFormProcedureComponentData})
    , [projectFormProcedureComponentData]);

    return (
        <ProceduresContext.Provider value={{...memoized, handleProcedureBlur, setProjectFormProcedureComponentData, setCurrentReqBody, loading, error, success }}>
            { children }
        </ProceduresContext.Provider>
    )
}

export const useProceduresContext = () => {
    const ctx = useContext(ProceduresContext)
    if(!ctx) {
        throw new Error("`useProceduresContext` must be used within ProcedureContextProvider")
    }
    return ctx
}