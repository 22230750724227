import React from "react";
import PropTypes from "prop-types";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

import {CLAPerson} from "@ais/components";

import style from "./EngagementLeader.module.css";

export function EngagementLeader(props) {
    return (
        <div className={style.engagementLeaderContainer}>
            <CLAPerson
                data-test="person-toolkit"
                styles={{
                    height: '100px',
                    width: '100px',
                    fontSize: '30px'
                }}
                account={props.account}
                personCardInteraction='none'
            />

            <div className={style.containerRight}>
                <span data-test="name" className={style.textName}>{props.name}</span>
                <span data-test="title" className={style.textTitle}>{props.title}</span>

                <div className={style.iconContainer}>
                    <ClickableIcon href={`mailto:${props.email}`}>
                        <MailOutlineIcon className={style.iconCSS} />
                    </ClickableIcon>
                    <ClickableIcon href={`sip:${props.email}`}>
                        <ChatBubbleOutlineIcon className={style.iconCSS} />
                    </ClickableIcon>
                    <ClickableIcon href={`tel:${props.phone}`}>
                        <CallOutlinedIcon className={style.iconCSS} />
                    </ClickableIcon>
                </div>
            </div>
        </div>
    );
}

EngagementLeader.propTypes = {
    userId: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    imgUrl: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
};

const ClickableIcon = ({ href, children }) => {
    return (
        <a href={href}>
            {children}
        </a>
    )
}