export const GRAPHS_DRAWER = {
    EN: {
        DRAWER_TITLE: "Available Graphs",
        DRAWER_DESCRIPTION: "Select or deselect Graphs to add or remove from the section",
        TABLE_HEADERS: {
            TYPE: "Type",
            CLASSIFICATION: "Classification",
            CATEGORY: "Category"
        },
        GRAPH_SECTION_TITLE: "Add/Remove Graphs",
        DEFAULT_GRAPHS: {
            INCOME: "D. Income"
        },
        MONTHS: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
        COMMENT: "Comment",
        COMMENT_PLACEHOLDER: "Enter a comment",
        DEFAULT_INCOME: "DEFAULT_INCOME",
        DEFAULT_INCOME_GROUPING_ID: "D. Income"
    }
}