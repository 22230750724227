export const initialState = {
    isOpen: false,
    isInitialize: false,
    isJournalEntry: false,
    isFinancialStatement: false,
    projectFormId: null, 
    projectScopeAuditAreaSCOTABDId: null,
    projectScopeAuditAreaSCOTABDProjectKeyControl: [],
    projectInternalControlProjectKeyControl: [],
    tempProjectKeyControl: null,
    internalControlData: {},
    projectScopeAuditAreaId: null,
    formReset: false,
    newInternalControlData: null,
    isRefreshData: false
};

export const emptyProjectInternalControlProjectKeyControl = {
    ProjectInternalControlProjectKeyControlId: null,
    IsJournalEntry: false,
    IsFinancialStatement: false,
    ProjectKeyControl: []
}

export const emptyInternalControlKeyControl = {
    ProjectKeyControlId: null,
    ProjectKeyControlName: "",
    ProjectKeyControlDescription: "", 
    IsITApplicationAssociated: false,
    IsDesignedEffectively: false,
    IsImplementedEffectively: false,
}

export const emptyProjectScopeAuditAreaSCOTABDProjectKeyControl = {
    ProjectScopeAuditAreaSCOTABDProjectKeyControlId: null,
    ProjectScopeAuditAreaSCOTABDId: 0,
    ICInfoProcessingAndControlActivity: "",
    ICComment: "",
    IsServiceOrganization: false,
    ProjectKeyControl: []
}

export const emptyAuditAreaSCOTABDSKeyControl = {
    ProjectKeyControlId: null,
    ITApplicationAssociatedComment: null,
    IsDesignedEffectively: false,
    IsITApplicationAssociated: false,
    IsImplementedEffectively: false,
    ProjectKeyControlDescription: "",
    ProjectKeyControlName: "",
    ProjectKeyControlProjectRisk: []
}

const KeyControlDrawerReducer = (state, action) => {
    const { type, payload } = action;
    let newState = { ...state };

    switch (type) {
        case "OPEN_DRAWER":
            return {
                ...initialState,
                isOpen: true
            }
        case "CLOSE_DRAWER":
            return {
                ...initialState,
                newInternalControlData: payload.newInternalControlData,
                isRefreshData: payload.isRefreshData
            };
        case "INITIALIZE_INTERNAL_CONTROL": 
            return {
                ...newState,
                isInitialize: true,
                projectFormId: payload.projectFormId, 
                isJournalEntry: payload.isJournalEntry,
                isFinancialStatement: payload.isFinancialStatement,
                internalControlData: payload.internalControlData,
                tempProjectKeyControl: payload.tempProjectKeyControl
            }
        case "INITIALIZE_AUDITAREA_SCOTABDS": 
            return {
                ...newState,
                isInitialize: true,
                projectFormId: payload.projectFormId,
                internalControlData: payload.internalControlData,
                tempProjectKeyControl: payload.tempProjectKeyControl,
                projectScopeAuditAreaId: payload.projectScopeAuditAreaId,
                projectScopeAuditAreaSCOTABDId: payload.projectScopeAuditAreaSCOTABDId,
                projectScopeAuditAreaSCOTABDProjectKeyControl: payload.projectScopeAuditAreaSCOTABDProjectKeyControl
            }
    }
}

export default KeyControlDrawerReducer;