import { useRef, useEffect, useState } from 'react';
import { useWatch, useFormContext } from "react-hook-form"
import { FormControl, Select, MenuItem, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import HelpIcon from '@mui/icons-material/Help';

import { colors } from '@ais/theme';

export const BasicDropdown = (props) => {
  const {
    name,
    showLabel,
    label,
    wrapLabel,
    options,
    tooltip,
    disabled,
    placeholder
  } = props;

  const dropdownRef = useRef(null);

  const [menuItemWidth, setMenuItemWidth] = useState(0);

  const { setValue, control, formState } = useFormContext();
  const { errors } = formState;
  const watchValue = useWatch({ control, name });
  const Placeholder = ({ children }) => {
    return <div style={{ color: '#aaa' }}>{children}</div>;
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#373D3F',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#373D3F',
      maxWidth: 500,
    },
  });

  useEffect(() => {
    setMenuItemWidth(dropdownRef.current?.offsetWidth);
  }, [options]);

  return (
    <FormControl ref={dropdownRef} fullWidth disabled={disabled}>
      {showLabel && (
        <div style={{ display: 'flex' }}>
          <Typography
            variant="label"
            sx={{
              marginBottom: '8px',
              whiteSpace: wrapLabel ? 'normal' : 'nowrap',
              paddingRight: '5px',
            }}
          >
            {label}
          </Typography>
          {tooltip && (
            <CustomWidthTooltip
              placement="top"
              title={<span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>}
            >
              <HelpIcon style={{ fontSize: '18px', color: colors.navy[400] }} />
            </CustomWidthTooltip>
          )}
        </div>
      )}
      <Select
        name={name}
        value={watchValue}
        onChange={(e) => setValue(name, e.target.value)}
        IconComponent={KeyboardArrowDown}
        error={!!errors[name]?.message}
        displayEmpty
        renderValue={
          !!watchValue
            ? undefined
            : () => <Placeholder>{placeholder ?? ''}</Placeholder>
        }
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option?.hasOwnProperty('value') ? option.value : option}
            sx={{
              maxWidth: `${menuItemWidth == 0 ? 500 : menuItemWidth}px`,
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            }}
          >
            {option?.hasOwnProperty('label') ? option.label : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

BasicDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  label: PropTypes.node,
  wrapLabel: PropTypes.bool,
  options: PropTypes.array,
  disabled: PropTypes.bool,
};

BasicDropdown.defaultProps = {
  showLabel: true,
  wrapLabel: true,
  options: [],
  disabled: false,
};
