import React, { createContext, useContext, useCallback, useState } from "react"

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import IconButton from '@mui/material/IconButton';

import styles from './ContextMenu.module.css';

const ContextMenuProContext = createContext()

const useMenuContext = () => {
    const ctx = useContext(ContextMenuProContext)
    if(!ctx) {
        throw new Error("ContextMenuProContext must be used within ContextMenuProProvider")
    }
    return ctx
}

const ContextMenuProvider = (props) => {
    const {
        open,
        onOpenChange,
        popPosition,
        children
    } = props

    const [ anchorEl, setAnchorEl ] = useState(null)

    return (
        <ContextMenuProContext.Provider
            value={{
                open,
                onOpenChange,
                popPosition,
                anchorEl,
                setAnchorEl
            }}
        >
            { children }
        </ContextMenuProContext.Provider>
    )
}

const ContextMenuContent = ({ children, ...other }) => {
    const { open, popPosition, onOpenChange, anchorEl, setAnchorEl } = useMenuContext()

    const onClickedOutside = useCallback(() => {
        setAnchorEl(null)
        onOpenChange(false)
    }, [ setAnchorEl, onOpenChange ])

    return (
        <Popper
            style={{ zIndex: 2 }}
            open={open}
            anchorEl={anchorEl}
            placement={popPosition}
            transition
            {...other}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === "bottom-start" ? "right-top" : "right-bottom"
                    }}
                >
                    <div className={styles["contextmenu__paper"]}>
                        <ClickAwayListener onClickAway={onClickedOutside}>
                            <MenuList>
                                { children }
                            </MenuList>
                        </ClickAwayListener>
                    </div>
                </Grow>
            )}
        </Popper>
    )
}

const ContextMenuTrigger = ({ children, onClick, ...other }) => {

    const { setAnchorEl } = useMenuContext()

    const onTriggerClicked = useCallback((event) => {
        setAnchorEl(event.currentTarget)
        onClick()
    }, [ setAnchorEl, onClick ])

    return (
        <IconButton sx={{ padding: 0 }} onClick={onTriggerClicked} {...other}>
            { children }
        </IconButton>
    )
}

const ContextMenuItem = ({ children, ...props}) => (
    <MenuItem className={styles["contextmenu__item"]} {...props}>
        { children }
    </MenuItem>
)

export const ContextMenu = ({ children, ...props }) => {
    return (
        <ContextMenuProvider { ...props}>
            { children }
        </ContextMenuProvider>
    )
}

ContextMenu.Trigger = ContextMenuTrigger
ContextMenu.Content = ContextMenuContent
ContextMenu.Item = ContextMenuItem

export default ContextMenu
