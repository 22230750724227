import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { NavLink, useParams } from 'react-router-dom';

import RequireFunctionalRight from "@components/RequireFunctionalRight/RequireFunctionalRight";
import RequireFunctionalGroup from "@components/RequireFunctionalGroup/RequireFunctionalGroup";
import { FeatureFlag } from '@components/FeatureFlag/FeatureFlag';

//custom modules
import { 
  AIS_DASHBOARD_NAVIGATION, 
  AIS_ACTIVE_PROJECT_NAVIGATION, 
  AIS_FINALIZED_PROJECT_NAVIGATION, 
  BACK_ARROW_LINK } from '@constants/index'
import { useFinalizedProject } from '@hooks/useProject';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import styles from '@components/CLANavigation/CLANavigation.module.css';

const CLANavigation = (props) => {
  const logoSrc = props.logoSrc;
  const userAvatarDropDown = props.userAvatarDropDown;
  const userNotificationsDropdown = props.userNotificationsDropdown;
  const { projectId } = useParams();
  const isProjectFinalized = useFinalizedProject(projectId);

  const getNavType = () => {
    if(props.navType === 'ais') 
      return AIS_DASHBOARD_NAVIGATION.EN.menuList;
    if(!isProjectFinalized)
      return AIS_ACTIVE_PROJECT_NAVIGATION.EN.menuList // featureFlag is disabled 
    else
      return AIS_FINALIZED_PROJECT_NAVIGATION.EN.menuList;
  }

  const pages = getNavType();

  return (
    <AppBar position="static" className={styles.appBar}>
      <Toolbar disableGutters>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ mr: 2, display: { md: 'flex' } }}
        >
          {logoSrc}
        </Typography>
        <Box sx={{ flexGrow: 1, minWidth: '700px', display: { md: 'flex' } }}>
          {props.navType === 'project' ? (
            <Box className={styles.backLinkBox}>
              <NavLink to={AIS_DASHBOARD_NAVIGATION.EN.menuList[0].to} className={({ isActive }) => `${styles.backLink} ${isActive ? styles.backLinkActive : ""}`} data-test="nav-sub-link">
                <KeyboardBackspaceIcon fontSize={'small'} />
                {BACK_ARROW_LINK.backLink[0]}
              </NavLink>
            </Box>
          ) : null}

          {pages.map((page, index) => (
            (props.navType === 'ais') ?
              <RequireFunctionalRight key={index} rightsKey={page.to} showUnauthorize={false}>
                <RequireFunctionalGroup requiredGroups={page.requiredGroups}>
                  <FeatureFlag feature={page.featureFlagId}>
                    <NavLink
                      key={index}
                      end={page.to === "/" ? true : false} to={page.to === "/" ? page.to : `/${page.to}`}
                      className={({ isActive }) => `${styles.claNavLink} ${isActive ? styles.claNavLinkActive : ""}`}
                      data-test="nav-link">
                      {page.title}
                    </NavLink>
                  </FeatureFlag>
                </RequireFunctionalGroup>
              </RequireFunctionalRight>
              :
              <NavLink key={index} to={`${page.to}/${projectId}`} className={({ isActive }) => `${styles.claNavLink} ${isActive ? styles.claNavLinkActive : ""}`} data-test="nav-link">{page.title}</NavLink>
          ))}
        </Box>
        <Box sx={{ minWidth: '50px' }}>
          {userNotificationsDropdown}
        </Box>
        <Box sx={{ minWidth: '100px' }}>
          {userAvatarDropDown}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CLANavigation;
