import {
    Box,
    Divider
} from '@mui/material';
import CustomTailorProcedure from './CustomTailorProcedure';

const CustomTailorProcedureTable = ({ customProcedures, ...props }) => {
    return (
        <Box sx={{ p: 0 }}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', color: '#4B4B4B'}}>
                {customProcedures?.length > 0 && customProcedures.map((cp, index) => {
                    return (                                                    
                        <CustomTailorProcedure
                            key={cp.ProjectFormCustomProcedureId}
                            customProcedure={cp}
                            index={index}
                            {...props} />                        
                    )
                })}
            </Box>
        </Box>
    )
}

export default CustomTailorProcedureTable;