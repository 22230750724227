import React from 'react';
import './PageTitle.css'

const PageTitle = (props) => {

    return (
        <span className="title" data-test="title">
            {props.title[0].hasOwnProperty('name') || props.hasOwnProperty('accounts') ?
            props.title[0].name.split(',')[1] : props.title}
        </span>
    );

}

export default PageTitle;