import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const RiskAssessmentContext = createContext({});

export const RiskAssessmentProvider = ({ children }) => {
    const [concurrencyEventReceived, setConcurrencyEventReceived] = useState(false)
    const [financialStatementPersuasiveRisk, setFinancialStatementPersuasiveRisk] = useState([]);
    const [answerList, setAnswerList] = useState([]);
    const [auditAreaWithComment, setAuditAreaWithComment] = useState([]);

    const [concurrencyRiskInfo, setConcurrencyRiskInfo] = useState(null);
    const [concurrencyOtherRelevantAssertion, setConcurrencyOtherRelevantAssertion] = useState(null);

    const memoized = useMemo(
        () => ({
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            answerList,
            setAnswerList,
            financialStatementPersuasiveRisk,
            setFinancialStatementPersuasiveRisk,
            auditAreaWithComment,
            setAuditAreaWithComment,
            concurrencyRiskInfo, 
            setConcurrencyRiskInfo,
            concurrencyOtherRelevantAssertion, 
            setConcurrencyOtherRelevantAssertion
        }),
        [
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            answerList,
            setAnswerList,
            financialStatementPersuasiveRisk,
            setFinancialStatementPersuasiveRisk,
            auditAreaWithComment,
            setAuditAreaWithComment,
            concurrencyRiskInfo, 
            setConcurrencyRiskInfo,
            concurrencyOtherRelevantAssertion, 
            setConcurrencyOtherRelevantAssertion
        ]
    )

    return (
        <RiskAssessmentContext.Provider value={memoized}>{children}</RiskAssessmentContext.Provider>
    )
}

RiskAssessmentProvider.propTypes = {
    children: PropTypes.node
}