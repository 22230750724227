import { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Paper,
  FormControl,
  Typography,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';

import {FIELD_TYPES, FORM_LABEL_TYPES} from '@ais/constants';
import { DIALOG } from '@constants/forms';
import {CLADropdown} from "@ais/forms";
import formCategoriesService from '@services/forms/formCategories';
import formServices from '@services/forms/forms';
import questionsService from '@services/forms/questions';

export const SelectQuestion = (props) => {
  const {
    formSchemaID,
    methodologyVersionId,
    formName,
    formIndustries,
    formCategoryOptions,
    formCategory,
    sections,
    fields,
    handlePropertyChange,
    visible,
    setVisible,
    setSelectedQuestion,
    setLoading,
  } = props;

  const { ERROR } = DIALOG.SETTINGS;

  const [categoryId, setCategoryId] = useState(formCategory);
  const [formId, setFormId] = useState();
  const [sectionId, setSectionId] = useState();
  const [questionId, setQuestionId] = useState();
  const [formOptions, setFormOptions] = useState([]);
  const [formSchema, setFormSchema] = useState(null);
  const [formSectionsOptions, setFormSectionOptions] = useState([]);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [helperText, setHelperText] = useState('');
  const [searchQuestionId, setSearchQuestionId] = useState();

  const validateQuestionID = (data) => {
    const pattern =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let result = data.match(pattern) ? true : false;
    return result;
  };

  const validateQuestionType = (data) => {
    switch (data.type) {
      case FIELD_TYPES.CHECKBOXGROUP:
      case FIELD_TYPES.RADIOGROUP:
      case FIELD_TYPES.DROPDOWN:
        return true;
      default:
        return false;
    }
  };

  const validateQuestionRequiredIndustries = (data) => {
    let valid = false;
    if (!data) {
      //No data passed
      return valid;
    }
    data?.formMetaData?.forEach(formMetaData => {
      if (formMetaData?.industryData?.filter(industry => formIndustries.some(formIndustry => formIndustry.MethodologyIndustryId === industry.MethodologyIndustryId))?.length > 0) {
        valid = true;
      }
    });
    return valid;
  }

  const handleConfirm = async () => {
    // Prioritize manual input over drop down selection
    if (searchQuestionId) {
      if (validateQuestionID(searchQuestionId)) {
        try {
          const { data } = await questionsService.getRequiredQuestionById(searchQuestionId, true, methodologyVersionId);
          const questionContainsRequiredIndustries = validateQuestionRequiredIndustries(data);
          //If Question either not right type or not right industry, display appropriate error based on failed validation reason
          if (!validateQuestionType(data)) {
            setHelperText(
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {ERROR.QUESTION_ID_INVALID}
              </span>
            );
            return;
          } else if (!questionContainsRequiredIndustries) {
            setHelperText(
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {ERROR.QUESTION_INDUSTRY_REQUIREMENT_INVALID}
              </span>
            );
            return;
          } else {
            handlePropertyChange('isExternalQuestion', true);
            handlePropertyChange('questionId', searchQuestionId);
            setSelectedQuestion({
              label: data.label,
              value: searchQuestionId,
            });
            setHelperText('');
            setSearchQuestionId();
            setVisible(false);
          }
        } catch (error) {
          // Question ID not found
          setHelperText(
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              {ERROR.QUESTION_ID_INVALID}
            </span>
          );
        }
      } else {
        setHelperText(
          <div style={{ color: 'red', fontWeight: 'bold' }}>
            {ERROR.QUESTION_ID_INVALID}
          </div>
        );
      }
    } else if (questionId) {
      const selectedQuestion = questionOptions.find(
        (question) => question.value === questionId
      );
      if (validateQuestionType(selectedQuestion)) {
        await handlePropertyChange('questionId', questionId);
        await setSelectedQuestion({
          label: selectedQuestion.label,
          value: questionId,
        });
        await setQuestionId();
        await setVisible(false);
      } else {
        setHelperText(
          <div style={{ color: 'red', fontWeight: 'bold' }}>
            {ERROR.QUESTION_ID_INVALID}
          </div>
        );
      }
    }
  };

  async function fetchFormOptions() {
    try {
      setLoading(true);
      const { data } = await formCategoriesService.getRequiredFormsByCategoryId(
        categoryId,
        true
      );
      // Filter out forms without industryData (no required Industries) to contain only ones that contain a required industry matching current form, or current form itself.
      const parsedData = data?.filter(form => ((form.hasOwnProperty('industryData') &&
        form?.industryData?.filter((industry) =>
          formIndustries.some((formIndustry) => formIndustry.MethodologyIndustryId === industry.MethodologyIndustryId
          )
        )?.length > 0) ||
        form.FormName === formName) && form.MethodologyVersionId === methodologyVersionId);
        
      return parsedData.map((e) => {
        return {
          label: e.FormName,
          value: e.FormId,
        };
      });
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setLoading(false);
    }
  }

  async function fetchFormSchema() { 
    const applicableTypes = [
      FIELD_TYPES.DROPDOWN,
      FIELD_TYPES.RADIOGROUP,
      FIELD_TYPES.CHECKBOXGROUP,
    ];
    try {
      setLoading(true);
      const data = await formServices.getFormWithSchemaByFormId(
        formId 
      );
      setFormSchema(data?.formSchema);
      setFormSectionOptions(
        data?.formSchema?.flatMap((e) => {
          return {
            label: e.title,
            value: e.id,
          };
        })
      );
      const options = data?.formSchema?.flatMap((section) =>
        section.fields.flatMap((row) =>
          row.flatMap((e) => {
            return {
              label: e.label,
              value: e.existingQuestionId ?? e.id,
              type: e.type,
            };
          })
        )
      );
      const filteredOptions = options?.filter((option) => {
        if (applicableTypes.includes(option.type)) {
          return option;
        }
      });
      setLoading(false);
      setQuestionOptions(filteredOptions);
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setLoading(false);
    }
  }

  function updateQuestionOptions() {
    const applicableTypes = [
      FIELD_TYPES.DROPDOWN,
      FIELD_TYPES.RADIOGROUP,
      FIELD_TYPES.CHECKBOXGROUP,
    ];
    let options = [];
    if (sectionId) {
      const section = formSchema?.find((section) => section.id === sectionId);
      if (section) {
        options = section.fields
          .flatMap((row) => row)
          .flatMap((field) => ({
            label: field.label,
            value: field.id,
            type: field.type,
          }));
        options = options?.filter((option) => {
          if (applicableTypes.includes(option.type)) {
            return option;
          }
        });
      }
      setQuestionOptions(options);
    } else {
      if (formId) fetchFormSchema();
    }
  }

  useEffect(() => {
    if (!visible) {
      setFormOptions([]);
      setFormSectionOptions([]);
      setQuestionOptions([]);
    }
  }, [visible]);

  useEffect(() => {
    const init = async () => {
      if (formId) await fetchFormSchema();
    };
    init();
  }, [formId]);

  useEffect(() => {
    const init = async () => {
      if (categoryId) {
        const formOpts = await fetchFormOptions();
        setFormOptions(formOpts);
      }
    };
    init();
  }, [categoryId]);

  useEffect(() => {
    updateQuestionOptions();
  }, [sectionId]);

  useEffect(() => {
    const init = async () => {
      const formOpts = await fetchFormOptions();
      setFormOptions(formOpts);
      const found = formOpts?.find((form) => form?.label === formName);
      if (found?.label) {
        setFormId(found.value);
      }
    };
    init();
  }, []);

  return (
    <Grid container spacing={4} pt={4}>
      <Grid item xs={7}>
        <Paper
          sx={{
            p: 3,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <CLADropdown
                label="Select Form Category"
                labelType={FORM_LABEL_TYPES.OUTER}
                value={categoryId}
                options={formCategoryOptions}
                onChange={(e) => {
                  setLoading(true);
                  setFormId('');
                  setSectionId('');
                  setQuestionId('');
                  setCategoryId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CLADropdown
                label="Select Form"
                labelType={FORM_LABEL_TYPES.OUTER}
                placeholder="Select"
                value={formId}
                options={formOptions}
                onChange={(e) => {
                  setSectionId('');
                  setQuestionId('');
                  const found = formOptions.find(
                    (form) => form.value === e.target.value
                  );
                  const isExternalQuestionType = found.label !== formName;
                  handlePropertyChange(
                    'isExternalQuestion',
                    isExternalQuestionType
                  );
                  setFormId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CLADropdown
                emptyCanBeSelected
                label="Select Section"
                labelType={FORM_LABEL_TYPES.OUTER}
                placeholder="Select"
                value={sectionId}
                options={formSectionsOptions ?? sections}
                isDisabled={!formId}
                onChange={(e) => {
                  setQuestionId('');
                  setSectionId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CLADropdown
                label="Select Question"
                labelType={FORM_LABEL_TYPES.OUTER}
                placeholder="Select"
                value={questionId}
                isDisabled={!formId}
                options={questionOptions}
                onChange={(e) => {
                  setQuestionId(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography
                  variant="label"
                  sx={{
                    marginBottom: '8px',
                  }}
                >
                  <strong>OR</strong> paste in question ID from the source
                </Typography>
                <TextField
                  name="searchByQuestionID"
                  placeholder="Question ID"
                  maxLength={256}
                  onChange={(e) => {
                    setSearchQuestionId(e.target.value);
                  }}
                  helperText={helperText}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={handleConfirm}>
                OK
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
