export default {
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
    backgroundColor: "white !important"
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
    border: "none",
    outline: "none",
    backgroundColor: "white !important",
    borderBottom: "solid 1px #97979740"
  },
  "&.MuiDataGrid-root .MuiTablePagination-root": {
    marginRight: "40px !important"
  },
  "&.MuiDataGrid-root .MuiTablePagination-selectLabel": {
    display: 'none'
  }
}
