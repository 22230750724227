import React, { useState, useEffect } from 'react';
import { Checkbox, Box  } from '@mui/material';

export const  ConcurrentCheckbox = ({
    value,
    onChange,
    customFormObjectId,
    shouldHighlight = false,
    ...props
  }) => {
  
    const [isChecked, setIsChecked] = useState(value)
  
    useEffect(() => {
      if(typeof value === 'boolean') setIsChecked(value)
    }, [ value ])

    const handleOnChange = async (e) => {
      setIsChecked(e.target.checked)
      if(typeof onChange === 'function') onChange(e)
    }
  
    return (
      <Box
      sx={
        shouldHighlight
          ? {
              padding: '8px',
              border: '4px dashed red',
              borderBottom: '4px dashed red !important',
            }
          : {}
      }
    >
        <Checkbox
          sx={{
            px: 'auto'
          }}
          { ...props }
          checked={isChecked}
          onChange={handleOnChange}
        />
      </Box>
    )
  }

  export default ConcurrentCheckbox;