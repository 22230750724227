import { Box, Typography } from '@mui/material';

import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import CLACheckBoxField from "@components/Forms/CLACheckBoxField/CLACheckBoxField";

export const getColumns = () => {
    return [
        {
            type: 'TEXT',
            field: 'DeficiencyName',
            headerName: 'Deficiency Name',
            sortable: true,
            filterable: true,
            flex: 1,
            attributes: {
            }
        },
        {
            type: 'CHECKBOX',
            field: 'IsMagnitudeMaterial',
            editable: false,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            headerName: 'Magnitude is material / potential material',
            flex: 1,
            attributes: {
                tooltiptext: DEFICIENCY_FORM_INSTANCE.EN.EVALUATION_OF_DEFICIENCIES.TOOLTIPS.MAGNITUDE
            }
        },
        {
            type: 'CHECKBOX',
            field: 'IsOccurencePossible',
            editable: false,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            headerName: 'Occurence is at least  reasonably possible',
            flex: 1,
            attributes: {
                tooltiptext: DEFICIENCY_FORM_INSTANCE.EN.EVALUATION_OF_DEFICIENCIES.TOOLTIPS.OCCURENCE
            }
        },
        {
            type: 'CHECKBOX',
            field: 'IsPrudentOfficial',
            editable: false,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            headerName: 'Prudent official would agree this is not a material weakness',
            flex: 1
        },
        {
            type: 'CHECKBOX',
            field: 'IsMeritsGovernance',
            editable: false,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            headerName: 'Merits the attention of governance',
            flex: 1,
        },
        {
            type: 'TEXT',
            field: 'Determination',
            headerName: 'Determination',
            headerAlign: 'center',
            align: 'center',
            sortable: true,
            filterable: true,
            flex: 1,
            attributes: {
            }
        }
    ]
};


const determineCheckboxState = (field, row) => {
    switch (field) {
        case 'IsPrudentOfficial':
            if (row.IsOccurencePossible && row.IsMagnitudeMaterial) return true;
            if (!row.IsOccurencePossible && !row.IsMagnitudeMaterial) return true;
            return false
        case 'IsMeritsGovernance':
            if (row.IsOccurencePossible && row.IsMagnitudeMaterial) return true;
            if (row.IsOccurencePossible && !row.IsMagnitudeMaterial && !row.IsPrudentOfficial) return true;
            if (!row.IsOccurencePossible && !row.IsMagnitudeMaterial) return true;
            return false
        default:
            return false
    }
}


export const renderField = (data, attributes, handleChange, isDisabled) => {
    const {
        type,
        value,
        field,
        row,
    } = data;

    const disabled = determineCheckboxState(field, row) || isDisabled;

    switch (type) {
        case "ACTION":
            return null;
        case "CHECKBOX":
            return (
                <Box sx={{
                    m: '0 40px',
                    display: 'flex',
                    margin: 'auto'
                }}>
                    <CLACheckBoxField
                        checked={row[field] || false}
                        onChange={(e) => {
                            handleChange({
                                row,
                                field,
                                value: e.target.checked
                            });
                        }}
                        disabled={disabled}
                        {...attributes} />
                </Box>
            )
        default:
            return (
                <Typography sx={{
                    fontWeight: 600,
                    fontSize: '16px',
                    m: '20px 20px 20px 40px'
                }}>

                    {value}
                </Typography>
            )
    }
}

export const calculateDetermination = (row) => {
    const MagnitudeMaterial = row.IsMagnitudeMaterial ?? false;
    const OccurencePossible = row.IsOccurencePossible ?? false;
    const PrudentOfficial = row.IsPrudentOfficial ?? false;
    const MeritGovernance = row.IsMeritsGovernance ?? false;
    if (MagnitudeMaterial && OccurencePossible && !PrudentOfficial && !MeritGovernance) return DEFICIENCY_FORM_INSTANCE.EN.EVALUATION_OF_DEFICIENCIES.DETERMINATIONS.MATERIAL_WEAKNESS;
    if (MagnitudeMaterial && !OccurencePossible && PrudentOfficial && MeritGovernance) return DEFICIENCY_FORM_INSTANCE.EN.EVALUATION_OF_DEFICIENCIES.DETERMINATIONS.SIGNIFICANT_DEFICIENCY;
    if (!MagnitudeMaterial && OccurencePossible && PrudentOfficial && MeritGovernance) return DEFICIENCY_FORM_INSTANCE.EN.EVALUATION_OF_DEFICIENCIES.DETERMINATIONS.SIGNIFICANT_DEFICIENCY;
    if (!MagnitudeMaterial && OccurencePossible && !PrudentOfficial && !MeritGovernance) return DEFICIENCY_FORM_INSTANCE.EN.EVALUATION_OF_DEFICIENCIES.DETERMINATIONS.MATERIAL_WEAKNESS;
    if (!MagnitudeMaterial && OccurencePossible && PrudentOfficial && !MeritGovernance) return DEFICIENCY_FORM_INSTANCE.EN.EVALUATION_OF_DEFICIENCIES.DETERMINATIONS.OTHER_DEFICIENCY;
    return "";
}

export const getDeficiencyNames = (rows) => {
    return rows.map((row) => { return { value: row.ProjectDeficiencyId, label: row.DeficiencyName } });
}

export const validateDrawerFields = (fields) => {   
    return !(!!fields.combinationName && !!fields.combinationDescription && fields.selectedDeficiencies?.length >= 2);
}