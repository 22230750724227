import { useFormContext, useFieldArray } from "react-hook-form"
import { useEffect } from "react"

import AddIcon from "@mui/icons-material/Add";
import { DeleteIconComponent } from "@ais/assets";
import { ReactComponent as DeleteDisabledIcon } from "@assets/form_delete_disabled.svg";
import { Grid, IconButton } from "@mui/material";

import styles from "./AddAnotherAuditArea.module.css";

import { CLADropdown } from "@ais/forms";
import ScotabdsSelect from "@components/FormView/actions/components/ScotabdsSelect/ScotabdsSelect";
import { INHERENT_RISK_FACTORS } from "@constants/customForm";
import { COMMON } from '@constants/index'

export const AddAnotherAuditArea = ({ auditAreas, ProjectScopeIds }) => {
  const { ADD_ANOTHER_AUDIT_AREA, SCOTABDS_TRANSACTION, SCOTABDS_ACCOUNT_BALAMCE } = INHERENT_RISK_FACTORS.EN;
  const { SELECT } = COMMON.EN.OTHERS;

  const formMethods = useFormContext();
  const { fields, append, remove, update } = useFieldArray({ control: formMethods.control, name: `auditAreaScotabdsList` });

  const appendAuditArea = () => {
    append({
      id: null,
      auditArea: [],
      scotabds: []
    });
  }

  const onChangeAuditArea = (e, index) => {
    update(index, {
      id: null,
      auditArea: e.target.value,
      scotabds: []
    })
  }

  const onChangeScotabd = (e, index) => {
    update(index, {
      id: null,
      auditArea: fields[index].auditArea,
      scotabds: e
    })
  }

  const getScopeAuditAreaIds = (auditAreaItems) => {
    const { value } = auditAreas.find(item => item.value.some(id => auditAreaItems.includes(id))) ?? { value: [] };
    const ProjectScopeAuditAreaIds = value && Array.isArray(value) ? value : [value]
    return ProjectScopeAuditAreaIds;
  }

  useEffect(() => {
    if(!fields.length) appendAuditArea();
  }, [])

  return (
    <>
      <div>
        {fields && fields.map(({ auditArea, scotabds }, index) => {
          return (
            <Grid key={`auditAreaScotabds[${index}]`} container rowSpacing={6} columnSpacing={2} sx={{ paddingTop: "21px" }}>
              <Grid item xs={3} className={styles.auditAreaWrapper}>
                <CLADropdown
                  id={`auditAreaScotabdsList[${index}].auditArea[${index}]`}
                  name={`auditAreaScotabdsList[${index}].auditArea[${index}]`}
                  label="Audit Area"
                  onChange={(e) => onChangeAuditArea(e, index)}
                  value={auditArea}
                  options={auditAreas}
                />
              </Grid>
              <Grid item xs={9} className={styles.scotabdWrapper}>
                <div className={styles.scotabdInnerWrapper}>
                  <ScotabdsSelect
                    id={`auditAreaScotabdsList[${index}].scotabds[${index}]`}
                    name={`auditAreaScotabdsList[${index}].scotabds[${index}]`}
                    label="SCOTABDs"
                    onChange={(e) => onChangeScotabd(e, index)}
                    defaultValues={scotabds}
                    placeholderLeft={SCOTABDS_TRANSACTION}
                    placeholderRight={SCOTABDS_ACCOUNT_BALAMCE}
                    truncateDisplay
                    ProjectScopeIds={ProjectScopeIds}
                    ProjectScopeAuditAreaId={getScopeAuditAreaIds(auditArea)}
                    index={index}
                    placeholder={SELECT}
                  />
                  <div className={styles["delete-container"]}>
                    {fields && fields.length > 1 ? (
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIconComponent />
                      </IconButton>
                    ) : (
                      <DeleteDisabledIcon />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          )
        })}
      </div>

      <div className={styles["add-audit-area-container"]} onClick={appendAuditArea}>
        <AddIcon className={styles["plusIcon"]} />
        <div className={styles["plusIconText"]}>{ADD_ANOTHER_AUDIT_AREA}</div>
      </div>
    </>
  );
};
