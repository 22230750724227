'use strict';

// =============================================================================
// initialize logger
// =============================================================================

const neutralizeLineBreaks = (...inputs) => {
  return inputs.map(input => {
    if (typeof input === 'string') return input.replace(/[\n]/g, '\\n').replace(/[\r]/g, '\\r');
    else return input;
  });
};

const logger = {
  info: (...inputs) => console.info(neutralizeLineBreaks(...inputs)),
  warn: (...inputs) => console.warn(neutralizeLineBreaks(...inputs)),
  error: (...inputs) => console.error(neutralizeLineBreaks(...inputs)),
  fatal: (...inputs) => console.error(neutralizeLineBreaks(...inputs))
};

export default logger;