
const getItem = (name) => {
    return sessionStorage.getItem(name);
}

const setItem = (name, value) => {
    return sessionStorage.setItem(name, value);
}

const removeItem = (name) => {
    sessionStorage.removeItem(name);
}

const clear = () => {
    sessionStorage.clear();
}

const isListView = (key) => getItem(key) === 'true';

const sessionWrapper = {
    getItem,
    setItem,
    removeItem,
    clear,
    isListView
}

export default sessionWrapper;