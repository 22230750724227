export const assertionAcronymFormatter = (assertions, mainAssertion, primary = false) => {    
    const newAssertion = assertions?.length > 0 && mainAssertion?.length > 0 && Array.isArray(assertions) ? assertions.reduce((acc, cur) => {
        const assertionExist = mainAssertion.find(a => a?.AssertionId === cur?.AssertionId);                    
        return assertionExist && assertionExist?.Acronym ? [...acc, assertionExist.Acronym] : acc;            
    }, []) : [];

    if(primary) {
        return newAssertion.join(', ').trim().toUpperCase();
    }

    return newAssertion.join(', ').trim().toLowerCase();
}