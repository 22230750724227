export const SOCKET_EVENT_NAMES = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  RECONNECT: 'reconnect', 
  CONNECTION: 'connection',
  DISCONNECT: 'disconnect',
  CONNECT_ERROR: 'connect_error',
  ERROR_MESSAGE: 'error-message',
  EMITS: {
    FORM_INSTANCE: 'form-instance',
    QUESTION_UPDATE: 'question-update',
    BROADCAST_QUESTION_UPDATE: 'broadcast-question-update',
    REMOVE_USER: 'remove-user',
    BROADCAST_REMOVE_USER: 'broadcast-remove-user',
    INITIAL_CONNECTION: 'initial-connection',
    SESSION_DROPPED: 'session-dropped',
    EXT_FORM_INSTANCE: 'ext-form-instance',
    EXT_QUESTION_UPDATE: 'ext-question-update',
    EXT_BROADCAST_QUESTION_UPDATE: 'ext-broadcast-question-update',
    EXT_REMOVE_USER: 'ext-remove-user',
    EXT_BROADCAST_REMOVE_USER: 'ext-broadcast-remove-user',
    EXT_INITIAL_CONNECTION: 'ext-initial-connection',
    EXT_SESSION_DROPPED: 'ext-session-dropped',
  },
};

export const SOCKET_EVENT_TYPES = {
  LOCK: 'lock',
  UNLOCK: 'unlock'
}


export const PROJECT_FORM_CUSTOM_LOCK = {
  INITIALIZE_BASE_STATE: "INITIALIZE_BASE_STATE",
  SUBSCRIBE_QUESTION_ID: "SUBSCRIBE_QUESTION_ID",
  INITIALIZE_SESSIONS: 'INITIALIZE_SESSIONS',
  UPSERT_SESSION: 'UPSERT_SESSION',
  REMOVE_SESSION: 'REMOVE_SESSION',
  UPDATE_IDLE: 'UPDATE-IDLE',
  MANUAL_UNLOCK_PROCEDURE_COMPONENT: 'MANUAL_UNLOCK_PROCEDURE_COMPONENT',
}

export const PROJECT_FORM_USAGE_ACTIONS = {
  INITIALIZE: 'INITIALIZE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  SESSION_DROPPED: 'SESSION_DROPPED',
}