import React, {useState, useEffect} from 'react';
import {Button, TextField} from '@mui/material';
import {useQueryClient} from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {toast} from 'react-toastify';
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import {CLASimpleDrawer} from "@ais/components";
import CLATextField from '@components/Forms/CLATextField/CLATextField';
import {CLASelect} from '@ais/forms';
import {usePutProjectDeficiencyCombination} from '@services/deficiencyCombination';

import styles from "../CLAEvaluationOfDeficiencies.module.css";

import {getDeficiencyNames, validateDrawerFields} from '../helpers';

export const EditCombinationDrawer = ({
  rows, 
  showDrawer, 
  projectId, 
  deficiencyCombination,
  onCancelEditClick,
  onSave,
}) => {
  const { EVALUATION_OF_DEFICIENCIES } = DEFICIENCY_FORM_INSTANCE.EN;
  const [combinationName, setCombinationName] = useState('');
  const [combinationDescription, setCombinationDescription] = useState('');
  const [selectedDeficiencies, setSelectedDeficiencies] = useState([]);
  const [deficiencyNameExist, setDeficiencyNameExist] = useState({
      isExist: false,
      message: ''
  });

  const { projectFormId } = useParams();

  const {
    mutate: deficiencyCombinationUpdateMutate,
    isLoading: deficiencyCombinationUpdateMutateIsLoading,
  } = 
    usePutProjectDeficiencyCombination();

  const queryClient = useQueryClient();

  const handleSaveClick = () => {
    const updatedDeficiencyCombination = {
      ...deficiencyCombination,
      CombinationName: combinationName,
      CombinationDescription: combinationDescription,
      Deficiencies: selectedDeficiencies.map((id) => { return { ProjectDeficiencyId: id } }),
      RequestProjectFormId: projectFormId
    };

    deficiencyCombinationUpdateMutate({
      projectDeficiencyCombinationId: updatedDeficiencyCombination.ProjectDeficiencyCombinationId, 
      reqBody: updatedDeficiencyCombination,
      projectId
    }, {
      onError: (error) => {
        const errResponse = error?.response;
        if (errResponse?.status === 409) {
          setDeficiencyNameExist({ isExist: true, message: 'Deficiency Combination Name must be unique.' });
        } else {
          setDeficiencyNameExist({ isExist: false, message: '' });
          toast.error(error.toString());
        }
      },
      onSuccess: () => {
        onSave();
        queryClient.invalidateQueries(['deficiencyEvaluationForm']);
      }
    });    
  };

  useEffect(() => {
    if (!showDrawer) return;

    const {
      CombinationName,
      CombinationDescription,
      Deficiencies
    } = deficiencyCombination;
  
    const selectedIds = Deficiencies.map(deficiency => deficiency.ProjectDeficiencyId);

    setCombinationName(CombinationName);
    setCombinationDescription(CombinationDescription);
    setSelectedDeficiencies(selectedIds);
    setDeficiencyNameExist({isExist: false, message: ''})
  }, [showDrawer]);

  return (
    <CLASimpleDrawer
      anchor="right"
      isVisible={showDrawer}
      headerTitle={EVALUATION_OF_DEFICIENCIES.DRAWER.DEFICIENCY_COMBINATION}
      onClose={onCancelEditClick}
      width={'40%'}
    >
      <div className={styles["add-combination-drawer-wrapper"]}>
        <div className={styles["add-combination-container"]}>
          <div className={styles["add-combination-buttons"]}>
            <Button variant="outlined" disableElevation onClick={onCancelEditClick}>
              Cancel
            </Button>
          </div>
          <div className={styles["add-combination-buttons"]}>
            <Button
              disabled={
                validateDrawerFields({ combinationName, combinationDescription, selectedDeficiencies }) ||
                deficiencyCombinationUpdateMutateIsLoading
              }
              variant="contained"
              disableElevation
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <div className={styles["add-combination-drawer-content-wrapper"]}>
        <div className={styles["add-combination-drawer-content-field"]}>
          <CLATextField
            label={EVALUATION_OF_DEFICIENCIES.DRAWER.COMBINATION_NAME}
            onChange={(e) => { setCombinationName(e.target.value) }}
            value={combinationName}
            inputLableShrink={true}
            error={deficiencyNameExist.isExist}
            helperText={deficiencyNameExist.message}
            placeholder={EVALUATION_OF_DEFICIENCIES.DRAWER.COMBINATION_NAME_PLACEHOLDER}
          />
        </div>
        <div className={styles["add-combination-drawer-content-field"]}>
          <TextField
            label={EVALUATION_OF_DEFICIENCIES.DRAWER.COMBINATION_DESCRIPTION}
            fullWidth
            multiline
            placeholder={EVALUATION_OF_DEFICIENCIES.DRAWER.COMBINATION_DESCRIPTION_PLACEHOLDER}
            rows={4}
            value={combinationDescription}
            onChange={(e) => { setCombinationDescription(e.target.value) }}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              notched: true
            }}
            inputProps={{
              maxLength: 4096
            }}
          />
        </div>
        <div className={styles["add-combination-drawer-content-field"]}>
          <CLASelect
            id={'projectScopeDeficiencySummary'}
            name={'projectScopeDeficiencySummary'}
            label={EVALUATION_OF_DEFICIENCIES.DRAWER.COMBINATION_DESCRIPTION}
            placeholder={EVALUATION_OF_DEFICIENCIES.DRAWER.DEFICIENCIES_PLACEHOLDER}
            isDisabled={false}
            truncateDisplay={true}
            defaultValues={selectedDeficiencies}
            menuItems={getDeficiencyNames(rows)}
            onChange={values => setSelectedDeficiencies(values)}
            error={false}
            helperText={''}
          />
        </div>
      </div>
    </CLASimpleDrawer>
  )
};