import { useState, useEffect } from 'react'
import { Button } from '@mui/material';

import { CLADialog } from '@ais/components';
import { Flex } from '@ais/palette';

import { CLA_INITIAL_SCOPING_CONSIDERATIONS_MODAL } from '@constants/forms/claInitialScopingConsiderations'

export const CLAFrameworkChangeModal = ({
    isFrameworkModalOpen,
    onConfirmFrameworkWarningModal,
    hasLoadingAuditAreaFrameworkData,
    setIsFrameworkModalOpen,
    associatedFrameworkData
}) => {

    const {
        FRAMEWORK,
        ASSOCIATED_RISK_FACTORS,
        ASSOCIATED_RISK,
        ASSOCIATED_DEFICIENCIES,
        KEY_CONTROLS,
        BUTTON_LABEL,
        WARNING_TITLE
    } = CLA_INITIAL_SCOPING_CONSIDERATIONS_MODAL.EN;

    const {
        associatedRiskFactors,
        associatedRisks,
        associatedDeficiency,
        associatedKeyControls,
    } = associatedFrameworkData;

    return <CLADialog
        visible={!hasLoadingAuditAreaFrameworkData && isFrameworkModalOpen}
        title={WARNING_TITLE}
        closeButtonLabel={BUTTON_LABEL}
        singleButton={true}
        width={635}
        setVisible={setIsFrameworkModalOpen}
        customActionButton={<ConfirmButton label={BUTTON_LABEL} onClick={onConfirmFrameworkWarningModal} />}
    >
        <Flex>
            <WarningText>
                {FRAMEWORK.WARNING_TEXT}
            </WarningText>
            <Flex style={{ maxHeight: '600px', overflowY: 'overlay', marginBottom: '10px' }}>
                {   
                    associatedRiskFactors.length > 0 && (
                        <Flex style={{marginBottom: '20px'}}>
                            <ItemHeading>{ASSOCIATED_RISK_FACTORS}</ItemHeading>
                            {
                                associatedRiskFactors.map(({RiskFactorName}) => {
                                    return <Item>{RiskFactorName}</Item>
                                })
                            }
                        </Flex>
                    )
                }
                {   
                    associatedRisks.length > 0 && (
                        <Flex style={{marginBottom: '20px'}}>
                            <ItemHeading size="3">{ASSOCIATED_RISK}</ItemHeading>
                            {
                                associatedRisks.map(({RiskName, RiskDescription}) => {
                                    return <Flex direction="row" justify='start'>
                                        <Item style={{ width: '50%'}}>{RiskName}</Item>
                                        <Item>{RiskDescription}</Item>
                                    </Flex>
                                })
                            }
                        </Flex>
                    )
                }
                {   
                    associatedDeficiency.length > 0 && (
                        <Flex style={{marginBottom: '20px'}}>
                            <ItemHeading size="3">{ASSOCIATED_DEFICIENCIES}</ItemHeading>
                            {
                                associatedDeficiency.map(({DeficiencyName, DeficiencyDescription}) => {
                                    return <Flex direction="row" justify='start'>
                                        <Item style={{ width: '50%'}}>{DeficiencyName}</Item>
                                        <Item>{DeficiencyDescription}</Item>
                                    </Flex>
                                })
                            }
                        </Flex>
                    )
                }
                {   
                    associatedKeyControls.length > 0 && (
                        <Flex style={{marginBottom: '20px'}}>
                            <ItemHeading size="3">{KEY_CONTROLS}</ItemHeading>
                            {
                                associatedKeyControls.map(({ProjectKeyControlName, ProjectKeyControlDescription}) => {
                                    return <Flex direction="row" justify='start'>
                                        <Item style={{ width: '50%'}}>{ProjectKeyControlName}</Item>
                                        <Item>{ProjectKeyControlDescription}</Item>
                                    </Flex>
                                })
                            }
                        </Flex>
                    )
                }
            </Flex>
        </Flex>
    </CLADialog>
}

const ConfirmButton = ({onClick, label}) => (
    <Button
        variant="contained"
        disableElevation
        onClick={onClick}>
        {label}
    </Button>
)

const ItemHeading = ({children}) => (
    <span style={{
        color: '#2D3C59',
        fontFamily: 'museo-sans',
        fontWeight: 'bold',
        lineHeight: '18.8px',
        fontSize: '14px',
        paddingBottom: '5px',
        marginBottom: '10px',
        display: 'block',
        width: '100%',
        borderBottom: '1px solid #979797'
    }}>{children}</span>
)

const Item = ({children}) => (
    <span style={{
        color: '#4B4B4B',
        fontFamily: 'museo-sans',
        fontWeight: '600',
        lineHeight: '16.8px',
        fontSize: '14px',
        marginBottom: '5px',
        width: '100%'
    }}>
        {children}
    </span>
)

const WarningText = ({children}) => (
    <div style={{
        fontFamily: 'museo-sans',
        fontWeight: 400,
        fontSize: '12px',
        marginTop: '-15px',
        marginBottom: '20px'
    }}>
        {children}
    </div>
)