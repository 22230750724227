import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useCommunicationOfSignificantsMattersContext } from "@contexts/CommunicationOfSignificantsMattersContext";
import styles from './styles.module.css';
import { useFinalizedProject } from '@hooks/useProject';
import { ConcurrentInternalControlTextbox } from '../../ConcurrentInternalControlTextbox';
import { INTERNAL_CONTROLS } from '@constants/customForm';
import { renderLabel } from '@ais/forms';
import CustomToast from '@components/CustomToast/CustomToast'; 
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext';
import { Box } from '@mui/material';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';

import { useRoomIdle, useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { useOthers } from '@components/Concurrency/store/users';
import { WAITING_TIME } from '@ais/constants';
import { useProjectFormInstanceProvider } from '@providers';

const {
  COMMUNICATION_OF_SIGNIFICANT_MATTERS: {
    LABEL,
    BETWEEN_PEOPLE_LABEL,
    BETWEEN_MANAGEMENT_LABEL,
    EXTERNAL_COMMENT_LABEL,
    PLACEHOLDER,
    PEOPLE_WITH_ENTITY_COMMENT_FIELD_NAME,
    MANAGEMENT_COMMENT_FIELD_NAME,
    EXTERNAL_COMMENT_FIELD_NAME
  }
} = INTERNAL_CONTROLS.EN;

const CommunicationOfSiginificantMatters = (props) => {
  const { signOffList } = props;

  const _useCommunicationOfSignificantsMattersContext = useCommunicationOfSignificantsMattersContext() 
  const { userId } = useProjectFormInstanceProvider();
  const { internalControls: { ExternalComment, ManagementComment, PeopleWithinEntityComment, History }, actions: { handleSave }, isError, isLoading, isSuccess } = _useCommunicationOfSignificantsMattersContext
  const {
    formattedInternalControls
  } = useInternalControlsContext();
  const [communicationHighlight, setCommunicationHighlight] = useState(false)
  const [peopleWithEntityCommentValue, setPeopleWithEntityCommentValue] = useState(PeopleWithinEntityComment)
  const [managementCommentValue, setManagementCommentValue] = useState(ManagementComment)
  const [externalCommentValue, setExternalCommentValue] = useState(ExternalComment)
  const [currentFocusedField, setCurrentFocusedField] = useState(null)
  const peopleWithEntityRef = useRef(null)
  const managementCommentRef = useRef(null)
  const externalCommentRef = useRef(null)
  const [ isBypassSave, setIsByPassSave ] = useState(false) 
  const { projectId } = useParams();
  const isProjectFinalized = useFinalizedProject(projectId);
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();
  const isIdle = useRoomIdle()

  useEffect(() => {    
    setPeopleWithEntityCommentValue(PeopleWithinEntityComment)
  }, [PeopleWithinEntityComment]);

  useEffect(() => {
    setManagementCommentValue(ManagementComment)
  }, [ManagementComment]);

  useEffect(() => {
    setExternalCommentValue(ExternalComment)
  }, [ExternalComment]);

  useEffect(() => {
      if (isIdle) {
        handleIdleField() 
      }
  }, [isIdle])

  const handleIdleField = () => {
    if (currentFocusedField === "between-people-textbox") {
      setPeopleWithEntityCommentValue(PeopleWithinEntityComment)
      peopleWithEntityRef.current?.blur()
    } else if (currentFocusedField === "between-management-textbox") {
      setManagementCommentValue(ManagementComment)
      managementCommentRef.current?.blur()
    } else if (currentFocusedField === "with-external-parties-textbox") {
      setExternalCommentValue(ExternalComment)
      externalCommentRef.current?.blur()
    }
    setIsByPassSave(true)
    updateMyPresence({ focusedId: null, type: 'custom' })
  }

  const handleOnBlurEvent = (event, identifier = null, focusedId = null) => { 
    if (isIdle) { 
      return
    }
    updateMyPresence({ focusedId, type: 'custom' })
    setCurrentFocusedField(focusedId)
    handleSave(identifier, event.target.value);
  };

  const handleOnFocusEvent = (event, identifier = null, focusedId) => {
    updateMyPresence({ focusedId, type: 'custom' }) 
    setCurrentFocusedField(focusedId)
  }

  const customLabelSx = {
    backgroundColor: '#FFFFFF',
    paddingRight: '3px',
    maxWidth: 'fit-content'
  }

  useEffect(() => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    const dateModified = new Date(formattedInternalControls.ValidFrom).getTime();
    const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
    const shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
    setCommunicationHighlight(isSignoffEnabled && shouldHighlight)
  }, [formattedInternalControls, signOffList])

  return (
    <>
      <div className={styles['communcation-container']}>
        <div className={styles['communcation-text']}>{LABEL}</div>
        <Box className={styles['communcation-main-content']} sx={communicationHighlight ? { borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p: '10px', pt: '1px', width: '100%' } : { width: '100%' }}>
          <VFRenderedFieldWrapper
            className={styles["field__wrapper"]}
            isLockedByUser={others.find((user) => user.presence.focusedId === "between-people-textbox" && user.info.userId.toLowerCase() !== userId.toLowerCase())}
          >
            <ConcurrentInternalControlTextbox
              inputRef={peopleWithEntityRef}
              value={peopleWithEntityCommentValue || ''}
              label={renderLabel(BETWEEN_PEOPLE_LABEL, null, null, null, customLabelSx)}
              isMultilineLabel={true}
              placeholder={PLACEHOLDER}
              defaultValue={peopleWithEntityCommentValue || ''}
              onKeyUp={e => setPeopleWithEntityCommentValue(e.target.value)}
              customFormObjectId={"between-people-textbox"}
              fieldName={PEOPLE_WITH_ENTITY_COMMENT_FIELD_NAME}
              disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === "between-people-textbox" && user.info.userId.toLowerCase() !== userId.toLowerCase())}
              onBlur={handleOnBlurEvent}
              onFocus={handleOnFocusEvent}
            />
          </VFRenderedFieldWrapper>
          <VFRenderedFieldWrapper
            className={styles["field__wrapper"]}
            isLockedByUser={others.find((user) => user.presence.focusedId === "between-management-textbox" && user.info.userId.toLowerCase() !== userId.toLowerCase())}
          >
            <ConcurrentInternalControlTextbox
              inputRef={managementCommentRef}
              value={managementCommentValue || ''}
              defaultValue={managementCommentValue || ''}
              onKeyUp={e => setManagementCommentValue(e.target.value)}
              label={renderLabel(BETWEEN_MANAGEMENT_LABEL, null, null, null, customLabelSx)}
              isMultilineLabel={true}
              placeholder={PLACEHOLDER}
              fieldName={MANAGEMENT_COMMENT_FIELD_NAME}
              customFormObjectId={"between-management-textbox"}
              disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === "between-management-textbox" && user.info.userId.toLowerCase() !== userId.toLowerCase())}
              onBlur={handleOnBlurEvent}
              onFocus={handleOnFocusEvent}
            />
          </VFRenderedFieldWrapper>
          <VFRenderedFieldWrapper
            className={styles["field__wrapper"]}
            isLockedByUser={others.find((user) => user.presence.focusedId === "with-external-parties-textbox" && user.info.userId.toLowerCase() !== userId.toLowerCase())}
          > 
          <ConcurrentInternalControlTextbox
              inputRef={externalCommentRef}
              value={externalCommentValue || ''}
              defaultValue={externalCommentValue || ''}
              onKeyUp={e => setExternalCommentValue(e.target.value)}
              label={renderLabel(EXTERNAL_COMMENT_LABEL, null, null, null, customLabelSx)}
              isMultilineLabel={true}
              placeholder={PLACEHOLDER}
              fieldName={EXTERNAL_COMMENT_FIELD_NAME}
              customFormObjectId={"with-external-parties-textbox"}
              disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === "with-external-parties-textbox" && user.info.userId.toLowerCase() !== userId.toLowerCase())}
              onBlur={handleOnBlurEvent}
              onFocus={handleOnFocusEvent}
            />
          </VFRenderedFieldWrapper>
        </Box>
        <CustomToast error={isError} success={isSuccess} loading={isLoading} />
      </div>
    </>
  )
}

export default CommunicationOfSiginificantMatters
