import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useFormContext } from "react-hook-form"

const CLADialogForm = (props) => {
  const {
    visible,
    title,
    message,
    cancelText,
    submitText,
    onCancel,
    children,
    onSubmit,
    sx = {},
    sxActions = {},
    saveDisabled = false
  } = props;

  const {
    formState: { isValid },
    handleSubmit
  } = useFormContext();
  return (
    <Dialog
      open={visible}
      scroll="paper"
      variant="cla-dialog-form"
      sx={sx}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle variant="cla-dialog-form">{title}</DialogTitle>
        <DialogContent variant="cla-dialog-form">
          <span>{message}</span>
          {children}
        </DialogContent>
        <DialogActions sx={sxActions}>
          <Button
            variant="outlined"
            disableElevation
            onClick={onCancel}
            sx={{ minWidth: '92px' }}
          >
            {cancelText}
          </Button>
          <Button
            disabled={!isValid || saveDisabled}
            variant="contained"
            disableElevation
            type="submit"
            sx={{ padding: '9px 25px 8.53px 25px', minWidth: '92px' }}
          >
            {submitText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

CLADialogForm.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  onCancel: PropTypes.func,
};

CLADialogForm.defaultProps = {
  visible: false,
};

export default CLADialogForm;
