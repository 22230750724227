import { SCHEMA_ACTION_TYPES } from '@ais/constants';
import { parseQuestionLabel } from '@ais/forms';

export const SchemaReducer = (state, action) => {
  let newState = { ...state };
  switch (action.type) {
    case SCHEMA_ACTION_TYPES.INITIALIZE_SCHEMA: {
      newState = { ...action.payload };
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_PROPERTIES: {
      newState.properties = [...action.payload];
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_SECTION: {
      const index = action.index;
      const payload = action.payload;
      newState.properties[index] = payload;
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_SECTION_FIELDS: {
      const index = action.index;
      const payload = action.payload;
      newState.properties[index].fields = [...payload];
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_QUESTION_GROUP_FIELDS: {
      const sectionIndex = action.sectionIndex;
      const rowIndex = action.rowIndex;
      const questionGroupIndex = action.questionGroupIndex;
      const payload = action.payload;
      newState.properties[sectionIndex].fields[rowIndex][questionGroupIndex].fields = [...payload];
      return newState;
    }
    case SCHEMA_ACTION_TYPES.STRINGIFY_QUESTION_GROUP_FIELDS: {
      const sectionIndex = action.sectionIndex;
      const rowIndex = action.rowIndex;
      const questionGroupIndex = action.questionGroupIndex;
      const label = newState.properties[sectionIndex].fields[rowIndex][questionGroupIndex].label;
      const parsed = parseQuestionLabel(label);
      const newLabel = JSON.stringify({
        questionLabel: typeof parsed === 'object' ? parsed.questionLabel : parsed,
        fields: newState.properties[sectionIndex].fields[rowIndex][questionGroupIndex].fields,
      });
      newState.properties[sectionIndex].fields[rowIndex][questionGroupIndex].label = newLabel;
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_FIELDS: {
      const index = action.index;
      const payload = action.payload;
      const rowIndex = action.rowIndex;
      newState.properties[index].fields[rowIndex] = [...payload];
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_FORM_SETTINGS: {
      newState.formSettings = { ...action.payload };
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_DISABLED_DELETE_QUESTION_IDS: {
      newState.disabledDeleteQuestionIds = [...action.payload];
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_NEWLY_ADDED_IDS: {
      newState.newlyAddedIds = [...action.payload];
      return newState;
    }
    case SCHEMA_ACTION_TYPES.SET_LOADING: {
      newState.isLoading = action.payload;
      return newState;
    }
    case SCHEMA_ACTION_TYPES.INITIALIZE_ANSWER_LINK_DEFAULT_IDS: {
      newState.answerLinkDefaultIds = [...action.payload];
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_ANSWER_LINK_DEFAULT_IDS: {      
      newState.answerLinkDefaultIds[action.index].isUserModified = !!action.payload;
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_FORM_RULES: {
      const questionId = action.questionId;
      const sectionId = action.sectionId;
      const foundIndex = newState.formRules?.findIndex(r=> r.QuestionId?.toLowerCase() === questionId?.toLowerCase() && r.SectionId?.toLowerCase() === sectionId?.toLowerCase());

      if (foundIndex >= 0)
        newState.formRules[foundIndex] =
        {
          FormRuleId: newState.formRules[foundIndex].FormRuleId,
          ...action.payload
        };
      else newState.formRules =  [...newState.formRules, action.payload];
      
      return newState;
    }
    case SCHEMA_ACTION_TYPES.UPDATE_FORM_PROCEDURES: {
      const sectionId = action.sectionId;
      const foundIndex = newState.formProcedures?.findIndex(proc=> proc.SectionId?.toLowerCase() === sectionId?.toLowerCase());

      if (foundIndex >= 0)
        newState.formProcedures[foundIndex] = {
          FormProcedureComponentAuditAreaId: newState.formProcedures[foundIndex].FormProcedureComponentAuditAreaId,
          ...action.payload
        };
      else newState.formProcedures =  [...newState.formProcedures, action.payload];
      
      return newState;
    }
  }
};
