import React, { memo } from "react";
import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form"

import styles from '@components/FormView/FormView.module.css';
import { useVFCanvasProvider } from '@providers';
import { FIELD_TYPES } from "@ais/constants";
import { TbLinkBaseField, NoValidationInput } from './TbLinkFields';
import CLATrialBalanceSelect from '@views/KPI/CLATrialBalanceSelect/CLATrialBalanceSelect';
import { useOthers } from "@components/Concurrency/store/users";
import { NATGSelector } from "@components/NATGSelector/NATGSelector";
import { useProjectFormInstanceProvider } from '@providers';
export const VFFields = memo((props) => {
    const {
        field,
        projectId,
        disabled: _disabled,
        isInstance,
        section,
        answerList,
        renderLink,
        getDefaultValue, 
        handleOnFocus,
        procedureFilter,
        watchRadioList,
        handleBlur,
        formTypeId,
        units,
        isIdle,
        project,
        answered, 
        signOffList
    } = props;
    
    const { userId } = useProjectFormInstanceProvider();
    const formCanvas = useFormContext();
    const { actions } = useVFCanvasProvider();
    const others = useOthers();
    const isLockedByUser = others.find((user) => user.presence.focusedId === field.id && user.info.userId.toLowerCase() !== userId.toLowerCase())
    const disabled = _disabled || field.linkType === 'Read Only' || isLockedByUser !== undefined;

    switch (field.type) {
        case FIELD_TYPES.KPI_TRIAL_BALANCE: {
            const defaultValue = getDefaultValue(true);
            const defaultArrayValue = Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : [];
            return (
                <Box className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && projectId ? (
                            <CLATrialBalanceSelect
                                defaultValues={defaultArrayValue}
                                formTypeId={formTypeId}
                                handCaptureInput={(userInput) => {
                                    formCanvas.setValue(field.id, userInput);
                                    actions.handleSubmit();
                                }}
                                field={field}
                                isDisabled={_disabled}
                                project={project}
                                onFocus={handleOnFocus}
                                signOffList={signOffList}
                                highlightable={true}
                                isLockedByUser={isLockedByUser}
                                isLocked={isLockedByUser}
                            />
                        ) : (
                            <div className={styles["vf-section-placeholder"]}>
                                Trial Balance
                            </div>
                        )}
                    </div>
                    {renderLink()}
                </Box>
            );
        }
        default: {
            return (
                <NATGSelector
                    answered={answered}
                    answerList={answerList}
                    disabled={disabled}
                    field={field} 
                    formCanvas={formCanvas}
                    formTypeId={formTypeId}
                    isIdle={isIdle}
                    isInstance={isInstance}
                    isLockedByUser={isLockedByUser}
                    NoValidationInput={NoValidationInput}
                    procedureFilter={procedureFilter}
                    projectId={projectId}
                    section={section}
                    tbLinkBaseField={TbLinkBaseField}
                    units={units}
                    watchRadioList={watchRadioList}
                    getDefaultValue={getDefaultValue}
                    handleBlur={handleBlur}
                    handleOnFocus={handleOnFocus}
                    renderLink={renderLink}   
                />
            );
        }
    }
});
