import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";
import { shallow } from "zustand/shallow";

import usersServices from '@services/users';
import { getProjectFormUserByProjectFormAndSessionId } from "@services/projectFormUsage";

export const useOtherUsersStore = create(
    (set, get) => ({
        projectId: null,
        projectFormId: null,
        users: [],
        initializeProjectFormData: (projectId, projectFormId) => {
            set({ projectId, projectFormId })
        },
        initialize: (users) => {
            if(!users) {
                return
            }
            const append = get().append
            for(let x = 0; x < users.length; x++) {
                const user = users[x]
                if(!user) {
                    continue
                }
                append(user)
            }
        },
        append: (user) => {
            const existingUsers = get().users
            const foundUser = existingUsers.find((existingUser) => existingUser.sessionId === user.sessionId)
            if(foundUser) {
                return
            }
            set({ users: [ ...existingUsers, user ]})
        },
        remove: (sessionId) => {
            const existingUsers = get().users
            const foundUserIndex = existingUsers.findIndex((user) => user.sessionId === sessionId)
            if(foundUserIndex > -1) {
                const users = [
                    ...existingUsers.slice(0, foundUserIndex),
                    ...existingUsers.slice(foundUserIndex + 1)
                ]
                set({ users })
            }
        },
        updateUserPresence: async (sessionId, presence) => {
            const existingUsers = get().users
            const user = existingUsers.find((user) => user.sessionId === sessionId) ?? null

            // If the user doesn't exist in existing users, add it to the users array
            // else just update the presence
            let users;
            if(!user) {
                const { projectId, projectFormId } = get()
                const { userId, name } = await getProjectFormUserByProjectFormAndSessionId(
                    projectId, 
                    projectFormId, 
                    sessionId
                );
                const newUser = {
                    sessionId,
                    info: {
                        userId: userId?.toLowerCase() ?? "",
                        name,
                    },
                    presence
                }

                users = [
                    newUser,
                    ...existingUsers,
                ]
            } else {
                user.presence = presence

                const index = existingUsers.findIndex((user) => user.sessionId === sessionId)
                users = [
                    user,
                    ...existingUsers.slice(0, index),
                    ...existingUsers.slice(index + 1),
                ]
            }

            set({ users })
        },
        clear: () => {
            set({ users: [] })
        }
    })
)

export const createStoreWithSelectors = (store) => {
    return function useStore(keys) {
        return store((state) => {
            const x = keys.reduce((acc, cur) => {
                acc[cur] = state[cur];
                return acc;
            }, {});
            return x
        }, shallow);
    };
};

export const useOthersStoreWithSelectors = createStoreWithSelectors(useOtherUsersStore);

export const useOthers = () => useOtherUsersStore((state) => state.users)

export const useOthersMapped = (selector) => {
    return useOtherUsersStore(useShallow(selector))
}
