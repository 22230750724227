
import {useContext} from "react"
import { ApplicationContext } from "@contexts/ApplicationContext";
import { APPLICATION_ACTION } from "@constants/index";

export const useLoading = () => {
    const { dispatchApplication } = useContext(ApplicationContext);

    const setLoading = (value) => {
        dispatchApplication({
            type: APPLICATION_ACTION.SET_LOADING,
            payload: value,
          });
    }

    return setLoading
}

