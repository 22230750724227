// libraries
import PropTypes from 'prop-types';

// MUI
import { TextField } from '@mui/material';

// helpers
import { renderLabel } from '@ais/forms';

// constants
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';

const UOEInfoProcessingConstants = UNDERSTANDING_OF_ENTITY.INFORMATION_PROCESSING;

export const InternalControlTextbox = ({
  label,
  isMultilineLabel = false,
  placeholder,
  value,
  maxLength = 4096,
  nonMultilineLabelFontSize = '18.2px',
  nonMultilineLabelMarginLeft = '23px',
  onBlur,
  sx,
  disabled,
  onChange,
  shouldHighlight,
  ...props
}) => {
  return (
    <TextField
      fullWidth={true}
      variant="outlined"
      multiline
      minRows={3}
      maxRows={20}
      label={isMultilineLabel ? label : renderLabel(label, 'tooltip', null, {
        fontSize: nonMultilineLabelFontSize,
        marginLeft: nonMultilineLabelMarginLeft,
        visibility: 'hidden'
      })}
      placeholder={placeholder}
      value={value}
      inputProps={{
        maxLength
      }}
      InputLabelProps={{
        shrink: true,
        ...(isMultilineLabel && { variant: 'multiline-label' })
      }}
      sx={{
        '& .MuiFormLabel-root': {
          color: '#2E334E',
          fontWeight: 700,
          fontSize: isMultilineLabel ? '14px' : '18.2px',
          textAlign: 'justify'
        },
        '&.MuiFormControl-root': shouldHighlight ? {
          paddingRight: '8px',
          paddingLeft: '8px',
          paddingBottom: '8px',
          border: '4px dashed red',
          borderBottom: '4px dashed red !important',
          borderTop: '4px dashed red !important'
        } : {},
        ...sx
      }}
      onBlur={e => onBlur(e)}
      onChange={e => onChange(e)}
      { ...props }
      disabled={disabled}
    />
  );
};

export default InternalControlTextbox;

export const InfoProcessingTextbox = otherProps => (
  <InternalControlTextbox
    label={UOEInfoProcessingConstants.LABEL}
    placeholder={UOEInfoProcessingConstants.PLACEHOLDER}
    {...otherProps}
  />
);

InternalControlTextbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, 
  isMultilineLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  nonMultilineLabelFontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  nonMultilineLabelMarginLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  sx: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};