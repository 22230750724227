import CLACheckBoxField from "@components/Forms/CLACheckBoxField/CLACheckBoxField";
import { useProjectRiskCharacteristics } from "./useProjectRiskCharacteristics";
import { useProjectScopeByProjectForm } from "@services/forms/forms";
import { useParams } from 'react-router-dom';
import { Flex } from "@ais/palette";
import { Heading } from "@palette";
import { Box } from "@mui/material";
import { CLADialogConfirm } from "@ais/components";
import { COMMON } from "@constants";
import styles from "./RiskCharacteristics.module.css";
import React, { useState, useEffect } from "react";
import CLAWarningModal from '@components/CLAWarningModal/CLAWarningModal';
import projectScopesKeyControls from '@services/forms/projectScopesKeyControls.js';
import projectScopeRiskCharacteristics from '@services/forms/projectScopeRiskCharacteristics.js';

export const RiskCharacteristics = (props) => {
    const { methodologyVersionId, projectFormId, methodologyIndustries, disabled, signOffList } = props;
    
    const { projectId } = useParams();
    const { data: projectScopes, isFetching: fetchingProjectScopes } = useProjectScopeByProjectForm(projectFormId, projectId);
 
    const [projectFormAuditAreas, {isSelected, toggleRiskCharacteristic, isToggleRiskCharacteristicLoading}] = useProjectRiskCharacteristics(
        methodologyVersionId,
        projectFormId,
        methodologyIndustries,
        signOffList
    );
 
    const {MODAL_HEADER, BUTTON: COMMON_BUTTON} = COMMON.EN;    
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [keyControlsData, setKeyControlsData] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [riskCharacteristicData, setRiskCharacteristicData] = useState();
    const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(false);

    useEffect(() => {
        setIsCheckBoxDisabled(isToggleRiskCharacteristicLoading);
    }, [isToggleRiskCharacteristicLoading]);
     
    const deleteDefaultRisk = async () => {
        setIsDeleteModalOpen(false);
        toggleRiskCharacteristic(null, riskCharacteristicData.rc, !riskCharacteristicData.event.target.checked);
    }

    const toggleIsDeleteModalOpen = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    }

    const setRiskCharacteristic = async (event, rc) => {
        setIsCheckBoxDisabled(true);
        const loadedKeyControls = await projectScopesKeyControls.getprojectScopesKeyControls(projectId, rc.riskCharacteristicId, projectScopes?.ProjectScopeId);
        const responseData = loadedKeyControls?.response?.data || [];

        if (responseData.length > 0) {
            setKeyControlsData( parseKeyControlData(responseData) );
            setShowWarningModal(true);
            setIsCheckBoxDisabled(false);
        }
        else {
            if (rc.projectScopeRiskCharacteristicId != null) {
                const riskCharacteristicCount = await projectScopeRiskCharacteristics.getprojectScopeRiskCharacteristicsDefaultRisks(projectId, rc.projectScopeRiskCharacteristicId);        
                setRiskCharacteristicData({event, rc}); 
                if (riskCharacteristicCount?.response?.data[0].DefaultRiskCount === 1) {        
                    setIsDeleteModalOpen(true);
                    setIsCheckBoxDisabled(false);
                    return;
                }
            }

            toggleRiskCharacteristic(null, rc, !event?.target.checked);
        }
    }

    const parseKeyControlData = (loadedKeyControls) => {
        const result = [];
    
        const findSCOTABDS = (auditArea, classOfTransaction) => {
            return result.find(item => item.AuditAreas === auditArea)
                ?.SCOTABDS.find(scotabds => scotabds.text === classOfTransaction);
        };
    
        loadedKeyControls?.forEach(entry => {
            const auditArea = entry.AuditAreaName;
            const classOfTransaction = entry.ClassOfTransactionName;
            const keyControl = entry.ProjectKeyControlName;
    
            let auditAreaEntry = result.find(item => item.AuditAreas === auditArea);
            if (!auditAreaEntry) {
                auditAreaEntry = { AuditAreas: auditArea, SCOTABDS: [] };
                result.push(auditAreaEntry);
            }
    
            let scotabdsEntry = findSCOTABDS(auditArea, classOfTransaction);
            if (!scotabdsEntry) {
                scotabdsEntry = { text: classOfTransaction, KeyControls: [] };
                auditAreaEntry.SCOTABDS.push(scotabdsEntry);
            }
    
            scotabdsEntry.KeyControls.push(keyControl);
        });
    
        return result;
    };
 
    return (
        <>
        <Flex>
            <Heading as="h1" size="3" weight="medium" className={styles["mb-2"]}>
                Are any of the following Common Identified Risks present in the project?
            </Heading>
 
            {projectFormAuditAreas?.map((auditArea) => (
                <Box
                    key={auditArea.id}
                    sx={auditArea.shouldHighlight ? { borderStyle: "dashed", borderWidth: "4px", borderColor: "red" } : {}}
                >
                    <Flex className={styles["mb-5"]}>
                        <Heading as="h2" size="2" weight="medium" className={styles["mb-2"]}>
                            {auditArea?.name}
                        </Heading>
 
                        {auditArea.riskCharacteristics.map((rc) => (
                            <Box>
                                <CLACheckBoxField
                                    key={rc.id}
                                    className={styles["mb-2"]}
                                    label={rc.name}
                                    value={isSelected(rc)}
                                    checked={isSelected(rc)}
                                    onChange={(event) => { setRiskCharacteristic(event, rc) }}
                                    disabled={disabled || isCheckBoxDisabled}
                                />
                            </Box>
                        ))}
                    </Flex>
                </Box>
            ))}
        </Flex>
 
        {
            keyControlsData &&
            <CLAWarningModal
                title={'Warning - Common identified risk cannot be deselected'}  
                description={'This common identified risk cannot be deselected until the following key control(s) linked to it have been disassociated (i.e., linked to a different risk) or deleted in the Understanding the Entity\'s Information System and Communication and Control Activities form. Note that deleting a key control will delete any completed walkthrough documentation on this form.'}  
                visible={showWarningModal}  
                setVisible={setShowWarningModal}  
                cols={[
                    {
                        id: "AuditAreas",
                        text: "Audit Areas"
                    },
                    {
                        id: "SCOTABDS",
                        text: "SCOTABDs"
                    },
                    {
                        id: "KeyControls",
                        text: "Key Controls"
                    }
                ]}
                rows={keyControlsData}
                />   
        }
 
        {
            isDeleteModalOpen &&
            <CLADialogConfirm
                visible={isDeleteModalOpen}
                title={MODAL_HEADER.WARNING}
                cancelText={COMMON_BUTTON.CANCEL}
                confirmText={COMMON_BUTTON.DELETE}
                message={'Are you sure you want to deselect this? It will delete the common identified risk from the Risk Assessment Summary and Risk Summary. Further, any link to procedure steps on the audit program form to address this risk will be removed.'}
                onConfirm={deleteDefaultRisk}
                onCancel={toggleIsDeleteModalOpen}
            />
        }

        </>       
    );
};