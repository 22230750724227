import { useEffect, useState } from "react"
import { FormControlLabel, FormGroup, Checkbox, TableCell, TableRow } from '@mui/material';

const labelStyle = {
    "& .MuiFormControlLabel-label": {
        fontSize: 12,
        lineHeight: 2,
        fontWeight: 700
    }
}

export const GraphsDrawerTableItem = (props) => {
    const {
        item,
        selections,
        selectOne,
        isSelected,
        enableMinimumSelection
    } = props

    const [ graphSelections, setGraphSelections ] = useState(selections)

    useEffect(() => {
        setGraphSelections(selections)
    }, [ selections ])
    return (
        <TableRow sx={{ verticalAlign: "top" }}>
            <TableCell>
                { (item.Name !== null && item.Name !== 'placeholder' && item.Name !== '') &&
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={enableMinimumSelection && isSelected(item) && graphSelections.length === 1}
                                    checked={isSelected(item)}
                                    onChange={(e) => selectOne(item)}
                                />
                            }
                            // TODO: Might need to update after implementing null handling for Planning Analytics in another US
                            label={item.Name === 'placeholder' ? '' : item.Name}
                            sx={labelStyle}
                        />
                    </FormGroup>
                }
            </TableCell>
            <TableCell>
                <FormGroup>
                    {item.Classifications.map((classification) => (
                        (classification.Name !== null && classification.Name !== '') && 
                        <FormControlLabel
                            key={classification.Id}
                            control={
                                <Checkbox
                                    disabled={enableMinimumSelection && isSelected(classification) && graphSelections.length === 1}
                                    checked={isSelected(classification)}
                                    onChange={(e) => selectOne(classification)}
                                />
                            }
                            label={classification.Name}
                            sx={labelStyle}
                        />
                    ))}
                </FormGroup>
            </TableCell>
            <TableCell>
                <FormGroup>
                    {item.Categories.map((category) => (
                        (category.Name !== null && category.Name !== '') &&
                            <FormControlLabel
                                key={category.Id}
                                control={
                                    <Checkbox
                                        disabled={enableMinimumSelection && isSelected(category) && graphSelections.length === 1}
                                        checked={isSelected(category)}
                                        onChange={(e) => selectOne(category)}
                                    />
                                }
                                label={category.Name}
                                sx={labelStyle}
                            />
                    ))}
                </FormGroup>
            </TableCell>
        </TableRow>
    )
}
