import { CLATooltip } from "@ais/components"
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';

const { COMMUNICATION_OF_DEFICIENCIES } = DEFICIENCY_FORM_INSTANCE.EN;

export const activateTooltip = (child, value) => {
    return <CLATooltip title={value} placement="bottom">
        {child}
    </CLATooltip>
}

export const getColumns = () => {
    return [
        {
            type: 'ACTION',
            field: 'id',            
            maxWidth: '1%',
            minWidth: '1%',
            headerName: ''            
        },
        {
            type: 'TEXT',
            field: 'DeficiencyName',
            combinationfield: 'CombinationName',
            editable: false,
            sortable: false,
            filterable: false,
            maxWidth: '16%',
            minWidth: '16%',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'flex-start',
            headerName: COMMUNICATION_OF_DEFICIENCIES.COLUMN_HEADERS.DEFICIENCY_NAME,                     
        },
        {
            type: 'NOWRAP_TEXT',
            field: 'DeficiencyDescription',
            combinationfield: 'CombinationDescription',
            editable: false,
            sortable: false,
            maxWidth: '20%',    
            minWidth: '20%',     
            filterable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'flex-start',
            headerName: COMMUNICATION_OF_DEFICIENCIES.COLUMN_HEADERS.DEFICIENCY_DESCRIPTION,

        },
        {
            type: 'LINK',
            field: 'WorkpaperReference',
            editable: false,
            sortable: false,            
            filterable: false,
            disableColumnMenu: true,
            maxWidth: '26%',
            minWidth: '26%',
            headerAlign: 'center',
            align: 'flex-start',
            headerName: COMMUNICATION_OF_DEFICIENCIES.COLUMN_HEADERS.WORKPAPER_FORMNAME,            
            attributes: {
            }
        },
        {
            type: 'TEXT',
            field: 'Determination',
            maxWidth: '12%',      
            minWidth: '12%',      
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'flex-start',
            headerName: COMMUNICATION_OF_DEFICIENCIES.COLUMN_HEADERS.DETERMINATION,            
            attributes: {
            }
        },
        {
            type: 'TEXT',
            field: 'FormOfCommunication',
            maxWidth: '12%',      
            minWidth: '12%',      
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'flex-start',
            headerName: COMMUNICATION_OF_DEFICIENCIES.COLUMN_HEADERS.FORM_OF_COMMUNICATION,            
            attributes: {
                defaultValue: 'Written'
            }
        },
        {
            type: 'COMMENT',
            field: 'Comment',
            maxWidth: '4%',
            minWidth: '4%',
            headerName: COMMUNICATION_OF_DEFICIENCIES.COLUMN_HEADERS.COMMENT,
            headerAlign: 'center',
            align: 'flex-start',
            sortable: true,
            filterable: true,
        }
    ]
};