import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const PlanningAnalyticsContext = createContext({});

export const PlanningAnalyticsProvider = ({ children }) => {
    const [concurrencyEventReceived, setConcurrencyEventReceived] = useState(false)
    const [answerList, setAnswerList] = useState([]);
    const [comment, setComment] = useState({})

    const memoized = useMemo(
        () => ({
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            answerList,
            setAnswerList,
            comment,
            setComment
        }),
        [
            concurrencyEventReceived,
            setConcurrencyEventReceived,
            answerList,
            setAnswerList,
            comment,
            setComment
        ]
    )

    return (
        <PlanningAnalyticsContext.Provider value={memoized}>{children}</PlanningAnalyticsContext.Provider>
    )
}

PlanningAnalyticsProvider.propTypes = {
    children: PropTypes.node
}