import { useReducer, useContext, createContext } from "react";
import { useMsal } from "@azure/msal-react";
import usersServices from "@services/users";
import UserReducer, { initialState } from './UserReducer';
import { FUNCTIONAL_RIGHT_ACTIONS } from "@ais/constants";
import {useUserList} from '@hooks/index'; 

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(UserReducer, initialState);
    const { accounts } = useMsal();
    const {getUserById} = useUserList();

    const getUserInfo = (account, functionalRightGroups, functionalRights, employeeId, email, isUserGroupFetch) => {
        dispatch({
            type: FUNCTIONAL_RIGHT_ACTIONS.GET_USER_INFO,
            payload: {
                authUser: account,
                userGroups: functionalRightGroups,
                functionalRights: functionalRights,
                employeeId,
                email,
                isUserGroupFetch
            }
        })
    }
    const addAISPerson = (user) => {
        dispatch({
            type: 'ADD_AISPERSON_DETAILS',
            payload: user
        })
    }

    const getUser = async () => {
        let user = state.userAccount;

        if (!user) {
            return await getUserById(accounts[0].localAccountId);
        }

        return user
    }

    const checkFunctionalArea = async (functionalRightId) => {
        return usersServices.checkFunctionalArea(functionalRightId);
    }

    const hasAccess = async (functionalRightId) => {
        const functionalRight = await checkFunctionalArea(functionalRightId);
        
        return (functionalRight && (functionalRight.CanCreate || functionalRight.CanRead
            || functionalRight.CanUpdate || functionalRight.CanDelete))
    }

    const value = {
        authUser: state.authUser,
        userFunctionalRights: state.functionalRights,
        userGroups: state.userGroups,
        checkFunctionalArea,
        hasAccess,
        getUserInfo,
        addAISPerson,
        getUser,
        employeeId: state.employeeId,
        email: state.email,
        isUserGroupFetch: state.isUserGroupFetch
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

const useUserProviderState = () => {
    const context = useContext(UserContext);

    if (!context) {
        throw `useAuth must be used within UserContext.`;
    }

    return context;
}

export default useUserProviderState;