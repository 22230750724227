import { createContext, useReducer, useContext } from 'react';
import { AccountAssertionLevelRiskReducer } from './AccountAssertionLevelRiskReducer';

const AccountAssertionLevelRiskContext = createContext();

//Creating a flat array for all expandable nodes in Assertion Level risk e.g Section, Audit Areas, Scotabds & Risks
export const AccountAssertionLevelRiskProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AccountAssertionLevelRiskReducer, {  allExpanded: false, children: [] });
    
    const expandAll = () => {
      dispatch({ type: 'EXPAND_ALL' });
    };
  
    const collapseAll = () => {
      dispatch({ type: 'COLLAPSE_ALL' });
    };
    const updateChildExpanded = (id, isExpanded) => {
      dispatch({ type: 'UPDATE_OR_ADD_CHILD', child: { id, expanded: isExpanded } });
    };
    const isChildExpanded = (id) => {
      const child = state.children.find(child => child.id === id);
      return child ? child.expanded : false;
    };
    const value = {
         state, expandAll, collapseAll, updateChildExpanded, isChildExpanded 
    }

    return <AccountAssertionLevelRiskContext.Provider value={value}>{children}</AccountAssertionLevelRiskContext.Provider>;
}

export const useAccountAssertionLevelRiskState = () => {
    const context = useContext(AccountAssertionLevelRiskContext);
    if (!context) {
        throw 'useAccountAssertionLevelRiskState must be used within AccountAssertionLevelRiskContext.';
    }

    return context;
}