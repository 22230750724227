export const TABLE_DRAWER_COLUMNS = [
    { id: 'actions', label: '', paddingLeft: "16px", },
    { id: 'clientName', label: 'Client Name', paddingLeft: "0px", whiteSpace: 'nowrap' },
    { id: 'projectId', label: 'Project ID', paddingLeft: "10px", },
    { id: 'projectName', label: 'Project Name', paddingLeft: "10px", whiteSpace: 'nowrap' },
    { id: 'yearEnd', label: 'Year End', paddingLeft: "10px", },
    { id: 'projectYear', label: 'Project Year', paddingLeft: "10px", },
    { id: 'projectState', label: 'Project State', paddingLeft: "10px", },
    { id: 'projectType', label: 'Project Type', paddingLeft: "10px", },
    { id: 'engagementLeader', label: 'Engagement Leader', paddingLeft: "10px", },
    { id: 'office', label: 'Office', paddingLeft: "10px", },
    { id: 'industry', label: 'Industry', paddingLeft: "10px", }
]

export const DRAWER = {
    EN: {
        BACKGROUND_COLOR: '#E4E0E0',
        ACTIVE_PROJECTS: 'Active Projects',
        KEY_DELIVERABLES: 'Key Deliverables',
        CLICK_HEADING_SORT_FILTER: 'Click Heading to sort and filter',
        AVAILABLE_PROJECTS: 'Available Projects',
        CLIENT_SEARCH: {
            ID: 'drawerClientNameSearch',
            LABEL: 'Client',
            PLACEHOLDER: 'Search for Client',
        },
        MESSAGES: {
            NO_DATA_LOADED: 'No data loaded.'
        },
        TOOLTIP: {
            MESSAGE: `<div style="
                font-size: 11px;
                font-weight: 200;
            ">Client search will return results for all clients that have at least one AIS project that has been created. Once you select a client only <span style="font-weight: 800">AIS projects that you are authorized to add</span> to your AIS dashboard will appear in the table below. If you do not see the specific project that you were expecting below. please contact a member of the project engagement team who has an access to the project and request they add you to the Project Team. Then return here to add the project to your dashboard.</div>`
        }
    }
}
