import { Dialog, DialogContent, IconButton, Button, Grid } from '@mui/material';
import { sanitize } from 'dompurify';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';

import {
  VFSection,
  VFRenderedField,
  VFSignOff,
} from '@components/FormView';
import { colors } from '@ais/theme';
import { CLATooltip } from '@ais/components';
import { DRAG_TYPES, INTERNAL_CONTROLS_FORM_TYPE_ID } from '@ais/constants';
import styles from './ViewFormDialog.module.css';
import { FORM_DESIGNER, FORM_NAME } from '@constants/forms';
import { VFCanvasProvider } from '@providers';

export const ViewFormDialog = (props) => {
  const { visible, setVisible, schema } = props;

  const formIsInternalControl = schema?.formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID;

  return (
    <Dialog
      open={visible}
      fullWidth
      scroll="body"
      PaperProps={{
        elevation: 2,
      }}
      variant="view-form"
      style={{ zIndex: 1101 }}
    >
      <DialogContent>
        <IconButton
          variant="close-drawer-button"
          sx={{
            top: -60,
            right: 0,
          }}
          onClick={() => setVisible(false)}
        >
          <CloseIcon />
        </IconButton>
        <Grid container gap="8px">
          <Grid item>
            <div className={styles['vf-form-title']}>
              {schema?.formSettings?.formName ||
                FORM_DESIGNER.LABELS.FORM_NAME_PLACEHOLDER}
            </div>
          </Grid>
          {schema?.formSettings?.toolTipText && (
            <Grid>
              <CLATooltip
                title={
                  <>
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(schema.formSettings.toolTipText, {
                          ADD_ATTR: ['target'],
                        }),
                      }}
                    />
                  </>
                }
                placement="bottom"
                PopperProps={{style:{zIndex:1102}}}
              >
                <HelpIcon
                  style={{ fontSize: '18px', color: colors.navy[400] }}
                />
              </CLATooltip>
            </Grid>
          )}
        </Grid>
        {(schema?.formSettings?.formDescription && schema?.formSettings?.formName.trim() !== FORM_NAME.PLANNING_ANALYTICS) && (
          <div className={styles['vf-form-description']}>
            {schema?.formSettings?.formDescription}
          </div>
        )}
        <VFCanvasProvider schema={schema}>
          {schema?.properties?.map((prop, index) => (
            <VFSection key={index} section={prop} formTypeId={schema?.formTypeId}>
              <div
                className={
                  formIsInternalControl && (prop?.sectionType || prop?.type) === DRAG_TYPES.SECTION_BLANK
                    ? null
                    : styles['vf-section-inner']
                }
              >
                {prop?.fields?.map((row, rowIndex) => (
                  <div
                    className={styles['row-field']}
                    key={`row-${rowIndex}`}
                  >
                    {row?.map((field, fieldIndex) => {
                      return (
                        <VFRenderedField
                          key={`col-${fieldIndex}`}
                          field={field}
                          formTypeId={schema?.formTypeId}
                          // Name as such to avoid messing other forms' UI styles
                          sectionForViewFormInternalControl={prop}
                          formIsInternalControl={formIsInternalControl}
                        />
                      )
                    }
                    )}
                  </div>
                ))}
              </div>
            </VFSection>
          ))}
        </VFCanvasProvider>
        <VFSignOff />
        <div
          style={{
            position: 'absolute',
            bottom: -80,
            right: 0,
            left: 0,
            textAlign: 'center',
          }}
        >
          <Button onClick={() => setVisible(false)} variant="close-view-form">
            {FORM_DESIGNER.BUTTONS.CLOSE}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
