import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from "react-hook-form";
import { Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';

import ColumnBuilderRow from './ColumnBuilderRow';
import { DIALOG } from '@constants/forms';

import { colors } from '@ais/theme';
import styles from './ColumnBuilder.module.css';

const ColumnBuilder = () => {
  const { control, setValue } = useFormContext();
  const watchColumns = useWatch({ control, name: 'columns' }) ?? [];
  const [columns, setColumns] = useState([]);

  const generateNewColumn = () => ({
    id: uuidv4(),
    columnLabel: '',
    columnFieldType: '',
  });

  const addColumn = () => {
    const newColumn = generateNewColumn();
    setColumns((prevState) => {
      const copy = [...prevState];
      copy.push(newColumn);
      return copy;
    });
  };

  const deleteColumn = (id) => {
    if (columns.length > 1) {
      const filtered = columns.filter((column) => column.id !== id);
      setColumns([...filtered]);
    } else setColumns([generateNewColumn()]);
  };

  const handleRowChange = (id, updatedValue) => {
    const updatedValues = columns.map((item) => {
      return item.id === id ? updatedValue : item;
    });
    setColumns(updatedValues);
  };

  useEffect(() => {
    if (watchColumns.length > 0) {
      setColumns(watchColumns);
    } else {
      setColumns([generateNewColumn()]);
    }
  }, []);

  useEffect(() => {
    setValue('columns', columns, { shouldValidate: true });
  }, [columns]);

  const handleMoveUp = (index) => {
      if(index === 0) return ;

      const newColumns  = [...columns];
      [newColumns[index], newColumns[index-1]] = [newColumns[index -1],newColumns[index]];
      setColumns(newColumns);
  }

  const handleMoveDown = (index) => {
    if(index === columns.length - 1) return;

    const newColumns  = [...columns];
    [newColumns[index], newColumns[index+1]] = [newColumns[index + 1],newColumns[index]];
    setColumns(newColumns);
}

  return (
    <>
      <div className={styles['cb-main-wrapper']}>
        <div className={styles['cb-addcolumn-row']}>
          <Button
            onClick={addColumn}
            disabled={false}
            startIcon={
              <AddIcon sx={{ fontSize: '12px', color: colors.riptide[500] }} />
            }
            sx={{
              padding: '15px',
              fontSize: '12px',
              fontWeight: 'bold',
              color: colors.charcoal,
            }}
          >
            {DIALOG.COLUMN_BUILDER.ADD_COLUMN}
          </Button>
        </div>
        <Grid container rowSpacing={4} p={2}>
          <div className={styles['cb-main']}>
            <div className={styles['cb-main-content']}>
              <div className={styles['cb-main-columns']}>
                {columns.map((column, index) => (
                  <Grid
                    key={column.id}
                    container
                    item
                    xs={12}
                    columnSpacing={4}
                    rowSpacing={2}
                    sx={{ paddingTop: '1.5rem', paddingBottom: '1.5rem', borderBottom: '1px solid rgba(0, 0, 0, 0.4)' }}
                  >
                    <ColumnBuilderRow
                      id={column.id}
                      index={index}
                      column={column}
                      columnsLength={columns.length}
                      handleMoveUp={handleMoveUp}
                      handleMoveDown={handleMoveDown}
                      onRowChange={handleRowChange}
                      onRowDelete={deleteColumn}
                    />
                  </Grid>
                ))}
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default ColumnBuilder;
