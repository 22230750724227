import {useEffect, useState} from 'react';
import {TableCell, Box} from '@mui/material';


import { DEFICIENCY_SUMMARY_COL_TYPES } from '@constants/index'

import { useCommunicationStore } from '../communication';
import { CommentCell, LinkCell, NoWrapText, TextCell, CombinationTextCell } from './CommunicationOfDeficienciesCells';
import { writtenOralFormat } from '../ManagementCommentDeficiency/helper';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';

const CommunicationOfDeficienciesCell = (props) => {
    const { row, columns, projectId, setComentAreaVisible, setIsExpanded, commentAreaVisible, otherDeficiency } = props;
    const [shouldHighlightComment, setShouldHighlightComment] = useState(false);
    const isProjectFinalized = useFinalizedProject(projectId);

    const latestSignoffTime = useCommunicationStore(state => state.latestSignoffTime);

    const handleOnClickComment = () => {
        setIsExpanded(true);
        setComentAreaVisible(!commentAreaVisible);
    } 

    useEffect(() => {
        const dateModified = new Date(row.ValidFrom).getTime();
        const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
        if('ProjectDeficiencyId' in row){
            setShouldHighlightComment(isSignoffEnabled && latestSignoffTime < new Date(row.ValidFrom).getTime())
        } else if ('ProjectDeficiencyCombinationId' in row){
            setShouldHighlightComment(isSignoffEnabled && latestSignoffTime < new Date(row.ValidFrom).getTime())
        } 
    }, [latestSignoffTime, row])

    return columns.map((column, index) => {
        const { type, field, combinationfield, maxWidth, minWidth, width, attributes } = column;
        const defaultValue = attributes?.defaultValue ? attributes.defaultValue : "";
        const IsCombination = row[combinationfield];
        const value = IsCombination ? IsCombination : (row[field] ?? defaultValue);     
           
        return (
            <TableCell
                key={index}
                sx={{
                    p: '0',
                    display: 'flex',
                    maxWidth: maxWidth,
                    minWidth: minWidth,
                    width: width,
                    flexGrow: 1,
                    border: 0,
                    alignItems: 'flex-start'
                }}
            >
                {type === DEFICIENCY_SUMMARY_COL_TYPES.NOWRAP_TEXT && <NoWrapText value={value} />}
                {type === DEFICIENCY_SUMMARY_COL_TYPES.LINK && <LinkCell row={row} value={value} projectId={projectId} />}
                {type === DEFICIENCY_SUMMARY_COL_TYPES.COMMENT && (
                    <Box sx={{padding: 0, ...(shouldHighlightComment && {
                        border: '4px dashed red'
                    })}}>
                        <CommentCell row={row} onClick={handleOnClickComment} commentAreaVisible={commentAreaVisible} />
                    </Box>
                )}
                {(type === DEFICIENCY_SUMMARY_COL_TYPES.TEXT && !IsCombination) && <TextCell value={value} />}
                {(type === DEFICIENCY_SUMMARY_COL_TYPES.TEXT && IsCombination) && <CombinationTextCell value={value} row={row} />}
                {(type === DEFICIENCY_SUMMARY_COL_TYPES.CHANGEABLE_TEXT && otherDeficiency) && <TextCell value={writtenOralFormat(row)} />}                
            </TableCell>)            
    })
}

export default CommunicationOfDeficienciesCell;