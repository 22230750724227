import { useFormContext, useWatch } from "react-hook-form";
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { RULES } from '@ais/constants';
import { colors } from '@ais/theme';
import {CLADropdown} from "@ais/forms";
import { Criterias } from '.';
import { CheckboxField } from '../settings';

export const RulesTab = (props) => {
  const { additionalData, trigger, isSection } = props;
  const { getValues, setValue } = useFormContext();

  const rulesBehavior = useWatch({name: 'rules.behavior'});
  const rulesMatch = useWatch({name: 'rules.match'});

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: '14px' }}>
          Conditionally show or hide this item.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 'bold' }}>
          {getValues('label') || getValues('title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <CLADropdown
              name="rules.behavior"
              options={[
                { label: 'Show', value: RULES.BEHAVIOR.SHOW },
                { label: 'Hide', value: RULES.BEHAVIOR.HIDE },
              ]}
              value={rulesBehavior}
              onChange={(e) => setValue('rules.behavior', e.target.value)}
              displayEmpty={false}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: colors.riptide[500],
              }}
            >
              This item when
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <CLADropdown
              name="rules.match"
              options={[
                { label: 'Any', value: RULES.MATCH.ANY },
                { label: 'All', value: RULES.MATCH.ALL },
              ]}
              value={rulesMatch}
              onChange={(e) => setValue('rules.match', e.target.value)}
              displayEmpty={false}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: colors.riptide[500],
              }}
            >
              of its criteria match:
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Criterias
          additionalData={additionalData}
          trigger={trigger}
        />
      </Grid>
      {!isSection && (
        <Grid item xs={12} mt={8} pt={4} borderTop="1px solid #D2D2D2">
          <Grid container spacing={4}>
            <Grid item>
              <CheckboxField
                name="rules.excludeAnswerOnRollForward"
                label="Exclude answer on roll forward?"
              />
            </Grid>
            <Grid item>
              <CheckboxField
                name="rules.canNotBeDeleted"
                label="Can not be deleted from the Form"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

RulesTab.propTypes = {
  additionalData: PropTypes.object.isRequired,
  trigger: PropTypes.string.isRequired,
  isSection: PropTypes.bool,
};

RulesTab.defaultProps = {
  isSection: false,
};
