import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useParams, useNavigate } from "react-router-dom";

import { PROJECT_STATE } from '@constants/common'
import { useFinalizedProject, useActiveProject } from '@hooks/useProject';

const RequireProjectState = ({projectState, children}) => {

  const { projectId } = useParams();
  const navigate = useNavigate();
  const isProjectFinalized = useFinalizedProject(projectId);
  const isProjectActive = useActiveProject(projectId);

  // Redirect to project dashboard if finalized
  const checkForState = (projectState) => {
    if(projectState !== PROJECT_STATE.ACTIVE && isProjectActive) {
      navigate(`/project-dashboard/${projectId}`);
    } 
    if(projectState !== PROJECT_STATE.FINALIZED && isProjectFinalized) {
      navigate(`/project-dashboard/${projectId}`);
    }
  }

  useEffect(() => {
    if(!Array.isArray(projectState)) checkForState(projectState);;

    for (const state in projectState) {
      checkForState(state);
    }
    
  }, [isProjectFinalized])

  return <>{children}</>;
};

RequireProjectState.propTypes = {
  projectState: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]).isRequired,
};

export default RequireProjectState;
