// React and other libraries
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

// MUI
import { Radio, RadioGroup, FormControlLabel, FormControl, Box } from '@mui/material';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

// CLA Components
import CLATooltip from '@components/CLATooltip/CLATooltip';

// Related files
import { INTERNAL_CONTROLS } from '@constants/customForm';
import { ConcurrentInternalControlTextbox } from '../../../ConcurrentInternalControlTextbox';
import { useUpdateFirstLevelInternalControlData } from '@services/customForm/internalControls';
import useProjectFormInstanceConcurrentLocking from '@hooks/useProjectFormInstanceConcurrentLocking'
import CustomToast from '@components/CustomToast/CustomToast';
import styles from './styles.module.css';
import { useParams } from 'react-router-dom';
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';

import { useOthers } from '@components/Concurrency/store/users';
import { useRoomIdle, useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { WAITING_TIME } from '@ais/constants';
import { useProjectFormInstanceProvider } from '@providers';
const {
  SUBSECTION_TITLE,
  FIRST_PARAGRAPH,
  SECOND_PARAGRAPH,
  TOOLTIP,
  IS_ACCORDING_TO_FRAMEWORK: { YES, NO },
  TEXTBOX,
  CUSTOM_FORM_OBJECT_ID
} = INTERNAL_CONTROLS.EN.EVALUATION_AND_CONCLUSION;

const tooltipSx = {
  p: '15px',
  pb: '22.5px',
  maxWidth: '350px',
  width: '350px',
  fontSize: '12px',
  lineHeight: 'normal'
};

const formControlLabelSx = {
  '& .MuiTypography-root': {
    color: '#333',
    fontSize: '18px',
    fontFamily: 'museo-sans',
    fontWeight: 600,
    lineHeight: '33.5px'
  }
};

const EvaluationAndConclusion = ({ internalControlData, isProjectFinalized, changeFirstLevelFieldWithObject, projectFormId, signOffList }) => {

  const [radioValue, setRadioValue] = useState(null);
  const [shouldShowTextbox, setShouldShowTextbox] = useState(false);
  const [textboxValue, setTextboxValue] = useState('');
  const [loadingFrameworkData, setLoadingFrameworkData] = useState(false)
  const [errorFrameworkData, setErrorFrameworkData] = useState(false)
  const [successFrameworkData, setSuccessFrameworkData] = useState(false)
  const { projectId } = useParams();
  const [fieldHighlight, setFieldHighlight] = useState(false)
  
  const { userId } = useProjectFormInstanceProvider();  
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();
  const [previousValue, setPreviousValue] = useState(null)
  const activeElementRef = useRef(null)
  const isIdle = useRoomIdle()

  const { mutate: updateFirstLevelInternalControlData } = useUpdateFirstLevelInternalControlData();

  const {
    formattedInternalControls
  } = useInternalControlsContext();

  const getRadioValue = () => {
    if (internalControlData?.IsAccordingToFramework == null) return null;
    else return internalControlData.IsAccordingToFramework ? YES : NO;
  };

  const getTextboxValue = () => {
    if (internalControlData?.AccordingToFrameworkComment == null) return '';
    else return internalControlData.AccordingToFrameworkComment;
  };


  const saveFrameworkData = (comment, radioValue) => {
    setLoadingFrameworkData(true)

    const changeFirstLevelPayload = {
      AccordingToFrameworkComment: comment,
      IsAccordingToFramework: radioValue,
    }

    changeFirstLevelFieldWithObject(changeFirstLevelPayload)

    const __radioValue = getRadioValue();
    const __textboxValue = getTextboxValue();

    const currentStates = {
      radioValue: __radioValue,
      shouldShowTextbox: !internalControlData?.IsAccordingToFramework,
      textboxValue: __textboxValue
    };

    const updatePayload = {
      ...changeFirstLevelPayload,
      ProjectFormId: projectFormId,
      ProjectInternalControlId: internalControlData?.ProjectInternalControlId,
    }

    updateFirstLevelInternalControlData(
      { projectFormId, payload: updatePayload, projectId },
      {
        onError: () => {
          setRadioValue(currentStates.radioValue);
          setShouldShowTextbox(currentStates.shouldShowTextbox);
          setTextboxValue(currentStates.textboxValue);
          setErrorFrameworkData(true)
          setSuccessFrameworkData(false)
          setLoadingFrameworkData(false)
        },
        onSuccess: () => {
          setErrorFrameworkData(false)
          setSuccessFrameworkData(true)
          setLoadingFrameworkData(false)
        }
      }
    );
  };

  const changeRadioValue = (value) => {
    if (value === YES) {
      setRadioValue(YES);
      setShouldShowTextbox(false);
      setTextboxValue('');

      saveFrameworkData(null, true);
    } else {
      setRadioValue(NO);
      setShouldShowTextbox(true);
      saveFrameworkData(internalControlData?.AccordingToFrameworkComment, false);
    }
  }

  const handleRadioChange = (event) => {
    changeRadioValue(event.target.value);
    updateMyPresence({ focusedId: null, type: 'custom' });
  };

  useEffect(() => {
    if (isIdle) {
      if (previousValue !== null) {
        setTextboxValue(previousValue)
        activeElementRef.current?.blur()
      }
    }
  }, [isIdle])

  const handleTextboxBlur = (event) => {
    activeElementRef.current = null
    if (isIdle) {
      setPreviousValue(null)
      updateMyPresence({ focusedId: null, type: 'custom' })
      return
    }
    if (event.relatedTarget?.id === 'eac-yes-radio-button' || event.relatedTarget?.id === 'eac-yes-radio-button-label')
      return;

    const newValue = event.target.value;
    if (textboxValue == null && !newValue) {
      updateMyPresence({ focusedId: null, type: 'custom' })
      setPreviousValue(null)
      return
    }
    if (previousValue === newValue) {
      updateMyPresence({ focusedId: null, type: 'custom' })
      setPreviousValue(null)
      return;
    }

    updateMyPresence({ focusedId: null, type: 'custom' })
    saveFrameworkData(newValue, false);
    setPreviousValue(null)
  };

  useEffect(() => {
    const __radioValue = getRadioValue();
    setRadioValue(__radioValue);
    if (__radioValue === null) {
      setShouldShowTextbox(false);
    } else {
      setShouldShowTextbox(!internalControlData?.IsAccordingToFramework);
    }
  }, [internalControlData?.IsAccordingToFramework]);

  useEffect(() => {
    const __textboxValue = getTextboxValue();
    setTextboxValue(__textboxValue);
  }, [internalControlData?.AccordingToFrameworkComment])

  useEffect(() => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    const dateModified = new Date(formattedInternalControls.ValidFrom).getTime();
    const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
    const shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
    setFieldHighlight(isSignoffEnabled && shouldHighlight)
  }, [formattedInternalControls, signOffList])

  const isLockedByUser = others.find((user) => user.presence.focusedId === 'evaluation-and-conclusion-textbox' && user.info.userId.toLowerCase() !== userId.toLowerCase())

  return (
    <React.Fragment>
      <div className={styles.EACSubsectionTitle}>{SUBSECTION_TITLE}</div>
      <p className={clsx(styles.EACParagraph, styles.EACFirstParagraph)}>{FIRST_PARAGRAPH}</p>
      <p className={styles.EACSecondParagraph}>
        <span className={styles.EACParagraph}>{SECOND_PARAGRAPH}</span>
        <CLATooltip
          title={TOOLTIP}
          placement="top"
          componentsProps={{
            tooltip: {
              sx: tooltipSx
            }
          }}
        >
          <HelpOutlinedIcon sx={{ mb: '3px', paddingLeft: '4px', width: '23px', color: '#2e334e', fontSize: 15 }} />
        </CLATooltip>
      </p>
      <Box sx={shouldShowTextbox && fieldHighlight ? { ml: '5px', mb: '25px', borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', pb: '10px', pl: '10px', pr: '10px' } : { ml: '5px', mb: '25px' }}>
        <FormControl sx={!shouldShowTextbox && fieldHighlight ? { ml: '5px', mb: '25px', borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', pl: '10px' } : { ml: '5px', mb: '25px', pl: '10px' }} disabled={isProjectFinalized}>
          <RadioGroup
            row
            aria-labelledby="is-according-to-framework-group-label"
            name="is-according-to-framework-group"
            value={radioValue}
            onChange={handleRadioChange}
          >
            <VFRenderedFieldWrapper
              className={`${styles["custom-content-text-field"]} ${styles["field__wrapper"]}`}
              isLockedByUser={isLockedByUser}
            >
              <FormControlLabel
                id="eac-yes-radio-button-label"
                tabIndex="-1"
                value={YES || radioValue}
                control={<Radio id="eac-yes-radio-button" />}
                label={YES}
                sx={{ ...formControlLabelSx, mr: '40px' }}
                disabled={!!isLockedByUser || isProjectFinalized}
              />

              <FormControlLabel
                value={NO || radioValue}
                control={<Radio />}
                label={NO}
                sx={formControlLabelSx}
                disabled={!!isLockedByUser || isProjectFinalized}
              />
            </VFRenderedFieldWrapper>
          </RadioGroup>
        </FormControl>
        <Box sx={{ mb: shouldShowTextbox ? '10px' : '172.25px' }}>
          {shouldShowTextbox && (
            <VFRenderedFieldWrapper
              className={`${styles["custom-content-text-field"]} ${styles["field__wrapper"]}`}
              isLockedByUser={isLockedByUser}
            >
              <ConcurrentInternalControlTextbox
                value={textboxValue || ""}
                defaultValue={textboxValue || ""}
                onKeyUp={event => setTextboxValue(event.target.value)}
                label={TEXTBOX.LABEL}
                isMultilineLabel={true}
                placeholder={TEXTBOX.PLACEHOLDER}
                onBlur={(e) => handleTextboxBlur(e)}
                onFocus={(event) => {
                  updateMyPresence({ focusedId: "evaluation-and-conclusion-textbox", type: 'custom' })
                  setPreviousValue(textboxValue)
                  activeElementRef.current = event.target
                }}
                customFormObjectId={"evaluation-and-conclusion-textbox"}
                disabled={isProjectFinalized || !!isLockedByUser}
              />
            </VFRenderedFieldWrapper>
          )}
        </Box>
      </Box>
      <CustomToast
        error={errorFrameworkData}
        success={successFrameworkData}
        loading={loadingFrameworkData}
      />
    </React.Fragment>
  );
};

export default EvaluationAndConclusion;