export default {
  "&.MuiDataGrid-root": {
    border: "none !important"
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
    display: "flex !important",
    fontWeight: "700",
    fontSize: "1.1em",
    fontFamily: "museo-sans, sans-serif",
    lineHeight: "19.2px",
    backgroundColor: "#F6F6F6",
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-virtualScroller": {
    marginTop: "90px !important"
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell": {
    outline: "none",
    border: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
    display: "flex !important",
    marginTop: "10px !important"
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell": {
    outline: "none !important",
    border: "none !important",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
    marginTop: "0em",
    marginLeft: "15px"
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell:nth-of-type(1)": {
    marginLeft: "15px !important",
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell": {
    outline: "none",
    borderBottom: "none",
    borderTop: "1px solid rgba(224, 224, 224, 1)"
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell:first-of-type": {
    borderTop: "none"
  },
  "&.MuiDataGrid-root .MuiDataGrid-row:first-of-type .MuiDataGrid-cell": {
    borderTop: "none"
  }
}