import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
    Box,
    Collapse,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';
import {CollapseIconComponent, ExpandIconComponent} from "@ais/assets";
import { CLATooltip } from "@ais/components"
import { CLA_METHODOLOGY } from '@constants/index';

import styles from './MethodologyVersionsContrast.module.css';
import {
    boxRowStyles,
    boxHeaderColStyles,
    boxColumnStyles,
    cellStyles,
    header1Style,
    wrapTextStyles,
    boxHeaderRowQuestionStyles,
    cellBorderStyles
} from './muiStyles';


const MethodologyVersionsContrastCollapsible = (props) => {
    const {
        form,
        isExpandAll,
        index
    } = props;
    const { REPORTS } = CLA_METHODOLOGY.EN;

    const [isExpanded, setIsExpanded] = useState(false);

    const displayCurrentIndustries = () => {
        if (!form?.currentIndustries) return '';
        return form.currentIndustries.map(i => i.Name).sort().join(', ')
    };

    useEffect(() => {
        setIsExpanded(isExpandAll);
    }, [isExpandAll]);
    return (
        <Box data-test="methodologyVersionsContrast-component">
            <Box sx={{ ...boxRowStyles }}>
                <div className={styles['collapse-icon-wrapper']}>
                    <IconButton
                        sx={{ p: 0 }}
                        onClick={() => { setIsExpanded(prevIsExpanded => !prevIsExpanded) }}>
                        {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                    </IconButton>
                </div>
                <div className={`${styles.column} ${styles['first-form-column']} ${index > 0 && styles['row-border']}`}>
                    <Box sx={{ ...header1Style, mr: '16px' }}>
                        <CLATooltip title={form?.formName} placement="bottom">
                            <span className={styles['wrap-text']}>{form?.formName} </span>
                        </CLATooltip>
                    </Box>
                    <Box sx={header1Style}>
                        <CLATooltip title={displayCurrentIndustries()} placement="bottom">
                            <span className={styles['wrap-text']}> {displayCurrentIndustries()}</span>
                        </CLATooltip>
                    </Box>
                </div>
            </Box>
            <Collapse in={isExpanded} collapsedSize={0} data-test="collapse-component" sx={{ ml: '40px' }}>
                <Box sx={{ ...boxColumnStyles, mb: '20px', fontSize: '14px' }}>
                    <TableContainer >
                        <Table data-test="tableContainer">
                            <TableHead data-test="tableHeader" sx={boxHeaderRowQuestionStyles}>
                                <TableRow>
                                    <TableCell sx={boxHeaderColStyles} data-test="current-question-header">{REPORTS.CURRENT_QUESTION}</TableCell>
                                    <TableCell sx={boxHeaderColStyles} data-test="previous-question-header">{REPORTS.PREVIOUS_QUESTION}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody data-test="tableBody">
                                {form?.questions?.map((question, index) =>
                                    <TableRow sx={{ ...(index > 0 ? cellBorderStyles : '') }} key={index} data-test="tableRowBody">
                                        <TableCell sx={{ ...cellStyles, ...wrapTextStyles }}>
                                            <CLATooltip title={question.current ? question.current : REPORTS.NA} placement="bottom">
                                                <div className={styles['wrap-text']}>{question.current ? question.current : REPORTS.NA}</div>
                                            </CLATooltip>
                                        </TableCell>
                                        <TableCell sx={{ ...cellStyles, ...wrapTextStyles }}>
                                            <CLATooltip title={question.previous ? question.previous : REPORTS.NA} placement="bottom">
                                                <div className={styles['wrap-text']}>{question.previous ? question.previous : REPORTS.NA}</div>
                                            </CLATooltip>
                                        </TableCell>
                                    </TableRow>)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Collapse >
        </Box >
    );
};

MethodologyVersionsContrastCollapsible.propTypes = {
    form: PropTypes.object,
    isExpandAll: PropTypes.bool,
    index: PropTypes.number
}

export default MethodologyVersionsContrastCollapsible;