export const initialState = {
    isEditMode: false,
    rowData: {}
}

const NestedDatagridReducer = (state, action) => {
    const { type, payload } = action;
    let newState = { ...state };

    switch (type) {
        case 'ON_EDIT_MODE':
            return {
                ...newState,
                isEditMode: payload.isEditMode,
                rowData: payload.rowData,
            }
    }
}

export default NestedDatagridReducer;