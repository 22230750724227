import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Checkbox,
} from '@mui/material';

import { colors } from '@ais/theme';


const SCOTABDsDefaultTable = (props) => {
  const {
    defaultScotabds,
    shouldHighlight
  } = props;

  const tableStyles = { 
    borderBottom: '1px', 
    borderColor: '#C6C6C6', 
    borderRaduis: 0,
  };

  const tableFontStyle = {
    color: colors.gray[400], 
    fontWeight: 600
  }

  return (
    <TableContainer component={Paper} sx={shouldHighlight ? {boxShadow: 'none', marginTop: '1rem', borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red' }:  {boxShadow: 'none', marginTop: '1rem'}}>
      <Table sx={{border: 0.1, borderColor: tableStyles.borderColor}}>
        <TableHead sx={{backgroundColor: colors.gray[30], ...tableStyles}}>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Classes of Transactions</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Account Balances</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>Assertions</TableCell>
            <TableCell sx={{fontWeight: 'bold'}}>
              Accounting Estimates and Related Disclosures
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {defaultScotabds.map((item, i) => {
            const value = item.SCOTABDId;
            return (
              <TableRow key={i} sx={tableStyles}>
                <TableCell sx={tableFontStyle}>{item.ClassOfTransactionName}</TableCell>
                <TableCell sx={tableFontStyle}>
                  {item.AccountBalanceTypeList.map((ab) => ab.AccountBalanceName).join(', ')}
                </TableCell>
                <TableCell sx={tableFontStyle}>{item.AssertionList.map((as) => as.AssertionName).join(', ')}</TableCell>
                <TableCell sx={tableFontStyle}>
                  <Checkbox
                    checked={item.IsAccountingEstimateAndDisclosure}
                    value={value}
                    disabled={true}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SCOTABDsDefaultTable