import { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

const initialData = {
    openComment: null
}

const OPEN_COMMENT = 'open-comment';

export const UnderstandingOfEntityContext = createContext({});

export const UnderstandingOfEntityReducer = (state, action) => {

    const newState = { ...state }

    switch (action.type) {
        case OPEN_COMMENT: {
            newState.openComment = newState.openComment === action.payload 
                ? null
                : action.payload;
            return newState
        }
    }

    return newState;
}

export const UnderstandingOfEntityProvider = ({ children }) => {
    const [state, dispatch] = useReducer(UnderstandingOfEntityReducer, initialData);

    const setOpenComment = (commentKey) => {
        dispatch({ type: OPEN_COMMENT, payload: commentKey })
    }

    const memoized = useMemo(
        () => ({ state, setOpenComment }),
        [state, setOpenComment]
    )

    return (
        <UnderstandingOfEntityContext.Provider value={memoized}>{children}</UnderstandingOfEntityContext.Provider>
    )
}

UnderstandingOfEntityProvider.propTypes = {
    children: PropTypes.node
}