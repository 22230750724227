import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CLATooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  '& p': {
    margin: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#373D3F',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#373D3F',
  },
});

export default CLATooltip;
