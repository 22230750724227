import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import update from 'immutability-helper';
import { v4 as uuid } from 'uuid';
import { useDrop } from 'react-dnd';
import {DRAG_TYPES} from '@ais/constants';
import {FORMULA_CONDITIONS} from '@constants/forms';
import { FormulaBar } from './FormulaBar';

export const FormulaConditionalRow = ({
    formula: initialFormula, 
    index: indexRow,     
    active,
    onChangeFormulaRow,    
    onAddFormulaConditions,
    removeCondition,
    resetCanvas
}) => {       
    const [formula, setFormula] = useState(initialFormula);
    const [currentHoverIndex, setCurrentHoverIndex] = useState(-1);
    const { IF } = FORMULA_CONDITIONS;

    useEffect(() => {        
        setFormula(initialFormula)
    }, [initialFormula])

    const [{ firstItemDragPreview }, ref] = useDrop({
        accept: [
            DRAG_TYPES.FORMULA_OPERATOR,
            DRAG_TYPES.FORMULA_VARIABLE,
            DRAG_TYPES.FORMULA_COMPONENT,
            DRAG_TYPES.FORMULA_PLACEHOLDER,
            DRAG_TYPES.FORMULA_QUESTION
        ],
        collect: (monitor) => {
            const itemType = monitor.getItemType();
            const item = monitor.getItem();

            if (!monitor.isOver() || !monitor.canDrop()) {
                setCurrentHoverIndex(-1);
            } else if (!!item && item.isPlaceholder && currentHoverIndex < 0) {
                setCurrentHoverIndex(formula.length);
            }

            return {
                firstItemDragPreview: !!itemType && formula.length === 0,
            };
        },
        drop: item => {
            const { isPlaceholder, shouldDelete, ...component } = item;
            const insertedComponent = { ...component, id: uuid() };

            if (item.itemType === 'FORMULA_QUESTION') {
                insertedComponent.questionId = item.questionId;
            };

            if(component?.operator !== IF && active) {
                if (formula.length === 0) {                                       
                    setFormula(prevItems => update(prevItems, {
                        $push: [insertedComponent],
                    }));
                } else {
                    const deleteIndex = formula.findIndex(f => f.id === item.id);                    
                    if(currentHoverIndex > 0) {
                        if (isNaN(deleteIndex) || deleteIndex === -1 ) {                        
                            setFormula(prevItems => update(prevItems, {
                                $splice: [
                                    [currentHoverIndex, 0, insertedComponent],
                                ],
                            }));
                        } else {                                                    
                            setFormula(prevItems => update(prevItems, {
                                $splice: [
                                    [deleteIndex, 1],
                                    [currentHoverIndex > deleteIndex ? currentHoverIndex - 1 : currentHoverIndex, 0, insertedComponent],
                                ],
                            }));                                      
                        }
                    }
                }
            }
            setCurrentHoverIndex(-1);
        },
    });

    const onFormulaComponentHover = (index, side) => {
        setCurrentHoverIndex(side === 'left' ? index : index + 1);
    };

    const removeFormulaComponent = (index) => {        
        if(index === 0) {
            removeCondition(index)
        } else if(index > 0)             
            setFormula(prevItems => update(prevItems, {
                $splice: [
                    [index, 1],
                ],
            }));
    };

    useEffect(() => {
        onChangeFormulaRow(formula, indexRow)
    }, [formula])
    
    return (
        <Box ref={ref} sx={{
            backgroundColor: firstItemDragPreview ? 'rgba(118, 210, 211, 0.5)' : 'white',
            display: 'flex', 
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 1
            }}>
            <FormulaBar
                formula={formula}                
                currentHoverIndex={currentHoverIndex}
                active={active}                
                onHover={(index, side) => onFormulaComponentHover(index, side)}
                onDropOutside={(index) => removeFormulaComponent(index)}
                isConditional={true}
                onAddFormulaConditions={onAddFormulaConditions}
                resetCanvas={resetCanvas}
            />
        </Box>
    )
}