import { useState, useEffect, useCallback } from 'react';

import { Grid, Typography, Menu, Box, Button, TextField, Fade, InputAdornment } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import AutoNumeric from 'autonumeric';
import update from 'immutability-helper';
import { useDrag } from 'react-dnd';

import { colors } from '@ais/theme';
import {DRAG_TYPES, FIELD_TYPES} from '@ais/constants';
import { DIALOG } from '@constants/forms/index';
import { debounce } from 'lodash';
import { SelectQuestion } from '@components/Forms/CLAFormDesigner/dialogs/dialog-components/settings/SelectQuestion.jsx';
import questionsService from '@services/forms/questions.js';
import { useLoading } from '@hooks/index';

const NumericFormat = Intl.NumberFormat('en-US');

export const FormulaVariableDropdown = (props) => {
    const LABEL = DIALOG.SETTINGS.LABEL;
    const { schema } = props;
    const [instance, setInstance] = useState(null);
    const [variableValues, setVariableValues] = useState([]);
    const [isDragging, setDragging] = useState(false);
    const [variableInputEntered, setVariableInputEntered] = useState(false);

    const [visible, setVisible] = useState(false);

    //isSearching is used to prevent the query from executing multiple times within the debounce time (one for each keystroke after char #2 but before 500ms)
    const [isSearching, setSearching] = useState(false);

    const [dropdownWidth, setDropdownWidth] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const isNumeric = (value) => {
        return /^-?\d*\.?\d+$/.test(value);
      }

    const inputRef = useCallback((node) => {
        if (open && !!node) {
            const newInstance = new AutoNumeric(node, {
                allowDecimalPadding: false,
                decimalPlaces: 2,
                selectOnFocus: false,
                showWarnings: false,
                modifyValueOnWheel: false,
                watchExternalChanges: true,
            });
            setInstance(newInstance);
        } else {
            setInstance(null);
        }
    }, [open]);

    const setBackDropLoading = useLoading();

    const handleLoader = (value) => {
        setBackDropLoading(value);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        setDropdownWidth(event.currentTarget.clientWidth);
    };

    const handleClose = () => setAnchorEl(null);

    const handleClickShowPanel = () => {
        //Toggle for select question panel
        setVisible(!visible);
    }

    const handleClickSelectQuestion = (question) => {
        if (question) {
            setVariableValues((prevValues) => update(prevValues, {
                $push: [{
                    value: question.label,
                    id: question.value
                }]
            }))
        }
    }

    const handleChangeSearch = async (event) => {
        if (event.target.value.length > 2 && !isSearching) {
            await setSearching(true);
            const debouncedSearch =
                debounce(async () => {
                    let data = await questionsService.getQuestionsByLabel(event.target.value, schema.formSettings.methodologyVersionId);
                    if (data) {
                        // Enforce results limit (20), filter first for accurate count
                        data = data.filter(value => value.validation !== 'none' && value.type == FIELD_TYPES.SHORT_ANSWER);
                        if (data.length > 20) {
                            data = data.slice(0, 21);
                        }
                        data.forEach(e => {
                            //Avoid popping in duplicates
                            if (variableValues.filter(value => value.id === e.id).length == 0) {
                                setVariableValues((prevValues) => update(prevValues, {
                                    $push: [{
                                        value: e.label,
                                        id: e.id
                                    }]
                                }))
                            }
                        }
                        )
                    };
                    await setSearching(false);
                }, 500);

            debouncedSearch();
        } else if (event.target.value.length == 0) {
            await setVariableValues([]);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            if (!!anchorEl) {
                setDropdownWidth(anchorEl.clientWidth);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleNumberSubmit = (e) => {

        if (!isNumeric(instance?.getNumericString())) {
            return;
        }
        
        e.preventDefault();
        e.stopPropagation();
        const value = instance.getNumericString();

        setVariableValues((prevValues) => update(prevValues, {
            $push: [{
                value,
                id: null
            }]
        }));
        instance.clear();
        setVariableInputEntered(false);
    };

    return (
        <Grid container sx={{ paddingRight: '50px' }} rowSpacing={2}>
            <Grid item xs={12}>
                <Typography variant="title" sx={{ fontSize: '18px' }}>
                    {LABEL.VARIABLE}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Box
                    endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    onClick={handleClick}
                    sx={{
                        borderRadius: '4px',
                        border: 1,
                        borderColor: 'gray.main',
                        borderBottomLeftRadius: (open && !isDragging) ? 0 : null,
                        borderBottomRightRadius: (open && !isDragging) ? 0 : null,
                        borderBottom: (open && !isDragging) ? 'none' : null,
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1.5,
                        backgroundColor: 'white',
                        fontSize: '16px',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    Select a variable or enter a number
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Box>

                <Menu
                    anchorEl={anchorEl}
                    open={open && !isDragging}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            px: 1.5,
                            border: 1,
                            borderColor: 'gray.main',
                            borderTop: 0,
                            borderRadius: 0,
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            width: dropdownWidth + 2,
                        },
                    }}
                >

                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Search"
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                        }}
                        onChange={handleChangeSearch}
                        onKeyDown={(e) => {
                            e.stopPropagation();
                        }}
                    />

                    <Box
                        sx={{
                            minHeight: '60px',
                            maxHeight: '60px',
                            overflowY: 'scroll',
                            marginTop: '20px'
                        }}
                    >
                        {variableValues.map((value, index) => (
                            <FormulaVariable
                                dragStart={() => setDragging(true)}
                                dragEnd={() => setDragging(false)}
                                key={index}
                                value={value.value}
                                questionId={value.id}
                                itemType={value.id ? DRAG_TYPES.FORMULA_QUESTION : DRAG_TYPES.FORMULA_VARIABLE}
                            />
                        ))}
                    </Box>

                    <form
                        onSubmit={handleNumberSubmit}
                        onChange={(e) => {
                            if (e?.target?.value?.length > 0) {
                                setVariableInputEntered(true);
                            } else {
                                setVariableInputEntered(false);
                            }
                        }}>
                        <Grid container columnSpacing={3} sx={{ pt: '18px' }}>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    inputRef={inputRef}
                                    label="Enter a number"
                                    variant="outlined"
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={!variableInputEntered}
                                    sx={{ height: '100%' }}
                                    onClick={handleNumberSubmit}
                                >
                                    OK
                                </Button>
                            </Grid>
                        </Grid>
                    </form>

                </Menu>
            </Grid>
            <Grid item xs={12}>
                <Button variant="text"
                    sx={{
                        fontSize: '16px',
                        color: colors.charcoal,
                    }}
                    endIcon={visible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    onClick={handleClickShowPanel}
                >
                    OR Select a Question
                </Button>
                <Box>
                    {visible && (<SelectQuestion
                        schema={schema}
                        visible={visible}
                        setVisible={setVisible}
                        handleClickSelectQuestion={handleClickSelectQuestion}
                        setLoading={handleLoader}
                    />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

const FormulaVariable = ({ value, questionId, dragStart, dragEnd, itemType }) => {
    const [_, ref] = useDrag({
        type: DRAG_TYPES.FORMULA_VARIABLE,
        item: {
            value,
            questionId,
            itemType,
            isPlaceholder: true,
        },
        collect: monitor => {
            if (monitor.isDragging()) {
                dragStart();
            }
        },
        end: dragEnd,
    });

    return (
        <Box
            ref={ref}
            title={value}
            sx={{
                borderRadius: .5,
                color: 'navy.main',
                fontSize: '16px',
                fontWeight: 'bold',
                cursor: 'move',
                padding: '6px 4px',
                mr: '12px',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                },
            }}
        >
            {itemType === DRAG_TYPES.FORMULA_VARIABLE ? NumericFormat.format(value) : value}
        </Box>
    );
};