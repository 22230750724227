import * as Constants from '@constants/index'

export const DRAWER_TITLE = "Select a Project to Add to your AIS Dashboard";

export const TABLE_DASHBOARD_COLUMNS = [
    { id: 'actions', label: '', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "40px" },
    { id: 'clientName', label: 'Client Name', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "50px", width: "80px" },
    { id: 'projectId', label: 'Project ID', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'projectName', label: 'Project Name', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'yearEnd', label: 'Year End', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'projectYear', label: 'Project Year', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'projectState', label: 'Project State', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'projectType', label: 'Project Type', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'engagementLeader', label: 'Engagement Leader', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "80px", width: "80px" },
    { id: 'office', label: 'Office', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'industry', label: 'Industry', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'progress', label: 'Progress', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px" },
    { id: 'updated', label: 'Updated', paddingLeft: "0px", paddingRight: "16px", paddingTop: "0px", paddingBottom: "0px", minWidth: "40px", width: "80px", fontStyle: "italic" }
]

export const CARD_VIEW_OPTIONS = [
    { id: '1', name: 'As List' },
    { id: '2', name: 'As Cards' }
];

export const CARD_SORT_OPTIONS = [
    { id: 'ClientName', name: 'Client Name' },
    { id: 'CLCProjectNumber', name: 'Project Number' }
];

export const CARD_FILTER_OPTIONS = [
    { id: 'select', name: 'Select Filter' },
    { id: 'ClientName', name: 'Client Name' },
    { id: 'CLCProjectNumber', name: 'Project Number' },
    { id: 'ProjectName', name: 'Project Name' }
];


export const PROJECT_CONTEXT_MENU_LIST_ITEM = [
    Constants.AIS_DASHBOARD.EN.PROJECT_CARD_CONTEXT_MENU.OPEN,
    Constants.AIS_DASHBOARD.EN.PROJECT_CARD_CONTEXT_MENU.EDIT_PROJECT_SETUP,
    Constants.AIS_DASHBOARD.EN.PROJECT_CARD_CONTEXT_MENU.REMOVE_FROM_DASHBOARD
];

export const PROJECT_FORM_CONTEXT_MENU_LIST_ITEM = [
    Constants.AIS_DASHBOARD.EN.PROJECT_FORM_CONTEXT_MENU.VIEW_AND_EDIT,
    Constants.AIS_DASHBOARD.EN.PROJECT_FORM_CONTEXT_MENU.RENAME,
    Constants.AIS_DASHBOARD.EN.PROJECT_FORM_CONTEXT_MENU.DUPLICATE,
    Constants.AIS_DASHBOARD.EN.PROJECT_FORM_CONTEXT_MENU.DELETE,
    Constants.AIS_DASHBOARD.EN.PROJECT_FORM_CONTEXT_MENU.MANAGE_UNITS,
    Constants.AIS_DASHBOARD.EN.PROJECT_FORM_CONTEXT_MENU.IMPORT_FROM_ANOTHER_PROJECT
];

export const PROJECT_CONTEXT_MENU_CARD_ITEM = [
    Constants.AIS_DASHBOARD.EN.PROJECT_CARD_FINALIZED_CONTEXT_MENU.OPEN,
    Constants.AIS_DASHBOARD.EN.PROJECT_CARD_FINALIZED_CONTEXT_MENU.EDIT_PROJECT_SETUP,
    Constants.AIS_DASHBOARD.EN.PROJECT_CARD_FINALIZED_CONTEXT_MENU.REMOVE_FROM_DASHBOARD,
    Constants.AIS_DASHBOARD.EN.PROJECT_CARD_FINALIZED_CONTEXT_MENU.UN_FINALIZE_PROJECT
];

export const PROJECT_FORM_CONTEXT_MENU_LIST_ITEM_LIMITED = [
    Constants.AIS_DASHBOARD.EN.PROJECT_FORM_CONTEXT_MENU.VIEW_AND_EDIT
];

export const SIGN_OFF_MOCK_DATA = {
    data: [{
        signOffs: ["aab92626-5b45-410b-b51d-da2aacc9c17f", "jhoan.sapiera@claconnect.com", "ashley.rinderle@claconnect.com", "nicolas.hernandez@claconnect.com"],
        personalDetails: ["Liang A.", "Jhoan S.", "Ashley R.", "Nicolas H."],
        dateSignOffs: ["02/22/2022", "02/23/2022", "02/25/2022", "02/28/2022"]
    }]
}

export const TABLE_MOCK_DATA = {
    data: [{
        id: 1,
        clientName: "Company name with rooms for 2 lines...",
        projectId: "pid001",
        projectName: "Project name with room for 2 lines....",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 2,
        clientName: "test for 50chars should append double dot when over 50 characters",
        projectId: "pid002",
        projectName: "project1",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 3,
        clientName: "client2",
        projectId: "2222",
        projectName: "project2",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 4,
        clientName: "client3",
        projectId: "3333",
        projectName: "project3",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 5,
        clientName: "client4",
        projectId: "4444",
        projectName: "project4",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 6,
        clientName: "client5",
        projectId: "5555",
        projectName: "project5",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    }, {
        id: 7,
        clientName: "client6",
        projectId: "6666",
        projectName: "project6",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 8,
        clientName: "client7",
        projectId: "7777",
        projectName: "project7",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 9,
        clientName: "client8",
        projectId: "8888",
        projectName: "project8",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 10,
        clientName: "client9",
        projectId: "9999",
        projectName: "project9",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 11,
        clientName: "client10",
        projectId: "0000",
        projectName: "project10",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 12,
        clientName: "client11",
        projectId: "1112",
        projectName: "project11",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 13,
        clientName: "client12",
        projectId: "1113",
        projectName: "project12",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 14,
        clientName: "client13",
        projectId: "1114",
        projectName: "project13",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 15,
        clientName: "client14",
        projectId: "1115",
        projectName: "project14",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 16,
        clientName: "client15",
        projectId: "1116",
        projectName: "project15",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 17,
        clientName: "client16",
        projectId: "1117",
        projectName: "project16",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 18,
        clientName: "client17",
        projectId: "1118",
        projectName: "project18",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 19,
        clientName: "client19",
        projectId: "1119",
        projectName: "project19",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 20,
        clientName: "client20",
        projectId: "1120",
        projectName: "project20",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    {
        id: 21,
        clientName: "client21",
        projectId: "1121",
        projectName: "project21",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Active",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "45%",
        updated: "An hour ago"
    },
    ]
}


export const TABLE_MOCK_DATA2 = {
    data: [{
        id: 1,
        clientName: "Company name with rooms for 2 lines...",
        projectId: "000321",
        projectName: "Project name with room for 2 lines....",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Finalized",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "100%",
        updated: "An hour ago"
    },
    {
        id: 2,
        clientName: "Company name with rooms for 2 lines...",
        projectId: "000322",
        projectName: "Project name with room for 2 lines....",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Finalized",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "100%",
        updated: "An hour ago"
    },
    {
        id: 3,
        clientName: "Company name",
        projectId: "000323",
        projectName: "Project name",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Finalized",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "100%",
        updated: "An hour ago"
    },
    {
        id: 4,
        clientName: "Company name with rooms for 2 lines...",
        projectId: "000324",
        projectName: "Project name with room for 2 lines....",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Finalized",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "100%",
        updated: "An hour ago"
    },
    {
        id: 5,
        clientName: "Company name with rooms for 2 lines...",
        projectId: "000325",
        projectName: "Project name with room for 2 lines....",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Finalized",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "100%",
        updated: "An hour ago"
    },
    {
        id: 6,
        clientName: "Company name",
        projectId: "000326",
        projectName: "Project name",
        yearEnd: 2022,
        projectYear: 2022,
        projectState: "Finalized",
        projectType: "Consolidated Audit",
        engagementLeader: "Timothy Johnson",
        office: "Minneapolis",
        industry: "Manufacturing",
        progress: "100%",
        updated: "An hour ago"
    }
    ]
}

export const AUDIT_PROGRESS_DATA = [
    { title: 'Planning & Risk Assessment', innerRadial: 49, outerRadial: 51 },
    { title: 'Substantive Procedures', innerRadial: 63, outerRadial: 37 },
    { title: 'Project Conclusions', innerRadial: 38, outerRadial: 62 },
]

export const KEY_PERFORMANCE_INDICATORS_DATA = [
    { topTitle: 'Average Inventory Turnover' },
    { topTitle: 'Value-Added Rev per ETE ($000\'s)' },
    { topTitle: 'Gross Margin %' },
]

export const METHODOLOGY_VERSIONS = [
    { projectId: 'pid002', methodologyVersion: '2023.1', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.' },
    { projectId: 'pid002', methodologyVersion: '2023.2', description: 'text here' },
    { projectId: 'pid002', methodologyVersion: '2023.3', description: 'text here' },
    { projectId: 'pid002', methodologyVersion: '2023.4', description: 'text here' },
    { projectId: 'pid002', methodologyVersion: '2023.5', description: 'text here' },
    { projectId: 'pid002', methodologyVersion: '2023.6', description: 'text here' },
    { projectId: 'pid003', methodologyVersion: '2023.1', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.' },
    { projectId: 'pid003', methodologyVersion: '2023.2', description: 'text here' },
    { projectId: 'pid003', methodologyVersion: '2023.3', description: 'text here' },

    { projectId: 'PID00000002', methodologyVersion: '2023.1', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.' },
    { projectId: 'PID00000002', methodologyVersion: '2023.2', description: 'text here' },
    { projectId: 'PID00000002', methodologyVersion: '2023.3', description: 'text here' },
    { projectId: 'PID00000002', methodologyVersion: '2023.4', description: 'text here' },
    { projectId: 'PID00000002', methodologyVersion: '2023.5', description: 'text here' },
    { projectId: 'PID00000002', methodologyVersion: '2023.6', description: 'text here' },
    { projectId: 'PID00000003', methodologyVersion: '2023.1', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.' },
    { projectId: 'PID00000003', methodologyVersion: '2023.2', description: 'text here' },
    { projectId: 'PID00000003', methodologyVersion: '2023.3', description: 'text here' },
]