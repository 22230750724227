import React from 'react';
import PropTypes from 'prop-types';
import string from "@utilities/stringHelper";
import { CLATooltip } from "@ais/components"

const CLATruncatedTextWithTooltip = React.memo(({
  text, 
  length, 
  tooltipProps, 
  truncateChars = '...', 
  ...props
}) => (
  <CLATooltip title={text} placement="bottom" {...tooltipProps}>
      <span {...props}>{string.truncate(text, length, truncateChars)}</span>
  </CLATooltip>
))

CLATruncatedTextWithTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  tooltipProps: PropTypes.object,
  truncateChars: PropTypes.string,
}

CLATruncatedTextWithTooltip.defaultPropTypes = {
  truncateChars: '...'
}

export default CLATruncatedTextWithTooltip;