/*
/App

The main entry point for application components.
*/

// common modules
import React, { useEffect, useState, useContext } from "react";
import { ToastContainer } from "react-toastify";
import { useIsFetching } from "@tanstack/react-query"
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { InteractionStatus } from "@azure/msal-browser";
import { Providers, SimpleProvider, ProviderState } from "@microsoft/mgt-element";
import { ThemeProvider } from '@mui/material/styles';

// @VIEWS
import "./index.css";
import "@palette/tokens/variable.css"
import * as Constants from "@constants/index";

// @UTILITIES
import { reactPlugin, appInsights } from "@utilities/applicationInsights.js";
import { getAccessToken } from "@utilities/authentication.js";
import { authenticated, getAccount } from "./utilities/authentication";
import { theme } from '@ais/theme';
import useUserProviderState from "@contexts/UserContext";
import { useUserSummary } from "@services/users";
import { useLoading } from '@hooks/index';
import { useLoadingByQueryCount } from '@hooks/useLoadingByQueryCount';
import { ApplicationContext } from "./contexts/ApplicationContext";
import { CLABackDrop } from '@ais/components';
import apiClientFactory from '@utilities/apiClientFactory.js';

//license
import { LicenseInfo } from '@mui/x-license-pro';
import { AppRoutes } from "@routes/AppRoutes";
import loadable from "@loadable/component"
import { VersionErrorDialog } from "@components/VersionErrorDialog";

const NoAccess = loadable(() => import("@views/NoAccess/NoAccess"))
const { NOT_LOGGED_IN } = Constants.ERRORS;

LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENSE_KEY_MUIXPRO);

//redirect to AD login for sso, call when unauthenticated
const RedirectToLogin = () => {
  const { instance, inProgress } = useMsal();

  // when interaction is complete, redirect to login
  if (inProgress === InteractionStatus.None) {
    instance.loginRedirect({ scopes: [] });
  }

  return null;
};

const Login = () => Providers.globalProvider.setState(ProviderState.SignedIn);
const Logout = () => Providers.globalProvider.setState(ProviderState.SignedOut);

const graphScopes = [
  'https://graph.microsoft.com/Contacts.Read',
  'https://graph.microsoft.com/People.Read',
  'https://graph.microsoft.com/Presence.Read.All',
  'https://graph.microsoft.com/User.ReadBasic.All',
  'https://graph.microsoft.com/User.Read.All'
];

const App = () => {
  const { instance, accounts } = useMsal();
  const { getUserInfo } = useUserProviderState();

  const fetchingQueriesCount = useIsFetching();
  const { setLoadingByQueryCount } = useLoadingByQueryCount();
  const [userAccount, setUserAccount] = useState(null);
  const { application } = useContext(ApplicationContext);
  const setLoading = useLoading();
  const {
    data: userSummary,
    isLoading: isLoadingUserSummary,
    error: errorSummary
  } = useUserSummary();

  useEffect(() => {
    setLoadingByQueryCount(fetchingQueriesCount);
  }, [fetchingQueriesCount])

  useEffect(() => {
    const GetAccessToken = async () => {
      const request = { scopes: graphScopes, account: accounts[0] };
      const accessToken = await getAccessToken(instance, request);
      return accessToken ? accessToken : null;
    };
    let provider = new SimpleProvider(GetAccessToken, Login, Logout);
    Providers.globalProvider = provider;
    provider.login();
    appInsights.clearAuthenticatedUserContext();

    if (authenticated()) {
      const account = getAccount();
      setLoading(true);
      appInsights.context.user.id = account.idTokenClaims.oid;
      appInsights.setAuthenticatedUserContext(account.username);
      setUserAccount(account);
    }
  }, []);

  useEffect(() => {
    if (isLoadingUserSummary) {
      return
    }
    if (!userSummary) {
      return
    }
    getUserInfo(userAccount, userSummary.groups, userSummary.functionalRights, userSummary.employeeId, userSummary.email, true)
    setLoading(false)
  }, [userSummary, isLoadingUserSummary])

  return (
    <>
      {!errorSummary && !apiClientFactory.isMaintenanceMode ?
        <>
          <AuthenticatedTemplate>
            <ThemeProvider theme={theme}>
              <VersionErrorDialog />
              <ToastContainer />
              {application?.isLoading && <CLABackDrop isOpen={application?.isLoading} />}
              <AppRoutes />
            </ThemeProvider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <RedirectToLogin />
          </UnauthenticatedTemplate>
        </>
        :
        errorSummary?.name === NOT_LOGGED_IN ?
          <UnauthenticatedTemplate>
            <RedirectToLogin />
          </UnauthenticatedTemplate>
          :
          <NoAccess errorSummary={errorSummary} isMaintenanceMode={apiClientFactory.isMaintenanceMode} />
      }
    </>
  )
};

export default withAITracking(reactPlugin, App);