import {
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow
} from '@mui/material';
import { colors } from '@ais/theme';
import CommunicationOfDeficienciesHeader from './CommunicationOfDeficienciesHeader';
import CommunicationOfDeficienciesRowCollapsible from './CommunicationOfDeficienciesRowCollapsible';

const CommunicationOfDeficienciesTable = (props) => {
    const { rows, columns, projectId, handleSave, otherDeficiency = false } = props;

    return (
        <>
            <TableContainer sx={{
                flexGrow: 1,
                width: '100%',
                overflow: 'auto'
            }}>
                <Table sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)', borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                    <TableHead className={'MuiDataGrid-columnHeaders'}>
                        <TableRow
                            className={'MuiDataGrid-columnHeadersInner'}
                            sx={{
                                p: '0 10px',
                                backgroundColor: colors.gray[25],
                                display: 'flex',
                                borderBottom: '1px solid rgba(224, 224, 224, 1)'
                            }}
                        >
                            <CommunicationOfDeficienciesHeader columns={columns} />
                        </TableRow>
                    </TableHead>
                    <TableBody className={'MuiDataGrid-columnHeaders'} style={{ backgroundColor: 'white' }}>
                        {rows?.map((row, index) => (
                            <CommunicationOfDeficienciesRowCollapsible
                                key={row?.ProjectDeficiencyId ? `ProjectDeficiencyId_${index}`: row.ProjectDeficiencyCombinationId ? `ProjectDeficiencyCombinationId_${index}` : null}
                                columns={columns}
                                rows={rows}
                                row={row}
                                projectId={projectId}
                                handleSave={handleSave}
                                otherDeficiency={otherDeficiency}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
        </>
    )
}

export default CommunicationOfDeficienciesTable;