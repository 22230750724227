import React, { useState, useEffect } from 'react';
import {CLADialog, CLADialogConfirm} from '@ais/components';
import {CLADatePickerField} from '@ais/forms';
import { Flex } from "@ais/palette"
import { Box, Typography } from '@mui/material';
import { Controller, useForm, useWatch } from "react-hook-form"
import { add } from 'date-fns';
import styles from './ClientAccessModal.module.css'
import { useUpdateSharedForm } from '@services/forms/projectforms.js'
import { useLoading } from "@hooks/index";
import { FORM_SETTINGS } from '@ais/constants';

const { CLIENT_ACCESS_MODAL } = FORM_SETTINGS.EN
const { 
    MODE, 
    SELECT_DUE_DATE, 
    SELECT_DATE_PLACEHOLDER,
    SHARE_FORM_MODAL_TITLE,
    CANCEL,
    SHARE,
    SUCCESS_TITLE,
    SUCCESS_SHARE_MESSAGE,
    CLIENT_PROJECT_FORM_STATUS,
    CLIENT_ACCESSIBLE,
    CLIENT_NOT_ACCESSIBLE,
    WARNING,
    REMOVE,
    REMOVE_MESSAGE,
    REMOVE_SUCCESS
 } = CLIENT_ACCESS_MODAL

const { NOT_STARTED } = CLIENT_PROJECT_FORM_STATUS

const ShareFormDatePicker = (formMethods) => {
    return (
            <Flex className={styles['flex']} direction={'row'} align={'center'}>
                <Typography className={styles['duedate']}>{SELECT_DUE_DATE}</Typography>
                <Box className={styles['datepicker']}>
                    <Controller
                        control={formMethods.control}
                        name="date"
                        render={({field: {onChange, value}}) => {
                            return <CLADatePickerField placeholder={SELECT_DATE_PLACEHOLDER} defaultValue={value} onChange={onChange}/>
                        }}
                        />
                </Box>
            </Flex>
    )
}

const ClientAccessModal = ({visible, setShareClientModalVisble, projectForm, projectId, setSharedToClient}) => {
    const [shareFormVisible, setShareFormVisible] = useState(false);
    const [successShareVisible, setSuccessShareVisible] = useState(false);
    const [successRemoveShareVisible, setSuccessRemoveShareVisible] = useState(false);
    const [removeShareVisible, setRemoveShareVisible] = useState(false);
    const {mutateAsync: putProjectForm, isLoading} = useUpdateSharedForm()
	const setLoading = useLoading()

    useEffect(() => {
        if(visible.mode === MODE.SHARE){
            setShareFormVisible(true)
        }
        if(visible.mode === MODE.REMOVE_SHARE){
            setRemoveShareVisible(true)
        }
    }, [visible])

    useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    const defaultValues = {
        date: add(new Date(), { weeks: 2 }),
    }

    const formMethods = useForm({
        defaultValues
    })
    
    const date = useWatch({ control: formMethods.control, name: "date" })

    const handleShareFormOnCancel = () => {
        setShareClientModalVisble({visible: false, mode: ""})
    }

    const handleOnShare = async () => {
        let data
        const currentDateAndTime = new Date();
        if(date){
            data = await putProjectForm({
                projectId: projectId,
                body: {
                    sharedForms: [
                        {
                            ProjectFormId: projectForm?.projectFormID,
                            IsClientAccessible: CLIENT_ACCESSIBLE,
                            ClientShareDate: currentDateAndTime,
                            ClientDueDate: date,
                            ClientProjectFormStatusId: NOT_STARTED,
                            ClientCompletionDate: null    
                        }
                    ]
                }
            })
            setShareFormVisible(false)
            setSuccessShareVisible(true)
        }
    }

    const handleOnRemoveShare = async () => {
        await putProjectForm({
            projectId: projectId,
            body: {
                sharedForms: [
                    {
                        ProjectFormId: projectForm?.projectFormID,
                        IsClientAccessible: CLIENT_NOT_ACCESSIBLE,
                        ClientShareDate: null,
                        ClientDueDate: null,
                        ClientProjectFormStatusId: null,
                        ClientCompletionDate: null    
                    }
                ]
            }
        })
        setRemoveShareVisible(false)
        setSuccessRemoveShareVisible(true)
    }

    const handleSuccessClose = (visible) => {
        setSharedToClient(true)
        setShareClientModalVisble({visible: visible, mode:""})
    }

    const handleRemoveSuccessClose = (visible) => {
        setSharedToClient(false)
        setShareClientModalVisble({visible: visible, mode:""})
    }

    return (
        <>
            <CLADialogConfirm 
                title={SHARE_FORM_MODAL_TITLE} 
                visible={shareFormVisible} 
                cancelText={CANCEL} 
                confirmText={SHARE}
                onCancel={handleShareFormOnCancel}
                customMessage={ShareFormDatePicker(formMethods)}
                onConfirm={handleOnShare}
                />

            <CLADialog title={SUCCESS_TITLE} visible={successShareVisible} setVisible={handleSuccessClose}>
                <Typography className={styles['success']}>{SUCCESS_SHARE_MESSAGE}</Typography>
            </CLADialog>

            <CLADialogConfirm 
                title={WARNING} 
                visible={removeShareVisible} 
                cancelText={CANCEL} 
                confirmText={REMOVE}
                onCancel={handleShareFormOnCancel}
                customMessage={<Typography className={styles['duedate']}>{REMOVE_MESSAGE}</Typography>}
                onConfirm={handleOnRemoveShare}
            />

            <CLADialog title={SUCCESS_TITLE} visible={successRemoveShareVisible} setVisible={handleRemoveSuccessClose}>
                <Typography className={styles['success']}>{REMOVE_SUCCESS}</Typography>
            </CLADialog>
        </>

    )
}

export default ClientAccessModal