import { useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@ais/palette";
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { RadioSelect, ToggleableTextarea, ExternalAccess } from './dialog-components/settings';
import {FIELD_TYPES} from '@ais/constants';
import { DIALOG, TRIGGERS } from '@constants/forms';
import {
  createField,
  dropNewItem,
  updateFormItem,
  widthOptions,
  filterEmptyCriterias,
  isTriggeredFromQuestionGroup,
  getShareableComponentDefaultValue
} from '@components/Forms/helpers';
import { SchemaContext } from '@ais/providers';
import { BaseDialog } from '.';
import { RulesTab } from './dialog-components/rules';
import classes from './FreeTextDialog.module.css';

export const FreeTextDialog = ({ visible, setVisible, additionalData, trigger }) => {
  const { LABEL, PLACEHOLDER } = DIALOG.SETTINGS;

  const { schema, dispatchSchema } = useContext(SchemaContext);

  const initialValues = {
    text: additionalData?.text ?? '',
    width: additionalData?.width ?? 100,
    rules:
      typeof additionalData?.rules === 'object'
        ? additionalData.rules
        : {
            behavior: 'show',
            match: 'any',
            criterias: [],
            excludeAnswerOnRollForward: false,
            canNotBeDeleted: true,
          },
    visibleToClient: getShareableComponentDefaultValue(additionalData, "visibleToClient", trigger),
    editableByClient: getShareableComponentDefaultValue(additionalData, "editableByClient", trigger),
  };

  const schemaValidation = Yup.object().shape({
    text: Yup.string().trim().required()
  })

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation)
  });

  const handleSubmit = (values) => {
    const filteredRules = filterEmptyCriterias(values.rules);
    if (![TRIGGERS.EDIT_BTN, TRIGGERS.QUESTION_GROUP_EDIT].some((t) => t === trigger)) {
      const newField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.refField.index,
        text: values.text,
        ...values,
        width: +values.width,
        rules: filteredRules,
      });
      dropNewItem(additionalData, newField, trigger, schema, dispatchSchema);
    } else {
      const updateField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.index,
        text: values.text,
        ...values,
        width: +values.width,
        rules: filteredRules,
      });
      updateFormItem(additionalData, updateField, schema, dispatchSchema);
    }
    setVisible(false);
  }

  useEffect(() => {
    formMethods.reset(initialValues);
    formMethods.trigger();
  }, [visible]);

  const tabs = [
    {
      label: LABEL.SETTINGS,
      render: () => (
        <Grid className={classes.container} container spacing={1} columns={1}>
          <Grid item xs={6}>
            <ToggleableTextarea
              name="text"
              label={LABEL.ENTER_YOUR_TEXT_HERE}
              placeholder={PLACEHOLDER.TEXT}
              maxLength={4096}
              maxRows={10}
              minRows={10}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              {!isTriggeredFromQuestionGroup(trigger) && <ExternalAccess
                allowExternalAccessName="visibleToClient"
                makeAnswerableName="editableByClient"
                viewableOnly
                formTypeId={schema.formTypeId}
              />}
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Grid item xs="auto">
                <RadioSelect
                  name="width"
                  label={LABEL.DISPLAY_WIDTH}
                  options={widthOptions}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    }
  ];

  if (additionalData?.fieldType === FIELD_TYPES.FREETEXT) {
    tabs.push({
      label: LABEL.RULES,
      render: () => (
        <RulesTab
          additionalData={additionalData}
          trigger={trigger}
        />
      ),
    });
  }

  return (
    <Form form={formMethods}>
      <BaseDialog
        id={isTriggeredFromQuestionGroup(trigger) ? '' : additionalData?.id}
        idPrefix={isTriggeredFromQuestionGroup(trigger) ? '' : LABEL.QUESTION_ID}
        title={LABEL.FREE_TEXT}
        visible={visible}
        setVisible={setVisible}
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        tabs={
          isTriggeredFromQuestionGroup(trigger)
            ? tabs.filter((tab) => tab.label !== LABEL.RULES)
            : tabs
        }
      />
    </Form>
  );
};

FreeTextDialog.propTypes = {
  visible: PropTypes.bool,
  additionalData: PropTypes.object,
  trigger: PropTypes.string,
  setVisible: PropTypes.func,
};

FreeTextDialog.defaultProps = {
  value: false,
  additionalData: null,
  trigger: '',
};
