import React from "react";
import { Box, Typography } from "@mui/material";
import { Flex } from "@ais/palette";
import { CLADialog } from "@ais/components";

const dialogStyle = {
  "& .MuiDialog-paper": {
    width: "655px",
  },
  "& .MuiDialogContent-root": {
    overflow: "hidden",
    marginBottom: "10px",
    paddingTop: "5px",
  },
  "& .MuiTypography-body1": {
    lineHeight: "1.3",
  }
};

const CLAWarningModal = ({
  title,
  description,
  visible,
  setVisible,
  cols,
  rows,
  closeButtonLabel,
}) => {

  return (
    <CLADialog
      {...{
        closeButtonLabel,
        dialogStyle,
        title,
        setVisible,
        visible,
        singleButton: true,
      }}
    >
      <Box sx={{ marginBottom: 3 }}>
        <WarningText>{description}</WarningText>
      </Box>
      <Flex style={{ marginBottom: "8px" }}>
        <Flex direction="row" justify="start">
          {cols.map((item) => (
            <ItemHeading size="3" key={item.id}>
              {item.text}
            </ItemHeading>
          ))}
        </Flex>
        <Box style={{
          maxHeight: 350,
          overflow: "auto"
        }}>
          {rows.map((row) => {
            return(
              <Box style={{ marginBottom: "10px" }} key={row[cols[0]?.id]}>
                <Box style={{ display: "inline-block", width: "33.33%", verticalAlign: "top" }}>
                  <Item>{row[cols[0]?.id]}</Item>
                </Box>
                <Box style={{ display: "inline-block", width: "66.66%" }}>
                  {row[cols[1].id]?.map((child) => (
                    <Box style={{ marginBottom: "10px" }} key={row[cols[1]?.id]}>
                      <Box style={{ width: "50%", display: "inline-block", verticalAlign: "top" }}><Item>{child.text}</Item></Box>
                      <Box style={{ display: "inline-block", width: "50%", verticalAlign: "top" }}>
                        {child[cols[2].id]?.map((childItem, index) => (
                          <Item style={{
                            marginBottom: "9px"
                          }}
                          key={index}>{childItem}</Item>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )
          })}
        </Box>
      </Flex>
    </CLADialog>
  );
};

const ItemHeading = (props) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        color: "#2D3C59",
        fontFamily: "museo-sans",
        fontWeight: "900",
        lineHeight: "18.8px",
        fontSize: "17px",
        paddingBottom: "5px",
        marginBottom: "15px",
        display: "block",
        width: "100%",
        borderBottom: "2px solid #DDDDDD",
        ...sx,
      }}
      {...other}
    />
  );
};

const WarningText = ({ children }) => (
  <Typography
    sx={{
      fontFamily: "museo-sans",
      fontWeight: 300,
      fontSize: "15px",
      marginTop: "-15px",
      marginBottom: "20px",
    }}
  >
    {children}
  </Typography>
);

const Item = ({ children, style }) => (
  <Box
    style={{
      color: "#4B4B4B",
      fontFamily: "museo-sans",
      fontWeight: "600",
      lineHeight: "16.8px",
      fontSize: "14px",
      marginBottom: "10px",
      paddingRight: "12px",
      ...style,
    }}
  >
    <Box>{children}</Box>
  </Box>
);

CLAWarningModal.defaultProps = {
  closeButtonLabel: "OK",
};

export default CLAWarningModal;
