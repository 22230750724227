import { memo, useEffect, useRef } from "react";
import AutoNumeric from 'autonumeric';
import { SHORT_ANSWER_MAX_CHARACTERS, StyledOutlinedInput } from "./NumericalField";

const PercentageField = (props) => {
  const { onChange, value, onBlur } =props
  const inputRef = useRef(null);
  const autoNumericRef = useRef(null);

  useEffect(() => {
      const inputElement = inputRef.current;
      const autoNumeric = new AutoNumeric(inputElement, {
          digitGroupSeparator: ',',
          decimalCharacter: '.',
          decimalPlaces: 2,
          suffixText: '%',
          minimumValue: 0,
          maximumValue: 100,
          modifyValueOnWheel: false
      });

      autoNumericRef.current = autoNumeric;

      return () => {
          autoNumeric.remove();
      };
  }, []);

  return (
    <StyledOutlinedInput
      notched
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
      }}
      inputProps={{
        ...props.inputProps,
        maxLength: SHORT_ANSWER_MAX_CHARACTERS
      }}
      defaultValue={value}
      inputRef={inputRef}
      onChange={() => { 
        onChange(autoNumericRef.current.get() / 100);
      }}
      onBlur={onBlur}
      
    />)
}

export default memo(PercentageField)