import React, { memo } from "react";
import {
    FormControl,
    InputLabel,
    Typography,
} from "@mui/material";
import { sanitize } from "dompurify";
import { Controller, useFormContext } from "react-hook-form"

import styles from '@components/FormView/FormView.module.css';
import { useVFCanvasProvider } from '@providers';
import { Flex } from "@ais/palette";
import { FIELD_TYPES } from "@ais/constants";
import {
    CLACheckBoxGroup,
    CLAComboBox,
    CLACurrencyField,
    CLADatePickerField,
    CLADropdown,
    CLAFormTable,
    CLANumberField,
    CLAPercentageField,
    CLARadioButtonList,
    CLASelect,
    ClientExternalIcon,
    copyObject,
    Formula,
    InstanceLongAnswer,
    parseQuestionLabel,
    renderLabel,
} from '@ais/forms';

import CLAFinancialStatementLevelRisk from "../../FinancialStatementLevelRisk/CLAFinancialStatementLevelRisk";
import CLAAccountAssertionLevelRisk from "../../AccountAssertionLevelRisk/CLAAccountAssertionLevelRisk";
import VFRenderedFieldWrapper from '@components/FormView/VFRenderedFieldWrapper';
import { TbLinkBaseField, NoValidationInput } from './TbLinkFields';

export const VFFields = memo((props) => {
    const {
        field,
        projectId,
        projectFormId,
        disabled: _disabled,
        isInstance,
        answerList,
        renderLink,
        getDefaultValue,
        handleCurrencyValue,
        isLockedByUser,
        handleOnFocus,
        watchRadioList,
        handleBlur,
        formTypeId,
        units,
        isIdle,
        answered,
        onResetAnswerQuestionField,
        onUnlockConcurrentField
    } = props;
    const formCanvas = useFormContext();
    const { actions } = useVFCanvasProvider();
    const disabled = _disabled || field.linkType === 'Read Only';
    const handleNoValidationBlur = (answer) => {
        let parsedAnswer;
        try {
            parsedAnswer = JSON.parse(answer);
        } catch {
            parsedAnswer = answer;
        }

        if (typeof parsedAnswer !== "string" || !isInstance) return;

        const formValue = formCanvas.getValues(field.id);

        if (answer !== formValue && isInstance)
            formCanvas.setValue(field.id, answer);
    }

    switch (field.type) {
        case FIELD_TYPES.SHORT_ANSWER:
        case FIELD_TYPES.LONG_ANSWER: {
            let defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();

            //only concurrency value from portal to portal has percentage symbol.
            if (field.validation === 'percentage' && isNaN(defaultValue) && defaultValue?.includes('%')) {
                defaultValue = Number(defaultValue.replace('%', '')) / 100;
            }

            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isInstance
                >
                    <FormControl fullWidth>
                        {
                            !!field?.label && <InputLabel shrink variant="multiline-label">
                                {renderLabel(field.label, field.tooltip)}
                            </InputLabel>
                        }
                        {field.validation === 'none' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <NoValidationInput
                                            field={field}
                                            defaultValue={defaultValue}
                                            isFormInstance={isInstance}
                                            disabled={disabled}
                                            onFocus={handleOnFocus}
                                            onChange={onChange}
                                            answered={answered}
                                            units={units}
                                            isIdle={isIdle}
                                            onBlur={(answer) => handleNoValidationBlur(answer)}
                                            onUnlockConcurrentField={onUnlockConcurrentField}
                                        />
                                    )
                                }} />

                        )}
                        {field.validation === 'numerical' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLANumberField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value)
                                            }}
                                            placeholder={field.placeholder}
                                            defaultValue={defaultValue}
                                            notched
                                            digitGroupSeparator=","
                                            disabled={disabled}
                                            isInstance={isInstance}
                                            tbLinkEnabled={isInstance}
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            field={field}
                                            tbLinkBaseField={TbLinkBaseField}
                                            units={units}
                                            onFocus={handleOnFocus}
                                            isIdle={isIdle}
                                            onUnlockConcurrentField={onUnlockConcurrentField}
                                        />
                                    )
                                }} />
                        )}
                        {field.validation === 'usCurrency' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLACurrencyField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value)
                                                if (isInstance) handleCurrencyValue(value)
                                            }}
                                            placeholder={field.placeholder}
                                            defaultValue={isInstance ? defaultValue : handleCurrencyValue(defaultValue)}
                                            disabled={disabled}
                                            notched
                                            onFocus={handleOnFocus}
                                            isInstance={isInstance}
                                            tbLinkEnabled={isInstance}
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            field={field}
                                            tbLinkBaseField={TbLinkBaseField}
                                            units={units}
                                            isIdle={isIdle}
                                            onUnlockConcurrentField={onUnlockConcurrentField}
                                        />
                                    )
                                }} />
                        )}
                        {field.validation === 'percentage' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLAPercentageField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value?.toString())
                                            }}
                                            placeholder={field.placeholder}
                                            value={defaultValue}
                                            disabled={disabled}
                                            notched
                                            onFocus={handleOnFocus}
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            isIdle={isIdle}
                                        />
                                    )
                                }} />
                        )}
                    </FormControl>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.INSTANCE_LONG_ANSWER: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isInstance
                >
                    <InstanceLongAnswer
                        field={field}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        onFocus={handleOnFocus}
                        isIdle={isIdle}
                    />
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.FREETEXT:
        case FIELD_TYPES.FREETEXT_NO_RULES: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isInstance
                >
                    <Flex direction="row" justify="between" align="baseline" className={styles['vf-field__freetext-container']}>
                        <Typography variant={field.type === FIELD_TYPES.FREETEXT && 'freeText'}>
                            <div
                                className="ql-editor"
                                dangerouslySetInnerHTML={{
                                    __html: sanitize(field.text, { ADD_ATTR: ['target'] }),
                                }}
                            />
                        </Typography>
                        <ClientExternalIcon
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                        />
                    </Flex>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.CHECKBOXGROUP: {
            const defaultValue = getDefaultValue(true);
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isInstance
                >
                    <CLACheckBoxGroup
                        id={field.id}
                        label={renderLabel(field.label, field.tooltip, renderLink)}
                        value={Array.isArray(defaultValue) ? defaultValue.filter(Boolean) : [defaultValue].filter(Boolean)}
                        onChange={(value, otherValue, otherToggled, e) => {
                            const copy = [...value];
                            if (otherValue) copy.push(otherValue)
                            formCanvas.setValue(field.id, copy);
                            if (!otherToggled && isInstance) {
                                actions.handleSubmit();
                            }
                            onResetAnswerQuestionField(field.id)
                            if (e) handleBlur(e, true)
                        }}
                        required={field.required}
                        columns={field.columns}
                        options={field.options}
                        allowOtherOption={field.allowOtherOption}
                        isDisabled={disabled}
                        onFocus={handleOnFocus}
                        isInstance={isInstance}
                        allowExternalAccess={field.visibleToClient ?? false}
                        answerable={field.editableByClient ?? false}
                        answered={answered}
                    />
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.RADIOGROUP: {
            const defaultValue = getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isInstance
                >
                    <Controller
                        control={formCanvas.control}
                        name={field.id}
                        render={({ field: { onChange } }) => {
                            return (
                                <CLARadioButtonList
                                    id={field.id}
                                    label={renderLabel(field.label, field.tooltip, renderLink)}
                                    defaultValue={watchRadioList}
                                    value={defaultValue}
                                    onChange={value => {
                                        onChange(value)
                                        if (isInstance) actions.handleSubmit();
                                    }}
                                    required={field.required}
                                    columns={field.columns}
                                    options={field.options}
                                    allowOtherOption={field.allowOtherOption}
                                    isDisabled={disabled}
                                    onFocus={handleOnFocus}
                                    formTypeId={formTypeId}
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                    answered={answered}
                                />
                            )
                        }} />
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.DROPDOWN: {
            const defaultValue = getDefaultValue(true);
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isInstance
                >
                    {field.allowOtherOption && (
                        <CLAComboBox
                            label={renderLabel(field.label, field.tooltip)}
                            defaultValue={defaultValue ?? ''}
                            onChange={(values, e, reason) => {
                                formCanvas.setValue(field.id, values)
                                if ((reason === 'removeOption' && e.target.tagName === 'path') && isInstance) {
                                    actions.handleSubmit();
                                }
                            }}
                            onBlur={(values) => {
                                formCanvas.setValue(field.id, values)
                                onResetAnswerQuestionField(field.id)
                                handleBlur({ target: { value: values } }, true)
                                if (isInstance) {
                                    actions.handleSubmit();
                                }
                            }}
                            options={field.options}
                            allowMultiSelect={field.allowMultiSelect}
                            isDisabled={disabled}
                            onFocus={handleOnFocus}
                            allowMultiLineLabel
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                            enableOnChangeHandler={false}
                        />
                    )}
                    {!field.allowOtherOption && !field.allowMultiSelect && (
                        <CLADropdown
                            name={field.id}
                            label={renderLabel(field.label, field.tooltip)}
                            options={field.options}
                            value={defaultValue}
                            isDisabled={disabled}
                            onChange={(values) => {
                                formCanvas.setValue(field.id, values?.target?.value)
                            }}
                            onOpen={handleOnFocus}
                            allowMultiLineLabel
                            onClose={(e) => {
                                onResetAnswerQuestionField(field.id)
                                handleBlur(e, true)
                            }}
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                        />
                    )}
                    {!field.allowOtherOption && field.allowMultiSelect && (
                        <CLASelect
                            id={field.id}
                            name={field.id}
                            label={renderLabel(field.label, field.tooltip)}
                            isDisabled={disabled}
                            defaultValues={
                                Array.isArray(defaultValue) ? defaultValue.filter(Boolean) : [defaultValue].filter(Boolean)
                            }
                            onChange={(values) => {
                                formCanvas.setValue(field.id, values?.filter((i) => i))
                            }}
                            menuItems={field.options}
                            onOpen={handleOnFocus}
                            allowMultiLineLabel
                            onClose={(e) => {
                                onResetAnswerQuestionField(field.id)
                                handleBlur(e, true)
                            }}
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                            uncontrolled
                        />
                    )}
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.DATE_PICKER: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isInstance
                >
                    <Controller
                        control={formCanvas.control}
                        name={field.id}
                        render={({ field: { onChange } }) => {
                            return (
                                <CLADatePickerField
                                    label={renderLabel(field.label, field.tooltip)}
                                    onChange={(e) => {
                                        const value = e ?? null;
                                        onChange(value)
                                    }}
                                    onBlur={() => {
                                        handleBlur(null, true)
                                    }}
                                    placeholder={field.placeholder}
                                    disabled={disabled}
                                    defaultValue={defaultValue || formCanvas.getValues(field.id)}
                                    onFocus={handleOnFocus}
                                    allowMultiLineLabel
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                    answered={answered}
                                    isIdle={isIdle}
                                />
                            )
                        }} />
                    {renderLink()}

                </VFRenderedFieldWrapper>

            );
        }
        case FIELD_TYPES.TABLE: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue(true);
            const parsed = parseQuestionLabel(field.label);
            const label = typeof parsed === 'object' ? parsed.questionLabel : parsed;
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isInstance
                >
                    <div className={styles['vf-section-table-container']}>
                        <div className={styles['vf-section-table-label-wrapper']}>
                            <Flex direction="row" justify="between" align="baseline">
                                <InputLabel className={styles['vf-section-table-label']}>
                                    {renderLabel(label, field.tooltip, renderLink)}
                                </InputLabel>
                                <ClientExternalIcon
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                />
                            </Flex>
                        </div>
                        <CLAFormTable
                            field={field}
                            values={defaultValue}
                            onChange={(e) => {
                                formCanvas.setValue(field.id, e)
                                if (isInstance) {
                                    actions.handleSubmit();
                                }
                                handleBlur({ target: { value: e } }, true)
                                onResetAnswerQuestionField(field.id)
                            }}
                            isInstance={isInstance}
                            disabled={disabled}
                            onFocus={handleOnFocus}
                            isIdle={isIdle}
                        />
                    </div>
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.FORMULA: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isInstance
                >
                    <Formula
                        field={field}
                        shouldRenderFormula={isInstance}
                        label={renderLabel(field.label, field.tooltip, renderLink)}
                        answerList={answerList ? copyObject(answerList) : null}
                        onFocus={handleOnFocus}
                        disabled={disabled}
                    />
                </VFRenderedFieldWrapper>
            )
        }
        case FIELD_TYPES.FINANCIAL_STATEMENT_LEVEL_RISK: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <CLAFinancialStatementLevelRisk
                            projectFormId={projectFormId}
                            disabled={_disabled} 
                            />
                    </div>
                </div>
            );
        }
        case FIELD_TYPES.ACCOUNT_AND_ASSERTION_LEVEL_RISK: {
            return (
                <div className={styles.account__assertion__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                            <CLAAccountAssertionLevelRisk
                                projectFormId={+projectFormId}
                                projectId={projectId}
                                disabled={_disabled}
                                isIdle={isIdle}
                            />
                    </div>
                </div>
            );
        }
        default: {
            return null;
        }
    }
});
