import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const ScopingFrameworkAndPerformanceStandardContext = createContext({});

export const ScopingFrameworkAndPerformanceStandardProvider = ({ children }) => {

    const [selectedFrameworks, setSelectedFrameworks] = useState(undefined);
    const [selectedPerformanceStandards, setSelectedPerformanceStandards] = useState([]);
    const [projectFormId, setProjectFormId] = useState(null);
    
    const memoized = useMemo(
        () => ({ 
            selectedFrameworks,
            selectedPerformanceStandards,
            setSelectedFrameworks,
            setSelectedPerformanceStandards,
            projectFormId
         }),
        [
            selectedFrameworks,
            selectedPerformanceStandards,
            projectFormId,
            setSelectedFrameworks,
            setSelectedPerformanceStandards,
            setProjectFormId,
        ]
    )

    return (
        <ScopingFrameworkAndPerformanceStandardContext.Provider value={memoized}>{children}</ScopingFrameworkAndPerformanceStandardContext.Provider>
    )
}

ScopingFrameworkAndPerformanceStandardProvider.propTypes = {
    children: PropTypes.node
}