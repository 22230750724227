import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { CLANestedDataGrid } from '@ais/components';
import styles from './styles'
import { usePriorPeriodComparisonTransactionLevel } from "@services/forms/priorperiodcomparison"
import { useLoading } from '@hooks/index';
import CLATruncatedTextWithTooltip from "@components/CLATruncatedTextWithTooltip/CLATruncatedTextWithTooltip";
import * as CustomFormConstants from "@constants/customForm/index";
import { format, parseISO } from "date-fns";
import { formatMoneyToNegativeDashed } from "@utilities/numberHelper";

const CPPPTransactionLevel = ({ correlationDetailId, accountNumber }) => {
  const [rows, setRows] = useState([])
  const [ isLoading, setIsLoading ] = useState(true)
  const { projectId } = useParams()
  const { data: dataTransactionsRowData, isLoading: isLoadingTransactionsRowData, isRefetching } = usePriorPeriodComparisonTransactionLevel(
    correlationDetailId,
    accountNumber,
    projectId
  );

  const updateRowDataFormat = (data) => {
    if (!data || !data.length) return [];
    return data.map((value, index) => ({
      id: index,
      TransactionReference: value.transactionId ? value.transactionId : "",
      TransactionDate: !!value.effectiveDate ? format(parseISO(value.effectiveDate), "MM/dd/yyyy") : "",
      TransactionDebitAmount: value.amount ? (value.amountCreditDebitIndicator) ? 
      ( value.amountCreditDebitIndicator === CustomFormConstants.TRANSACTION_DEBIT 
        ? formatMoneyToNegativeDashed(value.amount)
        : '---') : "" : "",
      TransactionCreditAmount: value.amount ? (value.amountCreditDebitIndicator) ? 
      (value.amountCreditDebitIndicator === CustomFormConstants.TRANSACTION_CREDIT
      ? formatMoneyToNegativeDashed(value.amount)
      : '---' ) : "" : "",
      TransactionDescription: (value.jeLineDescription) ? value.jeLineDescription : value.jeHeaderDescription,
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    if (isLoadingTransactionsRowData || isRefetching) return
    setRows(updateRowDataFormat(dataTransactionsRowData?.data) || [])

    setIsLoading(false);

    return () => {
      setRows([])
    }
  }, [ dataTransactionsRowData, isLoadingTransactionsRowData, isRefetching ]);

  useEffect(() => {
    return () => {
      setRows([])
    }
  }, []);

  const columns = useMemo(() => [
    {
      field: 'TransactionDate',
      headerName: 'Transaction Date',
      flex: 1,
      maxWidth: 195,
      minWidth: 195,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
    },
    {
      field: 'TransactionReference',
      headerName: 'Transaction Reference',
      flex: 1,
      maxWidth: 195,
      minWidth: 195,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
    },
    {
      field: 'TransactionDescription',
      headerName: 'Transaction Description',
      flex: 1,
      maxWidth: 450,
      minWidth: 450,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderCell: params => (
        (params?.row?.TransactionDescription.length <= 70)
          ? params?.row?.TransactionDescription
          : <CLATruncatedTextWithTooltip
            text={`${params?.row?.TransactionDescription}`}
            length={70}
          />
      )
    },
    {
      field: 'TransactionDebitAmount',
      headerName: 'Debit Amount',
      flex: 1,
      maxWidth: 245,
      minWidth: 245,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderCell: params => (
        <div>
          {
            (params.row.TransactionCreditAmount > 0)
              ? '--'
              : params?.row?.TransactionDebitAmount?.toLocaleString('en-US', { maximumFractionDigits: 2 })
          }
        </div>
      )
    },
    {
      field: 'TransactionCreditAmount',
      headerName: 'Credit Amount',
      flex: 1,
      maxWidth: 292,
      minWidth: 292,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderCell: params => (
        <div>
          {
            (params.row.TransactionDebitAmount > 0)
              ? '--'
              : params?.row?.TransactionCreditAmount?.toLocaleString('en-US', { maximumFractionDigits: 2 })
          }
        </div>
      )
    },
  ], [ correlationDetailId, accountNumber ])

  return (
    <div style={{ padding: "5px 0px 0px 3.5em" }}>
      <CLANestedDataGrid
        sx={styles}
        rows={rows}
        columns={columns}
        rowHeight={50}
        headerHeight={60}
        rowsPerPageOptions={[10, 20, 50]}
        loading={isLoading}
      />
    </div>
  )
}
// MuiDataGrid-row
export default CPPPTransactionLevel