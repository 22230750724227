import formApi from "@utilities/claApiForm";
import { useQuery } from "@tanstack/react-query";
import logger from '@utilities/logService'

export const fetchEventConditionsByMethodologyVersion = async (methodologyVersionId) => {
  try {
    const { data } = await formApi.get(`/eventconditions/methodologyversion/${methodologyVersionId}`);
    return data;
  } catch (error) {
    logger.error(error);
  }
};

// EXPORTS ====================================================================
export const useGetEventConditionsByMethodologyVersion = (methodologyVersionId, isDataLoaded) => {
  return useQuery(
    ["EventConditionsByMethodologyVersion", methodologyVersionId],
    () => fetchEventConditionsByMethodologyVersion(methodologyVersionId),
    {
      enabled: !!methodologyVersionId && isDataLoaded,
    }
  );
};

export const fetchAllEventConditionsByMethodologyVersion = async (methodologyVersionId) => {
  try {
    const { data } = await formApi.get(`/alleventconditions/methodologyversion/${methodologyVersionId}`);
    return { status: 200, data };
  } catch (error) {
    return { status: 400, message: error };
  }
}

export const upsertEventConditions = async (methodologyVersionId, body) => {
  try {
      const response = await formApi.put(`/eventconditions/methodologyversion/${methodologyVersionId}`, body);
      return { status: 200, data: response.data };
  } catch (error) {
      return { status: 400, message: error };
  }
}