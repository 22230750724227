import {CLASelect} from '@ais/forms';
import React from "react"
import * as Constants from "@constants/index"

const CLAPriorPeriodSelect = (props) => {
    const { onSelectionChange, defaultValues, onClose, disabled, onOpen, isIdle } = props
    const {
        ID,
        PLACEHOLDER,
        PRIOR_PERIOD,
        OPTIONS
    } = Constants.PRIOR_PERIOD.EN
    return (
        <div>
            <CLASelect
                id={ID}
                name={PRIOR_PERIOD}
                label={PRIOR_PERIOD}
                placeholder={PLACEHOLDER}
                defaultValues={defaultValues}
                menuItems={OPTIONS}
                truncateDisplay={true}
                onOpen={onOpen}
                onClose={onClose} 
                onChange={(values) => {
                    if (onSelectionChange) {
                        onSelectionChange(values)
                    }
                }}
                isDisabled={disabled}
                isIdle={isIdle}
                uncontrolled
            />
        </div>
    )

}

export default CLAPriorPeriodSelect