import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Button, Box } from "@mui/material/";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import IconButton from "@mui/material/IconButton";

// CSS
import "./CLANestedDataGrid.css";

export const DataGridPaginationAction = (props) => {
  const { rows, setRowsPerPage, count, page, rowsPerPage, onPageChange } = props;

  const theme = useTheme();
  const [pageNumber, setPageNumber] = useState(page);
  const [pageCount, setPageCount] = useState((
    parseFloat(rows?.length) % parseInt(rowsPerPage) === 0
      ? parseFloat(rows?.length) / parseInt(rowsPerPage)
      : Math.floor(parseInt(rows?.length) / parseInt(rowsPerPage)) + 1
  )-1);

  useEffect(() => {
    setPageCount(
      (parseFloat(count) % parseInt(rowsPerPage) === 0
        ? parseFloat(count) / parseInt(rowsPerPage)
        : Math.floor(parseInt(count) / parseInt(rowsPerPage)) + 1
    )-1);
    setRowsPerPage(rowsPerPage);
  }, [rowsPerPage]);

  const handleBackButtonClick = () => {
    onPageChange(pageNumber - 1);
    setPageNumber(pageNumber - 1);
  };

  const handleBackFirstButtonClick = () => {
    onPageChange(0);
    setPageNumber(0);
  }

  const handleNextButtonClick = () => {
    onPageChange(pageNumber + 1);
    setPageNumber(pageNumber + 1);
  };

  const handleNextLastButtonClick = () => { 
    onPageChange(pageCount);
    setPageNumber(pageCount);
  }

  const handlePageButtonClick = (event) => {
    onPageChange(parseInt(event.target.dataset.page));
    setPageNumber(parseInt(event.target.dataset.page));
  };

  const getButtons = () => {
    const content = [];
    const minPage = 5;
    let startIndex =  0;
    let endIndex = pageCount;

    if (!rows.length)
      return content;

    if (pageCount > minPage) {
      const minimumPagination = page >= 3 && (pageCount - page) >= 3 ? 3 : 5;
      startIndex =  Math.max(0, page - Math.floor(minimumPagination / 2));
      endIndex = Math.min(startIndex + minimumPagination - 1, pageCount);
      startIndex = endIndex === pageCount ? pageCount - (minimumPagination - 1) : startIndex;
    }

    if (startIndex > 1) {
      content.push(
        <Button
          key={0}
          data-page={0}
          size="small" 
          className={pageNumber === 0 ? "pageButtonSelected" : "pageButton"}
          onClick={handlePageButtonClick}
        >
          1
        </Button>
      )
      content.push(
        <Button
          key={`first-ellips`}
          size="small"
          className='ellipses'
        >
          ...
        </Button>
      );
    }

    for (let i = startIndex; i <= endIndex; i++) {
      content.push(
        <Button
          key={i}
          data-page={i}
          size="small"
          className={pageNumber === i ? "pageButtonSelected" : "pageButton"}
          onClick={handlePageButtonClick}
        >
          {i + 1}
        </Button>
      );
    }

    if (endIndex < pageCount) {
      content.push(
        <Button
          key={`last-ellips`}
          size="small"
          className='ellipses'
        >
          ...
        </Button>
      );
      content.push(
        <Button
          key={999} 
          data-page={pageCount}
          size="small" 
          className={pageNumber === pageCount ? "pageButtonSelected" : "pageButton"}
          onClick={handlePageButtonClick}
        >
          {pageCount + 1}
        </Button>
      );
    }

    return content;
  };

  const iconButtonStyle = {
    padding: 0, 
    color: '#2E334E', 
    width: '25px',
    height: '25px',
    '& svg': {
      width: '100%',
      height: '100%'
    }
  };

  return (
    <Box className='actionContainer'>
      <div className='actionButtons'>
        <IconButton
              onClick={() => handleBackFirstButtonClick()}
              disabled={page === 0}
              aria-label="previous page"
              sx={iconButtonStyle}
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick} 
          disabled={page === 0} 
          aria-label="previous page"
          sx={iconButtonStyle}>
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
      </div>
      <div className='buttonPageContainer'>
        {getButtons()}
      </div>
      <div className='actionButtons'>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          sx={iconButtonStyle}
        >
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
						onClick={() => handleNextLastButtonClick()}
						disabled={page >= Math.ceil(count / rowsPerPage) - 1}
						aria-label="next page"
            sx={iconButtonStyle}
					>
						{theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/> }
					</IconButton>
      </div>
    </Box>
  );
};

DataGridPaginationAction.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
};

export default DataGridPaginationAction;
