import { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm, useWatch } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@ais/palette";

import { SchemaContext } from '@ais/providers';
import {
  createField,
  dropNewItem,
  updateFormItem,
  filterEmptyCriterias,
  isTriggeredFromQuestionGroup,
  getShareableComponentDefaultValue
} from '@components/Forms/helpers';
import { BaseDialog } from '.';
import {
  BasicTextField,
  ToggleableTextarea,
  RadioSelect,
  ExternalAccess,
} from './dialog-components/settings';
import { RulesTab } from './dialog-components/rules';
import { DIALOG, TRIGGERS } from '@constants/forms';

const widthOptions = [
  {
    label: '25%',
    value: 25,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '75%',
    value: 75,
  },
  {
    label: '100%',
    value: 100,
  },
];

export const DatePickerDialog = ({
  visible,
  setVisible,
  additionalData,
  trigger  
}) => {
  const { LABEL, PLACEHOLDER } = DIALOG.SETTINGS;
  const { schema, dispatchSchema } = useContext(SchemaContext);

  const initialValues = {
    label: additionalData?.label ?? '',
    required: additionalData?.required ?? true,
    placeholder: additionalData?.placeholder ?? '',
    tooltip: additionalData?.tooltip ?? '',
    width: additionalData?.width ?? 100,
    rules:
      typeof additionalData?.rules === 'object'
        ? additionalData.rules
        : {
            behavior: 'show',
            match: 'any',
            criterias: [],
            excludeAnswerOnRollForward: false,
            canNotBeDeleted: true,
          },
    visibleToClient: getShareableComponentDefaultValue(additionalData, "visibleToClient", trigger),
    editableByClient: getShareableComponentDefaultValue(additionalData, "editableByClient", trigger),
  };

  const schemaValidation = Yup.object().shape({
    label: Yup.string().trim().required('')
  })

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schemaValidation)
  });

  const watchValidation = useWatch({ control: formMethods.control, name: 'validation' }) ?? 'none';
  const handleSubmit = (values) => {
    const filteredRules = filterEmptyCriterias(values.rules);
    if (![TRIGGERS.EDIT_BTN, TRIGGERS.QUESTION_GROUP_EDIT].some((t) => t === trigger)) {
      const newField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.refField.index,
        ...values,
        width: +values.width,
        rules: filteredRules,
      });
      dropNewItem(additionalData, newField, trigger, schema, dispatchSchema);
    } else {
      const updateField = createField({
        type: additionalData.type,
        id: additionalData.id,
        index: additionalData.index,
        ...values,
        width: +values.width,
        rules: filteredRules,
      });
      updateFormItem(additionalData, updateField, schema, dispatchSchema);
    }
    setVisible(false);
  }

  useEffect(() => {
    formMethods.reset(initialValues);
    formMethods.trigger();
  }, [visible]);

  const tabs = [
    {
      label: LABEL.SETTINGS,
      render: () => (
        <Grid container rowSpacing={6} columnSpacing={22}>
          <Grid item xs={12}>
            <BasicTextField
              name="label"
              label={LABEL.QUESTION_LABEL}
              placeholder={PLACEHOLDER.QUESTION}
              maxLength={256}
              showRequired
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <BasicTextField
                  name="placeholder"
                  label={LABEL.PLACEHOLDER_TEXT}
                  placeholder={PLACEHOLDER.TEXT}
                  maxLength={256}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container height="100%">
              <Grid item xs={12}>
                <ToggleableTextarea
                  name="tooltip"
                  label={LABEL.ADD_TOOLTIP}
                  placeholder={PLACEHOLDER.TEXT}
                  toggleable
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <RadioSelect
                  name="width"
                  label={LABEL.DISPLAY_WIDTH}
                  options={widthOptions}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            {!isTriggeredFromQuestionGroup(trigger) && <ExternalAccess
              allowExternalAccessName="visibleToClient"
              makeAnswerableName="editableByClient"
            />}
          </Grid>
        </Grid>
      ),
    },
    {
      label: LABEL.RULES,
      render: () => (
        <RulesTab
          additionalData={additionalData}
          trigger={trigger}
        />
      ),
    },
  ];
  return (
    <Form form={formMethods}>
      <BaseDialog
        id={isTriggeredFromQuestionGroup(trigger) ? '' : additionalData?.id}
        idPrefix={isTriggeredFromQuestionGroup(trigger) ? '' : LABEL.QUESTION_ID}
        title={LABEL.DATE_PICKER}
        visible={visible}
        setVisible={setVisible}
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        tabs={isTriggeredFromQuestionGroup(trigger) ? tabs.filter((tab) => tab.label !== LABEL.RULES) : tabs}
      />
    </Form>
  );
};
