export const PRIOR_PERIOD = { 
    EN: {
      ID: "prior-period",
      PRIOR_PERIOD: "Prior Period",
      PLACEHOLDER: "Select to compare",
      OPTIONS: [
        {
            value: 1, 
            label: 'Prior Period 1', 
            color: '#2e334e',
            code: 'PP1'
        },
        {
            value: 2, 
            label: 'Prior Period 2', 
            color: '#34A0A2',
            code: 'PP2'
        },
        {
            value: 3, 
            label: 'Prior Period 3', 
            color: '#fbc55a',
            code: 'PP3'
        },
        {
            value: 4, 
            label: 'Prior Period 4', 
            color: '#535c8d',
            code: 'PP4'
        },
      ]
    }
  }

export const CURRENT_PERIOD ={
  EN: {
    value: "Current Period",
    label: "Current Period Activity",
    color: "#8C93B8",
    code: "CP"
  }
}