import React from 'react';
import {TableRow, TableCell, Typography} from '@mui/material';
import { CLATooltip } from "@ais/components"
import string from "@utilities/stringHelper.js";

import {ActionCell} from './ActionCell';
import {renderField} from '../helpers';

export const CLAEvaluationOfDeficienciesCombinationRow = ({
  row, 
  columns, 
  isDisabled,
  onCombinationCheckboxChange,
  onCombinationDeleteClick,
  onCombinationEditClick,
  highlightedDeficiencyCombinationIds
}) => {
  const activateTooltip = (child, value) => {
    return (
      <CLATooltip title={value} placement="bottom">
        {child}
      </CLATooltip>
    );
  }
  const shouldHighlight = highlightedDeficiencyCombinationIds.includes(row.ProjectDeficiencyCombinationId);
  const renderTableCell = () => {
    const combinationColumns = [...columns];
    combinationColumns.shift()
    combinationColumns.unshift(      
      {
        type: 'LIST',
        field: 'DeficiencyName',
        headerName: 'Deficiency Name',
        sortable: true,
        filterable: true,
        flex: 1,
        attributes: {
        }
      }
    );

    return combinationColumns.map((column, index) => {
      const { type, field, attributes, maxWidth, minWidth } = column;
      const value = row[field];            
      const data = {
        type,
        value,
        field,
        row,
        column,
        onCombinationCheckboxChange,
        highlightedDeficiencyCombinationIds
      };


      return (
        <TableCell
          key={index}
          className={'MuiDataGrid-columnHeader MuiDataGrid-columnHeaderTitleContainer'}
          sx={{
            p: '0',
            display: 'flex',
            maxWidth: maxWidth,
            minWidth: minWidth,
            flexGrow: 1,
            border: 0,
            alignSelf: 'start',
            mt: '20px'
          }}>
          {type !== 'LIST' && renderField(data, attributes, onCombinationCheckboxChange, isDisabled)}
          {type === 'LIST' && (
            <>
              <ActionCell 
                onDeleteClick={() => onCombinationDeleteClick(row)} 
                onEditClick={() => onCombinationEditClick(row)}
                disabled={isDisabled}
              />
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden'
              }}>
                <Typography sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                  m: '0px 20px 5px 0px'
                }}>
                  {row.CombinationName}
                </Typography>
                <div style={{
                  display: 'inline-block',
                  margin: '0px 20px 20px 10px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}>
                  {row.Deficiencies.map((item, index) => {
                    const truncate = string.truncateWithBool(item.DeficiencyName, 15);
                    const component = (
                      <Typography key={index} sx={{
                        fontWeight: 600,
                        fontSize: '14px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        mt: '5px'
                      }}>
                        {item.DeficiencyName}
                      </Typography>
                    );
                    return (truncate.isTruncated ? activateTooltip(component, item.DeficiencyName) : component)
                  })}
                </ div>
              </div>
            </>
          )}
        </TableCell>
      )
    })
  };

  return (
    <TableRow
      id={row.id}
      className={'MuiDataGrid-columnHeadersInner'}
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
        gridTemplateColumns: '16.67%',
        p: '0 10px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        ...(shouldHighlight && {
          border: '4px dashed red'
      })
      }}
    >
      {renderTableCell()}
    </TableRow>
  )
};