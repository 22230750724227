
import { Button } from "@mui/material";
import { styled } from "@mui/system"

export const Flex = styled("div")`
    display: flex;
    flex-direction: column;
`;

export const FlexRow = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const MainContainer = {
    width: "45%",
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
        padding: "20px",
        width: "40%",
        backgroundColor: "#E4E0E0"
    }
}

export const CloseButton = {
    position: "relative", 
    marginTop: "0px"
}

export const ConfirmButton = styled(Button)`
    display: flex;
    padding: 10px 30px;
    backgroundcolor: #2e334e;
    color: #fff;
    min-width: 105px;
`;

export const CancelButton = styled(Button)`
    display: flex;
    padding: 10px 30px;
    backgroundcolor: #f6f6f6;
    bordercolor: #cbcbcb;
    color: #4b4b4b;
    min-width: 105px;
`;