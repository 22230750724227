import { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const FormInstanceBaseDialog = (props) => {
  const {
    title,
    visible,
    onCancel,
    setVisible,
    loading,
    formMethods,
    onSubmit,
    children,
    customModalButton,
    disableSave,
  } = props;

  useEffect(() => {
    if (!visible) formMethods?.reset();
  }, [visible]);

  return (
    <Dialog open={visible} fullWidth scroll="body">
      <form onSubmit={formMethods?.handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            '&.MuiDialogTitle-root': {
              p: 4,
            },
          }}
        >
          <Typography variant="title">{title}</Typography>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions
          sx={{
            '&.MuiDialogActions-root': {
              py: 4,
            },
          }}
        >
          {customModalButton ? (
            customModalButton
          ) : (
            <div>
              <Button
                variant="outlined"
                onClick={() => {
                  setVisible(false);
                  onCancel && onCancel();
                }}
                style={{ marginRight: '20px' }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
                disabled={!formMethods?.formState?.isValid || disableSave}
              >
                Save
              </LoadingButton>
            </div>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

FormInstanceBaseDialog.defaultProps = {
  loading: false,
  disableSave: false,
};
