export const AccountAssertionLevelRiskReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_OR_ADD_CHILD':
          const childIndex = state.children.findIndex(child => child.id === action.child.id);
          let updatedChildren;
          if (childIndex !== -1) {
              // Update existing child
              updatedChildren = state.children.map(child =>
                  child.id === action.child.id ? { ...child, expanded: action.child.expanded } : child
              );
          } else {
              // Add new child
              updatedChildren = [...state.children, action.child];
          }
          return {
              ...state,
              children: updatedChildren,
              allExpanded: updatedChildren.every(child => child.expanded)
          };
        case 'EXPAND_ALL':
          return {
            ...state,
            children: state.children.map(child => ({ ...child, expanded: true })),
            allExpanded: true
          };
        case 'COLLAPSE_ALL':
          return {
            ...state,
            children: state.children.map(child => ({ ...child, expanded: false })),
            allExpanded: false
          };
    }
};
