import React from 'react'
import { Box } from '@mui/material';
import { FormulaComponent } from './FormulaComponent';

export const FormulaBar = ({
    formula,
    active,
    onHover,
    onDropOutside,
    currentHoverIndex, 
    isConditional,
    onAddFormulaConditions,
    resetCanvas
}) => {
    return (
        <>
            {formula.map((item, index) => (
                <React.Fragment key={index}>
                    {index === 0 && currentHoverIndex === 0 && (!isConditional || isConditional && active) ? <Box sx={{ width: '45px', height: '45px', border: '1px dashed black' }} /> : null}
                        <FormulaComponent                            
                            item={item}
                            index={index}
                            onHover={onHover}
                            onDropOutside={onDropOutside}                            
                            active={active}
                            isConditional={isConditional}
                            onAddFormulaConditions={onAddFormulaConditions}
                            resetCanvas={resetCanvas}
                        />
                    {currentHoverIndex === index + 1 && (!isConditional || isConditional && active) ? <Box sx={{ width: '45px', height: '45px', border: '1px dashed black' }} /> : null}
                </React.Fragment>
            ))}
        </>
    )
}