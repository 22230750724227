import { USER_FORM_TYPE_ID } from './user'
import { INTERNAL_CONTROLS_FORM_TYPE_ID } from './internalcontrols';

export const FORM_SETTINGS = {
  EN: {
    FormCategoryOptions: [
      { value: 1, text: 'Reports and Other Deliverables' },
      { value: 2, text: 'Engagement Conclusions' },
      { value: 3, text: 'Engagement Reviewer Checklists' },
      { value: 4, text: 'Pre-Engagement Procedures' },
      { value: 5, text: 'Risk Assessment Procedures' },
      { value: 6, text: 'Evaluating Internal Control' },
      { value: 7, text: 'Performing Other Required Audit Procedures' },
      { value: 8, text: 'Financial Statement Preparation Review and Analysis' },
      { value: 9, text: 'Group Audits' },
      { value: 10, text: 'Trial Balance' },
      { value: 11, text: 'Assets' },
      { value: 12, text: 'Liabilities' },
      { value: 13, text: 'Equity' },
      { value: 14, text: 'Revenue' },
      { value: 15, text: 'Expense' },
      { value: 16, text: 'Other Income and Expense' },
      { value: 17, text: 'Income Tax Provision and Accruals' },
    ],
    MethodologyVersionStatus: {
      Draft: 1,
      Released: 3
    },
		FormSignOffLevel: {
			Preparer: 1,
			Reviewer1: 2,
			Reviewer2: 3,
			Reviewer3: 4
		},
    FormSignOffLevelName: {
			1: "Preparer",
			2: "Reviewer 1",
		},
    Errors: {
      FormNameRequiredError: 'formName is required',
      FormCategoryRequiredError: 'formCategory is required',
      MethodologyVersionRequiredError: 'methodologyVersion is required',
      IndustryRequiredError: 'industries is required',
      DUPLICATE_FORM_NAME: 'DUPLICATE_FORM_NAME',
      DuplicateFormNameError: 'Duplicate form name.'
    },
    Labels: {
      formName: 'Form Name',
      formDescription: 'Form Description (Optional)',
      formCategory: 'Form Category',
      industry: 'Industry',
      methodologyVersion: 'Methodology Version:',
      status: 'Status',
      addToolTip: 'Add Tooltip Instructions (Optional)',
      formatText: 'Format Text',
      title: 'Form Settings',
      formId: 'Form ID',
      formIdMessage: ' (Will be generated once saved)',
    },
    Tooltips: {
      industry: `By checking "Default" the form will appear in<br/>the default set of forms when a project is<br/>created according to the assigned industry.<br/><br/>By checking "Required" the form can not be<br/>deleted from the project.`,
    },
    StatusConfirmModal: {
      title: 'Confirmation',
      cancelText: 'No',
      confirmText: 'Yes',
      message:
        'Are you sure you want to change the status from Approve to Draft?',
    },
    PlaceHolders: {
      text: 'Text',
      select: 'Select',
    },
    Buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },
    MultiSelect: {
      all: 'All',
    },
    SHAREABLE_FORMS: [USER_FORM_TYPE_ID, INTERNAL_CONTROLS_FORM_TYPE_ID],
    SHARE_FORM_BUTTON_TEXT: "Share form with the client",
    REMOVE_CLIENT_ACCESS: "Remove client access",
    CLIENT_ACCESS_MODAL : {
      MODE: {
        SHARE: "share",
        REMOVE_SHARE: "removeShare"
      },
      SELECT_DUE_DATE: "Please select a due date",
      SELECT_DATE_PLACEHOLDER: "Select Date",
      SHARE_FORM_MODAL_TITLE: "Share Form",
      CANCEL: "Cancel",
      SHARE: "Share",
      SUCCESS_TITLE: "Success!",
      SUCCESS_SHARE_MESSAGE: "Form has been shared successfully.",
      CLIENT_PROJECT_FORM_STATUS: {
        NOT_STARTED: 1,
        IN_PROGRESS: 2,
        SUBMITTED: 3
      },
      CLIENT_ACCESSIBLE: 1,
      CLIENT_NOT_ACCESSIBLE: 0,
      WARNING: "Warning",
      REMOVE: "Remove",
      REMOVE_MESSAGE: "Are you sure you want to remove client access?",
      REMOVE_SUCCESS: "Form sharing has been removed successfully."
    }
  },
};
