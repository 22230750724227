import update from 'immutability-helper';
import logger from '@utilities/logService'

export const formatProjectInternalControl = (queriedProjectInternalControl) => {
  const projectInternalControl = queriedProjectInternalControl?.[0];

  // Parse first level fields
  const parsedData = update(projectInternalControl, {
    ProjectInternalControlProjectKeyControl: { $apply: (data) => data ? JSON.parse(data) : [] },
    ProjectScopeAuditArea: { $apply: (data) => JSON.parse(data) }
  });

  /**
   * Parse these nested fields:
   * parsedData.ProjectScopeAuditArea[index].ProjectScopeAuditAreaSCOTABDS[index]
   *  .AccountBalanceTypeList and
   *  .AssertionList
   */
  parsedData?.ProjectScopeAuditArea?.forEach((auditArea) => {
    auditArea?.ProjectScopeAuditAreaSCOTABDS?.forEach((scotabd) => {
      const { AccountBalanceTypeList, AssertionList, ProjectScopeAuditAreaSCOTABDProjectKeyControl } = scotabd;
      scotabd.AccountBalanceTypeList = AccountBalanceTypeList?.length > 0 ? JSON.parse(AccountBalanceTypeList) : [];
      scotabd.AssertionList = AssertionList?.length > 0 ? JSON.parse(AssertionList) : [];
      scotabd.ProjectScopeAuditAreaSCOTABDProjectKeyControl = ProjectScopeAuditAreaSCOTABDProjectKeyControl ?? []
    });
  });

  parsedData?.History?.forEach((history) => {
    try{
      history.ProjectScopeAuditAreaHistory = JSON.parse(history.ProjectScopeAuditAreaHistory) || [];
      history.ProjectScopeAuditAreaSCOTABDHistory = JSON.parse(history.ProjectScopeAuditAreaSCOTABDHistory) || [];
    }
    catch(err){
      logger.error(err)
    }
  });
  
  return parsedData;
};

const modifyProjectKeyControl = (projKeyControl) => {
  const { IsDesignedEffectively, IsImplementedEffectively, WalkthroughComment, ...requiredProjectKeyControlFields } =
    projKeyControl;

  const noRiskNameProjectRisks =
    requiredProjectKeyControlFields.ProjectKeyControlProjectRisk?.map((projectRisk) => {
      const { RiskName, ...requiredProjRiskFields } = projectRisk;
      return requiredProjRiskFields;
    }) || [];

  return {
    ...requiredProjectKeyControlFields,
    ProjectKeyControlProjectRisk: noRiskNameProjectRisks
  };
};

export const formatICAuditAreaPayload = (internalControlData) => {
  const {
    ...requiredFirstlevelFields
  } = internalControlData;

  const newProjectInternalControlProjectKeyControl =
    requiredFirstlevelFields?.ProjectInternalControlProjectKeyControl?.map((ICProjKeyControl) => {
      const modifiedProjectKeyControl =
        ICProjKeyControl.ProjectKeyControl?.map((projKeyControl) => modifyProjectKeyControl(projKeyControl)) || [];

      return {
        ...ICProjKeyControl,
        ProjectKeyControl: modifiedProjectKeyControl
      };
    }) || [];

  const newPSAASProjectKeyControls = [];

  // Gather and push all required fields for ProjectScopeAuditAreaSCOTABDProjectKeyControl field for payload
  requiredFirstlevelFields.ProjectScopeAuditArea?.forEach((auditArea) => {
    auditArea.ProjectScopeAuditAreaSCOTABDS?.forEach((scotabd) => {
      const result = {}

      result.ProjectScopeAuditAreaSCOTABDId = scotabd.ProjectScopeAuditAreaSCOTABDId;
      result.ICInfoProcessingAndControlActivity = scotabd.ICInfoProcessingAndControlActivity;
      result.ICComment = scotabd.ICComment;
      result.IsServiceOrganization = auditArea.IsServiceOrganization;

      scotabd?.ProjectScopeAuditAreaSCOTABDProjectKeyControl?.forEach((PSAASprojKeyControl) => {
        result.ProjectScopeAuditAreaSCOTABDProjectKeyControlId = PSAASprojKeyControl.ProjectScopeAuditAreaSCOTABDProjectKeyControlId;

        PSAASprojKeyControl.ProjectKeyControl?.forEach((projKeyControl) => {
          const modifiedProjectKeyControl = modifyProjectKeyControl(projKeyControl);

          result.ProjectKeyControl = modifiedProjectKeyControl;
        });
      });
      newPSAASProjectKeyControls.push(result)
    });
  });

  // Remove ProjectScopeAuditAreaSCOTABDS field just before getting final payload object
  const { ProjectScopeAuditArea, ...requiredFirstlevelFields_NoProjectScopeAuditArea } = requiredFirstlevelFields;

  const payload = {
    ...requiredFirstlevelFields_NoProjectScopeAuditArea,
    ProjectScopeAuditAreaSCOTABDProjectKeyControl: newPSAASProjectKeyControls,
    ProjectInternalControlProjectKeyControl: newProjectInternalControlProjectKeyControl
  };

  return payload;
};

export const renderCommonDatagridHeader = (params) => (
  <span style={{ fontWeight: 700 }}>{params.colDef?.headerName}</span>
);

export const getRowClassName = (params, arrayData, expandedRows, type, auditAreaIndex = null) => {
  const AUDIT_AREAS = 1;
  const SCOTABDS = 2;

  const isCellLast = params?.row?.index === arrayData?.length - 1;
  const isCellExpanded = expandedRows.indexOf(params?.row?.id) !== -1;
  const borderBottomShouldShow = !isCellLast && !isCellExpanded && arrayData?.length > 1;

  let indexOfPrevCell;
  let isPrevCellExpanded;

  if (type === AUDIT_AREAS) {
    indexOfPrevCell = params?.row?.id?.slice(10) - 1;
    isPrevCellExpanded = expandedRows.indexOf(`AuditArea-${indexOfPrevCell}`) !== -1;
  } else if (type === SCOTABDS) {
    indexOfPrevCell = params?.row?.id?.slice(20) - 1;
    isPrevCellExpanded = expandedRows.indexOf(`AuditArea-${auditAreaIndex}-Scotabd-${indexOfPrevCell}`) !== -1;
  } else {
    logger.error('Provided type does not exist for function getRowClassName');
    return;
  }

  // Check './styles.css' for these class names
  if (borderBottomShouldShow && !isPrevCellExpanded) return 'border-bottom-gray';
  if (!borderBottomShouldShow && isPrevCellExpanded) return 'border-top-gray';
  if (borderBottomShouldShow && isPrevCellExpanded) return 'border-y-gray';
  if (!borderBottomShouldShow && !isPrevCellExpanded) return null;
};

export const formatProjectInternalControlProjectKeyControl = (fields) => {
  return fields.map(
    ({
      ProjectInternalControlProjectKeyControlId,
      IsJournalEntry,
      IsFinancialStatement,
      ProjectKeyControlName,
      ProjectKeyControlDescription,
      IsITApplicationAssociated,
      ITApplicationAssociatedComment,
      ProjectKeyControl: OldProjectKeyControl,
    }) => ({
      ProjectInternalControlProjectKeyControlId,
      IsJournalEntry,
      IsFinancialStatement,
      ProjectKeyControl: OldProjectKeyControl.map((keyControl) => ({
        ...keyControl,
        ProjectKeyControlName,
        ProjectKeyControlDescription,
        IsITApplicationAssociated,
        ITApplicationAssociatedComment: ITApplicationAssociatedComment ?? null,
      })),
    })
  );
};

export const formatProjectScopeAuditAreaSCOTABDProjectKeyControl = (fields, projectRisks) => {

  return fields.map(
    ({
      ProjectScopeAuditAreaSCOTABDProjectKeyControlId,
      ProjectScopeAuditAreaSCOTABDId,
      ProjectKeyControlName,
      ProjectKeyControlDescription,
      NewProjectKeyControlProjectRisk,
      IsITApplicationAssociated,
      ITApplicationAssociatedComment,
      ICInfoProcessingAndControlActivity,
      ICComment,
      IsServiceOrganization,
      ProjectKeyControl: OldProjectKeyControl,
    }) => ({
      ProjectScopeAuditAreaSCOTABDProjectKeyControlId,
      ProjectScopeAuditAreaSCOTABDId,
      ICInfoProcessingAndControlActivity: ICInfoProcessingAndControlActivity ?? "",
      ICComment: ICComment ?? "",
      IsServiceOrganization: IsServiceOrganization ?? false,
      ProjectKeyControl: OldProjectKeyControl.map((keyControl) => {
        if (NewProjectKeyControlProjectRisk?.length) {
          const reducedProjectRisks =
            keyControl.ProjectKeyControlProjectRisk?.filter((x) =>
              NewProjectKeyControlProjectRisk.includes(x.ProjectRiskId)
            );

          const newRisks = NewProjectKeyControlProjectRisk
            .filter((riskId) => typeof riskId === 'number') // filter out when other relevant assertions is selected
            .map((riskId) => {
              const riskName = projectRisks.find(
                (o) => o.ProjectRiskId === riskId
              )?.RiskName;
              const existingRisks = reducedProjectRisks?.find(
                (o) => o.ProjectRiskId === riskId
              );

              if (existingRisks) {
                existingRisks.RiskName = riskName;
                return existingRisks;
              }

              return {
                ProjectKeyControlProjectRiskId: null,
                ProjectRiskId: riskId,
                RiskName: riskName,
              };
            });

          keyControl.ProjectKeyControlProjectRisk = newRisks;
        }

        return {
          ...keyControl,
          ProjectKeyControlName,
          ProjectKeyControlDescription,
          IsITApplicationAssociated,
          IsCoversOtherRelativeAssertion: NewProjectKeyControlProjectRisk.includes('other-relevant-assertions'),
          ITApplicationAssociatedComment: ITApplicationAssociatedComment ?? null,
        };
      }),
    })
  );
};