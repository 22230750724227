import { useState, useEffect, useCallback, useRef } from "react"
import { useIsMutating } from "@tanstack/react-query"
import { OutlinedInput, InputAdornment } from "@mui/material"

import { FIELD_TYPES } from "@ais/constants"
import { renderLabel } from "@ais/forms";
import { useFinalizedProject } from '@hooks/useProject';
import { useParams } from 'react-router-dom';
import TbLink from "./TbLink";
import { Flex } from "@ais/palette";
import { ClientExternalIcon } from '@ais/forms';
import { NO_GROUP_EXISTING, NO_PERIOD_EXISTING, useTrialBalanceQuery, VALID_TB_DATA, NO_TRIAL_BALANCE } from "./useTrialBalanceData";
import { useSaveProjectUnitAnswer, default as projectFormServices } from '@services/forms/projectforms.js';
import { useProjectFormInstanceProvider } from '@providers';

export const SHORT_ANSWER_MAX_CHARACTERS = 256
export const LONG_ANSWER_MAX_CHARACTERS = 4096

export const TbLinkBaseField = (props) => {

    const { field, defaultValue, disabled, setTextValue, isFormInstance, tbLinkEnabled = false, onChange, answered, units, onBlur, type, numerical = false, isIdle, onDrawerOpen, onUnlockConcurrentField,...inputPassThroughProps } = props
    const isMutating = useIsMutating();	

    const [tbCriteria, setTbCriteria] = useState()
    const [inputValue, setInputValue] = useState()
    const [validInput, setValidInput] = useState(true)
    const [isTBUpdated, setisTBUpdated] = useState(false)
    const [isTbRemoved, setisTbRemoved] = useState(false)
    const inputRef = useRef(null);
    const containerRef = useRef(null);
    const { projectId, projectFormId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);
    const { mutateAsync: saveProjectUnitAnswer, isLoading } = useSaveProjectUnitAnswer();
    const { actions: { updateIsTbLinkDrawerOpened } } = useProjectFormInstanceProvider();
    
    const getInputRef = () => {
         if (field.validation === 'usCurrency' || field.validation === 'numerical'){
            return inputPassThroughProps.inputRef;
         }
         return inputRef;
    };
    useEffect(() => {
        if (isIdle && containerRef.current?.classList.contains('Mui-focused')) { 
            containerRef.current?.classList.remove('Mui-focused');
            getInputRef().current?.blur();
        }
    }, [isIdle])

    useEffect(() => {
        let updatedDefaultValue = defaultValue;

        if (typeof updatedDefaultValue === 'object' && 
            updatedDefaultValue.isConcurrentValue) {
            updatedDefaultValue = JSON.stringify(updatedDefaultValue);
        }

        if (!tbLinkEnabled || !updatedDefaultValue) {
            setInputValue(updatedDefaultValue);
            setValidInput(false);
            return
        }
        try {
            let criteria = JSON.parse(updatedDefaultValue);

            if (criteria !== null && typeof criteria === "object") {
                setTbCriteria(criteria);
                setValidInput(true);
                setisTBUpdated(true);
                
                if (criteria.amountEnding) {
                    setInputValue(criteria.amountEnding);
                } 
            }else {
                setisTBUpdated(false);
                setTbCriteria(null);
                setInputValue(updatedDefaultValue)
                setValidInput(false);
            }
        }catch (error) {
            setInputValue(updatedDefaultValue)
            setValidInput(false);
        }
    }, [defaultValue, tbLinkEnabled])

    const { dataType, amountEnding } = useTrialBalanceQuery(tbCriteria, projectId)

    useEffect(() => {
        let finalValue = amountEnding
        if (isProjectFinalized) {
            try {
                const jsonValue = JSON.parse(defaultValue)
                finalValue = jsonValue?.amountEnding
            }
            catch (error) {
                finalValue = defaultValue
            }
        }
        if (!tbLinkEnabled) {
            return
        }
        if (field.validation !== 'usCurrency' && isTbRemoved) {
            onChange('')
            return
        }
        if (isTBUpdated) {
            if (dataType === VALID_TB_DATA && finalValue) {
                setInputValue(finalValue)
            }
            else if (dataType === NO_TRIAL_BALANCE) {
                setInputValue("No Data Available")
            }
            else if (dataType === NO_PERIOD_EXISTING) {
                finalValue = 0
                setInputValue("No Data Available")
            }
            else if (dataType === NO_GROUP_EXISTING) {
                finalValue = 0
                setInputValue("---")
            }
        }
        if (tbCriteria && typeof tbCriteria === "object") {
            const criteria = tbCriteria
            criteria.amount = finalValue || 0;
            if (isTBUpdated) {
                onChange(JSON.stringify(criteria))
                onBlur && onBlur(JSON.stringify(criteria))
            }
            else {
                setValidInput(false)
                onChange(inputValue)
                onBlur && onBlur(JSON.stringify(criteria))
                setTbCriteria(null)
            }
            return
        }
        
        if(inputValue) {
            onChange(inputValue)
        }
    }, [amountEnding, tbCriteria, dataType, inputValue, isTBUpdated])

    const broadcastConcurrentValue = (answer) => {
        onUnlockConcurrentField({
            questionId: field.id,
            answer,
            prevQuestionId: null
        })
    }

    const handleSave = useCallback(async (formValues, isValid) => {
        if (!isValid) {
            const projectUnitIds = Array.isArray(units) ? units.map(unit => unit.ProjectUnitID) : [units];
            const projectUnitAnswer = {
                projectId: projectId,
                projectUnitIds: projectUnitIds,
                questionId: field.id,
                answer: ''
            };
            await saveProjectUnitAnswer({
                projectId,
                projectFormId,
                unitAnswer: projectUnitAnswer
            });
            
            setisTbRemoved(true);
            setInputValue("");
            setTbCriteria(null);

            handleDrawerClose('');
            broadcastConcurrentValue('')
            onBlur && onBlur('');
            /***Adding focus back to field after tb link is removed and saved */
            containerRef.current?.classList.add('Mui-focused');
            if(getInputRef()) getInputRef().current?.focus();          
            /**end */
            return;
        };
        setisTBUpdated(true);
        setTbCriteria(formValues);
        setisTbRemoved(false);
        const projectUnitAnswer = {
            projectId: projectId,
            projectUnitIds: units.map(
                (item) => item.ProjectUnitID
            ),
            questionId: field.id,
            answer: JSON.stringify(formValues)
        };
       
        if (isValid) {
            await saveProjectUnitAnswer({
                projectId,
                projectFormId,
                unitAnswer: projectUnitAnswer
            });
        };
        broadcastConcurrentValue(JSON.stringify({...formValues, isConcurrentValue: true}));
        handleDrawerClose({...formValues, isConcurrentValue: true});
        onBlur && onBlur(JSON.stringify(formValues));
        /***Adding focus back to field after tb link is saved */
        containerRef.current?.classList.add('Mui-focused');
        if(getInputRef()) getInputRef().current?.focus();
        /**end */

    }, [isTbRemoved, tbCriteria]);

    const isLongAndShortFieldType = (field.type === FIELD_TYPES.LONG_ANSWER || type === FIELD_TYPES.SHORT_ANSWER)
    const VALID_INPUT_PROP = {
        VALUE: "VALUE",
        DEFAULT_VALUE: "DEFAULT_VALUE"
    }
    const conditionalInputValueType = (prop) => {
        if (isLongAndShortFieldType) {
            if (prop === VALID_INPUT_PROP.VALUE) return isTBUpdated || isIdle ? inputValue : defaultValue
            if (prop === VALID_INPUT_PROP.DEFAULT_VALUE) return isTBUpdated ? inputValue : defaultValue
        } else {
            return inputValue
        }
    }

    const handleDrawerOpen = () => {
        onDrawerOpen && onDrawerOpen();
        updateIsTbLinkDrawerOpened(true);
    }

    const handleDrawerClose = (answer) => {
        if (onUnlockConcurrentField) {
            const value = answer !== null || answer !== undefined ? answer : inputValue;

            onUnlockConcurrentField({
                questionId: null,
                answer: JSON.stringify(value),
                prevQuestionId: field.id
            })
        }
        updateIsTbLinkDrawerOpened(false);
    }

    return (
        <OutlinedInput
            {...inputPassThroughProps}
            inputRef={getInputRef()}
            ref={containerRef}
            disabled={disabled}
            readOnly={(tbCriteria?.correlationNameIds?.length > 0)}
            value={conditionalInputValueType(VALID_INPUT_PROP.VALUE)}
            defaultValue={conditionalInputValueType(VALID_INPUT_PROP.DEFAULT_VALUE)}
            onChange={(e) => {
                if(isTbRemoved){
                    onChange && onChange('')
                    setisTbRemoved(false)
                    setisTBUpdated(false)
                }
                if (!tbLinkEnabled) {
                    onChange && onChange(e.target.value)
                    setInputValue(e.target.value)
                    return
                }
                if (tbCriteria && (tbCriteria.amount !== e.target.value)) {
                    setisTBUpdated(true)
                    setInputValue(tbCriteria.amount)
                } else {
                    onChange(e.target.value)
                    setInputValue(e.target.value)
                }
            }}
            onBlur={(e) => {

                if (!tbLinkEnabled) {
                    onBlur && onBlur(e.target.value)
                    return
                }

                if (tbCriteria && !e.target.value) {
                    setisTBUpdated(false)
                }

                if (tbCriteria && field.type === FIELD_TYPES.SHORT_ANSWER && e.target.value !== '---' && (Number(tbCriteria?.amount?.toFixed(2)) !== Number(e.target.value.replaceAll(',', '')))) {
                    setisTBUpdated(false)
                }

                if (tbCriteria && field.type === FIELD_TYPES.LONG_ANSWER && e.target.value !== '---' && (tbCriteria.amount !== Number(e.target.value))) {
                    setisTBUpdated(false)
                }
                setInputValue(e.target.value)
                if (!tbCriteria) onBlur && onBlur(e.target.value)
            }}
            endAdornment={
                <InputAdornment
                    position="end"
                    sx={type === FIELD_TYPES.LONG_ANSWER ? { marginTop: "12px", marginRight: "-5px" } : {}}
                >
                    <Flex direction="row" justify="between" align="center">
                        {tbLinkEnabled ? (
                            <TbLink
                                state={isTbRemoved ? -1 : (validInput ? dataType : -1)}
                                valid={isTbRemoved ? true : (tbCriteria ? dataType === 0 || dataType === 2 : true)}
                                value={defaultValue}
                                onSave={handleSave}
                                criteria={tbCriteria}
                                disabled={disabled}
                                onDrawerOpen={handleDrawerOpen}
                                onDrawerClose={handleDrawerClose}
                            />
                        ) : null}
                        <ClientExternalIcon
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                        />
                    </Flex>
                </InputAdornment>
            }
            sx={type === FIELD_TYPES.LONG_ANSWER ? { alignItems: "flex-start" } : {}}
        />
    )
}

export const NoValidationInput = (props) => {

    const { field, disabled, isFormInstance, onFocus, ...passThroughProps } = props

    return (
        <TbLinkBaseField
            multiline
            notched
            field={field}
            type={field.type}
            minRows={field.type === FIELD_TYPES.SHORT_ANSWER ? 1 : field.minRows}
            maxRows={field.type === FIELD_TYPES.SHORT_ANSWER ? 1 : 20}
            label={!!field?.label ? renderLabel(field.label, field.tooltip) : null}
            placeholder={field.placeholder}
            disabled={disabled}
            inputProps={{
                maxLength: field.type === FIELD_TYPES.SHORT_ANSWER ? SHORT_ANSWER_MAX_CHARACTERS : LONG_ANSWER_MAX_CHARACTERS,
            }}
            sx={field.type === FIELD_TYPES.SHORT_ANSWER ? {
                '& textarea': {
                    whiteSpace: 'nowrap',
                    resize: 'none',
                    overflowX: 'hidden'
                }
            } : undefined}
            onKeyPress={(e) => {
                if (field.type === FIELD_TYPES.SHORT_ANSWER && e.key === 'Enter') {
                    e.preventDefault();
                }
            }}
            tbLinkEnabled={field.type === FIELD_TYPES.LONG_ANSWER && isFormInstance}
            onDrawerOpen={onFocus}
            onFocus={onFocus}
            {...passThroughProps}
        />
    )
}
