import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { ProjectScopeContext } from "@contexts/ProjectScope/ProjectScopeContext"
import formServices from '@services/forms/forms';
import projectScopeAuditAreaCategory from '@services/forms/projectscopeauditareacategory';

export const useProjectScope = () => {

    const { projectFormId, projectId } = useParams();
    const { 
        projectScopeId, 
        auditAreas,
        scotabds,
        financialStatements,
        deletedAuditAreas,
        deletedFinancialStatements,
        performanceStandards,
        frameworks,
        setProjectScopeId,
        setAuditAreas,
        setScotabds,
        setFinancialStatements,
        setDeletedAuditAreas,
        setDeletedFinancialStatements,
        setPerformanceStandards,
        setFrameworks
    } = useContext(ProjectScopeContext)

    const fetchProjectScopeAuditAreaData = async (projectScopeAuditAreaId) => {
        const response = await projectScopeAuditAreaCategory.getProjectScopeAuditAreaCategory(
          projectScopeAuditAreaId,
          projectId
        );
        if (response.status === 200) {
          const financialStatement = response?.data ?? [];
    
          setFinancialStatements((prev) => {
            const index = prev.findIndex(
              (auditArea) => auditArea.ProjectScopeAuditAreaId === projectScopeAuditAreaId
            );
            const financialStatementLines = financialStatement.map((data) => ({
              balance: data.Balance,
              financialStatementCategoryName: data.FinancialStatementCategoryName,
              isUserEnteredCategory: data.IsUserEnteredCategory,
              projectScopeAuditAreaCategoryId: data.ProjectScopeAuditAreaCategoryId,
              transactionCount: data.TransactionCount,
              ValidFrom: data.ValidFrom
            }));
            if (index >= 0) prev[index].FinancialStatementLineItems = financialStatementLines;
            else
              prev = [
                ...prev,
                {
                  ProjectScopeAuditAreaId: projectScopeAuditAreaId,
                  FinancialStatementLineItems: financialStatementLines,
                },
              ];
            return [...prev];
          });
        }
    };
    
    const fetchProjectScopeData = async () => {
        const response = await formServices.getProjectScopeByProjectFormId(projectFormId, projectId);
        if (response.status === 400) return false;
        else {
          setFinancialStatements([])
          await response.data.AuditAreas.map(
              async (auditArea) => await fetchProjectScopeAuditAreaData(auditArea.ProjectScopeAuditAreaId)
          );
          setProjectScopeId(response.data.ProjectScopeId);
          setAuditAreas(response.data.AuditAreas);
          setScotabds(response.data.SCOTABDs);
          setDeletedAuditAreas(response.data.deletedAuditAreas);
          setDeletedFinancialStatements(response.data.deletedProjectScopeAuditAreaCategory);
          setPerformanceStandards(response.data.performanceStandards);
          setFrameworks(response.data.frameworks);
          return true;
        }
    };

    return {
        projectScopeId, 
        auditAreas,
        scotabds,
        financialStatements,
        deletedAuditAreas,
        deletedFinancialStatements,
        performanceStandards,
        frameworks,
        setProjectScopeId,
        setAuditAreas,
        setScotabds,
        setFinancialStatements,
        fetchProjectScopeAuditAreaData,
        fetchProjectScopeData 
    }
}