// common modules
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import { PublicClientApplication, EventType } from '@azure/msal-browser';

// custom modules
import './index.css';
import App from './App.jsx';
import { msalConfig, } from '@utilities/authentication.js';
//import { register as registerServiceWorker } from '@utilities/serviceWorkerRegistration';
import { MsalProvider } from "@azure/msal-react";
import { UserContextProvider } from "@contexts/UserContext";

import { ApplicationProvider } from "@contexts/ApplicationContext";
import { QueryClientProvider } from "@tanstack/react-query"

import { queryClient } from "./routes/AppRoutes"


// =============================================================================
// setup authentication
// =============================================================================
// initialize MSAL client
const msal = new PublicClientApplication(msalConfig);

// enable state tracking from other tabs/windows
msal.enableAccountStorageEvents();

// default MSAL client to first account if present otherwise
if (!msal.getActiveAccount() && msal.getAllAccounts().length > 0) {
  msal.setActiveAccount(msal.getAllAccounts()[0]);
}

// setup login callback
msal.addEventCallback((event) => {

  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    msal.setActiveAccount(event.payload.account);
    window.location.reload();
  }
})


// =============================================================================
// setup service worker
// =============================================================================
// If this is uncommented, the website behavior changes as described here: https://create-react-app.dev/docs/making-a-progressive-web-app/
// Importantly, cached versions of the HTML/scripts/styles are used when the site is visited, and
// any updated resources are downloaded in the background. However, these updated files *are not used*
// until all tabs with the site open are closed and the site is visited again, which presents an unexpected
// user experience. As we have no current use case for offline usage, this is currently disabled.
//registerServiceWorker();


// =============================================================================
// render application
// =============================================================================
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msal}>
      <UserContextProvider>
        <ApplicationProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ApplicationProvider>
      </UserContextProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);