import { useState, useEffect } from 'react';
import { useFormContext } from "react-hook-form"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Button,
  Grid,
} from '@mui/material';

const tabStyles = {
  '&.MuiTab-root': {
    fontWeight: 300,
    fontSize: '16px',
    color: 'white',
    textTransform: 'none',
  },
  '&.Mui-selected': {
    fontWeight: 700,
  },
};

const TabPanel = ({ children, index, currentTab }) => {
  return <div hidden={currentTab !== index}>{children}</div>;
};

export const BaseDialog = (props) => {
  const { id, idPrefix, title, visible, setVisible, tabs, onSubmit } = props;

  const [tab, setTab] = useState(0);
  const { formState } = useFormContext();
  const { isValid } = formState;

  useEffect(() => {
    if (!visible) {
      setTab(0);
    }
  }, [visible]);

  const handleDialogClick = e => {
    e.stopPropagation();
  };

  return (
    <Dialog
      onClick={handleDialogClick}
      open={visible}
      fullWidth
      scroll="body">
      <form>
        <DialogTitle>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            padding={4}
          >
            <Grid item xs={6}>
              <strong>{title}</strong>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <span
                style={{
                  fontFamily: 'museo-sans',
                  fontSize: '12px',
                  color: 'black',
                  position: 'relative',
                  top: 20,
                  left: 10,
                }}
              >
                {idPrefix}
                {id}
              </span>
            </Grid>
          </Grid>
          <Tabs
            value={tab}
            onChange={(e, value) => setTab(value)}
            sx={{
              '&.MuiTabs-root': {
                backgroundColor: '#1E2133',
                px: '12rem',
              },
              '& .MuiTabs-flexContainer': {
                gap: '6.25rem',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#76D2D3',
                height: '5px',
              },
            }}
          >
            {tabs?.map((_tab, index) => (
              <Tab
                key={index}
                label={_tab.label}
                sx={tabStyles}
              />
            ))}
          </Tabs>
        </DialogTitle>
        <DialogContent>
          {tabs?.map((_tab, index) => (
            <TabPanel key={index} index={index} currentTab={tab}>
              {_tab.render()}
            </TabPanel>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setVisible(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!isValid}
            onClick={onSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
