import {DRAG_TYPES, FIELD_TYPES} from '@ais/constants';

export const AUDIT_SCOPING_FORM_TYPE_ID = 2;
export const PRE_ENGAGEMENT_PROCEDURE_ID = 4;
export const PROJECT_SCOPE_TEST_OPERATING_EFFECTIVENESS_QUESTION_ID = '00000000-0000-0000-0000-000000000001';
export const COMMON_IDENTIFIED_RISKS = 'Common Identified Risks';
export const RISK_CHARACTERISTICS = 'Risk Characteristics';


export const AUDIT_SCOPING_SECTION = {
  id: "AUDIT-SCOPING-SECTION-ID-1",
  type: DRAG_TYPES.SECTION_CUSTOM,
  fields: [
    [
      {
        type: FIELD_TYPES.PROJECT_SCOPE_TRIAL_BALANCE,
        id: "TRIAL-BALANCE-FIELD-ID-1",
        index: 0,
        text: "",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true,
        },
      },
      {
        type: FIELD_TYPES.PROJECT_SCOPE_AUDIT_AREA,
        id: "AUDIT-AREA-FIELD-ID-1",
        index: 0,
        text: "",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true,
        },
      }
    ],
  ],
  title: "",
  description: "",
  tooltip: "",
  excludeFromExport: false,
  showCollapsed: false,
  showContextMenu: true,
  rules: {
    behavior: "show",
    match: "any",
    criterias: [],
  },
};

export const INITIAL_SCOPING_CONSIDERATION = {
  id: "AUDIT-SCOPING-SECTION-ID-2",
  type: DRAG_TYPES.SECTION_CUSTOM,
  fields: [
    [
      {
        type: FIELD_TYPES.PROJECT_SCOPE_TEST_OPERATING_EFFECTIVENESS,
        id: "00000000-0000-0000-0000-000000000001",
        index: 0,
        text: "",
        width: 100,
        rules: {
          behavior: "show",
          match: "any",
          criterias: [],
          excludeAnswerOnRollForward: false,
          canNotBeDeleted: true,
        },
      }
    ],
  ],
  title: "Initial Scoping Considerations",
  description: "",
  tooltip: "",
  excludeFromExport: false,
  showCollapsed: false,
  showContextMenu: true,
  rules: {
    behavior: "show",
    match: "any",
    criterias: [],
  },
};