import {FIELD_TYPES} from '@ais/constants';

export const getTrialBalanceValues = (schema, values) => {

    if(!schema || !values) return; 
    
    const trialBalanceSection = schema?.properties?.find(data => {
        for (const field of data.fields) {
            if (field[0].type === FIELD_TYPES.TRIAL_BALANCE) {
                return data;
            }
        }
    })
    const trialBalanceField = trialBalanceSection?.fields?.find(field => field[0].type === FIELD_TYPES.TRIAL_BALANCE);
    const trialBalanceId = trialBalanceField[0]?.id;
    const trialBalanceValues = values[trialBalanceId] ?? [];

    return trialBalanceValues;
}