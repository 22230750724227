import React from 'react';
import { Typography } from '@mui/material';

export const NoWrapText = ({ value }) => {
  return (
    <Typography sx={{
      fontWeight: 600,
      fontSize: '14px',
      m: '20px'
    }}>
      {value}
    </Typography>
  );
};
