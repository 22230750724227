export const INTERNAL_CONTROLS = {
  EN: {
    PURPOSE_OBJECTIVES: {
      PURPOSE: {
        KEYWORD: "Purpose",
        DESCRIPTION: '"To support the identification and assessment of risks of material misstatement at the assertion level and provide a basis for the design and performance of further audit procedures responsive to these risks."',
      },
      OBJECTIVES: {
        KEYWORD: 'Objectives',
        DESCRIPTION: '"To gain an understanding of the flows of transactions and other aspects of the entity\'s information-processing activities relevant to the preparation of the financial statements, and the control activities component. To identify controls that address risks of material misstatement at the assertion level."'
      },
      PROCEDURES: {
        KEYWORD: 'Procedures',
        DESCRIPTION: '"Perform risk assessment procedures considered necessary to obtain an understanding of the entity\'s information system and communication. Document the entity\'s information-processing activities, including its data and information, the resources to be used in such activities, and the policies that define significant classes of transactions, account balances, and disclosures (SCOTABD). Document the entity\'s control activities. Identify the controls that address the risk of material misstatement at the assertion level (relevant key controls)." '
      }
    },
    TOOLTIPS: {
      KEY_CONTROLS_HEADER: 'For controls not designed or implemented effectively, enter a control deficiency. (Select control deficiency flyout by clicking the ellipsis at the top right of this section)',
      TEXTFIELD: 'Including date and location of walkthrough, identifying characteristics of transaction selected, documents examined, who performs the control, whether they have ever been asked to override the control, and whether the control was performed timely.'
    },
    KEYS: {
      IS_DESIGNED_EFFECTIVELY: 'IsDesignedEffectively',
      IS_IMPLEMENTED_EFFECTIVELY: 'IsImplementedEffectively',
      WALKTHROUGH_COMMENT: 'WalkthroughComment',
      ACTIONS: "Actions",
      PROJECT_KEY_CONTROL_NAME: "ProjectKeyControlName",
      IS_FINANCIAL_STATEMENT: "IsFinancialStatement",
      IS_JOURNAL_ENTRY: "IsJournalEntry"
    },
    TEXT: {
      NO_RECORDS: 'No records to display',
      PURPOSE: 'Purpose:',
      OBJECTIVE: 'Objectives:',
      OBJECTIVE_DESC: 'To evaluate the design and determine whether identified relevant (key) controls have been implemented.',
      PROCEDURES: 'Procedures:',
      PROCEDURES_DESC: 'For each relevant (key) control identified, evaluate whether the control is designed effectively.',
      PERFORM_PROCEDURES: 'Perform procedures in addition to inquiry to determine whether the control has been implemented.',
      ELLIPSE: 'Use the ellipse to accumulate any identified risks or deficiencies in internal control',
      SUMMARY_OF_KEY_CONTROLS: 'Summary of Key Controls',
      PURPOSE_DESC: 'To understand management\'s approach to addressing certain risks and provide a basis for the design and performance of further audit procedures responsive to these risks.',
      OTHER_RELEVANT_ASSERTIONS: "Other Relevant Assertions:"
    },
    LABELS: {
      WALKTHROUGH_COMMENT: 'Walkthrough of procedures performed',
      KEY_CONTROLS: 'Key Controls',
      KEY_CONTROLS_BUSY: 'These Key Controls are currently being edited.',
    },
    CUSTOM_KEY: {
      RISKS: 'Risks',
      CONTROL_DESIGNED_EFFECTIVELY: "Control is Designed Effectively",
      CONTROL_HAS_BEEN_IMPLEMENTED_EFFECTIVELY: 'Control Has Been Implemented Effectively'
    },
    EVALUATION_AND_CONCLUSION: {
      SUBSECTION_TITLE: 'Evaluation and Conclusion',
      FIRST_PARAGRAPH: 'Review control deficiencies added to the Control Deficiency Summary form as a result of the procedures performed above. Evaluate whether any control deficiencies (individually or in combination) affect our assessment of identified risks (i.e., create an additional overall financial statement risk or influence the assessment of assertion level risks). (To enter a risk, click ellipsis at the top right of this section.)',
      SECOND_PARAGRAPH: "Based on the understanding obtained, does the entity's information system and communication appropriately support the preparation of the entity's financial statements in accordance with the applicable financial reporting framework.",
      TOOLTIP: "CLA is often engaged to prepare the financial statements related to an audit engagement. Providing this nonattest service does not preclude the client's information system and communication from supporting the preparation of the entity's financial statements in accordance with the applicable financial reporting framework.",
      IS_ACCORDING_TO_FRAMEWORK: {
        YES: 'Yes',
        NO: 'No'
      },
      TEXTBOX: {
        LABEL: "Describe why the entity's information system and communication does not appropriately support the preparation of the entity's financial statements and implications to the audit, such as an additional overall financial statement risk. (To enter a risk, click ellipsis at the top right of this section.)",
        PLACEHOLDER: 'Enter description'
      },
      CUSTOM_FORM_OBJECT_ID: 'evaluation-and-conclusion-radio-group'
    },
    MODAL: {
      TITLE: 'Warning',
      CANCEL: 'Cancel',
      DELETE: "Delete",
      BODY: "Are you sure you want to delete this key control?"
    },
    INCORPORATE_KEY_CONTROLS: {
      TABLE: {
        KEY_CONTROLS: "Key Controls",
        CONTROL_DESIGNED_EFFECTIVELY: "Control is Designed Effectively",
        CONTROL_HAS_BEEN_IMPLEMENTED_EFFECTIVELY: "Control Has Been Implemented Effectively",
        JOURNAL_ENTRY_HEADER: "Journal Entries",
        FINANCIAL_STATEMENT_HEADER: "Financial Statement Close and Reporting Process",
        JOURNAL_ID: "JournalEntries",
        FINANCIAL_STATEMENT_ID: "FinancialStatementCloseAndReportingProcess"
      },
      PLACEHOLDERS: {
        WALKTHROUGH: 'Enter Walkthrough of procedures performed',
      }
    },
    COMMUNICATION_OF_SIGNIFICANT_MATTERS: {
      PEOPLE_WITH_ENTITY_COMMENT_FIELD_NAME: 'PeopleWithinEntityComment',
      MANAGEMENT_COMMENT_FIELD_NAME: 'ManagementComment',
      EXTERNAL_COMMENT_FIELD_NAME: 'ExternalComment',
      LABEL: "Describe how the entity communicates significant matters that support the preparation of the financial statements and related reporting responsibilities in the information system and other components of the system of internal control:",
      BETWEEN_PEOPLE_LABEL: "Between people within the entity, including how financial reporting roles and responsibilities are communicated",
      PLACEHOLDER: "Enter answer",
      BETWEEN_MANAGEMENT_LABEL: "Between management and those charged with governance (e.g., board)",
      EXTERNAL_COMMENT_LABEL: "With external parties, such as regulatory authorities"
    }
  },
}