import { APPLICATION_ACTION } from "@constants/index";

export const ApplicationReducer = (state, action) => {

    const newState = { ...state }

    const getLoadingState = (payload = false) => {
        return (newState.globalQueryLoading && newState.fetchingQueryCount > 0) // Global query loading
            || payload // legacy loading support
            || Object.values(newState.loadMap.values()).some((loadingState) => !!loadingState); // If any on the loading map is still true
    }

    switch (action.type) {
        case APPLICATION_ACTION.SET_LOADING: {
            newState.isLoading = getLoadingState(action.payload);
            return newState
        }
        case APPLICATION_ACTION.SET_LOAD_MAP: {
            const { isLoading, loadKey } = action.payload;
            newState.loadMap[loadKey] = isLoading;
            newState.isLoading = getLoadingState();
            return newState
        }
        case APPLICATION_ACTION.SET_LOADING_BY_QUERY_COUNT: {
            newState.fetchingQueryCount = action.payload;
            newState.isLoading = getLoadingState();
            return newState;
        }
        case APPLICATION_ACTION.CLEAR_LOAD_MAP: {
            newState.loadMap = new Map();
            return newState;
        }
        case APPLICATION_ACTION.TURN_OFF_GLOBAL_QUERY_LOADING: {
            newState.globalQueryLoading = false;
            return newState;
        }
        case APPLICATION_ACTION.TURN_ON_GLOBAL_QUERY_LOADING: {
            newState.globalQueryLoading = true;
            return newState;
        }
    }
}