import { useContext } from 'react';
import { ProjectFormInstanceContextV2 } from './ProjectFormInstanceProviderV2';

export const useProjectFormInstanceProviderV2 = () => {
  const context = useContext(ProjectFormInstanceContextV2);

  if (context === undefined) {
    throw new Error(
      'useProjectFormInstanceProviderV2() hook must be used within <ProjectFormInstanceContextV2/>'
    );
  }

  return context;
}