import {DRAG_TYPES, FIELD_TYPES} from './formdesigner';

export const INTERNAL_CONTROLS_FORM_TYPE_ID = 7;

export const INTERNAL_CONTROLS_CONSTANTS = {
    FORM_DRAGGABLES : [
        {
          dragType: DRAG_TYPES.SECTION,
          fieldType: FIELD_TYPES.SECTION,
          title: 'Section',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.FIELD,
          fieldType: FIELD_TYPES.FREETEXT_NO_RULES,
          title: 'Free Text',
        },
        {
          dragType: DRAG_TYPES.FIELD,
          fieldType: FIELD_TYPES.SHORT_ANSWER,
          title: 'Short Answer',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.FIELD,
          fieldType: FIELD_TYPES.LONG_ANSWER,
          title: 'Long Answer',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.FIELD,
          fieldType: FIELD_TYPES.RADIOGROUP,
          title: 'Radio Button',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.FIELD,
          fieldType: FIELD_TYPES.DROPDOWN,
          title: 'Dropdown',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.FIELD,
          fieldType: FIELD_TYPES.CHECKBOXGROUP,
          title: 'Checkbox',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.FIELD,
          fieldType: FIELD_TYPES.DATE_PICKER,
          title: 'Date Picker',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.FIELD,
          fieldType: FIELD_TYPES.TABLE,
          title: 'Table',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.FIELD,
          fieldType: FIELD_TYPES.FORMULA,
          title: 'Formula',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.PROCEDURE,
          fieldType: FIELD_TYPES.PROCEDURE,
          title: 'Procedures',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.FIELD,
          fieldType: FIELD_TYPES.EXISTING_QUESTION,
          title: 'Existing Question',
          disabled: true
        },
        {
          dragType: DRAG_TYPES.QUESTION_GROUP,
          fieldType: FIELD_TYPES.QUESTION_GROUP,
          title: 'Question Group',
          disabled: true
        },
    ],
    CUSTOM_COMPONENT_ENABLED: 'Custom component enabled.',
}