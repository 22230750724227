export const formatMoneyDecimalAndComma = (num) => {
  const formattedNum =  Math.abs(parseFloat(Math.round(num))).toLocaleString("en-US", { minimumFractionDigits: 0 });
  return parseFloat(num) < 0 ? `$(${formattedNum})` : `$${formattedNum}`;
};

export const formatDecimalToPercent = (num) => {
  const formattedNum = parseFloat(num).toLocaleString("en-US", { maximumFractionDigits: 2 }).toString().replace(',', '');
  return parseFloat(num) < 0 ? `(${Math.abs(formattedNum)})%` : `${Math.abs(formattedNum)}%`;
};

export const formatMoneyToNegativeDashed = (num) => {
  const formattedNum = Math.abs(parseFloat(Math.round(num))).toLocaleString("en-US", { minimumFractionDigits: 0 });
  return parseFloat(num) <= 0 ? `---` : `$${formattedNum}`;
};