import { createContext, useState, useEffect, useRef } from 'react';
import { useFormContext } from "react-hook-form";
import { useParams } from 'react-router-dom';
import { QUESTION_LINK_TYPE } from "@ais/constants"

import { transformAnswers } from '@utilities/transformAnswers';
import { useSaveProjectUnitAnswer } from '@services/forms/projectforms.js';
import projectFormServices, { useGetQuestionsWithLink } from "@services/forms/projectforms";

import CustomToast from '@components/FormView/v2/SaveIndicator/CustomToast';
import logger from '@utilities/logService'

export const ProjectFormInstanceContextV2 = createContext({});

export const ProjectFormInstanceProviderV2 = ({ projectFormData, answers, userId, children }) => {
    const [projectForm] = useState(projectFormData);
    const [answerList, setAnswerList] = useState(transformAnswers(answers ?? []));
    const { getValues, reset, setValue } = useFormContext();
    const { units } = projectForm;
    const { projectId, projectFormId } = useParams();
    const { data: questionsWithLink } = useGetQuestionsWithLink(projectId, projectFormId)
    const [relatedFields, setRelatedFields] = useState([]);
    const [focusedId, setFocusedId] = useState(null)
    const { mutateAsync: saveProjectUnitAnswer, isLoading, isError, isSuccess } = useSaveProjectUnitAnswer();
    const [linkDefaultFields, setLinkDefaultFields] = useState([])
    const fieldRef = useRef(null);
    const { QUESTION_LINK_TYPE: {
        DUPLICATE_QUESTION,
        LINK_ANSWER,
        LINK_ANSWER_DEFAULT
    } } = QUESTION_LINK_TYPE;

    const updateQuestionsWithLink = (id, answer) => {
        const { schema } = projectForm
        
        const result = []

        schema.forEach(section =>
            section.fields.forEach(row =>
                row.forEach((field) => {
                    if (field.existingQuestionId && field.id === id && field.linkType === LINK_ANSWER) {
                        handleSubmit(field.existingQuestionId, answer) 
                        return
                    }
                })
            )
        );

        schema.forEach(section =>
            section.fields.forEach(row =>
                row.forEach((field) => {
                    if (field.existingQuestionId && field.id === id && field.linkType === LINK_ANSWER_DEFAULT) setLinkDefaultFields(prev => [...prev, id])
                    if (field.existingQuestionId && field.existingQuestionId === id && field.linkType !== DUPLICATE_QUESTION && !field.isUserModified) result.push(field.id)
                    if (field.existingQuestionId && field.id === id && field.linkType === LINK_ANSWER) result.push(field.existingQuestionId)
                })
            )
        );
        if(result.length > 0) { 
            result.forEach(id => {
                if(!linkDefaultFields.includes(id)){    
                    setValue(id, answer)
                    setAnswerList(prev => {
                        return {...prev, [id]: answer }
                    })
                }
            })
        } 
    } 

    const handleSubmit = async (questionId, _answer = null) => {
        const isQuestionGroup = questionId.includes('.')
        if(isQuestionGroup) questionId = questionId.split('.')[0]
        const answer = _answer ?? getValues(questionId);
        let transformedValue = answer;
        const isTable = Array.isArray(answer) && answer.some(ans => typeof ans === 'object' && ans !== null && 'rowLabel' in ans)
        if (answer === answerList[questionId]) return;

        if(isTable) {
            transformedValue = answer.map(row => ({
                id: row.rowId,
                rowLabel: row.rowLabel,
                values: Object.keys(row).filter(key => key !== 'rowId' && key !== 'rowLabel').map(key => ({
                    id: key,
                    value: row[key]
                })).filter(value => value.value !== undefined)
            }))
        } 
        if(isQuestionGroup) {
            transformedValue = answer.map((item)=>{
                const transformedItem = Object.keys(item).map((key) => {
                    if(Array.isArray(item[key]) && item[key].length > 0 && item[key][0].rowId){
                        return {
                            id: key,
                            value: item[key].map(row => ({
                                id: row.rowId,
                                rowLabel: row.rowLabel,
                                values: Object.keys(row).filter(rowKey => rowKey !== 'rowId' && rowKey !== 'rowLabel').map(rowKey => ({
                                    id: rowKey,
                                    value: row[rowKey]
                                }))
                            }))
                        }
                    }else {
                        return {
                            id: key,
                            value: item[key]
                        }
                    } 
                })
                return transformedItem
            })
        }
        if(isQuestionGroup || isTable)
            if(JSON.stringify(answer.map(ans => {
                const obj = {}
                for(const key in ans){
                    if(ans[key] !== null && ans[key] !== undefined) obj[key] = ans[key]
                }
                return obj
            })) === JSON.stringify(answerList[questionId])) return
        try {
            const unitAnswer = {
                projectId,
                projectUnitIds: units.map(item => item.ProjectUnitID),
                questionId,
                answer: transformedValue
            };
            await saveProjectUnitAnswer({
                projectId,
                projectFormId,
                unitAnswer
            });

            updateQuestionsWithLink(questionId, answer)

            setAnswerList(prev => {
                return { ...prev, [questionId]: answer }
            })
        } catch (error) {
            logger.error(error.response.data.message ?? error.message);
            //Toast handles seperately via property state awareness of mutation Object
        }
    }

    const handleFocusField = (e, id) => {
        if(e) fieldRef.current = e.target;
        setFocusedId(id);
    }

    useEffect(() => {
        if (Object.keys(answerList).length > 0) {
            reset(answerList);
        }
    }, [answerList])
    
    useEffect(() => {
        setAnswerList(transformAnswers(answers ?? []));
        // link answer default if not modifed retrieve default or parent field
        projectForm.schema.forEach(section => 
            section.fields.forEach(row => 
                row.forEach((field) => {
                    if(field.linkType === LINK_ANSWER_DEFAULT && !field.isUserModified) {
                        const defaultAnswer = answers?.find(ans => ans.questionId?.toLowerCase() === field.existingQuestionId?.toLowerCase())
                        if(defaultAnswer){ 
                            setValue(field.id, defaultAnswer.answer)
                            setAnswerList(prev=> { 
                                return {...prev,[field.id]: defaultAnswer.answer }
                            })
                        }
                    }
                })
            )
        );
    }, [answers]);
    const handleLinkClick = async(questionId) => {
        if(questionId){
            const { data } = await projectFormServices.getRelatedFieldsByQuestionsIds(projectId, questionId)
            if(data){
                const relatedFields = data.map(item => ({
                    formId: item.projectFormId,
                    formName: item.projectFormName,
                    questionIds: item.questionIds,
                    sectionId: item.sectionId,
                    sectionName: item.sectionName,
                }))
                setRelatedFields(relatedFields)
            }
        } else {
            setRelatedFields([])
        }
    }

    return (
        <ProjectFormInstanceContextV2.Provider
            value={{
                projectForm,
                answers,
                answerList,
                questionsWithLink,
                relatedFields,
                focusedId,
                fieldRef,
                userId,
                actions: {
                    onSubmit: handleSubmit,
                    onLinkClick: handleLinkClick,
                    onFocus: handleFocusField
                }
            }}
        >
            {children}
            <CustomToast
                error={isError}
                success={isSuccess}
                loading={isLoading}
            />
        </ProjectFormInstanceContextV2.Provider>
    )
}
