export default {
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell": {
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "museo-sans, sans-serif",
    lineHeight: "19.2px",
    border: "none",
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
    fontWeight: "700",
    fontSize: "16px",
    fontFamily: "museo-sans, sans-serif",
    lineHeight: "19.2px",
    backgroundColor: "#F6F6F6",
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
    border: "solid 1px #97979740",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#e4f6f6",
    cursor: "pointer"
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "initial",
  },
  "&.MuiDataGrid-root": {
    border: "none",
  },
  "p": {
    margimBottom: 0
  }
};
