
import React, { useEffect, useState } from 'react';
import {
  TextField,
  Autocomplete,
  FormControl,
  Chip,
  InputAdornment
} from '@mui/material';
import classes from './CLASelect.module.css';
import { Controller, useFormContext } from 'react-hook-form'; 
import { useRoomIdle } from '@ais/hooks';

const CLAMultiOther = (props) => {
  const {
    options,
    allowExternalAccess,
    label,
    id,
    error,
    isDisabled,
    clientExternalIcon,
    lockField,
    unlockField,
    answerList,
    onFocus,
    onSubmit
  } = props; 

  const isIdle = useRoomIdle();
  const [open, setOpen] = useState(false);
  const { control } = useFormContext(); 
  const handleOpen = () => {
    setOpen(true)
    lockField && lockField()
  }

  const handleBlur = () => {
    onSubmit(id)
    unlockField && unlockField()
  };

  const fieldId = `multi-other-${id}`;

  useEffect(() => {
    if (isIdle) setOpen(false)
  }, [isIdle])

  return (
    <FormControl className={classes['cla-select']} error={error} fullWidth>
      <Controller
        key={id}
        render={({ field: { value = [], onChange } }) => {
          let _value = [];
          if (value && Array.isArray(value)) {
            _value = value
          } else {
            _value = [value]
          }
          return (
            <Autocomplete
              open={open}
              freeSolo
              disableCloseOnSelect
              multiple
              options={options}
              clearOnBlur
              autoSelect
              value={_value}
              disabled={isDisabled}
              test-id={fieldId}
              test-value={value}
              onOpen={handleOpen}
              onClose={() => { setOpen(false) }}
              onFocus={(e) => {
                onFocus(e, id)
              }}
              onChange={(_, newValue) => {
                onChange(newValue)
              }}
              onBlur={(e) => {
                if (isIdle) {
                  onChange(answerList[id])
                  return;
                }
                const textValue = e.target.value
                const newValue = value
                if (!!textValue && !value.includes(textValue)) newValue.push(textValue)
                onChange(newValue)
                handleBlur()
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={label}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    variant: 'multiline-label',
                    shrink: true
                  }}
                  sx={{
                    '& .MuiAutocomplete-endAdornment': {
                      right: allowExternalAccess ? '0 !important' : '9px',
                      position: allowExternalAccess ? 'relative' : 'absolute'
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      top: '14px'
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        <InputAdornment position="end">
                          {clientExternalIcon}
                        </InputAdornment>
                      </>
                    )
                  }}
                  onKeyDown={(event) => {
                    if (
                      (event.key === 'Enter' || event.key === 'Tab') &&
                      params.inputProps.value &&
                      !value.includes(params.inputProps.value)
                    ) {
                      event.preventDefault();
                      onChange([...value, params.inputProps.value]);
                    }
                  }}
                />
              )}
            />
          );
        }}
        name={id}
        control={control}
      />
    </FormControl>
  );
};

export default React.memo(CLAMultiOther);
