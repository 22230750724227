
export const GROUP_ROLE = {
    ADMIN: 'Admin',
    NATG_AUTHOR: 'NATG_Author'
}

export const NAVIGATION_TYPE = {
    type: ['AIS DASHBOARD', 'PROJECT DASHBOARD']
}

export const BACK_ARROW_LINK = {
    backLink: ['All Projects', 'Forms Library']
}


export const ROUTE_PATH = {
    formsLibrary: 'forms-library',
    proceduresLibrary: 'procedures-library',
    formsDesigner: 'forms-designer',
    formsSettings: 'forms-settings',
    formsHelp: 'forms-help',
    methodology: 'methodology',
    projectAdminstration: 'project-administration',
    listsLibrary: 'lists-library',
    kpiLibrary: 'kpi-library',
    noaccess: 'noaccess'
}

export const AIS_DASHBOARD_NAVIGATION = {
    EN: {
        menuList: [
            { title: 'AIS Dashboard', to: '/' },
            { title: 'Create a New Project', to: 'create-new-project' },
            { title: 'Forms Library', to: ROUTE_PATH.formsLibrary },
            { title: 'Procedures Library', to: ROUTE_PATH.proceduresLibrary, requiredGroups: [GROUP_ROLE.ADMIN, GROUP_ROLE.NATG_AUTHOR] },
            { title: 'KPI Library', to: ROUTE_PATH.kpiLibrary },
            { title: 'Lists Library', to: ROUTE_PATH.listsLibrary, requiredGroups: [GROUP_ROLE.ADMIN, GROUP_ROLE.NATG_AUTHOR] },
            { title: 'Methodology', to: ROUTE_PATH.methodology },
            { title: 'Project Administration', to: ROUTE_PATH.projectAdminstration }
        ],
    }
}

export const AIS_ACTIVE_PROJECT_NAVIGATION = {
    EN: {
        menuList: [
            { title: 'Project Dashboard', to: '/project-dashboard' },
            { title: 'Project Management', to: '/project-management' },
            { title: 'Diagnostics', to: '/diagnostics' },
            { title: 'KPIs', to: '/kpis' }
        ],
    }
}

export const AIS_FINALIZED_PROJECT_NAVIGATION = {
    EN: {
        menuList: [
            { title: 'Project Dashboard', to: '/project-dashboard' },
            { title: 'Project Management', to: '/project-management' },
        ],
    }
}