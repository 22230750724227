import { useState, useEffect } from 'react';
import { useWatch, useFormContext } from "react-hook-form";
import { useParams } from 'react-router-dom';
import {
    Grid,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { DIALOG } from '@constants/forms';

import formCategoriesService from '@services/forms/formCategories.js';
import formServices from '@services/forms/forms.js';

import {
    BasicTextField,
    BasicDropdown,
} from '../settings';

export const LinkList = ({ visible }) => {

    const { methodologyId } = useParams();

    const { PLACEHOLDER } = DIALOG.SETTINGS;
    const [formCategoryOptions, setFormCategoryOptions] = useState([]);
    const [formOptions, setFormOptions] = useState([]);
    const [formSchema, setFormSchema] = useState(null);
    const [formSectionsOptions, setFormSectionOptions] = useState([]);
    const [questionOptions, setQuestionOptions] = useState([]);

    const { control, setValue, trigger, formState: { errors } } = useFormContext();
    const watchLinkToInternalList = useWatch({ control, name: 'linkList.linkToInternalList' }) ?? false;
    const watchFormCategoryID = useWatch({ control, name: 'linkList.formCategoryId' }) ?? null;
    const watchFormId = useWatch({ control, name: 'linkList.formId' }) ?? null;
    const watchSectionId = useWatch({ control, name: 'linkList.sectionId' }) ?? '';
    const watchQuiestionId = useWatch({ control, name: 'linkList.questionId' }) ?? '';

    const toggleLinkQuestion = (checked) => {
        setValue('linkList.linkToInternalList', checked, { shouldValidate: true });
        if (!checked) trigger();
    }

    useEffect(() => {
        if (!visible) {
            setFormOptions([]);
            setFormSectionOptions([]);
            setQuestionOptions([]);
        }
    }, [visible]);

    useEffect(() => {
        if (watchQuiestionId) trigger();
    }, [watchQuiestionId])

    useEffect(() => {
        async function fetchCategoryData() {
            try {
                let { data } = await formCategoriesService.getFormCategories();
                data = data.map((e) => {
                    return {
                        label: e.FormCategoryName,
                        value: e.FormCategoryId
                    }
                })
                setFormCategoryOptions(data);
            }
            catch (error) {

            }
        }
        fetchCategoryData();
    }, []);

    useEffect(() => {
        async function fetchFormData() {
            try {
                const { data } = await formCategoriesService.getFormsByCategoryId(watchFormCategoryID);
                setFormOptions(
                    data.filter((e) => e.MethodologyVersionId === parseInt(methodologyId)).map((e) => {
                        return {
                            label: e.FormName,
                            value: e.FormId
                        }
                    })
                );
            } catch (error) { }
        }
        setFormOptions([]);
        setFormSectionOptions([]);
        setQuestionOptions([]);
        if (!!watchFormCategoryID) {
            fetchFormData();
        }
        setValue('linkList.formId', watchFormId);
        setValue('linkList.sectionId', watchSectionId);
        setValue('linkList.questionId', watchQuiestionId);
    }, [watchFormCategoryID]);

    useEffect(() => {
        async function fetchFormSchema() { 
            try {
                const data = await formServices.getFormWithSchemaByFormId(watchFormId);
                setFormSchema(data.formSchema);
                setFormSectionOptions(data.formSchema.flatMap((e) => {
                    return {
                        label: e.title,
                        value: e.id
                    }
                }));
                setQuestionOptions(data.formSchema
                    .flatMap(section => section.fields
                        .flatMap(row => row
                            .flatMap((e) => {
                                return {
                                    label: e.label,
                                    value: e.id
                                }
                            }))));
            } catch (error) { }
        }
        setFormSectionOptions([]);
        setQuestionOptions([]);
        if (!!watchFormId) {
            fetchFormSchema();
        }
        setValue('linkList.sectionId', watchSectionId);
        setValue('linkList.questionId', watchQuiestionId);
    }, [watchFormId]);

    useEffect(() => {
        function updateQuestionOptions() {
            let options = [];
            const section = formSchema?.find((section) => section.id === watchSectionId);
            if (section) {
                options = section.fields
                    .flatMap(row => row)
                    .flatMap((field) => ({ label: field.label, value: field.id }));
            }
            setQuestionOptions(options);
        }
        setQuestionOptions([]);
        if (!!watchSectionId) {
            updateQuestionOptions();
        }
        setValue('linkList.questionId', watchQuiestionId, { shouldValidate: true });
    }, [watchSectionId]);

    return (
        <Grid container rowSpacing={5} columnSpacing={8}>
            <Grid item>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="linkList.linkToInternalList"
                            checked={watchLinkToInternalList}
                            onChange={(e) => toggleLinkQuestion(e.target.checked)}
                            defaultChecked={watchLinkToInternalList}
                        />
                    }
                    label="Link to Internal List"
                    sx={{
                        m: 0,
                        justifyContent: 'flex-start',
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={8}>
                    <Grid item xs={6}>
                        <BasicDropdown
                            name="linkList.formCategoryId"
                            label="1. Select Form Category"
                            placeholder={PLACEHOLDER.BASIC_DROPDOWN}
                            options={formCategoryOptions}
                            disabled={watchLinkToInternalList}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicDropdown
                            name="linkList.formId"
                            label="2. Select Form"
                            placeholder={PLACEHOLDER.BASIC_DROPDOWN}
                            options={formOptions}
                            disabled={(!formOptions.length || watchLinkToInternalList) ? true : false}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={8}>
                    <Grid item xs={6}>
                        <BasicDropdown
                            name="linkList.sectionId"
                            label="3. Select Section"
                            placeholder={PLACEHOLDER.BASIC_DROPDOWN}
                            options={formSectionsOptions}
                            disabled={(!formSectionsOptions.length || watchLinkToInternalList) ? true : false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicDropdown
                            name="linkList.questionId"
                            label="4. Select Question"
                            placeholder={PLACEHOLDER.BASIC_DROPDOWN}
                            options={questionOptions}
                            disabled={(!questionOptions.length || watchLinkToInternalList) ? true : false}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <BasicTextField
                            name="linkList.searchByQuestionID"
                            label="OR paste in question ID from the source"
                            placeholder="Question ID"
                            maxLength={256}
                            helperText={<div style={{ color: 'red', fontWeight: 'bold' }}>{get(errors['linkList'], 'searchByQuestionID.message')}</div>}
                            disabled={!watchLinkToInternalList}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

LinkList.propTypes = {
    visible: PropTypes.bool
};