import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import CLACheckBoxField from '@components/Forms/CLACheckBoxField/CLACheckBoxField';

import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import { useUpdateMyPresence, useRoomIdle } from "@components/Concurrency/provider/RoomProvider";

import { useCommunicationStore } from '../communication';
import styles from '../CLACommunicationOfDeficiencies.module.css';

import { DeficiencyLabelTypography } from './helpers';
import CommunicationOfDeficienciesTextField from './CommunicationOfDeficienciesCells/CommunicationOfDeficienciesTextField';
import CommunicationOfDeficienciesDatepicker from './CommunicationOfDeficienciesCells/CommunicationOfDeficienciesDatepicker';

const CommunicationOfOtherDeficienciesSubRow = (props) => {
	const { projectId, row, onAutoSave, disabled } = props;
	const isProjectFinalized = useFinalizedProject(projectId);
	const { COMMUNICATION_OF_DEFICIENCIES, ID } = DEFICIENCY_FORM_INSTANCE.EN;
	const [deficiency, setDeficiency] = useState(row);
	const [shouldHighlightRow, setShouldHighlightRow] = useState(false);
	const updateMyPresence = useUpdateMyPresence()
	const latestSignoffTime = useCommunicationStore(state => state.latestSignoffTime);
	const isIdle = useRoomIdle()

	const handleCellChange = (field, value) => {
		setDeficiency((prev) => ({ ...prev, [field]: value }));
	};

	const handleChange = (field, value) => {
		if (onAutoSave) {
			onAutoSave(row, field, value);
		}
	};

	const handleDatePickerChange = (date) => { 
		handleChange('CommunicationDate', date);
	}

	const handleLockingField = (lock) => {
		if (lock) {
			updateMyPresence({ focusedId: ID })
		} else {
			updateMyPresence({ focusedId: null })
		}
	}

	useEffect(() => {
		const dateModified = new Date(row.ValidFrom).getTime();
		const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
		if ('ProjectDeficiencyId' in row) {
			setShouldHighlightRow(isSignoffEnabled && latestSignoffTime < new Date(row.ValidFrom).getTime())
		} else if ('ProjectDeficiencyCombinationId' in row) {
			setShouldHighlightRow(isSignoffEnabled && latestSignoffTime < new Date(row.ValidFrom).getTime())
		}
	}, [latestSignoffTime, row])

	useEffect(() => {
		setDeficiency(row) 
	}, [row])

	useEffect(() => {
		setDeficiency(row) 
	}, [isIdle])

	return (
		<>
			<Box sx={{ p: `20px 65px 10px 65px` }}>
				<div>
					<div className={styles['cood-header-row']}>
						<div className={styles['cood-header-cell']}>
							{COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT_COMMENT_DEFICIENCY.MERITS_MANAGEMENT}
						</div>
						<div className={styles['cood-header-cell']}>
							{COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.COMMUNICATED_TO}
						</div>
						<div className={styles['cood-header-cell']}>
							{COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.COMMUNICATED_BY}
						</div>
						<div className={styles['cood-header-cell']}>
							{COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT_COMMENT_DEFICIENCY.NAME_PERSON_COMMUNICATED_TO}
						</div>
						<div className={styles['cood-header-cell']}>
							{COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT_COMMENT_DEFICIENCY.DATE}
						</div>
					</div>
					<div className={styles['cood-body-row']} style={shouldHighlightRow ? { border: '4px dashed red' } : null}>
						<div className={`${styles['cood-body-cell']} ${styles['cood-merit-cell']}`}>
							<CLACheckBoxField
								checked={deficiency.IsMeritsAttentionOfManagement}
								name="IsMeritsAttentionOfManagement"
								onChange={(e) => {
									handleCellChange('IsMeritsAttentionOfManagement', !deficiency.IsMeritsAttentionOfManagement);
									handleChange('IsMeritsAttentionOfManagement', e.target.checked);
								}}
								disabled={disabled}
							/>
						</div>
						{deficiency.IsMeritsAttentionOfManagement && (
							<React.Fragment>
								<div className={styles['cood-body-cell']}>
									<CLACheckBoxField
										label={<DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.GOVERNANCE} />}
										checked={deficiency.IsCommunicatedGovernance}
										name="governance"
										onChange={(e) => {
											handleCellChange('IsCommunicatedGovernance', !deficiency.IsCommunicatedGovernance);
											handleChange('IsCommunicatedGovernance', e.target.checked);
										}}
										disabled={disabled}
									/>
									<CLACheckBoxField
										label={<DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT} />}
										checked={deficiency.IsCommunicatedManagement}
										name="IsCommunicatedManagement"
										onChange={(e) => {
											handleCellChange('IsCommunicatedManagement', !deficiency.IsCommunicatedManagement);
											handleChange('IsCommunicatedManagement', e.target.checked);
										}}
										disabled={disabled}
									/>
								</div>
								<div className={styles['cood-body-cell']}>
									<CLACheckBoxField
										label={
											<DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.WRITTEN} />
										}
										checked={deficiency.IsCommunicationWritten}
										name="IsCommunicationWritten"
										onChange={(e) => {
											handleCellChange('IsCommunicationWritten', !deficiency.IsCommunicationWritten);
											handleChange('IsCommunicationWritten', e.target.checked);
										}}
										disabled={disabled}
									/>
									<CLACheckBoxField
										label={<DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.ORAL} />}
										checked={deficiency.IsCommunicationOral}
										name="IsCommunicationOral"
										onChange={(e) => {
											handleCellChange('IsCommunicationOral', !deficiency.IsCommunicationOral);
											handleChange('IsCommunicationOral', e.target.checked);
										}}
										disabled={disabled}
									/>
								</div>
								{deficiency.IsCommunicationOral && (
									<React.Fragment>
										<div className={`${styles['cood-body-cell']} ${styles['cood-personcommunicatedto-cell']}`}>
											<CommunicationOfDeficienciesTextField
												row={deficiency}
												handlePersonCommunicatedToSave={onAutoSave}
												disabled={disabled}
											/>
										</div>
										<div className={styles['cood-body-cell']}>
											<CommunicationOfDeficienciesDatepicker
												row={row}
												handleLockingField={handleLockingField}
												handleDatePickerChange={handleDatePickerChange}
												handleCellChange={handleCellChange}
												disabled={disabled}
											/>
										</div>
									</React.Fragment>
								)}
							</React.Fragment>
						)}
					</div>
				</div>
			</Box>
		</>
	);
};

export default CommunicationOfOtherDeficienciesSubRow;
