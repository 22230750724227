import clientApi from '@utilities/claApiClient';
import logger from '@utilities/logService'

const getLedgerTrialbalanceCategories = async (projectId, body) => {
    try {
        const response = await clientApi.post(`/generalledger/trialbalancecategories/${projectId}`, body);
        return response;
    } catch (error) {
        logger.error(error)
    }
}

const generalLedgerService = {
    getLedgerTrialbalanceCategories
}

export default generalLedgerService;