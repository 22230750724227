import formApi from '@utilities/claApiForm';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"

const createProjectFormComments = async (
    payload,
    projectId
) => {
    try {
        const response = await formApi.post(`/projectformcomment/${projectId}`, payload);
        return { status: 201, data: response.data };
    } catch (error) {
        return { status: 500, message: error };
    }
};
const updateProjectFormComments = async (
    payload,
    projectId
) => {
    try {
        const response = await formApi.put(`/projectformcomment/${projectId}`, payload);
        return { status: 204, data: response.data };
    } catch (error) {
        return { status: 500, message: error };
    }
};

const getProjectFormComments = async (projectFormId, trialBalances, projectId) => {
    try {
        const trialBalanceIds = trialBalances.map((item) => { return item.value}).join().slice(0, -1);

        const response = await formApi.get(
            `/projectform/comments/${projectFormId}/${projectId}?trialBalanceIds=${trialBalanceIds}`
        );
        
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 500, message: error };
    }
};

const projectFormCommentService = {
    createProjectFormComments,
    getProjectFormComments,
    updateProjectFormComments
}

export const useProjectFormComments = (projectFormId, trialBalances, projectId) => {
    return useQuery(
        ["ProjectFormComments", projectFormId, trialBalances],
        () => getProjectFormComments(projectFormId, trialBalances, projectId),
        {
            enabled: !!projectFormId && !!trialBalances
        }
    )
}

export const useInsertProjectFormComments = (projectId) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (payload) => await createProjectFormComments(payload, projectId),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries("ProjectFormComments");
            }
        }
    )
}

export const useUpdateProjectFormComments = (projectId) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (payload) => await updateProjectFormComments(payload, projectId),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries("ProjectFormComments");
            }
        }
    )
}


export default projectFormCommentService